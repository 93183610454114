import React from 'react';
import PropTypes from 'prop-types';
// styles
import { DivStyledVideoContainer } from './styles';

export const VideoComponent = ({
  height,
  id,
  title,
  width,
}) => (
  <DivStyledVideoContainer
    className='video-container'
    data-video-id={id}>
    <iframe
      allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;'
      allowFullScreen=''
      height={height}
      src={`https://www.youtube.com/embed/${id}?enablejsapi=1&rel=0&origin=https://northwesternmutual.com/`}
      title={title}
      width={width}
    ></iframe>
  </DivStyledVideoContainer>
);

VideoComponent.propTypes = {
  /** optional video height, video-container is responsive so this value might not need to be adjusted */
  height: PropTypes.number,
  /** required YouTube video id */
  id: PropTypes.string.isRequired,
  /** required video title used for accessible iFrame, describes the video within the iFrame, special chars are accepted, i.e. &#45; */
  title: PropTypes.string.isRequired,
  /** optional video width, video-container is responsive so this value might not need to be adjusted */
  width: PropTypes.number,
};

VideoComponent.defaultProps = {
  height: 320,
  width: 600,
};

export default VideoComponent;
