import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

// template components
import Button from '../../../../components/Button';
import { Container, Row, Col } from '../../../../foundations/Grid';
import Link from '../../../../components/Link';
import List from '../../../../components/List';
import Typography from '../../../../foundations/Typography';

export const ButtonHideShowPasswordStyled = styled.button`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    let thisRight = rem(40);
    if (mediumUp) {
      thisRight = rem(80); /** TODO: build endAdornment into TextField Form Component */
    }

    return css`
      position: absolute;
      right: ${thisRight};
      margin-top: ${mediumUp ? rem(-35) : rem(-32)};
      background: none;
      border: none;
      .nmx-icon {
        .icon-fill {
          fill: ${theme.colors.blue.blue};
        }
      }
    `;
  }}
`;

export const ButtonCloseStyled = styled.button` /** TODO: import Dismiss Component */
  position: absolute;
  top: 0;
  right: 0;
  width: ${rem(42)};
  min-width: unset;
  padding: ${rem(12)};
  border: none;
  float: right;
  background: transparent;
  svg {
    height: ${rem(16)};
    width: ${rem(16)};
  }
`;

export const ButtonLoginStyled = styled(Button)`
  display: block;
  margin: 0 auto ${rem(16)};
`;

export const ColFormStyled = styled(Col)`
  margin-bottom: ${rem(16)};
  .nmx-label {
    display: block;
  }
`;

export const ColLoginContentStyled = styled(Col)`
  ${({ theme }) => css`
    padding: ${theme.mediumUp() ? rem(48) : rem(16)}; 
  `}
`;

export const ContainerModalStyled = styled(Container)`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    const xlargeUp = theme.xlargeUp();
    return css`
      padding: 0;
      position: relative;
      z-index: ${theme.zIndex.modalMain};
      max-width: ${xlargeUp ? rem(900) : rem(800)};
      height: ${mediumUp ? 'auto' : '100%'};
      border: 0;
      margin: 0 auto;
      backface-visibility: hidden;
      background-color: ${theme.colors.neutral.white};
      box-shadow: none;
      overflow-y: ${mediumUp ? 'auto' : 'scroll'};
      border-radius: ${mediumUp ? rem(4) : 0};
      min-height: ${mediumUp ? rem(300) : 0};
    `;
  }}
`;

export const DivLoginErrorStyled = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    padding: ${rem(16)};
    border-left: ${rem(5)} solid ${theme.colors.text.lightTheme.error};
    margin-bottom: ${rem(16)};
    background-color: ${theme.colors.background.lightTheme.lightB};

    p {
      flex: 1 0 auto;
    }

    .nmx-icon {
      .icon-fill {
        fill: ${theme.colors.text.lightTheme.error};
      }
      .icon-color {
        fill: ${theme.colors.neutral.white};
      }
    }
   `}
`;

export const DivModalOverlayStyled = styled.div`
  ${({ theme }) => css`
    position: fixed;
    z-index: ${theme.zIndex.modalOverlay};
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    background-color: rgba(0, 0, 0, 0.5); /** TODO: leverage shadow theme (once organized) */
    overflow-y: scroll;
    .is-active {
      display: flex;
      align-items: center;
      margin: auto;
    }
  `}
`;

export const UlLoginLinksStyled = styled(List)`
  ${({ theme }) => {
    const smallUp = theme.smallUp();
    return css`
      display: inline-block;
      .link-list__item {
        display: ${smallUp ? 'inline-block' : 'block'};
        margin: ${rem(8)};
        &:first-child {
          padding-right: ${smallUp ? rem(16) : 0};
          border-right: ${smallUp ? `${rem(1)} solid ${theme.colors.neutral.gray}` : 'none'};
        }
        &:last-child {
          display: block;
        }
      }
    `;
  }}
`;

export const TypographyStyled = styled(Typography)`
  font-size: ${rem(14)};
  line-height: ${rem(22)};
`;

export const LinkStyled = styled(Link)`
  font-size: ${rem(14)};
  line-height: ${rem(22)};
  text-align: center;
`;

export const RowStyled = styled(Row)`
  ${({ theme }) => css`
    background-color: ${theme.colors.background.lightTheme.lightC};
  `}
`;

export const ColStyledAppDownloadContainer = styled(Col)`
  padding: ${rem(24)} 0 ${rem(16)};
`;

export const ColStyledDownloadLinkContainer = styled(Col)`
  .nmx-icon.badge-appStore {
    max-height: ${rem(40)};
  }
  .nmx-icon.badge-googlePlay {
    max-height: ${rem(60)};
    margin-top: ${rem(-10)};
  }
`;

export const ListItemStyledDownLoadLink = styled.li`
  display: inline-block;
  vertical-align: top;
`;
