import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

// components
import { Col, Row } from '../../foundations/Grid';
import Typography from '../../foundations/Typography';

export const DivStyledContainer = styled.div`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    return css`
      display: flex;
      position: relative;
      width: 100%;
      min-height: ${mediumUp ? rem(100) : rem(90)};
      margin-bottom: ${rem(6)};
      transition: ${theme.effects.shadows.transition};
      background-color: ${theme.colors.neutral.grayLight30};
      flex-direction: column;
      justify-content: center;
      &:hover {
        box-shadow: ${theme.effects.shadows.hover};
        text-decoration: none;
        cursor: pointer;
      }
    `;
  }
}`;

export const RowStyled = styled(Row)`
  min-height: ${rem(70)};
  padding: ${rem(20)} 0;
  align-items: center;
`;

export const ColStyledIconContainer = styled(Col)`
  display: flex;
  height: ${rem(70)};
  padding: 0;
  align-items: center;
  justify-content: center;
  .nmx-icon {
    width: 100%;
    height: 100%;
  }
`;

export const ColStyledImageContainer = styled(Col)`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    return css`
      display: flex;
      height: ${rem(80)};
      padding-left: ${mediumUp ? rem(20) : rem(15)};
      align-items: center;
      justify-content: center;
    `;
  }
}`;

export const DivStyledImageContainer = styled.div`
  ${({ backgroundImage }) => css`
    width: 100%;
    height: 100%;
    background-image: url('${backgroundImage}');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  `}
`;

export const ColStyledLinkTextContainer = styled(Col)`
  display: flex;
  padding-left: 0;
  align-items: center;
`;

export const TypographyStyled = styled(Typography)`
  ${({ theme }) => css`
    margin-top: ${rem(-2)};
    margin-bottom: 0;
    padding-right: ${rem(30)};
    padding-bottom: 0;
    color: ${theme.colors.blue.blueLight10};
  `}
`;
