import styled from 'styled-components';

export const FormStyled = styled.form` 
  fieldset {
    margin: 0;
    padding: 0;
    border: none;
  }
`;

export const LegendStyled = styled.legend`
  display: none;
`;
