import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Container } from '../../foundations/Grid';

import {
  BgTextureLeftContainerStyled,
  BgTextureRightContainerStyled,
  SectionContainerStyled,
} from './styles';

export const SectionContainerComponent = React.forwardRef(
  (
    {
      backgroundVariant,
      branding,
      children,
      className,
      disableBottomPadding,
      disableTopPadding,
      hasTexture,
      id,
      moduleName,
      moduleVariation,
      textureGranuleColor,
      textureGranuleShape,
      themeType,
    },
    ref,
  ) => {
    const classNames = {};
    classNames[className] = className;
    const compiledId = `${id}-section-container`;

    return (
      <SectionContainerStyled
        backgroundVariant={backgroundVariant}
        branding={branding}
        className={classnames(classNames, className)}
        data-module={`${compiledId}:${moduleName}:${moduleVariation}`}
        disableBottomPadding={disableBottomPadding}
        disableTopPadding={disableTopPadding}
        hasTexture={hasTexture}
        id={compiledId}
        ref={ref}
        textureGranuleColor={textureGranuleColor}
        textureGranuleShape={textureGranuleShape}
        themeType={themeType}>
        {hasTexture && (
          <BgTextureLeftContainerStyled
            backgroundVariant={backgroundVariant}
            branding={branding}
            textureGranuleColor={textureGranuleColor}
            textureGranuleShape={textureGranuleShape}
            themeType={themeType}
          />
        )}
        <Container>
          {children}
        </Container>
        {hasTexture && (
          <BgTextureRightContainerStyled
            backgroundVariant={backgroundVariant}
            branding={branding}
            textureGranuleColor={textureGranuleColor}
            textureGranuleShape={textureGranuleShape}
            themeType={themeType}
          />
        )}
      </SectionContainerStyled>
    );
  },
);

SectionContainerComponent.propTypes = {
  /**  backgroundVariant determines the look and feel of the Section */
  backgroundVariant: PropTypes.oneOf([
    'darkDefault',
    'lightA',
    'lightB',
    'lightC',
    'lightSky',
  ]).isRequired,
  /** sets overall branding of component or module, default='nm' */
  branding: PropTypes.oneOf(['nm', 'pcg']),
  /** Children components placed inside the section */
  children: PropTypes.any.isRequired,
  /** Custom class name to add to Type. */
  className: PropTypes.string,
  /** Disable the default bottom padding for section */
  disableBottomPadding: PropTypes.bool,
  /** Disable the default top padding for section */
  disableTopPadding: PropTypes.bool,
  /** Optional prop to toggle background texture */
  hasTexture: PropTypes.bool, // TODO: depending on how many actual we add to this Component, we would move this prop to "variant"
  /** Id for the section */
  id: PropTypes.string.isRequired,
  /**  Used to populate and define a module on the section for a given page, used in adobe. (Ex: hero, intro-callout, content-hub) */
  moduleName: PropTypes.string,
  /** optional moduleVariation, Used to populate and define a module on the section for a given page, used in adobe. (Ex: hero, intro-callout, content-hub) */
  moduleVariation: PropTypes.string,
  /** Color of texture granule */
  textureGranuleColor: PropTypes.oneOf(['blue', 'sky']),
  /** Shape of texture granule */
  textureGranuleShape: PropTypes.oneOf(['circle']),
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']),
};

SectionContainerComponent.defaultProps = {
  backgroundVariant: 'lightA',
  branding: 'nm',
  className: null,
  disableBottomPadding: false,
  disableTopPadding: false,
  hasTexture: false,
  moduleName: 'unknown',
  moduleVariation: 'A',
  textureGranuleColor: 'blue',
  textureGranuleShape: 'circle',
  themeType: 'lightTheme',
};

export default SectionContainerComponent;
