import React from 'react';
import PropTypes from 'prop-types';

import {
  ButtonChipStyled,
  Styles,
} from './styles';

// TODO: Expand functionality to chips to make them
// * dismissable (with a close icon)
// * optionally clickable (chips can be used as static filter/info displays, for example.)

export const ChipComponent = ({
  children,
  className,
  icon: RenderIcon,
  id,
  onClick,
}) => {
  const styles = Styles();
  return (
    <ButtonChipStyled
      className={className}
      id={id}
      onClick={onClick}
      type='button'>
      {RenderIcon && (
        <RenderIcon style={styles.IconStyled(!!children)} />
      )}
      {children}
    </ButtonChipStyled>
  );
};

ChipComponent.propTypes = {
  /** Inner Text of chip */
  children: PropTypes.string,
  /** Optional custom class name */
  className: PropTypes.string,
  /** optional icon start adornment displayed before chip text - PASS IN SPECIFIC ICON COMPONENT */
  // TODO: lazyload direct/specific Icon React Component based on "oneOf" icon name (string)
  icon: PropTypes.any,
  /** Button id */
  id: PropTypes.string.isRequired,
  /** Optional onClick function */
  onClick: PropTypes.func,
};

export default ChipComponent;
