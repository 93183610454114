import { useDebugValue, useEffect, useState } from 'react';

export default function useMediaQuery(queryInput, options = {}) {
  if (typeof window === 'undefined') {
    // browser-only code - react-static has some server side rendering for build process
    global.window = { matchMedia: () => ({ matches: {} }) };
  }

  const query = queryInput.replace(/^@media( ?)/m, '');

  // Wait for jsdom to support the match media feature.
  // This defensive check is here for simplicity.
  // Most of the time, the match media logic isn't central to people tests.
  const supportMatchMedia
    = typeof window !== 'undefined' && typeof window.matchMedia !== 'undefined';

  const {
    defaultMatches = false,
    matchMedia = supportMatchMedia
      ? window.matchMedia
      : null,
    noSsr = false,
    ssrMatchMedia = null,
  } = { ...options };

  const [match, setMatch] = useState(() => {
    if (noSsr && supportMatchMedia) {
      return matchMedia(query).matches;
    }
    if (ssrMatchMedia) {
      return ssrMatchMedia(query).matches;
    }

    // Once the component is mounted, we rely on the
    // event listeners to return the correct matches value.
    return defaultMatches;
  });

  function CallUseDebugValue() {
    useDebugValue({
      query,
      match,
    });
  }

  useEffect(() => {
    let active = true;

    if (!supportMatchMedia) {
      return () => {};
    }

    const queryList = matchMedia(query);
    const updateMatch = () => {
      // Workaround Safari wrong implementation of matchMedia
      // TODO can we remove it?
      // https://github.com/mui-org/material-ui/pull/17315#issuecomment-528286677
      if (active) {
        setMatch(queryList.matches);
      }
    };
    updateMatch();
    queryList.addListener(updateMatch); // TODO: addListener deprecated, test addEventListener('change', updateMatch)
    return () => {
      active = false;
      queryList.removeListener(updateMatch); // TODO: removeListener deprecated, test removeEventListener('change', updateMatch)
    };
  }, [query, matchMedia, supportMatchMedia]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      // do nothing to satisfy SonarQube linting
    }
  }, [process.env.NODE_ENV]);

  if (process.env.NODE_ENV !== 'production') {
    CallUseDebugValue();
  }

  return match;
}
