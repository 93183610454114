import React, { Fragment, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';

// Aura elements
import CaratRightUtilityIcon from '../../../../foundations/Icon/icons/utility/CaratRight';
import { Col, Row } from '../../../../foundations/Grid';
import CollapsibleTypography from '../../../../components/CollapsibleTypography/collapsibleTypography';
import Link from '../../../../components/Link';
import Typography from '../../../../foundations/Typography';

// social icon imports
import FacebookIcon from '../../../../foundations/Icon/icons/social/lightTheme/Facebook';
import InstagramIcon from '../../../../foundations/Icon/icons/social/lightTheme/Instagram';
import LinkedinIcon from '../../../../foundations/Icon/icons/social/lightTheme/Linkedin';
import XIcon from '../../../../foundations/Icon/icons/social/X';

// local components
import AgentPhoto from '../../components/agentPhoto';
import SocialLink from '../../components/socialLink';

// helpers
import sanitizePhone from '../../../../../assets/js/helpers/sanitizePhone';

import {
  ColStyled,
  ColStyledSocial,
  DescriptionListStyled,
  LinkStyledFinraBrokerCheck,
  LinkStyledSchedule,
  LinkStyledWebsite,
  ListStyledDesignations,
  ListStyledSocialSiteLinks,
  LiStyledSocialIcon,
  LIStyledDesignation,
  TypographyStyledName,
  TypographyStyledSocialSiteLinksHeading,
} from './styles';

export const getCollapsibleTypographyButtonProps = (contentName, ctaIdPrefix, theme, themeType) => {
  const thisCollapsibleTypographyButtonProps = {};
  if (ctaIdPrefix) {
    thisCollapsibleTypographyButtonProps.id = `${ctaIdPrefix}-${contentName}-collapsible-typography-button`;
  }
  if (themeType !== 'darkTheme') {
    thisCollapsibleTypographyButtonProps.styles = { color: theme.colors.neutral.grayDark20 };
  }
  return thisCollapsibleTypographyButtonProps;
};

export const getNameAndDesignations = (designations, name, themeType) => (
  <>
    <TypographyStyledName
      className='p-name'
      component='h2'
      itemProp='name'
      themeType={themeType}>
      {name.fullName}
    </TypographyStyledName>
    <ListStyledDesignations reduced>
      {designations && designations.map((designation, index) => (
        <LIStyledDesignation key={`agent-designations-${index + 1}`}>
          <Typography
            className='p-honorificSuffix'
            component='span'
            itemProp='honorificSuffix'
            themeType={themeType}
            variant='p'
          >
            {designation}
          </Typography>{index < designations.length - 1 && <>,&nbsp;</>}
        </LIStyledDesignation>
      ))}
    </ListStyledDesignations>
  </>
);

export const getTitle = (title) => {
  if (title) {
    return {
      descriptionTerm: 'Title:',
      descriptionDetails: title,
      descriptionDetailsAttributes: [{
        className: 'p-job-title',
        itemProp: 'jobTitle',
      }],
    };
  }
  return null;
};

export const getAreasOfExpertise = (backgroundVariant, areasOfExpertise, ctaIdPrefix, theme, themeType) => {
  if (areasOfExpertise.length > 0) {
    const thisCollapsibleTypographyButtonProps = getCollapsibleTypographyButtonProps('areas-of-expertise', ctaIdPrefix, theme, themeType);
    return {
      descriptionTerm: 'Expertise:',
      descriptionDetails: <>
        <CollapsibleTypography
          backgroundVariant={backgroundVariant}
          {...Object.keys(thisCollapsibleTypographyButtonProps).length !== 0 && { buttonProps: thisCollapsibleTypographyButtonProps }}
          clampedLines={2}
          themeType={themeType}
          typographyProps={{
            component: 'span',
            disableBottomPadding: true,
          }}
        >
          {areasOfExpertise && areasOfExpertise.map((areasOfExpertiseItem, index) => (
            <Fragment key={`agent-areas-of-expertise-${index + 1}`}>
              {areasOfExpertiseItem}
              {index < areasOfExpertise.length - 1 && ', '}
            </Fragment>
          ))}
        </CollapsibleTypography>
        <meta
          content={areasOfExpertise}
          itemProp='knowsAbout'
        />
      </>,
      descriptionDetailsAttributes: [{ className: 'p-note' }],
    };
  }
  return {}; // empty object is handled in DescriptionList
};

export const getLanguages = (languages) => {
  if (languages.length > 0) {
    return {
      descriptionTerm: 'Language:',
      descriptionDetails: languages && languages.map((language, index) => (
        <>
          <span
            itemProp='knowsLanguage'
            key={`agent-language-${index + 1}`}>
            {language}
          </span>
          {index < languages.length - 1 && ', '}
        </>
      )),
    };
  }
  return {}; // empty object is handled in DescriptionList
};

export const getBuilding = (address) => {
  if (address.building) {
    return (
      <>
        &nbsp;<span className='p-extended-address'>{address.building}</span>
      </>
    );
  }
  return null;
};

export const getFormattedAddress = (address, { isInline }) => {
  if (address.street) {
    const thisFormattedAddress = <span
      className='p-adr'
      itemProp='address'
      itemScope
      itemType='https://schema.org/PostalAddress'>
      {address.street && <span
        className='p-street-address'
        itemProp='streetAddress'>{address.street}{getBuilding(address)}</span>}
      {address.street
        && isInline ? <>, </> : <br />
      }
      {address.city && <span
        className='p-locality'
        itemProp='addressLocality'>{address.city}</span>}
      {address.city && address.state && <>, </>}
      {address.state && <span
        className='p-region'
        itemProp='addressRegion'>{address.state}</span>}
      {address.state && address.zip && <> </>}
      {address.zip && <span
        className='p-postal-code'
        itemProp='postalCode'>{address.zip}{address.zipLastFour && `&#8211;${address.zipLastFour}`}</span>}
    </span>;
    return thisFormattedAddress;
  }
  return null;
};

export const getAddress = (address) => {
  if (address) {
    return {
      descriptionTerm: 'Location:',
      descriptionDetails: getFormattedAddress(address, { isInline: true }),
      descriptionDetailsAttributes: [
        { itemProp: 'workLocation' },
        { itemType: 'https://schema.org/Place' },
      ],
    };
  }
  return null;
};

export const getOfficePhone = (ctaIdPrefix, phone, themeType) => {
  if (phone) {
    return {
      descriptionTerm: 'Office Phone:',
      descriptionDetails: (
        <Link
          className='p-tel'
          {...ctaIdPrefix && { id: `${ctaIdPrefix}-advisor-phone-link` }}
          linkUrl={`tel:${sanitizePhone(phone)}`}
          themeType={themeType}
          variant='primaryDeemphasize'>
          {phone}
        </Link>
      ),
      descriptionDetailsAttributes: [{ itemProp: 'telephone' }],
    };
  }
  return {};
};

export const getBio = (backgroundVariant, bio, ctaIdPrefix, theme, themeType) => {
  if (bio) {
    const thisCollapsibleTypographyButtonProps = getCollapsibleTypographyButtonProps('bio', ctaIdPrefix, theme, themeType);
    return {
      descriptionTerm: 'Bio:',
      descriptionDetails: <>
        <CollapsibleTypography
          backgroundVariant={backgroundVariant}
          {...Object.keys(thisCollapsibleTypographyButtonProps).length !== 0 && { buttonProps: thisCollapsibleTypographyButtonProps }}
          clampedLines={2}
          themeType={themeType}
          typographyProps={{ component: 'span' }}
        >{bio}</CollapsibleTypography>
        <meta
          content={bio}
          itemProp='description'
        />
      </>,
      descriptionDetailsAttributes: [{ className: 'p-note' }],
    };
  }
  return {}; // empty object is handled in DescriptionList
};

export const getPersonalInformationItems = (agentDataObject, backgroundVariant, ctaIdPrefix, theme, themeType) => (
  <DescriptionListStyled
    descriptionListData={[
      getTitle(agentDataObject.title),
      getAreasOfExpertise(backgroundVariant, agentDataObject.areasOfExpertise, ctaIdPrefix, theme, themeType),
      getLanguages(agentDataObject.languages),
      getAddress(agentDataObject.address),
      getOfficePhone(ctaIdPrefix, agentDataObject.phone, themeType),
      getBio(backgroundVariant, agentDataObject.bio, ctaIdPrefix, theme, themeType),
    ]}
    disableBullet
    themeType={themeType}
  />
);

export const getSocialSiteLinks = (ctaIdPrefix, name, socialMediaUrls, themeType) => {
  if (socialMediaUrls && Object.keys(socialMediaUrls).length > 0) {
    return (
      <>
        <TypographyStyledSocialSiteLinksHeading
          themeType={themeType}
        >Follow</TypographyStyledSocialSiteLinksHeading>
        <ListStyledSocialSiteLinks reduced>
          {socialMediaUrls.facebook
            && <LiStyledSocialIcon>
              <SocialLink
                ariaLabel={`Connect with ${name.fullName} on Facebook`}
                {...ctaIdPrefix && { id: `${ctaIdPrefix}-social-media-facebook-link` }}
                itemProp='sameAs'
                linkUrl={socialMediaUrls.facebook}
              >
                <FacebookIcon />
              </SocialLink>
            </LiStyledSocialIcon>
          }
          {socialMediaUrls.linkedIn
            && <LiStyledSocialIcon>
              <SocialLink
                ariaLabel={`Visit ${name.fullName} on LinkedIn`}
                {...ctaIdPrefix && { id: `${ctaIdPrefix}-social-media-linkedIn-link` }}
                itemProp='sameAs'
                linkUrl={socialMediaUrls.linkedIn}
              >
                <LinkedinIcon />
              </SocialLink>
            </LiStyledSocialIcon>
          }
          {socialMediaUrls.x
            && <LiStyledSocialIcon>
              <SocialLink
                ariaLabel={`Follow ${name.fullName} on X`}
                {...ctaIdPrefix && { id: `${ctaIdPrefix}-social-media-x-link` }}
                itemProp='sameAs'
                linkUrl={socialMediaUrls.x}
              >
                <XIcon />
              </SocialLink>
            </LiStyledSocialIcon>
          }
          {socialMediaUrls.instagram
            && <LiStyledSocialIcon>
              <SocialLink
                ariaLabel={`Follow ${name.fullName} on Instagram`}
                {...ctaIdPrefix && { id: `${ctaIdPrefix}-social-media-instagram-link` }}
                itemProp='sameAs'
                linkUrl={socialMediaUrls.instagram}
              >
                <InstagramIcon />
              </SocialLink>
            </LiStyledSocialIcon>
          }
        </ListStyledSocialSiteLinks>
      </>
    );
  }
  return null;
};

const Agent = ({
  // config,
  agentDataObject,
  agentDisplayVariant,
  backgroundVariant,
  ctaIdPrefix,
  themeType,
}) => {
  const theme = useContext(ThemeContext);
  const isMediumUp = theme.mediumUp();
  const isLargeUp = theme.largeUp();

  if (!agentDataObject) { return null; } // this may be redundant, also checked in advisorConnect > getAdvisorConnectContent()

  const {
    designations,
    name,
    photoUrl,
    schedulerUrl,
    socialMediaUrls,
    websiteUrl,
  } = agentDataObject;

  if (agentDisplayVariant === 'financialAdvisorMatch') {
    return (
      <Row
        className='h-card'
        itemScope
        itemType='https://schema.org/Person'
      >
        <ColStyled
          align='center'
          medium={4}
        >
          <AgentPhoto
            agentName={name}
            agentImageUrl={photoUrl}
          />
          {!isMediumUp && getNameAndDesignations(designations, name, themeType)}
          {schedulerUrl
            && <LinkStyledSchedule
              className='u-url'
              {...ctaIdPrefix && { id: `${ctaIdPrefix}-advisor-scheduler-link` }}
              isInternalPdf // NOTE: product team requested this be a new tab, we will still be tracking
              itemProp='sameAs'
              linkUrl={schedulerUrl}
              themeType={themeType}
              variant='buttonLinkPrimary'>
              Schedule your first meeting
            </LinkStyledSchedule>
          }
          {websiteUrl
            && <LinkStyledWebsite
              className='u-url'
              {...schedulerUrl && {
                endAdornment: CaratRightUtilityIcon,
                endAdornmentStylesReset: true,
              }}
              {...ctaIdPrefix && { id: `${ctaIdPrefix}-advisor-website-link` }}
              isInternalPdf // NOTE: product team requested this be a new tab, we will still be tracking
              itemProp='url'
              linkUrl={websiteUrl}
              schedulerUrl={schedulerUrl}
              themeType={themeType}
              variant={schedulerUrl ? 'buttonLinkTertiary' : 'buttonLinkPrimary'}>
              Visit their website
            </LinkStyledWebsite>
          }
        </ColStyled>
        <Col
          medium={8}
          large={isLargeUp && socialMediaUrls && Object.keys(socialMediaUrls).length > 0 ? 7 : 8}
        >
          {isMediumUp && getNameAndDesignations(designations, name, themeType)}
          {getPersonalInformationItems(agentDataObject, backgroundVariant, ctaIdPrefix, theme, themeType)}
          <LinkStyledFinraBrokerCheck
            {...ctaIdPrefix && { id: `${ctaIdPrefix}-finra-broker-check-link` }}
            isOutgoingLink
            linkUrl='https://brokercheck.finra.org/'
            mediumDownSocialSiteLinks={!isLargeUp && socialMediaUrls && Object.keys(socialMediaUrls).length > 0}
            themeType={themeType}
            variant='primaryDeemphasize'
          >
            FINRA BrokerCheck
          </LinkStyledFinraBrokerCheck>
          {!isLargeUp && socialMediaUrls && Object.keys(socialMediaUrls).length > 0 && getSocialSiteLinks(ctaIdPrefix, name, socialMediaUrls, themeType)}
        </Col>
        {isLargeUp && socialMediaUrls && Object.keys(socialMediaUrls).length > 0
          && <ColStyledSocial large={1}>
            {getSocialSiteLinks(ctaIdPrefix, name, socialMediaUrls, themeType)}
          </ColStyledSocial>
        }
      </Row>
    );
  }

  return (
    <Row>
      <Col>
        {/** Return a (empty object) placeholder */}
      </Col>
    </Row>
  );
};

Agent.propTypes = {
  /** required primary agent data object with all of the pertinent information to display */
  agentDataObject: PropTypes.shape({
    address: PropTypes.shape({
      building: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      street: PropTypes.string,
      zip: PropTypes.string,
      zipLastFour: PropTypes.string,
    }),
    areasOfExpertise: PropTypes.arrayOf(PropTypes.string),
    bio: PropTypes.string,
    designations: PropTypes.arrayOf(PropTypes.string),
    languages: PropTypes.arrayOf(PropTypes.string),
    name: {
      firstName: PropTypes.string,
      fullName: PropTypes.string,
      lastName: PropTypes.string,
      lineage: PropTypes.string,
      middleName: PropTypes.string,
    },
    phone: PropTypes.string,
    photoUrl: PropTypes.string,
    schedulerUrl: PropTypes.string,
    socialMediaUrls: PropTypes.shape({
      facebook: PropTypes.string,
      linkedIn: PropTypes.string,
      x: PropTypes.string,
      instagram: PropTypes.string,
    }),
    title: PropTypes.string,
    websiteUrl: PropTypes.string,
  }).isRequired,
  /** optional prop that returns specific visual version the Agent module  */
  agentDisplayVariant: PropTypes.oneOf(['default', 'financialAdvisorMatch']),
  /** backgroundVariant allows section to be different background color based on themeType */
  backgroundVariant: PropTypes.oneOf(['darkDefault', 'lightA', 'lightB', 'lightC']),
  /** sets overall branding of component or module, default='nm' */
  branding: PropTypes.oneOf(['nm', 'pcg']),
  /** config needed for localized asset path and template version  */
  config: PropTypes.shape({
    basePath: PropTypes.string,
    nmxTemplateVersion: PropTypes.string,
  }),
  /** pass in an id string that will be prepended to trackable/hookable CTA */
  ctaIdPrefix: PropTypes.string,
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']),
};

Agent.defaultProps = {
  branding: 'nm',
  themeType: 'lightTheme',
};

export default Agent;
