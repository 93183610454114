import React from 'react';
import PropTypes from 'prop-types';
import TextField from '../../../../components/forms/TextField';

export const LastNameInputComponent = React.forwardRef(({
  hasError,
  helperText,
  onBlur,
  onChange,
  themeType,
  value,
}, ref) => (
  <TextField
    hasError={hasError}
    helperText={helperText}
    id='in-page-lead-form-last-name-input'
    labelText='Last name'
    maxLength={30}
    name='lastName'
    onBlur={onBlur}
    onChange={onChange}
    ref={ref}
    required
    themeType={themeType}
    value={value} />
));

LastNameInputComponent.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default LastNameInputComponent;
