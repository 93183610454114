import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Modal from 'react-modal';

// template components
import AssistiveText from '../../../../components/AssistiveText';
import Form from '../../../../components/forms/Form';
import Link from '../../../../components/Link';
import { Row, Col } from '../../../../foundations/Grid';
import TextField from '../../../../components/forms/TextField';
import Typography from '../../../../foundations/Typography';
import WidowBlock from '../../../../components/WidowBlock';

// icon imports
import CircleWithExclamationPointFill from '../../../../foundations/Icon/icons/utility/CircleWithExclamationPointFill';
import CloseIcon from '../../../../foundations/Icon/icons/utility/Close';
import EyeUtility from '../../../../foundations/Icon/icons/utility/Eye';
import EyeWithSlashUtility from '../../../../foundations/Icon/icons/utility/EyeWithSlash';

import {
  ButtonCloseStyled,
  ButtonLoginStyled,
  ButtonHideShowPasswordStyled,
  ColFormStyled,
  ColLoginContentStyled,
  ColStyledAppDownloadContainer,
  ColStyledDownloadLinkContainer,
  ContainerModalStyled,
  DivLoginErrorStyled,
  LinkStyled,
  ListItemStyledDownLoadLink,
  RowStyled,
  TypographyStyled,
  UlLoginLinksStyled,
} from './styles';

// TODO: this entire module will be deleted - moved to UserLoginButton.js

export const UserLoginModalComponent = (props) => {
  // states
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [loginError, setLoginError] = useState(false);
  const [password, setPassword] = useState('');
  const [showHidePasswordChecked, setChecked] = useState(false);
  const [username, setUsername] = useState('');
  // refs
  const loginComponent = useRef();
  const modalCloseEl = useRef();
  const modalWindow = useRef();

  // functions
  const handleHideShowPasswordChange = () => {
    setChecked(!showHidePasswordChecked);
    return false;
  };

  const closeModal = () => {
    // Using the .remove method instead of using state because the nm_mobile_login_button
    // component is adding the is-active class to this component in order to display it.
    // This was a simpler way of achieving the functionality without having to implement the context API or Redux.
    loginComponent.current.classList.remove('is-active');
    document.body.classList.remove('modal-is-open');
    document.getElementById('nmx-login-open-button').focus();
    setIsOpen(false);
    return false;
  };

  function openModal() {
    setIsOpen(true);
    document.getElementById('nmx-client-login-username').focus();
  }

  // Component mount
  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Use MutationObserver to detect class change on parent Component (this is only needed because button and modal are in 2 separate Components)
      // TODO: rework button and modal Components to utilize simpler React
      // functionality: state, etc... // This method was just to streamline currently 2 separate components (button, modal)
      const observerConfig = {
        attributes: true,
        attributeFilter: ['class'],
        subtree: false,
      };

      // TODO: use EventEmitter? https://medium.com/@krzakmarek88/eventemitter-instead-of-lifting-state-up-f5f105054a5#:~:text=Event%20Emitter%20is%20useful%20library%20in%20React%20apps&text=%2D%20on%2C%20adds%20listener%20and%20start,add%20payload%20when%20is%20needed
      // When a mutation is observed
      const observerCallback = (mutationsList) => {
        mutationsList.forEach((mutation) => {
        // If active, set component height (so flexbox functionality works)
          if (mutation.target.classList.contains('is-active')) {
            openModal();
            document.body.classList.add('modal-is-open');
          } else {
            closeModal();
            document.body.classList.remove('modal-is-open');
          }
        });
      };

      // Create an new observer
      const observer = new MutationObserver(observerCallback);

      // Start observing
      observer.observe(loginComponent.current, observerConfig);
    }
  }, []);

  const validateForm = (e) => {
    e.preventDefault();
    if (!username || !password) {
      setLoginError(true);
    } else {
      setLoginError(false);
      const formEl = document.getElementById('nmx-client-login-form');
      formEl.submit();
    }
  };

  // TODO: this markup is currently not used - moved to userLoginButton.js, however, userLoginModal styles are imported.

  return (
    <div
      ref={loginComponent}>
      <Modal
        contentLabel="Example Modal"
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        overlayClassName="ReactModalPortal__Overlay"
        shouldCloseOnOverlayClick={true}
      >
        <ContainerModalStyled
          className={classNames('nmx-modal nmx-client-login-modal')}
          id='nmx-client-login-modal'
          role='dialog'
          aria-modal='true'
          ref={modalWindow}
        >
          <Row>
            <ColLoginContentStyled
              xsmall={12}>
              <ButtonCloseStyled // TODO: use Dismiss Component
                id='nmx-client-login-modal-close-button'
                onClick={closeModal}
                ref={modalCloseEl}>
                <CloseIcon />
                <AssistiveText>Close Login Window</AssistiveText>
              </ButtonCloseStyled>
              <Typography
                component='h4'
                variant='h3'
                align='center'>
                Log in
              </Typography>
              {
                loginError && (
                  <DivLoginErrorStyled>
                    <CircleWithExclamationPointFill />
                    <Typography
                      align='center'
                      disableBottomPadding >
                      Please fill in your Username and Password.
                    </Typography>
                  </DivLoginErrorStyled>
                )
              }
              <Form
                className='nmx-form nmx-client-login-modal__form'
                method='POST'
                id='nmx-client-login-form'
                legend='Enter your username and password to access your account.'
                action={props.config.services.loginPageUrl}>
                <fieldset>
                  <Row>
                    <ColFormStyled xsmall={12}>
                      <TextField
                        labelText='Username'
                        id='nmx-client-login-username'
                        name='username'
                        type='text'
                        required={true}
                        value={username}
                        onChange={(e) => { setUsername(e.target.value); }}
                        onBlur={(e) => { setUsername(e.target.value.trim()); }}
                      />
                    </ColFormStyled>
                    <ColFormStyled
                      xsmall={12}
                      style={{ marginBottom: '0.5rem' }}>
                      <TextField
                        labelText='Password'
                        id='nmx-client-login-password'
                        name='password'
                        type={ showHidePasswordChecked ? 'text' : 'password'}
                        required={true}
                        value={password}
                        onChange={(e) => { setPassword(e.target.value); }}
                      />
                      <ButtonHideShowPasswordStyled
                        type='button'
                        onClick={handleHideShowPasswordChange}>
                        <span className='nmx-icon__container'>
                          {showHidePasswordChecked
                            ? <EyeUtility />
                            : <EyeWithSlashUtility />
                          }
                        </span>
                      </ButtonHideShowPasswordStyled>
                    </ColFormStyled>
                  </Row>
                  <Row flex-direction='row'>
                    <ColFormStyled xsmall={12}>
                      <TypographyStyled>Forgot <LinkStyled
                        variant='primaryDeemphasize'
                        linkUrl={`${props.config.services.loginPageUrlBase}/forgot-username`}
                        ariaLabel='Help with finding your username'>username</LinkStyled> or <LinkStyled
                        variant='primaryDeemphasize'
                        linkUrl={`${props.config.services.loginPageUrlBase}/password-reset`}
                        ariaLabel='Help with resetting your password'>password</LinkStyled>&#63;</TypographyStyled>
                    </ColFormStyled>
                  </Row>
                  <Row flex-direction='row'>
                    <ColFormStyled xsmall={12}>
                      <ButtonLoginStyled
                        id='nmx-client-login-submit'
                        className='nmx-client-login-modal__submit' // TODO: needed?
                        type='submit'
                        variant='primary'
                        onClick={validateForm}>
                        Log in
                      </ButtonLoginStyled>
                      <Typography align='center'>
                        Don&#39;t have an account&#63; <Link
                          variant='primaryDeemphasize'
                          linkUrl={`${props.config.services.loginPageUrlBase}/registration`}
                          ariaLabel='Register for a new account.'>Register</Link>.
                      </Typography>
                    </ColFormStyled>
                  </Row>
                </fieldset>
              </Form>
              <Row>
                <Col align='center'>
                  <Typography disableBottomPadding>
                    Log in to other Northwestern <WidowBlock>Mutual sites</WidowBlock>
                  </Typography>
                  <UlLoginLinksStyled className='reduced'>
                    <li className='link-list__item'>
                      <Link
                        variant='primaryDeemphasize'
                        linkUrl='https://www.eaccountservices.com/NM/'
                        ariaLabel='Visit Northwestern Access Fund.'>
                        Access Fund
                      </Link>
                    </li>
                    <li className='link-list__item'>
                      <Link
                        variant='primaryDeemphasize'
                        linkUrl='https://bcis.northwesternmutual.com/bcis/update/login'
                        ariaLabel='Visit Northwestern Mutual Business Insurance.'>
                        Business Insurance
                      </Link>
                    </li>
                  </UlLoginLinksStyled>
                </Col>
              </Row>
            </ColLoginContentStyled>
          </Row>
          <RowStyled>
            <ColStyledAppDownloadContainer>
              <Row>
                <Col align='center'>
                  <Typography component='h3'>Get anywhere access, go mobile</Typography>
                </Col>
              </Row>
              <Row align='center'>
                <ColStyledDownloadLinkContainer align='center'>
                  <ul className='reduced'>
                    <ListItemStyledDownLoadLink>
                      <Link
                        linkUrl='https://northwesternmutual.page.link/?link=https%3A%2F%2Fwww.northwesternmutual.com%2Fapp%2Fcampaign%3Futm_source%3Dnmcom_log-in_modal%26utm_medium%3Dweb%26utm_campaign%3Dmobileappbadges_Q4_2022%26utm_content%3Dclient_mobileappdownload%26utm_term%3Dios&apn=com.nm.nm&amv=0&ibi=com.nm.nm.ios&isi=1132579006&st=To%20continue%20%26%20download%20the%20app%2C%20click%20%22OPEN%22%20below.&sd=Northwestern%20Mutual%20Mobile%20App&ofl=https%3A%2F%2Fapps.apple.com%2Fus%2Fapp%2Fnorthwestern-mutual%2Fid1132579006'
                        isOutgoingLink={true}>
                        <img
                          alt='Download on the App Store'
                          src={`${props.config.basePath}/template/assets/2.16.1/images/apple-app-store-badge.svg`}
                          className='nmx-icon badge-appStore' />
                      </Link>
                    </ListItemStyledDownLoadLink>
                    <ListItemStyledDownLoadLink>
                      <Link
                        linkUrl='https://northwesternmutual.page.link/?link=https%3A%2F%2Fwww.northwesternmutual.com%2Fapp%2Fcampaign%3Futm_source%3Dnmcom_log-in_modal%26utm_medium%3Dweb%26utm_campaign%3Dmobileappbadges_Q4_2022%26utm_content%3Dclient_mobileappdownload%26utm_term%3Dandroid&apn=com.nm.nm&amv=0&ibi=com.nm.nm.ios&isi=1132579006&st=To%20continue%20%26%20download%20the%20app%2C%20click%20%22OPEN%22%20below.&sd=Northwestern%20Mutual%20Mobile%20App&ofl=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.nm.nm'
                        isOutgoingLink={true}>
                        <img
                          alt='Get it on Google Play'
                          src={`${props.config.basePath}/template/assets/2.16.1/images/google-play-badge.png`}
                          className='nmx-icon badge-googlePlay' />
                      </Link>
                    </ListItemStyledDownLoadLink>
                  </ul>
                </ColStyledDownloadLinkContainer>
              </Row>
            </ColStyledAppDownloadContainer>
          </RowStyled>
        </ContainerModalStyled>
      </Modal>
    </div>
  );
};

UserLoginModalComponent.propTypes = {
  /** Config vars used for varying components */
  config: PropTypes.shape({
    basePath: PropTypes.string,
    nmxTemplateVersion: PropTypes.string,
    services: PropTypes.shape({
      loginPageUrl: PropTypes.string.isRequired,
      loginPageUrlBase: PropTypes.string.isRequired,
    }),
  }),
};

UserLoginModalComponent.defaultProps = {
  config: {
    services: {
      loginPageUrl: '<%=loginPageUrl%>',
      loginPageUrlBase: '<%=loginPageUrlBase%>',
    },
  },
};

export default UserLoginModalComponent;
