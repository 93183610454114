import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';

import { Row } from '../../../../foundations/Grid';
import Typography from '../../../../foundations/Typography';
import Link from '../../../../components/Link';
import List from '../../../../components/List';
import SocialLinks from '../SocialLinks';
import WidowBlock from '../../../../components/WidowBlock';

import NmAppDownloadQrCode from '../../../../../assets/images/nm-app-download-qr-code.png';

import {
  ColStyledDownloadLinkContainer,
  ColStyledDescriptiveText,
  ColStyledQrCodeContainer,
  ColStyledSocialLinks,
  ListItemStyledDownLoadLink,
  PictureStyledQrCode,
} from './styles';

const AppDownload = ({ config }) => {
  const theme = useContext(ThemeContext);
  const mediumUp = theme.mediumUp();
  const mediumToLarge = theme.mediumToLarge();
  const [isMediumUp, setIsMediumUp] = useState(mediumUp);
  const [isMediumToLarge, setIsMediumToLarge] = useState(mediumToLarge);

  useEffect(() => {
    if (mediumUp === isMediumUp) {
      setIsMediumUp(mediumUp);
    }
  }, [mediumUp]);

  useEffect(() => {
    if (mediumToLarge === isMediumToLarge) {
      setIsMediumToLarge(mediumToLarge);
    }
  }, [mediumToLarge]);

  return (
    <>
      <Row>
        <ColStyledDescriptiveText>
          <Typography>Download our app for fast, easy, and secure access to your accounts and more&#8212;<WidowBlock>anytime, anywhere.</WidowBlock></Typography>
        </ColStyledDescriptiveText>
      </Row>
      <Row align='center'>
        <ColStyledDownloadLinkContainer
          align='center'
          medium={6}>
          <List reduced>
            <ListItemStyledDownLoadLink>
              <Link
                linkUrl='https://northwesternmutual.page.link/?link=https%3A%2F%2Fwww.northwesternmutual.com%2Fapp%2Fcampaign%3Futm_source%3Dnmcom_footer%26utm_medium%3Dweb%26utm_campaign%3Dmobileappbadges_Q4_2022%26utm_content%3Dclient_mobileappdownload%26utm_term%3Dios&apn=com.nm.nm&amv=0&ibi=com.nm.nm.ios&isi=1132579006&st=To%20continue%20%26%20download%20the%20app%2C%20click%20%22OPEN%22%20below.&sd=Northwestern%20Mutual%20Mobile%20App&ofl=https%3A%2F%2Fapps.apple.com%2Fus%2Fapp%2Fnorthwestern-mutual%2Fid1132579006'
                isOutgoingLink={true}>
                {/* Fallback 2, download from source */}
                {/* <img alt='Download on the App Store' src='https://developer.apple.com/app-store/marketing/guidelines/images/badge-example-preferred_2x.png' className='nmx-icon badge-appStore' /> */}
                {/* Fallback 1, asset exists here in 2.16.1 */}
                {/* <img alt='Download on the App Store' src={`${config.basePath}/template/assets/2.16.1/images/apple-app-store-badge.svg`} className='nmx-icon badge-appStore' /> */}
                <img
                  alt='Download on the App Store'
                  src={`${config.basePath}/template/assets/${config.nmxTemplateVersion}/images/badges/apple-app-store-badge.svg`}
                  className='nmx-icon badge-appStore' />
              </Link>
            </ListItemStyledDownLoadLink>
            <ListItemStyledDownLoadLink>
              <Link
                linkUrl='https://northwesternmutual.page.link/?link=https%3A%2F%2Fwww.northwesternmutual.com%2Fapp%2Fcampaign%3Futm_source%3Dnmcom_footer%26utm_medium%3Dweb%26utm_campaign%3Dmobileappbadges_Q4_2022%26utm_content%3Dclient_mobileappdownload%26utm_term%3Dandroid&apn=com.nm.nm&amv=0&ibi=com.nm.nm.ios&isi=1132579006&st=To%20continue%20%26%20download%20the%20app%2C%20click%20%22OPEN%22%20below.&sd=Northwestern%20Mutual%20Mobile%20App&ofl=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.nm.nm'
                isOutgoingLink={true}>
                {/* Fallback 2, download from source */}
                {/* <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' className='nmx-icon badge-googlePlay' /> */}
                {/* Fallback 1, asset exists here in 2.16.1 */}
                {/* <img alt='Get it on Google Play' src={`${config.basePath}/template/assets/2.16.1/images/google-play-badge.png`} className='nmx-icon badge-googlePlay' /> */}
                <img
                  alt='Get it on Google Play'
                  src={`${config.basePath}/template/assets/${config.nmxTemplateVersion}/images/badges/google-play-badge.png`}
                  className='nmx-icon badge-googlePlay' />
              </Link>
            </ListItemStyledDownLoadLink>
          </List>
        </ColStyledDownloadLinkContainer>
        {mediumUp
          && <ColStyledQrCodeContainer
            medium={6}
            nested>
            <PictureStyledQrCode
              lazyLoad={false}
              variant='rectangular'
              src={NmAppDownloadQrCode}
              altText='Northwestern Mutual app QR Code'
              fullWidth />
          </ColStyledQrCodeContainer>
        }
      </Row>
      {mediumToLarge
        && <Row>
          <ColStyledSocialLinks>
            <SocialLinks />
          </ColStyledSocialLinks>
        </Row>
      }
    </>
  );
};

AppDownload.propTypes = {
  /** Config vars used for varying components */
  config: PropTypes.shape({
    public: PropTypes.shape({ googleAnalyticsTrackingId: PropTypes.string }).isRequired,
    services: PropTypes.shape({ userLogoutUrls: PropTypes.string.isRequired }).isRequired,
  }),
};

AppDownload.defaultProps = {
  config: {
    public: { googleAnalyticsTrackingId: '<%=googleAnalyticsTrackingId%>' },
    services: { userLogoutUrls: '<%=userLogoutUrls%>' },
  },
};

export default AppDownload;
