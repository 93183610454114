import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
// native components
import Typography from '../../../foundations/Typography';
// other styles
import { defaultLabelStyles } from '../Label/styles';

export const TypographyStyled = styled(Typography)`
  display: block;
  padding-bottom: ${rem(8)};
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  font-weight: 400;
`;

// ====================
// Default styles
// ====================

export const DivStyledRadioGroup = styled.div`
  ${defaultLabelStyles}
`;

export const SpanStyledRequiredFieldAsterisk = styled.span`
  ${({ hasError, theme, themeType }) => {
    let thisColor = theme.colors.forms.lightTheme.inputFieldText;
    if (themeType === 'lightTheme' && hasError) {
      thisColor = theme.colors.forms.lightTheme.inputFieldError;
    }
    if (themeType === 'darkTheme') {
      thisColor = theme.colors.forms.darkTheme.inputFieldText;
      if (hasError) {
        thisColor = theme.colors.forms.darkTheme.inputFieldError;
      }
    }
    return css`
      color: ${thisColor};
    `;
  }}
`;

export const SpanStyledRadioLabel = styled.span`
  display: inline-flex;
  align-items: center;
  align-content: center;
  input[type=radio] {
    margin-top: ${rem(-2)};
    margin-right: ${rem(8)};
  }
`;

export const LabelStyledRadioLabelGroup = styled.label`
  ${({ disabled, index, stackOptions, theme, themeType }) => {
    let thisColor = theme.colors.forms.lightTheme.inputFieldText; // default lightTheme label
    if (themeType === 'lightTheme' && disabled) {
      thisColor = theme.colors.text.lightTheme.disabled;
    } else if (themeType === 'darkTheme') {
      thisColor = theme.colors.forms.darkTheme.inputFieldText;
    } else if (themeType === 'darkTheme' && disabled) {
      thisColor = theme.colors.text.darkTheme.disabled;
    }

    return css`
      ${index > 0 && `margin-left: ${rem(16)};`}
      ${stackOptions && `
        display: block;
        margin-left: 0;
      `}
      ${stackOptions && `padding-bottom: ${rem(4)};`} /** TODO: only add padding-bottom on all but last stacked option */
      color: ${thisColor};
      font-size: ${rem(16)}; /** Actual Radio Group option text should match TextField input */
      line-height: ${rem(24)}; /** Actual Radio Group option text should match TextField input */
    `;
  }
}
`;

// ====================
// Visual Picker styles
// ====================
export const DivStyledVisualPickerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DivStyledVisualPickerItemContainer = styled.div`
  ${({ stackOptions }) => css`
    display: ${stackOptions ? 'block' : 'flex'};
    flex-direction: row;
  `};
`;

export const DivStyledVisualPickerOption = styled.div`
  ${({ index, stackOptions }) => css`
    display: flex;
    width: auto;
    margin-right: ${rem(16)};
    margin-bottom: ${rem(4)};
    ${(stackOptions && index > 0) && `margin-top: ${rem(10)};`};
    cursor: pointer;
    input[type='checkbox'],
    input[type='radio'] {
      position: absolute;
      margin-left: ${rem(32)};
      align-self: center;
      cursor: pointer;
    }
  `
}`;

export const DivStyledVisualPickerOptionContainer = styled.div`
  ${({ theme, themeType }) => {
    const thisBorderColor = themeType === 'darkTheme' ? theme.colors.forms.darkTheme.inputFieldText : theme.colors.forms.lightTheme.inputFieldText;
    const thisBorderActiveColor = themeType === 'darkTheme' ? theme.colors.forms.darkTheme.inputFieldText : theme.colors.forms.lightTheme.inputFieldActive;
    return css`
      width: max-content;
      min-width: ${rem(120)};
      height: ${rem(50)};
      border: ${rem(1)} solid ${thisBorderColor};
      border-radius: ${rem(4)};
      && {${themeType === 'darkTheme' // double-specificity to override default Label Component, only for darkTheme visualPicker
        && `label.nmx-visual-picker__label {
          color: ${thisBorderActiveColor};
        }`};
      }
      &:hover {
        box-shadow: ${theme.effects.shadows.dp2};
      }

      input:focus ~ & {
        border: ${rem(2)} solid ${thisBorderActiveColor};
      }

      input:checked ~ & {
        border: ${rem(2)} solid ${thisBorderActiveColor};
        .nmx-visual-picker__label {
          color: ${thisBorderActiveColor};
          font-weight: 700;
          ${themeType === 'darkTheme' && `background: ${theme.colors.forms.darkTheme.inputFieldBg};`}
        }
      }
    `;
  }}
`;

export const LabelStyledVisualPickerOptionText = styled.label`
  ${({ theme }) => css`
    display: block;
    width: 100%;
    height: 100%;
    color: ${theme.colors.neutral.grayDark20};
    font-size: ${rem(18)};
    cursor: pointer;
  `}
`;

export const SpanStyledVisualPickerOptionText = styled.span`
  display: block;
  padding: ${rem(15)} ${rem(20)} 0 ${rem(52)};
`;
