import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// components
import { Row } from '../../foundations/Grid';
import CredentialCard from './credentialCard';
// credentials data array
import credentialData from './credentialData';
// styles
import { CredentialCardColStyled } from './styles';

export const CredentialCardSetComponent = ({
  accentColorVariant,
  accentOrientation,
  backgroundVariant,
  branding,
  className,
  credentials,
  descriptionTextAlign,
  hasAccent,
  headingColorVariant,
  headingTextUpperCase,
  themeType,
}) => {
  useEffect(() => {
    // validate all credentials passed in are correct
    if (credentials.length === 0) {
      console.error(
        'Missing credentials. At least one credential is required.',
      );
    }
    for (let i = 0; i < credentials.length; i += 1) {
      if (credentials[i].credential in credentialData) {
        // check if credential requires sup
        if (
          credentialData[credentials[i].credential]().isCitable
          && !credentials[i].sup1
        ) {
          console.error(
            `${credentials[i]} credential requires a sup to be passed in for footer reference.`,
          );
        }
      } else {
        // invalid credential provided
        console.error(
          'Provided credential identifier is invalid. Possible credentials are [amBestCompany, blackFounderAcceleratorProgram, blackImpactInvestingFund, blackStartStartUpCompanyDonations, childhoodCancerDonations, childhoodCancerResearch, clientRetailInvestmentAssets, communityDonations, covidReliefDonations, diDividendPayout, financialStrengthRating, fitchRatings, largestDirectProvider, milwaukeeNeighborhoodInvestments, milwaukeeStudentInvestments, moodysInvestorsService, mostAdmired, mostTrustedFinancialCompaniesForLifeInsurance, mostTrustedInsuranceCompany, numberOfClients, policyownerRetention, rankedDisabilityIncomeInsurance, rankedInsuranceCompany, rankedInvestmentService, sAndPGlobalRatings, totalDividendPayout, yearsOfService].',
        );
      }
    }
  }, []);

  const largeBreakpoint = 12 / credentials.length; // TODO: to be used in CredentialCardSet
  const mediumBreakpoint = credentials.length === 4 ? 6 : 4; // TODO: to be used in CredentialCardSet

  return (
    <Row
      className={className}
      align='center'>
      {credentials.map((credential, index) => (
        <CredentialCardColStyled
          key={`credential-${index + 1}`}
          medium={mediumBreakpoint}
          large={largeBreakpoint}>
          <CredentialCard
            className='nmx-credential-card'
            accentColorVariant={accentColorVariant}
            accentOrientation={accentOrientation}
            additionalDescription={credential.additionalDescription}
            backgroundVariant={backgroundVariant}
            branding={branding}
            credential={credential.credential}
            descriptionTextAlign={descriptionTextAlign}
            hasAccent={hasAccent}
            headingColorVariant={headingColorVariant}
            headingTextUpperCase={headingTextUpperCase}
            sup1={credential.sup1}
            sup2={credential.sup2}
            themeType={themeType}
          />
        </CredentialCardColStyled>
      ))}
    </Row>
  );
};

CredentialCardSetComponent.propTypes = {
  /** optional accent color */
  accentColorVariant: PropTypes.oneOf(['gold', 'sky']),
  /** optional prop to style credential heading accent */
  accentOrientation: PropTypes.oneOf(['horizontal', 'vertical']),
  /** backgroundVariant allows section cards to be different background color based on themeType */
  backgroundVariant: PropTypes.oneOf([
    'darkDefault',
    'lightA',
    'lightB',
    'lightC',
  ]), // add 'darkDefault', for darkTheme
  /** sets overall branding of component or module, default='nm' */
  branding: PropTypes.oneOf(['nm', 'pcg']),
  /** Optional custom class name */
  className: PropTypes.string,
  /** Array of credentials to display in the section */
  credentials: PropTypes.arrayOf(
    PropTypes.shape({
      /** optional credential description */
      additionalDescription: PropTypes.any,
      /** The 'id' of the credential wanting to display */
      credential: PropTypes.oneOf([
        'amBestCompany',
        'blackFounderAcceleratorProgram',
        'blackImpactInvestingFund',
        'blackStartStartUpCompanyDonations',
        'childhoodCancerDonations',
        'childhoodCancerResearch',
        'clientRetailInvestmentAssets',
        'communityDonations',
        'covidReliefDonations',
        'diDividendPayout',
        'financialStrengthRating',
        'fitchRatings',
        'largestDirectProvider',
        'milwaukeeNeighborhoodInvestments',
        'milwaukeeStudentInvestments',
        'moodysInvestorsService',
        'mostAdmired',
        'mostTrustedFinancialCompaniesForLifeInsurance',
        'mostTrustedInsuranceCompany',
        'numberOfClients',
        'policyownerRetention',
        'rankedDisabilityIncomeInsurance',
        'rankedInsuranceCompany',
        'rankedInvestmentService',
        'sAndPGlobalRatings',
        'totalDividendPayout',
        'yearsOfService',
      ]).isRequired,
      /** The superscript symbol or number corresponding with the footer reference, Required for credentials needing source to cite, maximum of 2 */
      sup1: PropTypes.any,
      sup2: PropTypes.any,
    }),
  ).isRequired,
  /** optional prop to align descriptionText Typography */
  descriptionTextAlign: PropTypes.oneOf(['inherit', 'left', 'center', 'right']),
  /** optional prop for heading color */
  headingColorVariant: PropTypes.oneOf(['default', 'sky']),
  /** optional boolean to set uppercase heading text */
  headingTextUpperCase: PropTypes.bool,
  /** section theme, which determines color styles */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme', 'nmx-pcg']),
};

CredentialCardSetComponent.defaultProps = {
  accentOrientation: 'vertical',
  accentColorVariant: 'gold',
  backgroundVariant: 'lightB',
  branding: 'nm',
  hasAccent: false,
  headingColorVariant: 'default',
  headingTextUpperCase: false,
  themeType: 'lightTheme',
};

export default CredentialCardSetComponent;
