import React from 'react';
import PropTypes from 'prop-types';

import { WidowBlockStyled } from './styles';

export const WidowBlockComponent = ({ children, className }) => (
  <WidowBlockStyled className={className}>
    {children}
  </WidowBlockStyled>
);

WidowBlockComponent.propTypes = {
  /** Optional classname for custom styling */
  className: PropTypes.string,
  /** The inner content to be rendered */
  children: PropTypes.node.isRequired,
};

export default WidowBlockComponent;
