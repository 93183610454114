import React from 'react';
import PropTypes from 'prop-types';
// components
import Typography from '../../../../foundations/Typography';
import { Row, Col } from '../../../../foundations/Grid';
// styles
import {
  LinkStyled,
  TypographyStyled,
} from './styles';
// shared submission screen styles
import {
  ContainerStyled,
  ThumbsUpIconStyled,
} from '../styles';
// helpers
import {
  getTypographyVariant,
  getTypographyWeight,
  heroCheck,
  pcgCheck,
} from '../utils/submissionScreenHelperFunctions';

export const ThankYouModuleComponent = ({
  branding,
  themeType,
  userName,
  variant,
}) => {
  const isHeroVariant = heroCheck(variant); // If 'hero' variant
  return (
    <ContainerStyled
      formvariant={variant}
      id='in-page-lead-thank-you-view'>
      {branding !== 'pcg' && (
        <Row align='center'>
          <ThumbsUpIconStyled formvariant={variant} />
        </Row>
      )}
      <Row align='center'>
        <Col align='center'>
          <TypographyStyled
            formvariant={variant}
            variant={getTypographyVariant(variant, branding)}
            themeType={themeType}>
            Got it&#33; Thanks, {userName}.
          </TypographyStyled>
          {isHeroVariant && (
            <Typography
              variant='h2'
              themeType={themeType}
              disableBottomPadding>
              We&#39;re excited to work with you.
            </Typography>
          )}
        </Col>
      </Row>
      {/* FOR EMBEDDED VARIANT (DEFAULT) */}
      {!isHeroVariant && (
        <Row align='center'>
          <Col medium={10}>
            <Typography
              weight={getTypographyWeight(branding)}
              themeType={themeType}
              nextElement="graphic">
              We&#39;re excited to work with you. Your advisor will reach out
              soon to talk about your goals. Until then, check out&nbsp;
              <LinkStyled
                id='in-page-lead-form-guide-on-what-to-expect-link'
                linkUrl='/email-landing/prospect/get-the-most-from-your-first-advisor-meeting?utm_source=NMCOM&utm_medium=LandingPage&utm_term=na&utm_campaign=FAFA&utm_content=Let%27s_Get_Ready'
                variant='primaryDeemphasize'
                themeType={themeType}>
                our guide on what you can expect
              </LinkStyled>
              &nbsp;from your first meeting and what to have handy.
            </Typography>
            {pcgCheck(branding) && (
              <Typography
                component='small'
                themeType={themeType}
                disableBottomPadding>
                We do our best to match you with an advisor based on what you
                told us. While unlikely, there are circumstances that could
                prevent us from matching you to an advisor at a Private Client
                Group firm. If this should happen, we&#39;ll still make sure to
                match you with an equally qualified advisor that will fit your
                needs.
              </Typography>
            )}
          </Col>
        </Row>
      )}
    </ContainerStyled>
  );
};

ThankYouModuleComponent.propTypes = {
  /** sets overall branding of component or module, default='nm' */
  branding: PropTypes.oneOf(['nm', 'pcg']),
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']),
  /** passes along the user inputed first name to the thank you screen */
  userName: PropTypes.string,
  /** what variant of this form */
  variant: PropTypes.oneOf(['embedded', 'hero']).isRequired,
};

ThankYouModuleComponent.defaultProps = {
  branding: 'nm',
  themeType: 'darkTheme',
  variant: 'embedded',
};

export default ThankYouModuleComponent;
