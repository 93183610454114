import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

// template components
import Link from '../../../components/Link';
import Typography from '../../../foundations/Typography';

export const LinkStyled = styled(Link)`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    return css`
      font-size: ${mediumUp ? rem(24) : rem(19)}; /** h3 */
      line-height: ${mediumUp ? rem(32) : rem(25)}; /** h3 */
    `;
  }}
`;

export const TypographyStyled = styled(Typography)`
  ${({ theme, disableBottomPadding }) => {
    let paddingBottom = theme.mediumUp() ? rem(16) : rem(12);
    if (disableBottomPadding) {
      paddingBottom = 0;
    }
    return css`
      padding-bottom: ${paddingBottom};
      text-align: ${theme.xlargeUp() ? 'center' : 'left'};
    `;
  }
}`;
