import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import ChevronLeftNarrowIcon from '../../foundations/Icon/icons/utility/ChevronLeftNarrow';
import ChevronRightNarrowIcon from '../../foundations/Icon/icons/utility/ChevronRightNarrow';
import { Row, Col } from '../../foundations/Grid';

export const SwiperCarouselContainerRow = styled(Row)`
  && {
    width: 100%;
    height: 100%;
    padding-bottom: ${rem(40)};
  }
`;

export const SwiperCarouselSwiperSlide = styled.div`
  display: flex;
  justify-content: center;
`;

export const SwiperCarouselSwiperSlideRow = styled(Row)`
  width: 100%;
  justify-content: center;
`;

export const SwiperCarouselSwiperSlideCol = styled(Col)`
  ${({ theme }) => {
    let thisFlex = '0 100%';
    let thisMaxWidth = '100%';
    if (theme.smallUp()) {
      thisFlex = '0 83.33333333333334%';
      thisMaxWidth = '83.33333333333334%';
    }
    // TODO: not working
    return css`
      flex: ${thisFlex};
      max-width: ${thisMaxWidth};
    `;
  }}
`;

export const SwiperCarouselSwiperPagination = styled.div`
  && {
    width: 100%;
    bottom: 0;
  }
`;

export const SwiperCarouselSwiperNavigation = styled.div`
  ${({ theme }) => {
    const smallUp = theme.smallUp();
    return css`
    && {
      display: block;
      width: ${rem(17)};
      height: ${smallUp ? 'unset' : '100%'};
      top: ${smallUp ? '45%' : 0};
      background-color: ${smallUp ? 'unset' : 'rgba(255,255,255,0.3)'};
      &::after {
        color: ${theme.colors.neutral.grayDark10};
        visibility: hidden;
      }
    }
  `;
  }
}`;

export const SwiperCarouselSwiperNavigationLeftIcon = styled(ChevronLeftNarrowIcon)`
  ${({ theme }) => css`
    .icon-stroke {
      fill: none; /** TODO: reexamine build-icons to handle svg polyline stroke and fill */
      stroke: ${theme.colors.neutral.gray};
    }
    :hover {
      .icon-stroke {
        stroke: ${theme.colors.action.lightTheme.linkHover};
      }
    }
  `}
`;

export const SwiperCarouselSwiperNavigationRightIcon = styled(ChevronRightNarrowIcon)`
  ${({ theme }) => css`
    .icon-stroke {
      fill: none; /** TODO: reexamine build-icons to handle svg polyline stroke and fill */
      stroke: ${theme.colors.neutral.gray};
    }
    :hover {
      .icon-stroke {
        stroke: ${theme.colors.action.lightTheme.linkHover}; /** switched from linkDefault to linkHover - Confirm with Creative */
      }
    }
  `}
`;
