import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const DivSearchStyled = styled.div`
  ${({ theme, nmxTemplateVersion, basePath, hasValue }) => {
    const largeUp = theme.largeUp();
    const largeDown = theme.largeDown();
    return css`
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      overflow: hidden;
      padding: 0;
      margin: 0;
      &.has-results {
        z-index: ${theme.zIndex.searchMain};
      }
      ${largeUp
        && css`
          display: none;
        `}
      a {
        text-decoration: none;
        color: ${theme.colors.action.lightTheme.linkDefault};
      }
      p {
        color: ${theme.colors.neutral.grayDark20};
      }

      && {
        .nmx-container__search {
          position: fixed;
          display: block;
          width: 100%;
          margin-left: 0;
          background-color: ${theme.colors.neutral.grayLight30};
          &.has-results {
            z-index: ${theme.zIndex.searchMainResults};
            overflow-y: visible;
            ${largeUp
              && css`
                padding-bottom: ${rem(29)};
              `}
          }
          ${largeUp
            && css`
              z-index: ${theme.zIndex.searchMain};
              width: 100%;
              max-width: none;
              margin-left: 0;
              background-color: ${theme.colors.neutral.white};
              & > .nmx-row {
                max-width: 90rem;
                margin: 0 auto;
              }
            `}
        }
      }

      .nmx-search-overlay {
        position: fixed;
        z-index: ${theme.zIndex.searchOverlay};

        display: none;
        width: 200%;
        height: 100%;
        background-color: ${theme.colors.neutral.black};
        opacity: 0.4;
        &.has-results {
          display: block;
        }

        ${largeUp
          && css`
            display: block;
          `}
      }

      .nmx-search-form {
        padding: ${rem(16)};

        ${largeUp
          && css`
            padding: ${rem(50)} 0;
          `}

        legend {
          display: none;
        }
        label {
          display: none;
          margin-bottom: ${rem(20)};
          color: ${theme.colors.forms.lightTheme.inputFieldText};
        }
        .nmx-icon-search-field-clear {
          position: relative;
          top: ${rem(-60)};
          right: 0;
          right: ${rem(20)};
          display: none;
          border: 1px solid transparent;
          background-color: transparent;
          color: ${theme.colors.action.lightTheme.linkDefault};
          cursor: pointer;
          font-size: ${rem(20)};
          font-weight: 400;
          line-height: ${rem(30)};
          visibility: hidden;
          ${largeUp
            && css`
              display: block;
              float: right;
            `}
        }
      }

      &.is-open {
        ${largeUp
          && css`
            z-index: ${theme.zIndex.searchMain};
            display: block;
          `}
      }

      .nmx-search-results {
        position: relative;
        z-index: ${theme.zIndex.searchMain};
        display: block;
        overflow: auto;
        width: 100%;

        background-color: ${theme.colors.neutral.grayLight30};
        ${largeUp
          && css`
            margin-bottom: ${rem(16)};
            background-color: ${theme.colors.neutral.white};
          `}

        &__zero-matches-message {
          padding: 0 ${rem(12)} ${rem(16)};
          ${largeUp
            && css`
              padding-bottom: ${rem(40)};
            `}
        }
      }

      .nmx-search-result-item {
        padding-right: ${rem(12)};
        padding-bottom: ${rem(20)};
        padding-left: ${rem(12)};
        margin-bottom: ${rem(20)};
        ${largeUp
          && css`
            padding-right: 0;
            padding-bottom: ${rem(40)};
            padding-left: 0;
            margin-bottom: ${rem(40)};
          `}

        &:not(:last-child) {
          border-bottom: 1px solid ${theme.colors.neutral.grayDark20};
        }

        a {
          &:hover {
            text-decoration: none;
            .nmx-search-result-item-content h3 {
              color: $${theme.colors.action.darkTheme.ctaBgDefault};
              text-decoration: underline;
            }
          }
        }
        &-content {
          h3 {
            font-weight: 100;
            text-decoration: underline;
            ${largeUp
              && css`
                text-decoration: none;
              `}
          }
        }
        &-eyebrow {
          margin-bottom: 0.5rem;
          color: ${theme.colors.neutral.grayDark20};
          font-size: ${rem(12)};
          font-weight: 100;
          letter-spacing: ${rem(1)};
          line-height: ${rem(16)};
          ${largeUp
            && css`
              font-size: ${rem(14)};
              line-height: ${rem(22)};
            `}
          &-category {
            font-weight: 400;
            text-transform: uppercase;
          }
        }
      }

      #nmx-search-field {
        position: relative;
        width: 100%;

        height: ${rem(50)};
        padding: ${rem(8)};
        color: ${theme.colors.neutral.grayDark20};
        font-size: ${rem(20)};
        font-weight: 300;
        line-height: ${rem(20)};
        outline: none;
        text-indent: ${rem(32)};

        ${hasValue
          && css`
            ${largeDown
              && css`
                width: 75%;
              `}
          `}

        ${largeUp
          && css`
            &::-ms-clear {
              display: none;
            }

            &::-webkit-search-decoration,
            &::-webkit-search-cancel-button,
            &::-webkit-search-results-button,
            &::-webkit-search-results-decoration {
              -webkit-appearance: none;
            }
            width: 100%;
            height: ${rem(85)};
            padding-right: ${rem(220)};
            border: 0;
            border-bottom: 1px solid ${theme.colors.neutral.gray};
            font-size: ${rem(30)};
            line-height: ${rem(30)};

            text-indent: 0;
          `}
        ${largeUp
          && css`
            padding-right: ${rem(250)};
            font-size: ${rem(35)};
            line-height: ${rem(35)};
          `}

        &::placeholder {
          color: ${theme.colors.neutral.grayDark20};
        }
        &:focus {
          border: 1px solid ${theme.colors.action.lightTheme.linkDefault}; /** TODO: allow browser to handle focus? confirm visible focus ring */
          ${largeUp
            && css`
              border-top: none;
              border-right: none;
              border-bottom-color: ${theme.colors.action.lightTheme.linkDefault}; /** TODO: allow browser to handle focus? confirm visible focus ring */
              border-left: none;
            `}
        }
      }


      #nmx-search-form-container {
        padding: 0;
      }

      #nmx-search-form-submit {
        ${largeDown
          && css`
            position: absolute;
            top: 0;
            left: ${rem(10)};
            width: ${rem(20)};
            min-width: ${rem(20)};
            height: ${rem(40)};
            min-height: ${rem(40)};
            padding-top: ${rem(15)};
            border: none;
            margin-top: ${rem(13)} !important;
            margin-left: ${rem(16)} !important;
            background: none;
            box-shadow: none;
            text-indent: ${rem(-9999)};
            overflow: hidden; /** Safari fix */
            white-space: nowrap; /** Safari fix */
            display: block; /** Safari fix */
            padding: 0; /** Safari fix */
          `}

        bottom: 0;
        margin: 0 auto;
        margin-top: ${rem(20)};
        float: none;
        &::before {
          position: absolute;
          top: ${rem(18)};
          left: 0;
          width: ${rem(17)};
          height: ${rem(30)};
          background-image: url('${basePath}/template/assets/${nmxTemplateVersion}/images/icons/utility/magnifying-glass.svg');
          background-repeat: no-repeat;
          content: ' ';
          filter: invert(25%) sepia(96%) saturate(7900%) hue-rotate(195deg)
            brightness(100%) contrast(0);
          ${largeUp
            && css`
              display: none;
            `}
        }

        ${largeUp
          && css`
            position: relative;
            bottom: ${rem(70)};
            display: block;
            margin: 0;
            float: right;
            text-transform: uppercase;
          `}
      }

      #nmx-search-toggle-close {
        z-index: ${theme.zIndex.searchMain};
        display: none;
        width: ${rem(100)};
        min-width: unset;
        height: ${rem(50)};
        min-height: ${rem(50)};
        padding-right: ${rem(16)};
        border: none;
        margin-top: ${rem(15)};
        background: none;
        color: ${theme.colors.action.lightTheme.linkDefault};
        float: right;
        font-size: ${rem(13)};
        font-weight: 400;
        line-height: ${rem(21)};

        ${hasValue
          && css`
            ${largeDown
              && css`
                display: block;
              `}
          `}

        ${largeUp
          && css`
          display: block;
          width: ${rem(50)};
          padding-right: ${rem(25)};
          background: ${theme.colors.neutral.white}
            url('${basePath}/template/assets/${nmxTemplateVersion}/images/icons/utility/close.svg')
            no-repeat ${rem(20)} 50%;
          background-size: ${rem(16)};
          text-indent: ${rem(-9999)};
        `}
      }
    `;
  }
}
`;

export default DivSearchStyled;
