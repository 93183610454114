import React from 'react';
import PropTypes from 'prop-types';

import Select from '../../../../components/forms/Select';
import { goals } from '../../utilities/inPageLeadFormData';

export const GoalSelectInputComponent = React.forwardRef(({
  hasError,
  helperText,
  onBlur,
  onChange,
  themeType,
  value,
}, ref) => (
  <Select
    hasError={hasError}
    helperText={helperText}
    id='in-page-lead-form-goal-select'
    labelText="What's your biggest financial goal?"
    name='goal'
    onBlur={onBlur}
    onChange={onChange}
    options={goals}
    ref={ref}
    required={true}
    themeType={themeType}
    value={value} />
));

GoalSelectInputComponent.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default GoalSelectInputComponent;
