import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import Typography from '../../../foundations/Typography';

import { defaultLabelStyles } from '../Label/styles';

export const TypographyStyled = styled(Typography)`
  display: block;
  padding-bottom: ${rem(8)};
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  font-weight: 400;
`;

// ====================
// checkbox variant styles
// ====================

export const ToggleSwitchStyledCheckbox = styled.div`
  ${defaultLabelStyles}
`;

export const SpanStyledRequiredFieldAsterisk = styled.span`
  ${({ hasError, theme, themeType }) => {
    let thisColor = theme.colors.forms.lightTheme.inputFieldText;
    if (themeType === 'lightTheme' && hasError) {
      thisColor = theme.colors.forms.lightTheme.inputFieldError;
    }
    if (themeType === 'darkTheme') {
      thisColor = theme.colors.forms.darkTheme.inputFieldText;
      if (hasError) {
        thisColor = theme.colors.forms.darkTheme.inputFieldError;
      }
    }
    return css`
      color: ${thisColor};
    `;
  }}
`;

// ====================
// button variant styles
// ====================
export const ToggleSwitchStyledButton = styled.div`
  ${() => css` /** themeType */
    /** TODO: build in darkTheme */
  `};
`;

export const ButtonStyledToggleSwitch = styled.button`
  ${({ ariaPressed, theme, themeType }) => {
    let thisButtonColor = theme.colors.neutral.gray;
    let thisPressedButtonColor = theme.colors.blue.blueLight20;

    if (themeType === 'darkTheme') {
      thisButtonColor = theme.colors.blue.blueLight30;
      thisPressedButtonColor = theme.colors.sky.skyDark10;
    }

    return css`
      display: block;
      box-sizing: border-box;
      border: none;
      color: inherit;
      background: none;
      font: inherit;
      line-height: inherit;
      text-align: left;
      padding: ${rem(6)} 0 ${rem(6)} ${rem(70)};
      position: relative;
      &::before,
      &::after {
        content: '';
        position: absolute;
        height: ${rem(22)};
        transition: all 0.25s ease;
      }
      &::before {
        left: 0;
        top: ${rem(3)};
        width: ${rem(47)};
        border: ${rem(3)} solid ${thisButtonColor};
        background: ${thisButtonColor};
        border-radius: ${rem(18)};

        ${ariaPressed
        && `
          background-color: ${thisPressedButtonColor};
          border-color: ${thisPressedButtonColor};
        `}
      }
      &::after {
        left: 0;
        top: ${rem(3)};
        background-color: ${theme.colors.neutral.white};
        background-position: center center;
        border-radius: 50%;
        width: ${rem(22)};
        border: ${rem(2)} solid ${thisButtonColor};
        background-size: 30%;
        background-repeat: no-repeat;
        background-position: center center;

        ${ariaPressed
        && `
          left: ${rem(25)};
          border-color: ${thisPressedButtonColor};
          color: ${thisPressedButtonColor};
        `}
      }
    `;
  }};
`;
