import React from 'react';
import PropTypes from 'prop-types';

import {
  BaseOrderedList,
  BaseUnorderedList,
} from './styles';

const ListComponent = ({
  children,
  inline,
  orderedListType,
  reduced,
  variant,
  ...rest
}) => (
  variant === 'unordered' // TODO: this can probably be optimized
    ? (<BaseUnorderedList
      reduced={reduced}
      inline={inline}
      variant={variant}
      {...rest}>
      {children}
    </BaseUnorderedList>)
    : (<BaseOrderedList
      reduced={reduced}
      inline={inline}
      orderedListType={orderedListType}
      variant={variant}
      {...rest}>
      {children}
    </BaseOrderedList>)
);

ListComponent.propTypes = {
  /** li elements */
  children: PropTypes.node.isRequired,
  /** optional boolean to toggle inline list */
  inline: PropTypes.bool,
  // orderedListControl: PropTypes.number, // TODO: add in ordered list control
  /** type of ordered list item marker: number, uppercase letter, lowercase letter, uppercase Roman numeral, lowercase Roman numeral */
  orderedListType: PropTypes.oneOf(['decimal', 'lowerAlpha', 'lowerRoman', 'upperAlpha', 'upperRoman']), // TODO: add all OL list types? (https://www.w3schools.com/tags/att_ol_type.asp)
  /** optional reduced boolean removes all user agent list styles */
  reduced: PropTypes.bool,
  /** allows developer to deliver ordered or unordered list markup */
  variant: PropTypes.oneOf(['ordered', 'unordered']),
};

ListComponent.defaultProps = {
  inline: false,
  orderedListType: 'decimal',
  reduced: false,
  variant: 'unordered',
};

export default ListComponent;
