import React from 'react';
import PropTypes from 'prop-types';
// components
import CustomFooter from './variants/Custom';
import NmComFooter from './variants/NmCom';
import SareFooter from './variants/Sare';

export const FooterComponent = ({
  appendFooter,
  config,
  customDisclaimers,
  customFooter,
  disableDisclaimerTopBorder,
  disclaimerFootnotes,
  footerVariant,
  isAmp,
  legalCopy,
  stateFilingComplianceNumber,
}) => {
  const renderFooterVariant = () => {
    // render footer variant
    switch (footerVariant) {
      case 'custom':
        // Custom Footer
        return (
          <CustomFooter
            customFooter={customFooter}
          />
        );
        // break;
      case 'nmCom':
        // NMCom Footer
        return (
          <NmComFooter
            appendFooter={appendFooter}
            config={config}
            customDisclaimers={customDisclaimers}
            disableDisclaimerTopBorder={disableDisclaimerTopBorder}
            disclaimerFootnotes={disclaimerFootnotes}
            isAmp={isAmp}
            legalCopy={legalCopy}
            stateFilingComplianceNumber={stateFilingComplianceNumber}
          />
        );
        // break;
      case 'sare':
        // SARE Footer
        return (
          <SareFooter
            customDisclaimers={customDisclaimers}
            disableDisclaimerTopBorder={disableDisclaimerTopBorder}
            disclaimerFootnotes={disclaimerFootnotes}
            isAmp={isAmp}
            stateFilingComplianceNumber={stateFilingComplianceNumber}
          />
        );
        // break;
      default:
        return <></>;
        // break;
    }
  };

  return (
    <>
      {renderFooterVariant()}
    </>
  );
};

FooterComponent.propTypes = {
  /** Additional footer verbiage, this was pulled in from nmx-static // TODO: still being used? */
  appendFooter: PropTypes.any,
  /** Config vars used in footer */
  config: PropTypes.shape({
    basePath: PropTypes.string.isRequired,
    nmxTemplateVersion: PropTypes.string.isRequired,
  }).isRequired,
  /** any page-specific, non-ordered footnotes */
  customDisclaimers: PropTypes.any,
  /** JSX customFooter markup, must use footerVariant='custom' for this content to render */
  customFooter: PropTypes.shape({
    /** Required customFooter content */
    footerContent: PropTypes.node.isRequired,
    /** Required field to satisfy HTML5 accessibility and SEO requirements */
    footerHeadingAssistiveText: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]).isRequired,
  }),
  /** optional boolean to remove top border (when page last section has a background color other than white) */
  disableDisclaimerTopBorder: PropTypes.bool,
  /** page-specific, ordered footnotes used in conjunction with superscript numbering on the page */
  disclaimerFootnotes: PropTypes.any,
  /** optional footer variant prop to select which specific (and different) footer to render */
  footerVariant: PropTypes.oneOf(['custom', 'nmCom', 'sare']),
  /** optional isAmp boolean */
  isAmp: PropTypes.bool,
  /** legal copy in reference to footer verbiage (below disclaimer) */
  legalCopy: PropTypes.any,
  /** optional state filing compliance number for pages that have to legally display one */
  stateFilingComplianceNumber: PropTypes.any,
};

FooterComponent.defaultProps = {
  disableDisclaimerTopBorder: false,
  footerVariant: 'nmCom',
  isAmp: false,
};

export default FooterComponent;
