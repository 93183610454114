import React from 'react';
import PropTypes from 'prop-types';

import TextField from '../../../../components/forms/TextField';

export const ZipInputComponent = React.forwardRef(({
  hasError,
  helperText,
  onBlur,
  onChange,
  themeType,
  value,
}, ref) => (
  <TextField
    hasError={hasError}
    helperText={helperText}
    id='in-page-lead-form-zip-input'
    labelText='U.S. ZIP code'
    maxLength={5}
    name='zip'
    onBlur={onBlur}
    onChange={onChange}
    ref={ref}
    required={true}
    themeType={themeType}
    value={value} />
));

ZipInputComponent.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default ZipInputComponent;
