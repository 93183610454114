import React, { useState } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import classnames from 'classnames';
// native components
import HelperTextLabel from '../HelperTextLabel';
// styles
import {
  DivStyledRadioGroup,
  DivStyledVisualPickerContainer,
  DivStyledVisualPickerItemContainer,
  DivStyledVisualPickerOption,
  DivStyledVisualPickerOptionContainer,
  LabelStyledRadioLabelGroup,
  LabelStyledVisualPickerOptionText,
  SpanStyledRadioLabel,
  SpanStyledRequiredFieldAsterisk,
  SpanStyledVisualPickerOptionText,
  TypographyStyled,
} from './styles';

export const RadioGroupComponent = ({
  className,
  disabled,
  hasError,
  helperText,
  id,
  labelText,
  name,
  onChange,
  options,
  radioGroupClassName,
  required,
  stackOptions,
  tabIndex,
  themeType,
  variant,
}) => {
  const selectedOption = find(options, { selected: true });
  const [selectedValue, setSelectedValue] = useState((selectedOption && selectedOption.value) || false);

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
    onChange(e);
  };

  if (variant !== 'visualPicker') {
    return (
      <DivStyledRadioGroup
        className={classnames(className, 'nmx-form__input')}
        role='radiogroup'>
        <TypographyStyled
          component='label'
          className='nmx-label'
          themeType={themeType}>
          {labelText}
          {required && (
            <SpanStyledRequiredFieldAsterisk
              className='nmx-label__asterisk'
              hasError={hasError}
              themeType={themeType}>
              &#42;
            </SpanStyledRequiredFieldAsterisk>
          )}
        </TypographyStyled>
        <div className={classnames('nmx-radio-label-group', radioGroupClassName)}>
          {
            options.map((radioValue, index) => (
              <LabelStyledRadioLabelGroup
                className='nmx-radio-label-group__label'
                aria-label={radioValue.label}
                disabled={disabled}
                index={index}
                key={radioValue.value}
                stackOptions={stackOptions}
                themeType={themeType}>
                <SpanStyledRadioLabel className='nmx-form__radio-button-label'>
                  <input
                    className='nmx-radio-label-group__input'
                    aria-checked={radioValue.value === selectedValue}
                    defaultChecked={radioValue.value === selectedValue}
                    disabled={disabled}
                    id={`${id}-${radioValue.value}`}
                    name={name}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    tabIndex={tabIndex}
                    type='radio'
                    value={radioValue.value}
                  />
                  {radioValue.label}
                </SpanStyledRadioLabel>
              </LabelStyledRadioLabelGroup>
            ))
          }
          <HelperTextLabel
            className='nmx-radio-label-group__helper-text'
            disabled={disabled}
            hasError={hasError}
            helperText={helperText}
            themeType={themeType}
          />
        </div>
      </DivStyledRadioGroup>
    );
  }
  return (
    <DivStyledVisualPickerContainer
      role='radiogroup'
      className={classnames(className, 'nmx-visual-picker')}>
      <TypographyStyled
        className='nmx-visual-picker__group-label'
        themeType={themeType}>
        {labelText}
        {required && (
          <span>&#42;</span>
        )}
      </TypographyStyled>
      <DivStyledVisualPickerItemContainer
        stackOptions={stackOptions}
        className={classnames('nmx-radio-label-group', radioGroupClassName)}>
        {
          options.map((radioValue, index) => (
            <DivStyledVisualPickerOption
              className='nmx-visual-picker__option'
              aria-label={radioValue.label}
              index={index}
              key={`${radioValue.value}-${index + 1}`}
              stackOptions={stackOptions}>
              <input
                className='nmx-visual-picker__input'
                aria-checked={radioValue.value === selectedValue}
                defaultChecked={radioValue.value === selectedValue}
                id={`${id}-${radioValue.value}`}
                name={name}
                onChange={(e) => { handleChange(e); }}
                type='radio'
                value={radioValue.value}
              // readOnly // use to style a visual picker without the native radio button
              />
              <DivStyledVisualPickerOptionContainer
                className='nmx-visual-picker__option-container'
                themeType={themeType}>
                <LabelStyledVisualPickerOptionText
                  htmlFor={`${id}-${radioValue.value}`}
                  className='nmx-visual-picker__label'>
                  <SpanStyledVisualPickerOptionText className='nmx-visual-picker__option-text'>
                    {radioValue.label}
                  </SpanStyledVisualPickerOptionText>
                </LabelStyledVisualPickerOptionText>
              </DivStyledVisualPickerOptionContainer>
            </DivStyledVisualPickerOption>
          ))
        }
      </DivStyledVisualPickerItemContainer>
      <HelperTextLabel
        className='nmx-visual-picker__helper-text'
        disabled={disabled}
        hasError={hasError}
        helperText={helperText}
        themeType={themeType}
      />
    </DivStyledVisualPickerContainer>
  );
};

RadioGroupComponent.propTypes = {
  /** optional additional className */
  className: PropTypes.string,
  /** Disabled attribute */
  disabled: PropTypes.bool,
  /** Is this input in an error state */
  hasError: PropTypes.bool,
  /** Optional helperText positioned below input field */
  helperText: PropTypes.string,
  /** Required id attribute */
  id: PropTypes.string.isRequired,
  /** Name applied to input or textarea */
  name: PropTypes.string.isRequired,
  /** The labelText placed on the text field */
  labelText: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  /** Optional function when radioGroup selected option changes */
  onChange: PropTypes.func,
  /** Array of radio options for group, each option contains label and value */
  options: PropTypes.array.isRequired,
  /** optional additional className assigned to RadioGroup */
  radioGroupClassName: PropTypes.string,
  /** input is required */
  required: PropTypes.bool,
  /** optional boolean to remove left margin and display: block options in situations of lengthy labels  */
  stackOptions: PropTypes.bool,
  /** Optional tabIndex assigned to field */
  tabIndex: PropTypes.number,
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']),
  /** distinguish between native and visual picker variants */
  variant: PropTypes.oneOf(['native', 'visualPicker']).isRequired,
};

RadioGroupComponent.defaultProps = {
  disabled: false,
  hasError: false,
  helperText: '',
  onChange: () => {},
  options: [],
  required: false,
  stackOptions: false,
  tabIndex: 0,
  themeType: 'lightTheme',
  variant: 'native',
};

export default RadioGroupComponent;
