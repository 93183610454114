import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

// template components
import Button from '../../../components/Button';
import { Col } from '../../../foundations/Grid';
import Link from '../../../components/Link';
import Typography from '../../../foundations/Typography';

export const ColLoginNotificationStyled = styled(Col)`
  // Notification is set to default lightTheme // TODO: check with Creative on a darkTheme variant
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    padding: ${rem(16)};
    border-left: ${rem(5)} solid ${theme.colors.text.lightTheme.error};
    margin-bottom: ${rem(16)};
    background-color: ${theme.colors.background.lightTheme.lightB};

    .nmx-icon {
      .icon-fill {
        fill: ${theme.colors.text.lightTheme.error};
      }
      .icon-color {
        fill: ${theme.colors.neutral.white};
      }
    }
   `}
`;

export const LoginNotificationTypographyStyled = styled(Typography)`
  flex: 1 0 auto;
`;

export const ColFormStyled = styled(Col)`
  position: relative;
  margin-bottom: ${rem(16)}; /** TODO: build in variant for Form Col? */
  .nmx-label {
    display: block;
  }
`;

export const HideShowPasswordButtonStyled = styled.button`
  ${({ theme, themeType }) => {
    const mediumUp = theme.mediumUp();
    const thisIconFill = themeType === 'darkTheme' ? theme.colors.neutral.white : theme.colors.blue.blue;
    const thisRight = rem(20);

    return css`
      position: absolute;
      right: ${thisRight};
      margin-top: ${mediumUp ? rem(-35) : rem(-32)};
      background: none;
      border: none;
      .nmx-icon {
        .icon-fill {
          fill: ${thisIconFill};
        }
        .icon-stroke {
          stroke: ${thisIconFill};
        }
      }
    `;
  }}
`;

export const TypographyStyled = styled(Typography)`
  font-size: ${rem(14)};
  line-height: ${rem(22)};
`;

export const LinkStyled = styled(Link)`
  font-size: ${rem(14)};
  line-height: ${rem(22)};
  text-align: center;
`;

export const ButtonLoginStyled = styled(Button)`
  && {
    display: block;
    margin: 0 auto ${rem(16)};
  }
`;
