import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
// template components
import { Col, Row } from '../../../foundations/Grid';
import Link from '../../../components/Link';
import SectionContainer from '../../../components/SectionContainer';
import Typography from '../../../foundations/Typography';

export const ColStyledLeft = styled(Col)`
  ${({ theme }) => {
    const largeUp = theme.largeUp();
    return css`
      ${largeUp && `
        display: flex;
        flex: 0 0 auto;
        padding: 0;
      `}
    `;
  }
}`;

export const ColStyledRight = styled(Col)`
  ${({ theme }) => {
    const largeUp = theme.largeUp();
    return css`
      ${largeUp && `
        display: flex;
        flex: 0 1 auto;
        flex-wrap: wrap;
        padding: 0;
      `}
    `;
  }
}`;

export const LinkStyled = styled(Link)`
  ${({ theme }) => {
    const largeUp = theme.largeUp();
    const thisHorizontalMargin = rem(15);
    return css`
      margin-right: ${thisHorizontalMargin};
      margin-left: ${largeUp ? thisHorizontalMargin : 0};
    `;
  }
}`;

export const LIStyled = styled.li`
  ${({ theme }) => {
    const largeUp = theme.largeUp();
    return css`
      display: ${largeUp ? 'inline' : 'block'};
    `;
  }
}`;

export const RowStyled = styled(Row)`
  ${({ theme }) => {
    const largeUp = theme.largeUp();
    return css`
      ${largeUp && `
        display: flex;
        flex-wrap: unset;
        flex-direction: row;
      `}
    `;
  }
}`;

export const SectionContainerStyled = styled(SectionContainer)`
  ${({ theme, disableTopPadding }) => {
    const mediumUp = theme.mediumUp();
    let thisTopPadding = mediumUp ? rem(48) : rem(32);

    if (disableTopPadding) {
      thisTopPadding = 0;
    }
    return css`
      padding-top: ${thisTopPadding};
    `;
  }
}`;

export const TypographyStyledListItem = styled(Typography)`
  ${({ theme, lastItem, themeType }) => {
    const largeUp = theme.largeUp();
    return css`
      padding-bottom: 0;
      ${largeUp && 'white-space: nowrap;'}
      display: inline-block;
      ::after {
        ${(!lastItem && largeUp) && 'content: \' | \';'};
        ${themeType === 'darkTheme' && `color: ${theme.colors.text.darkTheme.default}`};
      }
    `;
  }
}`;

export const ULStyled = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;
