import React, { useState } from 'react';
import PropTypes from 'prop-types';
// components
import Typography from '../../foundations/Typography';
// icons
import DrawerCaretFlippedIcon from '../../foundations/Icon/icons/utility/DrawerCaretFlipped';
import DrawerCaretIcon from '../../foundations/Icon/icons/utility/DrawerCaret';
// styles
import {
  ButtonStyled,
  DdStyled,
  DivStyled,
  DtStyled,
  Styles,
  TypographyStyled,
} from './styles';

export const AccordionComponent = ({
  addFAQPageSchemaItemType,
  backgroundVariant,
  branding,
  className,
  firstItemOpen,
  id,
  isFAQ,
  items,
  themeType,
  toggleCloseIcon,
  toggleOpenIcon,
  variant,
}) => {
  const styles = Styles();
  const [accordionItems, setAccordionItems] = useState(items.map((i, index) => ({
    ...i,
    isOpen: !!(firstItemOpen && index === 0),
  }))); // keeps track of open Accordion indeces

  const ToggleOpenIconComponent = toggleOpenIcon;
  const ToggleCloseIconComponent = toggleCloseIcon;
  const accordionItemTitleTypographyVariant = variant === 'primary' ? 'h4' : 'h3';
  const iconDimension = variant === 'primary' ? 15 : 30; // 30px height for FAQ +/- collapse/expand icon

  const iconProps = {
    style: styles.IconToggleStyled,
    height: iconDimension,
    width: iconDimension,
    className: 'accordion-toggle-icon',
  };

  return (
    <dl
      className={className}
      id={id}
      itemScope={isFAQ && addFAQPageSchemaItemType}
      // conditionally appending schema attributes for FAQ instances
      {...(isFAQ && addFAQPageSchemaItemType) && { itemType: 'https://schema.org/FAQPage' }}>
      {accordionItems.map((item, index) => (
        <DivStyled
          backgroundVariant={backgroundVariant}
          branding={branding}
          id={`container-${item.id}`}
          isFAQ={isFAQ}
          itemScope={isFAQ}
          key={`${item.title}-${index + 1}`}
          themeType={themeType}
          variant={variant}
          // conditionally appending schema attributes for FAQ instances
          {...isFAQ && {
            itemProp: 'mainEntity',
            itemType: 'https://schema.org/Question',
          }}>
          <DtStyled id={`dt-container-${item.id}`}>
            <ButtonStyled
              aria-expanded={item.isOpen}
              className={item.isOpen && 'is-accordion-item-expanded'}
              id={`dt-button-${item.id}`}
              isFAQ={isFAQ}
              isOpen={item.isOpen}
              onClick={() => {
                setAccordionItems(accordionItems.map((i, accordionIndex) => ({
                  ...i,
                  isOpen: index === accordionIndex ? !i.isOpen : i.isOpen,
                })));
              }}
              variant={variant}>
              {item.icon && <item.icon style={styles.TitleIconStyled} />}
              <TypographyStyled
                align='left'
                className='accordion-item-title'
                component='h3'
                disableBottomPadding
                id={`dt-title-${item.id}`}
                leftPadding={!!item.icon}
                variant={accordionItemTitleTypographyVariant}
                // conditionally appending schema attributes for FAQ instances
                {...isFAQ && { itemProp: 'name' }}>
                {item.title}
              </TypographyStyled>
              {item.isOpen ? (
                <ToggleCloseIconComponent {...iconProps}/>
              ) : (
                <ToggleOpenIconComponent {...iconProps}/>
              )}
            </ButtonStyled>
          </DtStyled>
          <DdStyled
            id={`dd-container-${item.id}`}
            isFAQ={isFAQ}
            itemScope={isFAQ}
            style={{ display: item.isOpen ? 'block' : 'none' }}
            variant={variant}
            // conditionally appending schema attributes for FAQ instances
            {...isFAQ && {
              itemProp: 'acceptedAnswer',
              itemType: 'https://schema.org/Answer',
            }}>
            <Typography
              as={item.contentAs || 'p'}
              disableBottomPadding
              // conditionally appending schema attributes for FAQ instances
              {...isFAQ && { itemProp: 'text' }}>
              {item.content}
            </Typography>
          </DdStyled>
        </DivStyled>
      ))}
    </dl>
  );
};

AccordionComponent.propTypes = {
  /** backgroundVariant allows accordion to be different background color based on themeType */
  backgroundVariant: PropTypes.oneOf(['darkDefault', 'lightA', 'lightB', 'lightC']),
  /** used in conjunction with isFAQ, allows option to remove FAQPage schema on parent element. NOTE: "itemScope" and "itemType: 'https://schema.org/FAQPage'" will need to be added somewhere on the page in order for FAQ schema to render in rich snippets console */
  /** sets overall branding of component or module, default='nm' */
  branding: PropTypes.oneOf(['nm', 'pcg']),
  addFAQPageSchemaItemType: PropTypes.bool,
  /** optional additional className */
  className: PropTypes.string,
  /** Optional default open the first accordion item */
  firstItemOpen: PropTypes.bool,
  /** accordion id */
  id: PropTypes.string.isRequired,
  /** boolean used to flag the insertion of schema microdata into the markup */
  isFAQ: PropTypes.bool,
  /** Array of accoladed to display in the section */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      /** Icon to display in front of title */
      icon: PropTypes.any,
      /** The 'id' of the accolade wanting to display */
      title: PropTypes.any.isRequired,
      /** The sup number corresponding with the footer reference, Required for descriptionListData needing source to cite */
      content: PropTypes.any.isRequired,
    }),
  ).isRequired,
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme', 'nmx-pcg']),
  /** The icon displayed to toggle a drawer list item */
  toggleCloseIcon: PropTypes.any,
  /** The icon displayed to toggle a drawer list item */
  toggleOpenIcon: PropTypes.any,
  /** default primary variant is white background, secondary has the gray background, used in FAQs */
  variant: PropTypes.oneOf(['primary', 'secondary']), // TODO: convert isFAQ to variant
};

AccordionComponent.defaultProps = {
  addFAQPageSchemaItemType: true,
  backgroundVariant: 'lightC',
  branding: 'nm',
  firstItemOpen: false,
  isFAQ: false,
  themeType: 'lightTheme',
  titleTypographyProps: {},
  toggleCloseIcon: DrawerCaretFlippedIcon,
  toggleOpenIcon: DrawerCaretIcon,
  variant: 'primary',
};

export default AccordionComponent;
