import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import { Col } from '../../foundations/Grid';
import QuotationIcon from '../../foundations/Icon/icons/utility/QuotationMarkDoubleCurved';
import Typography from '../../foundations/Typography';

export const ColStyledTestimonialContainer = styled(Col)`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    return css`
      .nmx-slider-slide {
        max-width: ${mediumUp ? '66.66666666666666%' : '100%'}; /** per comp, have this at 8 cols */
        flex: ${mediumUp ? '0 66.66666666666666%' : '0 100%'}; /** per comp, have this at 8 cols */
      }
    `;
  }}
`;

export const FigureStyled = styled.figure`
  ${({ theme }) => css`
    margin: 0;
    .icon-fill {
      fill: ${theme.colors.brand.nm.secondary.sky};
    }
    @media print {
      .icon-fill {
        fill: ${theme.colors.neutral.black};
      }
    }
  `}
`;

export const BlockquoteStyled = styled.blockquote`
  && {
    margin-left: 0;
    margin-right: 0;
    padding-bottom: ${rem(10)};
  }
`;

export const FigcaptionStyled = styled.figcaption`
  ${({ figcaptionCentered }) => css`
    text-align: ${figcaptionCentered ? 'center' : 'left'};
  `}
`;

export const TypographyStyledDescription = styled(Typography)`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    return css`
      &&& {
        font-size: ${mediumUp ? rem(26) : rem(18)};
        line-height: ${mediumUp ? rem(40) : rem(24)};
        font-weight: 300;
        font-style: italic;
      }
    `;
  }}
`;

export const TypographyStyledCitation = styled(Typography)`
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  font-style: normal;
  font-weight: 400;
`;

export const IconStyledQuotation = styled(QuotationIcon)`
  ${({ theme }) => css`
    display: block;
    margin: 0 auto ${rem(8)};
    .icon-fill {
      fill: ${theme.colors.brand.nm.secondary.sky};
    }
    @media print {
      .icon-fill {
        fill: ${theme.colors.neutral.black};
      }
    }
  `}
`;

export const ColStyledDisclosure = styled(Col)`
  margin-bottom: ${rem(-34)};
  padding-top: ${rem(52)};
`;
