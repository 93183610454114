import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  DivStyledBaseCol,
  DivStyledBaseContainer,
  DivStyledBaseRow,
} from './styles';

/* *****************
  CONTAINER
* **************** */

// TODO: Adjust logic so the nmx-container modifier only gets appended when needed
export const Container = React.forwardRef(({
  id,
  children,
  className,
  styles,
}, ref) => (
  <DivStyledBaseContainer
    id={id}
    className={classnames('nmx-container', className)}
    ref={ref}
    style={styles || {}}
  >
    {children}
  </DivStyledBaseContainer>
));

Container.propTypes = {
  /** stuff inside Container */
  children: PropTypes.node.isRequired,
  /** Optional custom class name */
  className: PropTypes.string,
  /** Optional Container id */
  id: PropTypes.string,
  /** Optional custom styles */
  styles: PropTypes.object,
};

Container.defaultProps = {
  children: '',
  className: null,
  styles: null,
};

/* *****************
  ROW
* **************** */

export const Row = React.forwardRef(({
  id,
  align,
  children,
  className,
  reverse,
  styles,
  ...rest
}, ref) => (
  <DivStyledBaseRow
    id={id}
    align={align}
    className={classnames('nmx-row', className)}
    ref={ref}
    reverse={reverse}
    style={styles || {}}
    {...rest}
  >
    {children}
  </DivStyledBaseRow>
));

Row.propTypes = {
  /** How to align the grid cols in the row */
  align: PropTypes.oneOf(['center', 'left', 'right']),
  /** Stuff inside the Row */
  children: PropTypes.node.isRequired,
  /** Optional custom class name */
  className: PropTypes.string,
  /** Optional Container id */
  id: PropTypes.string,
  /** Reverse the grid cols in this row */
  reverse: PropTypes.bool,
  /** Optional custom styles */
  styles: PropTypes.object,
};

Row.defaultProps = {
  align: null,
  children: '',
  className: null,
  reverse: false,
  styles: null,
};

/* *****************
  COL
* **************** */

export const Col = React.forwardRef(({
  id,
  align,
  children,
  className,
  nested,
  nestedChild,
  paddingLeft,
  paddingRight,
  styles,
  // breakpoint sizes
  xsmall,
  xsmallOffset,
  small,
  smallOffset,
  medium,
  mediumOffset,
  large,
  largeOffset,
  xlarge,
  xlargeOffset,
  xxlarge,
  xxlargeOffset,
  ...rest
}, ref) => (
  <DivStyledBaseCol
    id={id}
    align={align}
    className={classnames('nmx-col', className)}
    nested={nested}
    nestedChild={nestedChild}
    paddingLeft={paddingLeft}
    paddingRight={paddingRight}
    ref={ref}
    style={styles || {}}
    // breakpoint sizes
    xsmall={xsmall}
    xsmallOffset={xsmallOffset}
    small={small}
    smallOffset={smallOffset}
    medium={medium}
    mediumOffset={mediumOffset}
    large={large}
    largeOffset={largeOffset}
    xlarge={xlarge}
    xlargeOffset={xlargeOffset}
    xxlarge={xxlarge}
    xxlargeOffset={xxlargeOffset}
    {...rest}
  >
    {children}
  </DivStyledBaseCol>
));

Col.propTypes = {
  /** horizontal alignment of the grid cols in the row */
  align: PropTypes.oneOf(['center', 'left', 'right']),
  /** Inner Contents of Col */
  children: PropTypes.node.isRequired,
  /** Optional Class Name */
  className: PropTypes.string,
  /** Optional Container id */
  id: PropTypes.string,
  /** Does Column have a nested Row/Col set? */
  nested: PropTypes.bool,
  /** is a nested child of a parent Row/Col set */
  nestedChild: PropTypes.bool, // TODO: remove?
  /** Optional paddingLeft prop that adds 2rem */
  paddingLeft: PropTypes.bool,
  /** Optional paddingRight prop that adds 2rem */
  paddingRight: PropTypes.bool,
  /** Optional custom styles */
  styles: PropTypes.object,
  /** number of grid columns (out of 12 total) at the xsmall (0) breakpoint */
  xsmall: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  /** number of grid columns (out of 12 total) that are right-offset at the xsmall (0) breakpoint */
  xsmallOffset: PropTypes.number,
  /** number of grid columns (out of 12 total) at the small (480) breakpoint */
  small: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  /** number of grid columns (out of 12 total) that are right-offset at the small (480) breakpoint */
  smallOffset: PropTypes.number,
  /** number of grid columns (out of 12 total) at the medium (768) breakpoint */
  medium: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  /** number of grid columns (out of 12 total) that are right-offset at the medium (768) breakpoint */
  mediumOffset: PropTypes.number,
  /** number of grid columns (out of 12 total) at the large (1024) breakpoint */
  large: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  /** number of grid columns (out of 12 total) that are right-offset at the large (1024) breakpoint */
  largeOffset: PropTypes.number,
  /** number of grid columns (out of 12 total) at the xlarge (1440) breakpoint */
  xlarge: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  /** number of grid columns (out of 12 total) that are right-offset at the xlarge (1440) breakpoint */
  xlargeOffset: PropTypes.number,
  /** number of grid columns (out of 12 total) at the xxlarge (1920) breakpoint */
  xxlarge: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  /** number of grid columns (out of 12 total) that are right-offset at the xxlarge (1920) breakpoint */
  xxlargeOffset: PropTypes.number,
};

Col.defaultProps = {
  children: '',
  className: null,
  nested: false,
  nestedChild: false,
  styles: null,
  // breakpoint sizes
  xsmall: 12,
  xsmallOffset: null,
  small: null,
  smallOffset: null,
  medium: null,
  mediumOffset: null,
  large: null,
  largeOffset: null,
  xlarge: null,
  xlargeOffset: null,
  xxlarge: null,
  xxlargeOffset: null,
};
