import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
// components
import Button from '../../../../components/Button';
import { Col } from '../../../../foundations/Grid';

export const ButtonStyled = styled(Button)`
  ${({ theme }) => css`
    && {
      min-width: unset;
      min-height: unset;
      padding: ${rem(32)} 0;
      color: ${theme.colors.neutral.grayDark20};
      font-size: ${rem(14)};
      line-height: ${rem(22)};
      text-align: left;
      :hover {
        color: ${theme.colors.action.lightTheme.linkHover};
        text-decoration: underline;
        text-underline-offset: ${rem(5)};
      }
    }
  `}
`;

export const ColStyled = styled(Col)`
  ${({ theme }) => css`
    /* style overrides to remove OneTrust button styles, set redundantly as a fallback */
    #ot-sdk-btn.ot-sdk-show-settings.nmx-override {
      height: unset;
      padding: ${rem(32)} 0;
      font-size: ${rem(14)};
      line-height: ${rem(22)};
      color: ${theme.colors.neutral.grayDark20};
      white-space: unset;
      word-wrap: unset;
      border: unset;
      cursor: unset;
      transition: unset;
      :hover {
        && {
          color: ${theme.colors.action.lightTheme.linkHover};
          background-color: unset;
        }
      }
    }
  `};
`;
