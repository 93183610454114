export default (e, elId, scrollConfig) => {
  e.preventDefault();

  const el = document.getElementById(elId);
  if (scrollConfig?.offset) {
    Object.assign(scrollConfig, {
      behavior: 'smooth',
      top: (window.scrollY + el.getBoundingClientRect().top - scrollConfig.offset),
    });
    window.scroll(scrollConfig);
  } else {
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  }

  setTimeout(() => {
    const focusableEls = Array.prototype.slice.call(el.querySelectorAll('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]):not(.no-query), [tabindex="0"]'));
    const firstFocusableEl = focusableEls[0];
    if (firstFocusableEl) {
      firstFocusableEl.focus({ preventScroll: true });
    }
  }, 500);
};
