import rem from 'polished/lib/helpers/rem';
import { useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
// helpers
import { getSectionBackgroundColor } from '../../components/SectionContainer/styles';
// components
import Typography from '../../foundations/Typography';

export const ButtonStyled = styled.button`
  ${({ theme, variant, isOpen, isFAQ }) => {
    let thisPadding = `${rem(22)} 0`;

    if (variant === 'secondary') {
      thisPadding = rem(22);
      if (isFAQ) {
        thisPadding = rem(24);
        if (isOpen === 'true') {
          thisPadding = `${rem(24)} ${rem(24)} ${rem(16)}`;
        }
      }
    }

    return css`
      display: flex;
      position: relative;
      width: 100%;
      padding: ${thisPadding};
      background: inherit;
      border: 0;
      justify-content: flex-start;
      align-items: center;
      color: ${theme.colors.neutral.grayDark20};

      &.is-accordion-item-expanded {
        ${variant === 'primary'
          && `
          color: ${theme.colors.action.lightTheme.ctaBgDefault};
          .accordion-toggle-icon {
            color: ${theme.colors.action.lightTheme.ctaBgDefault};
          }
        `}
      }

      &:hover {
        .accordion-item-title {
          color: ${theme.colors.action.lightTheme.ctaBgHover};
        }
        .accordion-toggle-icon {
          .icon-fill {
            fill: ${theme.colors.action.lightTheme.ctaBgHover};
          }
        }
      }
    `;
  }}
`;

export const DdStyled = styled.dd`
  ${({ theme, variant, isFAQ }) => {
    const mediumUp = theme.mediumUp();
    const isFaqAndVariantSecondary = variant === 'secondary' && isFAQ;

    return css`
      margin: 0;
      padding-bottom: ${isFaqAndVariantSecondary ? rem(24) : rem(22)};
      /** Secondary: 70 = 24 (button padding) + 30 (icon width) + 16 left-margin */
      /** right padding only active for FAQ (for now) */
      ${variant === 'secondary' && `
        padding-left: ${rem(22)};
        padding-right: ${rem(22)};
      `}

      ${isFaqAndVariantSecondary && `
        padding-left: ${rem(24)};
        padding-right: ${mediumUp ? rem(70) : rem(24)};
      `}
    `;
  }
}`;

export const DivStyled = styled.div`
  ${({ backgroundVariant, branding, isFAQ, theme, themeType, variant }) => {
    const thisBorderStyle = `${rem(1)} solid ${theme.colors.neutral.grayLight10}`;
    return css`
    border-bottom: ${thisBorderStyle};
    ${isFAQ && `
      :first-of-type {
        ${thisBorderStyle}
      }
    `};

    ${variant === 'secondary' && `
      background-color: ${getSectionBackgroundColor(backgroundVariant, branding, theme, themeType)};
      margin-bottom: ${rem(16)};
      border: 0;
    `}
  `;
  }
}`;

export const DtStyled = styled.dt`
  width: 100%;
`;

/** Creates inline styles (to override stock Component Library styles) */
export const Styles = () => {
  const theme = useContext(ThemeContext);
  const mediumUp = theme.mediumUp();
  return {
    TitleIconStyled: {
      flexShrink: 0,
      maxWidth: rem(28),
      height: rem(33),
      /** set icon height to 100% mediumUp */
      ...(mediumUp && { height: '100%' }),
    },
    IconToggleStyled: {
      flexShrink: 0,
      color: theme.colors.action.lightTheme.ctaBgDefault,
      marginLeft: rem(16),
    },
  };
};

export const TypographyStyled = styled(Typography)`
  ${({ leftPadding, variant }) => css`
    flex: 1 1 auto;
    padding-top: ${rem(6)};
    ${variant === 'primary' && `
      font-size: ${rem(17)};
    `}
    ${leftPadding && `
      padding-left: ${rem(16)};
    `}
  `}
`;
