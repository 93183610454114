/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';

export const DivContainerStyled = styled.div`
  ${({ theme }) => css`
    {
      margin-bottom: 1rem !important;
      padding: 1rem !important;
      background-color: ${theme.colors.neutral.grayLight30};
    }
  `}
`;
