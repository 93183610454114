import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import Picture from '../../../../components/Picture';

export const commonAgentPhotoStyles = (theme) => {
  const mediumUp = theme.mediumUp();
  const smallUp = theme.smallUp();
  let thisWidth = '100%';
  let thisHeight = 'auto';
  if (mediumUp) {
    thisWidth = '20vw';
    thisHeight = '20vw';
  } else if (smallUp) {
    thisWidth = rem(200);
    thisHeight = rem(200);
  }
  return css`
    display: flex;
    width: ${thisWidth};
    max-width: ${rem(200)};
    height: ${thisHeight};
    max-height: ${rem(200)};
    margin: ${mediumUp ? `0 auto ${rem(40)}` : `0 auto ${rem(24)}`};
    flex-wrap: wrap;
    overflow: hidden;
    justify-content: center;
  `;
};

export const PictureStyled = styled(Picture)`
  ${({ theme }) => css`
    ${commonAgentPhotoStyles(theme)};
  `}
`;

export const DivStyledAgentInitials = styled.div`
  ${({ theme }) => css`
    ${commonAgentPhotoStyles(theme)};
  `}
`;

export const SpanStyledAgentPlaceholderInitials = styled.span`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();

    return css`
      display: flex;
      width: 100%;
      padding-top: ${rem(8)};
      align-items: center;
      justify-content: center;
      aspect-ratio: 1 / 1;
      font-size: ${mediumUp ? rem(48) : rem(32)};
      font-weight: 500;
      text-transform: uppercase;
      color: ${theme.colors.blue.blue};
      background-color: ${theme.colors.sky.skyDark10};
    `;
  }}
`;
