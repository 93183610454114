import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import Typography from '../../../../foundations/Typography';

export const FooterLogoStyled = styled.a`
${({ nmxTemplateVersion, basePath }) => css`
    top: 0;
    left: 0;
    display: inline-block;
    width: ${rem(250)};
    max-width: ${rem(250)};
    height: ${rem(30)};
    align-self: center;
    margin: 0 auto;
    background-image: url('${basePath}/template/assets/${nmxTemplateVersion}/images/logos/logo-horizontal-navy.svg'); /**  TODO: encode svg */
    background-position-x: 0;
    background-repeat: no-repeat;
    background-size: cover;
    text-indent: ${rem(-9999)};
    vertical-align: text-top;
    @media print {
      background-image: none !important;
    }
    `}
`;

export const FooterLogoContainerStyled = styled(Typography)`
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0 0 ${rem(32)};
  margin-left: 0;
`;
