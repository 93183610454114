import em from 'polished/lib/helpers/em';

export const breakpointKeys = [
  'xsmall',
  'small',
  'medium',
  'large',
  'large1200',
  'xlarge',
  'xxlarge',
];

const values = {
  xsmall: 0,
  small: 480,
  medium: 768,
  large: 1024,
  large1200: 1200,
  xlarge: 1440,
  xxlarge: 1920,
};

const keys = Object.keys(values);

const up = (key) => {
  const value = typeof values[key] === 'number' ? values[key] : key;
  return `@media (min-width:${value}px)`;
};

const down = (key) => {
  const value = typeof values[key] === 'number' ? values[key] : key;
  return `@media (max-width:${value - 5 / 100}px)`;
};

const between = (start, end) => {
  const endIndex = keys.indexOf(end);

  return (
    `@media (min-width:${
      typeof values[start] === 'number' ? values[start] : start
    }px) and `
    + `(max-width:${(endIndex !== -1 && typeof values[keys[endIndex]] === 'number'
      ? values[keys[endIndex]]
      : end)
      - 5 / 100}px)`
  );
};

const defaultMatchUp = (breakpoint, windowWidth) => ({ defaultMatches: windowWidth >= breakpoint });
const defaultMatchDown = (breakpoint, windowWidth) => ({ defaultMatches: windowWidth < breakpoint });
const defaultMatchBetween = (breakpoint1, breakpoint2, windowWidth) => ({ defaultMatches: windowWidth >= breakpoint1 && windowWidth < breakpoint2 });

export default {
  xsmall: em('0px'),
  small: em('480px'),
  medium: em('768px'),
  large: em('1024px'),
  large1200: em('1200px'),
  xlarge: em('1440px'),
  xxlarge: em('1920px'),
  up,
  down,
  between,
  values,
  defaultMatchUp,
  defaultMatchDown,
  defaultMatchBetween,
};
