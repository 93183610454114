import styled, { ThemeContext } from 'styled-components';
import { useContext } from 'react';
import rem from 'polished/lib/helpers/rem';
import { Col } from '../../../foundations/Grid';
import Link from '../../../components/Link';

export const ColContentContainerStyled = styled(Col)`
  && {
    padding-right: 0;
  }
`;

export const ColIconContainerStyled = styled(Col)`
  && {
    padding: 0;
  }
`;

export const LinkStyled = styled(Link)`
  white-space: nowrap;
`;

/** Creates inline styles (to override stock Component Library styles) */
export const Styles = () => {
  const theme = useContext(ThemeContext);

  let marginTop = rem(2);
  if (theme.largeUp()) {
    marginTop = rem(3);
  } else if (theme.mediumUp()) {
    marginTop = rem(4);
  }
  return {
    IconStyled: {
      width: '100%',
      marginTop,
      height: 'auto',
      maxHeight: rem(30),
      color: theme.colors.blue.blue,
    },
  };
};

