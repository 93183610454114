import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

export const DivInlineTooltipContainerStyled = styled.div`
  ${({ theme, leftPos }) => {
    let top = -2;
    const mediumUp = theme.mediumUp();
    if (mediumUp) {
      top = 0;
    }

    return css`
      && {
        position: absolute;
        top: ${rem(top)};
        left: ${`${leftPos}px`};
      }
    `;
  }}
`;

// HERO Heading TOOLTIPS
export const DivHeadingInlineTooltipContainerStyled = styled.div`
  ${({ theme }) => {
    let top = rem(-5);
    const mediumUp = theme.mediumUp();
    if (mediumUp) {
      top = rem(-3);
    }

    return css`
      position: relative;
      display: inline;
      top: ${top};
      white-space: normal;
    `;
  }}
`;
