import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
// components
import gradients from '../../../../foundations/theme/effects/gradients';
import Link from '../../../../components/Link';
import Typography from '../../../../foundations/Typography';

export const LinkStyled = styled(Link)`
  ${({ theme, themeType }) => css`
    ${themeType === 'nmx-pcg'
      && `
      color: ${theme.colors.neutral.white};
      text-decoration: underline;
      :hover,
      :active {
        color: ${theme.colors.brand.pcg.gray};
      }
    `}
  `}
`;

export const TypographyStyled = styled(Typography)`
  ${({ theme, formvariant, themeType }) => {
    let padding = `0 ${rem(30)} ${rem(5)}`;
    const xlargeUp = theme.xlargeUp();
    if (xlargeUp) {
      padding = 0;
    }

    return css`
      ${formvariant === 'hero' && `padding: ${padding}`};
      text-align: center;
      /** TODO: these reusable PCG gradients should be somewhere, will find them */
      ${themeType === 'nmx-pcg'
        && `
        text-transform: uppercase;
        letter-spacing: ${rem(4)};
        font-weight: 400;
        ::after {
          content: '';
          display: block;
          height: ${rem(3)};
          width: ${rem(100)};
          margin: ${rem(10)} auto 0;
          ${gradients.pcgGold};
        }
      `};
    `;
  }}
`;
