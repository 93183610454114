import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Col } from '../../foundations/Grid';
import Typography from '../../foundations/Typography';

// styles
import { SwiperCarouselContainerRow, SwiperCarouselSwiperSlide, SwiperCarouselSwiperSlideRow, SwiperCarouselSwiperSlideCol, SwiperCarouselSwiperPagination, SwiperCarouselSwiperNavigation, SwiperCarouselSwiperNavigationLeftIcon, SwiperCarouselSwiperNavigationRightIcon } from './styles';
import './carousel.scss';

export const CarouselModule = ({ 'data-static-config': staticConfig }) => {
  const [swiperLoaded, setSwiperLoaded] = useState(false);
  const swiperCarouselConfig = useRef();
  const [retrievedSwiperCarouselConfig, setRetrievedSwiperCarouselConfig] = useState(false); // triggers render to show banner

  useEffect(() => {
    /*
      Because we don't want swiper to bloat the bundle and these charts don't need to be ready on page load,
      we will render the script on mount and load the charts once ready.
    */
    const script = document.createElement('script');

    script.src = 'https://unpkg.com/swiper@6.7.0/swiper-bundle.min.js';
    script.integrity = 'sha384-Y1O5WrIEuc9IhTQYuX3AAtW4jNhdPW/kGCjE/AyulARrGXpDMJYi1WD6UIllTfJ0';
    script.crossOrigin = 'anonymous';
    script.async = true;

    document.body.appendChild(script);
    script.onload = () => {
      setSwiperLoaded(true);
    };

    const link = document.createElement('link');

    link.href = 'https://unpkg.com/swiper@6.7.0/swiper-bundle.min.css';
    link.rel = 'stylesheet';
    link.integrity = 'sha384-hJBk1nqEF9yHIQHQ6cqCLBxzW+Eb4zV4S04M5kR/dG4EDiYTLEoBjGCKD3y5DDlK';
    link.crossOrigin = 'anonymous';

    document.body.appendChild(link);
  }, []);

  useEffect(() => {
    // init videos for YouTube API
    // TODO: build out YT players array to utilize YT API

    // Initiate Swiper (node slider/carousel library)
    if (retrievedSwiperCarouselConfig && swiperLoaded) {
      const swiperAutoplay = swiperCarouselConfig.current.autoplay
        ? { autoplay: { delay: swiperCarouselConfig.current.autoplayDelay } }
        : {};

      // eslint-disable-next-line no-unused-vars, no-undef
      const thisSwiperCarousel = new Swiper('.swiper-container', {
        slidesPerView: 1,
        centeredSlides: true,
        grabCursor: true,
        ...swiperAutoplay,
        speed: 400,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          type: 'bullets',
        },
        on: {
          slideChange() {
            // pause prev video on slideChange
            const thisYTPlayer = this.slides[this.previousIndex]
              ? this.slides[this.previousIndex].querySelectorAll('iframe')[0]
              : undefined;
            if (thisYTPlayer) {
              thisYTPlayer.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', 'https://www.youtube.com');
            }
            // TODO: tap into YT API for more control
            // TODO: pause slider on YT video play (Swiper API autoplay)
          },
        },
      });
    }
  }, [retrievedSwiperCarouselConfig, swiperLoaded]);

  useEffect(() => {
    // used by storybook
    if (staticConfig && swiperLoaded) {
      swiperCarouselConfig.current = staticConfig.swiperCarousel;
      setRetrievedSwiperCarouselConfig(true);
    }
    // TODO: dynamicConfig here
  }, [swiperLoaded]);

  return (
    (retrievedSwiperCarouselConfig && (
      <>
        {/* assuming this component will live within a <section> element */}
        {swiperCarouselConfig.current.sectionHeading
          && <Typography
            component='h2'
            className='nmx-assistive-text'>{swiperCarouselConfig.current.sectionHeading}</Typography>}
        <SwiperCarouselContainerRow className='swiper-container'>
          <Col className='nmx-section-thisSwiperCarousel-items swiper-wrapper'>
            {swiperCarouselConfig.current.slides.map((swiperCarouselItem, index) => (
              <SwiperCarouselSwiperSlide
                className='swiper-slide'
                key={`${swiperCarouselItem}${index + 1}`}>
                <SwiperCarouselSwiperSlideRow>
                  <SwiperCarouselSwiperSlideCol
                    xsmall={12}
                    small={10}
                    className='nmx-align-center nmx-col-small-10'>
                    {swiperCarouselItem.data.heading
                      && <Typography variant='h2'>{swiperCarouselItem.data.heading}</Typography>}
                    {swiperCarouselItem.data.text
                      && <Typography
                        variant='p'
                        align='left'>{swiperCarouselItem.data.text}</Typography>}
                    <div
                      className="video-container"
                      data-video-id={swiperCarouselItem.data.videoId}>
                      <iframe
                        width="600"
                        height="320"
                        title={swiperCarouselItem.data.heading}
                        src={`https://www.youtube.com/embed/${swiperCarouselItem.data.videoId}?enablejsapi=1`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;enablejsapi;"
                        allowFullScreen=""></iframe>
                    </div>
                  </SwiperCarouselSwiperSlideCol>
                </SwiperCarouselSwiperSlideRow>
              </SwiperCarouselSwiperSlide>
            ))}
          </Col>
          <SwiperCarouselSwiperNavigation className="swiper-button-prev">
            <SwiperCarouselSwiperNavigationLeftIcon />
          </SwiperCarouselSwiperNavigation>
          <SwiperCarouselSwiperNavigation className="swiper-button-next">
            <SwiperCarouselSwiperNavigationRightIcon />
          </SwiperCarouselSwiperNavigation>
          <SwiperCarouselSwiperPagination className="swiper-pagination" />
        </SwiperCarouselContainerRow>
      </>
    ))
  );
};

CarouselModule.propTypes = {
  'data-static-config': PropTypes.object,
  'data-app-name': PropTypes.string,
  'data-dynamic-config-base-url': PropTypes.string,
};

export default withTheme(CarouselModule);
