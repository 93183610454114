import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import HelperTextLabel from '../HelperTextLabel';
import Typography from '../../../foundations/Typography';

import {
  ButtonStyledToggleSwitch,
  SpanStyledRequiredFieldAsterisk,
  ToggleSwitchStyledCheckbox,
  ToggleSwitchStyledButton,
  TypographyStyled,
} from './styles';

export const handleToggle = (e, setThisToggleSwitchStatus, onChange, thisToggleSwitchStatus) => {
  const toggleStatus = !thisToggleSwitchStatus;
  setThisToggleSwitchStatus(toggleStatus);
  onChange(e);
};

export const ToggleSwitchComponent = ({
  ariaLabel,
  disabled,
  hasError,
  helperText,
  id,
  labelText,
  name,
  onChange,
  required,
  toggleSwitchControlClassName,
  toggleSwitchStatus,
  toggleSwitchText,
  themeType,
  variant,
}) => {
  const [thisToggleSwitchStatus, setThisToggleSwitchStatus] = useState(toggleSwitchStatus);
  const thisToggleSwitch = useRef(null);

  if (variant !== 'button') {
    return <ToggleSwitchStyledCheckbox className='nmx-form__input nmx-toggleswitch'>
      <TypographyStyled
        component='label'
        themeType={themeType}>{labelText}{required && <SpanStyledRequiredFieldAsterisk
          hasError={hasError}
          themeType={themeType}>&#42;</SpanStyledRequiredFieldAsterisk>}</TypographyStyled>
      <div>
        {/* TODO: put the checkbox ToggleSwitch here. */}
        {/* based off this tutorial: https://adrianroselli.com/2019/03/under-engineered-toggles.html */}
        {/* TODO: move input nested in label */}
        <input
          className={toggleSwitchControlClassName}
          id={id}
          name='default'
          type='checkbox'
        />
        <label htmlFor={id}>{toggleSwitchText} (not done yet)</label>
        <HelperTextLabel
          disabled={disabled}
          hasError={hasError}
          helperText={helperText}
          themeType={themeType}
        />
      </div>
    </ToggleSwitchStyledCheckbox>;
  }
  return <ToggleSwitchStyledButton
    themeType={themeType}
    className='nmx-toggleswitch'>
    {labelText
    && <TypographyStyled themeType={themeType}>{labelText}{required && <span>&#42;</span>}</TypographyStyled>
    }
    {/* https://www.w3.org/WAI/ARIA/apg/patterns/button/ */}
    {/* based of this tutorial: https://adrianroselli.com/2019/08/under-engineered-toggles-too.html */}
    <ButtonStyledToggleSwitch
      {...(ariaLabel && { 'aria-label': ariaLabel })} // NOTE: React implements exceptions to JSX, "aria-*" is one of them: https://reactjs.org/docs/accessibility.html
      aria-pressed={thisToggleSwitchStatus} // NOTE: React implements exceptions to JSX, "aria-*" is one of them: https://reactjs.org/docs/accessibility.html
      ariaPressed={thisToggleSwitchStatus}
      className={toggleSwitchControlClassName}
      id={id}
      name={name}
      onClick={(e) => handleToggle(e, setThisToggleSwitchStatus, onChange, thisToggleSwitchStatus)}
      ref={thisToggleSwitch}
      themeType={themeType}
      type='button'
    >
      <Typography
        className='nmx-toggleswitch-text'
        component='span'
        themeType={themeType}
      >
        {toggleSwitchText}
      </Typography>
    </ButtonStyledToggleSwitch>

    <HelperTextLabel
      disabled={disabled}
      hasError={hasError}
      helperText={helperText}
      themeType={themeType}
    />
  </ToggleSwitchStyledButton>;
};

ToggleSwitchComponent.propTypes = {
  /** required ariaLabel attribute, used for ADA to add more description to the ToggleSwitch (what will happen when clicked) */
  ariaLabel: PropTypes.string.isRequired,
  /** sets overall branding of component or module, default='nm' */
  branding: PropTypes.oneOf(['nm', 'pcg']),
  /** Disabled attribute */
  disabled: PropTypes.bool,
  /** Is this input in an error state */
  hasError: PropTypes.bool,
  /** Optional helperText positioned below input field */
  helperText: PropTypes.string,
  /** Required id attribute */
  id: PropTypes.string.isRequired,
  /** Name applied to input or textarea */
  name: PropTypes.string,
  /** The labelText placed on the text field */
  labelText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]), // TODO: only required if using checkbox
  /** Optional function when ToggleSwitch status changes */
  onChange: PropTypes.func,
  /** input is required, used for uses such as "I have read the terms of the agreement, check to comply, or whatever" */
  required: PropTypes.bool,
  /** optional className to put directly on the (button/checkbox) control element */
  toggleSwitchControlClassName: PropTypes.string,
  /** optional boolean to set ToggleSwitch true/false on load  */
  toggleSwitchStatus: PropTypes.bool,
  /** Optional toggleSwitchText positioned adjacent to field */
  toggleSwitchText: PropTypes.string,
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']), // TODO: build out darkTheme
  /** distinguish between button and checkbox variants */
  variant: PropTypes.oneOf(['button', 'checkbox']).isRequired, // TODO: build out checkbox variant
};

ToggleSwitchComponent.defaultProps = {
  branding: 'nm',
  disabled: false,
  hasError: false,
  helperText: '',
  onChange: () => {},
  required: false,
  toggleSwitchStatus: false,
  themeType: 'lightTheme',
  variant: 'button',
};

export default ToggleSwitchComponent;
