import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
// native components
import Typography from '../../../foundations/Typography';

// eslint-disable-next-line import/prefer-default-export
export const HelperTextLabelStyled = styled(Typography)`
  ${({ disabled, hasError, theme, themeType }) => {
    // Default Styles
    const getDefaultStyles = () => css`
      display: block;
      margin-top: ${rem(3)};
      font-weight: 400;
    `;

    // Light Theme
    const getLightThemeStyles = () => css`
      color: ${theme.colors.forms.lightTheme.inputFieldText};
    `;

    // Disabled Light Theme
    const getDisabledLightThemeStyles = () => css`
      color: ${theme.colors.text.lightTheme.disabled};
    `;

    // Error Light Theme
    const getErrorLightThemeStyles = () => css`
      color: ${theme.colors.text.lightTheme.error};
    `;

    // Dark Theme
    const getDarkThemeStyles = () => css`
      color: ${theme.colors.forms.darkTheme.inputFieldText};
    `;

    // Disabled Dark Theme
    const getDisabledDarkThemeStyles = () => css`
      color: ${theme.colors.text.darkTheme.disabled};
    `;

    // Error Dark Theme
    const getErrorDarkThemeStyles = () => css`
      color: ${theme.colors.text.darkTheme.error};
    `;

    return css`
      /** Default/Base TextField styles get loaded first every time */
      ${getDefaultStyles()}
      /* then, conditionally, color, border, background-color styles get added */
      /* getting light theme */
      ${themeType === 'lightTheme' && getLightThemeStyles()} /** Default = Primary Light Theme */
      ${themeType === 'lightTheme' && disabled && getDisabledLightThemeStyles()}
      ${themeType === 'lightTheme' && hasError && getErrorLightThemeStyles()}
      /* getting dark theme */
      ${themeType === 'darkTheme' && getDarkThemeStyles()}
      ${themeType === 'darkTheme' && disabled && getDisabledDarkThemeStyles()}
      ${themeType === 'darkTheme' && hasError && getErrorDarkThemeStyles()}
    `;
  }}
`;
