import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

export const ButtonStyledDismiss = styled.button`
  ${({ theme, themeType }) => {
    const thisIconColorHover = themeType === 'darkTheme' ? theme.colors.action.darkTheme.utilityItemHover : theme.colors.action.lightTheme.utilityItemHover;
    return css`
      position: absolute;
      width: ${rem(40)};
      height: ${rem(40)};
      padding: 0;
      border: 0;
      background: transparent;
      &:hover {
        .nmx-icon {
          ::after,
          ::before {
            background-color: ${thisIconColorHover};
          }
        }
      }
    `;
  }};
`;

export const SpanStyled = styled.span`
  ${({ theme, themeType }) => {
    const thisIconColor = themeType === 'darkTheme' ? theme.colors.action.darkTheme.utilityItemDefault : theme.colors.action.lightTheme.utilityItemDefault;
    return css`
    display: block;
    position: absolute;
    left: ${rem(8)};
    top: ${rem(8)};
    width: ${rem(24)};
    height: ${rem(24)};
    ::before,
    ::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 0;
      width: ${rem(2)};
      height: 100%;
      background-color: ${thisIconColor};
      transform: rotate(45deg) translateX(0);
    }
    ::before {
      transform: rotate(-45deg) translateX(0);
    }
  `;
  }};
`;
