import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// styles
import {
  ProgressBarStyled,
  ProgressBarContainerStyled,
} from './styles';

export const ProgressBarComponent = ({
  branding,
  className,
  fillPercentage,
  labelText,
  themeType,
}) => (
  <ProgressBarContainerStyled className={classNames('nmx-no-print', className)}>
    <label>
      {' '}
      {/** TODO: will have to come back to this - thinking <label> is not the best markup here... probably just a div or span. */}
      {labelText}
      <ProgressBarStyled
        branding={branding}
        className="nmx-progress-bar"
        max="100"
        themeType={themeType}
        value={fillPercentage}
      />
    </label>
  </ProgressBarContainerStyled>
);
ProgressBarComponent.propTypes = {
  /** sets overall branding of component or module, default='nm' */
  branding: PropTypes.oneOf(['nm', 'pcg']),
  /** Optional custom class name */
  className: PropTypes.string,
  /** The progress percentage of the progress bar */
  fillPercentage: PropTypes.number.isRequired,
  /** required ariaLabel attribute, used for ADA to add more description to the progress bar (what ) */
  labelText: PropTypes.string,
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']),
};

ProgressBarComponent.defaultProps = {
  branding: 'nm',
  fillPercentage: 0,
  themeType: 'lightTheme',
};

export default ProgressBarComponent;
