import styled, { css, keyframes } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
// components
import { Col, Container, Row } from '../../foundations/Grid';
import Link from '../../components/Link';
import Typography from '../../foundations/Typography';
// helpers
import { getSectionBackgroundColor } from '../../components/SectionContainer/styles';

const sharpenBg = keyframes`
  0% {
    filter: blur(${rem(20)});
  }
  100% {
    filter: blur(0);
  }
`;

export const ColStyled = styled(Col)`
  ${({ theme, themeType, backgroundVariant, branding }) => {
    const largeUp = theme.largeUp();
    const large1200Up = theme.large1200Up();

    return css`
      background-color: ${getSectionBackgroundColor(backgroundVariant, branding, theme, themeType)};
      padding: ${largeUp ? rem(60) : `${rem(32)} ${rem(16)} ${rem(48)}`};
      height: 100%;
      min-height: ${large1200Up ? rem(450) : 'unset'};
      display: flex;
      flex-direction: column;
      justify-content: center;
    `;
  }}
`;

export const ContainerStyled = styled(Container)`
  z-index: 1;
  margin: 0;
  padding: 0;
  height: 100%;
`;

export const DivStyledBackgroundContainer = styled.div`
  ${({
    theme,
    bgImageLargeUpDefault,
    bgImageLargeUpLowRes,
    bgImageLargeUpWebp,
    bgImageXSmallUpDefault,
    bgImageXSmallUpLowRes,
    bgImageXSmallUpWebp,
    bgImageMediumUpDefault,
    bgImageMediumUpLowRes,
    bgImageMediumUpWebp,
  }) => {
    const largeUp = theme.largeUp();
    const mediumUp = theme.mediumUp();
    const thisHeight = rem(210);

    let thisImageDefault = bgImageXSmallUpDefault; // required prop
    let thisImageLowRes = bgImageXSmallUpLowRes || '';
    let thisImageWebp = bgImageXSmallUpWebp || '';

    if (largeUp) {
      thisImageDefault = bgImageLargeUpDefault; // required prop
      thisImageLowRes = bgImageLargeUpLowRes || '';
      thisImageWebp = bgImageLargeUpWebp || '';
    } else if (mediumUp) {
      thisImageDefault = bgImageMediumUpDefault || bgImageLargeUpDefault; // if no mediumUp image is passed in, fall back to the largeUp image set
      thisImageLowRes = bgImageMediumUpLowRes || bgImageLargeUpLowRes || '';
      thisImageWebp = bgImageMediumUpWebp || bgImageLargeUpWebp || '';
    }

    return css`
      position: ${mediumUp ? 'absolute' : 'unset'};
      top: 0;
      left: 0;
      width: 100%;
      min-height: ${thisHeight};
      height: ${mediumUp ? '100%' : thisHeight};
      background: ${theme.colors.background.lightTheme.heroLazyLoad} no-repeat scroll 50% 20%;
      background-position: right 0;
      background-image: url(${thisImageLowRes});
      background-image: url(${thisImageWebp || thisImageDefault});
      background-position-y: 0%;
      background-size: cover;
      transform: scale(1);
      -webkit-animation: ${sharpenBg} 1s both;
      animation: ${sharpenBg} 1s both;
      @media print {
        && {
          display: none !important;
        }
      }
    `;
  }
}`;

export const LinkStyledButton = styled(Link)`
  ${({ theme, applyTopPadding }) => {
    const smallUp = theme.smallUp();
    return css`
      min-width: ${smallUp ? rem(200) : rem(150)};
      ${applyTopPadding && `margin-top: ${rem(8)};`}
      ${!smallUp && `
        display: block;
        margin-left: auto;
        margin-right: auto;
      `}
    `;
  }}
`;

export const RowStyled = styled(Row)`
  height: 100%;
  text-align: left;
  justify-content: left;
`;

export const SectionStyled = styled.section`
  ${({ theme }) => css`
    overflow: hidden; /** for bg blur-up */
    padding-top: 0; /** for bg blur-up // overwrite global section padding */
    padding-bottom: 0; /** for bg blur-up // overwrite global section padding */
    position: relative;
    align-items: center;
    background-color: ${theme.colors.neutral.white};
    color: ${theme.colors.neutral.grayDark20};
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding: 0;
  `}
`;

export const TypographyStyledDescription = styled(Typography)`
  padding-bottom: ${rem(32)};
`;

export const TypographyStyledHeading = styled(Typography)`
  ${({ theme }) => {
    let fontSize = rem(26);
    let lineHeight = rem(32);
    const paddingBottom = rem(16);
    const mediumUp = theme.mediumUp();
    const largeUp = theme.largeUp();

    if (largeUp) {
      fontSize = rem(44);
      lineHeight = rem(52);
    } else if (mediumUp) {
      fontSize = rem(35);
      lineHeight = rem(41);
    }

    return css`
      font-family: 'Guardian Sans', 'Helvetica Neue', Helvetica, sans-serif;
      padding-bottom: ${paddingBottom};
      line-height: ${lineHeight};
      font-size: ${fontSize};
      ::after {
        display: block;
        width: ${rem(60)};
        padding-bottom: ${paddingBottom};
        border-bottom: ${rem(4)} solid ${theme.colors.gold.gold};
        content: '';
      }
    `;
  }}
`;

export const TypographyStyledProductName = styled(Typography)`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    let fontSize = rem(12);
    let lineHeight = rem(18);
    const paddingBottom = rem(16);

    if (mediumUp) {
      fontSize = rem(16);
      lineHeight = rem(22);
    }

    return css`
      && {
        padding-bottom: ${paddingBottom};
        font-size: ${fontSize};
        line-height: ${lineHeight};
        font-weight: 400;
        letter-spacing: ${rem(2.5)};
        text-transform: uppercase;
      }
    `;
  }}
`;

