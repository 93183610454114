import React from 'react';
import PropTypes from 'prop-types';
// components
import Typography from '../../foundations/Typography';
// styles
import {
  IconStyledDarkTheme,
  IconStyledLightTheme,
  ListItemStyled,
  ListStyled,
  SpanStyledDisc,
  SpanStyledFilledCircle,
  SpanStyledFilledSquare,
  TypographyStyledEndash,
} from './styles';

export const getListDecorator = (themeType, removeBullet, bulletType, orderedListOptions) => {
  if (removeBullet || bulletType === 'none' || orderedListOptions.includes(bulletType)) {
    return <></>;
  }

  switch (bulletType) {
    case 'filledCircle':
      return <SpanStyledFilledCircle themeType={themeType} />;
    case 'checkmark':
      return themeType === 'darkTheme' ? <IconStyledDarkTheme /> : <IconStyledLightTheme />;
    case 'disc':
      return <SpanStyledDisc themeType={themeType} />;
    case 'endash':
      return (
        <TypographyStyledEndash
          component='span'
          themeType={themeType}>
          &#8211;
        </TypographyStyledEndash>
      );
    case 'filledSquare':
      return <SpanStyledFilledSquare themeType={themeType} />;
    default:
      return <></>;
  }
};

export const getOrderedListType = (listBulletType) => {
  switch (listBulletType) {
    case 'orderedDecimal':
      return 'decimal';
    case 'orderedLowerAlpha':
      return 'lowerAlpha';
    case 'orderedLowerRoman':
      return 'lowerRoman';
    case 'orderedUpperAlpha':
      return 'upperAlpha';
    case 'orderedUpperRoman':
      return 'upperRoman';
    default:
      return null;
  }
};

export const BulletedListComponent = ({
  bulletedListData,
  bulletType,
  className,
  disableBottomPadding,
  disableTopPadding,
  themeType,
}) => {
  const orderedListOptions = ['orderedDecimal', 'orderedLowerAlpha', 'orderedLowerRoman', 'orderedUpperAlpha', 'orderedUpperRoman'];

  const listVariant = (listBulletType) => (orderedListOptions.includes(listBulletType) ? 'ordered' : 'unordered');

  return (
    <ListStyled
      className={className}
      disableBottomPadding={disableBottomPadding}
      disableTopPadding={disableTopPadding}
      orderedListType={getOrderedListType(bulletType)}
      variant={listVariant(bulletType)}>
      {bulletedListData.map((item, index) => (
        <ListItemStyled
          style={item.bulletStyles}
          className={item.bulletClassName}
          key={`item-key-${index + 1}`}
          removeBullet={item.removeBullet}
          themeType={themeType}
          {...item.orderedBulletValue && { value: item.orderedBulletValue }}
          variant={listVariant(bulletType)}>
          {getListDecorator(themeType, item.removeBullet, bulletType, orderedListOptions)}
          {(item.bulletText && item.useRawImportedBulletContent)
            ? (item.bulletText)
            : (
              <Typography
                block
                component='span'
                disableBottomPadding={bulletedListData.length === (index + 1)} // disable bottom padding for last item
                themeType={themeType}
                variant='p'>
                {item.bulletText}
              </Typography>
            )
          }
        </ListItemStyled>
      ))}
    </ListStyled>
  );
};

BulletedListComponent.propTypes = {
  /** optional additional className */
  className: PropTypes.string,
  /** Array of bulletedList data config items */
  bulletedListData: PropTypes.arrayOf( // TODO: rename to styledListData
    PropTypes.shape({
      /** optional additional className for individual bullet */
      bulletClassName: PropTypes.string,
      /** Optional custom styles for individual bullet */
      bulletStyles: PropTypes.object,
      /** Individual Bullet Text, can include valid markup */
      bulletText: PropTypes.any.isRequired, // TODO: rename to listItemText
      /** force an ordered list item to be numbered a specific way, use if an ordered BulletedList is nesting another BulletedList */
      orderedBulletValue: PropTypes.number,
      /** boolean to remove individual bullet - used predominantly in cases with nested BulletedLists, set to true to remove specific bullet decorator */
      removeBullet: PropTypes.bool,
      /**  remove base <Typography /> children and use raw imported markup */
      useRawImportedBulletContent: PropTypes.bool, // TODO: rename to useRawImportedListItemContent
    }),
  ).isRequired,
  /** renders the desired type of marker */
  bulletType: PropTypes.oneOf(['checkmark', 'disc', 'endash', 'filledCircle', 'filledSquare', 'none', 'orderedDecimal', 'orderedLowerAlpha', 'orderedLowerRoman', 'orderedUpperAlpha', 'orderedUpperRoman']),
  /** Removes inherited browser bottom-padding (margin) on bottom of bulletedList, used when list is at end of Section with NO CTA below, see special-needs-planning page */
  disableBottomPadding: PropTypes.bool,
  /** Removes inherited browser top-padding (margin) on top of bulletedList, used when list is after a section of paragraph text */
  disableTopPadding: PropTypes.bool,
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme', 'nmx-pcg']),
};

BulletedListComponent.defaultProps = {
  bulletType: 'filledCircle',
  disableBottomPadding: false,
  disableTopPadding: false,
  themeType: 'lightTheme',
};

export default BulletedListComponent; // TODO: rename to StyledListComponent
