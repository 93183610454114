/** Created manually */
import React from 'react';
import classNames from 'classnames';

class Icon extends React.PureComponent {
  render() {
    const { className, svgRef, ...otherProps } = this.props;
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="32"
        fill="none"
        className={classNames('nmx-icon', className)}
        ref={svgRef}
        {...otherProps}><path
          fill="#F52A01"
          d="M1.541 13.27c.85 3.657 1.694 7.314 2.543 10.97.136.576.688 1.038 1.346 1.095.706.061 1.218-.32 1.514-.847.706-1.242 1.855-2.785 2.89-4.04a60.708 60.708 0 0 1 4.072-4.417 78.568 78.568 0 0 1 9.608-8.095c1.618-1.158 3.38-2.318 5.066-3.204.683-.36 1.066-1.061.69-1.759-.325-.602-1.3-1.01-1.989-.65-3.646 1.928-6.947 4.354-10.044 6.914-3.426 2.835-6.668 5.9-9.485 9.228-1.358 1.605-2.585 3.28-3.601 5.074l2.86.247c-.85-3.656-1.693-7.313-2.543-10.97-.387-1.675-3.313-1.227-2.92.455H1.54Z"/></svg>
    );
  }
}

Icon.displayName = 'HandDrawnCheckmarkIcon';

export default Icon;
