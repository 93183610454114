import React from 'react';
import PropTypes from 'prop-types';

import { LegendStyled, FormStyled } from './styles';
import { Container } from '../../../foundations/Grid';

export const FormComponent = React.forwardRef(({
  children,
  className,
  hasContainer,
  legend,
  ...rest
}, ref) => (
  <FormStyled
    ref={ref}
    className={className}
    {...rest}>
    { legend && <LegendStyled>{legend}</LegendStyled> }
    <Container>
      {children}
    </Container>
  </FormStyled>
));

FormComponent.propTypes = {
  /** Form children */
  children: PropTypes.node.isRequired,
  /** Optional prop to nest form inside <Container> Component */
  // if the consuming component already has nmx-container, set this to false to prevent duplicating of classes
  hasContainer: PropTypes.bool,
  /** Optional form legend */
  legend: PropTypes.string,
  /** Optional function when form is submitted */
  onSubmit: PropTypes.func,
};

FormComponent.defaultProps = {
  children: '',
  hasContainer: true,
  onSubmit: () => {},
};

export default FormComponent;
