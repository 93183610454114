/**
 * Constants that are only used internally, or not meant to be used by clients of this library
 */

/**
 * Types of push events
 * ---
 * In contrast with `NAME_PUSH_EVENTS`, these are only used within this codebase to help discern the difference between
 * the different type of push events. For example, when throwing an error, it can be useful to know what type of
 * event triggered the error.
 *
 * @type {{QUIZ: string, COMMON_COMPONENT: string, LEAD_FORM: string, CALCULATOR: string}}
 *
 * @private This is not meant to be used externally
 */
var TYPE_PUSH_EVENTS = {
  QUIZ: 'quiz',
  LEAD_FORM: 'lead_form',
  CALCULATOR: 'calculator',
  COMMON_COMPONENT: 'common_component'
};
export { TYPE_PUSH_EVENTS };