import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

export const DivStyledLoadingIcon = styled.div`
  ${({ branding, hasChildren, theme, themeType }) => {
    const thisBorderColorPrimary = themeType === 'darkTheme' ? theme.colors.neutral.white : theme.colors.blue.blue;
    const thisBorderColorSecondary = branding === 'pcg' ? theme.colors.brand.pcg.gold1 : theme.colors.gold.gold;

    return css`
      @keyframes animate-spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(-360deg);
        }
      }
      display: block;
      position: relative;
      width: 100%;
      height: 0;
      ${hasChildren && { 'margin-bottom': rem(20) }}
      padding-bottom: 100%;

      &::before,
      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        border: ${rem(3)} solid transparent;
        animation-name: animate-spin;
        animation-duration: 1250ms;
        animation-timing-function: cubic-bezier(0.12, 0.33, 0.89, 0.68);
        animation-iteration-count: infinite;
        border-radius: ${rem(1000)};
        /* mobile only, but could just be a weird vw issue */
        border-width: ${rem(3)};
      }

      &::before {
          border-bottom-color: ${thisBorderColorPrimary};
          border-left-color: ${thisBorderColorPrimary};
          height: 100%;
          width: 100%;
          margin: -50% 0 0 -50%;
      }

      &::after {
        border-top-color: ${thisBorderColorSecondary};
        border-right-color: ${thisBorderColorSecondary};
        animation-direction: reverse;
        /* may need to be adjusted IF the container for the loader is LARGE */
        height: 90%;
        width: 90%;
        margin: -45% 0 0 -45%;
      }
    `;
  }
}`;

export const DivStyledLoadingContainer = styled.div`
  ${({ loadingSpinnerWidth, theme }) => css`
    width: ${rem(loadingSpinnerWidth)};
    max-width: ${rem(loadingSpinnerWidth)};
    margin-left: ${`calc(50% - ${rem(loadingSpinnerWidth / 2)})`};
    text-align: center;
    color: ${theme.colors.neutral.grayDark10};
  `}
`;
