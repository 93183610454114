import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../../foundations/Typography';
import {
  ColStyled,
  ContainerStyled,
  DivStyledDate,
  DivStyledEvent,
  RowStyled,
  TypographyStyledDescription,
  TypographyStyledEvent,
} from './styles';
import { Col } from '../../foundations/Grid';

const EventComponent = ({
  className,
  dateTime,
  description,
  location,
  name,
  themeType,
  backgroundVariant,
  disableBottomBorder,
}) => {
  const date = new Date(dateTime);
  const day = date.getDate();
  const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const dayOfWeek = dayNames[date.getDay()];
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const month = monthNames[date.getMonth()];
  const time = date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
  });
  const year = date.getFullYear();

  return (
    <ContainerStyled
      backgroundVariant={backgroundVariant}
      themeType={themeType}
      className={className}
      disableBottomBorder={disableBottomBorder}
      itemScope
      itemType='https://schema.org/Event'>

      <RowStyled
        align='center'>
        <ColStyled
          xsmall={4}
          small={3}
          large={2}>
          <DivStyledDate
            backgroundVariant={backgroundVariant}
            themeType={themeType}>
            <meta
              itemProp='startDate'
              content={dateTime}/>
            <TypographyStyledEvent
              variant='h1'
              align='center'
              disableBottomPadding>
              {dayOfWeek.substring(0, 3)}
            </TypographyStyledEvent>
            <TypographyStyledEvent
              variant='h4'
              align='center'
              weight={400}
              disableBottomPadding>
              {month} {day},
              <TypographyStyledEvent
                component='span'
                weight={400}
                block
              >
                {year}
              </TypographyStyledEvent>
            </TypographyStyledEvent>
          </DivStyledDate>
        </ColStyled>
        <Col
          xsmall={8}
          small={9}
          large={10}>
          <DivStyledEvent>
            <TypographyStyledEvent
              component='h3'
              variant='h2'
              weight={400}
              nextElement='typography'
              itemProp="name">
              {name}
            </TypographyStyledEvent>
            <Typography
              variant='h3'
              weight={300}
              nextElement='typography'>
              <Typography
                component='span'
                itemProp="location">
                <TypographyStyledEvent
                  component='span'
                  itemScope
                  itemType="https://schema.org/Place">{location}</TypographyStyledEvent>
              </Typography>
              <TypographyStyledEvent
                component='span'>
          , {time}
              </TypographyStyledEvent>
            </Typography>
            <TypographyStyledDescription
              variant='h4'
              weight={300}
              disableBottomPadding
              itemProp='about'>
              {description}
            </TypographyStyledDescription>
          </DivStyledEvent>
        </Col>

      </RowStyled>

    </ContainerStyled>
  );
};

EventComponent.propTypes = {
  /** Custom class name to add to Type. */
  className: PropTypes.string,
  /** dateTime of the event, used in the standard JavaScript dateTime string format: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#date_time_string_format */
  dateTime: PropTypes.string.isRequired,
  /** Description of the event */
  description: PropTypes.string,
  /** Place of the event */
  location: PropTypes.string.isRequired,
  /** Name of the event */
  name: PropTypes.string.isRequired,
  /** backgroundVariant allows section cards to be different background color */
  backgroundVariant: PropTypes.oneOf(['lightA', 'lightB', 'lightC']),
  /** themeType allows for different color themes */
  themeType: PropTypes.oneOf(['lightTheme']),
  /** Disable the default bottom padding for section */
  disableBottomBorder: PropTypes.bool,
};

EventComponent.defaultProps = {
  backgroundVariant: 'lightA',
  disableBottomBorder: false,
  themeType: 'lightTheme',
};

export default EventComponent;
