import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

export const DivStyled = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    height: 100%;
    background-color: ${theme.colors.background.darkTheme.darkDefault};
    overflow-x: hidden;
    &#{&} {
      position: fixed;
      z-index: ${theme.zIndex.globalNavMain};
      top: 0;
      right: 0;
      width: ${rem(80)};
      height: ${rem(50)};
      ${theme.largeUp()
        && css`
          position: relative;
          top: auto;
          right: auto;
          width: auto;
          height: auto;
        `}
    }
  `}
`;

export const DivLogoutFramesStyled = styled.div`
  display: none;
`;

export const FwmButtonStyled = styled.button`
  ${({ theme }) => css`
    appearance: none;
    border: none;
    background: transparent;
    cursor: pointer;
    font-weight: 500;
    color: ${theme.colors.neutral.white};

    :hover {
      color: ${theme.colors.brand.nm.secondary.sky};
      text-decoration: underline;
    }
  `}
`;

export const ButtonStyled = styled.button`
  ${({ theme }) => {
    let paddingTop = '0';
    const xlargeUp = theme.xlargeUp();
    const largeUp = theme.largeUp();

    if (xlargeUp) {
      paddingTop = rem(8);
    } else if (largeUp) {
      paddingTop = rem(6);
    }

    return css`
      display: inline-block;
      width: ${largeUp ? 'auto' : rem(80)};
      height: 100%;
      border: none;
      margin: ${largeUp ? '0' : '0 auto'};
      background-color: ${theme.colors.background.darkTheme.darkDefault};
      border-radius: 0;
      color: ${theme.colors.neutral.white};
      padding-top: ${paddingTop};

      height: auto;
      font-size: ${rem(15)};
      font-weight: 500;
      line-height: ${rem(22)};
      ${largeUp
        && css`
          height: 100%;
          font-size: ${rem(11)};
          line-height: ${rem(17)};
        `}
      ${xlargeUp
        && css`
          font-size: ${rem(13)};
          line-height: ${rem(20)};
        `}

      .nmx-icon {
        display: ${largeUp ? 'block' : 'none'};
        margin-right: ${rem(7)};
        margin-top: ${largeUp ? rem(2) : 0};
        float: left;
        .icon-fill {
          fill: ${theme.colors.brand.nm.secondary.sky};
        }
      }
      &:hover,
      &:focus {
        background-color: ${theme.colors.background.darkTheme.darkDefault};
        text-decoration: underline;
        .nmx-icon {
          .icon-fill {
            fill: ${theme.colors.neutral.white};
          }
        }
      }
    `;
  }}
`;
