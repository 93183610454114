import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const DivSkipNavContainerStyled = styled.div`
  ${({ theme }) => css`
    position: fixed;
    z-index: ${theme.zIndex.skipNav};
    top: 0;
    left: ${rem(-9999)};
    opacity: 0;
    &.is-active {
      left: 0;
      opacity: 1;
    }
    .nmx-nav-link {
      position: relative;
      top: 0;
      left: 0;
      display: block;
      width: ${rem(200)};
      padding: ${rem(16)};
      background-color: ${theme.colors.neutral.white};
      color: ${theme.colors.background.darkTheme.default};
      font-size: 12px; /** TODO : Visit if these can be rems, or if they have to be px for some reason.  */
      font-weight: 500;
      line-height: 18px; /** TODO : Visit if these can be rems, or if they have to be px for some reason.  */
      text-align: center;
      text-transform: uppercase;

      &:focus {
        z-index: ${theme.mediumUp() ? 340 : 'auto'};
      }
    }
  `}
`;

export default DivSkipNavContainerStyled;
