import React from 'react';
import PropTypes from 'prop-types';

import { LinkStyledSocial } from './styles';

const SocialLink = ({
  ariaLabel,
  children,
  id,
  linkUrl,
  themeType,
  ...rest
}) => (
  <LinkStyledSocial
    ariaLabel={ariaLabel}
    className='u-url'
    id={id}
    isOutgoingLink
    linkUrl={linkUrl}
    resetBaseStyles
    themeType={themeType}
    {...rest}
  >
    {children}
  </LinkStyledSocial>
);

SocialLink.propTypes = {
  /** required ariaLabel attribute, used for ADA to add more description to the Social Link (what will happen when clicked) */
  ariaLabel: PropTypes.string.isRequired,
  /** Inner content of Link (pass in Social Icon of choice) */
  children: PropTypes.node.isRequired,
  /** optional Link id */
  id: PropTypes.string,
  /** required url path for href */
  linkUrl: PropTypes.string.isRequired,
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']),
};

SocialLink.defaultProps = { themeType: 'lightTheme' };

export default SocialLink;
