import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { standardFontCss } from '../../foundations/GlobalStyles'; // standardizes typography to nmx-p sizes on all breakpoints
// components
import List from '../List'; // allows for reduced styling
import Typography from '../../foundations/Typography';
// icons
import CheckmarkBoldIcon from '../../foundations/Icon/icons/utility/CheckmarkBold';

const getDefaultThemeColor = (theme, themeType) => (themeType === 'lightTheme' ? theme.colors.text.lightTheme.default : theme.colors.text.darkTheme.default);

export const ListStyled = styled(List)`
  ${({ disableBottomPadding, disableTopPadding }) => css`
    padding: 0 0 0 ${rem(40)};
    ${disableBottomPadding
      && css`
        margin-bottom: 0; /** deprecated in 4.x.x (remove prop and change this style to default/permanent) */
      `}
    ${disableTopPadding
      && css`
        margin-top: 0; /** deprecated in 4.x.x (remove prop and change this style to default/permanent) */
      `}
  `}
`;

export const ListItemStyled = styled.li`
  /* TODO: these styles should be redone to pull in native list bullets, and then style those */
  ${({ removeBullet, theme, themeType, variant }) => css`
    position: relative;
    ::marker {
      ${standardFontCss};
      color: ${getDefaultThemeColor(theme, themeType)};
    }
    ${variant === 'ordered' && `
      margin-left: ${rem(-5)};
      padding-left: ${rem(5)};
    `}
    ${(removeBullet || variant === 'unordered') && `
      list-style: none;
    `}
  `};
`;

const getBulletTypeStyles = (theme) => {
  const smallUp = theme.smallUp();
  const thisHeightWidthDimension = smallUp ? rem(7) : rem(5);
  /* TODO: these styles should be redone to pull in native list bullets, and then style those */
  return css`
    position: absolute;
    top: ${smallUp ? rem(10) : rem(8)};
      left: ${smallUp ? rem(-24) : rem(-32)};
       /** TODO: style native ::marker */
      ::before {
        width: ${thisHeightWidthDimension};
        height: ${thisHeightWidthDimension};
        display: inline-block;
        position: absolute;
        content: '';
      }
  `;
};

export const SpanStyledFilledCircle = styled.span`
  ${({ theme, themeType }) => css`
    ${getBulletTypeStyles(theme)};
    ::before {
      background-color: ${getDefaultThemeColor(theme, themeType)};
      border-radius: 50%;
    }
  `};
`;

export const SpanStyledDisc = styled.span`
  ${({ theme, themeType }) => css`
    ${getBulletTypeStyles(theme)};
    ::before {
      border: ${rem(1)} solid ${getDefaultThemeColor(theme, themeType)};
      border-radius: 50%;
    }
  `};
`;

export const SpanStyledFilledSquare = styled.span`
  ${({ theme, themeType }) => css`
    ${getBulletTypeStyles(theme)};
    ::before {
      background-color: ${getDefaultThemeColor(theme, themeType)};
    }
  `};
`;

export const TypographyStyledEndash = styled(Typography)`
  ${({ theme, themeType }) => {
    const smallUp = theme.smallUp();
    return css`
      position: absolute;
      top: ${smallUp ? rem(5) : rem(3)};
      left: ${smallUp ? rem(-20) : rem(-15)};
      color: ${getDefaultThemeColor(theme, themeType)};
      font-weight: 500;
    `;
  }};
`;

const getIconStyles = (theme) => {
  const mediumUp = theme.mediumUp();
  const thisTop = mediumUp ? 5 : 2;
  return css`
    position: absolute;
    top: ${rem(thisTop)};
    left: ${rem(-40)};
    padding-right: ${rem(8)};
  `;
};

export const IconStyledDarkTheme = styled(CheckmarkBoldIcon)`
  ${({ theme }) => css`
    ${getIconStyles(theme)};
    .icon-fill {
      fill: ${theme.colors.sky.sky};
    }
  `}
`;

export const IconStyledLightTheme = styled(CheckmarkBoldIcon)`
  ${({ theme }) => css`
    ${getIconStyles(theme)};
    .icon-fill {
      fill: ${theme.colors.blue.blue}; /** TODO: nmx-pcg and darkTheme will inherit the same styles */
    }
  `}
`;
