import styled, { css, ThemeContext } from 'styled-components';
import { useContext } from 'react';
import rem from 'polished/lib/helpers/rem';
// template components
import { Container, Col, Row } from '../../foundations/Grid';
import Picture from '../../components/Picture';
import Typography from '../../foundations/Typography';
import { getSectionBackgroundColor } from '../../components/SectionContainer/styles';
import { getSectionTextColor } from '../../foundations/Typography/styles';

// Loaded for the BG Image variant
export const BGImageContainerExperienceCalloutStyled = styled.div`
  ${({ theme, forFullWidthContainer, imageUrl, disableLazyLoad }) => {
    let width = '100%';
    let height = '100%';
    let position = 'relative';
    const largeUp = theme.largeUp();
    const mediumDown = theme.mediumDown();

    if (forFullWidthContainer && largeUp) {
      // if it IS fullWidth, set the BG imageUrl to the full screen orientation
      width = rem(460);
      position = 'absolute';
    }

    if (mediumDown) {
      height = rem(180);
    }

    return css`
      position: ${position};
      top: 0;
      left: 0;
      height: ${height};
      width: ${width};
      background-size: cover;
      background-position: center;
      ${disableLazyLoad && css`background-image: url(${imageUrl})`};
      display: flex;
      align-items: center;
      padding: 0;
    `;
  }}
`;

export const ButtonLinkOverlayStyled = styled.a`
  width: 100%;
  height: 100%;
  opacity: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
`;

export const ColExperienceCalloutStyled = styled(Col)`
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0;
`;

export const ComponentContainerExperienceCalloutStyled = styled.div`
  ${({ component, theme, themeType, backgroundVariant, branding, isFullWidthContainer, withHoverShadow }) => {
    let thisHoverBorderColor;
    let thisHoverColor;
    let thisHoverBgColor;

    if (themeType === 'darkTheme') {
      thisHoverBorderColor = theme.colors.action.darkTheme.ctaTextSecondary;
      thisHoverColor = theme.colors.action.darkTheme.ctaTextSecondary;
      thisHoverBgColor = theme.colors.action.darkTheme.ctaBgSecondaryHover;
    } else {
      thisHoverBorderColor = theme.colors.action.lightTheme.ctaTextSecondary;
      thisHoverColor = theme.colors.action.lightTheme.ctaTextSecondary;
      thisHoverBgColor = theme.colors.action.lightTheme.ctaBgSecondaryHover;
    }

    return css`
      ${!isFullWidthContainer && 'position:relative'};
      transition: ${theme.effects.shadows.transition};
      background-color: ${getSectionBackgroundColor(backgroundVariant, branding, theme, themeType)};
      color: ${getSectionTextColor(component, theme, themeType)};
      &:hover {
        box-shadow: ${withHoverShadow ? theme.effects.shadows.hover : 'none'};
        text-decoration: none;
        .button-experience-callout-styled {
          /** Inner button Hover styles */ /** TODO: this should be a pseudo button (span or div) */
          border-color: ${thisHoverBorderColor};
          color: ${thisHoverColor};
          background-color: ${thisHoverBgColor};
        }
    `;
  }}
`;

export const ContainerExperienceCalloutStyled = styled(Container)`
  ${({ theme, themeType, backgroundVariant, branding, isFullWidthContainer, withBGImage }) => {
    let background = 'transparent';

    if (isFullWidthContainer || !theme.mediumUp()) {
      background = getSectionBackgroundColor(backgroundVariant, branding, theme, themeType);
    }

    return css`
      background-color: ${background};
      padding: 0;
      ${withBGImage && !isFullWidthContainer && 'position: relative;'}
    `;
  }}
`;

export const CTAContainerExperienceCalloutStyled = styled.div`
  ${({ theme, withBGImage }) => {
    const mediumUp = theme.mediumUp();
    let padding = `0 ${rem(32)} ${rem(48)}`;

    if (withBGImage || theme.mediumUp()) {
      padding = `${rem(48)} ${rem(32)}`;
    }

    return css`
      padding: ${padding};
      min-height: ${mediumUp ? rem(260) : 'unset'};
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    `;
  }}
`;

export const DescriptionCopyTypographyStyled = styled(Typography)`
  padding-bottom: ${rem(24)};
`;

export const ImageContainerExperienceCalloutStyled = styled.div`
  ${({ theme }) => {
    let padding = 0;
    const mediumDown = theme.mediumDown();
    const largeDown = theme.largeDown();

    if (mediumDown) {
      padding = `${rem(40)} ${rem(32)} ${rem(32)}`;
    } else if (largeDown) {
      padding = `0 0 0 ${rem(32)}`;
    }

    return css`
      position: relative;
      height: auto;
      width: 100%;
      display: flex;
      align-items: center;
      padding: ${padding};
      justify-content: center;
    `;
  }}
`;

export const PictureExperienceCalloutStyled = styled(Picture)`
  && {
    height: ${rem(200)};
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const RowStyled = styled(Row)`
  position: relative;
`;

export const SectionExperienceCalloutStyled = styled.section`
  && {
    ${({ theme, themeType, backgroundVariant, branding, hasFullWidthContainer, disableBottomPadding }) => {
    let thisMarginTop;
    let thisMarginBottom;
    let paddingHorizontal;
    const mediumUp = theme.mediumUp();
    const xlargeUp = theme.xlargeUp();

    if (hasFullWidthContainer) {
      thisMarginTop = 0;
      thisMarginBottom = 0;
      paddingHorizontal = 0;
    } else if (mediumUp) {
      thisMarginTop = rem(60);
      thisMarginBottom = disableBottomPadding ? 0 : rem(60);
    } else {
      thisMarginTop = rem(50);
      thisMarginBottom = disableBottomPadding ? 0 : rem(50);
    }

    if (!hasFullWidthContainer) {
      if (xlargeUp) {
        paddingHorizontal = 0;
      } else if (mediumUp) {
        paddingHorizontal = rem(16);
      } else {
        paddingHorizontal = rem(12);
      }
    }

    return css`
      position: relative;
      padding-left: ${paddingHorizontal};
      padding-right: ${paddingHorizontal};
      margin-top: ${thisMarginTop};
      margin-bottom: ${thisMarginBottom};
      max-width: unset;
      background-color: ${getSectionBackgroundColor(backgroundVariant, branding, theme, themeType)};
      @media print {
        display: none;
      }
    `;
  }}
  }
`;

export const TypographyTitleStyled = styled(Typography)`
  ${({ theme, hasCopy }) => {
    let bottomPadding;
    const mediumUp = theme.mediumUp();
    // if there is no text copy present beneath the header, change the bottom padding
    if (hasCopy) {
      if (mediumUp) {
        bottomPadding = rem(16);
      } else {
        bottomPadding = rem(12);
      }
    } else {
      bottomPadding = rem(24);
    }

    return css`
      padding-bottom: ${bottomPadding};
    `;
  }}
`;

/** Creates inline styles (to override stock Component Library styles) */
export const Styles = () => {
  const theme = useContext(ThemeContext);
  const mediumUp = theme.mediumUp();
  const largeUp = theme.largeUp();
  const thisWidthHeight = largeUp ? '80%' : '100%';
  return {
    IllustrationExperienceCalloutStyled: {
      display: 'flex',
      width: thisWidthHeight,
      height: thisWidthHeight,
      maxHeight: mediumUp ? 'unset' : rem(180),
      justifyContent: 'center',
    },
  };
};
