import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import { Container } from '../../../../foundations/Grid';
import Link from '../../../../components/Link';

export const ContainerNavBreadCrumbStyled = styled(Container)`
  ${({ theme }) => {
    const largeDown = theme.largeDown();
    const largeUp = theme.largeUp();

    // height config
    const thisHeaderHeight = largeUp ? 115 : 50; // default header height
    const thisHeaderBottomBorderHeight = largeUp ? 1 : 0;
    const thisInformationalBannerHeight = 0; // default informationalBanner height
    // if (showInformationalBanner) {
    //   thisInformationalBannerHeight = largeUp ? 64 : 50; // may vary based on variant // TODO: this is handled in InformationalBanner
    // }

    return css`
      &&& {
        /** Enable default container styles */
        position: relative;
        top: ${rem(thisHeaderHeight + thisHeaderBottomBorderHeight + thisInformationalBannerHeight)};
        width: 100%;
        max-width: 90rem; /** 1440px (header max-width) */
        margin: auto;
        padding: 0 0.75rem;
        flex: 0 0 auto;
        z-index: ${largeDown ? 'auto' : theme.zIndex.globalNavMain};
      }
    `;
  }}
`;

export const NavBreadCrumbStyled = styled.nav`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    const large1200Up = theme.large1200Up();
    const xlargeUp = theme.xlargeUp();

    let thisMarginLeft = 0;

    if (xlargeUp) {
      thisMarginLeft = rem(0);
    } else if (large1200Up) {
      thisMarginLeft = rem(18);
    }

    return css`
      width: 100%;
      height: ${mediumUp ? rem(53) : rem(40)};
      ${large1200Up && `margin-left: ${thisMarginLeft}`};
      padding-top: ${mediumUp ? rem(12) : rem(10)};
      z-index: ${theme.zIndex.globalNavMain};
    `;
  }}
`;

export const NavBreadCrumbListStyled = styled.ol`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const LinkContainerStyled = styled.li`
  ${({ theme, isSecondToLastItem }) => {
    const smallUp = theme.smallUp();
    return css`
      display: inline-flex;
      align-items: center;
      /** add separator always desktop */
      ${smallUp
      && css`
        &:not(:last-child) {
          &::after {
            position: relative;
            padding: 0 ${rem(8)};
            content: '/';
            font-weight: 300;
            color: ${theme.colors.neutral.grayDark10};
          }
        }
      `};
      /** add separator last 2 */
      ${!smallUp && !isSecondToLastItem
      && css`
        &:not(:last-child) {
          &::after {
            position: relative;
            padding: 0 ${rem(8)};
            content: '/';
            font-weight: 300;
            color: ${theme.colors.neutral.grayDark10};
          }
        }
      `};
    `;
  }}
`;

export const LinkStyled = styled(Link)`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    return css`
      && {
        height: auto;
        font-size: ${mediumUp ? rem(14) : rem(12)};
        line-height: ${mediumUp ? rem(21) : rem(18)};
        font-weight: 300;
        letter-spacing: ${rem(0.5)};
        color: ${theme.colors.neutral.grayDark10};
        text-decoration: none;
        &:hover {
          color: ${theme.colors.action.lightTheme.linkHover};
          text-decoration: underline;
        }
        &:visited {
          color: ${theme.colors.neutral.grayDark10};
        }
      }
    `;
  }}
`;

export const SecondToLastItemStyled = styled.span`
  font-size: ${rem(20)};
`;
