const transition = 'all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)';
const dp2 = '0 2px 8px 0 rgba(0, 0, 0, 0.1)';
const dp4 = '0 4px 16px 0 rgba(0, 0, 0, 0.1)';
const dp8 = '0 8px 32px 0 rgba(0, 0, 0, 0.3)';
const hover = '0 0 10px rgb(0 0 0 / 7%), 0 2px 10px rgb(0 0 0 / 10%)';
const inner = 'inset 0px -6px 6px 0 rgba(0, 0, 0, 0.1)'; // used on Informational Banner

export default {
  transition,
  dp2,
  dp4,
  dp8,
  hover,
  inner,
};
