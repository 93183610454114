// THIS MODULE HAS BEEN DEPRECATED AND SHOULD BE REMOVED IN THE NEXT MAJOR RELEASE
import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import Picture from '../../components/Picture';

export const DivStyledActionContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: ${rem(20)};
  text-decoration: underline;
  line-height: ${rem(19)};
  font-size: ${rem(14)};
  letter-spacing: ${rem(0.45)};
`;

export const DivStyledArticleCardContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    text-align: center;
    min-height: ${rem(350)};
    height: 100%;
    background-color: ${theme.colors.neutral.white};
    border-radius: ${rem(2)};
  `}
`;

export const DivStyledContent = styled.div`
  padding: ${rem(20)};
`;

export const DivStyledImageContainer = styled.div`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    return css`
      height: ${mediumUp ? '50%' : '60%'};
    `;
  }}
`;

export const PictureStyled = styled(Picture)`
  height: 100%;
  img {
    position: relative;
    display: block;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;
