import styled from 'styled-components';

// .preloaded-svgs from nmx-static
// moved to just inside closing body tag
const SvgStyled = styled.svg`
  position: absolute;
  overflow: hidden;
  width: 0; 
  height: 0;
`;

export default SvgStyled;
