import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import { Col } from '../../../../foundations/Grid';
import Picture from '../../../../components/Picture';

export const ColStyledDescriptiveText = styled(Col)`
  ${({ theme }) => {
    const smallUp = theme.smallUp();
    return css`
      text-align: ${smallUp ? 'left' : 'center'};
    `;
  }};
`;

export const ColStyledDownloadLinkContainer = styled(Col)`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    const mediumToLarge = theme.mediumToLarge();
    const largeUp = theme.largeUp();
    return css`
      max-width: ${mediumToLarge ? rem(150) : 'unset'};
      .nmx-icon.badge-appStore {
        position: relative;
        display: ${mediumUp ? 'block' : 'inline-block'};
        width: ${largeUp ? rem(150) : 'auto'};
        max-width: ${largeUp ? rem(130) : rem(150)};
        height: ${largeUp ? 'auto' : rem(45)};
        max-height: ${mediumUp ? 'unset' : rem(40)};
        margin-bottom: ${mediumUp ? rem(10) : 0};
        margin-left: ${mediumUp ? 0 : rem(10)};
      }
      .nmx-icon.badge-googlePlay {
        max-width: ${largeUp ? rem(150) : rem(170)};
        max-height: ${mediumUp ? rem(59.7) : rem(60)}; /** TODO: trim out Google Play built-in padding */
        margin-top: ${rem(-10)};
        margin-left: ${mediumUp ? rem(-10) : 0};
      }
    `;
  }};
`;

export const ListItemStyledDownLoadLink = styled.li`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    return css`
      display: ${mediumUp ? 'block' : 'inline-block'};
      vertical-align: top;
      text-align: ${mediumUp ? 'left' : 'center'};
    `;
  }};
`;

export const ColStyledQrCodeContainer = styled(Col)`
  margin-top: ${rem(-20)};
  padding-top: ${rem(5)};
`;

export const PictureStyledQrCode = styled(Picture)`
  ${({ theme }) => {
    const largeUp = theme.largeUp();
    return css`
      position: relative;
      width: ${largeUp ? 'auto' : rem(125)};
      max-width: ${largeUp ? rem(122) : rem(125)};
      height: auto;
      padding-bottom: ${rem(20)};
      align-self: center;
    `;
  }};
  
`;

export const ColStyledSocialLinks = styled(Col)`
  padding-top: ${rem(16)};
`;
