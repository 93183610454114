import Location from '@nmx/utils-frontend/dist/utilities/frontend/location';
import Session from '@nmx/utils-frontend/dist/utilities/frontend/session';
import {
  isEmail,
  isEmpty,
  isFirstName,
  isLastName,
  isPhoneNumber,
  isString,
  isZipCode,
} from '@nmx/utils-frontend/dist/utilities/validators';
import { sanitizePhone } from '@nmx/utils-frontend/dist/utilities/frontend/sanitize';

export const goals = [
  {
    name: 'Please select a goal...',
    value: '',
    disabled: false,
  },
  {
    name: 'buy a home',
    value: 'buy a home',
    disabled: false,
  },
  {
    name: 'save for college',
    value: 'save for college',
    disabled: false,
  },
  {
    name: 'save for retirement',
    value: 'save for retirement',
    disabled: false,
  },
  {
    name: 'protect my income',
    value: 'protect my income',
    disabled: false,
  },
  {
    name: 'plan for growing my family',
    value: 'plan for growing my family',
    disabled: false,
  },
  {
    name: 'protect my family',
    value: 'protect my family',
    disabled: false,
  },
  {
    name: 'grow my business',
    value: 'grow my business',
    disabled: false,
  },
  {
    name: 'grow my investments',
    value: 'grow my investments',
    disabled: false,
  },
  {
    name: "I'm not sure",
    value: "I'm not sure",
    disabled: false,
  },
];

export const incomes = [
  {
    name: 'Please select a range...',
    value: '',
    disabled: false,
  },
  {
    name: 'less than $40,000',
    value: 39999,
    disabled: false,
  },
  {
    name: '$40,001 - $75,000',
    value: 57500,
    disabled: false,
  },
  {
    name: '$75,001 - $100,000',
    value: 87500,
    disabled: false,
  },
  {
    name: '$100,001 - $150,000',
    value: 125000,
    disabled: false,
  },
  {
    name: '$150,001 - $200,000',
    value: 175000,
    disabled: false,
  },
  {
    name: '$200,001 - $300,000',
    value: 250000,
    disabled: false,
  },
  {
    name: 'more than $300,000',
    value: 300000,
    disabled: false,
  },
];

// form flow analytics names that map to form field names for adobe shared calls on lead and service request forms
export const analyticsFlowNames = {
  policy_owner: {
    type: 'Are You Currently Working Together',
    value: 'Completed',
  },
  first_name: {
    type: 'First Name',
    value: 'Completed',
  },
  last_name: {
    type: 'Last Name',
    value: 'Completed',
  },
  zip: {
    type: 'Zip',
    value: 'Completed',
  },
  phone: {
    type: 'Phone',
    value: 'Completed',
  },
  email: {
    type: 'Email',
    value: 'Completed',
  },
  comments: {
    type: 'Comments',
    value: 'Completed',
  },
  wealth_range: { type: 'Wealth Range' },
  income_range: { type: 'Income Range' },
  goal: { type: 'Goal' },
  age: {
    type: 'Age',
    value: 'Completed',
  },
};

export const getSourceAndTopic = (sourceParam, topicParam) => {
  let source = sourceParam;
  let topic = topicParam;

  if (Location.getQueryParam('intcmp') === 'fws-contact') {
    source = 'Field Website';
    topic = 'FR Contact Form';
  }

  return {
    source,
    topic,
  };
};

export const validatePolicyOwnerSelected = (policyOwner) => !!policyOwner && (policyOwner === 'Yes' || policyOwner === 'No');

export const validateFirstName = (firstName, required) => isFirstName(firstName, required);

export const validateLastName = (lastName, required) => isLastName(lastName, required);

export const validateZipCode = (zip) => !isEmpty(zip) && isString(zip) && isZipCode(zip);

export const validatePhone = (phone) => !isEmpty(phone) && isPhoneNumber(phone);

export const validateEmail = (email) => !isEmpty(email) && isEmail(email);

export const validateAge = (age, policyOwner) => {
  if (policyOwner === 'Yes') {
    return true;
  }
  const ageString = (age || '').toString();
  const ageStringCheck = !!ageString && !isEmpty(ageString) && /^[0-9]+$/.test(ageString);
  const ageInt = parseInt(ageString, 10);

  return ageStringCheck && ageInt > 0 && ageInt < 125;
};

export const validateGoal = (goal, policyOwner) => {
  if (policyOwner === 'Yes') {
    return true;
  }

  return !!goal && !isEmpty(goal);
};

export const validateIncomeRange = (incomeRange, policyOwner) => {
  if (policyOwner === 'Yes') {
    return true;
  }

  return !!incomeRange && !isEmpty(incomeRange);
};

// only required for leads when income range is below $40K
export const validateWealthRange = (wealthRange, incomeRange, policyOwner) => {
  if (policyOwner === 'Yes' || (!!incomeRange && incomeRange > 39999)) {
    return true;
  }

  return !!wealthRange && !isEmpty(incomeRange);
};

export const validateComments = (comments, policyOwner) => {
  if (policyOwner === 'Yes') {
    // comment field required
    return !isEmpty(comments) && isString(comments) && comments.length <= 2500;
  }
  return true;
};

export const buildServiceRequestPayload = (
  agentnumber,
  recaptchaReponseToken,
  source,
  topic,
  values,
) => {
  const sourceAndTopic = getSourceAndTopic(source, topic);

  return {
    agent_number: agentnumber,
    comments: values.comments,
    email: values.email,
    first_name: values.first_name,
    last_name: values.last_name,
    phone: sanitizePhone(values.phone),
    recaptcha_response_token: recaptchaReponseToken,
    source: sourceAndTopic.source,
    topic: sourceAndTopic.topic,
    utm_campaign: Session.get('utmCampaign'),
    utm_content: Session.get('utmContent'),
    utm_medium: Session.get('utmMedium'),
    utm_source: Session.get('utmSource'),
    utm_term: Session.get('utmTerm'),
    zip: values.zip,
  };
};

export const buildLeadPayload = (
  agentnumber,
  recaptchaReponseToken,
  source,
  topic,
  values,
) => {
  const sourceAndTopic = getSourceAndTopic(source, topic);
  let wealthRange;
  let districtOfficeNumber;
  let leadReferenceId;
  let matchingRequestId;
  let networkOfficeNumber;
  // do not pass wealthRange unless it is defined and was still a visible input field
  if (values.wealth_range && !!values.income_range && values.income_range <= 39999) {
    wealthRange = values.wealth_range;
  }

  try {
    if (typeof window !== 'undefined' && window.localStorage) {
      districtOfficeNumber = localStorage.getItem('districtOfficeNumber');
      leadReferenceId = localStorage.getItem('leadReferenceId');
      matchingRequestId = localStorage.getItem('matchingRequestId');
      networkOfficeNumber = localStorage.getItem('networkOfficeNumber');
    }
  } catch (error) {
    console.error('Failed to access localStorage:', error);
  }

  return {
    age: values.age,
    agent_number: agentnumber,
    application_id: values.application_id,
    campaign_name: values.campaign_name,
    comments: `I am ${values.age} years old and my biggest financial goal is to ${values.goal}`,
    district_office_number: districtOfficeNumber,
    email: values.email,
    first_name: values.first_name,
    goal: values.goal,
    income_range: values.income_range,
    last_name: values.last_name,
    leadReferenceId,
    matchingRequestId,
    network_office_number: networkOfficeNumber,
    page_url: window ? window.location.href : '',
    phone: sanitizePhone(values.phone),
    recaptcha_response_token: recaptchaReponseToken,
    source: sourceAndTopic.source,
    topic: sourceAndTopic.topic,
    utm_campaign: Session.get('utmCampaign'),
    utm_content: Session.get('utmContent'),
    utm_medium: Session.get('utmMedium'),
    utm_source: Session.get('utmSource'),
    utm_term: Session.get('utmTerm'),
    wealth_range: wealthRange,
    zip: values.zip,
  };
};
