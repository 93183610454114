import React from 'react';
import PropTypes from 'prop-types';
// components
import List from '../../components/List';

// styles
import {
  IconStyledDrawerCaret,
  LinkStyled,
  ListItemStyled,
} from './styles';

export const LinksListComponent = ({
  className,
  items,
  themeType,
}) => (
  <List
    className={className}
    reduced>
    {
      items.map((item, index) => (
        <ListItemStyled key={`list-item-${index + 1}`}>
          <LinkStyled
            linkUrl={item.linkUrl}
            target={item.target || '_self'}
            {...item.target === '_blank' && { rel: 'noopener' }}
            themeType={themeType}
            variant='primaryDeemphasize'>
            {item.linkText}&nbsp;
            <IconStyledDrawerCaret />
          </LinkStyled>
        </ListItemStyled>
      ))
    }
  </List>
);

LinksListComponent.propTypes = {
  /** backgroundVariant allows section to be different background color based on themeType */
  backgroundVariant: PropTypes.oneOf(['darkDefault']), // TODO: style lightTheme (, 'lightA', 'lightB', 'lightC')
  /** Optional classname for custom styling */
  className: PropTypes.string,
  /** required array of list Items */
  items: PropTypes.arrayOf(PropTypes.shape({
    linkText: PropTypes.string.isRequired,
    linkUrl: PropTypes.string.isRequired,
    target: PropTypes.string,
  })).isRequired,
  /** section theme, which determines color styles */
  themeType: PropTypes.oneOf(['darkTheme']), // TODO: style LightTheme ('lightTheme', 'nmx-pcg')
};

LinksListComponent.defaultProps = {
  backgroundVariant: 'darkDefault',
  themeType: 'darkTheme',
};

export default LinksListComponent;
