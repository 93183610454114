import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import Button from '../../../../components/Button';
import { Col } from '../../../../foundations/Grid';
import MagnifyingGlass from '../../../../foundations/Icon/icons/utility/MagnifyingGlass';

export const ColStyledNavContainer = styled(Col)`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    padding: 0;
    ${theme.largeUp()
      && css`
        width: auto;
        padding-right: 0;
        float: right;
      `}
    ${theme.xlargeUp()
      && css`
        padding: 0 ${rem(16)};
      `}
  `}
`;

export const NavStyled = styled.nav`
  ${({ theme }) => css`
    z-index: ${theme.zIndex.globalNavMain};
    width: 100%;
  `}
`;

export const LiStyled = styled.li`
  ${({ theme, mobileButton, isSearchEnabled }) => {
    const largeUp = theme.largeUp();
    return css`
      display: flex;
      height: ${rem(60)};
      align-items: center;
      border-bottom: 1px solid ${theme.colors.neutral.grayDark20};
      background-color: ${theme.colors.background.darkTheme.darkDefault};
      :first-child {
        &::after {
          position: relative;
          color: rgb(255, 255, 255);
          content: '|';
          font-weight: 100;
        }
      }
      :nth-child(3n) {
        &::after {
          ${largeUp
            && css`
              position: fixed;
              z-index: -1;
              top: 0;
              right: 0;
              display: block;
              width: 20%;
              height: ${rem(40)};
              background-color: rgb(14, 73, 123);
              content: '';
            `}
        }
      }
      ${isSearchEnabled && css` 
        :last-child {
              position: absolute;
              z-index: 330;
              right: 0;
              width: ${rem(75)};
              height: max-content;
              padding: 0;
              border-bottom: none;
              top: ${rem(40)};
        }
      `}
      ${largeUp
        && css`
          display: inline-flex;
          height: ${rem(40)};
          padding: 0;
          border-bottom: none;
        `}
      ${mobileButton
        && theme.largeDown()
        && css`
          border: none;
          margin: ${rem(16)} 0;
          background: none;
        `}
    `;
  }}
`;

export const AStyled = styled.a`
  ${({ theme, isFAFA }) => css`
      letter-spacing: 1px;
      font-weight: 500;
      height: 100%;
      font-size: ${rem(13)};
      line-height: ${rem(22)};
      color: rgb(255, 255, 255);
      text-decoration: none;
      align-self: center;
      padding: ${rem(10)} ${rem(15)} ${rem(8)};
      &:hover {
        color: ${theme.colors.neutral.white};
        text-decoration: underline;
      }
      ${isFAFA
        && css`
          border: ${rem(1.5)} solid ${theme.colors.gold.gold};
          background-color: ${theme.colors.gold.gold};
          text-rendering: optimizelegibility;
          cursor: pointer;
          display: inline-flex;
          margin: 0;
          background-clip: padding-box;
          border-radius: ${rem(2)};
          justify-content: center;
          align-items: center;
          color: ${theme.colors.neutral.grayDark20};
          height: ${rem(30)};
          transition: background-color 100ms ease-out 0s,
            color 100ms ease-out 0s, border-color 100ms ease-out 0s;
          :hover {
            text-decoration: none;
            color: ${theme.colors.neutral.grayDark20};
            background-color: ${theme.colors.gold.goldDark1};
            border-color: ${theme.colors.gold.goldDark1};
          }
        `}
      ${theme.largeDown()
        && css`
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          padding: 0 ${rem(16)};
        `}
      ${theme.largeUp()
        && css`
          align-self: center;
          padding: ${rem(10)} ${rem(15)} ${rem(8)};
        `}
    `}
`;

export const UlStyled = styled.ul`
  ${({ theme }) => css`
    display: block;
    ${theme.largeUp()
      && css`
        display: flex;
        justify-content: flex-end;
      `}
  `}
`;

export const SearchButtonStyled = styled(Button)`
  ${({ theme }) => css`
    display: ${theme.largeUp() ? 'flex' : 'none'};
    width: ${rem(75)};
    min-width: ${rem(75)};
    align-items: center;
    padding: 0;
    border: none;
    background-color: ${theme.colors.neutral.white};
    border-radius: 0;
    color: ${theme.colors.background.darkTheme.default};
    text-indent: ${rem(-9999)};
    ${theme.largeUp()
      && css`
        height: ${rem(75)};
        @media all and (-ms-high-contrast: none) {
          position: absolute;
          top: 0;
          display: flex;
          align-items: center;
        }
      `}
    &:hover {
      box-shadow: ${theme.effects.shadows.dp2};
      background-color: ${theme.colors.neutral.white};
      border-color: ${theme.colors.neutral.white};

      svg {
        .icon-fill {
          fill: ${theme.colors.gold.goldDark10};
        }
      }
    }
  `}
`;

export const DivStyled = styled.div`
  button {
    padding: ${rem(10)} ${rem(15)} ${rem(8)};
  }
`;

export const MagnifyingGlassStyled = styled(MagnifyingGlass)`
  ${({ theme }) => css`
    position: absolute;
    right: 0;
    width: 100%;
    height: ${rem(20)};
    .icon-fill {
      fill: ${theme.colors.blue.blue};
    }
    @media all and (-ms-high-contrast: none) {
      left: 0;
    }
  `}
`;
