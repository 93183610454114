import React from 'react';
import PropTypes from 'prop-types';

// components
import Link, { linkPropTypes } from '../../components/Link';

// icons
import YoutubeIcon from '../../foundations/Icon/icons/social/Youtube';

// styles
import {
  ColStyledIconContainer,
  ColStyledImageContainer,
  ColStyledLinkTextContainer,
  DivStyledContainer,
  DivStyledImageContainer,
  RowStyled,
  TypographyStyled,
} from './styles';

export const DocumentLinkComponent = ({
  children,
  className,
  iconObject: RenderIcon,
  imageUrl,
  imageVariant,
  isInternalPdf,
  isOutgoingLink,
  linkAriaLabel,
  linkId,
  linkUrl,
}) => (
  <DivStyledContainer className={className}>
    <Link
      // NOTE: don't full-on use {...linkProps} here because we don't want to override these specific Link children and variant props
      id={linkId} // change this to linkProps.id in 4.x.x
      ariaLabel={linkAriaLabel} // change this to linkProps.ariaLabel in 4.x.x
      isInternalPdf={isInternalPdf} // change this to linkProps.isInternalPdf in 4.x.x
      isOutgoingLink={isOutgoingLink} // change this to linkProps.isOutgoingLink in 4.x.x
      linkUrl={linkUrl} // change this to linkProps.linkUrl in 4.x.x
      variant='primaryDeemphasize'>
      <RowStyled>
        {imageVariant === 'icon' && (
          <ColStyledIconContainer
            xsmall={4}
            large={3}>
            <RenderIcon />
          </ColStyledIconContainer>
        )}
        {imageVariant === 'image' && (
          <ColStyledImageContainer xsmall={4}>
            <DivStyledImageContainer
              id={`${linkId}-background-image-container`} // change this to linkProps.id in 4.x.x
              backgroundImage={imageUrl} />
          </ColStyledImageContainer>
        )}
        <ColStyledLinkTextContainer xsmall={8}>
          <TypographyStyled weight={500}>
            {children} {/** change this to linkProps.children in 4.x.x */}
          </TypographyStyled>
        </ColStyledLinkTextContainer>
      </RowStyled>
    </Link>
  </DivStyledContainer>
);

DocumentLinkComponent.propTypes = {
  /** required description text */
  children: PropTypes.node.isRequired, // deprecated
  /** optional additional className added to parent container */
  className: PropTypes.string,
  /** select between the imageVariant types */
  imageVariant: PropTypes.oneOf(['icon', 'image']),
  /** coincides with imageVariant='icon', iconObject must be supplied */
  iconObject: PropTypes.any,
  /** coincides with imageVariant='image', imageUrl must be supplied */
  imageUrl: PropTypes.any,
  /** optional isInternalPdf boolean */
  isInternalPdf: PropTypes.bool, // deprecated
  /** optional isOutgoingLink boolean */
  isOutgoingLink: PropTypes.bool, // deprecated
  /** optional aria-label of link */
  linkAriaLabel: PropTypes.any, // deprecated
  /** required id of the link */
  linkId: PropTypes.string.isRequired, // deprecated
  /** The object containing the Link propType shape */
  linkProps: PropTypes.shape(linkPropTypes), // add isRequired in 4.x.x
  /** required URL of link */
  linkUrl: PropTypes.string.isRequired, // deprecated
};

DocumentLinkComponent.defaultProps = {
  iconObject: YoutubeIcon,
  imageVariant: 'icon',
};

export default DocumentLinkComponent;
