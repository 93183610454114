import React from 'react';
import PropTypes from 'prop-types';
import { TypographyHeadingWithLineStyled } from './styles';

export const HeadingWithLineComponent = ({
  centeredLine,
  className,
  headingText,
}) => (
  <TypographyHeadingWithLineStyled
    className={className}
    component="h3"
    variant="h6"
    centeredLine={centeredLine}>
    {headingText}
  </TypographyHeadingWithLineStyled>
);

HeadingWithLineComponent.propTypes = {
  centeredLine: PropTypes.bool,
  /** optional additional className */
  className: PropTypes.string,
  /** Required heading text of the Module */
  headingText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
};

export default HeadingWithLineComponent;
