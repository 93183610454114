import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import Typography from '../../../../foundations/Typography';

export const SectionStyled = styled.section`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    return css`
      padding-top: ${mediumUp ? rem(24) : rem(32)};
      padding-bottom: ${rem(32)};
      .nmx-col {
        @media print {
          max-width: 100%;
          flex: 0 100%;
        }
      }
    `;
  }};
`;

export const TypographyStyledCopyright = styled(Typography)`
  && {
    line-height: ${rem(30)};
  }
`;

export const TypographyStyledCopyrightSmall = styled(Typography)`
  && {
    font-size: 80%;
  }
`;
