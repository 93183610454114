// deprecated
import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from '../../../foundations/Grid';
import FrPicture from './FrPicture';
import Typography from '../../../foundations/Typography';
import Link from '../../../components/Link';

import { PictureColStyled } from './styles';

const DefaultCard = ({
  defaultCtaText,
  defaultDescriptionText,
  defaultHeadingText,
  defaultFrPicture,
  themeType,
}) => {
  const content = (
    <>
      <Typography
        component='h2'
        themeType={themeType}>
        {defaultHeadingText}
      </Typography>
      <>
        {defaultDescriptionText}
      </>
      <Link
        className='nmx-uppercase'
        linkUrl='/find-a-financial-advisor/'
        variant='buttonLinkPrimary'
        themeType={themeType}>{defaultCtaText}</Link>
    </>
  );

  return defaultFrPicture ? (
    <Row align="center">
      <PictureColStyled
        xsmall={12}
        medium={3}>
        {/*
          I thought about moving the width 100% into the styled component itself,
          but left it out in case we have a scenario where we don't want the width to be 100%
        */}
        <FrPicture
          frPicture={defaultFrPicture}
          altText='picture of a financial representative' // TODO: make this dynamic?
          pictureStyles={{ // TODO: this should be moved into styles
            width: '100%',
            height: 'auto',
          }}
          themeType={themeType}
        />
      </PictureColStyled>
      <Col
        xsmall={12}
        mediumOffset={1}
        medium={7}>
        {content}
      </Col>
    </Row>
  ) : (
    <Row styles={{ textAlign: 'center' }}>
      <Col
        xsmall={12}
        small={10}
        smallOffset={1}
        medium={8}
        mediumOffset={2}>
        {content}
      </Col>
    </Row>
  );
};

DefaultCard.propTypes = {
  /** inner text of CTA Link for default variant */
  defaultCtaText: PropTypes.string,
  /** description text for the default variant - will accept fragments and special characters */
  defaultDescriptionText: PropTypes.any,
  /** image for the default variant */
  defaultFrPicture: PropTypes.oneOf(['default', 'danNguyen', 'holleeKier', 'johnWhite', 'kellerLindler', 'leoTucker', 'trevorSmith']), // TODO: get this to work in Storybook
  /** heading text for the default variant */
  defaultHeadingText: PropTypes.string,
  // defaultSecondaryText: PropTypes.string,
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme', 'nmx-pcg']),
};

DefaultCard.defaultProps = {
  defaultCtaText: "Let's Talk",
  defaultDescriptionText: <><Typography
    weight={200}
    nextElement='button'>Our advisors are here to give you more of the information you want, and the knowledge you never knew you needed. To get to your next goal, and the next.</Typography></>,
  defaultHeadingText: 'Take the next step.',
  themeType: 'lightTheme',
};

export default DefaultCard;
