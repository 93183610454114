import React, { useContext, useEffect, useRef, useState } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import { NavPrimaryStyled } from '../../styles';
import { AStyled, ButtonStyled, LiStyled, UlStyled, LiStyledMobile, SpanStyledMobile } from './styles';
import AssistiveText from '../../../../components/AssistiveText';

const PrimaryNavigationItemConfig = {
  path: PropTypes.string,
  label: PropTypes.string.isRequired,
  isHidden: PropTypes.bool,
};
PrimaryNavigationItemConfig.subNavs = PropTypes.arrayOf(PropTypes.shape(PrimaryNavigationItemConfig));

function getSubIdFromName(name) {
  return name.replace(/ /g, '-').toLowerCase();
}

export const handleMouseEvent = (item, updateNavStates, largeDown, newState) => {
  if (largeDown) {
    return;
  }
  updateNavStates(getSubIdFromName(item.label), newState);
};

export const getLevelName = (level) => {
  switch (level) {
    case 1:
      return 'primary';
    case 2:
      return 'sub';
    case 3:
      return `sub-${level - 1}`;
    default:
      return 'primary';
  }
};

function hasSubNavs(item) {
  return item.subNavs;
}

const removePrefix = (value, prefix) => (value.startsWith(prefix) ? value.slice(prefix.length) : value);
const stripTrailingSlash = (str) => (str.endsWith('/') ? str.slice(0, -1) : str);

export const NavigationLink = ({ item, level, state, updateClickStates, parentID, refs, envConfig }) => {
  const theme = useContext(ThemeContext);
  const largeDown = theme.largeDown();
  const ref = useRef();
  const location = stripTrailingSlash(removePrefix(window?.location?.href, document.baseURI));

  useEffect(() => {
    if (refs) {
      if (level === 1) {
        refs.current.push({
          ref: ref.current,
          id: getSubIdFromName(item.label),
          subNavs: [],
        });
      } else {
        refs.current.find((r) => r.id === parentID).subNavs.push({
          ref: ref.current,
          id: getSubIdFromName(item.label),
          subNavs: [],
        });
      }
    }
  }, []);

  const onClick = (e) => {
    if (largeDown && hasSubNavs(item)) {
      e.preventDefault();
      updateClickStates(getSubIdFromName(item.label), true); // show subnav menu
    }
  };

  return (<AStyled
    basePath={envConfig.basePath}
    nmxTemplateVersion={envConfig.nmxTemplateVersion}
    ref={ref}
    isHover={state.hover}
    isClicked={state.click}
    isFocused={state.focus}
    onClick={onClick}
    role='menuitem'
    itemProp='url'
    tabIndex={level === 1 ? 0 : -1}
    aria-haspopup={hasSubNavs(item) ? 'true' : 'false'}
    href={item.path}
    hasSubNavs={hasSubNavs(item)}
    id={`nmx-nav-link-${getLevelName(level)}-${getSubIdFromName(item.label)}`}
    level={level}
    aria-expanded={state.hover}
    {...(location === item.path ? { 'aria-current': 'page' } : {})}
  >
    {item.label}
  </AStyled>
  );
};

export const PrimaryNavigationItems = ({ itemConfig, navStates, updateNavStates, updateClickStates, refs, offsetTop, envConfig }) => {
  const level = 1;

  return itemConfig.map((item) => (
    <PrimaryNavigationItem
      envConfig={envConfig}
      refs={refs}
      itemConfig={item}
      level={level}
      navStates={navStates}
      updateNavStates={updateNavStates}
      updateClickStates={updateClickStates}
      key={item.label}
      offsetTop={offsetTop}
    />
  ));
};

export const PrimaryNavigationItem = ({ itemConfig, level, navStates, updateNavStates, updateClickStates, refs, offsetTop, envConfig }) => {
  const theme = useContext(ThemeContext);
  const largeDown = theme.largeDown();

  const onMouseEnter = () => {
    handleMouseEvent(itemConfig, updateNavStates, largeDown, true);
  };

  const onMouseLeave = () => {
    handleMouseEvent(itemConfig, updateNavStates, largeDown, false);
  };

  const id = getSubIdFromName(itemConfig.label);

  return (
    <LiStyled
      level={level}
      role='none'
      itemProp='name'
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      key={`${getLevelName(level)}-id`}>
      <NavigationLink
        envConfig={envConfig}
        refs={refs}
        item={itemConfig}
        level={level}
        state={navStates[id]}
        updateClickStates={updateClickStates}
      />
      {itemConfig.subNavs && (
        <UlStyled
          role='menu'
          isVisible={navStates[id].hover}
          isClicked={navStates[id]?.click}
          aria-label={`${itemConfig.label} submenu`}
        >
          <SubNavigationItems
            envConfig={envConfig}
            itemConfig={itemConfig.subNavs}
            level={level + 1}
            navStates={navStates}
            updateNavStates={updateNavStates}
            updateClickStates={updateClickStates}
            parentID={getSubIdFromName(itemConfig.label)}
            refs={refs}
            offsetTop={offsetTop}
          />
        </UlStyled>
      )}
    </LiStyled>
  );
};

export const getBackButtonName = (level, item) => {
  if (level > 2) {
    return `Back to ${item.label}`;
  }
  return 'Back to Main Menu';
};

export const SubNavigationItems = ({ itemConfig, level, navStates, updateNavStates, updateClickStates, parentID, refs, offsetTop, envConfig }) => {
  const backButtonClick = () => {
    updateClickStates(parentID, false);
  };

  return [
    <LiStyledMobile
      role='none'
      key={`li-sub-nav-back-to-${parentID}`}
    >
      <ButtonStyled
        onClick={backButtonClick}
        type='button'
        variant='tertiary'
        id={`button-sub-nav-back-to-${parentID}`}
        tabIndex='-1'
        role='menuitem'
        key={`button-sub-nav-back-to-${parentID}`}
      >
        {getBackButtonName(level, itemConfig)}
        <SpanStyledMobile offsetTop={offsetTop}/>
      </ButtonStyled>
    </LiStyledMobile>,
    itemConfig.map((item) => (
      <SubNavigationItem
        envConfig={envConfig}
        item={item}
        level={level}
        refs={refs}
        navStates={navStates}
        updateNavStates={updateNavStates}
        updateClickStates={updateClickStates}
        parentID={parentID}
        key={item.label}
      />
    )),
  ];
};

export const SubNavigationItem = ({ item, level, navStates, updateNavStates, updateClickStates, refs, parentID, envConfig }) => {
  const theme = useContext(ThemeContext);
  const largeDown = theme.largeDown();

  const onMouseEnter = () => {
    handleMouseEvent(item, updateNavStates, largeDown, true);
  };

  const onMouseLeave = () => {
    handleMouseEvent(item, updateNavStates, largeDown, false);
  };

  return (
    <LiStyled
      level={level}
      role='none'
      itemProp='name'
      onMouseEnter={onMouseEnter}
      onFocus={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onBlur={onMouseLeave}
      key={`${getLevelName(level)}-${getSubIdFromName(item.label)}`}>
      <NavigationLink
        envConfig={envConfig}
        item={item}
        level={level}
        parentID={parentID}
        refs={refs}
        state={navStates[getSubIdFromName(item.label)]}
        updateClickStates={updateClickStates}
      />
    </LiStyled>);
};

export const isTabKey = (e) => e?.key === 'Tab' && !e.shiftKey;
export const isShiftTabKey = (e) => e?.key === 'Tab' && e.shiftKey;

export const findReferenceParent = (refs, id) => {
  const currentReference = refs.find((ref) => ref.subNavs?.some((subRef) => subRef.ref.id === id));
  if (currentReference) {
    return currentReference;
  }
  return null;
};

const getIndexUpdateVerticalValue = (direction) => {
  if (direction === 'down') {
    return 1;
  }
  if (direction === 'up') {
    return -1;
  }
  return 0;
};

const isLevelOneWithoutSubNavs = (currentLevel, currentReference) => currentLevel === 1 && currentReference.subNavs.length === 0;
const isLevelOneWithSubNavs = (currentLevel, currentReference) => currentLevel === 1 && currentReference.subNavs.length > 0;

export const findNextVerticalReference = (refs, id, level, direction, previousAndNextLevel) => {
  if (refs.length === 0) {
    return null;
  }

  const currentLevel = level || 1;
  const indexValue = getIndexUpdateVerticalValue(direction);

  const currentReferenceIndex = refs.findIndex((ref) => ref.ref.id === id);
  const currentReference = refs[currentReferenceIndex];

  // If the current reference is not found, search the subnavs for the reference
  if (!currentReference) {
    let nextReference = null;
    refs.forEach((ref) => {
      const nextRef = findNextVerticalReference(ref.subNavs, id, currentLevel + 1, direction, previousAndNextLevel);
      if (nextRef) {
        nextReference = nextRef;
      }
    });
    return nextReference;
  }
  // If the current reference is found, return the next reference
  if (isLevelOneWithSubNavs(currentLevel, currentReference)) {
    previousAndNextLevel(currentLevel, currentLevel + 1);
    if (direction === 'down') {
      return currentReference.subNavs[0];
    }
    if (direction === 'up') {
      return currentReference.subNavs[currentReference.subNavs.length - 1];
    }
  }
  if (isLevelOneWithoutSubNavs(currentLevel, currentReference)) {
    return null;
  }
  if (refs.length === currentReferenceIndex + indexValue) {
    previousAndNextLevel(currentLevel, currentLevel);
    return refs[0];
  }
  if (currentReferenceIndex + indexValue < 0) {
    previousAndNextLevel(currentLevel, currentLevel);
    return refs[refs.length - 1];
  }
  previousAndNextLevel(currentLevel, currentLevel);
  return refs[currentReferenceIndex + indexValue];
};

export const findNextHorizontalReference = (refs, id, level, direction, previousAndNextLevel) => {
  const indexValue = direction === 'right' ? 1 : -1;
  if (refs.length === 0) {
    return null;
  }

  const currentReferenceIndex = refs.findIndex((ref) => ref.ref.id === id);
  if (currentReferenceIndex === -1) {
    const foundRef = refs.find((ref) => ref.subNavs.length !== 0
      && ref.subNavs.some((subRef) => subRef.ref.id === id));

    if (foundRef) {
      previousAndNextLevel(level + 1, level);
      const index = refs.indexOf(foundRef);
      return refs[index + indexValue];
    }
  }

  previousAndNextLevel(level, level);
  if (refs.length === currentReferenceIndex + indexValue) {
    return refs[0];
  }
  if (currentReferenceIndex + indexValue < 0) {
    return refs[refs.length - 1];
  }
  return refs[currentReferenceIndex + indexValue];
};

export const updateHorizontalNavStateMovement = (updateNavStates, getNavState, e) => (currentRef, nextRef, refs, previousLevel, nextLevel) => {
  if (previousLevel === 1 && nextLevel > 1) {
    // This case should never happen. If it does something has gone wrong.
    return;
  }
  // If the last element is focused and the direction is right, blur the focused element
  if (isTabKey(e) && refs.current[refs.current?.length - 1].ref.id === currentRef.ref.id) {
    updateNavStates(currentRef.id, false, false);
    currentRef.ref.blur();
    return;
  }
  // If the first element is focused and the direction is left, blur the focused element
  if (isShiftTabKey(e) && refs.current[0].ref.id === currentRef.ref.id) {
    updateNavStates(currentRef.id, false, false);
    currentRef.ref.blur();
    return;
  }
  if (previousLevel > 1 && nextLevel === 1) {
    updateNavStates(nextRef.id, true, true);
    updateNavStates(currentRef.id, false, false);
    updateNavStates(findReferenceParent(refs.current, currentRef.ref.id).id, false, false);
    if (isTabKey(e) || isShiftTabKey(e)) {
      updateNavStates(nextRef.id, false, true);
    }
  } else if (getNavState(currentRef.id).hover && !isTabKey(e) && !isShiftTabKey(e)) {
    updateNavStates(currentRef.id, false, false);
    updateNavStates(nextRef.id, true, true);
  } else {
    updateNavStates(currentRef.id, false, false);
    updateNavStates(nextRef.id, false, true);
  }
};

export const updateVerticalNavStateMovement = (updateNavStates) => (currentRef, nextRef, refs, previousLevel, nextLevel) => {
  if (previousLevel === 1 && nextLevel > 1) {
    updateNavStates(currentRef.id, true, false);
  }
};

export const findCurrentReference = (refs, id) => {
  const currentReference = refs?.find((ref) => ref.ref.id === id);
  if (currentReference) {
    return currentReference;
  }
  for (let i = 0; i < refs?.length; i += 1) {
    const nextReference = findCurrentReference(refs[i].subNavs, id);
    if (nextReference) {
      return nextReference;
    }
  }
  return null;
};

export const handleFocusMovement = (e, refs, direction, nextReferenceFunc, updateStates) => {
  // Find the currently focused element
  let previousLevel = 1;
  let nextLevel = 1;
  const focusedRef = findCurrentReference(refs.current, document.activeElement.id);
  if (!focusedRef) {
    return;
  }
  const nextElement = nextReferenceFunc(refs.current, focusedRef.ref.id, 1, direction, (prevLevel, nLevel) => { previousLevel = prevLevel; nextLevel = nLevel; });
  if (!nextElement) {
    return;
  }
  updateStates(focusedRef, nextElement, refs, previousLevel, nextLevel);
  if (!isTabKey(e) && !isShiftTabKey(e)) {
    nextElement.ref.focus();
  } else if (isShiftTabKey(e) && previousLevel > 1) {
    e.preventDefault();
    nextElement.ref.focus();
  }
};

export const handleEnterKey = (e, refs, updateNavStates) => {
  const focusedRef = findCurrentReference(refs.current, document.activeElement.id);
  if (focusedRef.subNavs?.length > 0) {
    handleFocusMovement(e, refs, 'down', findNextVerticalReference, updateVerticalNavStateMovement(updateNavStates));
  } else {
    document.activeElement.click();
  }
};

export const findNextLetterMatchReference = (refs, id, level, letter, previousAndNextLevel) => {
  const currentRef = findCurrentReference(refs, id);
  const currentRefIndex = refs.findIndex((item) => item.ref.id === currentRef.ref.id);

  const parentRef = findReferenceParent(refs, id);

  if (parentRef) {
    const matchingSubnavElement = parentRef.subNavs[parentRef.subNavs.findIndex((ref) => ref.id !== currentRef.id && ref.id.toLowerCase().startsWith(letter))];
    if (matchingSubnavElement) {
      previousAndNextLevel(level, level + 1);
      return matchingSubnavElement;
    }
  } else {
    const matchingElement = refs[refs.findIndex((ref, index) => index > currentRefIndex && ref.id.toLowerCase().startsWith(letter))];
    if (matchingElement) {
      previousAndNextLevel(level, level);
      return matchingElement;
    }
    const nextMatchingElement = refs[refs.findIndex((ref) => ref.id.toLowerCase().startsWith(letter))];
    if (nextMatchingElement) {
      previousAndNextLevel(level, level);
      return nextMatchingElement;
    }
  }

  return null;
};

export const updateLetterPressNavStateMovement = (updateNavStates) => (currentRef, nextRef) => {
  updateNavStates(currentRef.id, false, false);
  updateNavStates(nextRef.id, false, true);
};

export const handleClickOutside = (event, refs, getNavState, updateNavStates) => {
  const activeRefs = refs.current.filter((ref) => {
    const navState = getNavState(ref.id);
    return navState.hover || navState.focus;
  });

  // If the click was inside the subnav menu, do nothing
  if (event.target?.closest('[role="menu"]')) {
    return;
  }

  if (activeRefs.length > 0 && !activeRefs.some((ref) => ref.ref.contains(event.target))) {
    // If the click was outside the activeRefs, update all navigation states to false
    activeRefs.forEach((ref) => {
      updateNavStates(ref.id, false, false);
    });
  }
};

export const handleEscapeKey = (e, refs, getNavState, updateNavStates) => {
  const currentRef = findCurrentReference(refs.current, document.activeElement.id);
  if (!currentRef) {
    return;
  }
  const parentRef = findReferenceParent(refs.current, currentRef.ref.id);
  if (!parentRef) {
    updateNavStates(currentRef.id, false, true);
    return;
  }
  updateNavStates(currentRef.id, false, false);
  updateNavStates(parentRef.id, false, true);
  parentRef.ref.focus();
};

export const handleResize = (largeUp, navStates, setNavStates) => {
  if (window.innerWidth >= largeUp) {
    setNavStates(Object.keys(navStates).reduce((acc, key) => {
      acc[key] = {
        ...navStates[key],
        hover: navStates[key].click,
        click: navStates[key].click,
        focus: navStates[key].focus,
      };
      return acc;
    }, {}));
  }
};

export const PrimaryNavigationMenu = (
  {
    config,
    envConfig,
  },
) => {
  const navItems = config;
  const theme = useContext(ThemeContext);
  const largeUp = theme.largeUp();
  const ref = useRef();

  const states = {};
  navItems.forEach((item) => {
    states[getSubIdFromName(item.label)] = {
      hover: false,
      click: undefined,
      focus: false,
    };
    if (item.subNavs) {
      item.subNavs.forEach((subItem) => {
        states[getSubIdFromName(subItem.label)] = {
          hover: false,
          click: undefined,
          focus: false,
        };
      });
    }
  });

  const [navStates, setNavStates] = useState(states);
  const [keyDown, setKeyDown] = useState(false);
  const [offsetTop, setOffsetTop] = useState(0);

  const getNavState = (id) => navStates[id];

  const updateNavStates = (id, hover, focus) => {
    setNavStates((prevNavStates) => {
      const newNavStates = { ...prevNavStates };
      const previousNavState = prevNavStates[id];
      previousNavState.hover = hover;
      previousNavState.focus = focus;
      newNavStates[id] = previousNavState;
      return newNavStates;
    });
  };

  const updateClickStates = (id) => {
    setNavStates((prevNavStates) => {
      const newNavStates = { ...prevNavStates };
      const previousNavState = prevNavStates[id];
      previousNavState.click = !prevNavStates[id]?.click;
      newNavStates[id] = previousNavState;
      return newNavStates;
    });
  };

  const refs = useRef([]);
  const handleKeyDown = (e) => {
    if (keyDown && !e.shiftKey) return;

    setKeyDown(true);

    if (e.key === 'Escape') {
      handleEscapeKey(e, refs, getNavState, updateNavStates);
    }

    if (e.key === 'ArrowRight') {
      e.preventDefault();
      handleFocusMovement(e, refs, 'right', findNextHorizontalReference, updateHorizontalNavStateMovement(updateNavStates, getNavState, e));
    }
    if (e.key === 'ArrowLeft') {
      e.preventDefault();
      handleFocusMovement(e, refs, 'left', findNextHorizontalReference, updateHorizontalNavStateMovement(updateNavStates, getNavState, e));
    }

    if (e.key === 'ArrowDown') {
      e.preventDefault();
      handleFocusMovement(e, refs, 'down', findNextVerticalReference, updateVerticalNavStateMovement(updateNavStates));
    }
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      handleFocusMovement(e, refs, 'up', findNextVerticalReference, updateVerticalNavStateMovement(updateNavStates));
    }
    if (e.key === 'Enter') {
      e.preventDefault();
      handleEnterKey(e, refs, updateNavStates);
    }
    if (e.key === 'Tab' && !e.shiftKey) {
      handleFocusMovement(e, refs, 'right', findNextHorizontalReference, updateHorizontalNavStateMovement(updateNavStates, getNavState, e));
    }
    if (e.key === 'Tab' && e.shiftKey) {
      handleFocusMovement(e, refs, 'left', findNextHorizontalReference, updateHorizontalNavStateMovement(updateNavStates, getNavState, e));
    }
    if (e.key.length === 1 && e.key.match(/[a-z]/i)) {
      e.preventDefault();
      handleFocusMovement(e, refs, e.key, findNextLetterMatchReference, updateLetterPressNavStateMovement(updateNavStates));
    }
  };

  const handleKeyUp = () => {
    setKeyDown(false);
  };

  useEffect(() => {
    const boundHandleClickOutside = (event) => handleClickOutside(event, refs, getNavState, updateNavStates);
    window.addEventListener('click', boundHandleClickOutside);
    return () => {
      window.removeEventListener('click', boundHandleClickOutside);
    };
  }, [refs, getNavState, updateNavStates]);

  useEffect(() => {
    const handleResizeWithNavStates = () => {
      handleResize(window.innerWidth, navStates, setNavStates);
      setOffsetTop(ref.current?.offsetTop);
    };
    window.addEventListener('resize', handleResizeWithNavStates);
    return () => {
      window.removeEventListener('resize', handleResizeWithNavStates);
    };
  }, [navStates, setNavStates, largeUp]);

  useEffect(() => {
    setOffsetTop(ref.current?.offsetTop);
  }, []);

  return (
    <NavPrimaryStyled
      ref={ref}
      aria-label='Primary Navigation'
      className='nmx-nav-primary'
      id='nmx-nav-primary'
      itemScope
      itemType='https://schema.org/SiteNavigationElement'
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
    >
      <AssistiveText component='h6'>
        Primary Navigation
      </AssistiveText>
      <ul
        role={largeUp ? 'menubar' : 'menu'}
        className='reduced'
      >
        <PrimaryNavigationItems
          envConfig={envConfig}
          refs={refs}
          itemConfig={navItems}
          navStates={navStates}
          updateNavStates={updateNavStates}
          updateClickStates={updateClickStates}
          offsetTop={offsetTop}
        />
      </ul>
    </NavPrimaryStyled>
  );
};

PrimaryNavigationMenu.propTypes = {
  config: PropTypes.arrayOf(PropTypes.shape(PrimaryNavigationItemConfig)).isRequired,
  envConfig: PropTypes.shape({
    basePath: PropTypes.string.isRequired,
    nmxTemplateVersion: PropTypes.string.isRequired,
  }),
};

export default PrimaryNavigationMenu;
