import React from 'react';
import PropTypes from 'prop-types';

// TODO: this whole Component could be structured like the other Components in the ComponentLibrary, with a styles.js, etc.

/**
 * <b>This component should only be used when absolutely necessary, as it can lead to inconsistent spacing.</b></br>
 * Box component that provides padding and margin properties.
 * The padding and margin values are multiplied by a constant to provide a consistent spacing system.
 *
 * @component
 * @example
 * <Box sx={{ pb: 1, pt: 2, mb: 3, mt: 4 }}>
 *   <p>Content inside the box</p>
 * </Box>
 */
const Box = ({ sx, children }) => {
  const multiplier = 16;
  const { pb, pt, mb, mt } = sx || {};
  const paddingBottom = pb ? { paddingBottom: pb * multiplier } : {};
  const paddingTop = pt ? { paddingTop: pt * multiplier } : {};
  const marginBottom = mb ? { marginBottom: mb * multiplier } : {};
  const marginTop = mt ? { marginTop: mt * multiplier } : {};

  const styles = {
    ...paddingBottom,
    ...paddingTop,
    ...marginBottom,
    ...marginTop,
  };

  return (
    <div style={styles}>
      {children}
    </div>
  );
};

/**
 * Prop Types for the Box component.
 */
Box.propTypes = {
  /**
   * An object containing padding and margin values.
   */
  sx: PropTypes.shape({
    pb: PropTypes.number,
    pt: PropTypes.number,
    mb: PropTypes.number,
    mt: PropTypes.number,
  }),
};

/**
 * Default props for the Box component.
 */
Box.defaultProps = {
  pb: 0,
  pt: 0,
  mb: 0,
  mt: 0,
};

export default Box;
