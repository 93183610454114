import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import Colors from '../../foundations/theme/colors';
// components
import { Row } from '../../foundations/Grid';
import Typography from '../../foundations/Typography';
// styles
import {
  AnchorStyledArticleContainer,
  ArticleStyledDynamicCallout,
  ButtonStyledDynamicCalloutOverlay,
  ColStyledContentContainer,
  ColStyledImageContainer,
  DivStyledBannerImageContainer,
  DivStyledCardImage,
  DivStyledContentContainer,
  DivStyledDynamicCalloutBanner,
  DivStyledDynamicCalloutCard,
  DivStyledDynamicCalloutCardContainer,
  IconStyledVisualLink,
  LinkStyledDynamicCalloutBannerButton,
  SpanStyledArticleFlag,
  TypographyStyledArticleFlag,
  TypographyStyledCard,
  TypographyStyledMetadata,
  TypographyStyledVisualLink,
} from './styles';

const buttonTextDefault = 'Get started';
const jsRedirect = (urlPath) => {
  window.location.href = urlPath;
};

export const DynamicCalloutComponent = ({
  actionId,
  assistiveText,
  backgroundVariant,
  branding,
  buttonText,
  className,
  disableLazyLoad,
  flag,
  image,
  imageWebp,
  isOutgoingLink,
  metadata,
  orientation,
  tag,
  themeType,
  title,
  url,
  visualLink,
}) => {
  // Adding appropriate color based on the flag prop. Primary is default.
  const flagPrimary = Colors.sky.sky;
  const flagSecondary = Colors.gold.gold;
  const flagPCG = Colors.brand.pcg.gold2;

  let flagColor;

  if (flag === 'pcg' || branding === 'pcg') {
    flagColor = flagPCG;
  } else if (flag === 'secondary') {
    flagColor = flagSecondary;
  } else {
    flagColor = flagPrimary;
  }

  // card CTA orientation
  if (orientation === 'card') {
    return (
      <DivStyledDynamicCalloutCard className={className}>
        <ArticleStyledDynamicCallout
          backgroundVariant={backgroundVariant}
          themeType={themeType}>
          <AnchorStyledArticleContainer
            href={url}
            id={actionId}
            data-tracking-id={actionId}
            ariaLabel={assistiveText}
            {...(isOutgoingLink && {
              target: '_blank',
              rel: 'nofollow noopener noreferrer',
            })}>
            <SpanStyledArticleFlag flagColor={flagColor}>
              <TypographyStyledArticleFlag
                weight={500}
                branding={branding}
                disableBottomPadding>
                {tag}
              </TypographyStyledArticleFlag>
            </SpanStyledArticleFlag>
            <DivStyledCardImage
              image={image}
              imageWebp={imageWebp}
              className={`${!disableLazyLoad && 'lazy'}`}
            />
            <DivStyledDynamicCalloutCardContainer className='js-dynamic-callout-card-content-container'>
              {!!metadata && (
                <TypographyStyledMetadata
                  className='nmx-no-print'
                  backgroundVariant={backgroundVariant}
                  component='time'
                  variant='h6'
                  color={Colors.neutral.grayDark10} // TODO: move to styles
                  weight={300}
                  branding={branding}
                  uppercase>
                  {metadata}
                </TypographyStyledMetadata>
              )}
              <TypographyStyledCard
                component='h3'
                variant='h4'
                backgroundVariant={backgroundVariant}
                color={Colors.neutral.grayDark20} // TODO: move to styles
                branding={branding}
                hasLink={visualLink}>
                {title}
              </TypographyStyledCard>
              {!!visualLink && (
                <TypographyStyledVisualLink
                  className='nmx-no-print'
                  weight={500}
                  backgroundVariant={backgroundVariant}
                  disableBottomPadding>
                  {visualLink}
                  <IconStyledVisualLink />
                </TypographyStyledVisualLink>
              )}
            </DivStyledDynamicCalloutCardContainer>
          </AnchorStyledArticleContainer>
        </ArticleStyledDynamicCallout>
      </DivStyledDynamicCalloutCard>
    );
  }

  return (
    <DivStyledDynamicCalloutBanner className={className}>
      <Row align='center'>
        <ColStyledImageContainer
          small={5}
          medium={6}
          large={5}
          nested>
          <DivStyledBannerImageContainer
            image={image}
            imageWebp={imageWebp}
            className={`${!disableLazyLoad && 'lazy'}`}
          />
        </ColStyledImageContainer>
        <ColStyledContentContainer
          small={7}
          medium={6}
          large={7}>
          <DivStyledContentContainer>
            <Typography
              component='h2'
              variant='h3'
              branding={branding}
              color={Colors.neutral.grayDark20} // TODO: move to styles
              disableBottomPadding>
              {title}
            </Typography>
            <LinkStyledDynamicCalloutBannerButton
              role='link'
              variant='buttonLinkSecondary'
              id={`${actionId}-button`}
              data-tracking-id={`${actionId}-button`}
              ariaLabel={assistiveText}
              linkUrl={url}>
              {buttonText || buttonTextDefault}
            </LinkStyledDynamicCalloutBannerButton>
          </DivStyledContentContainer>
        </ColStyledContentContainer>
      </Row>
      {/* Clickable overlay for the banner variation */}
      <ButtonStyledDynamicCalloutOverlay
        role='link'
        id={actionId}
        data-tracking-id={actionId}
        aria-label={assistiveText} // not Button Component, but button DOM element
        onClick={() => {
          jsRedirect(url); // TODO: this can be a buttonLink
        }}
      />
    </DivStyledDynamicCalloutBanner>
  );
};

DynamicCalloutComponent.propTypes = {
  actionId: PropTypes.string.isRequired,
  assistiveText: PropTypes.string,
  /**  backgroundVariant determines the look and feel of the card */
  backgroundVariant: PropTypes.oneOf([
    'darkDefault',
    'lightA',
    'lightB',
    'lightC',
    'lightSky',
  ]),
  /** sets overall branding of component or module, default='nm' */
  branding: PropTypes.oneOf(['nm', 'pcg']),
  buttonText: PropTypes.string,
  /** Optional custom class name */
  className: PropTypes.string,
  disableLazyLoad: PropTypes.bool,
  flag: PropTypes.string, // TODO: flagColor: OneOf: gold, sky, pcg, etc.
  image: PropTypes.string.isRequired,
  /* optional, but strongly recommended, prop for passing in the webp version of an image */
  imageWebp: PropTypes.string,
  /** Optional Duration prop (TODO: sync with video DurationStyled naming convention) */
  metadata: PropTypes.string,
  orientation: PropTypes.string,
  isOutgoingLink: PropTypes.bool,
  tag: PropTypes.string,
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']),
  title: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  url: PropTypes.string.isRequired,
  visualLink: PropTypes.string,
};

DynamicCalloutComponent.defaultProps = {
  disableLazyLoad: false,
  branding: 'nm',
  backgroundVariant: 'lightA',
  themeType: 'lightTheme',
};

export default withTheme(DynamicCalloutComponent);
