import styled, { css, ThemeContext } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { useContext } from 'react';
// components
import { Col, Row } from '../../foundations/Grid';
import Link from '../../components/Link';
import Typography from '../../foundations/Typography';
import WidowBlock from '../../components/WidowBlock';
// icons
import ArrowRightIcon from '../../foundations/Icon/icons/utility/ArrowRight';
// styles
import { getSectionBackgroundColor } from '../../components/SectionContainer/styles';

// start styled components
export const ColStyledLinkContainer = styled(Col)`
  margin-top: ${rem(12)};
`;

export const DivStyledProductCard = styled.div`
  ${({ theme, themeType, backgroundVariant, branding, condensed, fixHeight }) => css`
    position: relative;
    background-color: ${getSectionBackgroundColor(backgroundVariant, branding, theme, themeType)};
    color: ${theme.colors.neutral.grayDark20};
    height: ${(condensed && fixHeight) ? rem(90) : '100%'};
    overflow: hidden;
    ${condensed ? `padding: ${rem(24)} 0;` : `padding: ${rem(40)} 0;`};
    ${condensed && `
      display: flex;
      align-items: center;
    `}
    :hover {
      .icon-product-callout-arrow {
        transition: left 0.2s;
        left: ${rem(10)};
        margin-right: 0;
      }
    }
  `}
`;

export const ArrowRightIconStyled = styled(ArrowRightIcon)`
 ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    return css`
      transition: left 0.2s;
      margin-bottom: ${rem(2)};
      margin-left: ${rem(10)};
      left: 0;
      position: relative;
      margin-top: ${mediumUp ? rem(11) : rem(7)};
      fill: ${theme.colors.action.lightTheme.linkDefault};
    `;
  }}
`;

// becomes the product callout wrapper if condensed variant
export const LinkStyledCondensed = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  :hover {
    && {
      text-decoration: none;
    }
  }
`;

export const RowStyledProductCard = styled(Row)`
  ${({ condensed }) => css`
    position: relative;
    padding: 0 ${rem(24)} 0 ${rem(16)};
    ${condensed && 'display: none;'}
  `}
`;

export const RowStyledHeading = styled(Row)`
  justify-content: space-between;
  align-items: flex-start;
`;

/** Creates inline styles (to override stock Component Library styles) */
export const Styles = () => {
  const theme = useContext(ThemeContext);
  const mediumUp = theme.mediumUp();
  const thisHeightWidth = mediumUp ? rem(44) : rem(33);
  return {
    IconCardMainStyled: {
      height: `${thisHeightWidth}`,
      width: `${thisHeightWidth}`,
      position: 'absolute',
      right: rem(16),
      top: rem(-8),
    },
  };
};

export const TypographyStyledCardText = styled(Typography)`
  padding-bottom: 0;
  padding-right: ${rem(32)};
`;

export const TypographyStyledHeading = styled(Typography)`
  ${({ theme, condensed, withHeadingAccent }) => {
    const mediumUp = theme.mediumUp();
    const largeUp = theme.largeUp();
    let height = rem(23);
    let maxWidth = '85%';
    let paddingBottom = mediumUp ? rem(12) : rem(8);
    let decorationDisplay = 'none';

    if (largeUp) {
      height = rem(28);
      maxWidth = '80%';
    } else if (mediumUp) {
      height = rem(25);
      maxWidth = '75%';
    }

    if (condensed) {
      paddingBottom = 0;
      decorationDisplay = 'inline-block';
      maxWidth = '100%';
    }
    if (withHeadingAccent) {
      decorationDisplay = 'inline-block';
    }

    return css`
      padding-bottom: ${paddingBottom};
      max-width: ${maxWidth};
      :before {
        content: '';
        position: absolute;
        left: 0;
        top: ${condensed ? 0 : rem(-3)};
        display: ${decorationDisplay};
        width: ${rem(5)};
        height: ${height};
        background-color: ${theme.colors.gold.gold};
        ${condensed && `
          bottom: 0;
          margin-top: auto;
          margin-bottom: auto;
        `}
      }
    `;
  }}
`;

export const WidowBlockStyled = styled(WidowBlock)`
  display: inline;
  white-space: nowrap;
`;
