// deprecated
/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

export const SpanStyledPlaceholderInitials = styled.span`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();

    return css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      font-size: ${mediumUp ? rem(48) : rem(32)};
      aspect-ratio: 1 / 1;
      padding-top: ${rem(8)};
      font-weight: 500;
      text-transform: uppercase;
      color: ${theme.colors.blue.blue};
      background-color: ${theme.colors.sky.skyDark10};
    `;
  }}
`;
