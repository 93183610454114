import React from 'react';
import PropTypes from 'prop-types';
// template components
import Typography from '../../../../foundations/Typography';
import { Row, Col } from '../../../../foundations/Grid';
// icons
import CaratRightUtility from '../../../../foundations/Icon/icons/utility/CaratRight';
// styles
import {
  LinkStyled,
  TypographyStyled,
} from './styles';
// shared submission screen styles
import {
  ContainerStyled,
  MagnifyingGlassIconStyled,
} from '../styles';
// helpers
import {
  getTypographyVariant,
  getTypographyWeight,
} from '../utils/submissionScreenHelperFunctions';

export const NoMatchModuleComponent = ({
  branding,
  themeType,
  userName,
  variant,
}) => (
  <ContainerStyled
    formvariant={variant}
    id='in-page-lead-no-match-view'>
    <Row align='center'>
      <MagnifyingGlassIconStyled formvariant={variant} />
    </Row>
    <Row align='center'>
      <Col align='center'>
        <TypographyStyled
          formvariant={variant}
          nextElement='typography'
          themeType={themeType}
          variant={getTypographyVariant(variant, branding)}>
          We couldn&#39;t find a match for you, {userName}.
        </TypographyStyled>
      </Col>
    </Row>
    <Row align='center'>
      <Col medium={8}>
        <Typography
          nextElement='graphic'
          themeType={themeType}
          weight={getTypographyWeight(branding)}>
          Thanks for your interest in working with us. At this time, we don&#39;t have a financial advisor that would be right for your current needs. But we can still help you reach your goals. Here are some articles that might interest you:&nbsp;
        </Typography>
        <LinkStyled
          endAdornment={CaratRightUtility}
          endAdornmentStylesReset
          id='in-page-lead-form-no-match-4-steps-link'
          linkUrl='https://www.northwesternmutual.com/life-and-money/4-steps-to-building-a-solid-financial-foundation/'
          themeType={themeType}
          variant='primaryDeemphasize'>
          4 Steps to Building a Solid Financial Foundation
        </LinkStyled>
        <LinkStyled
          endAdornment={CaratRightUtility}
          endAdornmentStylesReset
          id='in-page-lead-form-no-match-good-financial-plan-link'
          linkUrl='https://www.northwesternmutual.com/life-and-money/what-is-included-in-a-financial-plan/'
          themeType={themeType}
          variant='primaryDeemphasize'>
          What Is Included in a Good Financial Plan&#63;
        </LinkStyled>
        <LinkStyled
          disableBottomPadding
          endAdornment={CaratRightUtility}
          endAdornmentStylesReset
          id='in-page-lead-form-no-match-modern-retirement'
          linkUrl='https://www.northwesternmutual.com/life-and-money/planning-for-a-modern-retirement/'
          themeType={themeType}
          variant='primaryDeemphasize'>
          Planning for a Modern Retirement
        </LinkStyled>
      </Col>
    </Row>
  </ContainerStyled>
);

NoMatchModuleComponent.propTypes = {
  /** sets overall branding of component or module, default='nm' */
  branding: PropTypes.oneOf(['nm', 'pcg']),
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']),
  /** passes along the user inputed first name to the thank you screen */
  userName: PropTypes.string,
  /** what variant of this form */
  variant: PropTypes.oneOf(['embedded', 'hero']).isRequired,
};

NoMatchModuleComponent.defaultProps = {
  branding: 'nm',
  themeType: 'darkTheme',
  variant: 'embedded',
};

export default NoMatchModuleComponent;
