import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

export const ProgressBarContainerStyled = styled.div`
  height: ${rem(6)};
  overflow: hidden;
`;

export const ProgressBarStyled = styled.progress`
  ${({ branding, theme, themeType }) => {
    let thisBgColor;
    let thisFillColor;
    if (branding === 'nm') {
      if (themeType === 'lightTheme') {
        thisBgColor = theme.colors.action.lightTheme.ctaBgSecondaryHover; // confirm with Creative
        thisFillColor = theme.colors.blue.blueLight2;
      } else if (themeType === 'darkTheme') {
        thisBgColor = theme.colors.action.darkTheme.ctaBgSecondaryHover; // confirm with Creative
        thisFillColor = theme.colors.sky.sky;
      }
    }
    if (branding === 'pcg') {
      thisBgColor = theme.colors.brand.pcg.gray;
      thisFillColor = theme.colors.brand.pcg.gold1;
    }

    const getThemeStyles = () => css`
      width: 100%;
      background-color: ${thisBgColor};
      border: none;
      appearance: none;
      &::-moz-progress-bar {
        background-color: ${thisFillColor};
        transition: all 1s;
      }
      &::-moz-appearance {
        background-color: ${thisBgColor};
      }
      ::-webkit-progress-bar {
        background-color: ${thisBgColor};
      }
      ::-webkit-progress-value {
        background-color: ${thisFillColor};
        transition: all 1s;
      }
      color: blue;
    `;

    return css`
      ${getThemeStyles()} /** Default = Primary Light Theme */
    `;
  }}
`;
