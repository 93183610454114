import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import xhr from 'xhr';
import Modal from 'react-modal';
import classNames from 'classnames';
import { ThemeContext } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

// template components
import AssistiveText from '../../../../components/AssistiveText';
import { Col, Row } from '../../../../foundations/Grid';
import Link from '../../../../components/Link';
import List from '../../../../components/List';
import LoginForm from '../../../../modules/forms/Login';
import Typography from '../../../../foundations/Typography';
import WidowBlock from '../../../../components/WidowBlock';

// icon imports
import CircleWithPerson from '../../../../foundations/Icon/icons/utility/CircleWithPerson';
import CloseIcon from '../../../../foundations/Icon/icons/utility/Close';

import {
  ButtonStyled,
  DivLogoutFramesStyled,
  DivStyled,
  FwmButtonStyled,
} from './styles';

import {
  ButtonCloseStyled,
  ColLoginContentStyled,
  ColStyledAppDownloadContainer,
  ColStyledDownloadLinkContainer,
  ContainerModalStyled,
  ListItemStyledDownLoadLink,
  RowStyled,
  UlLoginLinksStyled,
} from '../UserLoginModal/styles';

export const NMLoginButtonComponent = (props) => {
  const { variant, parentId } = props;
  const isFwm = variant === 'fwm';
  const theme = useContext(ThemeContext);

  const [iframesLoading, setIframesLoading] = useState(false);
  const [userLogoutUrls, setUserLogoutUrls] = useState(null);
  const loginButtonComponent = useRef();

  const [modalIsOpen, setIsOpen] = useState(false);
  // refs
  const modalCloseEl = useRef();
  const modalWindow = useRef();

  const isSafari = /^((?!chrome|android).)*safari/i.test( // TODO: picking up FF, get better selector
    typeof navigator !== 'undefined'
      ? navigator.userAgent
      : '',
  );

  const mmCheckMedium = () => typeof document !== 'undefined'
    && window.matchMedia('(min-width: 768px)').matches;

  useEffect(() => {
    xhr({
      method: 'GET',
      uri: props.config.services.userLogoutUrls,
    }, (err, resp, body) => {
      if (typeof body !== 'undefined') {
        setUserLogoutUrls(JSON.parse(body));
      }
    });
  }, []);

  // If the user is logged into any other NM services, clicking the login button will log them out.
  const LogoutFrames = () => {
    if (userLogoutUrls) {
      const nmisLogout = userLogoutUrls.nmisLogoutUrl;
      const urls = Object.keys(userLogoutUrls.urls).map((item) => userLogoutUrls.urls[item]); // ie11 fix // TODO: revert after NM stops supporting IE11
      const randomizeUrl = `?${window.crypto.getRandomValues(new Uint32Array(1))}${window.crypto.getRandomValues(new Uint32Array(1))}`;
      const iframeTitle = 'Logout Window Content';
      urls.forEach((key, i) => {
        urls[i] += randomizeUrl;
      });

      return (
        <DivLogoutFramesStyled className='nmx-logout-iframe-container'>
          {urls.map((url) => (
            <iframe
              key={url}
              src={url}
              title={iframeTitle} />
          ))}
          <iframe
            src={nmisLogout}
            title={iframeTitle} />
        </DivLogoutFramesStyled>
      );
    }
    return false;
  };

  const openModal = () => {
    setIframesLoading(true);
    setIsOpen(true);

    // TODO: confirm Adobe tracking

    // Google analytics tracking
    window.gtag('event', 'send', {
      send_to: props.config.public.googleAnalyticsTrackingId,
      event_category: 'Log in Link',
      event_action: 'Click',
      event_label: 'Log in Link Click',
    });
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const afterOpenModal = () => {
    const loginModalContent = document.querySelector('.ReactModal__Content--after-open'); // TODO: customize after-open classes (remove BEM syntax?)
    if (isSafari && !mmCheckMedium()) {
      loginModalContent.style.position = 'static'; // Safari fix for mobile profile modal getting squished down to 1px horizontal line // TODO: if needed, activate the afterOpenModal prop on the Modal
    }
  };

  return (
    <>
      {isFwm ? (
        <FwmButtonStyled
          id='fwm-login-open-button'
          onClick={openModal}>Client log in</FwmButtonStyled>
      ) : (
        <DivStyled
          className='nm-login-button'
          ref={loginButtonComponent}>
          {/** TODO: use Modal Component */}
          <ButtonStyled
            className='nmx-login-open-button nmx-nav-link nmx-nav-link-utility'
            id='nmx-login-open-button'
            aria-label='Open login modal'
            onClick={openModal}
          >
            <CircleWithPerson />Log in
          </ButtonStyled>
        </DivStyled>
      )}
      {iframesLoading && (
        <>{LogoutFrames()}</>
      )}
      {/* TODO: organize Button vs Modal markup/logic... move Modal back to userLoginModal? */}
      <Modal
        ariaHideApp={false}
        bodyOpenClassName='modal-is-open'
        contentLabel='Northwestern Mutual Login Modal'
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        parentSelector={() => document.querySelector(parentId)} // bind Modal to element // TODO: This is breaking FWM because they don't have #nmx-header
        preventScroll={false}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        shouldReturnFocusAfterClose={true}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.5)',
            overflow: 'auto',
            zIndex: theme.zIndex.modalOverlay,
          },
          content: {
            position: 'absolute',
            top: rem(24), // TODO: confirm with Creative: inset
            left: rem(24), // TODO: confirm with Creative: inset
            right: rem(24), // TODO: confirm with Creative: inset
            bottom: rem(24), // TODO: confirm with Creative: inset
            maxWidth: rem(560), // TODO: confirm with Creative
            height: window.innerHeight <= 730 ? 'auto' : 'fit-content', // TODO: height is less than ? // TODO: use the contentRef callback to get height of Modal
            margin: 'auto',
            padding: 0,
            boxShadow: theme.effects.shadows.dp8,
            background: theme.colors.background.lightTheme.lightA,
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: rem(4),
            outline: 'none',
          },
        }}
      >
        <ContainerModalStyled
          className={classNames('nmx-modal nmx-client-login-modal')}
          id='nmx-client-login-modal'
          role='dialog'
          aria-modal='true'
          ref={modalWindow}
        >
          <Row>
            <ColLoginContentStyled>
              <ButtonCloseStyled // TODO: use Dismiss Component
                id='nmx-client-login-modal-close-button'
                onClick={closeModal}
                ref={modalCloseEl}>
                <CloseIcon /> {/* TODO: use Dismiss Component */}
                <AssistiveText>Close Login Window</AssistiveText>
              </ButtonCloseStyled>
              <Typography
                component='h4'
                variant='h3'
                align='center'>
                Log in
              </Typography>
              <LoginForm
                config={props.config}
                formIdSuffix='login-modal' />
              <Row>
                <Col align='center'>
                  <Typography disableBottomPadding>
                    Log in to other Northwestern <WidowBlock>Mutual sites.</WidowBlock>
                  </Typography>
                  <UlLoginLinksStyled className='reduced'>
                    <li className='link-list__item'>
                      <Link
                        ariaLabel='Visit Northwestern Access Fund.'
                        isOutgoingLink={true}
                        linkUrl='https://www.eaccountservices.com/NM/'
                        variant='primaryDeemphasize'>
                        Access Fund
                      </Link>
                    </li>
                    <li className='link-list__item'>
                      <Link
                        ariaLabel='Visit Northwestern Mutual Business Insurance.'
                        isOutgoingLink={true}
                        linkUrl='https://bcis.northwesternmutual.com/bcis/update/login/'
                        variant='primaryDeemphasize'>
                        Business Insurance
                      </Link>
                    </li>
                    <li className='link-list__item'>
                      <Link
                        ariaLabel='Visit Northwestern Mutual Brokerage and Advisory.'
                        isOutgoingLink={true}
                        linkUrl='https://nmis.netxinvestor.com/'
                        variant='primaryDeemphasize'>
                        Brokerage and Advisory
                      </Link>
                    </li>
                  </UlLoginLinksStyled>
                </Col>
              </Row>
            </ColLoginContentStyled>
          </Row>
          <RowStyled>
            <ColStyledAppDownloadContainer>
              <Row>
                <Col align='center'>
                  <Typography component='h3'>Get anywhere access, go mobile</Typography>
                </Col>
              </Row>
              <Row align='center'>
                <ColStyledDownloadLinkContainer align='center'>
                  <List reduced>
                    <ListItemStyledDownLoadLink>
                      <Link
                        linkUrl='https://northwesternmutual.page.link/?link=https%3A%2F%2Fwww.northwesternmutual.com%2Fapp%2Fcampaign%3Futm_source%3Dnmcom_log-in_modal%26utm_medium%3Dweb%26utm_campaign%3Dmobileappbadges_Q4_2022%26utm_content%3Dclient_mobileappdownload%26utm_term%3Dios&apn=com.nm.nm&amv=0&ibi=com.nm.nm.ios&isi=1132579006&st=To%20continue%20%26%20download%20the%20app%2C%20click%20%22OPEN%22%20below.&sd=Northwestern%20Mutual%20Mobile%20App&ofl=https%3A%2F%2Fapps.apple.com%2Fus%2Fapp%2Fnorthwestern-mutual%2Fid1132579006'
                        isOutgoingLink={true}>
                        <img
                          alt='Download on the App Store'
                          src={`${props.config.basePath}/template/assets/${props.config.nmxTemplateVersion}/images/badges/apple-app-store-badge.svg`}
                          className='nmx-icon badge-appStore' />
                      </Link>
                    </ListItemStyledDownLoadLink>
                    <ListItemStyledDownLoadLink>
                      <Link
                        linkUrl='https://northwesternmutual.page.link/?link=https%3A%2F%2Fwww.northwesternmutual.com%2Fapp%2Fcampaign%3Futm_source%3Dnmcom_log-in_modal%26utm_medium%3Dweb%26utm_campaign%3Dmobileappbadges_Q4_2022%26utm_content%3Dclient_mobileappdownload%26utm_term%3Dandroid&apn=com.nm.nm&amv=0&ibi=com.nm.nm.ios&isi=1132579006&st=To%20continue%20%26%20download%20the%20app%2C%20click%20%22OPEN%22%20below.&sd=Northwestern%20Mutual%20Mobile%20App&ofl=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.nm.nm'
                        isOutgoingLink={true}>
                        <img
                          alt='Get it on Google Play'
                          src={`${props.config.basePath}/template/assets/${props.config.nmxTemplateVersion}/images/badges/google-play-badge.png`}
                          className='nmx-icon badge-googlePlay' />
                      </Link>
                    </ListItemStyledDownLoadLink>
                  </List>
                </ColStyledDownloadLinkContainer>
              </Row>
            </ColStyledAppDownloadContainer>
          </RowStyled>
        </ContainerModalStyled>
      </Modal>
    </>
  );
};

NMLoginButtonComponent.propTypes = {
  /** Config vars used for varying components */
  config: PropTypes.shape({
    public: PropTypes.shape({ googleAnalyticsTrackingId: PropTypes.string }).isRequired,
    services: PropTypes.shape({ userLogoutUrls: PropTypes.string.isRequired }).isRequired,
  }),
  /** Optional prop to render FWM login button variant */
  isFwm: PropTypes.bool,
  parentId: PropTypes.string,
};

NMLoginButtonComponent.defaultProps = {
  config: {
    public: { googleAnalyticsTrackingId: '<%=googleAnalyticsTrackingId%>' },
    services: { userLogoutUrls: '<%=userLogoutUrls%>' },
  },
  isFwm: false,
  parentId: '#nmx-header',
};

export default NMLoginButtonComponent;
