import React from 'react';
import PropTypes from 'prop-types';

import {
  DivGoalCardIconContainerStyled,
  DivGoalCardTextContainerStyled,
  InputRadioCardStyled,
  LabelGoalCardStyled,
  TypographyGoalCardStyled,
} from './styles';

export const GoalCardRadioInputComponent = React.forwardRef(({
  icon,
  id,
  value,
  onChange,
}, ref) => (
  <LabelGoalCardStyled className='fafa-hero-goal-card'>
    <DivGoalCardIconContainerStyled>
      {icon}
    </DivGoalCardIconContainerStyled>
    <DivGoalCardTextContainerStyled>
      <TypographyGoalCardStyled
        className='fafa-hero-goal-card-label-text'
        disableBottomPadding>
        {value}
      </TypographyGoalCardStyled>
    </DivGoalCardTextContainerStyled>
    <InputRadioCardStyled
      className='fafa-hero-goal-card-radio-input'
      ref={ref}
      id={id}
      name='goal'
      value={value}
      onChange={onChange}
      required={true}
      data-type='goals-card'
      type='radio' />
  </LabelGoalCardStyled>
));

GoalCardRadioInputComponent.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  icon: PropTypes.object,
};

export default GoalCardRadioInputComponent;
