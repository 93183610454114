import React, { useState } from 'react';
import PropTypes from 'prop-types';

// template components
import Form from '../../../components/forms/Form';
import Link from '../../../components/Link';
import { Row } from '../../../foundations/Grid';
import TextField from '../../../components/forms/TextField';
import Typography from '../../../foundations/Typography';

// icon imports
import CircleWithExclamationPointFill from '../../../foundations/Icon/icons/utility/CircleWithExclamationPointFill';
import EyeUtility from '../../../foundations/Icon/icons/utility/Eye';
import EyeWithSlashUtility from '../../../foundations/Icon/icons/utility/EyeWithSlash';

import {
  ButtonLoginStyled,
  ColFormStyled,
  ColLoginNotificationStyled,
  HideShowPasswordButtonStyled,
  LoginNotificationTypographyStyled,
  LinkStyled,
  TypographyStyled,
} from './styles';

export const LoginModule = (
  config,
  formIdSuffix,
  themeType,
) => {
  const formId = `nmx-client-login-form-${formIdSuffix}`;
  const userNameFieldId = `nmx-client-login-username-${formIdSuffix}`;
  const passwordFieldId = `nmx-client-login-password-${formIdSuffix}`;

  // states
  const [loginError, setLoginError] = useState(false);
  const [password, setPassword] = useState('');
  const [showHidePasswordChecked, setChecked] = useState(false);
  const [username, setUsername] = useState('');

  // functions
  const handleHideShowPasswordChange = () => {
    setChecked(!showHidePasswordChecked);
    return false;
  };

  const validateForm = (e) => {
    e.preventDefault();
    if (!username || !password) {
      setLoginError(true);
    } else {
      setLoginError(false);
      const formEl = document.getElementById(formId);
      formEl.submit();
    }
  };

  return (
    <>
      {
        loginError && (
          <Row>
            <ColLoginNotificationStyled>
              {/* notification */}
              <CircleWithExclamationPointFill />
              <LoginNotificationTypographyStyled
                align='center'
                disableBottomPadding
              >
                Please fill in your Username and Password.
              </LoginNotificationTypographyStyled>
            </ColLoginNotificationStyled>
          </Row>
        )
      }
      <Form
        action={config.config.services.loginPageUrl}
        className='nmx-form nmx-form__login'
        id={formId}
        legend='Enter your username and password to access your account.'
        method='POST'>
        <fieldset>
          <Row>
            <ColFormStyled>
              <TextField
                id={userNameFieldId}
                hasError={loginError}
                labelText='Username'
                name='username'
                onChange={(e) => { setUsername(e.target.value); }}
                onBlur={(e) => { setUsername(e.target.value.trim()); }}
                required={true}
                themeType={themeType}
                type='text'
                value={username}
              />
            </ColFormStyled>
          </Row>
          <Row>
            <ColFormStyled>
              <TextField
                id={passwordFieldId}
                hasError={loginError}
                labelText='Password'
                name='password'
                onChange={(e) => { setPassword(e.target.value); }}
                required={true}
                themeType={themeType}
                type={ showHidePasswordChecked ? 'text' : 'password'}
                value={password}
              />
              <HideShowPasswordButtonStyled
                onClick={handleHideShowPasswordChange}
                themeType={themeType}
                type='button'
              >
                <span className='nmx-icon__container'>
                  {showHidePasswordChecked
                    ? <EyeUtility />
                    : <EyeWithSlashUtility />
                  }
                </span>
              </HideShowPasswordButtonStyled>
            </ColFormStyled>
          </Row>
          <Row>
            <ColFormStyled>
              <TypographyStyled themeType={themeType}>Forgot <LinkStyled
                variant='primaryDeemphasize'
                linkUrl={`${config.config.services.loginPageUrlBase}/forgot-username/`}
                ariaLabel='Help with finding your username'
                themeType={themeType}>username</LinkStyled> or <LinkStyled
                variant='primaryDeemphasize'
                linkUrl={`${config.config.services.loginPageUrlBase}/password-reset/`}
                ariaLabel='Help with resetting your password'
                themeType={themeType}>password</LinkStyled>&#63;</TypographyStyled>
            </ColFormStyled>
          </Row>
          <Row>
            <ColFormStyled>
              <ButtonLoginStyled
                className='nmx-client-login-modal__submit' // TODO: needed?
                id='nmx-client-login-submit'
                onClick={validateForm}
                themeType={themeType}
                type='submit'
                variant='primary'>
                Log in
              </ButtonLoginStyled>
              <Typography
                align='center'
                themeType={themeType}>
                Don&#39;t have an account&#63; <Link
                  variant='primaryDeemphasize'
                  linkUrl={`${config.config.services.loginPageUrlBase}/registration`}
                  ariaLabel='Register for a new account.'
                  themeType={themeType}>Register</Link>.
              </Typography>
            </ColFormStyled>
          </Row>
        </fieldset>
      </Form>
    </>
  );
};

LoginModule.propTypes = {
  /** backgroundVariant allows section to be different background color based on themeType */
  backgroundVariant: PropTypes.oneOf(['darkDefault', 'lightA', 'lightB', 'lightC']),
  /** Config vars used for varying components */
  config: PropTypes.shape({
    services: PropTypes.shape({
      loginPageUrl: PropTypes.string.isRequired,
      loginPageUrlBase: PropTypes.string.isRequired,
    }),
  }),
  /** required suffix to append to form id */
  formIdSuffix: PropTypes.string.isRequired,
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']),
};

LoginModule.defaultProps = {
  backgroundVariant: 'lightA',
  config: {
    services: {
      loginPageUrl: '<%=loginPageUrl%>',
      loginPageUrlBase: '<%=loginPageUrlBase%>',
    },
  },
  themeType: 'lightTheme',
};

export default LoginModule;
