import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

export const ToastBox = styled.div`
  ${({ theme }) => css`
    width: ${rem(280)};
    max-height: 100%;
    margin-left: ${rem(16)};
    background-color: ${theme.colors.neutral.grayDark30};
    border-radius: ${rem(7)};
    color: ${theme.colors.neutral.white};
    overflow-y: auto;

    ${theme.mediumUp()
      && `
        width: ${rem(325)};
        margin-left: ${rem(50)};
        font-size: ${rem(20)};
      `}
  `}
`;

export const ToastContainer = styled.div`
  ${({ theme, hideToast }) => css`
    position: fixed;
    z-index: ${theme.zIndex.toast};
    bottom: 5%;
    max-height: 85%;
    margin: 0;
    font-family: sans-serif;
    line-height: 1.15;

    ${hideToast && 'display: none;'}
  `}
`;

export const ToastContentContainer = styled.div`
  ${({ theme }) => css`
    padding: ${rem(10)} ${rem(12)};

    ${theme.mediumUp()
      && `
        display: flex;
        width: 100%;
        height: ${rem(60)};
        flex-direction: column;
        justify-content: space-between;
        padding: 0 0 ${rem(5)};
      `}
  `}
`;

export const ToastContent = styled.span`
  ${({ theme, isExpanded }) => css`
    font-size: ${rem(12)};
    font-weight: 400;
    line-height: ${rem(18)};

    ${isExpanded
      && `
        padding-bottom: ${rem(8)};
        border-bottom: 1px solid ${theme.colors.neutral.white};
      `}

    ${theme.mediumUp()
      && `
        width: 100%;
        margin: 0;
        font-size: ${rem(15)};
        line-height: ${rem(22)};

        ${isExpanded
          && `
          padding-bottom: ${rem(15)};
        `}
      `}
  `}
`;

export const ToastHeadline = styled.span`
  display: inline-block;
`;

export const ToastIconContainer = styled.span`
  ${({ theme }) => css`
    position: relative;
    top: ${rem(2)};
    display: inline-block;
    width: ${rem(15)};
    height: ${rem(9)};
    margin-left: ${rem(4)};
    ${theme.mediumUp()
      && `
        position: relative;
        display: inline-flex;
        width: ${rem(15)};
        height: ${rem(22)};
        flex: 0 0 auto;
        align-items: center;
        justify-content: center;
        margin-left: ${rem(7)};
      `}
  `}
`;

export const ToastIcon = styled.span`
  ${({ nmxTemplateVersion, basePath, theme, invert }) => css`
      display: block;
      color: ${theme.colors.gold.gold};
      width: ${rem(13)};
      height: ${rem(13)};
      padding: 0;
      margin: 0;
      background-image: url('${basePath}/template/assets/${nmxTemplateVersion}/images/icons/utility/chevron-down.svg'); /** TODO: encode svg */
      background-repeat: no-repeat;
      filter: invert(91%) sepia(26%) saturate(6548%) hue-rotate(372deg)
        brightness(104%) contrast(101%); /** $color-system-cta-yellow */
      /** TODO: can be nested */
      font-size: 27px;

      ${invert
        && `
        position: relative;
        top: -5px;
        transform: rotate(180deg);
      `}

      ${theme.mediumUp()
        && `
        display: flex;
        width: ${rem(20)};
        height: ${rem(15)};
        transform: rotate(0deg);

        ${invert
          && `
          top: -10px;
          transform: rotate(180deg);
        `}
      `}
    `}
`;

export const ToggleButton = styled.button`
  ${({ theme }) => css`
    border-color: transparent;
    width: auto;
    height: ${rem(30)};
    background-color: transparent;
    color: ${theme.colors.neutral.white};
    font-size: ${rem(16)};
    text-transform: none;

    ${theme.mediumUp()
      && `
        display: block;
        width: 100%;
        height: ${rem(45)};
        flex: 1 0 auto;
        padding: 0;
        cursor: pointer;
      `}
  `}
`;

export const ToggleContent = styled.div`
  ${({ theme }) => css`
    padding-right: 0;
    overflow-y: hidden;
    transition: height 0.3s ease;

    ${theme.mediumUp()
      && `
        height: 0;
        padding: 0;
      `}
  `}
`;

export const ToastText = styled.p`
  ${({ theme }) => css`
    padding: 0 ${rem(16)};
    margin: ${rem(16)} 0 ${rem(5)};
    font-size: ${rem(13)};
    font-weight: 300;
    line-height: ${rem(22)};

    ${theme.mediumUp()
      && `
        margin: ${rem(16)} 0 0;
        font-size: ${rem(14)};
        line-height: ${rem(22)};
      `}
  `}
`;

export const TextCloseButton = styled.button`
  ${({ theme }) => css`
    border-color: transparent;
    height: ${rem(50)};
    padding: 0 ${rem(16)};
    margin-top: ${rem(-15)};
    background-color: transparent;
    color: ${theme.colors.neutral.white};
    font-size: ${rem(11)};
    font-weight: 400;
    letter-spacing: ${rem(3)};
    text-transform: uppercase;

    ${theme.mediumUp()
      && `
        display: block;
        height: ${rem(45)};
        margin-top: ${rem(-13)};
        cursor: pointer;
        font-size: ${rem(13)};
      `}
  `}
`;

export const CloseButton = styled.button`
  ${({ theme }) => css`
    border-color: transparent;
    position: relative;
    top: 0; /** overrides legacy mixins */
    left: 0; /** overrides legacy mixins */
    width: ${rem(22)};
    height: ${rem(23)};
    align-self: flex-start;
    padding: ${rem(1)} ${rem(3)} 0;
    margin-top: ${rem(-23)};
    margin-left: ${rem(16)};
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: ${rem(2)};
    color: ${theme.colors.neutral.grayDark30};
    font-size: ${rem(16)};

    ${theme.mediumUp()
      && `
        position: absolute;
        margin-left: ${rem(50)};
        cursor: pointer;
        font-weight: 500;
      `}
  `}
`;
