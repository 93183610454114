import React from 'react';
import PropTypes from 'prop-types';

import {
  DivStyledAgentInitials,
  PictureStyled,
  SpanStyledAgentPlaceholderInitials,
} from './styles';

export const AgentInitials = ({
  firstName,
  lastName,
}) => <SpanStyledAgentPlaceholderInitials>{firstName?.substring(0, 1)} {lastName?.substring(0, 1)}</SpanStyledAgentPlaceholderInitials>;

const AgentPhoto = ({ agentImageUrl, agentName }) => {
  if (agentImageUrl) {
    return <PictureStyled
      altText={agentName.fullName}
      baseImageProps={{
        itemProp: 'image',
        className: 'u-photo',
      }}
      fullWidth
      lazyLoad={false}
      src={agentImageUrl}
    />;
  }

  return <DivStyledAgentInitials>
    <AgentInitials
      firstName={agentName.firstName}
      lastName={agentName.lastName}
    />
  </DivStyledAgentInitials>;
};

AgentPhoto.propTypes = {
  /** Optional agent image url - if not supplied, will render agent initials */
  agentImageUrl: PropTypes.string,
  /** Required agent name object */
  agentName: PropTypes.shape({
    firstName: PropTypes.string,
    fullName: PropTypes.string,
    lastName: PropTypes.string,
    middleName: PropTypes.string,
  }).isRequired,
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']),
};

AgentPhoto.defaultProps = { themeType: 'lightTheme' };

export default AgentPhoto;
