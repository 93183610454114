/* eslint-disable no-param-reassign */
/* eslint-disable react/self-closing-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import find from 'lodash/find';

import CircleWithExclamationPointFill from '../../../componentLibrary/foundations/Icon/icons/utility/CircleWithExclamationPointFill';
import EyeUtility from '../../../componentLibrary/foundations/Icon/icons/utility/Eye';
import EyeWithSlashUtility from '../../../componentLibrary/foundations/Icon/icons/utility/EyeWithSlash';

// TODO: this Component can be optimized in many places (hooks, refs, remove jsxIf, ErrorBoundaries,
// aside info can be Component toggled by state, etc)

// TODO: remove this Component (React Component exists in Layout/Header)

export class NMUserLoginComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginError: false,
      username: '',
      password: '',
      isOpen: false,
      altHeight: '',
      altModalHeight: '',
      modalHeightLock: false,
      mobileDevice: (typeof document !== 'undefined') && !document.documentElement.classList.contains('nmx-no-touch'),
      showHidePasswordChecked: false,
      setChecked: false,
    };

    // component listeners
    this.keyDownListener = throttle(this.keyDownListener.bind(this), 100);
    this.resizeListener = debounce(this.resizeListener.bind(this), 100);

    // component refs
    this.loginComponent = React.createRef();
    this.modalWindow = React.createRef();
    this.modalCloseEl = React.createRef();
  }

  handleHideShowPasswordChange = () => {
    this.setState({
      showHidePasswordChecked: !this.state.showHidePasswordChecked,
      setChecked: this.state.showHidePasswordChecked,
    });
    return false;
  };

  checkModalHeight = () => {
    if (this.modalWindow !== undefined && this.modalWindow !== null) { // TODO: use state.isOpen?
      return this.modalWindow.current.offsetHeight; // totalModalHeight
    }
    return false;
  }

  setModalAttributes() {
    const totalModalHeight = this.checkModalHeight();
    this.setState({ modalHeightLock: window.innerHeight <= totalModalHeight });
    if (this.state.isOpen) {
      document.body.classList.add('modal-is-open'); // used for scroll lock // TODO: we're getting legacy styles in repos overriding body.overflow styles, remove the html styles once figured out.
    }

    if (this.state.mobileDevice) {
      // mobile attributes
      this.setState({
        altHeight: window.innerHeight, // 100%' // set modal height to window height
        altModalHeight: '100%',
        altWidth: `${window.innerWidth}px`, // set modal width to window width
      });
    } else {
      // desktop attributes
      // desktop state, content height greater than modal height
      this.setState({
        altModalHeight: window.innerHeight <= totalModalHeight
          ? `${window.innerHeight}px`
          : 'auto', // auto inline height if not mobile landscape
        altHeight: `${window.innerHeight}px`, // auto inline height if not mobile landscape
        altWidth: 'auto',
      });
    }
  }

  keyDownListener(e) {
    const tabKeyCode = 9;
    const escapeKeyCode = 27;
    const enterKeyCode = 13;

    switch (e.keyCode) {
      case escapeKeyCode:
      // close modal on escape key press
        if (this.state.isOpen === true) {
          this.closeModal();
        }
        break;
      case enterKeyCode:
      // toggle search modal on enter key press
        if (document.activeElement === this.modalCloseEl.current) {
          e.preventDefault();
          this.closeModal();
        }
        break;
      case tabKeyCode:
      // if modal is open, trap focus
      // TODO: this is used in a few places (search, login modal)... add as a helper function?
        if (this.state.isOpen === true) {
          const thisModal = this.modalWindow.current; // document.getElementById('nmx-client-login-modal')
          thisModal.focus();
          const focusableEls = Array.prototype.slice.call(thisModal.querySelectorAll('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'));
          const firstFocusableEl = focusableEls[0];
          const lastFocusableEl = focusableEls[focusableEls.length - 1];
          // if modal does not have focus, focus on modal, otherwise, focus on first focusable ele
          if (find(focusableEls, document.activeElement) === undefined) {
            firstFocusableEl.focus();
          }

          if (e.shiftKey) {
          // reverse tab
            if (document.activeElement === firstFocusableEl) {
              e.preventDefault();
              lastFocusableEl.focus();
            }
          } else if (document.activeElement === lastFocusableEl) {
          // forward tab
            e.preventDefault();
            firstFocusableEl.focus();
          }
        }
        break;
      default:
        break;
    }
  }

  mmCheck = () => (typeof window !== 'undefined'
    ? window.matchMedia('(min-width: 768px)').matches
    : false);

  resizeListener() {
    if (this.modalWindow !== undefined && this.modalWindow !== null) {
      // set modal attributes
      this.setModalAttributes();
    }
  }

  setOpenState() {
    this.setState({ isOpen: true });
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      // browser-only code - react-static has some server side rendering for build process
    // add keydown listener
      window.addEventListener('keydown', this.keyDownListener, true);
      // add resize listener
      window.addEventListener('resize', this.resizeListener, true);

      this.overlay.addEventListener('click', () => { // TODO: add Component listener and throttle
        this.closeModal();
      });

      // Use MutationObserver to detect class change on parent Component (this is only needed because button and modal are in 2 separate Components)
      // TODO: rework button and modal Components to utilize simpler React
      // functionality: state, etc... // This method was just to streamline currently 2 separate components (button, modal)
      const observerConfig = {
        attributes: true,
        attributeFilter: ['class'],
        subtree: false,
      };

      // When a mutation is observed
      const observerCallback = function (mutationsList) {
        mutationsList.forEach((mutation) => {
        // If active, set component height (so flexbox functionality works)
          if (mutation.target.classList.contains('is-active')) {
            mutation.target.style.height = `${window.innerHeight}px`; // modal component (includes overlay)
            // TODO: once login component is using Component-based states, rework this initial height setting (mutationObserver probably won't be necessary)
            mutation.target.childNodes[0].style.height = window.innerHeight <= mutation.target.childNodes[0].offsetHeight ? `${window.innerHeight}px` : `${mutation.target.childNodes[0].offsetHeight}px`; // modal window
            this.setState({ isOpen: true });
            document.body.classList.add('modal-is-open');
            // this.setModalAttributes()
          } else {
            mutation.target.style.height = 0; // TODO: confirm this is necessary? or just leave set height
            this.setState({ isOpen: false });
            document.body.classList.remove('modal-is-open');
          }
        });
      }.bind(this);

      // Create an new observer
      const observer = new MutationObserver(observerCallback);

      // Start observing
      observer.observe(this.loginComponent.current, observerConfig);
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      // browser-only code - react-static has some server side rendering for build process
      // remove listeners
      window.removeEventListener('keydown', this.keyDownListener, true);
      window.removeEventListener('resize', this.resizeListener, true);
    }
  }

  closeModal = () => {
    // Using the .remove method instead of using state because the nm_mobile_login_button
    // component is adding the is-active class to this component in order to display it.
    // This was a simpler way of achieving the functionality without having to implement the context API or Redux.
    this.loginComponent.current.classList.remove('is-active');
    document.body.classList.remove('modal-is-open');
    document.getElementById('nmx-login-open-button').focus();
  }

  // As long as the input names match the state, this will be the appropriate input value to the appropriate state
  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  validateForm = (e) => {
    e.preventDefault();
    if (!this.state.username || !this.state.password) {
      this.setState({ loginError: true });
    } else {
      this.setState({ loginError: false });
      this.formEl.submit();
    }
  }

  render() {
    return (
      <div className='nmx-client-login' id='nmx-client-login' ref={this.loginComponent} style={{ height: this.state.altHeight }}>
        <div
          className={classNames('nmx-modal nmx-client-login-modal nmx-container')} id='nmx-client-login-modal'
          role='dialog'
          aria-modal='true'
          ref={this.modalWindow}
          style={{
            height: this.state.altModalHeight,
            width: this.state.altModalWidth,
          }}
        >
          <div className="nmx-row">
            <div className={classNames('nmx-col nmx-col-xsmall-12 nmx-client-login-modal__content')}>
              <button
                className='nmx-client-login-modal__close-button'
                id='nmx-client-login-modal-close-button'
                onClick={this.closeModal}
                ref={this.modalCloseEl}>
                Close Login Window
              </button>
              <h4 className="nmx-h3">Log in</h4>
              <div className={classNames('nmx-client-login-modal__inline-notification', { 'is-active': this.state.loginError })}>
                <CircleWithExclamationPointFill />
                <p className="nmx-client-login-modal__inline-notification-text">Please fill in your Username and Password.</p>
              </div>
              <form
                className='nmx-form nmx-client-login-modal__form'
                method='POST'
                id='nmx-client-login-form'
                ref={(el) => { this.formEl = el; }}
                action={this.props.config.services.loginPageUrl}>
                <fieldset className='nmx-client-login-modal__fieldset'>
                  <legend>Enter your username and password to access your account.</legend>
                  <label htmlFor='nmx-client-login-username' className='nmx-client-login-modal__form-field-label nmx-p'>Username</label>
                  <input
                    className='nmx-client-login-modal__form-field nmx-form__input'
                    id='nmx-client-login-username'
                    name='username'
                    type='text'
                    aria-required='true'
                    value={this.state.username}
                    onChange={this.handleInputChange}
                  />
                  <label htmlFor='nmx-client-login-password' className='nmx-client-login-modal__form-field-label nmx-p'>Password</label>
                  <input
                    className='nmx-client-login-modal__form-field nmx-form__input'
                    id='nmx-client-login-password'
                    name='password'
                    type={ this.state.showHidePasswordChecked ? 'text' : 'password'}
                    aria-required='true'
                    value={this.state.password}
                    onChange={this.handleInputChange}
                  />
                  <button type='button' className='nmx-client-login-modal__button-hide-show-password' onClick={this.handleHideShowPasswordChange}>
                    <span className='nmx-icon__container'>
                      {this.state.showHidePasswordChecked
                        ? <EyeUtility />
                        : <EyeWithSlashUtility />
                      }
                    </span>
                  </button>
                  <p className="nmx-client-login-modal__login-assistance">Forgot <a href={`${this.props.config.services.loginPageUrlBase}/forgot-username`} aria-label='Help with finding your username'>username</a> or <a href={`${this.props.config.services.loginPageUrlBase}/password-reset`} aria-label='Help with resetting your password'>password</a>&#63;</p>
                </fieldset>
                <button id="nmx-client-login-submit" className='nmx-button nmx-button--secondary nmx-client-login-modal__submit' type='submit' onClick={this.validateForm}>Log in</button>
                <p className="nmx-client-login-modal__login-assistance">Don&#39;t have an account&#63; <a href={`${this.props.config.services.loginPageUrlBase}/registration`} aria-label='Register for a new account.'>Register</a>.</p>
              </form>
              <div className='nmx-client-login-modal__other-accounts'>
                <p className='text'>
                  <span className='block'>Log in to other </span>
                  <span className='block'>Northwestern Mutual sites&#58;</span>
                </p>
                <ul className='link-list reduced'>
                  <li className='link-list__item'>
                    <a href='https://www.eaccountservices.com/NM/' aria-label='Visit Northwestern Access Fund.' target="_blank" rel="noopener noreferrer nofollow">Access Fund</a>
                  </li>
                  <li className='link-list__item'>
                    <a href='https://bcis.northwesternmutual.com/bcis/update/login' aria-label='Visit Northwestern Mutual Business Insurance.' target='_blank' rel='noopener noreferrer nofollow'>Business Insurance</a>
                  </li>
                  <li className='link-list__item'>
                    <a
                      aria-label='Visit Northwestern Mutual Brokerage and Advisory.'
                      href='https://nmis.netxinvestor.com/'
                      target="_blank" rel='noopener noreferrer nofollow'>
                      Brokerage and Advisory
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='nmx-row nmx-row--align-center'>
            <div className='nmx-col nmx-client-login-modal__app-download-container'>
              <div className='nmx-row'>
                <div className='nmx-col nmx-client-login-modal__app_download-heading'>
                  <h4>Get anywhere access, go mobile</h4>
                </div>
              </div>
              <div className="nmx-row">
                <div className='nmx-col'>
                  <ul className='reduced'>
                    <li className='nmx-client-login-modal__download-link'>
                      <a href='https://northwesternmutual.page.link/?link=https%3A%2F%2Fwww.northwesternmutual.com%2Fapp%2Fcampaign%3Futm_source%3Dnmcom_log-in_modal%26utm_medium%3Dweb%26utm_campaign%3Dmobileappbadges_Q4_2022%26utm_content%3Dclient_mobileappdownload%26utm_term%3Dios&apn=com.nm.nm&amv=0&ibi=com.nm.nm.ios&isi=1132579006&st=To%20continue%20%26%20download%20the%20app%2C%20click%20%22OPEN%22%20below.&sd=Northwestern%20Mutual%20Mobile%20App&ofl=https%3A%2F%2Fapps.apple.com%2Fus%2Fapp%2Fnorthwestern-mutual%2Fid1132579006' target="_blank" rel="noopener noreferrer nofollow">
                        <img alt='Download on the App Store' src='https://developer.apple.com/app-store/marketing/guidelines/images/badge-example-preferred_2x.png' className='nmx-icon badge-appStore' />
                        {/* <img alt='Download on the App Store' src={`${this.props.config.basePath}/template/assets/2.16.1/images/apple-app-store-badge.svg`} className='nmx-icon badge-appStore' /> */}
                        {/* <img alt='Download on the App Store' src={`${this.props.config.basePath}/template/assets/${this.props.config.nmxTemplateVersion}/images/badges/apple-app-store-badge.svg`} className='nmx-icon badge-appStore' /> */}
                        {/* <AppleAppStoreBadge /> */}
                      </a>
                    </li>
                    <li className='nmx-client-login-modal__download-link'>
                      <a href='https://northwesternmutual.page.link/?link=https%3A%2F%2Fwww.northwesternmutual.com%2Fapp%2Fcampaign%3Futm_source%3Dnmcom_log-in_modal%26utm_medium%3Dweb%26utm_campaign%3Dmobileappbadges_Q4_2022%26utm_content%3Dclient_mobileappdownload%26utm_term%3Dandroid&apn=com.nm.nm&amv=0&ibi=com.nm.nm.ios&isi=1132579006&st=To%20continue%20%26%20download%20the%20app%2C%20click%20%22OPEN%22%20below.&sd=Northwestern%20Mutual%20Mobile%20App&ofl=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.nm.nm' target="_blank" rel="noopener noreferrer nofollow">
                        <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' className='nmx-icon badge-googlePlay' />
                        {/* <img alt='Get it on Google Play' src={`${this.props.config.basePath}/template/assets/2.16.1/images/google-play-badge.png`} className='nmx-icon badge-googlePlay' /> */}
                        {/* <img alt='Get it on Google Play' src={`${this.props.config.basePath}/template/assets/${this.props.config.nmxTemplateVersion}/images/badges/google-play-badge.png`} className='nmx-icon badge-googlePlay' /> */}
                        {/* <GooglePlayBadge /> */}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='nmx-modal__overlay' ref={(el) => { this.overlay = el; }}></div>
      </div>
    );
  }
}

NMUserLoginComponent.propTypes = {
  /** Config vars used for varying components */
  config: PropTypes.shape({
    basePath: PropTypes.string,
    nmxTemplateVersion: PropTypes.string,
    services: PropTypes.shape({
      loginPageUrl: PropTypes.string.isRequired,
      loginPageUrlBase: PropTypes.string.isRequired,
    }),
  }),
};

NMUserLoginComponent.defaultProps = {
  config: {
    services: {
      loginPageUrl: '<%=loginPageUrl%>',
      loginPageUrlBase: '<%=loginPageUrlBase%>',
    },
  },
};

export default NMUserLoginComponent;
