import React from 'react';
import PropTypes from 'prop-types';

import { TypographyStyledAssistiveTextBase } from './styles';

export const AssistiveTextComponent = ({
  children,
  className,
  component,
  ...rest
}) => (
  <TypographyStyledAssistiveTextBase
    component={component}
    className={className}
    {...rest}
  >
    {children}
  </TypographyStyledAssistiveTextBase>
);

AssistiveTextComponent.propTypes = {
  /** Inner Text to be rendered as assistiveText */
  children: PropTypes.node.isRequired,
  /** Optional custom class name */
  className: PropTypes.string,
  /** what DOM element the rendered Component should be */
  component: PropTypes.oneOf([
    'address',
    'cite',
    'div',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'label',
    'p',
    'small',
    'span',
    'time',
  ]),
};
AssistiveTextComponent.defaultProps = { component: 'span' };

export default AssistiveTextComponent;
