import shade from 'polished/lib/color/shade';

import Colors from '../colors';

const pcgGold = `
  background: rgb(189, 162, 99);
  background: linear-gradient(
    90deg,
    ${shade(0.05, Colors.brand.pcg.gold1)} 0%,
    ${shade(0.05, Colors.brand.pcg.gold2)} 50%,
    ${shade(0.05, Colors.brand.pcg.gold1)}
  );
`;

// 10% darker hover on all 3 RGBa values?
const pcgGoldHover = `
  background: rgb(189, 162, 99);
  background: linear-gradient(
    90deg,
    ${shade(0.05, Colors.brand.pcg.gold1)} 0%,
    ${shade(0.05, Colors.brand.pcg.gold2)} 50%,
    ${shade(0.05, Colors.brand.pcg.gold1)}
  ); // TODO: darken RGB values programatically
`;

const pcgBlue = `
  background: rgb(2, 0, 36);
  background: linear-gradient(
    193deg,
    ${shade(0.05, Colors.brand.pcg.darkBlue1)} 0%,
    ${shade(0.05, Colors.brand.pcg.darkBlue2)} 50%,
    ${shade(0.05, Colors.brand.pcg.darkBlue1)}
  );
  `;

export default {
  pcgGold,
  pcgGoldHover,
  pcgBlue,
};
