import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
// components
import { Col } from '../../foundations/Grid';
import FormRow from '../../components/forms/FormRow';

export const ColStyled = styled(Col)`
  margin-bottom: ${rem(32)};
`;

export const FormRowStyled = styled(FormRow)`
  ${({ theme }) => css`
    padding-bottom: ${rem(20)};
    border-bottom: ${rem(1)} solid ${theme.colors.neutral.gray};
  `}
`;
