export function jsxIf(condition, trueCase, falseCase = (() => null)) {
  if (condition) {
    return trueCase();
  }
  return falseCase();
}

export function jsxIfNot(condition, trueCase, falseCase = (() => null)) {
  if (!condition) {
    return trueCase();
  }
  return falseCase();
}

export function jsxWith(object, callback) {
  return callback(object);
}
