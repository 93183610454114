import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
// components
import { Col, Container, Row } from '../../foundations/Grid';
import Dismiss from '../../components/Dismiss';
import Link from '../../components/Link';
import Typography from '../../foundations/Typography';
// icons
import LightbulbIcon from '../../foundations/Icon/icons/illustrative/darkTheme/Lightbulb';

export const getContainerStyledStickyBannerTopValue = (informationalBannerHeight, small0to601) => {
  let thisTop = 0;
  if (informationalBannerHeight > 0 && small0to601) {
    thisTop = rem(informationalBannerHeight + 82);
  } else if (informationalBannerHeight <= 0 && small0to601) {
    thisTop = rem(82);
  }
  return thisTop;
};

export const getContainerStyledStickyBannerMarginLeftValue = (isVisible, small0to601, small601Up) => {
  let thisMarginLeft = rem(-600);

  if (isVisible && small601Up) {
    thisMarginLeft = rem(-300);
  } else if (isVisible && small0to601) {
    thisMarginLeft = rem(16);
  }
  return thisMarginLeft;
};

export const ContainerStyledStickyBanner = styled(Container)`
  ${({ theme, isVisible, informationalBannerHeight }) => {
    const small601to768 = theme.useMediaQuery(
      theme.breakpoints.between(601, theme.breakpoints.values.medium),
      theme.breakpoints.defaultMatchBetween(601, theme.breakpoints.values.medium),
    );
    const small0to601 = theme.useMediaQuery(
      theme.breakpoints.between(0, 601),
      theme.breakpoints.defaultMatchBetween(0, 601),
    );
    const mediumUp = theme.mediumUp();
    const small601Up = small601to768 || mediumUp;

    const thisTop = getContainerStyledStickyBannerTopValue(informationalBannerHeight, small0to601);
    const thisMarginLeft = getContainerStyledStickyBannerMarginLeftValue(isVisible, small0to601, small601Up);

    const thisMarginBottom = (!small0to601 && !isVisible) ? rem(-150) : 0;

    return css`
      && {
        display: flex;
        position: fixed;
        left: ${small601Up ? '50%' : 0};
        top: ${thisTop};
        bottom: ${small601Up ? rem(30) : 'unset'};
        width: ${small601Up ? rem(600) : 'fill-available'};
        max-width: ${rem(600)};
        height: ${rem(90)};
        margin-left: ${thisMarginLeft};
        margin-right: ${small601Up ? 0 : rem(16)};
        margin-bottom: ${thisMarginBottom};
        background-color: ${theme.colors.background.darkTheme.darkDefault};
        border: ${rem(1)} solid ${theme.colors.background.darkTheme.darkDefault};
        box-shadow: ${theme.effects.shadows.dp8};
        z-index: ${theme.zIndex.stickyBanner};
        transition: ${small601Up ? 'margin-bottom 0.2s ease-out' : 'margin-left 0.2s ease-out'};
      }
    `;
  }}
`;

export const RowStyled = styled(Row)`
  && {
    width: 100%;
  }
`;

export const ColStyled = styled(Col)`
  ${({ theme, variant }) => {
    const small0to601 = theme.useMediaQuery(
      theme.breakpoints.between(0, 601),
      theme.breakpoints.defaultMatchBetween(0, 601),
    );
    const thisPaddingLeft = (small0to601 && variant === 'col-right') ? rem(16) : 0;

    return css`
      && {
        padding-left: ${thisPaddingLeft};
        padding-right: 0;
        display: inline-flex;
        align-items: center;
      }
    `;
  }}
`;

export const LightBulbIconStyled = styled(LightbulbIcon)`
  ${() => {
    const thisIconDimension = rem(57);
    return css`
      width: ${thisIconDimension};
      height: ${thisIconDimension};
    `;
  }}
`;

export const LinkStyled = styled(Link)`
  ${({ theme }) => {
    const small601to768 = theme.useMediaQuery(
      theme.breakpoints.between(601, theme.breakpoints.values.medium),
      theme.breakpoints.defaultMatchBetween(
        601,
        theme.breakpoints.values.medium,
      ),
    );
    const mediumUp = theme.mediumUp();
    const small601Up = small601to768 || mediumUp;
    const thisHeightAndWidth = small601Up ? rem(14) : rem(12);
    return css`
      && {
        display: ${small601Up ? 'inline' : 'block'};
        color: ${theme.colors.action.darkTheme.ctaBgDefault};
        font-size: ${small601Up ? rem(16) : rem(14)};
        line-height: ${small601Up ? rem(24) : rem(22)};
        &::after {
          position: relative;
          top: ${rem(1)};
          width: ${thisHeightAndWidth};
          height: ${thisHeightAndWidth};
          filter: invert(66%) sepia(75%) saturate(646%) hue-rotate(15deg) brightness(104%) contrast(101%);
        }
        &:hover {
          color: ${theme.colors.action.darkTheme.ctaBgHover};
          filter: invert(48%) sepia(77%) saturate(1364%) hue-rotate(16deg) brightness(97%) contrast(101%);
        }
      }
    `;
  }}
`;

export const TypographyStyled = styled(Typography)`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    return css`
      font-size: ${mediumUp ? rem(16) : rem(14)};
      line-height: ${mediumUp ? rem(24) : rem(22)};
    `;
  }
}`;

export const DismissStyled = styled(Dismiss)`
  ${({ theme }) => css`
    &&& {
      right: 0;
      .nmx-dismiss:hover {
        .nmx-icon:before,
        .nmx-icon:after,{
          background-color: ${theme.colors.neutral.white};
        }
      }
      .nmx-icon {
        &:before,
        &:after {
          background-color: ${theme.colors.neutral.white};
        }
        &:hover {
          &:before,
          &:after {
            background-color: ${theme.colors.neutral.gray};
          }
        }
      }
    }
  `}
`;
