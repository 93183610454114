import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import ChevronLeftNarrowIcon from '../../foundations/Icon/icons/utility/ChevronLeftNarrow';
import ChevronRightNarrowIcon from '../../foundations/Icon/icons/utility/ChevronRightNarrow';
import { Row, Col } from '../../foundations/Grid';

export const ColStyledSwiperWrapper = styled(Col)`
  && {
    height: auto;
  }
`;

export const DivStyledSliderSwiperSlide = styled.div`
  display: flex;
  justify-content: center;
`;

export const RowStyledSliderSwiperSlide = styled(Row)`
  width: 100%;
  justify-content: center;
`;

// Consuming module may need to hook into RowStyledSliderSwiperSlide > Col > .nmx-slider-slide to adjust slide width (i.e. Testimonial)
// default slider: nmx-col-xsmall-10
//   thisFlex = '0 83.33333333333334%';
//   thisMaxWidth = '83.33333333333334%';
// default testimonial: nmx-col-small-8
//   thisFlex = '0 66.66666666666666%';
//   thisMaxWidth = '66.66666666666666%';

export const DivStyledSliderSwiperNavigationContainer = styled.div`
  ${({ slideCount, theme }) => {
    const mediumUp = theme.mediumUp();
    return css`
      display: flex;
      position: ${mediumUp ? 'static' : 'relative'};
      bottom: ${rem(-32)};
      width: ${rem(40 * slideCount)};
      height: ${rem(16)};
      margin: 0 auto;
      justify-content: center;
      && { /** remove specificity after Video (Slider) and Content Hub (Slider) get refactored to utilize Slider Component, and the removal of carousel.scss */
        ${mediumUp
          && `
          .swiper-button-prev {
            left: 0;
            right: unset;
            margin-left: ${rem(12)}; /** offset pagination left button, also overwrites any Swiper css style stragglers */
          }
          .swiper-button-next {
            left: unset;
            right: 0;
            margin-right: ${rem(10)}; /** offset pagination left button, also overwrites any Swiper css style stragglers */
          `}
      }
    `;
  }}
`;

const paginationBulletIdenticalHoverActiveState = (theme, themeType) => css`background-color: ${themeType === 'darkTheme' ? theme.colors.action.darkTheme.linkDefault : theme.colors.action.lightTheme.linkDefault}`;

export const DivStyledSliderSwiperPagination = styled.div`
  ${({ theme, themeType }) => {
    const mediumUp = theme.mediumUp();
    return css`
      && { /** specificity ensures Swiper css styles are overwritten */
        ${mediumUp
          && `
            position: relative;
          `}
        height: ${rem(16)};
        margin-top: ${mediumUp ? rem(34) : rem(-9)};
        && { /** specificity ensures Swiper css styles are overwritten */
          .swiper-pagination-bullet {
            margin: 0 ${rem(8)};
            background-color: ${theme.colors.neutral.grayLight10};
            opacity: 1;
            :hover {
              ${paginationBulletIdenticalHoverActiveState(theme, themeType)};
            }
            &-active {
              ${paginationBulletIdenticalHoverActiveState(theme, themeType)};
              :hover {
                background-color: ${themeType === 'darkTheme' ? theme.colors.action.darkTheme.linkHover : theme.colors.action.lightTheme.linkHover};
              }
            }
          }
        }
      }
    `;
  }}
`;

export const DivStyledSliderSwiperNavigation = styled.div`
  && {
    display: block;
    width: ${rem(20)};
    height: ${rem(29)};
    margin-top: ${rem(-23)};
    text-align: center;
    &::after {
      display: none; /** hide stock Swiper arrow pagination */
    }
  }
`;

/* $themeType intentionally set as a transient prop to not add "themeType" attribute to SVG DOM element: https://styled-components.com/docs/api#transient-props */
const getIconStyles = (theme, $themeType) => {
  const mediumUp = theme.mediumUp();
  return css`
    ${mediumUp
      && `
        && {
          height: ${rem(50)};
        }
      `}
    .icon-stroke {
      stroke: ${$themeType === 'darkTheme' ? theme.colors.action.darkTheme.linkDefault : theme.colors.action.lightTheme.linkDefault};
    }
    :hover {
      .icon-stroke {
        stroke: ${$themeType === 'darkTheme' ? theme.colors.action.darkTheme.linkHover : theme.colors.action.lightTheme.linkHover};
      }
    }
  `;
};

export const IconStyledLeftSliderSwiperNavigation = styled(ChevronLeftNarrowIcon)`
  ${({ theme, $themeType }) => css`
    margin-left: ${rem(-26)};
    ${getIconStyles(theme, $themeType)};
  `}
`;

export const IconStyledRightSliderSwiperNavigation = styled(ChevronRightNarrowIcon)`
  ${({ theme, $themeType }) => css`
    margin-right: ${rem(-26)};
    ${getIconStyles(theme, $themeType)};
  `}
`;
