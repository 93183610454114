import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
// components
import Link from '../../components/Link';
// icons
import DrawerCaret from '../../foundations/Icon/icons/utility/DrawerCaret';

export const IconStyledDrawerCaret = styled(DrawerCaret)`
  ${({ theme }) => css`
    &&& {
      .icon-fill {
        fill: ${theme.colors.neutral.white};
      }
    }
  `
}`;

export const ListItemStyled = styled.li`
  ${({ theme }) => {
    const xlargeUp = theme.xlargeUp();
    const smallUp = theme.smallUp();
    let thisFontSize = rem(14);
    let thisLineHeight = rem(22);

    if (xlargeUp) {
      thisFontSize = rem(20);
      thisLineHeight = rem(30);
    } else if (smallUp) {
      thisFontSize = rem(16);
      thisLineHeight = rem(24);
    }

    return css`
      padding: ${rem(16)} 0;
      font-size: ${thisFontSize};
      line-height: ${thisLineHeight};
      &:not(:last-child) {
        border-bottom: ${rem(1)} solid ${theme.colors.brand.nm.secondary.sky}; /** TODO: style themeType */
      }
    `;
  }
}
`;

export const LinkStyled = styled(Link)`
  ${({ theme }) => css`
    display: inline-block;
    width: 100%;
    .nmx-icon {
      float: right;
      transform: rotate(270deg);
      margin-top: ${rem(8)};
      .icon-fill {
        fill: ${theme.colors.action.darkTheme.ctaBgDefault}; /** TODO: style themeType */
      }
    }

    &:hover {
      .nmx-icon {
        .icon-fill {
          fill: ${theme.colors.neutral.grayLight10}; /** TODO: style themeType */
        }
      }
    }
  `}
`;
