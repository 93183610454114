import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';

// local components
import { Row, Col } from '../../../../foundations/Grid';
import AssistiveText from '../../../../components/AssistiveText';

// styles
import {
  ContainerNavBreadCrumbStyled,
  NavBreadCrumbListStyled,
  NavBreadCrumbStyled,
  LinkContainerStyled,
  LinkStyled,
  SecondToLastItemStyled,
} from './styles';

export const BreadcrumbsNagigationComponent = ({ config }) => {
  const theme = useContext(ThemeContext);
  const smallDown = theme.smallDown();
  const [isXSmall, setIsXSmall] = useState(smallDown);

  useEffect(() => {
    if (smallDown !== isXSmall) {
      setIsXSmall(smallDown);
    }
  }, [smallDown]);

  return (
    <ContainerNavBreadCrumbStyled
      id="nmx-breadcrumbs-container">
      <Row>
        <Col>
          <NavBreadCrumbStyled
            aria-label="Breadcrumbs Navigation"
            className="nmx-breadcrumbs"
            id="nmx-breadcrumbs"
          >
            <AssistiveText component="h6">Breadcrumbs Navigation</AssistiveText>
            <NavBreadCrumbListStyled
              role="menu"
              className='reduced'
              itemScope
              itemType="https://schema.org/BreadcrumbList">
              {config.map((breadcrumb, index) => (
                <React.Fragment key={`key-${index + 1}`}>
                  {/* Always display last breadcrumb item */}
                  {index === config.length - 1
                    && <LinkContainerStyled
                      isSecondToLastItem={index < config.length - 2}
                      className='nmx-nav-link-breadcrumb-container'
                      role="none"
                      itemProp="itemListElement"
                      itemScope
                      itemType="https://schema.org/ListItem" >
                      <LinkStyled
                        role="menuitem"
                        linkUrl={breadcrumb.linkUrl}
                        className='nmx-nav-link nmx-nav-link-breadcrumb'
                        ariaLabel={breadcrumb.linkAriaLabel}
                        itemProp="item"
                        aria-current="page">
                        <span itemProp="name" >{breadcrumb.pageName}</span>
                      </LinkStyled>
                      <meta
                        itemProp="position"
                        content={index + 1} />
                    </LinkContainerStyled>
                  }
                  {/* Display ellipses for second last item on mobile, full menu item on desktop */}
                  {!isXSmall && index === config.length - 2 ? (
                    <LinkContainerStyled
                      isSecondToLastItem={index < config.length - 2}
                      className='nmx-nav-link-breadcrumb-container'
                      role="none"
                      itemProp="itemListElement"
                      itemScope
                      itemType="https://schema.org/ListItem">
                      <LinkStyled
                        role="menuitem"
                        linkUrl={breadcrumb.linkUrl}
                        className='nmx-nav-link nmx-nav-link-breadcrumb'
                        ariaLabel={breadcrumb.linkAriaLabel}
                        itemProp="item">
                        <span itemProp="name" >{breadcrumb.pageName}</span>
                      </LinkStyled>
                      <meta
                        itemProp="position"
                        content={index + 1} />
                    </LinkContainerStyled>
                  ) : null }
                  {isXSmall && index === config.length - 2 ? (
                    <LinkContainerStyled
                      isSecondToLastItem={index < config.length - 2}
                      className='nmx-nav-link-breadcrumb-container'
                      role="none"
                      itemProp="itemListElement"
                      itemScope
                      itemType="https://schema.org/ListItem">
                      <LinkStyled
                        role="menuitem"
                        linkUrl={breadcrumb.linkUrl}
                        className='nmx-nav-link nmx-nav-link-breadcrumb'
                        ariaLabel={breadcrumb.linkAriaLabel}
                        itemProp="item">
                        <meta
                          itemProp="name"
                          content={breadcrumb.pageName} />
                        <SecondToLastItemStyled>&#8230;</SecondToLastItemStyled>
                      </LinkStyled>
                      <meta
                        itemProp="position"
                        content={index + 1} />
                    </LinkContainerStyled>
                  ) : null}
                  {/* Display meta (hidden) for all other earlier levels on mobile, full menu item on desktop */}
                  {!isXSmall && index < config.length - 2 ? (
                    <LinkContainerStyled
                      isSecondToLastItem={index < config.length - 2}
                      className='nmx-nav-link-breadcrumb-container'
                      role="none"
                      itemProp="itemListElement"
                      itemScope
                      itemType="https://schema.org/ListItem">
                      <LinkStyled
                        role="menuitem"
                        linkUrl={breadcrumb.linkUrl}
                        className='nmx-nav-link nmx-nav-link-breadcrumb'
                        ariaLabel={breadcrumb.linkAriaLabel}
                        itemProp="item">
                        <span itemProp="name" >{breadcrumb.pageName}</span>
                      </LinkStyled>
                      <meta
                        itemProp="position"
                        content={index + 1} />
                    </LinkContainerStyled>
                  ) : null }
                  {isXSmall && index < config.length - 2 ? (
                    <LinkContainerStyled
                      isSecondToLastItem={index < config.length - 2}
                      className='nmx-nav-link-breadcrumb-container'
                      role="none"
                      itemProp="itemListElement"
                      itemScope
                      itemType="https://schema.org/ListItem">
                      <link
                        itemProp="item"
                        href={breadcrumb.linkUrl} />
                      <meta
                        itemProp='name'
                        content={breadcrumb.pageName} />
                      <meta
                        itemProp="position"
                        content={index + 1} />
                    </LinkContainerStyled>
                  ) : null}
                </React.Fragment>
              ))}
            </NavBreadCrumbListStyled>
          </NavBreadCrumbStyled>
        </Col>
      </Row>
    </ContainerNavBreadCrumbStyled>
  );
};

BreadcrumbsNagigationComponent.propTypes = {
  config: PropTypes.any,
  showInformationalBanner: PropTypes.bool,
};

export default BreadcrumbsNagigationComponent;
