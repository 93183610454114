import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

// component imports
import { Col } from '../../foundations/Grid';
import Link from '../../components/Link';
import Picture from '../../components/Picture';
import Typography from '../../foundations/Typography';

export const ColContentStyled = styled(Col)`
  ${({ theme }) => {
    let paddingRight = 0;
    const mediumUp = theme.mediumUp();
    const largeUp = theme.largeUp();
    const xlargeUp = theme.xlargeUp();

    if (xlargeUp) {
      paddingRight = rem(65);
    } else if (largeUp) {
      paddingRight = rem(40);
    } else if (mediumUp) {
      paddingRight = rem(30);
    }

    return css`
      text-align: left;
      padding-right: ${paddingRight};
    `;
  }}
`;

export const ColDesktopStatCardContainer = styled(Col)`
  ${({ theme }) => css`
    ${theme.mediumDown() && 'display: none;'};
  `}
`;

export const DivMobileStatCardContainer = styled.div`
  ${({ theme, buttonText, LinkText }) => {
    let paddingBottom = rem(32);
    if (!buttonText && !LinkText) {
      paddingBottom = 0;
    }
    return css`
      display: ${theme.mediumUp() ? 'none' : 'block'};
      padding-bottom: ${paddingBottom};
    `;
  }}
`;

export const DivStatCardStyled = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.background.lightTheme.lightB};
    padding: ${theme.mediumUp() ? rem(48) : rem(32)};
    margin: 0 ${theme.mediumUp() ? rem(10) : 0};
  `}
`;

export const PrimaryCtaStyled = styled(Link)`
  ${({ theme }) => {
    const horizontalMargin = theme.mediumUp() ? 0 : 'auto';
    return css`
      margin-bottom: ${theme.mediumUp() ? rem(24) : rem(14)};
      margin-left: ${horizontalMargin};
      margin-right: ${horizontalMargin};
      ${!theme.mediumUp() && 'display: block;'}
    `;
  }}
`;

export const SecondaryCtaStyled = styled(Link)`
  ${({ theme }) => css`
    display: block;
    padding-bottom: 0;
    ${!theme.mediumUp() && 'text-align: center;'};
    @media print {
      display: none;
    }
  `}
`;

export const PictureCardImageStyled = styled(Picture)`
  padding-top: 0;
  padding-bottom: ${rem(16)};
  width: 60%;
  max-width: ${rem(150)};
  margin: 0 auto;
`;

// Card Heading
export const TypographyCardHeadingStyled = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${rem(50)};
    line-height: ${rem(50)};
    font-weight: 300;
    text-align: center;
    color: ${theme.colors.blue.blue};
    overflow: hidden;
    padding-bottom: ${theme.mediumUp() ? rem(18) : rem(20)};
    ::after {
      content: '';
      display: block;
      width: ${rem(48)};
      height: ${rem(3)};
      background-color: ${theme.colors.gold.gold};
      margin: ${rem(18)} auto 0;
    }
    @media print {
      ::after {
        display: none;
      }
    }
  `}
`;

// section Heading
export const TypographyContentHeadingStyled = styled(Typography)`
  ${({ theme }) => css`
    text-align: ${theme.mediumUp() ? 'left' : 'center'};
  `}
`;
