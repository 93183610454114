import Location from '@nmx/utils-frontend/dist/utilities/frontend/location';
import Session from '@nmx/utils-frontend/dist/utilities/frontend/session';

if (Location.hasQueryParam('utm_source')) {
  Session.set('utmSource', Location.getQueryParam('utm_source'));
}

if (Location.hasQueryParam('utm_medium')) {
  Session.set('utmMedium', Location.getQueryParam('utm_medium'));
}

if (Location.hasQueryParam('utm_campaign')) {
  Session.set('utmCampaign', Location.getQueryParam('utm_campaign'));
}

if (Location.hasQueryParam('utm_content')) {
  Session.set('utmContent', Location.getQueryParam('utm_content'));
}

if (Location.hasQueryParam('utm_term')) {
  Session.set('utmTerm', Location.getQueryParam('utm_term'));
}

if (Location.hasQueryParam('agentid')) {
  // try to get agentid from query params
  const agentIdFromQueryParam = Location.getQueryParam('agentid');

  // gather specified audiences from tealium
  let audiences;
  try {
    audiences = Object.values(JSON.parse(localStorage.getItem('teal_adbe_enrichment_data')).audiences).toString().replace(/,/g, '|').toLowerCase();
  } catch (err) {
  // do nothing
    console.error(`Error capturing audiences from Tealium with error message: ${err}`);
  }

  // set chosen audiences array for audiences that should have agentNumber stored into LocaStorage
  // For now, it's just one audience that we're looking for, but I'm leaving the loop in place so we can easily add more.
  const audiencesArray = ['js_client'];
  let userIsClient = false;
  // check for any audience matches coming from tealium
  if (audiences) {
    for (let i = 0; i < audiencesArray.length; i += 1) {
      if (audiences.includes(audiencesArray[i])) {
        userIsClient = true;
      }
    }
  }

  if (agentIdFromQueryParam) {
    if (userIsClient) {
      // IF js_client is present in tealium/LocalStorage, set agentid (as agentNumber) to LocalStorage
      localStorage.setItem('agentNumber', agentIdFromQueryParam);
    } else {
    // ELSE Set agentid to SessionStorage
      Session.set('agentNumber', agentIdFromQueryParam);
    }
  }
}
