// deprecated
import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { Col } from '../../../foundations/Grid';

export const ColPhotoContainerStyled = styled(Col)`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    return css`
      && {
        padding-left: ${mediumUp ? 0 : rem(16)};
        margin-bottom: ${mediumUp ? 0 : rem(24)};
      }
    `;
  }
}`;

export const ImgStyled = styled.img`
  ${({ theme, photoWidth }) => {
    const smallDown = theme.smallDown();
    let thisWidth = 'auto';

    if (smallDown) {
      thisWidth = '100%';
    } else if (photoWidth) {
      thisWidth = `${photoWidth}%`;
    }

    return css`
      width: ${thisWidth};
    `;
  }
}`;

export const DivAgentCardWrapperStyled = styled.div`
  padding-top: ${rem(16)};
  padding-bottom: ${rem(32)};
`;

export const ColFormContainerStyled = styled(Col)`
${({ theme }) => {
    const mediumUp = theme.mediumUp();
    return css`
      ${mediumUp && `
        padding-left: ${rem(32)};
        padding-bottom: ${rem(20)};
        border-left: ${rem(1)} solid ${theme.colors.gold.gold};
      `}
      margin-top: ${mediumUp ? 0 : rem(32)};
    `;
  }
}`;
