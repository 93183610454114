import React from 'react';
import PropTypes from 'prop-types';
// components
import AssistiveText from '../../../../components/AssistiveText';
import { Container, Row } from '../../../../foundations/Grid';
import DisclaimerSection from '../../components/DisclaimerSection';
// styles
import {
  ButtonStyled,
  ColStyled,
} from './styles';

export const FooterComponent = ({
  customDisclaimers,
  disableDisclaimerTopBorder,
  disclaimerFootnotes,
  isAmp,
  stateFilingComplianceNumber,
}) => (
  <footer
    id='footer'
    data-nosnippet>
    <Container>
      <AssistiveText component='h3'>
        Find What You&#39;re Looking for at Northwestern Mutual
      </AssistiveText>
      <DisclaimerSection
        customDisclaimers={customDisclaimers}
        disableDisclaimerTopBorder={disableDisclaimerTopBorder}
        disclaimerFootnotes={disclaimerFootnotes}
        isAmp={isAmp}
        stateFilingComplianceNumber={stateFilingComplianceNumber}
      />
      <Row>
        <ColStyled>
          {/* <!-- OneTrust Cookies Settings button start --> */}
          <ButtonStyled
            variant='tertiary'
            id='ot-sdk-btn' // DO NOT CHANGE
            className='ot-sdk-show-settings nmx-override' // DO NOT CHANGE
            role='menuitem'>
            Do Not Sell or Share My Personal Information
          </ButtonStyled>
          {/* <!-- #OneTrust Cookies Settings button end --> */}
        </ColStyled>
      </Row>
    </Container>
  </footer>
);

FooterComponent.propTypes = {
  /** any page-specific, non-ordered footnotes */
  customDisclaimers: PropTypes.any,
  /** optional boolean to remove top border (when page last section has a background color other than white) */
  disableDisclaimerTopBorder: PropTypes.bool,
  /** page-specific, ordered footnotes used in conjunction with superscript numbering on the page */
  disclaimerFootnotes: PropTypes.any,
  /** optional isAmp boolean */
  isAmp: PropTypes.bool,
  /** optional state filing compliance number for pages that have to legally display one */
  stateFilingComplianceNumber: PropTypes.any,
};

FooterComponent.defaultProps = {
  disableDisclaimerTopBorder: false,
  footerVariant: 'nmCom',
  isAmp: false,
};

export default FooterComponent;
