import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
// components
import gradients from '../../../../foundations/theme/effects/gradients';
import Link from '../../../../components/Link';
import Typography from '../../../../foundations/Typography';
// icons
import MagnifyingGlass from '../../../../foundations/Icon/icons/illustrative/darkTheme/MagnifyingGlass';

export const IconStyled = styled(MagnifyingGlass)`
  ${({ theme, formvariant }) => {
    let marginTop = 0;
    let iconWidth = rem(50);
    const xlargeUp = theme.xlargeUp();
    const mediumUp = theme.mediumUp();

    if (mediumUp) {
      marginTop = rem(-25);
    }

    if (formvariant === 'hero' && xlargeUp) {
      iconWidth = rem(60); // hero desktop icon width
    }

    return css`
      width: ${iconWidth};
      margin-top: ${marginTop};
      margin-bottom: ${rem(10)};
    `;
  }}
`;

export const LinkStyled = styled(Link)`
  ${({ theme, themeType, disableBottomPadding }) => {
    const mediumUp = theme.mediumUp();
    return css`
    display: block;
    padding-bottom: ${disableBottomPadding ? 0 : rem(24)};
    text-align: ${mediumUp ? 'center' : 'left'};
    ${themeType === 'nmx-pcg'
      && `
      color: ${theme.colors.neutral.white};
      text-decoration: underline;
      :hover,
      :active {
        color: ${theme.colors.brand.pcg.gray};
      }
    `}
  `;
  }
}
`;

export const TypographyStyled = styled(Typography)`
  ${({ themeType }) => css`
    text-align: center;
    ${themeType === 'nmx-pcg'
      && `
      text-transform: uppercase;
      letter-spacing: ${rem(4)};
      font-weight: 400;
      ::after {
        content: '';
        display: block;
        height: ${rem(3)};
        width: ${rem(100)};
        margin: ${rem(10)} auto 0;
        ${gradients.pcgGold};
      }
    `};
  `}
`;
