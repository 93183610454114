// deprecated
import React from 'react';
import PropTypes from 'prop-types';
import { SpanStyledPlaceholderInitials } from './styles';

const Placeholder = ({ first, last }) => (<SpanStyledPlaceholderInitials>{first?.substring(0, 1)} {last?.substring(0, 1)}</SpanStyledPlaceholderInitials>);

Placeholder.propTypes = {
  first: PropTypes.string,
  last: PropTypes.string,
};

export default Placeholder;
