export function checkMobileDeviceWidth() {
  const mmMedium = typeof window !== 'undefined' && window.matchMedia('(min-width: 768px)');
  if (mmMedium.matches === false) {
    // mobile actions
    return 'mobile';
  }
  return 'desktop';
}

export function checkDesktopLargeDeviceWidth() {
  const mmLarge = typeof window !== 'undefined' && window.matchMedia('(min-width: 1024px)');
  return mmLarge.matches;
}
