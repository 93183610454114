import React, { useEffect, useState, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
// components
import { Row, Col, Container } from '../../../../foundations/Grid';
import AppDownload from '../../components/AppDownload';
import AssistiveText from '../../../../components/AssistiveText';
import CopyrightSection from '../../components/CopyrightSection';
import DisclaimerSection from '../../components/DisclaimerSection';
import FooterLogo from '../../components/FooterLogo';
import NavLinks from '../../components/NavLinks';
import SocialLinks from '../../components/SocialLinks';
import WidowBlock from '../../../../components/WidowBlock';
// icons
import CaratRightUtility from '../../../../foundations/Icon/icons/utility/CaratRight';
// styles
import {
  ColStyledAppDownload,
  ColStyledFafa,
  ColStyledSocialLinksLargeUp,
  FooterSection,
  LinkStyledFafa,
  TypographyStyledFafa,
} from './styles';

export const FooterComponent = ({
  appendFooter,
  config,
  customDisclaimers,
  disableDisclaimerTopBorder,
  disclaimerFootnotes,
  isAmp,
  legalCopy,
  stateFilingComplianceNumber,
}) => {
  const { basePath, nmxTemplateVersion } = config;
  const theme = useContext(ThemeContext);
  const mediumUp = theme.mediumUp();
  const largeUp = theme.largeUp();
  const [isMediumUp, setIsMediumUp] = useState(mediumUp);
  const [isLargeUp, setIsLargeUp] = useState(largeUp);

  useEffect(() => {
    if (mediumUp === isMediumUp) {
      setIsMediumUp(mediumUp);
    }
  }, [mediumUp]);

  useEffect(() => {
    if (largeUp === isLargeUp) {
      setIsLargeUp(largeUp);
    }
  }, [largeUp]);

  return (
    // TODO: remove class "nmx-base" once nmx-template 1.0.x is implemented and "nmx-base" is added to body tag
    <footer
      id='footer'
      data-nosnippet>
      <Container>
        <AssistiveText component='h3'>
          Find What You&#39;re Looking for at Northwestern Mutual
        </AssistiveText>
        <DisclaimerSection
          customDisclaimers={customDisclaimers}
          disableDisclaimerTopBorder={disableDisclaimerTopBorder}
          disclaimerFootnotes={disclaimerFootnotes}
          isAmp={isAmp}
          stateFilingComplianceNumber={stateFilingComplianceNumber}
        />
        <FooterSection>
          <Row align='left'>
            <Col>
              <FooterLogo
                isAmp={isAmp}
                basePath={basePath}
                nmxTemplateVersion={nmxTemplateVersion} />
            </Col>
          </Row>
          <Row>
            <Col
              medium={7}
              large={5}
              nested>
              <NavLinks basePath={basePath} />
            </Col>
            {largeUp && (
              <ColStyledSocialLinksLargeUp
                large={3}
                className='nmx-no-print'>
                <SocialLinks />
              </ColStyledSocialLinksLargeUp>
            )}
            <ColStyledAppDownload
              medium={5}
              large={4}
              nested>
              <AppDownload config={config} />
            </ColStyledAppDownload>
            <ColStyledFafa>
              <TypographyStyledFafa
                component='h4'
                variant={mediumUp ? 'p' : 'h3'}
                disableBottomPadding>
                Over 6,400 Financial Advisors and <WidowBlock>Professionals Nationwide&#42;</WidowBlock>
              </TypographyStyledFafa>
              <LinkStyledFafa
                linkUrl='/find-a-financial-advisor/'
                className='nmx-no-print'
                config={config}
                endAdornment={CaratRightUtility}
                endAdornmentStylesReset
                variant='buttonLinkTertiary'>
                Find an Advisor
              </LinkStyledFafa>
            </ColStyledFafa>
            {!mediumUp && (
              <Col
                medium={4}
                large={3}
                className='nmx-no-print'>
                <SocialLinks />
              </Col>
            )}
          </Row>
        </FooterSection>
        <CopyrightSection
          legalCopy={legalCopy}
          appendFooter={appendFooter} />
      </Container>
    </footer>
  );
};

FooterComponent.propTypes = {
  /** Additional footer verbiage, this was pulled in from nmx-static // TODO: still being used? */
  appendFooter: PropTypes.any,
  /** Config vars used in footer */
  config: PropTypes.shape({
    basePath: PropTypes.string.isRequired,
    nmxTemplateVersion: PropTypes.string.isRequired,
  }).isRequired,
  /** any page-specific, non-ordered footnotes */
  customDisclaimers: PropTypes.any,
  /** optional boolean to remove top border (when page last section has a background color other than white) */
  disableDisclaimerTopBorder: PropTypes.bool,
  /** page-specific, ordered footnotes used in conjunction with superscript numbering on the page */
  disclaimerFootnotes: PropTypes.any,
  /** optional footer variant prop to select which specific (and different) footer to render */
  footerVariant: PropTypes.oneOf(['nmCom', 'sare']),
  /** optional isAmp boolean */
  isAmp: PropTypes.bool,
  /** legal copy in reference to footer verbiage (below disclaimer) */
  legalCopy: PropTypes.any,
  /** optional state filing compliance number for pages that have to legally display one */
  stateFilingComplianceNumber: PropTypes.any,
};

FooterComponent.defaultProps = {
  disableDisclaimerTopBorder: false,
  footerVariant: 'nmCom',
  isAmp: false,
};

export default FooterComponent;
