import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import { defaultInputStyles } from '../TextField/styles';

// TODO: one day, come back and style an accessible Select Dropdown Component, which is typically extremely difficult to achieve: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/select#styling_with_css

const SelectStyled = styled.select`
  ${({ branding, disabled, hasError, theme, themeType }) => {
    // value
    const mediumUp = theme.mediumUp();

    // Default Styles
    const getDefaultStyles = () => css`
      min-height: ${mediumUp ? rem(55) : rem(47)}; /** TODO: move into defaultInputStyles */
      padding: ${mediumUp ? rem(14) : rem(10)}; /** TODO: move into defaultInputStyles */
      ${defaultInputStyles}
      letter-spacing: ${rem(1)};
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; // needed to remove native/default dropdown arrow
      :-ms-expand {
        display: none; // needed to remove native/default dropdown arrow
      }
      :-ms-clear {
        display: none; // needed to remove native/default dropdown arrow
      }
      /** cross-brower fallback to change the BG color of the select dropdowns */
      /**  This affects IE, Edge, and Firefox */
      option {
        color: ${theme.colors.forms.lightTheme.inputFieldText}; /** fixes Windows Chrome */
        background-color ${theme.colors.forms.lightTheme.inputFieldSelectBg}; /** fixes Windows Chrome */
      }
      white-space: break-spaces;
    `;

    // Light Theme
    const getLightThemeStyles = () => css`
      border: ${rem(1)} solid ${theme.colors.neutral.gray};
      color: ${theme.colors.forms.lightTheme.inputFieldText};
    `;

    // Disabled Light Theme
    const getDisabledLightThemeStyles = () => css`
      border: ${rem(1)} solid ${theme.colors.neutral.gray};
      color: ${theme.colors.text.lightTheme.disabled};
    `;

    // Error Light Theme
    const getErrorLightThemeStyles = () => css`
      border: ${rem(1)} solid ${theme.colors.text.lightTheme.error};
    `;

    // Dark Theme
    const getDarkThemeStyles = () => css`
      border: ${rem(1)} solid ${theme.colors.forms.darkTheme.inputFieldText};
      background-color: ${theme.colors.forms.darkTheme.inputFieldBg};
      color: ${theme.colors.forms.darkTheme.inputFieldText};
    `;

    // Disabled Dark Theme
    const getDisabledDarkThemeStyles = () => css`
      border: ${rem(1)} solid ${theme.colors.text.darkTheme.disabled};
      background-color: ${theme.colors.forms.darkTheme.inputFieldBg};
      color: ${theme.colors.text.darkTheme.disabled};
    `;

    // Error Dark Theme
    const getErrorDarkThemeStyles = () => css`
      border: ${rem(1)} solid ${theme.colors.text.darkTheme.error};
      background-color: ${theme.colors.forms.darkTheme.inputFieldBg};
      color: ${theme.colors.forms.darkTheme.inputFieldText};
    `;

    // PCG Theme
    const getPcgThemeStyles = () => css`
      border: ${rem(1)} solid ${theme.colors.text.darkTheme.inputFieldText};
      background-color: ${theme.colors.brand.pcg.darkBlue5};
      color: ${theme.colors.forms.darkTheme.inputFieldText};
      &:hover {
        background-color: ${theme.colors.brand.pcg.darkBlue4};
      }
      :-webkit-autofill,
      :-webkit-autofill:hover,
      :-webkit-autofill:focus,
      :-webkit-autofill:active,
      :-webkit-autofill::first-line {
        -webkit-box-shadow: 0 0 0 1000px ${theme.colors.brand.pcg.darkBlue5}
          inset;
        -webkit-text-fill-color: ${theme.colors.neutral.white};
      }
    `;

    // Error PCG Theme
    const getErrorPcgStyles = () => css`
      border: ${rem(1)} solid ${theme.colors.text.darkTheme.error};
      background-color: ${theme.colors.brand.pcg.darkBlue5};
      color: ${theme.colors.forms.darkTheme.inputFieldText};
      &:hover {
        background-color: ${theme.colors.brand.pcg.darkBlue4};
      }
      :-webkit-autofill,
      :-webkit-autofill:hover,
      :-webkit-autofill:focus,
      :-webkit-autofill:active,
      :-webkit-autofill::first-line {
        -webkit-box-shadow: 0 0 0 1000px ${theme.colors.brand.pcg.darkBlue5}
          inset;
        -webkit-text-fill-color: ${theme.colors.neutral.white};
      }
    `;

    return css`
      /** Default/Base SelectField styles get loaded first every time */
      ${getDefaultStyles()}
      // then, conditionally, color, border, background-color styles get added
      ${themeType === 'lightTheme'
        && getLightThemeStyles()} /** Default = Primary Light Theme */
      ${themeType === 'lightTheme' && disabled && getDisabledLightThemeStyles()}
      ${themeType === 'lightTheme' && hasError && getErrorLightThemeStyles()}
      ${themeType === 'darkTheme' && getDarkThemeStyles()}
      ${themeType === 'darkTheme' && disabled && getDisabledDarkThemeStyles()}
      ${themeType === 'darkTheme' && hasError && getErrorDarkThemeStyles()}
      ${themeType === 'darkTheme'
        && branding === 'pcg'
        && getPcgThemeStyles()} // TODO: build PCG lightTheme
      ${themeType === 'darkTheme'
        && branding === 'pcg'
        && hasError
        && getErrorPcgStyles()}
    `;
  }}
`;

export default SelectStyled;
