import styled, { css } from 'styled-components';
import { rem } from 'polished';
import Button from '../../../../components/Button';

export const ButtonStyled = styled(Button)`
${({ theme }) => {
    const largeUp = theme.largeUp();
    return css`
      max-width: unset;
      margin: 0 ${rem(16)};
      font-size: ${rem(12)};
      line-height: ${rem(18)};
      border-color: ${theme.colors.neutral.white};
      color: ${theme.colors.blue.blue};
      font-weight: 500;
      width: 100%;
      height: 100%;
      text-transform: uppercase;
      justify-content: flex-start;
      letter-spacing: ${rem(1)};
      padding-left: ${rem(32)};

    &::after {
      position: absolute;
      left: ${rem(16)};
      width: ${rem(30)};
      height: ${rem(18)};
      background-repeat: no-repeat;
      content: '\u2190';
      font-size: ${rem(20)};
    }
    ${largeUp && css`
        display: none;
    `}
    `;
  }}
`;

export const AStyled = styled.a`
  ${({ theme, isHover, level, hasSubNavs, isFocused, nmxTemplateVersion, basePath }) => {
    const largeUp = theme.largeUp();
    const isActive = isHover || isFocused;

    return css`
      color: ${theme.colors.blue.blue};
      text-decoration: none;
      text-transform: uppercase;
      letter-spacing: ${rem(3)};
      font-size: ${rem(12)};
      position: relative;
      line-height: ${rem(18)};
      font-weight: 500;
      border-bottom: ${rem(1)} solid ${theme.colors.neutral.gray59};
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      margin: 0 ${rem(16)};

      ${(level >= 2) && css`
        color: ${theme.colors.blue.blue};
        width: 100%;
      `}

      ${hasSubNavs && css`
        &::after {
            position: absolute;
            text-decoration: none;
            right: 5%;
            width: ${rem(15)};
            height: ${rem(15)};
            background-image: url('${basePath}/template/assets/${nmxTemplateVersion}/images/icons/utility/chevron-right.svg');
            background-repeat: no-repeat;
            background-size: ${rem(8)};
            content: ' ';
            ${!largeUp && css`
              filter: invert(25%) sepia(96%) saturate(7900%) hue-rotate(195deg) brightness(100%) contrast(84%);
            `}
            ${largeUp && css`
              display: none;
            `}
        }
      `}

        ${largeUp && css`
            animation: none;
            margin: 0 ${rem(15)};
            border-bottom: none;
            width: auto;

            ${isActive && level === 1 && css`
                color: ${theme.colors.action.darkTheme.ctaBgDefault};
                &::after {
                    position: absolute;
                    bottom: ${rem(-1)};
                    left: 0;
                    display: block;
                    width: 100%;
                    height: ${rem(4)};
                    margin-top: ${rem(13)};
                    background: ${theme.colors.action.darkTheme.ctaBgDefault};
                    content: '';
                }
            `}
            ${level >= 2 && css`
                color: ${theme.colors.neutral.grayDark20};
                ${isHover && css`
                    color: ${theme.colors.blue.blue};
                    text-decoration: underline;
                `}
            `}
        `}
  `;
  }}
`;

export const UlStyled = styled.ul`
  ${({ theme, isVisible, isClicked }) => {
    const largeUp = theme.largeUp();

    return css`
      display: block;
      position: absolute;
      padding: 0;
      background-color: ${theme.colors.neutral.white};
      z-index: ${theme.zIndex.globalNavPrimary};
      animation: none;
      opacity: 1;

      ${!largeUp && css`
        width: 100%;
        top: 0;
        margin-left: -100%;
        transition: margin-left 0.15s ease-out; /** mobile transition */

        ${isClicked !== undefined && css`
          margin-left: -100%;
        `}

        ${isClicked && css`
          margin-left: 0;
          visibility: visible;
          opacity: 1;
          transform: scale(1) translateY(0);
          z-index: ${theme.zIndex.globalNav};
        `}
      `}
      ${largeUp && css`
        width: auto;
        height: auto;
        margin-top: ${rem(2)};
        margin-left: 0;
        padding: ${rem(15)};
        opacity: 0;
        transform-origin: 20% -10%;
        transform: scale(0.1) translateY(-10%);
        transition: transform 0.15s ease, opacity 0.15s ease; /** desktop transition */
        box-shadow: 0 ${rem(4)} ${rem(16)} 0 rgb(0 0 0 / 10%);

        ${isVisible && css`
          margin-left: 0;
          opacity: 1;
          transform: scale(1) translateY(0);
          box-shadow: 0 ${rem(4)} ${rem(16)} 0 rgb(0 0 0 / 10%);
        `}
      `}
    `;
  }
}`;

export const LiStyled = styled.li`
  ${({ theme, level }) => {
    const largeUp = theme.largeUp();
    let thisHeight = rem(60);
    let thisDisplay = 'flex';

    if (largeUp) {
      thisHeight = rem(75);
      thisDisplay = 'inline-block';
      if (level === 2) {
        thisHeight = rem(42);
        thisDisplay = 'inherit';
      }
    }

    return css`
      display: ${thisDisplay};
      align-items: center;
      height: ${thisHeight};
      background-color: ${theme.colors.neutral.white};
      ${largeUp && 'overflow-wrap: break-word;'};
      ${(largeUp && (level === 2)) && 'width: max-content;'};
    `;
  }}
`;

export const LiStyledMobile = styled.li`
  ${({ theme }) => {
    const largeUp = theme.largeUp();

    return css`
      height: ${rem(60)};
      display: flex;
      align-items: center;
      ${largeUp
        && css`
          display: none;
      `}
    `;
  }}
`;

export const SpanStyledMobile = styled.span`
  ${({ theme, offsetTop }) => {
    const largeUp = theme.largeUp();

    return css`
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: calc(100vh - ${offsetTop + 3}px);
      background-color: ${theme.colors.neutral.white};
      opacity: 1;
      ${largeUp
        && css`
          display: block;
      `}
    `;
  }
}`;
