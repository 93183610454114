import React from 'react';
import PropTypes from 'prop-types';
import Select from '../../../../components/forms/Select';
import { incomes } from '../../utilities/inPageLeadFormData';

export const WealthRangeSelectInputComponent = React.forwardRef(({
  hasError,
  helperText,
  onBlur,
  onChange,
  themeType,
  value,
}, ref) => (
  <Select
    hasError={hasError}
    helperText={helperText}
    id="in-page-lead-form-wealth-range-select"
    labelText='Net Worth'
    name='wealthRange'
    onBlur={onBlur}
    onChange={onChange}
    options={incomes}
    ref={ref}
    required={true}
    themeType={themeType}
    value={value} />
));

WealthRangeSelectInputComponent.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default WealthRangeSelectInputComponent;
