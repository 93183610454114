import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
// components
import { Col } from '../../foundations/Grid';
import Typography from '../../foundations/Typography';
import Link from '../../components/Link';
// icons
import ArrowRightIcon from '../../foundations/Icon/icons/utility/ArrowRight';

export const AnchorStyledArticleContainer = styled.a`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

export const ArticleStyledDynamicCallout = styled.article`
  ${({ theme, backgroundVariant, themeType }) => {
    let thisBackgroundColor = theme.colors.background.lightTheme.lightA;

    if (themeType === 'lightTheme') {
      // lightA is default
      if (backgroundVariant === 'lightB') {
        thisBackgroundColor = theme.colors.background.lightTheme.lightB;
      } else if (backgroundVariant === 'lightC') {
        thisBackgroundColor = theme.colors.background.lightTheme.lightC;
      } else if (backgroundVariant === 'lightSky') {
        thisBackgroundColor = theme.colors.background.lightTheme.lightSky;
      }
    }
    // darkTheme backgroundVariants
    if (themeType === 'darkTheme' && backgroundVariant === 'darkDefault') {
      thisBackgroundColor = theme.colors.background.darkTheme.darkDefault;
    }

    return css`
    position: relative;
    background-color: ${thisBackgroundColor};
  `;
  }
}
`;

export const ButtonStyledDynamicCalloutOverlay = styled.button`
  width: 100%;
  height: 100%;
  opacity: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  &:hover {
    cursor: pointer;
  }
`;

export const ColStyledContentContainer = styled(Col)`
  margin: auto;
`;

export const ColStyledImageContainer = styled(Col)`
  margin: 0;
`;

export const DivStyledBannerImageContainer = styled.div`
  ${({ theme, image, imageWebp }) => {
    const smallUp = theme.smallUp();
    return css`
      min-height: ${smallUp ? rem(265) : rem(175)};
      height: 100%;
      width: 100%;
      /* if webp is not supported, fall back to the default image */
      background-image: url(${image});
      /* browsers that support the gap property and ones that support the webp property are nearly identical */
      /* If the imageWebp prop is passed in, display that, otherwise we fall back to the default image */
      ${imageWebp && `
        @supports (gap: 1px) {
          background-image: url(${imageWebp});
        }
      `}
      background-size: cover;
      background-position: center;
    `;
  }}
`;

export const DivStyledCardImage = styled.div`
  ${({ theme, image, imageWebp }) => {
    const mediumUp = theme.mediumUp();

    return css`
      height: ${mediumUp ? rem(200) : rem(160)};
      width: 100%;
      background-size: cover;
      background-position: center;
      /* if webp is not supported, fall back to the default image */
      background-image: url(${image});
      /* browsers that support the gap property and ones that support the webp property are nearly identical */
      /* If the imageWebp prop is passed in, display that, otherwise we fall back to the default image */
      ${imageWebp && `
        @supports (gap: 1px) {
          background-image: url(${imageWebp});
        }
      `}
    `;
  }}
`;

export const DivStyledContentContainer = styled.div`
  ${({ theme }) => {
    const largeUp = theme.largeUp();
    let padding = rem(32);
    if (largeUp) {
      padding = `${rem(32)} ${rem(64)}`;
    }

    return css`
      align-items: center;
      text-align: center;
      height: 100%;
      padding: ${padding};
    `;
  }}
`;

export const DivStyledDynamicCalloutBanner = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.neutral.grayLight30};
    overflow: hidden;
    transition: ${theme.effects.shadows.transition};
    margin: 0 0 ${rem(32)};
    position: relative;
    &:hover {
      box-shadow: ${theme.effects.shadows.hover};
      text-decoration: none;
    }
  `}
`;

export const DivStyledDynamicCalloutCard = styled.div`
  ${({ theme }) => css`
    overflow: hidden;
    transition: ${theme.effects.shadows.transition};
    margin: 0 0 ${rem(32)};
    position: relative;
    overflow: visible;
    &:hover {
      box-shadow: ${theme.effects.shadows.hover};
      text-decoration: none;
    }
  `}
`;

export const DivStyledDynamicCalloutCardContainer = styled.div`
  flex-direction: column;
  padding: ${rem(24)};
`;

export const IconStyledVisualLink = styled(ArrowRightIcon)`
  margin-left: ${rem(8)};
  margin-bottom: ${rem(1)};
`;

export const LinkStyledDynamicCalloutBannerButton = styled(Link)`
  margin-top: ${rem(16)};
  text-transform: none;
`;

export const SpanStyledArticleFlag = styled.span`
  ${({ flagColor }) => css`
    position: absolute;
    top: ${rem(8)};
    left: ${rem(-8)};
    display: inline-flex;
    height: ${rem(25)};
    align-items: center;
    padding: ${rem(13)};
    background-color: ${flagColor};
    z-index: 1;
    border-radius: 0 0 1px 0;
    ::after {
      position: absolute;
      top: 0;
      right: ${rem(-12)};
      border-bottom: ${rem(26)} solid transparent;
      border-left: ${rem(12)} solid ${flagColor};
      content: '';
      @media print {
        display: none;
      }
    }
  `}
`;

export const TypographyStyledArticleFlag = styled(Typography)`
  ${({ theme, branding }) => {
    let thisFontWeight = 500;
    let thisTextColor = theme.colors.neutral.black;

    if (branding === 'pcg') {
      thisFontWeight = 300;
      thisTextColor = theme.colors.neutral.greyDark3;
    }

    return css`
      && {
        color: ${thisTextColor};
        font-size: ${rem(14)};
        text-transform: uppercase;
        letter-spacing: ${rem(2.5)};
        margin-top: ${rem(2)};
        font-weight: ${thisFontWeight};
      }
    `;
  }}
`;

export const TypographyStyledCard = styled(Typography)`
  ${({ theme, hasLink, branding, backgroundVariant }) => {
    const medium = theme.mediumUp();
    let thisFontWeight = 500;
    let paddingBottom;
    if (medium) {
      paddingBottom = rem(64);
    } else {
      paddingBottom = rem(48);
    }

    if (branding === 'pcg') {
      thisFontWeight = 300;
    }

    return css`
      && {
        padding-bottom:${hasLink ? paddingBottom : 0};
        font-weight: ${thisFontWeight};
        ${(backgroundVariant === 'darkDefault') && `color: ${theme.colors.neutral.white};`}
      }
    `;
  }}
`;

export const TypographyStyledMetadata = styled(Typography)`
  ${({ theme, backgroundVariant }) => css`
    display: block;
    ${(backgroundVariant === 'darkDefault') && `color: ${theme.colors.neutral.white};`}
  `
}`;

export const TypographyStyledVisualLink = styled(Typography)`
  ${({ theme, backgroundVariant }) => css`
    position: absolute;
    bottom: ${rem(16)};
    padding-bottom: ${rem(4)};
    color: ${(backgroundVariant === 'darkDefault') ? theme.colors.action.darkTheme.linkDefault : theme.colors.action.lightTheme.linkDefault};
  `}
`;

