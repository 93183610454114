import styled, { css, ThemeContext } from 'styled-components';
import { useContext } from 'react';
import rem from 'polished/lib/helpers/rem';

export const ButtonChipStyled = styled.button`
  ${({ theme }) => {
    let chipWidth = '100%';
    let padding = `${rem(10)} ${rem(40)}`;

    if (theme.mediumUp()) {
      chipWidth = 'auto';
      padding = `${rem(10)} ${rem(20)}`;
    }

    return css`
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: ${chipWidth};
      background-color: transparent;
      border: 1px solid ${theme.colors.action.lightTheme.ctaBgDefault};
      border-radius: ${rem(30)};
      color: ${theme.colors.action.lightTheme.ctaBgDefault};
      padding: ${padding};
      :hover,
      :focus {
        cursor: pointer;
        border-color: ${theme.colors.action.lightTheme.ctaBgHover};
        color: ${theme.colors.action.lightTheme.ctaBgHover};
      }
      :active {
        background-color: ${theme.colors.action.lightTheme.ctaBgActive};
        cursor: pointer;
        border-color: ${theme.colors.action.lightTheme.ctaTextDefault};
        color: ${theme.colors.action.lightTheme.ctaTextDefault};
      }
    `;
  }}
`;

/** Creates inline styles (to override stock Component Library styles) */
export const Styles = () => {
  const theme = useContext(ThemeContext);

  let position = 'absolute';
  let left = rem(15);

  if (theme.mediumUp()) {
    position = 'relative';
    left = 0;
  }
  return {
    IconStyled: (hasChildren) => ({
      position,
      maxHeight: rem(25),
      marginRight: hasChildren ? rem(10) : 0,
      left,
      ':hover,:focus': { fill: theme.colors.action.lightTheme.ctaBgHover },
      ':active': { fill: theme.colors.action.lightTheme.ctaTextDefault },
    }),
  };
};
