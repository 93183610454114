// deprecated
import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
// template components
import Picture from '../../../components/Picture';
import Link from '../../../components/Link';
import Typography from '../../../foundations/Typography';
import { Col, Row } from '../../../foundations/Grid';

// Hide for medium view
export const ColCtaContainerStyled = styled(Col)`
  ${({ theme }) => {
    const largeDown = theme.largeDown();
    const mediumUp = theme.mediumUp();
    return css`
      ${(largeDown && mediumUp) && 'display: none;'}
    `;
  }
}`;

export const DivBottomCtaContainerStyled = styled.div`
  margin-top: ${rem(8)};
`;

export const DivFinraContainerStyled = styled.div`
  margin-top: ${rem(24)};
`;

export const DivInfoContainerStyled = styled.div`
  margin-left: ${rem(40)};
`;

export const LinkButtonStyled = styled(Link)`
  ${({ theme }) => {
    const largeUp = theme.largeUp();
    const mediumDown = theme.mediumDown();
    const smallUp = theme.smallUp();
    return css`
    ${(mediumDown || largeUp)
      && `
        max-width: 100%;
        width: 100%;
      `}
      min-width: ${smallUp ? rem(224) : 'unset'}
    `;
  }
}`;

export const PictureStyled = styled(Picture)`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    return css`
      display: block;
      margin-bottom: ${mediumUp ? 0 : rem(40)};
    `;
  }
}`;

export const RowMediumFinraContainerStyled = styled(Row)`
  ${({ theme }) => {
    const largeUp = theme.largeUp();
    const mediumDown = theme.mediumDown();
    return css`
      margin: ${rem(24)} 0;
      ${(largeUp || mediumDown) && 'display: none;'};
    `;
  }
}`;

export const SpanTitleHeadingStyled = styled.span`
  font-size: 85%;
  font-weight: 300;
`;

export const TypographyWithIconStyled = styled(Typography)`
  ${({ icon, config, themeType, preWrap }) => {
    let thisFilter = 'invert(25%) sepia(96%) saturate(7900%) hue-rotate(195deg) brightness(100%) contrast(84%)'; // default lightTheme
    if (themeType === 'darkTheme') {
      thisFilter = 'invert(100%) sepia(0%) saturate(7500%) hue-rotate(321deg) brightness(110%) contrast(101%)';
    }

    return css`
      position: relative;
      ${preWrap && 'white-space: pre-wrap;'}
      ::before {
        position: absolute;
        top: ${rem(4)};
        left: ${rem(-41)};
        display: inline-block;
        width: ${rem(20)};
        height: ${rem(25)};
        padding-left: ${rem(40)};
        background-image: url('${config.basePath}/template/assets/${config.nmxTemplateVersion}/images/icons/utility/${icon}.svg');
        filter: ${thisFilter};
        background-repeat: no-repeat;
        content: ' ';
      }
    `;
  }}
`;
