// deprecated
import React, { Component } from 'react';
import { searchFactory } from '@nmx/utils-frontend/dist/services/SearchService/Frontend';
import { newRelicJSError } from '@nmx/utils-frontend/dist/utilities/frontend/Analytics/new_relic_helper';
// template component
import Link from '../../../../components/Link';

// props: agentnumber - used to fetch the ensemble information
export default class NmEnsemble extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ensemble: {},
      isLoading: false,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    const SearchService = searchFactory({ baseURL: this.props.config.services.searchApiBaseUrl });
    SearchService.getTeams({
      agentNumber: this.props.agentnumber,
      isEnsemble: true,
    }).then((rsp) => {
      const ensembleData = rsp.data;
      if (ensembleData.length === 1) {
        this.setState({ ensemble: ensembleData[0] }, () => {
          this.setState({ isLoading: false });
        });
      } else {
        this.setState({ isLoading: false });
      }
    }).catch((err) => {
      const error = `An error occurred while fetching ensemble. Error: ${err}`;
      newRelicJSError(error);
      console.error(error);
    });
  }

  render() {
    if (!this.state.isLoading
            && this.state.ensemble.name
            && this.state.ensemble.url) {
      return (
        <h4 className="nm-rep-card--ensemble" >
          <Link
            linkUrl={`${this.state.ensemble.url}`}
            isOutgoingLink
            variant='primaryDeemphasize'>
            {this.state.ensemble.name}
          </Link>
        </h4>
      );
    }
    return false;
  }
}
