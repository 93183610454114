import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

// template components
import { Col } from '../../../../foundations/Grid';
import DescriptionList from '../../../../components/DescriptionList';
import Link from '../../../../components/Link';
import List from '../../../../components/List';
import Typography from '../../../../foundations/Typography';

export const ColStyled = styled(Col)`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    return css`
      ${!mediumUp && `margin-bottom: ${rem(32)}`}
    `;
  }}
`;

export const TypographyStyledName = styled(Typography)`
  padding-bottom: ${rem(6)};
`;

export const LinkStyledFinraBrokerCheck = styled(Link)`
  ${({ mediumDownSocialSiteLinks, theme }) => {
    const largeUp = theme.largeUp();
    const mediumUp = theme.mediumUp();
    return css`
      ${!mediumUp
        && `
          margin: 0 auto;
        `}
      }
      ${!largeUp
        && `
          display: block;
          width: max-content;
          padding-bottom: ${mediumDownSocialSiteLinks ? rem(32) : 0};
        `}
    `;
  }}
`;

export const LinkStyledSchedule = styled(Link)`
  ${({ theme }) => {
    const largeUp = theme.largeUp();
    return css`
      min-width: ${largeUp ? rem(200) : 'unset'};
      margin-bottom: ${rem(12)};
    `;
  }
}`;

export const LinkStyledWebsite = styled(Link)`
  ${({ schedulerUrl }) => css`
    display: ${schedulerUrl ? 'block' : 'inline-flex'};
    margin: 0 auto;
  `}
`;

export const ListStyledDesignations = styled(List)`
  margin-bottom: ${rem(16)};
`;

export const DescriptionListStyled = styled(DescriptionList)`
  margin-left: 0;
  padding-left: 0;
`;

export const LIStyledDesignation = styled.li`
  display: inline-block;
`;

export const ColStyledSocial = styled(Col)`
  display: flex;
  padding: 0;
  flex-direction: column;
  align-items: center;
`;

export const TypographyStyledSocialSiteLinksHeading = styled(Typography)`
  ${({ theme }) => {
    const mediumToLarge = theme.mediumToLarge();
    const mediumUp = theme.mediumUp();
    return css`
      && {
        display: ${mediumUp ? 'inline-block' : 'block'};
        padding-bottom: ${rem(12)};
        text-align: center;
        word-wrap: break-word;
        font-size: ${rem(14)};
        line-height: ${rem(20)};
        font-weight: 500;
        letter-spacing: ${rem(1.4)};
        text-transform: uppercase;
      }
      ${mediumToLarge
        && `
          margin-right: ${rem(10)};
        `}
    `;
  }}
`;

export const ListStyledSocialSiteLinks = styled(List)`
  ${({ theme }) => {
    const mediumToLarge = theme.mediumToLarge();
    const mediumUp = theme.mediumUp();
    return css`
      ${!mediumUp && 'text-align: center'};
      ${mediumToLarge
        && `
          display: inline-block;
          vertical-align: middle;
        `}
    `;
  }
}`;

export const LiStyledSocialIcon = styled.li`
  ${({ theme }) => {
    const largeUp = theme.largeUp();
    return css`
      ${!largeUp
        && `
          display: inline-block;
          &:not(:last-child) {
            margin-right: ${rem(12)};
          }
          vertical-align: top;
        `}
      ${largeUp
        && `
          &:not(:last-child) {
            padding-bottom: ${rem(12)};
          }
        `}
    `;
  }
}`;
