import rem from 'polished/lib/helpers/rem';

const columnCount = 12;

const getColStyles = (flex = 1, maxWidth = 'none') => `
    max-width: ${maxWidth};
    flex: ${flex};
    overflow-wrap: break-word;
  `;

const getNestedStyles = () => `
  max-width: none;
  margin: 0 auto;
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;
`;

const getNestedChildStyles = (theme) => {
  const large = theme.largeUp();
  const xlarge = theme.xlargeUp();

  return `
    padding: 0 1rem;
    ${large && `padding: 0 ${rem(20)};`}
    ${xlarge && `padding: 0 ${rem(32)};`}
  `;
};

const generateColOffsetStyles = (offsetSize) => {
  let colSizeStyles = '';

  // loop through each of the column breakpoints
  for (let x = 0; x <= columnCount; x += 1) {
    // calc offset %
    const percent = (x / columnCount) * 100;

    // add margin-left based upon the size of the offset
    colSizeStyles
      += (offsetSize === x
        && `
    margin-left: ${percent}%;
    `)
      || '';
  }

  return colSizeStyles;
};

const generateColSizeStyles = (colSize) => {
  let colSizeStyles = '';

  // for  medium={0} large={0}, etc - display: none; so cols padding is removed
  colSizeStyles
    += (colSize === 0
      && `
  display: none;
  `)
    || '';

  /*
    small={0} medium={10}
    display: block; ensures the col shows on the medium breakpoint
  */
  for (let x = 1; x <= columnCount; x += 1) {
    // calc column width
    const percent = (x / columnCount) * 100;

    // add styles if colSize matches looped col and mediaQuery
    colSizeStyles
      += (colSize === x
        && `
      ${getColStyles(`0 ${percent}%`, `${percent}%`)}
      display: block;
    `)
      || '';
  }

  return colSizeStyles;
};

// gridGutterInPx = total inner gutter between columns divided by 2
// gridOuterGutterInPx = total outer gutter
const breakpointData = {
  xsmall: {
    gridGutterInPx: 4,
    gridOuterGutterInPx: 16,
  },
  small: {
    gridGutterInPx: 4,
    gridOuterGutterInPx: 16,
  },
  medium: {
    gridGutterInPx: 8,
    gridOuterGutterInPx: 20,
  },
  large: {
    gridGutterInPx: 10,
    gridOuterGutterInPx: 24,
  },
  large1200: {
    gridGutterInPx: 10,
    gridOuterGutterInPx: 24,
  },
  xlarge: {
    gridGutterInPx: 10,
    gridOuterGutterInPx: 24,
  },
  xxlarge: {
    gridGutterInPx: 10,
    gridOuterGutterInPx: 24,
  },
};

Object.keys(breakpointData).forEach((bp) => {
  const bpData = breakpointData[bp];
  bpData.outerGutterVal = rem(
    bpData.gridOuterGutterInPx - bpData.gridGutterInPx,
  );
});

export {
  breakpointData,
  getColStyles,
  getNestedStyles,
  getNestedChildStyles,
  generateColOffsetStyles,
  generateColSizeStyles,
};
