import React from 'react';
import PropTypes from 'prop-types';

import FormRowComponentStyled from './styles';

export const FormRowComponent = ({
  children,
  className,
}) => (
  <FormRowComponentStyled className={className}>
    {children}
  </FormRowComponentStyled>
);

FormRowComponent.propTypes = {
  /** Inner Text to be rendered */
  children: PropTypes.node,
  /** Optional custom class name */
  className: PropTypes.string,
};

FormRowComponent.defaultProps = {};

export default FormRowComponent;
