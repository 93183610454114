import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import getGuid from '../../../utils/guid';

import { buttonPropTypes } from '../Button';
import { typographyPropTypes } from '../../foundations/Typography';

// styles
import {
  ButtonStyled,
  SpanStyledCollapsibleContainer,
  TypographyStyled,
} from './styles';
import useClamped from './clampedHook';

export const CollapsibleTypographyComponent = ({
  backgroundVariant,
  branding,
  buttonContentCollapsed,
  buttonContentExpanded,
  buttonProps,
  children,
  clampedLines,
  className,
  themeType,
  typographyIsExpanded,
  typographyProps,
}) => {
  const thisCollapsibleTypographyRef = useRef();
  const { isClamped, isExpanded, toggleExpanded } = useClamped(thisCollapsibleTypographyRef, typographyIsExpanded);

  // if buttonProps.id is not supplied, generate a unique id and assign to buttonProps
  if (!buttonProps) {
    // eslint-disable-next-line no-param-reassign
    buttonProps = {};
  }
  if (!buttonProps.id) {
    Object.assign(buttonProps, { id: getGuid() });
  }

  const checkButtonVisibility = () => {
    if (isExpanded) {
      return true;
    }
    return isClamped;
  };

  return (
    <SpanStyledCollapsibleContainer
      className={className}
      typographyProps={typographyProps}>
      <TypographyStyled
        clampedLines={isExpanded ? 0 : clampedLines}
        disableBottomPadding // should always be set in order for #expand-collapse-typography-button to be properly aligned in container
        isExpanded={isExpanded}
        ref={thisCollapsibleTypographyRef}
        themeType={themeType}
        {...typographyProps}
      >
        {children}
        {checkButtonVisibility()
          && <ButtonStyled
            isExpanded={isExpanded}
            backgroundVariant={backgroundVariant}
            branding={branding}
            {...buttonProps}
            onClick={toggleExpanded}
            themeType={themeType}
            variant='tertiary'>
            {!isExpanded ? buttonContentCollapsed : buttonContentExpanded}
          </ButtonStyled>
        }
      </TypographyStyled>
    </SpanStyledCollapsibleContainer>
  );
};

CollapsibleTypographyComponent.propTypes = {
  /** backgroundVariant allows accordion to be different background color based on themeType */
  backgroundVariant: PropTypes.oneOf(['darkDefault', 'lightA', 'lightB', 'lightC']),
  /** sets overall branding of component or module, default='nm' */
  branding: PropTypes.oneOf(['nm', 'pcg']),
  /** contents of the collapsed button */
  buttonContentCollapsed: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  /** contents of the expanded button */
  buttonContentExpanded: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  /** Optional config file that contains any additional button props */
  buttonProps: PropTypes.shape(buttonPropTypes),
  /** required content that will be expanded/collapsed  */
  children: PropTypes.node.isRequired,
  /** number of desired lines to clamp */
  clampedLines: PropTypes.number.isRequired,
  /** Optional custom class name */
  className: PropTypes.string,
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']),
  /** Optional boolean to open truncated typography without clicking button */
  typographyIsExpanded: PropTypes.bool,
  /** Optional config file that contains any additional Typography props */
  typographyProps: PropTypes.shape(typographyPropTypes),
};

CollapsibleTypographyComponent.defaultProps = {
  backgroundVariant: 'lightA',
  branding: 'nm',
  buttonContentCollapsed: '...Read more',
  buttonContentExpanded: 'Read less',
  clampedLines: 3,
  themeType: 'lightTheme',
  typographyIsExpanded: false,
};

export default CollapsibleTypographyComponent;
