import breakpoints from './breakpoints';
import colors from './colors';
import effects from './effects/index';
import spacing from './spacing';
import useMediaQuery from './useMediaQuery';
import zIndex from './zIndex';

const defaultThemeExport = {
  breakpoints,
  colors,
  spacing,
  effects,
  useMediaQuery,
  zIndex,
};

const getContextBreakpoints = (pageLoadWindowWidth) => ({
  xsmallUp: () => useMediaQuery(breakpoints.up('xsmall'), breakpoints.defaultMatchUp(breakpoints.values.xsmall, pageLoadWindowWidth)),
  smallUp: () => useMediaQuery(breakpoints.up('small'), breakpoints.defaultMatchUp(breakpoints.values.small, pageLoadWindowWidth)),
  mediumUp: () => useMediaQuery(breakpoints.up('medium'), breakpoints.defaultMatchUp(breakpoints.values.medium, pageLoadWindowWidth)),
  largeUp: () => useMediaQuery(breakpoints.up('large'), breakpoints.defaultMatchUp(breakpoints.values.large, pageLoadWindowWidth)),
  large1200Up: () => useMediaQuery(breakpoints.up('large1200'), breakpoints.defaultMatchUp(breakpoints.values.large1200, pageLoadWindowWidth)),
  xlargeUp: () => useMediaQuery(breakpoints.up('xlarge'), breakpoints.defaultMatchUp(breakpoints.values.xlarge, pageLoadWindowWidth)),
  xxlargeUp: () => useMediaQuery(breakpoints.up('xxlarge'), breakpoints.defaultMatchUp(breakpoints.values.xxlarge, pageLoadWindowWidth)),
  // xsmallDown: () => useMediaQuery(breakpoints.down('xsmall'), breakpoints.defaultMatchDown(breakpoints.values.xsmall, pageLoadWindowWidth)), // TODO: should not be used anywhere
  smallDown: () => useMediaQuery(breakpoints.down('small'), breakpoints.defaultMatchDown(breakpoints.values.small, pageLoadWindowWidth)),
  mediumDown: () => useMediaQuery(breakpoints.down('medium'), breakpoints.defaultMatchDown(breakpoints.values.medium, pageLoadWindowWidth)),
  largeDown: () => useMediaQuery(breakpoints.down('large'), breakpoints.defaultMatchDown(breakpoints.values.large, pageLoadWindowWidth)),
  large1200Down: () => useMediaQuery(breakpoints.down('large1200'), breakpoints.defaultMatchDown(breakpoints.values.large1200, pageLoadWindowWidth)),
  xlargeDown: () => useMediaQuery(breakpoints.down('xlarge'), breakpoints.defaultMatchDown(breakpoints.values.xlarge, pageLoadWindowWidth)),
  xxlargeDown: () => useMediaQuery(breakpoints.down('xxlarge'), breakpoints.defaultMatchDown(breakpoints.values.xxlarge, pageLoadWindowWidth)),
  // custom811to1023
  custom768820: () => useMediaQuery(
    breakpoints.between(768, 820),
    breakpoints.defaultMatchBetween(768, 820, pageLoadWindowWidth),
  ),
  custom768810: () => useMediaQuery(
    breakpoints.between(768, 810),
    breakpoints.defaultMatchBetween(768, 810, pageLoadWindowWidth),
  ),
  custom1070up: () => useMediaQuery(breakpoints.up(1070), breakpoints.defaultMatchUp(1070, pageLoadWindowWidth)),
  custom2300Up: () => useMediaQuery(breakpoints.up(2300), breakpoints.defaultMatchUp(2300, pageLoadWindowWidth)),
  mediumToLarge: () => useMediaQuery(
    breakpoints.between('medium', 'large'),
    breakpoints.defaultMatchBetween(breakpoints.values.medium, breakpoints.values.large, pageLoadWindowWidth),
  ),
  mediumToLarge1200: () => useMediaQuery(
    breakpoints.between('medium', 'large1200'),
    breakpoints.defaultMatchBetween(breakpoints.values.medium, breakpoints.values.large1200, pageLoadWindowWidth),
  ),
  smallToMedium: () => useMediaQuery(breakpoints.between('small', 'medium'), breakpoints.defaultMatchBetween(breakpoints.small, breakpoints.medium, pageLoadWindowWidth)),
  smallToLarge: () => useMediaQuery(breakpoints.between('small', 'large'), breakpoints.defaultMatchBetween(breakpoints.small, breakpoints.large, pageLoadWindowWidth)),
});

export const render = (pageLoadWindowWidth, pageLoadWindowHeight) => ({
  ...defaultThemeExport,
  pageLoadWindowWidth,
  pageLoadWindowHeight,
  ...getContextBreakpoints(pageLoadWindowWidth),
});

export default {
  ...defaultThemeExport,
  render,
};
