import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import throttle from 'lodash/throttle';
// template components
import { Row, Col } from '../../foundations/Grid';
// helpers
import appendSwiperScripts from '../../../helpers/appendSwiperScripts';
// styles
import {
  ColStyledSwiperWrapper,
  DivStyledSliderSwiperNavigation,
  DivStyledSliderSwiperNavigationContainer,
  DivStyledSliderSwiperPagination,
  DivStyledSliderSwiperSlide,
  IconStyledLeftSliderSwiperNavigation,
  IconStyledRightSliderSwiperNavigation,
  RowStyledSliderSwiperSlide,
} from './styles';

// keycode declarations - these are candidates for placing these in some sortof global declarations directory?
export const ENTER_KEY_CODE = 13;
export const SPACE_KEY_CODE = 32;

export const initializeSwiperInstance = (sliderConfig, setSliderReadyToRender) => {
  const swiperAutoplay = sliderConfig.autoplay
    ? { autoplay: { delay: sliderConfig.autoplayDelay } }
    : {};
  setTimeout(() => {
    if (typeof Swiper !== 'undefined') {
    // eslint-disable-next-line no-unused-vars, no-undef
      const thisSlider = new Swiper('.swiper-container', {
        slidesPerView: 1,
        centeredSlides: true,
        grabCursor: true,
        ...swiperAutoplay,
        speed: 600,
        loop: false,
        effect: 'creative',
        creativeEffect: { // requires effect set to 'creative'
          prev: {
            translate: ['-100%', 0, 0], // translate x, y, z
            opacity: 0,
          },
          next: {
            translate: ['100%', 0, 0], // translate x, y, z
            opacity: 0,
          },
        },
        keyboard: {
          enabled: true,
          onlyInViewport: false,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          type: 'bullets',
        },
      });
    }
  }, 50);
  setSliderReadyToRender(true);
};

export const handleKeydown = (e, el) => {
  let thisEl = null;
  if (e && e.target) {
    if (e.target.classList.contains('swiper-button-next') || e.target.classList.contains('swiper-button-prev')) {
      thisEl = el;
    }
    if (thisEl && [ENTER_KEY_CODE, SPACE_KEY_CODE].includes(e.keyCode)) {
      e.preventDefault();
      thisEl.click();
    }
  }
};

export const addEventListeners = (sliderReadyToRender, LISTENER_THROTTLE, thisPrevButton, thisNextButton) => {
  if (sliderReadyToRender && typeof document !== 'undefined') {
    // add listeners on Mount
    thisPrevButton.addEventListener(
      'keydown',
      throttle((e) => handleKeydown(e, thisPrevButton), LISTENER_THROTTLE),
    );
    thisNextButton.addEventListener(
      'keydown',
      throttle((e) => handleKeydown(e, thisNextButton), LISTENER_THROTTLE),
    );
    return () => {
      // remove listeners on Unmount
      thisPrevButton.removeEventListener(
        'keydown',
        throttle((e) => handleKeydown(e, thisPrevButton), LISTENER_THROTTLE),
      );
      thisNextButton.removeEventListener(
        'keydown',
        throttle((e) => handleKeydown(e, thisNextButton), LISTENER_THROTTLE),
      );
    };
  }
  // this ensures we return a function when the if statement is false
  return () => {};
};

export const SliderComponent = ({
  disableSwiperScripts,
  sliderConfig,
  sliderContent,
  themeType,
}) => {
  const [hasMultipleSlides, setHasMultipleSlides] = useState(false); // more than one piece of content was passed into the content array
  const [noContentShouldLoad, setNoContentShouldLoad] = useState(false); // module received an empty content array
  const [retrievedSliderConfig, setRetrievedSliderConfig] = useState(false); // module received the slider config
  const [singleSlideContentShouldLoad, setSingleSlideContentShouldLoad] = useState(false); // one piece of content was passed into the content array
  const [sliderReadyToRender, setSliderReadyToRender] = useState(false); // the swiper has been loaded, a new swiper instance was initialized, and we are ready to render
  const [swiperLoaded, setSwiperLoaded] = useState(false); // the swiper script/assets have loaded

  // refs
  const thisNextButtonRef = useRef(null);
  const thisPrevButtonRef = useRef(null);

  // constants
  const LISTENER_THROTTLE = 100;

  useEffect(() => {
    if (sliderContent.length > 1) {
      setHasMultipleSlides(true);
    } else if (sliderContent.length === 1) {
      setSingleSlideContentShouldLoad(true);
    } else {
      setNoContentShouldLoad(true);
    }
  }, []);

  useEffect(() => {
    addEventListeners(sliderReadyToRender, LISTENER_THROTTLE, thisPrevButtonRef.current, thisNextButtonRef.current);
  }, [sliderReadyToRender]);

  useEffect(() => {
    if (hasMultipleSlides && !disableSwiperScripts) {
      appendSwiperScripts(setSwiperLoaded);
    }
  }, [hasMultipleSlides]);

  useEffect(() => {
    // used by storybook
    if (sliderContent && swiperLoaded) {
      setRetrievedSliderConfig(true);
    }
  }, [swiperLoaded]);

  useEffect(() => {
    // Swiper Init
    if (retrievedSliderConfig && swiperLoaded) {
      initializeSwiperInstance(sliderConfig, setSliderReadyToRender);
    }
  }, [retrievedSliderConfig, swiperLoaded]);

  // jsx for multiple slides
  const contentSlides = (
    <Row className='swiper-container'>
      <ColStyledSwiperWrapper className='nmx-slider-items swiper-wrapper'>
        {sliderContent.map((sliderItem, index) => (
          <DivStyledSliderSwiperSlide
            className='swiper-slide'
            key={`${sliderItem}${index + 1}`} // SonarQube barking at react/no-array-index-key error if index used in the key. Adding '+1' resolves this.
          >
            <RowStyledSliderSwiperSlide>
              <Col
                className='nmx-slider-slide nmx-align-center'
                small={10}>
                {sliderItem.content}
              </Col>
            </RowStyledSliderSwiperSlide>
          </DivStyledSliderSwiperSlide>
        ))}
      </ColStyledSwiperWrapper>
      <DivStyledSliderSwiperNavigationContainer
        className='nmx-slider-navigation'
        slideCount={sliderContent.length}>
        <DivStyledSliderSwiperPagination
          className='swiper-pagination'
          themeType={themeType}
        />
        <DivStyledSliderSwiperNavigation
          className='swiper-button-prev'
          ref={thisPrevButtonRef}
        >
          <IconStyledLeftSliderSwiperNavigation $themeType={themeType} />
        </DivStyledSliderSwiperNavigation>
        <DivStyledSliderSwiperNavigation
          className='swiper-button-next'
          ref={thisNextButtonRef}
        >
          <IconStyledRightSliderSwiperNavigation $themeType={themeType} />
        </DivStyledSliderSwiperNavigation>
      </DivStyledSliderSwiperNavigationContainer>
    </Row>
  );

  return (
    <>
      {sliderReadyToRender && (
        <>
          {contentSlides}
        </>
      )}

      {noContentShouldLoad && (
        <></>
      )}

      {singleSlideContentShouldLoad && (
        <>
          {sliderContent[0].content}
        </>
      )}
    </>
  );
};

SliderComponent.propTypes = {
  /** optional boolean to disable loading of swiper scripts */
  disableSwiperScripts: PropTypes.bool,
  /** sliderConfig passes in the slider functionality parameters */
  sliderConfig: PropTypes.object,
  /** required sliderContent array to house all slide data. */
  sliderContent: PropTypes.arrayOf(
    PropTypes.shape({ content: PropTypes.object }),
  ).isRequired,
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme', 'nmx-pcg']).isRequired,
};

SliderComponent.defaultProps = {
  disableSwiperScripts: false,
  sliderConfig: {
    autoplay: false,
    autoplayDelay: 5000,
  },
  themeType: 'lightTheme',
};

export default withTheme(SliderComponent);
