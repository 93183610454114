import React from 'react';
import PropTypes from 'prop-types';

import {
  ImgStyled,
  PictureStyled,
} from './styles';
import breakpoints from '../../foundations/theme/breakpoints';

// Style variation for tertiary picture is TBD

export const PictureComponent = ({
  altText,
  baseImageProps,
  desktopWebpSrc,
  fullWidth,
  id,
  lazyLoad,
  mobileSrc,
  mobileWebpSrc,
  src,
  styles,
  variant,
  ...rest
}) => {
  const mediumSourceMedia = `(max-width: ${breakpoints.values.medium}px)`;
  const imageProps = {};
  const sourceProps = {};
  if (lazyLoad) {
    imageProps['data-src'] = src;
    imageProps.className = 'lazy';
    if (mobileWebpSrc) { sourceProps['data-srcset'] = mobileWebpSrc; }
    if (mobileSrc) { sourceProps['data-srcset'] = mobileSrc; }
    if (desktopWebpSrc) { sourceProps['data-srcset'] = desktopWebpSrc; }
  } else {
    imageProps.src = src;
    if (mobileWebpSrc) { sourceProps.srcSet = mobileWebpSrc; }
    if (mobileSrc) { sourceProps.srcSet = mobileSrc; }
    if (desktopWebpSrc) { sourceProps.srcSet = desktopWebpSrc; }
  }

  return (
  // TODO: Create a class and modifier(s) for nmx-picture in nmx-template. This is currently unsupported.
    <PictureStyled
      id={id}
      {...rest}>
      {mobileWebpSrc && <source
        {...sourceProps}
        type="image/webp"
        media={mediumSourceMedia}
      />}
      {mobileSrc && <source
        {...sourceProps}
        media={mediumSourceMedia}
      />}
      {desktopWebpSrc && <source
        {...sourceProps}
        type="image/webp"
      />}
      <ImgStyled
        alt={altText}
        fullWidth={fullWidth}
        style={styles || {}}
        variant={variant}
        {...baseImageProps}
        {...imageProps}
      />
    </PictureStyled>
  );
};

PictureComponent.propTypes = {
  /** required Alt text for picture description */
  altText: PropTypes.string,
  /** optional prop to allow adding of additional baseImage attributes */
  baseImageProps: PropTypes.object,
  /** desktop image source in WebP format */
  desktopWebpSrc: PropTypes.any,
  /** optional prop to implement full-width styling */
  fullWidth: PropTypes.bool,
  /** optional image id */
  id: PropTypes.string,
  /** lazyload image */
  lazyLoad: PropTypes.bool,
  /** mobile image source in jpg format */
  mobileSrc: PropTypes.any,
  /** mobile image source in WebP format */
  mobileWebpSrc: PropTypes.any,
  /** desktop image source in jpg format */
  src: PropTypes.any.isRequired,
  /** optional image styles */
  styles: PropTypes.object,
  /** required variant for corner styles of image */
  variant: PropTypes.oneOf(['rectangular', 'round']).isRequired,
};

PictureComponent.defaultProps = {
  lazyLoad: true,
  variant: 'rectangular',
};

export default PictureComponent;
