import styled from 'styled-components';

export const DivStyledDonutChart = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DivStyledContentContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
