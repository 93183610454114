import React from 'react';
import PropTypes from 'prop-types';
// data
import CredentialCardContent from './credentialCardContent';
import credentialData from './credentialData';

export const CredentialCardComponent = ({
  accentOrientation,
  accentColorVariant,
  additionalDescription,
  backgroundVariant,
  branding,
  className,
  credential,
  descriptionTextAlign,
  hasAccent,
  headingColorVariant,
  headingTextUpperCase,
  themeType,
  sup1,
  sup2,
}) => {
  const thisCredentialData = credentialData[credential](sup1, sup2, themeType);
  const content = {
    additionalDescription,
    credential,
    descriptionText: thisCredentialData.descriptionText,
    footerText: thisCredentialData.footerText,
    headingText: thisCredentialData.headingText,
    headingTextAriaLabel: thisCredentialData.headingTextAriaLabel,
    headingTextAssistiveSupplement: thisCredentialData.headingTextAssistiveSupplement,
    isCitable: thisCredentialData.isCitable,
    logo: thisCredentialData.credentialLogo,
    sup1,
    sup2,
  };

  return (
    <CredentialCardContent
      content={content}
      accentOrientation={accentOrientation}
      accentColorVariant={accentColorVariant}
      backgroundVariant={backgroundVariant}
      branding={branding}
      className={className}
      descriptionTextAlign={descriptionTextAlign}
      hasAccent={hasAccent}
      headingColorVariant={headingColorVariant}
      headingTextUpperCase={headingTextUpperCase}
      themeType={themeType}
    />
  );
};

CredentialCardComponent.propTypes = {
  /** optional accent color, used in CredentialCardSet */
  accentColorVariant: PropTypes.oneOf(['gold', 'sky']),
  /** optional credential description */
  additionalDescription: PropTypes.any,
  /** optional prop to style credential heading accent, used in CredentialCardSet */
  accentOrientation: PropTypes.oneOf(['horizontal', 'vertical']),
  /** backgroundVariant allows section cards to be different background color based on themeType */
  backgroundVariant: PropTypes.oneOf([
    'darkDefault',
    'lightA',
    'lightB',
    'lightC',
  ]),
  /** sets overall branding of component or module, default='nm' */
  branding: PropTypes.oneOf(['nm', 'pcg']),
  /** Optional custom class name */
  className: PropTypes.string,
  /** Selection List of approved credentials */
  credential: PropTypes.oneOf([
    'amBestCompany',
    'blackFounderAcceleratorProgram',
    'blackImpactInvestingFund',
    'blackStartStartUpCompanyDonations',
    'childhoodCancerDonations',
    'childhoodCancerResearch',
    'clientRetailInvestmentAssets',
    'communityDonations',
    'covidReliefDonations',
    'diDividendPayout',
    'financialStrengthRating',
    'fitchRatings',
    'largestDirectProvider',
    'milwaukeeNeighborhoodInvestments',
    'milwaukeeStudentInvestments',
    'moodysInvestorsService',
    'mostAdmired',
    'mostTrustedFinancialCompaniesForLifeInsurance',
    'mostTrustedInsuranceCompany',
    'numberOfClients',
    'policyownerRetention',
    'rankedDisabilityIncomeInsurance',
    'rankedInsuranceCompany',
    'rankedInvestmentService',
    'sAndPGlobalRatings',
    'totalDividendPayout',
    'yearsOfService',
  ]).isRequired,
  /** optional prop to align descriptionText Typography */
  descriptionTextAlign: PropTypes.oneOf(['inherit', 'left', 'center', 'right']),
  /** optional prop to display accent bar, used in CredentialCardSet */
  hasAccent: PropTypes.bool,
  /** optional prop for heading color */
  headingColorVariant: PropTypes.oneOf(['default', 'sky']),
  /** optional boolean to set uppercase heading text, used in CredentialCardSet */
  headingTextUpperCase: PropTypes.bool,
  /** optional superscript added at precise location within respective credential data */
  sup1: PropTypes.any,
  sup2: PropTypes.any, // used if a specific credential has 2 required footnotes
  /** section theme, which determines color styles */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']),
};

CredentialCardComponent.defaultProps = {
  accentOrientation: 'vertical',
  accentColorVariant: 'gold',
  backgroundVariant: 'lightB',
  branding: 'nm',
  hasAccent: false,
  headingColorVariant: 'default',
  headingTextUpperCase: false,
  themeType: 'lightTheme',
};

export default CredentialCardComponent;
