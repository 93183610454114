import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

export const defaultInputStyles = `
  display: flex;
  width: 100%;
  margin-top: ${rem(8)};
  padding-left: ${rem(12)};
  padding-right: ${rem(12)};
  font-size: ${rem(16)};
  font-weight: 400;
  line-height: ${rem(24)};
  border-radius: ${rem(2)};
  align-items: center;
`;

export const InputStyled = styled.input`
  ${({ disabled, hasError, theme, themeType }) => {
    const mediumUp = theme.mediumUp();

    // Default Styles
    const getDefaultStyles = () => css`
      min-height: ${mediumUp
    ? rem(55)
    : rem(47)}; /** TODO: move into defaultInputStyles */
      padding: ${mediumUp
    ? rem(14)
    : rem(10)}; /** TODO: move into defaultInputStyles */
      ${defaultInputStyles}
    `;

    // Light Theme
    const getLightThemeStyles = () => css`
      border: ${rem(1)} solid ${theme.colors.neutral.gray};
      color: ${theme.colors.forms.lightTheme.inputFieldText};
      &::placeholder {
        color: ${theme.colors.neutral.gray}; /** TODO: confirm with Creative */
        opacity: 1; /** Firefox */
      }
    `;

    // Disabled Light Theme
    const getDisabledLightThemeStyles = () => css`
      border: ${rem(1)} solid ${theme.colors.neutral.gray};
      color: ${theme.colors.text.lightTheme.disabled};
      &::placeholder {
        color: ${theme.colors.neutral.gray}; /** TODO: confirm with Creative */
        opacity: 1; /** Firefox */
      }
    `;

    // Error Light Theme
    const getErrorLightThemeStyles = () => css`
      border: ${rem(1)} solid ${theme.colors.text.lightTheme.error};
      &::placeholder {
        color: ${theme.colors.neutral.gray}; /** TODO: confirm with Creative */
        opacity: 1; /** Firefox */
      }
    `;

    // Dark Theme
    const getDarkThemeStyles = () => css`
      border: ${rem(1)} solid ${theme.colors.forms.darkTheme.inputFieldText};
      background-color: ${theme.colors.forms.darkTheme.inputFieldBg};
      color: ${theme.colors.forms.darkTheme.inputFieldText};
      &::placeholder {
        color: ${theme.colors.forms.darkTheme
    .inputFieldText}; // TODO: confirm with Creative grayLight2?
        opacity: 1; /** Firefox */
      }
    `;

    // Disabled Dark Theme
    const getDisabledDarkThemeStyles = () => css`
      border: ${rem(1)} solid ${theme.colors.text.darkTheme.disabled};
      background-color: ${theme.colors.forms.darkTheme.inputFieldBg};
      color: ${theme.colors.text.darkTheme.disabled};
      &::placeholder {
        color: ${theme.colors.text.darkTheme.disabled};
        opacity: 1; /** Firefox */
      }
    `;

    // Error Dark Theme
    const getErrorDarkThemeStyles = () => css`
      border: ${rem(1)} solid ${theme.colors.text.darkTheme.error};
      background-color: ${theme.colors.forms.darkTheme.inputFieldBg};
      color: ${theme.colors.forms.darkTheme.inputFieldText};
      &::placeholder {
        color: ${theme.colors.forms.darkTheme.inputFieldText};
        opacity: 1; /** Firefox */
      }
    `;

    // PCG Theme
    const getPcgThemeStyles = () => css`
      border: ${rem(1)} solid ${theme.colors.forms.darkTheme.inputFieldText};
      background-color: ${theme.colors.brand.pcg.darkBlue5};
      color: ${theme.colors.forms.darkTheme.inputFieldText};
      &::placeholder {
        color: ${theme.colors.forms.darkTheme.inputFieldText};
        opacity: 1; /** Firefox */
      }
      &:hover {
        background-color: ${theme.colors.brand.pcg.darkBlue4};
      }
      :-webkit-autofill,
      :-webkit-autofill:hover,
      :-webkit-autofill:focus,
      :-webkit-autofill:active,
      :-webkit-autofill::first-line {
        -webkit-box-shadow: 0 0 0 1000px ${theme.colors.brand.pcg.darkBlue5}
          inset;
        -webkit-text-fill-color: ${theme.colors.neutral.white};
      }
    `;

    // Error PCG Theme
    const getErrorPcgStyles = () => css`
      border: ${rem(1)} solid ${theme.colors.text.darkTheme.error};
      background-color: ${theme.colors.brand.pcg.darkBlue5};
      color: ${theme.colors.forms.darkTheme.inputFieldText};
      &::placeholder {
        color: ${theme.colors.forms.darkTheme.inputFieldText};
        opacity: 1; /** Firefox */
      }
    `;

    return css`
      /** Default/Base TextField styles get loaded first every time */
      ${getDefaultStyles()}
      // then, conditionally, color, border, background-color styles get added
      ${themeType === 'lightTheme'
        && getLightThemeStyles()} /** Default = Primary Light Theme */
      ${themeType === 'lightTheme' && disabled && getDisabledLightThemeStyles()}
      ${themeType === 'lightTheme' && hasError && getErrorLightThemeStyles()}
      ${themeType === 'darkTheme' && getDarkThemeStyles()}
      ${themeType === 'darkTheme' && disabled && getDisabledDarkThemeStyles()}
      ${themeType === 'darkTheme' && hasError && getErrorDarkThemeStyles()}
      // TODO build in branding prop
      ${themeType === 'nmx-pcg' && getPcgThemeStyles()}
      ${themeType === 'nmx-pcg' && hasError && getErrorPcgStyles()}
    `;
  }}
`;

export const TextAreaStyled = styled.textarea`
  ${({ hasError, theme, themeType }) => {
    let thisBorderColor = theme.colors.neutral.gray;
    if (hasError) {
      if (themeType === 'lightTheme') {
        thisBorderColor = theme.colors.text.lightTheme.error;
      } else if (themeType === 'darkTheme') {
        thisBorderColor = theme.colors.text.darkTheme.error;
      }
    }
    return css`
      ${defaultInputStyles}
      color: ${
  themeType === 'lightTheme'
    ? theme.colors.forms.lightTheme.inputFieldText
    : theme.colors.forms.darkTheme.inputFieldText
};
      border-color: ${thisBorderColor};
      background-color: ${
  themeType === 'lightTheme'
    ? theme.colors.forms.lightTheme.inputFieldBg
    : theme.colors.forms.darkTheme.inputFieldBg
};
    `;
  }}
`;
