import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import ProfileOutlineCheckmarkWithBorder from '../../../foundations/Icon/icons/illustrative/lightTheme/ProfileOutlineCheckmarkWithBorder';
import Typography from '../../../foundations/Typography';

export const HeadingTextStyled = styled(Typography)`
  ${({ theme, themeType }) => {
    // Default Styles
    const getDefaultStyles = () => css`
      display: 'block';
      margin: 0 auto;
      color: ${themeType === 'lightTheme' ? theme.colors.text.lightTheme.default : theme.colors.text.darkTheme.default}; /** nmx-pcg and darkTheme will inherit the same styles */
    `;

    return css`
      /** Default/Base styles get loaded first every time */
      ${getDefaultStyles()}
    `;
  }}
`;

export const TypographyStyled = styled(Typography)`
  ${({ theme, themeType }) => css`
    color: ${themeType === 'lightTheme' ? theme.colors.text.lightTheme.default : theme.colors.text.darkTheme.default}; /** nmx-pcg and darkTheme will inherit the same styles */
  `}
`;

export const ProfileOutlineCheckmarkWithBorderStyled = styled(ProfileOutlineCheckmarkWithBorder)`
  // $themeType intentionally set as a transient prop to not add "themeType" attribute to SVG DOM element: https://styled-components.com/docs/api#transient-props
  ${({ theme, $themeType }) => css`
    width: ${rem(100)};
    height: ${rem(100)};
    .icon-fill { /** TODO: once we have a dark-them Illustrative  */
      fill: ${$themeType === 'lightTheme' ? theme.colors.text.lightTheme.error : theme.colors.text.darkTheme.error}; /** nmx-pcg and darkTheme will inherit the same styles */
    }
  `}
`;
