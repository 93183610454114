import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import gradients from '../theme/effects/gradients';

export const getSectionTextColor = (component, theme, themeType) => {
  // lightTheme backgroundVariants
  // TODO: instead of pcgLightTheme, let's use branding === 'pcg'
  if (themeType === 'lightTheme') {
    if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(component)) {
      return theme.colors.text.lightTheme.defaultHeading;
    }
    return theme.colors.text.lightTheme.default;
  }
  // TODO: build in branding === 'pcg'
  if (themeType === 'darkTheme') {
    return theme.colors.text.darkTheme.default;
  }

  // Default lightTheme
  return theme.colors.text.lightTheme.default;
};

const headingsLargeSet = ['h1', 'h2'];
const headingsMediumSet = ['h3', 'h4', 'h5', 'h6'];

const getVerticalRhythmBottomPaddingLink = (visualComponent, mediumUp) => {
  if (headingsMediumSet.includes(visualComponent)) {
    return mediumUp ? rem(12) : rem(8);
  }
  if (headingsLargeSet.includes(visualComponent)) {
    return mediumUp ? rem(16) : rem(12);
  }
  return rem(12);
};

const getVerticalRhythmBottomPaddingTypography = (visualComponent, mediumUp) => {
  if (headingsMediumSet.includes(visualComponent)) {
    return mediumUp ? rem(12) : rem(8);
  }
  return mediumUp ? rem(16) : rem(12);
};

export const getVerticalRhythmBottomPadding = (component, disableBottomPadding, mediumUp, nextElement, smallUp, variant) => {
  if (disableBottomPadding) {
    /** If a disableBottomPadding prop was provided, override the default */
    return 0;
  }

  // conditional vars
  const visualComponent = variant || component; // if no variant is selected, fallback to the component being used.

  if (nextElement === 'graphic') {
    return mediumUp ? rem(40) : rem(24);
  }
  if (nextElement === 'button') {
    return rem(24);
  }

  if (nextElement === 'typography') {
    return getVerticalRhythmBottomPaddingTypography(visualComponent, mediumUp);
  }

  if (nextElement === 'link') {
    return getVerticalRhythmBottomPaddingLink(visualComponent, mediumUp);
  }

  return smallUp ? rem(16) : rem(12);
};

export const BaseFont = styled.h1`
  /* If a weight value was provided, override the default */
  ${({ weight }) => weight
    && css`
      && {
        font-weight: ${weight};
      }
    `}
  /* If a align value was provided, override the default */
  ${({ align }) => align
    && css`
      text-align: ${align};
    `}
  /* If a italic value was provided, override the default */
  ${({ italic }) => italic
    && css`
      font-style: italic;
    `}
  /* If a uppercase value was provided, override the default */
  ${({ uppercase }) => uppercase
    && css`
      text-transform: uppercase;
    `}
  /* If a block value was provided, override the default */
  ${({ block }) => block
    && css`
      display: block;
    `}
  /* themeType */
  ${({ component, theme, themeType }) => themeType
    && css`
      color: ${getSectionTextColor(component, theme, themeType)};
    `}
  /** variant */
  ${({ theme, variant }) => {
    /** eyebrow variant */
    if (variant === 'eyebrow') {
      const mediumUp = theme.mediumUp();
      return css`
        && {
          font-size: ${mediumUp ? rem(16) : rem(12)};
          line-height: ${mediumUp ? rem(22) : rem(18)};
          font-weight: 400;
          letter-spacing: ${rem(2.5)};
          text-transform: uppercase;
        }
      `;
    }
    return null;
  }}

  /** branding */
  ${({ accent, align, branding }) => {
    let thisTopMargin = rem(20); // default accent margin // TODO: separate top/bottom margins when accent style requires it
    if (branding === 'pcg') {
      thisTopMargin = rem(30);
    }

    let thisShorthandMargin;
    if (align === 'center') {
      thisShorthandMargin = `${thisTopMargin} auto;`;
    } else if (align === 'left') {
      thisShorthandMargin = `${thisTopMargin} 0;`;
    } else if (align === 'right') {
      thisShorthandMargin = `${thisTopMargin} 0 ${thisTopMargin} auto;`;
    }

    return css`
      ${branding === 'pcg'
        && css`
          font-weight: 300;
          text-transform: uppercase;
          letter-spacing: ${rem(2)};
        `}
      ${accent === 'pcgHeading'
        && css`
          ::after {
            content: '';
            display: block;
            width: ${rem(100)};
            height: ${rem(5)};
            margin: ${thisShorthandMargin};
            ${gradients.pcgGold};
          }
        `}
    `;
  }}

  ${({ component, disableBottomPadding, nextElement, theme, variant }) => css`
    padding-bottom: ${getVerticalRhythmBottomPadding(component, disableBottomPadding, theme.mediumUp, nextElement, theme.smallUp, variant)};
  `};
`;
