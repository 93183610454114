import rem from 'polished/lib/helpers/rem';
import styled, { css } from 'styled-components';
// helpers
import {
  breakpointData,
  getColStyles,
  getNestedStyles,
  getNestedChildStyles,
  generateColOffsetStyles,
  generateColSizeStyles,
} from './helpers';

const getBaseColHorizontalPadding = (
  smallUp,
  mediumUp,
  largeUp,
  xlargeUp,
  xxlargeUp,
) => {
  let thisReturnValue = breakpointData.xsmall.gridGutterInPx; // mobile default (xsmall)

  if (xxlargeUp) {
    thisReturnValue = breakpointData.xxlarge.gridGutterInPx;
  } else if (xlargeUp) {
    thisReturnValue = breakpointData.xlarge.gridGutterInPx;
  } else if (largeUp) {
    thisReturnValue = breakpointData.large.gridGutterInPx;
  } else if (mediumUp) {
    thisReturnValue = breakpointData.medium.gridGutterInPx;
  } else if (smallUp) {
    thisReturnValue = breakpointData.small.gridGutterInPx;
  }

  return thisReturnValue;
};

const getBaseContainerHorizontalPadding = (
  smallUp,
  mediumUp,
  largeUp,
  xlargeUp,
  xxlargeUp,
) => {
  let thisReturnValue = breakpointData.xsmall.outerGutterVal; // mobile default (xsmall)

  if (xxlargeUp) {
    thisReturnValue = breakpointData.xxlarge.outerGutterVal;
  } else if (xlargeUp) {
    thisReturnValue = breakpointData.xlarge.outerGutterVal;
  } else if (largeUp) {
    thisReturnValue = breakpointData.large.outerGutterVal;
  } else if (mediumUp) {
    thisReturnValue = breakpointData.medium.outerGutterVal;
  } else if (smallUp) {
    thisReturnValue = breakpointData.small.outerGutterVal;
  }

  return thisReturnValue;
};

const getBaseColTextAlignment = (align) => {
  let thisReturnVal;
  if (align === 'center') {
    thisReturnVal = 'center';
  } else if (align === 'right') {
    thisReturnVal = 'right';
  } else if (align === 'left') {
    thisReturnVal = 'left';
  }
  return thisReturnVal;
};

export const DivStyledBaseContainer = styled.div`
  ${({ theme }) => {
    const smallUp = theme.smallUp();
    const mediumUp = theme.mediumUp();
    const largeUp = theme.largeUp();
    const xlargeUp = theme.xlargeUp();
    const xxlargeUp = theme.xxlargeUp();

    const thisHorizontalPadding = getBaseContainerHorizontalPadding(
      smallUp,
      mediumUp,
      largeUp,
      xlargeUp,
      xxlargeUp,
    );

    return css`
      width: 100%;
      max-width: ${rem(theme.breakpoints.values.large1200)};
      flex: 0 0 auto; /** ie fix */
      margin: auto;
      padding: 0 ${thisHorizontalPadding};
    `;
  }
}`;

export const DivStyledBaseRow = styled.div`
  ${({ align, reverse }) => css`
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    ${reverse && 'flex-direction: row-reverse;'}
    ${align === 'center' && 'justify-content: center;'}
    ${align === 'left' && 'align-self: flex-start;'}
    ${align === 'right' && 'justify-content: flex-end;'}
  `
}`;

export const DivStyledBaseCol = styled.div`
  ${({
    align,
    large,
    largeOffset,
    medium,
    mediumOffset,
    nested,
    nestedChild,
    paddingLeft,
    paddingRight,
    small,
    smallOffset,
    theme,
    xlarge,
    xlargeOffset,
    xsmall,
    xsmallOffset,
    xxlarge,
    xxlargeOffset,
  }) => {
    const xsmallUp = theme.xsmallUp();
    const smallUp = theme.smallUp();
    const mediumUp = theme.mediumUp();
    const largeUp = theme.largeUp();
    const xlargeUp = theme.xlargeUp();
    const xxlargeUp = theme.xxlargeUp();

    const thisHorizontalPadding = getBaseColHorizontalPadding(
      smallUp,
      mediumUp,
      largeUp,
      xlargeUp,
      xxlargeUp,
    );

    const thisTextAlign = getBaseColTextAlignment(align);

    return css`
      ${getColStyles('0 100%')}
      ${align && `text-align: ${thisTextAlign};`};
      padding: 0 ${rem(thisHorizontalPadding)};

      ${xsmallUp && (generateColSizeStyles(xsmall) + generateColOffsetStyles(xsmallOffset))}
      ${smallUp && (generateColSizeStyles(small) + generateColOffsetStyles(smallOffset))}
      ${mediumUp && (generateColSizeStyles(medium) + generateColOffsetStyles(mediumOffset))}
      ${largeUp && (generateColSizeStyles(large) + generateColOffsetStyles(largeOffset))}
      ${xlargeUp && (generateColSizeStyles(xlarge) + generateColOffsetStyles(xlargeOffset))}
      ${xxlargeUp && (generateColSizeStyles(xxlarge) + generateColOffsetStyles(xxlargeOffset))}

      ${mediumUp
        && css`
          ${paddingLeft
            && css`
              padding-left: ${rem(32)};
            `}

          ${paddingRight
            && css`
              padding-right: ${rem(32)};
            `}
        `}

      ${nested && getNestedStyles(theme)}
      ${nestedChild && getNestedChildStyles(theme)}
    `;
  }
}`;
