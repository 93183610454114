import React from 'react';
import PropTypes from 'prop-types';

import { Container, Row, Col } from '../../../foundations/Grid';

import {
  CircleWithExclamationPointStyled,
  HeadingTextStyled,
  TypographyStyled,
} from './styles';

export const SubmissionErrorModule = ({
  descriptionText,
  headingText,
  themeType,
}) => (
  <Container>
    <Row align="center">
      <Col
        medium={10}
        align='center'>
        <CircleWithExclamationPointStyled $themeType={themeType} />
        <HeadingTextStyled
          component="h3"
          themeType={themeType}
        >
          {headingText}
        </HeadingTextStyled>
        {descriptionText
        && <TypographyStyled
          themeType={themeType}>
          {descriptionText}
        </TypographyStyled>
        }
      </Col>
    </Row>
  </Container>
);

SubmissionErrorModule.propTypes = {
  /** Error Subtext (additional error supporting text) below heading */
  descriptionText: PropTypes.string,
  /** Required Error heading describing main submission error */
  headingText: PropTypes.string.isRequired,
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme', 'nmx-pcg']),
};

SubmissionErrorModule.defaultProps = {
  descriptionText: 'Please try again later.',
  headingText: 'Unable to submit form.',
  themeType: 'lightTheme',
};

export default SubmissionErrorModule;
