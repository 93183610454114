import React from 'react';
import PropTypes from 'prop-types';

// import Dark Theme Illustrations
import BeachChairWithUmbrella from './illustrations/darkTheme/BeachChairWithUmbrella';
import FamilyShieldClouds from './illustrations/darkTheme/FamilyShieldClouds';
import GetMatched from './illustrations/darkTheme/GetMatched';
import PaperMoneyParachute from './illustrations/darkTheme/PaperMoneyParachute';

/**
 * Illustrations paint a mini scene to help bring the message to life and are slightly more robust versions of the icons. Use illustrations when a communication could benefit from a visual story.
 */

const Illustration = ({ id, className, variant, ...otherProps }) => {
  switch (variant) {
  // illustrations Dark Theme
    case 'beach-chair-with-umbrella-dark-theme':
      return <BeachChairWithUmbrella
        className={className}
        {...otherProps} />;
    case 'family-shield-clouds-dark-theme':
      return <FamilyShieldClouds
        className={className}
        {...otherProps} />;
    case 'get-matched-dark-theme':
      return <GetMatched
        className={className}
        {...otherProps} />;
    case 'paper-money-parachute-dark-theme':
      return <PaperMoneyParachute
        className={className}
        {...otherProps} />;
    default:
      return <BeachChairWithUmbrella
        className={className}
        {...otherProps} />;
  }
};

Illustration.propTypes = {
  /** Custom class name to add to Illustration. */
  className: PropTypes.string,
  /** ID attribute for `Illustration`. */
  id: PropTypes.string,
  /** Optional themeType */ // Only used in demo, developers should always load direct path to single/specific Illustration for their needs
  // themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']),
  /**  Determines which Illustration to display */ // Only used in demo, developers should always load direct path to single/specific Illustration for their needs
  variant: PropTypes.oneOf([
    // illustrations
    'beach-chair-with-umbrella-dark-theme',
    'family-shield-clouds-dark-theme',
    'get-matched-dark-theme',
    'paper-money-parachute-dark-theme',
  ]),
};

// Illustration.defaultProps = {
//   className: 'nmx-icon',
//   variant: 'triangle-with-exclamation-point',
// }

export default Illustration;
