import React from 'react';
import PropTypes from 'prop-types';

// utils
import resolveFrPhoto from '../../../assets/js/utils/resolveFrPhoto.util';

// components
import { Agent } from './variants'; // variants of the AdvisorConnect module will live here
import SectionContainer from '../../components/SectionContainer';

export const getStandardizedTitle = (agentObj) => {
  if (agentObj.type === 'MD') {
    return 'Managing Director';
  }
  return agentObj.title;
};

export const transformAgentData = (agentObj) => {
  // add English as default language if none are provided AND/OR add English if it not already in the list
  const thisLanguages = agentObj.languages || [];
  if (!thisLanguages.includes('English')) {
    thisLanguages.unshift('English');
  }

  return ({
    address: {
      building: agentObj.building,
      city: agentObj.city,
      state: agentObj.state,
      street: agentObj.street,
      zip: agentObj.zip,
      zipLastFour: agentObj.zipLastFour,
    },
    areasOfExpertise: agentObj.areasOfExpertise ? agentObj.areasOfExpertise : [],
    bio: agentObj.briefBio,
    designations: agentObj.designations,
    languages: thisLanguages,
    name: {
      firstName: agentObj.firstName,
      fullName: agentObj.fullName,
      lastName: agentObj.lastName,
      lineage: agentObj.lineage,
      middleName: agentObj.middleName,
    },
    phone: agentObj.selectedPhone1 && agentObj.selectedPhone1.Number,
    ...agentObj.photo && agentObj.photo.length !== 0 && { photoUrl: resolveFrPhoto(agentObj) },
    schedulerUrl: agentObj.schedulerLinks && agentObj.schedulerLinks.length > 0 ? agentObj.schedulerLinks[0].url : '', // for right now, will grab the object of type "digitalLeads"
    socialMediaUrls: {
      ...agentObj.socialMedia && agentObj.socialMedia.facebookUrl && { facebook: agentObj.socialMedia.facebookUrl },
      ...agentObj.socialMedia && agentObj.socialMedia.instagramUrl && { instagram: agentObj.socialMedia.instagramUrl },
      ...agentObj.socialMedia && agentObj.socialMedia.linkedinUrl && { linkedIn: agentObj.socialMedia.linkedinUrl }, // NOTE: spelling of "linkedIn Url" var is not camelCase, coming from ms-search
      ...agentObj.socialMedia && agentObj.socialMedia.xUrl && { x: agentObj.socialMedia.xUrl },
    },
    title: getStandardizedTitle(agentObj),
    websiteUrl: `${agentObj.url}/`,
  });
};

export const getAdvisorConnectContent = (agentDisplayVariant, agentObject, backgroundVariant, config, ctaIdPrefix, themeType) => {
  // if agentObject is passed in, it takes precedence over everything
  if (agentObject) {
    return (
      <Agent
        agentDataObject={transformAgentData(agentObject)}
        agentDisplayVariant={agentDisplayVariant}
        backgroundVariant={backgroundVariant}
        config={config}
        ctaIdPrefix={ctaIdPrefix}
        themeType={themeType} />
    );
  }
  // this is where a removeDefaultCardForLeads && defaultCardNeedsToBeRemoved check for leads would go?
  return null;
};

const AdvisorConnectModule = ({
  agentDisplayVariant,
  agentObject,
  backgroundVariant,
  className,
  config,
  ctaIdPrefix,
  sectionId,
  themeType,
}) => (
  <SectionContainer
    backgroundVariant={backgroundVariant}
    className={className}
    id={sectionId}
    moduleName='FR-card-fafa-module' // what's the new module name?
    moduleVariation='A'
    themeType={themeType}>
    {getAdvisorConnectContent(agentDisplayVariant, agentObject, backgroundVariant, config, ctaIdPrefix, themeType)}
  </SectionContainer>
);

AdvisorConnectModule.propTypes = {
  /** optional prop that returns specific visual version the Agent module  */
  agentDisplayVariant: PropTypes.oneOf(['default', 'financialAdvisorMatch']),
  /** optional agentObject that will determine if personalized or default module will be displayed if provided */
  agentObject: PropTypes.shape({
    agentNumber: PropTypes.string,
    areasOfExpertise: PropTypes.arrayOf(PropTypes.string),
    briefBio: PropTypes.string,
    building: PropTypes.string,
    city: PropTypes.string,
    designations: PropTypes.array,
    dnoNum: PropTypes.string,
    email: PropTypes.string,
    facebookUrl: PropTypes.string,
    firstName: PropTypes.string,
    fullName: PropTypes.string,
    gender: PropTypes.string,
    hostname: PropTypes.string,
    id: PropTypes.string,
    instagramUrl: PropTypes.string,
    isActive: PropTypes.bool,
    isPcg: PropTypes.bool,
    languages: PropTypes.arrayOf(PropTypes.string),
    lastName: PropTypes.string,
    latitude: PropTypes.number,
    leid: PropTypes.number,
    lengthOfService: PropTypes.string,
    licenses: PropTypes.arrayOf(PropTypes.shape({
      state: PropTypes.string,
      number: PropTypes.string,
      type: PropTypes.arrayOf(PropTypes.string),
    })),
    lineage: PropTypes.string,
    linkedinUrl: PropTypes.string,
    longitude: PropTypes.number,
    middleName: PropTypes.string,
    nicknamesFirstName: PropTypes.string,
    nmuid: PropTypes.string,
    noNum: PropTypes.string,
    photo: PropTypes.string,
    /** schedulerUrl PropType of string, populated by separate scheduler api call, consuming repo to modify agentObj at that point */
    schedulerUrl: PropTypes.string,
    selectedDesignation1: PropTypes.string,
    selectedDesignation2: PropTypes.string,
    selectedDesignation3: PropTypes.string,
    selectedDesignation4: PropTypes.string,
    selectedDesignation5: PropTypes.string,
    selectedPhone1: PropTypes.object,
    selectedPhone2: PropTypes.object,
    selectedPhone3: PropTypes.object,
    slug: PropTypes.string,
    state: PropTypes.string,
    street: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string, // MP, MD, etc
    url: PropTypes.string,
    websiteUrl: PropTypes.string,
    xUrl: PropTypes.string,
    zip: PropTypes.string,
    zipLastFour: PropTypes.string,
  }).isRequired,
  /** backgroundVariant allows section to be different background color based on themeType */
  backgroundVariant: PropTypes.oneOf(['darkDefault', 'lightA', 'lightB', 'lightC']),
  /** CheckCRC boolean */
  checkCrc: PropTypes.bool,
  /** Optional custom class name */
  className: PropTypes.string,
  /** module config */
  config: PropTypes.shape({
    basePath: PropTypes.string,
    nmxTemplateVersion: PropTypes.string,
  }),
  /** pass in an optional id string that will be prepended to trackable/hookable CTA ids within the module */
  ctaIdPrefix: PropTypes.string,
  /** optional defaultCardProps */
  /** pull defaultCardProps propTypes from advisorConnectCard when ready to pull in defaultCard work */
  /** optional boolean to disable lazy-loading of image */
  disableLazyLoad: PropTypes.bool,
  /** If business needs to hide the default card for specific reasons */
  // removeDefaultCardForLeads: PropTypes.bool,
  /** Id for the section */
  sectionId: PropTypes.string.isRequired,
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']),
};

AdvisorConnectModule.defaultProps = {
  checkCrc: false,
  defaultCardProps: {},
  disableLazyLoad: false,
  // removeDefaultCardForLeads: false,
  themeType: 'lightTheme',
};

export default AdvisorConnectModule;
