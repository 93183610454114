/** Created manually */
import React from 'react';
import classNames from 'classnames';

class Icon extends React.PureComponent {
  render() {
    const { className, svgRef, ...otherProps } = this.props;
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="44"
        fill="none"
        className={classNames('nmx-icon', className)}
        ref={svgRef}
        {...otherProps}><g clipPath="url(#a)"><path
          fill="#F52A01"
          d="M36.369 41.533c.797-1.31 1.383-4.122 2.328-7.503.691-2.46 2.34-5.865 2.94-7.65.3-.886-.756-2.444-1.356-1.762-.475.544-1.953 3.134-2.702 4.87-1.144 2.645-1.564 4.556-1.779 3.988-1.65-4.376-2.795-10.348-10.4-15.805-2.215-1.589-5.677-3.39-8.66-4.436-3.145-1.094-8.102-1.832-8.577-1.78-.531.06-.78.272-.903.591-.078.2 1.604.647 3.579 1.094 2.37.537 4.958 1.203 7.743 2.592 2.693 1.345 5.565 3.346 8.802 7.313 4.203 5.15 4.46 10.463 4.46 10.463s-5.55-4.87-7.111-5.157c-2.292-.431-2.613.169-2.744.777-.06.287 4.171 2.709 7.298 5.623.659.613 1.815 1.944 2.713 3.352.978 1.533 1.688 3.152 2.105 3.812 1.238 1.963 2.005.046 2.274-.392l-.01.01Z"/></g><defs><clipPath id="a"><path
          fill="#fff"
          d="m43.124 21.793-6.932 21.37L.876 31.706l6.932-21.37z"/></clipPath></defs></svg>
    );
  }
}

Icon.displayName = 'HandDrawnArrowIcon';

export default Icon;
