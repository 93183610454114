import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import List from '../../../../components/List';
import Theme from '../../../../foundations/theme';
import Typography from '../../../../foundations/Typography';

import Facebook from '../../../../foundations/Icon/icons/social/Facebook';
import Instagram from '../../../../foundations/Icon/icons/social/Instagram';
import Linkedin from '../../../../foundations/Icon/icons/social/Linkedin';
import Youtube from '../../../../foundations/Icon/icons/social/Youtube';

export const HeadingStyled = styled(Typography)`
  && {
    ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    return css`
      display: none;

      ${mediumUp
        && `
        display: block;
        margin-bottom: ${rem(8)};
      `}
    `;
  }}
}`;

export const ListStyled = styled(List)`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    return css`
      display: flex;
      margin-top: ${rem(24)};
      text-align: center;

      ${!mediumUp
        && `
          justify-content: center;
        `}

      ${mediumUp
        && `
          margin-top: 0;
          text-align: left;
        `}
    `;
  }
}`;

const getIconStyles = () => css`
    .icon-fill {
      fill: ${Theme.colors.neutral.gray};
    }
    &:hover,
    &:focus {
      .icon-fill {
        fill: ${Theme.colors.action.lightTheme.linkDefault};
      }
    }
    &:active {
      .icon-fill {
        fill: ${Theme.colors.action.lightTheme.linkHover};
      }
    }
  `;

const getIconLinkStyles = (theme) => css`
  display: flex;
  width: ${rem(30)};
  height: ${rem(30)};
  align-items: center;
  background-color: ${theme.colors.neutral.gray}; /** default social link state different than default CTA/Link state */
  border-radius: 50%;
  &:hover,
  &:focus {
    background-color: ${theme.colors.action.lightTheme.linkDefault}; /** hover social link state different than default CTA/Link hover state */
  }
  &:active {
    background-color: ${theme.colors.action.lightTheme.linkHover}; /** active social link state different than default CTA/Link active state */
  }
  .nmx-icon {
    width: 100%;
    .icon-fill {
      fill: ${theme.colors.neutral.white};
    }
  }
`;

export const FacebookIconStyled = styled(Facebook)`
  ${getIconStyles()}
`;
export const AStyledX = styled.a`
  ${({ theme }) => css`
    ${getIconLinkStyles(theme)}
  `}
`;
export const LinkedinIconStyled = styled(Linkedin)`
  ${getIconStyles()}
`;
export const InstagramIconStyled = styled(Instagram)`
  ${getIconStyles()}
`;
export const YoutubeIconStyled = styled(Youtube)`
  ${getIconStyles()}
`;

export const ListItemStyled = styled.li`
  margin-right: ${rem(12)};
`;
