import React from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';

// template components
import AssistiveText from '../../../components/AssistiveText';
import { linkPropTypes } from '../../../components/Link';
import Typography from '../../../foundations/Typography';

// styles
import {
  ColStyledLeft,
  ColStyledRight,
  LinkStyled,
  LIStyled,
  RowStyled,
  SectionContainerStyled,
  TypographyStyledListItem,
  ULStyled,
} from './styles';

export const RelatedLinksComponent = ({
  backgroundVariant,
  className,
  disableBottomPadding,
  disableTopPadding,
  linkItems,
  moduleName,
  moduleVariation,
  sectionId,
  themeType,
}) => (
  <SectionContainerStyled
    id={sectionId}
    backgroundVariant={backgroundVariant}
    className={className}
    disableBottomPadding={disableBottomPadding}
    disableTopPadding={disableTopPadding}
    moduleName={moduleName}
    moduleVariation={moduleVariation}
    themeType={themeType}>
    <AssistiveText component='h2'>
      Related links
    </AssistiveText>
    <RowStyled align='center'>
      <ColStyledLeft
        medium={3}
        mediumOffset={2}
        large={12}
        largeOffset={0}>
        <Typography
          disableBottomPadding
          themeType={themeType}
          uppercase
          weight={500}>
          Related links:
        </Typography>
      </ColStyledLeft>
      <ColStyledRight
        medium={7}
        large={12}>
        {linkItems && (
          <ULStyled>
            {
              linkItems.map((link, index) => (
                <LIStyled key={`list-item-${link.linkId}`}> {/** change this to link.id in 4.x.x */}
                  <TypographyStyledListItem
                    component='span'
                    lastItem={index === (linkItems.length - 1)}
                    themeType={themeType}
                    variant='p'>
                    <LinkStyled
                      id={link.linkId} // remove in 4.x.x
                      {...link}
                      themeType={themeType}
                      variant='primaryDeemphasize'>
                      {link.linkText} {/** change this to link.children in 4.x.x */}
                    </LinkStyled>
                  </TypographyStyledListItem>
                </LIStyled>
              ))
            }
          </ULStyled>
        )}
      </ColStyledRight>
    </RowStyled>
  </SectionContainerStyled>
);

RelatedLinksComponent.propTypes = {
  /** backgroundVariant allows section to be different background color based on themeType */
  backgroundVariant: PropTypes.oneOf(['darkDefault', 'lightA', 'lightB', 'lightC']),
  /** Optional custom class name */
  className: PropTypes.string,
  /** Disable the default bottom padding for section */
  disableBottomPadding: PropTypes.bool,
  /** Disable the default top padding for section */
  disableTopPadding: PropTypes.bool,
  /** The object containing the list of links you want to display */
  linkItems: PropTypes.arrayOf(
    PropTypes.shape({
      ...linkPropTypes,
      linkId: PropTypes.string.isRequired, // deprecated
      linkText: PropTypes.oneOfType([ // deprecated
        PropTypes.string,
        PropTypes.object,
      ]).isRequired,
    }),
  ).isRequired,
  /**  Used to populate and define a module on the section for a given page, used in adobe. (Ex: hero, intro-callout, content-hub) */
  moduleName: PropTypes.string,
  /** optional moduleVariation */
  moduleVariation: PropTypes.string,
  /** section id */
  sectionId: PropTypes.string.isRequired,
  /** section theme, which determines color styles */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']),
};

RelatedLinksComponent.defaultProps = {
  backgroundVariant: 'lightA',
  disableBottomPadding: false,
  disableTopPadding: false,
  moduleName: 'related-links',
  moduleVariation: 'A',
  sectionId: 'related-links',
  themeType: 'lightTheme',
};

export default withTheme(RelatedLinksComponent);
