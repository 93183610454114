const appendSwiperScripts = (setSwiperLoaded) => {
  const script = document.createElement('script');

  script.src = 'https://unpkg.com/swiper@10.2.0/swiper-bundle.min.js';
  script.integrity = 'sha384-w/tRw9v+HfTKxfFbKCiOJIAKHSLE4TnkuvDtyjikxwjF/mCWnAfrCZgFKakWUjz6'; // for future upgrades, update integrity sha from here: https://www.srihash.org/
  script.crossOrigin = 'anonymous';
  script.async = true;

  document.body.appendChild(script);

  const link = document.createElement('link');

  link.href = 'https://unpkg.com/swiper@10.2.0/swiper-bundle.min.css';
  link.rel = 'stylesheet';
  link.integrity = 'sha384-GPXcoUAyS15YvSpjGtvjRXByqJxq1AscBXEAvQiTRgSS4wU+eFBD6UCJNOz46Csb'; // for future upgrades, update integrity sha from here: https://www.srihash.org/
  link.crossOrigin = 'anonymous';

  document.body.appendChild(link);

  script.onload = () => {
    setSwiperLoaded(true);
  };
};

export default appendSwiperScripts;
