import React from 'react';
import PropTypes from 'prop-types';

import Label from '../Label';
import HelperTextLabel from '../HelperTextLabel';

import SelectStyled from './styles';

export const SelectComponent = React.forwardRef(
  (
    {
      branding,
      disabled,
      hasError,
      helperText,
      id,
      labelText,
      labelVariant,
      name,
      onChange,
      options,
      required,
      tabIndex,
      themeType,
      value,
      ...rest
    },
    ref,
  ) => (
    {
      ...labelVariant === 'nested'
          && <Label
            disabled={disabled}
            hasError={hasError}
            htmlFor={id}
            inputType="select"
            labelText={labelText}
            ref={ref}
            required={required}
            themeType={themeType}>
            <SelectStyled
              aria-required={required}
              branding={branding}
              disabled={disabled}
              hasError={hasError}
              id={id}
              inputType="select"
              name={name}
              onChange={onChange}
              tabIndex={tabIndex}
              themeType={themeType}
              value={value}
              {...rest}>
              {options.map((option) => (
                <option
                  disabled={option.disabled}
                  key={option.name}
                  value={option.value}>
                  {option.name}
                </option>
              ))}
            </SelectStyled>
            <HelperTextLabel
              disabled={disabled}
              hasError={hasError}
              helperText={helperText}
              themeType={themeType}
            />
          </Label>,
      ...labelVariant === 'sibling'
      && <>
        <Label
          disabled={disabled}
          hasError={hasError}
          htmlFor={id}
          inputType="select"
          labelText={labelText}
          ref={ref}
          required={required}
          themeType={themeType}
        />
        <SelectStyled
          aria-required={required}
          branding={branding}
          disabled={disabled}
          hasError={hasError}
          id={id}
          inputType="select"
          name={name}
          onChange={onChange}
          tabIndex={tabIndex}
          themeType={themeType}
          value={value}
          {...rest}>
          {options.map((option) => (
            <option
              disabled={option.disabled}
              key={option.name}
              value={option.value}>
              {option.name}
            </option>
          ))}
        </SelectStyled>
        <HelperTextLabel
          disabled={disabled}
          hasError={hasError}
          helperText={helperText}
          themeType={themeType}
        />
      </>,
    }
  ),
);

SelectComponent.propTypes = {
  /** sets overall branding of component or module, default='nm' */
  branding: PropTypes.oneOf(['nm', 'pcg']),
  /** Disabled attribute */
  disabled: PropTypes.bool,
  /** Is this input in an error state */
  hasError: PropTypes.bool,
  /** Optional helperText positioned below input field */
  helperText: PropTypes.string,
  /** Required id attribute */
  id: PropTypes.string.isRequired,
  /** The label placed on the input field */
  labelText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  /** default labelVariant is nested, sibling labelVariant separates label and input */
  labelVariant: PropTypes.oneOf(['nested', 'sibling']).isRequired,
  /** Name (attribute) applied to input or textarea */
  name: PropTypes.string.isRequired, // TODO: where is this being used?
  /** Optional function when textField loses focus */
  onBlur: PropTypes.func,
  /** Optional function when textField contents changes */
  onChange: PropTypes.func,
  /** Required array of select options */
  // options: PropTypes.func.isRequired, // TODO: add validation that option value has zero spaces
  options: PropTypes.array.isRequired, // TODO: add selected?
  /** field is required */
  required: PropTypes.bool,
  /** Optional tabIndex assigned to field */
  tabIndex: PropTypes.number,
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']),
  /** Required field value */
  value: PropTypes.any.isRequired,
};

SelectComponent.defaultProps = {
  branding: 'nm',
  disabled: false,
  hasError: false,
  helperText: '',
  labelVariant: 'nested',
  onChange: () => {},
  onBlur: () => {},
  required: false,
  tabIndex: 0,
  value: 'Please make a selection',
};

export default SelectComponent;
