// deprecated
import React from 'react';
import PropTypes from 'prop-types';

import Picture from '../../../../components/Picture';

const basePath = 'https://www.northwesternmutual.com/react-assets/static'; // TODO: confirm this path to S3 assets
const frPictures = {
  default: {
    src: '/fr-2-desktop.jpg',
    mobileSrc: '/fr-2-mobile.jpg',
    mobileWebpSrc: '/fr-2-mobile.webp',
    desktopWebpSrc: '/fr-2-desktop.webp',
  },
  danNguyen: {
    src: '/dan-nguyen-desktop-v2.jpg',
    mobileSrc: '/dan-nguyen-mobile-v2.jpg',
    mobileWebpSrc: '/dan-nguyen-mobile-v2.webp',
    desktopWebpSrc: '/dan-nguyen-desktop-v2.webp',
  },
  holleeKier: {
    src: '/hollee-kier-desktop-v2.jpg',
    mobileSrc: '/hollee-kier-mobile-v2.jpg',
    mobileWebpSrc: '/hollee-kier-mobile-v2.webp',
    desktopWebpSrc: '/hollee-kier-desktop-v2.webp',
  },
  johnWhite: {
    src: '/john-white-desktop-v2.jpg',
    mobileSrc: '/john-white-mobile-v2.jpg',
    mobileWebpSrc: '/john-white-mobile-v2.webp',
    desktopWebpSrc: '/john-white-desktop-v2.webp',
  },
  kellerLindler: {
    src: '/keller-lindler-desktop-v2.jpg',
    mobileSrc: '/keller-lindler-mobile-v2.jpg',
    mobileWebpSrc: '/keller-lindler-mobile-v2.webp',
    desktopWebpSrc: '/keller-lindler-desktop-v2.webp',
  },
  leoTucker: {
    src: '/leo-tucker.jpeg', // TODO: get all of these paths working
    mobileSrc: '/leo-tucker.jpeg',
    mobileWebpSrc: '/leo-tucker.webp',
    desktopWebpSrc: '/leo-tucker.webp',
  },
  trevorSmith: {
    src: '/trevor-smith-desktop-v2.jpg',
    mobileSrc: '/trevor-smith-mobile-v2.jpg',
    mobileWebpSrc: '/trevor-smith-mobile-v2.webp',
    desktopWebpSrc: '/trevor-smith-desktop-v2.webp',
  },
};

const FrPicture = ({
  altText,
  frPicture,
  pictureStyles,
}) => <Picture
  mobileSrc={`${basePath}${frPictures[frPicture].mobileSrc}`}
  mobileWebpSrc={`${basePath}${frPictures[frPicture].mobileWebpSrc}`}
  desktopWebpSrc={`${basePath}${frPictures[frPicture].desktopWebpSrc}`}
  src={`${basePath}${frPictures[frPicture].src}`}
  altText={altText}
  styles={pictureStyles}
  style={{ display: 'block' }} // TODO: combine styles and style
/>;

FrPicture.propTypes = {
  /** alt text for FR picture */
  altText: PropTypes.string,
  /** Select which FR picture to display in default agent card */
  frPicture: PropTypes.oneOf(['default', 'danNguyen', 'holleeKier', 'johnWhite', 'kellerLindler', 'leoTucker', 'trevorSmith']),
  /** additional styles to add to FR Picture */
  pictureStyles: PropTypes.object,
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme', 'nmx-pcg']),
};

FrPicture.defaultProps = {
  altText: 'Northwestern Mutual Financial Representative',
  frPicture: 'default',
};

export default FrPicture;
