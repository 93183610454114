import styled, { css, ThemeContext } from 'styled-components';
import { useContext } from 'react';
import rem from 'polished/lib/helpers/rem';
// components
import { Container } from '../../../foundations/Grid';
import Button from '../../../components/Button';
import Link from '../../../components/Link';
// icons
import DrawerCaret from '../../../foundations/Icon/icons/utility/DrawerCaret';
import DrawerCaretFlipped from '../../../foundations/Icon/icons/utility/DrawerCaretFlipped';

export const ContainerDefault = styled(Container)`
  &&& {
    /** doubles specificity */
    max-width: ${rem(1440)};
    position: relative;
    margin: 0 auto;
    background: transparent;
    box-shadow: none;
  }
`;

/** TODO: move styles into Component */
export const InformationalBanner = styled.div`
  ${({ theme }) => {
    const largeUp = theme.largeUp();
    return css`
      display: flex;
      max-width: ${rem(2000)};
      width: 100%;
      min-height: ${rem(50)};
      margin: 0 auto;
      align-items: center;
      z-index: ${theme.zIndex.informationalBanner};
      position: ${largeUp ? 'relative' : 'fixed'};
    `;
  }
}`;

export const DismissButton = styled.button`
  // TODO: use Dismiss Component
  ${({ theme, drawerIsOpen }) => {
    const largeUp = theme.largeUp();
    const thisHeightWidth = largeUp ? rem(18) : rem(14);
    return css`
      position: ${largeUp ? 'relative' : 'absolute'};
      right: ${largeUp ? rem(24) : rem(16)};
      top: ${largeUp ? 'unset' : 0};
      width: ${thisHeightWidth};
      height: ${thisHeightWidth};
      margin-top: ${largeUp ? 0 : rem(16)};
      padding: 0;
      border: 0;
      background: transparent;
      ::before,
      ::after {
        content: '';
        width: ${rem(2)};
        height: 100%;
        background-color: ${theme.colors.neutral.grayDark20};
        transform: rotate(45deg) translateX(0);
        position: absolute;
        left: 50%;
        top: 0;
      }
      ::before {
        transform: rotate(-45deg) translateX(0);
      }
      :hover::before,
      :hover::after {
        background-color: ${theme.colors.neutral.gray};
      }
      ${drawerIsOpen
        && largeUp
        && css`
          position: absolute;
          top: ${rem(23)};
        `}
    `;
  }}
`;

export const DataTextContainer = styled.p`
  // TODO: convert to Typography
  ${({ theme, variant, activeWindow }) => {
    const largeUp = theme.largeUp();
    const largeDown = theme.largeDown();
    let thisTextAlign; /** TODO: come up with a prop "manager" to handle these better */
    let thisWidth;
    if (variant !== 'cta-button') {
      // TODO: this method of styling is so weird - it actually renders the opposite of what is desired
      thisWidth = '100%';
    } else {
      thisWidth = 'auto';
    }
    if (
      (largeDown && variant === 'text')
      || (largeUp && variant === 'text' && !activeWindow)
      || (largeDown && variant === 'text-link')
      || (largeUp && variant === 'text-link' && !activeWindow)
      || variant === 'cta-button'
      // || /** TODO: revisit cta-button widow */ (largeUp && variant === 'drawer')
      || variant === 'drawer-contents'
    ) {
      thisTextAlign = 'left';
    } else {
      thisTextAlign = 'center';
    }
    return css`
      && {
        display: inline-block;
        width: ${thisWidth};
        margin-left: ${largeUp && variant === 'text' && activeWindow} ? 0 : ${rem(80)};
        ${variant === 'cta-button'
          && css`
            padding-right: ${rem(16)};
          `}
        font-weight: 400; /** TODO: could use a typography weight theme */
        font-size: ${largeDown ? rem(14) : rem(16)};
        line-height: ${largeDown ? rem(21) : rem(24)};
        color: ${variant === 'drawer-contents' ? theme.colors.neutral.grayDark2 : theme.colors.blue.blue};
        text-align: ${thisTextAlign};
        background: transparent;
      }
    `;
  }}
`;

export const StartAdornment = styled.span`
  ${({ theme, variant, activeWindow }) => {
    const largeDown = theme.largeDown();
    const largeUp = theme.largeUp();
    const thisHeightWidth = largeUp ? rem(20) : rem(18);
    let thisPosition; /** TODO: come up with a prop "manager" to handle these better */
    if (variant === 'text' || variant === 'text-link') {
      if (largeDown || (largeUp && !activeWindow)) {
        thisPosition = 'absolute';
      } else {
        thisPosition = 'relative';
      }
    } else if (variant === 'cta-button') {
      thisPosition = 'absolute'; /** force left-align for lengthy cta-button text // TODO: revisit cta-button widow */
    } else if (variant !== 'drawer') {
      thisPosition = largeDown ? 'absolute' : 'relative';
    }

    let thisMarginLeft; /** TODO: come up with a prop "manager" to handle these better */
    if (variant === 'text' || variant === 'text-link' || variant === 'cta-button') {
      if (largeDown || (largeUp && !activeWindow)) {
        thisMarginLeft = rem(16);
      } else {
        thisMarginLeft = 0;
      }
    }

    return css`
      position: ${thisPosition};
      left: 0;
      margin-left: ${thisMarginLeft};
      margin-right: ${largeUp && variant !== 'drawer' ? rem(20) : 0};
      display: inline-block;
      .nmx-icon-start-adornment {
        width: ${thisHeightWidth};
        height: ${thisHeightWidth};
      }
      .icon-fill {
        color: ${theme.colors.action.lightTheme.linkDefault};
      }
    `;
  }}
`;

export const LinkStyled = styled(Link)`
  ${({ theme, linkSection }) => css`
    color: ${theme.colors.action.lightTheme.linkDefault};
    font-size: ${rem(14)};
    line-height: ${rem(21)};
    font-weight: 300;
    ${theme.largeUp()
      && css`
        font-size: ${rem(16)};
        line-height: ${rem(24)};
      `}
    ${linkSection === 'drawer'
      && css`
        display: block;
        margin: ${rem(16)} auto ${rem(10)};
        font-weight: 500;
        color: ${theme.colors.action.lightTheme.linkDefault};
        white-space: nowrap;
        text-align: center;
      `}
  `}
`;

export const DrawerCaretIconStyled = styled(DrawerCaret)`
  margin-left: ${rem(12)};
`;
export const DrawerCaretFlippedIconStyled = styled(DrawerCaretFlipped)`
  margin-left: ${rem(12)};
`;

export const ButtonLinkStyled = styled(Link)`
  ${({ theme, buttonVariant }) => {
    const mediumUp = theme.mediumUp();
    const thisVerticalPadding = mediumUp ? rem(4) : rem(9);
    return css`
      ${buttonVariant === 'cta-button'
        && css`
          && {
            display: ${mediumUp ? 'inline-flex' : 'block'};
            min-width: unset;
            height: ${mediumUp ? rem(36) : rem(40)};
            min-height: unset;
            ${!mediumUp
              && `
                margin: ${rem(8)} auto 0;
            `}
            padding-top: ${thisVerticalPadding};
            padding-bottom: ${thisVerticalPadding};
            white-space: nowrap;
            border-width: ${rem(1)}; /** because this is a short, min-height: 2rem button, the border-width is affected on hover */
            font-weight: 400;
          }
        `}
    `;
  }}
`;

export const ButtonDrawerStyled = styled(Button)`
  &:hover {
    box-shadow: none;
  }
  &:focus {
    background: rgba(255, 255, 255, 0.1);
  }
`;

/** Creates inline styles (to override stock Component Library styles) */
export const Styles = () => {
  const theme = useContext(ThemeContext);
  const large1200Up = theme.large1200Up();
  const largeDown = theme.largeDown();
  const largeUp = theme.largeUp();

  return {
    Container: {
      maxWidth: rem(1440),
      position: 'relative',
      margin: '0 auto',
      background: 'transparent',
    },
    ColInner: (startAdornment, hideDismissButton, variant, activeWindow) => ({
      paddingTop: rem(14),
      paddingRight: rem(40) /** default active dismiss button */,
      paddingBottom: rem(14),
      paddingLeft: rem(16),
      textAlign: 'center',

      /** default large1200Up (matches Header grid (logo, search button, etc)) */
      ...(large1200Up && { paddingLeft: rem(40) }),
      /** default startAdornment (all breakpoints) */
      ...(startAdornment && { paddingLeft: rem(50) }),

      /** text styles */
      ...(variant === 'text' && hideDismissButton && { paddingRight: rem(16) }),
      ...(variant === 'text'
        && startAdornment
        && largeDown && {
        paddingLeft: rem(50),
        textAlign: 'left',
      }),
      ...(variant === 'text'
        && startAdornment
        && largeUp
        && activeWindow && {
        paddingLeft: rem(40),
        textAlign: 'center' /** default left */,
      }),
      ...(variant === 'text'
        && startAdornment
        && largeUp
        && !activeWindow && {
        paddingLeft: rem(50),
        textAlign: 'left',
      }),

      /** text-link styles */
      ...(variant === 'text-link' && hideDismissButton && { paddingRight: rem(16) }),
      ...(variant === 'text-link'
        && startAdornment
        && largeDown && {
        paddingLeft: rem(50),
        textAlign: 'left',
      }),
      ...(variant === 'text-link'
        && startAdornment
        && largeUp
        && activeWindow && {
        paddingLeft: rem(40),
        textAlign: 'center' /** default left */,
      }),
      ...(variant === 'text-link'
        && startAdornment
        && largeUp
        && !activeWindow && {
        paddingLeft: rem(50),
        textAlign: 'left',
      }),

      /** cta-button styles */
      ...(variant === 'cta-button' && /** TODO: repeat for other variants */ largeUp && startAdornment && { paddingLeft: rem(40) }),
      ...(variant === 'cta-button' && largeUp && startAdornment && { paddingLeft: rem(50) }),
      ...(variant === 'cta-button' && largeDown && { paddingBottom: rem(16) }),
      ...(variant === 'cta-button' && largeDown && startAdornment && { paddingBottom: rem(16) }),
      ...(variant === 'cta-button'
        && largeUp && {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center' /** hard-coding always left aligned until this is figure out. */,
      }),

      /** drawer styles */
      ...(variant === 'drawer' && {
        display: 'flex',
        paddingLeft: 0,
        paddingRight: 0,
        alignItems: 'center',
        justifyContent: 'center',
      }),
    }),
    ColDrawer: (startAdornment, hideDismissButton) => ({
      paddingTop: 0,
      paddingRight: rem(16),
      paddingBottom: rem(14),
      paddingLeft: rem(16),
      maxWidth: rem(800),
      margin: '0 auto',
      /** right padding for dismiss button */
      ...(!hideDismissButton && { paddingRight: rem(20) }),
      /** left padding for start adornment mobile */
      ...(startAdornment && !largeUp && { paddingLeft: rem(40) }),
      /** reset left padding for start adornment desktop (styled components bug?) */
      ...(startAdornment && largeUp && { paddingLeft: rem(34) }),
    }),
    CtaButton: (variant, contentHasStartAdornment) => ({
      minWidth: rem(180),
      minHeight: rem(32),
      marginLeft: rem(30),
      marginTop: 0,
      padding: `${rem(6)} ${rem(10)}`,
      fontSize: rem(16),
      lineHeight: rem(24),
      /** mobile styles */
      ...(largeDown && {
        marginLeft: 0,
        marginTop: rem(16),
        minWidth: rem(160) /** TODO: revisit if cta-button inner-text width changes? */,
        minHeight: rem(44),
        fontSize: rem(14),
        lineHeight: rem(20),
      }),
      /** cta-button */
      ...(variant === 'cta-button' && largeDown && { marginRight: rem(8) }),
      ...(variant === 'cta-button'
        && largeDown
        && !contentHasStartAdornment && {
        marginRight: 0,
        marginLeft: rem(24),
      }),
      ...(variant === 'cta-button'
        && largeUp
        && largeDown && {
        marginTop: 0,
        marginRight: rem(32),
        marginLeft: rem(24),
      }),
      ...(variant === 'cta-button'
        && largeUp && {
        marginTop: 0,
        marginRight: rem(32),
        marginLeft: rem(24),
      }),
      /** drawer */
      ...(variant === 'tertiary' && {
        marginLeft: 0,
        marginTop: 0,
        border: 'none' && largeDown && { minHeight: rem(42) },
      }),
      /** drawer mobile */
      ...(variant === 'tertiary'
        && !largeUp && {
        maxWidth: 'unset',
        fontSize: rem(14),
        lineHeight: rem(16),
      }),
    }),
  };
};
