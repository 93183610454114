function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import dataLayerConfig from '../dataLayerConfig';
/**
 * Returns the Adobe Data Layer object, or an Array
 *
 * When no Adobe Data Layer object is found, we set `window.adobeDataLayer` to be an empty Array as opposed to using
 * setTimeout or setInterval and waiting for it to load. When the data layer code eventually loads (we hope),
 * it'll pick up all the array entries before overwriting the prop with itself.
 *
 * This works out well, as most usages of `window.adobeDataLayer` just expect a `push()` function on there.
 * Unfortunately, we also expect a `getState()` function for lead forms, which is why we have a tiny
 * function that just returns 0.'
 *
 * This is because we call `getState` on page load to signify that the form has loaded, and this is a time when it's
 * highly likely the Adobe Data Layer lib hasn't loaded yet. For this "form-load" event, it's ok for it to be 0 as
 * that's what we expect (it's naturally the zeroth/first event for forms). If it happens for later interactions,
 * worst case is we don't know what order the user filled out the different form fields,
 *
 * @return {{getState: function, push: function}|Array}
 */

var getAdobeDataLayer = function getAdobeDataLayer() {
  window.adobeDataLayer = window.adobeDataLayer || [];
  return window.adobeDataLayer;
};
/**
 * Returns data from the Adobe Data Layer state based on property key.
 *
 * Uses a timer to wait for the availability of the `getState()` function on the window.adobeDataLayer object, and tries
 * for a maximum of 5 times, after which the return value is `undefined`. You can use dot notation to get nested
 * properties. For example,
 *
 *    getState('calculator.name')
 *
 * will return 'My Test Calc' if the state object looks like
 *
 *    {
 *      calculator: {
 *        name: 'My Test Calc',
 *        testProp: 12345,
 *      }
 *    }
 *
 * @param {string} stateKey
 * @param {boolean} [shouldWaitForDefined=false]
 *    If true, will wait until the stateKey value is defined
 *
 * @return {*}
 */


var getState = function getState(stateKey) {
  var shouldWaitForDefined = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var dataLayer = getAdobeDataLayer();

  if (typeof dataLayer.getState === 'function') {
    var _stateValue = dataLayer.getState(stateKey);

    if (shouldWaitForDefined && _stateValue !== undefined || !shouldWaitForDefined) {
      return _stateValue;
    }
  }

  var stateValue;
  var attemptCounter = 0;
  var intervalId = setInterval(function () {
    var isStateAvail = shouldWaitForDefined ? typeof dataLayer.getState === 'function' && dataLayer.getState(stateKey) !== undefined : typeof dataLayer.getState === 'function';

    if (attemptCounter > 5 || isStateAvail) {
      stateValue = isStateAvail ? dataLayer.getState(stateKey) : undefined;
      clearInterval(intervalId);
    } else {
      attemptCounter++;
    }
  }, 150);
  return stateValue;
};
/**
 * Returns the initial load path
 *
 * This is different from just calling `window.location.pathname` as this value remains static for the duration of a
 * session and is loaded only once. When the URL of the application changes this remains the same.
 *
 *  eg. /life-insurance navigates to /life-insurance/questions but this function will always return "/life-insurance"
 *
 *  This difference is particularly important for when we use this to set the `name` field
 */


var getPageLoadPath = function getPageLoadPath() {
  var _getState;

  return (_getState = getState('page.load-path', true)) !== null && _getState !== void 0 ? _getState : window.location.pathname;
};
/**
 * Push an event to the Adobe Data Layer
 * ---
 * This is an abstraction for a simple call like (data is just for example):
 *
 *    window.adobeDataLayer.push({
 *      "event": "form-interact",
 *      "form": {
 *        "name" : "<Form Name>",
 *        "experience": "<campaign Or office-site OR quiz or calculator or fafa or other as needed>",
 *        "form-type": "<type of Form>",
 *        "incrementor": "<previous value +1>" ,//should be 1 for the first interaction
 *        "action-type" : "<action type value>",
 *        "action-keys" : "<key of value>",
 *        "action-values" : "<value Selected>",
 *      }
 *    })
 *
 * Both the "form" property and its object value change based on where we're pushing from. For example, if we were to
 * push from a quiz, "form" would instead be "quiz". This value is not arbitrary, and needs to match what the
 * analytics team expects. For us, we just refer to the "form" prop here as an "event type"
 *
 * Additionally, the schema of the event type's value also changes. So a "form" has a completely different schema for
 * its value than say, a quiz. This schema is also not arbitrary, and should match what the analytics team expects
 *
 * @param eventName
 * @param eventData
 *
 * @return void
 */


var push = function push(eventName, eventData) {
  var adobeDataLayer = getAdobeDataLayer();

  var pushPayload = _objectSpread({
    event: eventName
  }, eventData);

  if (dataLayerConfig.shouldDebug) {
    console.debug("Pushing event: '".concat(eventName, "' to data layer"), eventData);
  }

  adobeDataLayer.push(pushPayload);
};

export { getAdobeDataLayer, getPageLoadPath, getState, push };