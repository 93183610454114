import styled, { css } from 'styled-components';

export const BaseUnorderedList = styled.ul`
  ${({ reduced, inline }) => css`
      ${reduced && `
        padding: 0;
        margin: 0;
        list-style: none;
      `}

      ${inline && `
        > li {
          display: inline-block;
        }     
      `}
    `}
`;

export const BaseOrderedList = styled.ol`
  ${({ inline, orderedListType, reduced }) => {
    let thisOrderedListType;
    if (orderedListType === 'decimal') {
      thisOrderedListType = 'decimal';
    } else if (orderedListType === 'lowerAlpha') {
      thisOrderedListType = 'lower-alpha';
    } else if (orderedListType === 'lowerRoman') {
      thisOrderedListType = 'lower-roman';
    } else if (orderedListType === 'upperAlpha') {
      thisOrderedListType = 'upper-alpha';
    } else if (orderedListType === 'upperRoman') {
      thisOrderedListType = 'upper-roman';
    }
    return css`
      list-style-type: ${thisOrderedListType};
      ${reduced && `
        padding: 0;
        margin: 0;
        list-style: none;
      `}

      ${inline && `
        > li {
          display: inline-block;
        }     
      `}
    `;
  }}
`;
