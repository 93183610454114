import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
// template components
import Button from '../../components/Button';
import { Container, Row } from '../../foundations/Grid';
import SectionContainer from '../../components/SectionContainer';
import Typography from '../../foundations/Typography';

// styled components
export const ContainerGoalViewStyled = styled(Container)`
  ${({ variant }) => {
    let hero = false;
    if (variant === 'hero') {
      hero = true;
    }
    return css`
      ${hero && 'padding: 0'};
    `;
  }}
`;

export const RowGoalCardsStyled = styled(Row)`
  ${({ theme }) => {
    let marginBottom = rem(20);
    const mediumUp = theme.mediumUp();

    if (mediumUp) {
      marginBottom = 0;
    }

    return css`
      margin-bottom: ${marginBottom};
    `;
  }}
`;

export const SectionContainerStyled = styled(SectionContainer)`
  width: 100%;
  position: relative;
  z-index: 2;
  && {
    padding: 0;
  }
  max-width: unset;
  overflow: hidden;
  && {
    > .nmx-container {
      max-width: unset;
      padding: 0;
    }
  }
  @media print {
    display: none !important;
  }
`;

// returns string name of the active view.
const getCurrentView = (views) => {
  const obj = Object.entries(views);
  for (let i = 0; i < obj.length; i += 1) {
    if (obj[i][1]) {
      return obj[i][0];
    }
  }
  return false;
};

// GOTCHA: The view container height is going to have to be handled manually
// for each view, since this container will not expand with its
// position absolute children.
export const DivViewContainerStyled = styled.div`
  ${({ theme, views, variant, displayWealthRange, errors }) => {
    let height;
    const activeView = getCurrentView(views);
    let transitionActive = true;
    const mediumUp = theme.mediumUp();
    const largeUp = theme.largeUp();

    if (variant === 'hero') {
      // HERO VARIANT ///////////////////////////////////
      let largeUpHeroHeight;
      let mediumUpHeroHeight;
      let xsmallHeroHeight;
      // HERO XSMALL VIEW SETTINGS
      if (activeView === 'isActiveViewStep1') {
        xsmallHeroHeight = rem(960); // hero mobile, goals view height
      } else if (activeView === 'isActiveViewStep2') {
        let heightIncrement = 555; // default view height for mobile step 2.
        // If wealth range input is displayed, or we have error messages, increase the screen height.
        if (displayWealthRange) {
          heightIncrement += 100;
        }
        if (errors.age) {
          heightIncrement += 30;
        }
        if (errors.zip) {
          heightIncrement += 30;
        }
        if (errors.incomeRange) {
          heightIncrement += 30;
        }
        if (errors.wealthRange) {
          heightIncrement += 30;
        }
        xsmallHeroHeight = rem(heightIncrement); // hero mobile, step 2 view height
      } else if (activeView === 'isActiveViewStep3') {
        let heightIncrement = 555; // default view height for mobile step 3.

        if (errors.firstName) {
          heightIncrement += 30;
        }
        if (errors.lastName) {
          heightIncrement += 30;
        }
        xsmallHeroHeight = rem(heightIncrement); // hero mobile, step 3 view height
      } else if (activeView === 'isActiveViewStep4') {
        const heightIncrement = 555; // default view height for mobile step 4.
        xsmallHeroHeight = rem(heightIncrement); // hero mobile, step 3 view height
      } else if (activeView === 'isActiveViewThankYou' || activeView === 'isActiveViewError') {
        xsmallHeroHeight = rem(300); // hero mobile, thank you & error views height
        transitionActive = false; // Will deactivate height animation for all device widths once we hit the thank you screen.
      } else if (activeView === 'isActiveViewNoMatch') {
        xsmallHeroHeight = rem(570); // hero mobile, no match view height
        transitionActive = false; // Will deactivate height animation for all device widths once we hit the no match screen.
      } else {
        xsmallHeroHeight = rem(555);
      }

      // HERO MEDIUM VIEW SETTINGS
      if (activeView === 'isActiveViewStep1') {
        mediumUpHeroHeight = rem(710); // hero tablet, goals view height
      } else if (activeView === 'isActiveViewStep2') {
        let heightIncrement = 365; // default view height for mobile step 2.
        // If wealth range input is displayed, or we have error messages, increase the screen height.
        if (displayWealthRange) {
          heightIncrement += 100;
        }
        if (
          displayWealthRange
          && (errors.age || errors.zip || errors.incomeRange)
        ) {
          heightIncrement += 30;
        }
        if (errors.wealthRange) {
          heightIncrement += 30;
        }
        mediumUpHeroHeight = rem(heightIncrement); // hero tablet, step 2 view height
      } else if (activeView === 'isActiveViewStep3' || activeView === 'isActiveViewStep4') {
        mediumUpHeroHeight = rem(365); // hero tablet, step 3 & step 4 view height
      } else if (activeView === 'isActiveViewThankYou' || activeView === 'isActiveViewError') {
        mediumUpHeroHeight = rem(280); // hero tablet, thank you & error views height
      } else if (activeView === 'isActiveViewNoMatch') {
        mediumUpHeroHeight = rem(520); // hero mobile, no match view height
      } else {
        mediumUpHeroHeight = rem(365); // hero tablet, standard view height
      }

      // HERO LARGE VIEW SETTINGS
      if (activeView === 'isActiveViewStep1') {
        largeUpHeroHeight = rem(600); // hero desktop, goals cards view height
      } else if (activeView === 'isActiveViewThankYou') {
        largeUpHeroHeight = rem(300); // hero desktop, thank you view height
      } else if (activeView === 'isActiveViewNoMatch') {
        largeUpHeroHeight = rem(500); // hero desktop, no match view height
      } else {
        largeUpHeroHeight = rem(370); // hero desktop, all other views heights
      }

      // media queries
      if (largeUp) {
        height = largeUpHeroHeight; // hero desktop
      } else if (mediumUp) {
        height = mediumUpHeroHeight; // hero tablet
      } else {
        height = xsmallHeroHeight; // hero mobile
      }
    } else {
      // EMBEDDED AND DEFAULT VARIANT ///////////////////////////////////
      // view container height needs to be set manually for each step, since views are position absolute.
      let xsmallHeight = rem(700); // default mobile height for most views (default embedded variant)
      let mediumUpHeight = rem(410);
      let largeUpHeight = rem(295);

      if (activeView === 'isActiveViewStep1') {
        xsmallHeight = rem(380);
      } else if (activeView === 'isActiveViewNoMatch') {
        xsmallHeight = rem(580);
        mediumUpHeight = rem(550);
        largeUpHeight = rem(520);
      }
      // media queries
      if (largeUp) {
        height = largeUpHeight;
      } else if (mediumUp) {
        height = mediumUpHeight;
      } else {
        height = xsmallHeight;
      }
    }

    return css`
      position: relative;
      width: 100%;
      height: ${height};
      transition: height 0.4s;
      ${!transitionActive && 'transition-property: none;'}
    }`;
  }}
`;

// Dynamic, cross-fading views that contain the different steps
export const DivDynamicViewStyled = styled.div`
  ${({ theme, isActive, isAccessible, centered, themeType }) => {
    let visibility = 'hidden';
    let zIndex = -1;
    let opacity = 0;
    const pcgOffset = 10; // px
    let topPadding = 48; // px
    const mediumUp = theme.mediumUp();

    // this state gets a 1.5 s delay before rerendering
    if (isAccessible) {
      visibility = 'visible';
      zIndex = 0;
    }

    // this state rerenders immediately
    if (isActive) {
      opacity = 1;
    }

    if (mediumUp) {
      topPadding = 60; // px
    }

    // has 10px extra top padding for PCG variant, globally.
    if (themeType === 'nmx-pcg') {
      topPadding += pcgOffset;
    }

    // isSubmitting, thankYou, and error views are vertically centered.
    return css`
      position: absolute;
      display: ${centered ? 'flex' : 'block'};
      visibility: ${visibility};
      z-index: ${zIndex};
      width: 100%;
      height: 100%;
      transition: opacity 0.75s;
      opacity: ${opacity};
      padding-top: ${centered ? 0 : rem(topPadding)};
    `;
  }}
`;

export const DivNextButtonContainerStyled = styled.div`
  ${({ theme, variant }) => {
    const hero = variant === 'hero';
    let marginTop = 0;
    let width = '100%';
    const mediumUp = theme.mediumUp();

    // For HERO variant ///////////////////
    if (hero) {
      return css`
        position: absolute;
        margin-top: ${marginTop};
        width: ${width};
        display: flex;
        justify-content: center;
        bottom: ${rem(50)};
      `;
    }
    // For embedded variant (default) ///////////////////
    if (mediumUp) {
      marginTop = rem(24);
      width = 'auto';
    }

    return css`
      margin-top: ${marginTop};
      width: ${width};
    `;
  }}
`;

export const ButtonNextStyled = styled(Button)`
  ${({ theme, formvariant }) => {
    const embedded = formvariant === 'embedded';
    const mediumUp = theme.mediumUp();
    return css`
      display: block;
      ${!mediumUp && 'max-width: unset;'};
      ${embedded && 'width: 100%;'};
    `;
  }}
`;

// Goal dropdown select
export const DivInputColStyled = styled.div`
  /* TODO: would be nice to use our Col Component here regardless of overriding padding */
  ${({ theme, inputWidth }) => {
    let width = '100%';
    let paddingRight;
    let paddingBottom = 0;
    const largeUp = theme.largeUp();
    const mediumUp = theme.mediumUp();

    if (largeUp) {
      paddingRight = rem(12);
      width = rem(inputWidth);
    } else if (mediumUp) {
      paddingRight = rem(15);
      paddingBottom = rem(20);
      width = rem(inputWidth);
    } else {
      paddingRight = 0;
      paddingBottom = rem(20);
    }

    return css`
      position: relative;
      width: ${width};
      padding-right: ${paddingRight};
      padding-bottom: ${paddingBottom};
    `;
  }}
`;

export const DivProgressBarContainerStyled = styled.div`
  ${({ isActive }) => css`
    opacity: ${isActive ? '1' : '0'};
  `}
`;

export const ButtonBackStyled = styled(Button)`
  ${({ branding, theme, themeType }) => {
    const thisTop = 0;
    let thisLeft = 5;
    let thisMinWidth = 'unset';
    const mediumUp = theme.mediumUp();
    const largeUp = theme.largeUp();
    const xlargeUp = theme.xlargeUp();
    const xlargeDown = theme.xlargeDown();
    let thisTextColor = theme.colors.blue.blue;

    if (largeUp) {
      thisMinWidth = rem(120);
    } else if (mediumUp) {
      thisMinWidth = rem(80);
    }

    if (xlargeUp) {
      thisLeft = -12;
    } else if (largeUp) {
      thisLeft = -17;
    } else if (mediumUp) {
      thisLeft = 20;
    }

    if (branding === 'pcg' || themeType === 'darkTheme') {
      thisTextColor = theme.colors.neutral.white;
    }

    return css`
      && {
        position: absolute;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        min-width: ${thisMinWidth};
        left: ${rem(thisLeft)};
        top: ${rem(thisTop)};
        z-index: 1;
        background: transparent;
        color: ${thisTextColor};
        ${xlargeDown && 'padding-left:0;'}
        .nmx-icon {
          transition: margin-right 0.3s;
        }
        &:hover {
          .nmx-icon {
            margin-right: ${rem(13)};
          }
        }
      }
    `;
  }}
`;

export const TypographyBackButtonStyled = styled(Typography)`
  padding: 0;
  font-weight: 400;
`;

export const DivGoalCardsColStyled = styled.div`
  ${({ theme }) => {
    let colWidth = '50%';
    let marginBottom = 0;
    let padding = rem(5);
    const mediumUp = theme.mediumUp();
    const largeUp = theme.largeUp();

    if (largeUp) {
      colWidth = '20%';
      marginBottom = rem(2);
      padding = rem(8);
    } else if (mediumUp) {
      colWidth = `${100 / 3}%`;
      padding = rem(10);
    }

    return css`
      display: flex;
      width: ${colWidth};
      margin-bottom: ${marginBottom};
      padding: ${padding};
    `;
  }}
`;
