import React from 'react';
import PropTypes from 'prop-types';

import Select from '../../../../components/forms/Select';
import { incomes } from '../../utilities/inPageLeadFormData';

export const IncomeSelectInputComponent = React.forwardRef(({
  hasError,
  helperText,
  onBlur,
  onChange,
  themeType,
  value,
}, ref) => (
  <Select
    hasError={hasError}
    helperText={helperText}
    id='in-page-lead-form-income-range-select'
    labelText='Annual household income'
    name='incomeRange'
    onBlur={onBlur}
    onChange={onChange}
    options={incomes}
    ref={ref}
    required={true}
    themeType={themeType}
    value={value} />
));

IncomeSelectInputComponent.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default IncomeSelectInputComponent;
