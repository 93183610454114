// deprecated
import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from '../../../foundations/Grid';
import Link from '../../../components/Link';
import Typography from '../../../foundations/Typography';
import { PHONE_MEDIA_QUERY } from '../../../../assets/js/constants/media_query.constants';
import sanitizePhone from '../../../../assets/js/helpers/sanitizePhone';
import CaratRightIcon from '../../../foundations/Icon/icons/utility/CaratRight';

const EnsembleCard = ({ ensembleObject, themeType }) => {
  const ensemble = ensembleObject;

  return (
    <Row align="center">
      <Col
        xsmall={12}
        small={4}
        medium={4}
        nestedChild>
        <Row align="center">
          <Col
            xsmall={12}
            small={12}
            medium={12}
            nested>
            {/* TODO: this can be a Picture Component? */}
            <picture className='nm-ensemble-card--photo'>
              <source
                srcSet={ensemble.imageUrl || '/life-and-money/assets/images/ensemble-default-mobile.jpg'}
                media={PHONE_MEDIA_QUERY}
              />
              <source
                srcSet={ensemble.imageUrl || '/life-and-money/assets/images/ensemble-default.jpg'}
              />
              <img
                src={ensemble.imageUrl || '/life-and-money/assets/images/ensemble-default.jpg'}
                alt={ensemble.name} />
            </picture>
          </Col>
        </Row>
      </Col>
      <Col
        xsmall={12}
        small={8}
        medium={8}
        nestedChild>
        <Row>
          <Col
            xsmall={12}
            small={12}
            medium={6}
            styles={{ marginBottom: '1rem' }}
            nested>
            {/* TODO: this needs major rework - use Components */}
            <div
              className='nm-rep-card--title'
              style={{ marginBottom: '1rem' }}>
              <h3 className='nm-rep-card--name'>{ensemble.name}</h3>
            </div>
            <div className='nm-rep-card--additional-info'>
              <Link
                linkUrl='https://brokercheck.finra.org/'
                isOutgoingLink={true}>FINRA BrokerCheck</Link>
            </div>
          </Col>
          <Col
            xsmall={12}
            small={12}
            medium={6}
            nested>
            {/* TODO: this needs major rework */}
            <Typography
              component='address'
              variant='p'
              styles={{ whiteSpace: 'pre-wrap' }}
              themeType={themeType}>
              {ensemble.street}. {ensemble.city}, {ensemble.state} {ensemble.zip}
            </Typography>
            {ensemble.primaryPhone && <Link linkUrl={`tel:${sanitizePhone(ensemble.primaryPhone)}`}>{ensemble.primaryPhone}</Link>}
            {ensemble.email && (
              <Row>
                {/* TODO: this Link should be nested in a Col */}
                <Link
                  style={{ marginTop: '1rem' }}
                  variant='buttonLinkPrimary'
                  linkUrl={`mailto:${ensemble.email}`}
                  endAdornment={CaratRightIcon}
                  endAdornmentStylesReset={true}
                  themeType={themeType}>Contact</Link>
              </Row>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

EnsembleCard.propTypes = {
  ensembleObject: PropTypes.shape({
    imageUrl: PropTypes.string,
    name: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    primaryPhone: PropTypes.string,
    email: PropTypes.string,
  }),
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme', 'nmx-pcg']),
};

export default EnsembleCard;
