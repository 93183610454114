import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

export const defaultLabelStyles = `
  position: relative;
  display: inline-block; /** Fix for firefox */
  font-size: ${rem(14)};
  font-weight: 400;
  letter-spacing: ${rem(0.67)};
  line-height: ${rem(16)};
`;

export const LabelStyled = styled.label`
  ${({ branding, disabled, hasError, inputType, theme, themeType }) => {
    const mediumUp = theme.mediumUp();

    // Default Styles
    const getDefaultStyles = () => css`
      width: 100%;
      ${defaultLabelStyles}
    `;

    // Light Theme
    const getLightThemeStyles = () => css`
      color: ${theme.colors.forms.lightTheme.inputFieldText};
    `;

    // Disabled Light Theme
    const getDisabledLightThemeStyles = () => css`
      color: ${theme.colors.text.lightTheme.disabled};
    `;

    // pseudo select dropdowns on textField Label, add the custom chevron arrow
    // TODO: consolidate select theme styles
    const getSelectLightThemeStyles = () => css`
      color: ${theme.colors.forms.lightTheme.inputFieldText};
      font-weight: 400;
      ::after { /** TODO: Pass in create optional prop "selectWidth" in Select Component to size Select and then pass into Label for the pseudo dropdown arrow element here, then, will need to add margin: 0 auto;  */
        content: '\\25c4'; /** Keep the escaping double backslash, or this unicode char will break */ // https://www.htmlsymbols.xyz/unicode/U+25C4
        position: absolute;
        top: ${mediumUp ? rem(57) : rem(52)};
        right: ${rem(34)}; /** removing max-width: 325, change to right: 60 */
        width: 0;
        height: 0;
        padding: 0 0 ${rem(2)};
        color: ${hasError ? theme.colors.forms.lightTheme.inputFieldError : theme.colors.forms.lightTheme.inputFieldText};
        transform: rotate(270deg) scale(0.7, 1.9);
        pointer-events: none;
      }
    `;

    // pseudo select dropdowns on textField Label, add the custom chevron arrow
    // TODO: consolidate select theme styles
    const getDisabledSelectLightThemeStyles = () => css`
      color: ${theme.colors.text.lightTheme.disabled};
      font-weight: 400;
      ::after {
        content: '\\25c4'; /** Keep the escaping double backslash, or this unicode char will break */ // https://www.htmlsymbols.xyz/unicode/U+25C4
        position: absolute;
        top: ${mediumUp ? rem(57) : rem(52)};
        right: ${rem(34)};
        width: 0;
        height: 0;
        padding: 0 0 ${rem(2)};
        color: ${theme.colors.text.lightTheme.disabled};
        transform: rotate(270deg) scale(0.7, 1.9);
        pointer-events: none;
      }
    `;

    // Dark Theme
    const getDarkThemeStyles = () => css`
      color: ${theme.colors.forms.darkTheme.inputFieldText};
    `;

    // Disabled Dark Theme
    const getDisabledDarkThemeStyles = () => css`
      color: ${theme.colors.text.darkTheme.disabled};
    `;

    // pseudo select dropdowns on textField Label, add the custom chevron arrow
    const getSelectDarkThemeStyles = () => css`
      ${getDarkThemeStyles()};
      font-weight: 400;
      ::after {
        content: '\\25c4'; /** Keep the escaping double backslash, or this unicode char will break */ // https://www.htmlsymbols.xyz/unicode/U+25C4
        position: absolute;
        top: ${mediumUp ? rem(57) : rem(52)};
        right: ${rem(34)};
        width: 0;
        height: 0;
        padding: 0 0 ${rem(2)};
        color: ${hasError ? theme.colors.forms.darkTheme.inputFieldError : theme.colors.forms.darkTheme.inputFieldText};
        transform: rotate(270deg) scale(0.7, 1.9);
        pointer-events: none;
      }
    `;

    // pseudo select dropdowns on textField Label, add the custom chevron arrow
    const getDisabledSelectDarkThemeStyles = () => css`
      color: ${theme.colors.text.darkTheme.disabled};
      font-weight: 400;
      ::after {
        content: '\\25c4'; /** Keep the escaping double backslash, or this unicode char will break */ // https://www.htmlsymbols.xyz/unicode/U+25C4
        position: absolute;
        top: ${mediumUp ? rem(57) : rem(52)};
        right: ${rem(34)};
        width: 0;
        height: 0;
        padding: 0 0 ${rem(2)};
        color: ${theme.colors.text.darkTheme.disabled};
        transform: rotate(270deg) scale(0.7, 1.9);
        pointer-events: none;
      }
    `;

    // PCG Theme
    // pseudo select dropdowns on textField Label, add the custom chevron arrow
    const getSelectPcgStyles = () => css`
      ${getDarkThemeStyles()};
      font-weight: 400;
      ::after {
        ${getDarkThemeStyles()};
        content: '\\25c4'; /** Keep the escaping double backslash, or this unicode char will break - https://www.htmlsymbols.xyz/unicode/U+25C4 */
        position: absolute;
        top: ${mediumUp ? rem(57) : rem(52)};
        right: ${rem(34)};
        width: 0;
        height: 0;
        padding: 0 0 ${rem(2)};
        transform: rotate(270deg) scale(0.7, 1.9);
        pointer-events: none;
      }
    `;

    return css`
      /** Default/Base TextField styles get loaded first every time */
      ${getDefaultStyles()}
      // then, conditionally, color, border, background-color styles get added
      ${themeType === 'lightTheme'
        && getLightThemeStyles()} /** Default = Primary Light Theme */
      ${themeType === 'lightTheme' && disabled && getDisabledLightThemeStyles()}
      ${themeType === 'lightTheme'
        && inputType === 'select'
        && getSelectLightThemeStyles()}
      ${themeType === 'lightTheme'
        && disabled
        && inputType === 'select'
        && getDisabledSelectLightThemeStyles()}
      ${themeType === 'darkTheme' && getDarkThemeStyles()}
      ${themeType === 'darkTheme' && disabled && getDisabledDarkThemeStyles()}
      ${themeType === 'darkTheme'
        && inputType === 'select'
        && getSelectDarkThemeStyles()}
      ${themeType === 'darkTheme'
        && disabled
        && inputType === 'select'
        && getDisabledSelectDarkThemeStyles()}
      ${themeType === 'darkTheme' && branding === 'pcg' && getDarkThemeStyles()}
      ${themeType === 'darkTheme'
        && branding === 'pcg'
        && inputType === 'select'
        && getSelectPcgStyles()}
    `;
  }}
`;

export const SpanStyledRequiredFieldAsterisk = styled.span`
  ${({ hasError, theme, themeType }) => {
    let thisColor = theme.colors.forms.lightTheme.inputFieldText;
    if (themeType === 'lightTheme' && hasError) {
      thisColor = theme.colors.forms.lightTheme.inputFieldError;
    }
    if (themeType === 'darkTheme') {
      thisColor = theme.colors.forms.darkTheme.inputFieldText;
      if (hasError) {
        thisColor = theme.colors.forms.darkTheme.inputFieldError;
      }
    }
    return css`
      color: ${thisColor};
    `;
  }}
`;
