import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import { Row } from '../../../foundations/Grid';

const FormRowComponentStyled = styled(Row)`
  margin-bottom: ${rem(32)};
`;

export default FormRowComponentStyled;
