import React from 'react';
import PropTypes from 'prop-types';

// styles
import {
  LabelStyled,
  SpanStyledRequiredFieldAsterisk,
} from './styles';

export const LabelComponent = React.forwardRef(
  (
    {
      branding,
      children,
      disabled,
      hasError,
      inputType,
      labelText,
      required,
      themeType,
    },
    ref,
  ) => (
    <LabelStyled
      branding={branding}
      className='nmx-label'
      disabled={disabled}
      hasError={hasError} // used on Select field to color dropdown arrow
      inputType={inputType}
      themeType={themeType}>
      <span ref={ref}>
        {labelText}
        {required && (
          <SpanStyledRequiredFieldAsterisk
            aria-hidden='true'
            className='nmx-label-asterisk-container' // keep className on this element for consumption repo styling needs hook
            hasError={hasError}
            themeType={themeType}>
            &#42;
          </SpanStyledRequiredFieldAsterisk>
        )}
      </span>
      {children}
    </LabelStyled>
  ),
);

LabelComponent.propTypes = {
  /** sets overall branding of component or module, default='nm' */
  branding: PropTypes.oneOf(['nm', 'pcg']),
  /** Disabled attribute */
  disabled: PropTypes.bool,
  /** Optional bool depicts label as errorText, only used currently to style Select field dropdown arrow  */
  hasError: PropTypes.bool,
  /** Required Label Text */
  labelText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  /** Textfield is required */
  required: PropTypes.bool,
  /** InputType with value select: depicts pseudo select dropdown styles */
  inputType: PropTypes.oneOf(['text', 'select']),
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']),
};

LabelComponent.defaultProps = {
  branding: 'nm',
  disabled: false,
  hasError: false,
  inputType: 'text',
  required: false,
  themeType: 'lightTheme',
};

export default LabelComponent;
