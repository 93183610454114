import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import throttle from 'lodash/throttle';

import {
  ButtonStyledDismiss,
  SpanStyled,
} from './styles';

export const DismissComponent = ({
  ariaLabel,
  className,
  id,
  onClick,
  themeType,
}) => {
  // refs
  const modalCloseEl = useRef();

  // constants
  const LISTENER_THROTTLE = 100;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    function handleKeydown(e) {
      const enterKeyCode = 13;
      switch (e.keyCode) {
        case enterKeyCode:
          onClick();
          break;
        default:
          break;
      }
    }

    if (typeof document !== 'undefined') {
      // add listeners on Mount
      modalCloseEl.current.addEventListener(
        'keydown',
        throttle(handleKeydown, LISTENER_THROTTLE),
      );
      return () => {
        // remove listeners on Unmount
        modalCloseEl.current.removeEventListener(
          'keydown',
          throttle(handleKeydown, LISTENER_THROTTLE),
        );
      };
    }
  }, []);

  return (
    <ButtonStyledDismiss
      {...ariaLabel && { 'aria-label': ariaLabel }} // NOTE: React implements exceptions to JSX, "aria-*" is one of them: https://reactjs.org/docs/accessibility.html
      {...id && { id }}
      className={classNames(className, 'nmx-button-dismiss')}
      onClick={onClick}
      ref={modalCloseEl}
      themeType={themeType}
    >
      <SpanStyled
        className='nmx-icon'
        themeType={themeType}
      />
    </ButtonStyledDismiss>
  );
};

DismissComponent.propTypes = {
  /** Optional ariaLabel attribute, used for ADA to add more description to the Button (what will happen when clicked) */
  ariaLabel: PropTypes.string,
  /** Optional custom class name */
  className: PropTypes.string,
  /** optional id for the Dismiss button */
  id: PropTypes.string,
  /** Optional onClick function */
  onClick: PropTypes.func,
  // TODO: add in darkTheme styles
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']),
};

DismissComponent.defaultProps = {
  ariaLabel: 'dismiss',
  themeType: 'lightTheme',
};

export default DismissComponent;
