// deprecated
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Location from '@nmx/utils-frontend/dist/utilities/frontend/location';
import { processAgentId } from '../../utils/processAgentId';
// component
import AgentCard from './AgentCard';
import CrcCard from './CrcCard';
import DefaultCard from './DefaultCard';
import EnsembleCard from './EnsembleCard';

const DynamicAgentCardComponent = ({
  applicationId,
  agentCardTypeCallback,
  agentObject,
  backgroundVariant,
  campaign,
  checkCrc,
  config,
  customName,
  defaultCtaText,
  defaultDescriptionText,
  defaultFrPicture,
  defaultHeadingText,
  photoWidth,
  removeDefaultCardForLeads,
  searchAnalytics,
  source,
  themeType,
  topic,
}) => {
  const [isEnsemble, setIsEnsemble] = useState(false);
  // if agentObject is passed in, it takes precedence over everything
  const [agentData, setAgentData] = useState(agentObject);
  const [ensembleData, setEnsembleData] = useState();
  const [crcData, setCrcData] = useState({});
  const [isCrcDataValid, setIsCrcDataValid] = useState(false);

  const setCRCValues = () => {
    // Check for CRC value in query string
    const crcQueryParam = (Location.getQueryParam('crc') || '').substring(0, 4);
    if (crcQueryParam === 'true' || crcQueryParam === 'True') {
      const crc = true;
      const phone = Location.getQueryParam('no_phone');
      const city = Location.getQueryParam('no_city');

      setCrcData({
        crc,
        phone,
        city,
      });

      setIsCrcDataValid(crc && phone && city);
    }
  };

  const defaultCardNeedsToBeRemoved = () => {
    const localStorageVar = localStorage.getItem('leadConfirmedUser');
    const queryParam = (Location.getQueryParam('cta') || '').substring(0, 4);

    if (queryParam === 'none' || localStorageVar === 'true') {
      // If the user is a lead, set the localStorage var
      localStorage.setItem('leadConfirmedUser', true);

      return true;
    }

    return false;
  };

  useEffect(() => {
    processAgentId(config, agentObject, setIsEnsemble, setEnsembleData, setAgentData);
    setCRCValues();
  }, []);

  if (isEnsemble && ensembleData) {
    agentCardTypeCallback('ensemble');
    return <EnsembleCard ensembleObject={ensembleData} />;
  }

  // Crcs are orphans, they don't have an agent anymore. So show the city and office phone number instead...
  if (checkCrc && isCrcDataValid) {
    return <CrcCard
      city={crcData.city}
      phone={crcData.phone} />;
  }

  if (agentData) {
    agentCardTypeCallback('agent');
    // AgentData is supplied
    return (
      <AgentCard
        applicationId={applicationId}
        agentObject={agentData}
        campaign={campaign}
        config={config}
        customName={customName}
        key={agentData}
        photoWidth={photoWidth}
        searchAnalytics={searchAnalytics}
        source={source}
        themeType={themeType}
        topic={topic}
      />
    );
  }

  /*
    we're about to show the DefaultCard, but HOLLLLLL UP.
    We may need this entire section to disappear.
    If business wants us to hide this for visitors meets certain criteria
  */
  if (removeDefaultCardForLeads && defaultCardNeedsToBeRemoved()) {
    return false;
  }

  agentCardTypeCallback('default');
  // This is Card that is displayed if the agentData doesn't exist
  return (
    <DefaultCard
      backgroundVariant={backgroundVariant}
      defaultCtaText={defaultCtaText}
      defaultDescriptionText={defaultDescriptionText}
      defaultHeadingText={defaultHeadingText}
      defaultFrPicture={defaultFrPicture}
      themeType={themeType}
    />
  );
};

DynamicAgentCardComponent.propTypes = {
  /** backgroundVariant allows section to be different background color based on themeType */
  backgroundVariant: PropTypes.oneOf(['darkDefault', 'lightA', 'lightB', 'lightC']),
  /** CheckCRC boolean */
  checkCrc: PropTypes.bool,
  /** customName passed into AgentCard to override Default name */
  customName: PropTypes.string,
  /** inner text of CTA Link for default variant */
  defaultCtaText: PropTypes.string,
  /** description text for the default variant - will accept fragments and special characters */
  defaultDescriptionText: PropTypes.any,
  /** image for the default variant */
  defaultFrPicture: PropTypes.oneOf(['default', 'danNguyen', 'holleeKier', 'johnWhite', 'kellerLindler', 'leoTucker', 'trevorSmith']),
  /** heading text for the default variant */
  defaultHeadingText: PropTypes.string,
  /** If business needs to hide the default card for specific reasons */
  removeDefaultCardForLeads: PropTypes.bool,
  photoWidth: PropTypes.number,
  campaign: PropTypes.string,
  source: PropTypes.string,
  topic: PropTypes.string,
  agentCardTypeCallback: PropTypes.func,
  agentObject: PropTypes.shape({
    id: PropTypes.string,
    agentNumber: PropTypes.string,
    fullName: PropTypes.string,
    type: PropTypes.string, // MP, MD, etc
    street: PropTypes.string,
    building: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    zipLastFour: PropTypes.string,
    designations: PropTypes.array,
    selectedPhone1: PropTypes.object,
    briefBio: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    photo: PropTypes.string,
    gender: PropTypes.string,
  }),
  config: PropTypes.shape({
    public: PropTypes.shape({ googlePlacesApiKey: PropTypes.string }),
    services: PropTypes.shape({
      serviceRequestApiBaseUrl: PropTypes.string,
      leadApiBaseUrl: PropTypes.string,
    }),
  }),
  searchAnalytics: PropTypes.shape({
    searchTerm: PropTypes.string,
    resultsNumber: PropTypes.string,
  }),
  onFormEngaged: PropTypes.func,
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme', 'nmx-pcg']),
  /** application Id for the form */
  applicationId: PropTypes.string,

  /** required variant for agent card */
  // variant: PropTypes.oneOf(['default', 'agent', 'ensemble']), // TODO: can we refactor current setup into something like this?
};

DynamicAgentCardComponent.defaultProps = {
  agentCardTypeCallback: () => { },
  checkCrc: false,
  config: {
    public: { googlePlacesApiKey: '<%=googlePlacesApiKey%>' },
    services: {
      serviceRequestApiBaseUrl: '<%=serviceRequestApiBaseUrl%>',
      leadApiBaseUrl: '<%=leadApiBaseUrl%>',
    },
  },
  removeDefaultCardForLeads: false,
  searchAnalytics: {},
  themeType: 'lightTheme',
};

export default DynamicAgentCardComponent;
