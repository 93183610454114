import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import Typography from '../../foundations/Typography';
import { getSectionBackgroundColor } from '../../components/SectionContainer/styles';
import { Row, Col, Container } from '../../foundations/Grid';

export const getBorderBottom = (disableBottomBorder, theme) => {
  const mediumUp = theme.mediumUp();
  if (disableBottomBorder || !mediumUp) {
    /** If a disableBottomBorder prop was provided, override the default */
    return 'none';
  }
  /** Return a default value when disableBottomBorder is not provided */
  return `${rem(2)} solid ${theme.colors.sky.sky}`;
};

export const ContainerStyled = styled(Container)`
    ${({ theme, backgroundVariant, themeType, disableBottomBorder }) => {
    const mediumUp = theme.mediumUp();
    const thisBackgroundColor = getSectionBackgroundColor(backgroundVariant, 'nm', theme, themeType);
    const thisBorderColor = theme.colors.sky.sky;
    let thisBorderLeft = `${rem(6)} solid ${thisBorderColor}`;
    let thisBorderTop = 'none';
    let thisPadding = `${rem(38)} 0`;
    if (mediumUp) {
      thisBorderLeft = 'none';
      thisBorderTop = `${rem(2)} solid ${theme.colors.sky.sky}`;
      thisPadding = `${rem(16)} 0`;
    }

    return css`
        border-left: ${thisBorderLeft};
        flex-direction: row;
        background-color: ${thisBackgroundColor};
        padding: ${thisPadding};
        border-top: ${thisBorderTop};
        border-bottom: ${getBorderBottom(disableBottomBorder, theme)};
    `;
  }}
`;

export const DivStyledDate = styled.div`
    ${({ theme, backgroundVariant, themeType }) => {
    const mediumUp = theme.mediumUp();
    let thisBackgroundColor = getSectionBackgroundColor(backgroundVariant, 'nm', theme, themeType);
    let thisHeight = 'auto';
    let thisMargin = `0 0 0 ${rem(20)}`;
    if (mediumUp) {
      thisMargin = '0';
      thisBackgroundColor = theme.colors.sky.sky;
      thisHeight = rem(160);
    }
    return css`
        color: ${theme.colors.blue.blue};
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: ${thisMargin};
        text-align: center;
        height: ${thisHeight};
        background-color: ${thisBackgroundColor};
    `;
  }}
`;

export const DivStyledEvent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
    margin-left: ${rem(40)};
`;

export const TypographyStyledEvent = styled(Typography)`
    ${({ theme }) => css`
      color: ${theme.colors.blue.blue};
    `}
`;

export const TypographyStyledDescription = styled(Typography)`
    ${({ theme }) => css`
        color: ${theme.colors.neutral.grayDark};
    `}
`;

export const RowStyled = styled(Row)`
  width: 100%;
`;

export const ColStyled = styled(Col)`
  padding-left: 0;
`;
