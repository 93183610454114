// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import scriptsToLoad from './nmcom_scripts';

const loadScripts = (scripts, props) => {
  for (let i = 0; i < scripts.length; i += 1) {
    /*  only add the script is there is no prop value determining if it should be added/remove
        or if the prop value determining to add the script is presenent and true */
    if (!scripts[i].propAddValue || !!props[scripts[i].propAddValue]) {
      /* build script and append to body */
      const script = document.createElement('script');
      Object.assign(script, scripts[i]);
      delete script.propAddValue; // we don't need the prod check field added to the script tag
      document.body.appendChild(script);
    }
  }
};

export const ScriptsComponent = (props) => {
  useEffect(() => {
    // These scripts can/should load after page load. with small timeout
    loadScripts(scriptsToLoad(props), props);
  }, []);
  return false;
};

ScriptsComponent.propTypes = {
  /** Config vars used for varying components */
  config: PropTypes.shape({
    basePath: PropTypes.string.isRequired,
    nmxTemplateVersion: PropTypes.string.isRequired,
    public: PropTypes.shape({ googlePlacesApiKey: PropTypes.string }).isRequired,
  }).isRequired,
  showGooglePlaces: PropTypes.bool,
};

ScriptsComponent.defaultProps = { showGooglePlaces: false };

export default ScriptsComponent;
