/**
 * Little functions used for validation
 */
import * as changeCase from 'change-case';
var PII = ['age', 'comments', 'email', 'e-mail', 'firstname', 'first-name', 'lastname', 'last-name', 'phone', 'zip', 'zipcode', 'zip-code'];
/**
 * Checks whether a field name - generally an HTML input name - is considered PII. Any value that isn't a string is
 * automatically `false`
 *
 * @param {string} fieldName
 *
 * @return {boolean}
 */

var isFieldNamePii = function isFieldNamePii(fieldName) {
  if (typeof fieldName !== 'string') {
    return false;
  }

  var fieldNameSanitized = changeCase.paramCase(fieldName);
  return PII.includes(fieldNameSanitized);
};

export { isFieldNamePii };