import React from 'react';
import PropTypes from 'prop-types';

import { HelperTextLabelStyled } from './styles';

const HelperTextLabel = ({
  branding,
  disabled,
  hasError,
  helperText,
  themeType,
}) => {
  if (helperText) {
    // Return default version of the error message
    return (
      <HelperTextLabelStyled
        branding={branding}
        className="nmx-input-error-label"
        component="small"
        disabled={disabled}
        themeType={themeType}
        hasError={hasError}>
        {helperText}
      </HelperTextLabelStyled>
    );
  }
  return false;
};

HelperTextLabel.propTypes = {
  /** sets overall branding of component or module, default='nm' */
  branding: PropTypes.oneOf(['nm', 'pcg']),
  /** Disabled attribute */
  disabled: PropTypes.bool,
  /** Optional bool depicts helperText as errorText  */
  hasError: PropTypes.bool,
  /** Optional helperText positioned below input field */
  helperText: PropTypes.string, // TODO: convert to children
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']),
};

HelperTextLabel.defaultProps = {
  branding: 'nm',
  disabled: false,
  hasError: false,
  helperText: '', // TODO: build PropType function (if hasError = true, then default helperText = "Error")
  themeType: 'lightTheme',
};

export default HelperTextLabel;
