import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

// template components
import Link from '../../../../components/Link';

const getIconLinkStyles = (theme) => css`
  display: flex;
  width: ${rem(30)};
  height: ${rem(30)};
  align-items: center;
  border-radius: 50%;
  &:link {
    background-color: ${theme.colors.action.lightTheme.linkDefault};
  }
  &:visited {
    background-color: ${theme.colors.action.lightTheme.linkVisited};
  }
  &:hover,
  &:focus {
    background-color: ${theme.colors.action.lightTheme.linkHover};
  }
  &:active {
    background-color: ${theme.colors.action.lightTheme.linkActive};
  }
  .nmx-icon {
    width: 100%;
    .icon-fill {
      fill: ${theme.colors.neutral.white};
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const LinkStyledSocial = styled(Link)`
  ${({ theme }) => css`
    ${getIconLinkStyles(theme)}
  `}
`;
