import { rem } from 'polished';
import styled, { css } from 'styled-components';
// template components
import Button from '../../components/Button';
import { Col, Row } from '../../foundations/Grid';
import Form from '../../components/forms/Form';
import SectionContainer from '../../components/SectionContainer';
import TextField from '../../components/forms/TextField';
import Typography from '../../foundations/Typography';
// icons
import HandDrawnArrow from './icons/HandDrawnArrow';
import HandDrawnCheckmark from './icons/HandDrawnCheckmark';

export const ButtonStyled = styled(Button)`
  ${({ theme }) => {
    const xlargeDown = theme.xlargeDown();
    const mediumUp = theme.mediumUp();
    // returns true for breakpoint range between 0px and 480px
    const between0To480 = theme.useMediaQuery(theme.breakpoints.between(0, 480),
      { defaultMatches: (theme.pageLoadWindowWidth >= 0 && theme.pageLoadWindowWidth < 480) });
    // returns true for breakpoint range between 768px and 1024px
    const between768To1024 = theme.useMediaQuery(theme.breakpoints.between(768, 1024),
      { defaultMatches: (theme.pageLoadWindowWidth >= 768 && theme.pageLoadWindowWidth < 1024) });
    let thisMarginTop = rem(20);

    if (mediumUp) {
      thisMarginTop = rem(24);
    }

    return css`
      display: block;
      margin-top: ${thisMarginTop};
      /* In other words, apply these rules for xsmall, but not small, and then again for medium, but not for large and up */
      ${(between0To480 || between768To1024) && `
        min-width: unset;
        max-width: unset;
        width: 100%;
     `};

      ${xlargeDown && `
        margin-left: auto;
        margin-right: auto;
      `};
    `;
  }
}`;

// White box inner col
export const ColContainerStyled = styled(Col)`
  padding-right: 0;
  padding-left: 0;
`;

// Form container
export const ColInputContainerStyled = styled(Col)`
  ${({ theme, disableBottomPadding }) => {
    const largeUp = theme.largeUp();
    const smallUp = theme.smallUp();
    let verticalPadding = largeUp ? 0 : rem(12);
    const horizontalPadding = smallUp ? rem(4) : 0;

    if (disableBottomPadding) {
      verticalPadding = 0;
    }

    return css`
      padding: 0 ${horizontalPadding} ${verticalPadding};
    `;
  }
}`;

// White box
export const DivFormContainerStyled = styled.div`
  ${({ theme, themeType }) => {
    const mediumUp = theme.mediumUp();
    const thisLightThemeVerticalPadding = mediumUp ? rem(36) : rem(18);
    const thisVerticalPadding = themeType === 'darkTheme' ? 0 : thisLightThemeVerticalPadding;
    return css`
      position: relative;
      height: auto;
      margin-top: 0;
      padding-top: ${thisVerticalPadding};
      padding-bottom: ${thisVerticalPadding};
      background-color: ${themeType === 'darkTheme' ? theme.colors.background.darkTheme.darkDefault : theme.colors.background.lightTheme.lightA};
    `;
  }
}`;

// PATTERN STYLES /////////////////
// controls the opacity of both left and right dot pattern svgs
const patternOpacity = 0.25;

export const DivLeftPatternContainerStyled = styled.div`
  ${({ theme }) => {
    const xxlargeUp = theme.xxlargeUp();
    const xlargeUp = theme.xlargeUp();
    const largeUp = theme.largeUp();
    const mediumUp = theme.mediumUp();
    const smallUp = theme.smallUp();
    let minHeight = rem(320); // xsmall default
    let patternOffsetDesktop = rem(-550); // xsmall default

    if (xxlargeUp) {
      patternOffsetDesktop = 0;
    } else if (xlargeUp) {
      patternOffsetDesktop = rem(-100);
    } else if (largeUp) {
      patternOffsetDesktop = rem(-444);
    } else if (mediumUp) {
      patternOffsetDesktop = rem(-575);
    } else if (smallUp) {
      patternOffsetDesktop = rem(-460);
    }

    if (xlargeUp) {
      minHeight = rem(310);
    } else if (mediumUp) {
      minHeight = rem(400);
    }

    return css`
      position: absolute;
      bottom: 0;
      left: ${patternOffsetDesktop};
      width: ${mediumUp ? 'auto' : '100%'};
      opacity: ${patternOpacity + 0.15}; /** Adding compensation */
      z-index: 0;
      .nmx-icon {
        height: 100%; /** was 560 */
        min-height: ${minHeight}; /** adding min-height so dot pattern doesn't scale down on confirmation screen */
        width: auto;
      }
    `;
  }
}`;

export const DivRightPatternContainerStyled = styled.div`
  ${({ theme }) => {
    const xxlargeUp = theme.xxlargeUp();
    const xlargeUp = theme.xlargeUp();
    const largeUp = theme.largeUp();
    const mediumUp = theme.mediumUp();
    const smallUp = theme.smallUp();
    let minHeight = rem(320); // xsmall default
    let patternOffsetDesktop = rem(-10); // xsmall default

    if (xxlargeUp) {
      patternOffsetDesktop = rem(0);
    } else if (xlargeUp) {
      patternOffsetDesktop = rem(-200);
    } else if (largeUp) {
      patternOffsetDesktop = rem(-580);
    } else if (mediumUp) {
      patternOffsetDesktop = rem(-550);
    } else if (smallUp) {
      patternOffsetDesktop = rem(-95);
    }

    if (xlargeUp) {
      minHeight = rem(310);
    } else
    if (mediumUp) {
      minHeight = rem(400);
    }

    return css`
      position: absolute;
      top: 0;
      right: ${patternOffsetDesktop};
      width: ${mediumUp ? 'auto' : '100%'};
      opacity: ${patternOpacity};
      .nmx-icon {
        height: 100%;
        min-height: ${minHeight}; /** adding min-height so dot pattern doesn't scale down on confirmation screen */
        width: auto;
      }
    `;
  }
}`;
// END PATTERN STYLES ////////////////

export const DivLoadingContainerStyled = styled.div`
  width: ${rem(125)};
  margin: 0 auto;
`;

export const FieldsetStyled = styled.fieldset`
  border: none;
  padding: 0;
  margin: 0;
`;

export const FormStyled = styled(Form)`
  position: relative;
  z-index: 1;
  .nmx-container {
    padding: 0
  }
`;

export const LegendStyled = styled.legend`
  display: none;
`;

export const RowConfirmationStyled = styled(Row)`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    return css`
    padding-top: ${mediumUp ? rem(10) : rem(5)};
  `;
  }
}`;

export const SectionContainerStyled = styled(SectionContainer)`
  ${({ theme, themeType }) => {
    const mediumUp = theme.mediumUp();
    const thisDarkThemePadding = mediumUp ? rem(60) : rem(32);
    const thisPadding = rem(28);
    const thisVerticalPadding = themeType === 'darkTheme' ? thisDarkThemePadding : thisPadding;

    return css`
      && {
        background-color: ${themeType === 'darkTheme' ? theme.colors.background.darkTheme.darkDefault : theme.colors.background.lightTheme.lightSky};
        width: 100%;
        position: relative;
        max-width: unset;
        overflow: hidden;
        padding-top: ${thisVerticalPadding};
        padding-bottom: ${thisVerticalPadding};
     

        & > .nmx-container {
          max-width: unset;
          ${!mediumUp && `
            padding-left: ${thisPadding};
            padding-right: ${thisPadding};
          `}
        }

        .nmx-input-error-label {
          padding-bottom: 0;
        }

        @media print {
          display: none !important;
        }
      }
    `;
  }
}`;

export const SpanStyledIconContainer = styled.span`
  position: relative;
  display: inline-block;
`;

// ARROW STYLES
export const HandDrawnArrowStyled = styled(HandDrawnArrow)`
  ${({ theme }) => {
    const largeUp = theme.largeUp();
    const mediumUp = theme.mediumUp();
    let thisScale = 0.8;

    if (largeUp) {
      thisScale = 1.1;
    } else if (mediumUp) {
      thisScale = 1;
    }

    return css`
      position: absolute;
      top: ${rem(-20)};
      scale: ${thisScale};
    `;
  }}
`;

// CHECKMARK STYLES
export const HandDrawnCheckmarkStyled = styled(HandDrawnCheckmark)`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    return css`
      position: absolute;
      top: ${rem(-23)};
      left: ${mediumUp ? rem(7) : rem(4)};
      scale: ${mediumUp ? 1.1 : 1};
    `;
  }}
`;

// this style gets imported by the individual input files, not the main js file
export const TextFieldStyled = styled(TextField)`
  height: ${rem(55)};
`;

export const TypographyHeaderStyled = styled(Typography)`
  ${({ theme, themeType }) => {
    const mediumUp = theme.mediumUp();
    let thisTextAlignment = 'center';

    // headingText will be left-aligned for small devices when darkTheme (For now......MWAAA HA HA HA HA *DUN DUN DUN* *lightning and thunder sounds*)
    if (themeType === 'darkTheme') {
      thisTextAlignment = mediumUp ? 'center' : 'left';
    }

    return css`
      padding-bottom: ${rem(16)};
      text-align: ${thisTextAlignment};
    `;
  }
}`;

export const TypographyStyled = styled(Typography)`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    return css`
      text-align: ${mediumUp ? 'center' : 'left'};
    `;
  }
}`;
