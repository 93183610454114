// THIS MODULE HAS BEEN DEPRECATED AND SHOULD BE REMOVED IN THE NEXT MAJOR RELEASE
import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
// components
import Link from '../../components/Link';
import Typography from '../../foundations/Typography';
// icons
import CaratRightIcon from '../../foundations/Icon/icons/utility/CaratRight';
// styles
import {
  DivStyledActionContainer,
  DivStyledArticleCardContainer,
  DivStyledContent,
  DivStyledImageContainer,
  PictureStyled,
} from './styles';

export const ArticleCardComponent = ({
  headingText,
  imageAltText,
  imageUrl,
  imageWebpUrl,
  linkUrl,
}) => {
  // eslint-disable-next-line no-console
  console.warn('The ArticleCard module is deprecated and will not be supported in nmx-template v4.0. Please use the DynamicCallout module instead. '); // NOSONAR

  return (
    <DivStyledArticleCardContainer>
      <DivStyledImageContainer>
        <PictureStyled
          src={imageUrl}
          desktopWebpSrc={imageWebpUrl}
          altText={imageAltText}
          lazyLoad={false}
        />
      </DivStyledImageContainer>
      <DivStyledContent>
        <Typography
          weight={200}
          component='h4'
          variant='h5'
          disableBottomPadding>
          {headingText}
        </Typography>
      </DivStyledContent>
      <DivStyledActionContainer>
        <Link
          id='storybook-link-id'
          variant='primaryDeemphasize'
          linkUrl={linkUrl}
          ariaLabel={`Read more about ${headingText}`}
          endAdornment={CaratRightIcon}
          endAdornmentStylesReset>
          Read more
        </Link>
      </DivStyledActionContainer>
    </DivStyledArticleCardContainer>
  );
};

ArticleCardComponent.propTypes = {
  headingText: PropTypes.string.isRequired,
  imageAltText: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  imageWebpUrl: PropTypes.string,
  linkUrl: PropTypes.string.isRequired,
};

export default withTheme(ArticleCardComponent);
