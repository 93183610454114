import React from 'react';
import PropTypes from 'prop-types';
import { Row } from '../../foundations/Grid';

// template components
import { linkPropTypes } from '../../components/Link';
import SectionContainer from '../../components/SectionContainer';
import Typography from '../../foundations/Typography';
import CaratRightUtility from '../../foundations/Icon/icons/utility/CaratRight';

import {
  PrimaryCtaStyled,
  ColContentStyled,
  ColDesktopStatCardContainer,
  DivMobileStatCardContainer,
  DivStatCardStyled,
  SecondaryCtaStyled,
  PictureCardImageStyled,
  TypographyCardHeadingStyled,
  TypographyContentHeadingStyled,
} from './styles';

export const StatModuleComponent = ({
  backgroundVariant,
  cardDescriptionText,
  cardDescriptionTextAriaLabel,
  cardHeadingText,
  cardHeadingTextAriaLabel,
  cardImage,
  cardImageAltText,
  ctaPrimary,
  ctaSecondary,
  descriptionText,
  disableBottomPadding,
  headingText,
  sectionId,
  themeType,
}) => {
  const getStatCard = () => (
    <DivStatCardStyled>
      {cardImage && (
        <PictureCardImageStyled
          lazyLoad={false}
          src={cardImage}
          altText={cardImageAltText}
          fullWidth={true}
        />
      )}
      {cardHeadingText && (
        <TypographyCardHeadingStyled
          component="h3"
          ariaLabel={cardHeadingTextAriaLabel || ''}>
          {cardHeadingText}
        </TypographyCardHeadingStyled>
      )}
      {cardDescriptionText && (
        <Typography
          component="p"
          align="center"
          ariaLabel={cardDescriptionTextAriaLabel || ''}
          disableBottomPadding>
          {cardDescriptionText}
        </Typography>
      )}
    </DivStatCardStyled>
  );

  return (
    <SectionContainer
      id={`section-${sectionId}`}
      backgroundVariant={backgroundVariant}
      themeType={themeType}
      moduleName="stat-module"
      moduleVariation="A"
      disableBottomPadding={disableBottomPadding}>
      <Row align="left">
        <ColContentStyled
          xsmall={12}
          medium={6}
          large={8}>
          <TypographyContentHeadingStyled
            component="h2"
            themeType={themeType}>
            {headingText}
          </TypographyContentHeadingStyled>
          <Typography
            themeType={themeType}
            nextElement='button'>
            {descriptionText}
          </Typography>
          {/* ONLY DISPLAY FOR MOBILE */}
          {/* TODO: this is displaying 2 sets of markup, needs fixing!!! */}
          <DivMobileStatCardContainer
            // TODO: passing in these (null) optional props throwing error - we can do this a better way
            buttonText={ctaPrimary.linkText}
            linkText={ctaSecondary.linkText}>
            {getStatCard()}
          </DivMobileStatCardContainer>
          {ctaPrimary && ctaPrimary.linkText && ( // change condition check to "{ctaPrimary && ctaPrimary.children && (" in 4.x.x
            <PrimaryCtaStyled
              {...ctaPrimary} // NOTE: list the dynamic props first, to not overwrite the hard-coded props
              id={`${sectionId}-cta-primary`}
              themeType={themeType}
              variant='buttonLinkPrimary'>
              {ctaPrimary.linkText} {/** change this to ctaPrimary.children in 4.x.x */}
            </PrimaryCtaStyled>
          )}
          {ctaSecondary && ctaSecondary.linkText && ( // change condition check to "{ctaSecondary && ctaSecondary.children && (" in 4.x.x
            <SecondaryCtaStyled
              {...ctaSecondary} // NOTE: list the dynamic props first, to not overwrite the hard-coded props
              id={`${sectionId}-cta-secondary`}
              endAdornment={CaratRightUtility}
              endAdornmentStylesReset={true}
              themeType={themeType}
              variant='primaryDeemphasize'>
              {ctaSecondary.linkText} {/** change this to ctaSecondary.children in 4.x.x */}
            </SecondaryCtaStyled>
          )}
        </ColContentStyled>
        {/* TODO: this is displaying 2 sets of markup, needs fixing!!! */}
        <ColDesktopStatCardContainer
          medium={6}
          large={4}>
          {/* ONLY DISPLAY FOR TABLET AND DESKTOP */}
          {getStatCard()}
        </ColDesktopStatCardContainer>
      </Row>
    </SectionContainer>
  );
};

StatModuleComponent.propTypes = {
  /** backgroundVariant allows section to be different background color based on themeType */
  backgroundVariant: PropTypes.oneOf(['darkDefault', 'lightA', 'lightB', 'lightC']),
  /** Card Description Text */
  cardDescriptionText: PropTypes.any,
  /** Card Description Aria Label */
  cardDescriptionTextAriaLabel: PropTypes.any,
  /** Card Heading Text */
  cardHeadingText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /** Card Heading Aria Label */
  cardHeadingTextAriaLabel: PropTypes.any,
  /** optional card image path */
  cardImage: PropTypes.any,
  /** alt tag for optional card image */
  cardImageAltText: PropTypes.any,
  /** optional additional className */
  className: PropTypes.string,
  /** Props passed to primary CTA button - if object not provided, will now show cta link */
  ctaPrimary: PropTypes.shape({
    linkId: PropTypes.string, // deprecated
    linkText: PropTypes.oneOfType([ // deprecated
      PropTypes.string,
      PropTypes.object,
    ]),
    ...linkPropTypes,
  }),
  /** Props passed to secondary CTA button - if object not provided, will now show cta link */
  ctaSecondary: PropTypes.shape({
    linkId: PropTypes.string, // deprecated
    linkText: PropTypes.oneOfType([ // deprecated
      PropTypes.string,
      PropTypes.object,
    ]),
    ...linkPropTypes,
  }),
  /** Required Main Description Text */
  descriptionText: PropTypes.any.isRequired,
  /** optional prop to disable section bottom padding */
  disableBottomPadding: PropTypes.bool,
  /** Required Main Heading Text */
  headingText: PropTypes.any.isRequired,
  /** required section id */
  sectionId: PropTypes.string.isRequired,
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme', 'nmx-pcg']),
};

export default StatModuleComponent;
