import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// styles
import {
  DdStyled,
  DlStyled,
  DtStyled,
  SpanStyledDescriptionListItem,
  TypographyStyled,
} from './styles';

export const DescriptionListComponent = ({
  className,
  descriptionListData,
  disableBullet,
  themeType,
}) => {
  useEffect(() => {
    // validate that populated descriptionListData is passed in
    if (!descriptionListData || descriptionListData.length === 0) {
      console.error('Missing description list data. At least one entry is required.');
    }
  }, []);

  if (!descriptionListData || descriptionListData.length < 1) {
    return null;
  }

  return (
    <DlStyled className={className}> {/** description list */}
      {descriptionListData && descriptionListData.map((item, index) => {
        const thisDescriptionDetailsAttributes = item.descriptionDetailsAttributes
          ? item.descriptionDetailsAttributes.reduce((thisAccumulator, thisCurrentEl) => ({
            ...thisAccumulator,
            ...thisCurrentEl,
          }), {})
          : {};

        return (
          Object.prototype.hasOwnProperty.call(item, 'descriptionTerm') && Object.prototype.hasOwnProperty.call(item, 'descriptionDetails')
          && <SpanStyledDescriptionListItem key={`${item.descriptionTerm}-${index + 1}`}>
            <DtStyled // description term
              disableBullet={disableBullet}
              themeType={themeType}
            >
              <TypographyStyled
                themeType={themeType}
                weight={400}
              >
                {item.descriptionTerm}
              </TypographyStyled>
            </DtStyled>
            <DdStyled // description details
              itemScope={Object.prototype.hasOwnProperty.call(thisDescriptionDetailsAttributes, 'itemType')} // when passing in schema itemType, itemScope will be appropriately set
              {...thisDescriptionDetailsAttributes}
            >
              <TypographyStyled themeType={themeType}>
                {item.descriptionDetails}
              </TypographyStyled>
            </DdStyled>
          </SpanStyledDescriptionListItem>
        );
      })}
    </DlStyled>
  );
};

DescriptionListComponent.propTypes = {
  /** Custom class name to add to description list */
  className: PropTypes.string,
  /** Array of data to display in the description list */
  descriptionListData: PropTypes.arrayOf(PropTypes.shape({
    /** description term */
    descriptionTerm: PropTypes.any.isRequired,
    /** description details, will support React.Fragment (i.e. <sup>3</sup>) */
    descriptionDetails: PropTypes.any.isRequired,
    /** optional array of objects added as attributes to parent list item   */
    descriptionDetailsAttributes: PropTypes.arrayOf(PropTypes.object),
  })).isRequired,
  /** Hide list item bullets */
  disableBullet: PropTypes.bool,
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']),
};

DescriptionListComponent.defaultProps = { themeType: 'lightTheme' };

export default DescriptionListComponent;
