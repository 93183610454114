/** Created manually */
import React from 'react';
import classNames from 'classnames';

class Icon extends React.PureComponent {
  render() {
    const { className, svgRef, ...otherProps } = this.props;
    return (
      <svg
        version="1.1"
        x="0"
        y="0"
        viewBox="0 0 1570.1 700"
        className={classNames('nmx-icon', className)}
        ref={svgRef}
        {...otherProps}>
        <circle
          cx="722.4"
          cy="18.6"
          r="2"/>
        <circle
          cx="722.4"
          cy="50.4"
          r="2"/>
        <circle
          cx="722.4"
          cy="82.2"
          r="2"/>
        <circle
          cx="722.4"
          cy="114"
          r="2"/>
        <circle
          cx="722.4"
          cy="145.8"
          r="2"/>
        <circle
          cx="722.4"
          cy="177.6"
          r="2"/>
        <circle
          cx="722.4"
          cy="209.3"
          r="2"/>
        <circle
          cx="722.4"
          cy="241.1"
          r="2"/>
        <circle
          cx="722.4"
          cy="272.9"
          r="2"/>
        <circle
          cx="722.4"
          cy="304.7"
          r="2"/>
        <circle
          cx="722.4"
          cy="336.5"
          r="2"/>
        <circle
          cx="722.4"
          cy="368.2"
          r="2"/>
        <circle
          cx="722.4"
          cy="400"
          r="2"/>
        <circle
          cx="722.4"
          cy="431.8"
          r="2"/>
        <circle
          cx="722.4"
          cy="463.6"
          r="2"/>
        <circle
          cx="722.4"
          cy="495.4"
          r="2"/>
        <circle
          cx="722.4"
          cy="527.1"
          r="2"/>
        <circle
          cx="722.4"
          cy="558.9"
          r="2"/>
        <circle
          cx="722.4"
          cy="590.7"
          r="2"/>
        <circle
          style={{ opacity: 0.75 }}
          cx="722.4"
          cy="622.5"
          r="2"/>
        <circle
          style={{ opacity: 0.5 }}
          cx="722.4"
          cy="654.3"
          r="2"/>
        <circle
          style={{ opacity: 0.1 }}
          cx="722.4"
          cy="686.1"
          r="2"/>
        <circle
          cx="754.2"
          cy="18.6"
          r="2"/>
        <circle
          cx="754.2"
          cy="50.4"
          r="2"/>
        <circle
          cx="754.2"
          cy="82.2"
          r="2"/>
        <circle
          cx="754.2"
          cy="114"
          r="2"/>
        <circle
          cx="754.2"
          cy="145.8"
          r="2"/>
        <circle
          cx="754.2"
          cy="177.6"
          r="2"/>
        <circle
          cx="754.2"
          cy="209.3"
          r="2"/>
        <circle
          cx="754.2"
          cy="241.1"
          r="2"/>
        <circle
          cx="754.2"
          cy="272.9"
          r="2"/>
        <circle
          cx="754.2"
          cy="304.7"
          r="2"/>
        <circle
          cx="754.2"
          cy="336.5"
          r="2"/>
        <circle
          cx="754.2"
          cy="368.2"
          r="2"/>
        <circle
          cx="754.2"
          cy="400"
          r="2"/>
        <circle
          cx="754.2"
          cy="431.8"
          r="2"/>
        <circle
          cx="754.2"
          cy="463.6"
          r="2"/>
        <circle
          cx="754.2"
          cy="495.4"
          r="2"/>
        <circle
          cx="754.2"
          cy="527.1"
          r="2"/>
        <circle
          cx="754.2"
          cy="558.9"
          r="2"/>
        <circle
          cx="754.2"
          cy="590.7"
          r="2"/>
        <circle
          cx="754.2"
          cy="622.5"
          r="2"/>
        <circle
          style={{ opacity: 0.75 }}
          cx="754.2"
          cy="654.3"
          r="2"/>
        <circle
          style={{ opacity: 0.5 }}
          cx="754.2"
          cy="686.1"
          r="2"/>
        <circle
          cx="786"
          cy="18.6"
          r="2"/>
        <circle
          cx="786"
          cy="50.4"
          r="2"/>
        <circle
          cx="786"
          cy="82.2"
          r="2"/>
        <circle
          cx="786"
          cy="114"
          r="2"/>
        <circle
          cx="786"
          cy="145.8"
          r="2"/>
        <circle
          cx="786"
          cy="177.6"
          r="2"/>
        <circle
          cx="786"
          cy="209.3"
          r="2"/>
        <circle
          cx="786"
          cy="241.1"
          r="2"/>
        <circle
          cx="786"
          cy="272.9"
          r="2"/>
        <circle
          cx="786"
          cy="304.7"
          r="2"/>
        <circle
          cx="786"
          cy="336.5"
          r="2"/>
        <circle
          cx="786"
          cy="368.2"
          r="2"/>
        <circle
          cx="786"
          cy="400"
          r="2"/>
        <circle
          cx="786"
          cy="431.8"
          r="2"/>
        <circle
          cx="786"
          cy="463.6"
          r="2"/>
        <circle
          cx="786"
          cy="495.4"
          r="2"/>
        <circle
          cx="786"
          cy="527.1"
          r="2"/>
        <circle
          cx="786"
          cy="558.9"
          r="2"/>
        <circle
          cx="786"
          cy="590.7"
          r="2"/>
        <circle
          cx="786"
          cy="622.5"
          r="2"/>
        <circle
          cx="786"
          cy="654.3"
          r="2"/>
        <circle
          style={{ opacity: 0.75 }}
          cx="786"
          cy="686.1"
          r="2"/>
        <g>
          <circle
            cx="817.8"
            cy="18.6"
            r="2"/>
          <circle
            cx="817.8"
            cy="50.4"
            r="2"/>
          <circle
            cx="817.8"
            cy="82.2"
            r="2"/>
          <circle
            cx="817.8"
            cy="114"
            r="2"/>
          <circle
            cx="817.8"
            cy="145.8"
            r="2"/>
          <circle
            cx="817.8"
            cy="177.6"
            r="2"/>
          <circle
            cx="817.8"
            cy="209.3"
            r="2"/>
          <circle
            cx="817.8"
            cy="241.1"
            r="2"/>
          <circle
            cx="817.8"
            cy="272.9"
            r="2"/>
          <circle
            cx="817.8"
            cy="304.7"
            r="2"/>
          <circle
            cx="817.8"
            cy="336.5"
            r="2"/>
          <circle
            cx="817.8"
            cy="368.2"
            r="2"/>
          <circle
            cx="817.8"
            cy="400"
            r="2"/>
          <circle
            cx="817.8"
            cy="431.8"
            r="2"/>
          <circle
            cx="817.8"
            cy="463.6"
            r="2"/>
          <circle
            cx="817.8"
            cy="495.4"
            r="2"/>
          <circle
            cx="817.8"
            cy="527.1"
            r="2"/>
          <circle
            cx="817.8"
            cy="558.9"
            r="2"/>
          <circle
            cx="817.8"
            cy="590.7"
            r="2"/>
          <circle
            cx="817.8"
            cy="622.5"
            r="2"/>
          <circle
            cx="817.8"
            cy="654.3"
            r="2"/>
          <circle
            cx="817.8"
            cy="686.1"
            r="2"/>
        </g>
        <g>
          <circle
            cx="849.5"
            cy="18.6"
            r="2"/>
          <circle
            cx="849.5"
            cy="50.4"
            r="2"/>
          <circle
            cx="849.5"
            cy="82.2"
            r="2"/>
          <circle
            cx="849.5"
            cy="114"
            r="2"/>
          <circle
            cx="849.5"
            cy="145.8"
            r="2"/>
          <circle
            cx="849.5"
            cy="177.6"
            r="2"/>
          <circle
            cx="849.5"
            cy="209.3"
            r="2"/>
          <circle
            cx="849.5"
            cy="241.1"
            r="2"/>
          <circle
            cx="849.5"
            cy="272.9"
            r="2"/>
          <circle
            cx="849.5"
            cy="304.7"
            r="2"/>
          <circle
            cx="849.5"
            cy="336.5"
            r="2"/>
          <circle
            cx="849.5"
            cy="368.2"
            r="2"/>
          <circle
            cx="849.5"
            cy="400"
            r="2"/>
          <circle
            cx="849.5"
            cy="431.8"
            r="2"/>
          <circle
            cx="849.5"
            cy="463.6"
            r="2"/>
          <circle
            cx="849.5"
            cy="495.4"
            r="2"/>
          <circle
            cx="849.5"
            cy="527.1"
            r="2"/>
          <circle
            cx="849.5"
            cy="558.9"
            r="2"/>
          <circle
            cx="849.5"
            cy="590.7"
            r="2"/>
          <circle
            cx="849.5"
            cy="622.5"
            r="2"/>
          <circle
            cx="849.5"
            cy="654.3"
            r="2"/>
          <circle
            cx="849.5"
            cy="686.1"
            r="2"/>
        </g>
        <g>
          <circle
            cx="881.3"
            cy="18.6"
            r="2"/>
          <circle
            cx="881.3"
            cy="50.4"
            r="2"/>
          <circle
            cx="881.3"
            cy="82.2"
            r="2"/>
          <circle
            cx="881.3"
            cy="114"
            r="2"/>
          <circle
            cx="881.3"
            cy="145.8"
            r="2"/>
          <circle
            cx="881.3"
            cy="177.6"
            r="2"/>
          <circle
            cx="881.3"
            cy="209.3"
            r="2"/>
          <circle
            cx="881.3"
            cy="241.1"
            r="2"/>
          <circle
            cx="881.3"
            cy="272.9"
            r="2"/>
          <circle
            cx="881.3"
            cy="304.7"
            r="2"/>
          <circle
            cx="881.3"
            cy="336.5"
            r="2"/>
          <circle
            cx="881.3"
            cy="368.2"
            r="2"/>
          <circle
            cx="881.3"
            cy="400"
            r="2"/>
          <circle
            cx="881.3"
            cy="431.8"
            r="2"/>
          <circle
            cx="881.3"
            cy="463.6"
            r="2"/>
          <circle
            cx="881.3"
            cy="495.4"
            r="2"/>
          <circle
            cx="881.3"
            cy="527.1"
            r="2"/>
          <circle
            cx="881.3"
            cy="558.9"
            r="2"/>
          <circle
            cx="881.3"
            cy="590.7"
            r="2"/>
          <circle
            cx="881.3"
            cy="622.5"
            r="2"/>
          <circle
            cx="881.3"
            cy="654.3"
            r="2"/>
          <circle
            cx="881.3"
            cy="686.1"
            r="2"/>
        </g>
        <g>
          <circle
            cx="913.1"
            cy="18.6"
            r="2"/>
          <circle
            cx="913.1"
            cy="50.4"
            r="2"/>
          <circle
            cx="913.1"
            cy="82.2"
            r="2"/>
          <circle
            cx="913.1"
            cy="114"
            r="2"/>
          <circle
            cx="913.1"
            cy="145.8"
            r="2"/>
          <circle
            cx="913.1"
            cy="177.6"
            r="2"/>
          <circle
            cx="913.1"
            cy="209.3"
            r="2"/>
          <circle
            cx="913.1"
            cy="241.1"
            r="2"/>
          <circle
            cx="913.1"
            cy="272.9"
            r="2"/>
          <circle
            cx="913.1"
            cy="304.7"
            r="2"/>
          <circle
            cx="913.1"
            cy="336.5"
            r="2"/>
          <circle
            cx="913.1"
            cy="368.2"
            r="2"/>
          <circle
            cx="913.1"
            cy="400"
            r="2"/>
          <circle
            cx="913.1"
            cy="431.8"
            r="2"/>
          <circle
            cx="913.1"
            cy="463.6"
            r="2"/>
          <circle
            cx="913.1"
            cy="495.4"
            r="2"/>
          <circle
            cx="913.1"
            cy="527.1"
            r="2"/>
          <circle
            cx="913.1"
            cy="558.9"
            r="2"/>
          <circle
            cx="913.1"
            cy="590.7"
            r="2"/>
          <circle
            cx="913.1"
            cy="622.5"
            r="2"/>
          <circle
            cx="913.1"
            cy="654.3"
            r="2"/>
          <circle
            cx="913.1"
            cy="686.1"
            r="2"/>
        </g>
        <g>
          <circle
            cx="944.9"
            cy="18.6"
            r="2"/>
          <circle
            cx="944.9"
            cy="50.4"
            r="2"/>
          <circle
            cx="944.9"
            cy="82.2"
            r="2"/>
          <circle
            cx="944.9"
            cy="114"
            r="2"/>
          <circle
            cx="944.9"
            cy="145.8"
            r="2"/>
          <circle
            cx="944.9"
            cy="177.6"
            r="2"/>
          <circle
            cx="944.9"
            cy="209.3"
            r="2"/>
          <circle
            cx="944.9"
            cy="241.1"
            r="2"/>
          <circle
            cx="944.9"
            cy="272.9"
            r="2"/>
          <circle
            cx="944.9"
            cy="304.7"
            r="2"/>
          <circle
            cx="944.9"
            cy="336.5"
            r="2"/>
          <circle
            cx="944.9"
            cy="368.2"
            r="2"/>
          <circle
            cx="944.9"
            cy="400"
            r="2"/>
          <circle
            cx="944.9"
            cy="431.8"
            r="2"/>
          <circle
            cx="944.9"
            cy="463.6"
            r="2"/>
          <circle
            cx="944.9"
            cy="495.4"
            r="2"/>
          <circle
            cx="944.9"
            cy="527.1"
            r="2"/>
          <circle
            cx="944.9"
            cy="558.9"
            r="2"/>
          <circle
            cx="944.9"
            cy="590.7"
            r="2"/>
          <circle
            cx="944.9"
            cy="622.5"
            r="2"/>
          <circle
            cx="944.9"
            cy="654.3"
            r="2"/>
          <circle
            cx="944.9"
            cy="686.1"
            r="2"/>
        </g>
        <g>
          <circle
            cx="976.6"
            cy="18.6"
            r="2"/>
          <circle
            cx="976.6"
            cy="50.4"
            r="2"/>
          <circle
            cx="976.6"
            cy="82.2"
            r="2"/>
          <circle
            cx="976.6"
            cy="114"
            r="2"/>
          <circle
            cx="976.6"
            cy="145.8"
            r="2"/>
          <circle
            cx="976.6"
            cy="177.6"
            r="2"/>
          <circle
            cx="976.6"
            cy="209.3"
            r="2"/>
          <circle
            cx="976.6"
            cy="241.1"
            r="2"/>
          <circle
            cx="976.6"
            cy="272.9"
            r="2"/>
          <circle
            cx="976.6"
            cy="304.7"
            r="2"/>
          <circle
            cx="976.6"
            cy="336.5"
            r="2"/>
          <circle
            cx="976.6"
            cy="368.2"
            r="2"/>
          <circle
            cx="976.6"
            cy="400"
            r="2"/>
          <circle
            cx="976.6"
            cy="431.8"
            r="2"/>
          <circle
            cx="976.6"
            cy="463.6"
            r="2"/>
          <circle
            cx="976.6"
            cy="495.4"
            r="2"/>
          <circle
            cx="976.6"
            cy="527.1"
            r="2"/>
          <circle
            cx="976.6"
            cy="558.9"
            r="2"/>
          <circle
            cx="976.6"
            cy="590.7"
            r="2"/>
          <circle
            cx="976.6"
            cy="622.5"
            r="2"/>
          <circle
            cx="976.6"
            cy="654.3"
            r="2"/>
          <circle
            cx="976.6"
            cy="686.1"
            r="2"/>
        </g>
        <g>
          <circle
            cx="1008.4"
            cy="18.6"
            r="2"/>
          <circle
            cx="1008.4"
            cy="50.4"
            r="2"/>
          <circle
            cx="1008.4"
            cy="82.2"
            r="2"/>
          <circle
            cx="1008.4"
            cy="114"
            r="2"/>
          <circle
            cx="1008.4"
            cy="145.8"
            r="2"/>
          <circle
            cx="1008.4"
            cy="177.6"
            r="2"/>
          <circle
            cx="1008.4"
            cy="209.3"
            r="2"/>
          <circle
            cx="1008.4"
            cy="241.1"
            r="2"/>
          <circle
            cx="1008.4"
            cy="272.9"
            r="2"/>
          <circle
            cx="1008.4"
            cy="304.7"
            r="2"/>
          <circle
            cx="1008.4"
            cy="336.5"
            r="2"/>
          <circle
            cx="1008.4"
            cy="368.2"
            r="2"/>
          <circle
            cx="1008.4"
            cy="400"
            r="2"/>
          <circle
            cx="1008.4"
            cy="431.8"
            r="2"/>
          <circle
            cx="1008.4"
            cy="463.6"
            r="2"/>
          <circle
            cx="1008.4"
            cy="495.4"
            r="2"/>
          <circle
            cx="1008.4"
            cy="527.1"
            r="2"/>
          <circle
            cx="1008.4"
            cy="558.9"
            r="2"/>
          <circle
            cx="1008.4"
            cy="590.7"
            r="2"/>
          <circle
            cx="1008.4"
            cy="622.5"
            r="2"/>
          <circle
            cx="1008.4"
            cy="654.3"
            r="2"/>
          <circle
            cx="1008.4"
            cy="686.1"
            r="2"/>
        </g>
        <g>
          <circle
            cx="1040.2"
            cy="18.6"
            r="2"/>
          <circle
            cx="1040.2"
            cy="50.4"
            r="2"/>
          <circle
            cx="1040.2"
            cy="82.2"
            r="2"/>
          <circle
            cx="1040.2"
            cy="114"
            r="2"/>
          <circle
            cx="1040.2"
            cy="145.8"
            r="2"/>
          <circle
            cx="1040.2"
            cy="177.6"
            r="2"/>
          <circle
            cx="1040.2"
            cy="209.3"
            r="2"/>
          <circle
            cx="1040.2"
            cy="241.1"
            r="2"/>
          <circle
            cx="1040.2"
            cy="272.9"
            r="2"/>
          <circle
            cx="1040.2"
            cy="304.7"
            r="2"/>
          <circle
            cx="1040.2"
            cy="336.5"
            r="2"/>
          <circle
            cx="1040.2"
            cy="368.2"
            r="2"/>
          <circle
            cx="1040.2"
            cy="400"
            r="2"/>
          <circle
            cx="1040.2"
            cy="431.8"
            r="2"/>
          <circle
            cx="1040.2"
            cy="463.6"
            r="2"/>
          <circle
            cx="1040.2"
            cy="495.4"
            r="2"/>
          <circle
            cx="1040.2"
            cy="527.1"
            r="2"/>
          <circle
            cx="1040.2"
            cy="558.9"
            r="2"/>
          <circle
            cx="1040.2"
            cy="590.7"
            r="2"/>
          <circle
            cx="1040.2"
            cy="622.5"
            r="2"/>
          <circle
            cx="1040.2"
            cy="654.3"
            r="2"/>
          <circle
            cx="1040.2"
            cy="686.1"
            r="2"/>
        </g>
        <g>
          <circle
            cx="1072"
            cy="18.6"
            r="2"/>
          <circle
            cx="1072"
            cy="50.4"
            r="2"/>
          <circle
            cx="1072"
            cy="82.2"
            r="2"/>
          <circle
            cx="1072"
            cy="114"
            r="2"/>
          <circle
            cx="1072"
            cy="145.8"
            r="2"/>
          <circle
            cx="1072"
            cy="177.6"
            r="2"/>
          <circle
            cx="1072"
            cy="209.3"
            r="2"/>
          <circle
            cx="1072"
            cy="241.1"
            r="2"/>
          <circle
            cx="1072"
            cy="272.9"
            r="2"/>
          <circle
            cx="1072"
            cy="304.7"
            r="2"/>
          <circle
            cx="1072"
            cy="336.5"
            r="2"/>
          <circle
            cx="1072"
            cy="368.2"
            r="2"/>
          <circle
            cx="1072"
            cy="400"
            r="2"/>
          <circle
            cx="1072"
            cy="431.8"
            r="2"/>
          <circle
            cx="1072"
            cy="463.6"
            r="2"/>
          <circle
            cx="1072"
            cy="495.4"
            r="2"/>
          <circle
            cx="1072"
            cy="527.1"
            r="2"/>
          <circle
            cx="1072"
            cy="558.9"
            r="2"/>
          <circle
            cx="1072"
            cy="590.7"
            r="2"/>
          <circle
            cx="1072"
            cy="622.5"
            r="2"/>
          <circle
            cx="1072"
            cy="654.3"
            r="2"/>
          <circle
            cx="1072"
            cy="686.1"
            r="2"/>
        </g>
        <g>
          <circle
            cx="1103.7"
            cy="18.6"
            r="2"/>
          <circle
            cx="1103.7"
            cy="50.4"
            r="2"/>
          <circle
            cx="1103.7"
            cy="82.2"
            r="2"/>
          <circle
            cx="1103.7"
            cy="114"
            r="2"/>
          <circle
            cx="1103.7"
            cy="145.8"
            r="2"/>
          <circle
            cx="1103.7"
            cy="177.6"
            r="2"/>
          <circle
            cx="1103.7"
            cy="209.3"
            r="2"/>
          <circle
            cx="1103.7"
            cy="241.1"
            r="2"/>
          <circle
            cx="1103.7"
            cy="272.9"
            r="2"/>
          <circle
            cx="1103.7"
            cy="304.7"
            r="2"/>
          <circle
            cx="1103.7"
            cy="336.5"
            r="2"/>
          <circle
            cx="1103.7"
            cy="368.2"
            r="2"/>
          <circle
            cx="1103.7"
            cy="400"
            r="2"/>
          <circle
            cx="1103.7"
            cy="431.8"
            r="2"/>
          <circle
            cx="1103.7"
            cy="463.6"
            r="2"/>
          <circle
            cx="1103.7"
            cy="495.4"
            r="2"/>
          <circle
            cx="1103.7"
            cy="527.1"
            r="2"/>
          <circle
            cx="1103.7"
            cy="558.9"
            r="2"/>
          <circle
            cx="1103.7"
            cy="590.7"
            r="2"/>
          <circle
            cx="1103.7"
            cy="622.5"
            r="2"/>
          <circle
            cx="1103.7"
            cy="654.3"
            r="2"/>
          <circle
            cx="1103.7"
            cy="686.1"
            r="2"/>
        </g>
        <g>
          <circle
            cx="1135.5"
            cy="18.6"
            r="2"/>
          <circle
            cx="1135.5"
            cy="50.4"
            r="2"/>
          <circle
            cx="1135.5"
            cy="82.2"
            r="2"/>
          <circle
            cx="1135.5"
            cy="114"
            r="2"/>
          <circle
            cx="1135.5"
            cy="145.8"
            r="2"/>
          <circle
            cx="1135.5"
            cy="177.6"
            r="2"/>
          <circle
            cx="1135.5"
            cy="209.3"
            r="2"/>
          <circle
            cx="1135.5"
            cy="241.1"
            r="2"/>
          <circle
            cx="1135.5"
            cy="272.9"
            r="2"/>
          <circle
            cx="1135.5"
            cy="304.7"
            r="2"/>
          <circle
            cx="1135.5"
            cy="336.5"
            r="2"/>
          <circle
            cx="1135.5"
            cy="368.2"
            r="2"/>
          <circle
            cx="1135.5"
            cy="400"
            r="2"/>
          <circle
            cx="1135.5"
            cy="431.8"
            r="2"/>
          <circle
            cx="1135.5"
            cy="463.6"
            r="2"/>
          <circle
            cx="1135.5"
            cy="495.4"
            r="2"/>
          <circle
            cx="1135.5"
            cy="527.1"
            r="2"/>
          <circle
            cx="1135.5"
            cy="558.9"
            r="2"/>
          <circle
            cx="1135.5"
            cy="590.7"
            r="2"/>
          <circle
            cx="1135.5"
            cy="622.5"
            r="2"/>
          <circle
            cx="1135.5"
            cy="654.3"
            r="2"/>
          <circle
            cx="1135.5"
            cy="686.1"
            r="2"/>
        </g>
        <g>
          <circle
            cx="1167.3"
            cy="18.6"
            r="2"/>
          <circle
            cx="1167.3"
            cy="50.4"
            r="2"/>
          <circle
            cx="1167.3"
            cy="82.2"
            r="2"/>
          <circle
            cx="1167.3"
            cy="114"
            r="2"/>
          <circle
            cx="1167.3"
            cy="145.8"
            r="2"/>
          <circle
            cx="1167.3"
            cy="177.6"
            r="2"/>
          <circle
            cx="1167.3"
            cy="209.3"
            r="2"/>
          <circle
            cx="1167.3"
            cy="241.1"
            r="2"/>
          <circle
            cx="1167.3"
            cy="272.9"
            r="2"/>
          <circle
            cx="1167.3"
            cy="304.7"
            r="2"/>
          <circle
            cx="1167.3"
            cy="336.5"
            r="2"/>
          <circle
            cx="1167.3"
            cy="368.2"
            r="2"/>
          <circle
            cx="1167.3"
            cy="400"
            r="2"/>
          <circle
            cx="1167.3"
            cy="431.8"
            r="2"/>
          <circle
            cx="1167.3"
            cy="463.6"
            r="2"/>
          <circle
            cx="1167.3"
            cy="495.4"
            r="2"/>
          <circle
            cx="1167.3"
            cy="527.1"
            r="2"/>
          <circle
            cx="1167.3"
            cy="558.9"
            r="2"/>
          <circle
            cx="1167.3"
            cy="590.7"
            r="2"/>
          <circle
            cx="1167.3"
            cy="622.5"
            r="2"/>
          <circle
            cx="1167.3"
            cy="654.3"
            r="2"/>
          <circle
            cx="1167.3"
            cy="686.1"
            r="2"/>
        </g>
        <g>
          <circle
            cx="1199.1"
            cy="18.6"
            r="2"/>
          <circle
            cx="1199.1"
            cy="50.4"
            r="2"/>
          <circle
            cx="1199.1"
            cy="82.2"
            r="2"/>
          <circle
            cx="1199.1"
            cy="114"
            r="2"/>
          <circle
            cx="1199.1"
            cy="145.8"
            r="2"/>
          <circle
            cx="1199.1"
            cy="177.6"
            r="2"/>
          <circle
            cx="1199.1"
            cy="209.3"
            r="2"/>
          <circle
            cx="1199.1"
            cy="241.1"
            r="2"/>
          <circle
            cx="1199.1"
            cy="272.9"
            r="2"/>
          <circle
            cx="1199.1"
            cy="304.7"
            r="2"/>
          <circle
            cx="1199.1"
            cy="336.5"
            r="2"/>
          <circle
            cx="1199.1"
            cy="368.2"
            r="2"/>
          <circle
            cx="1199.1"
            cy="400"
            r="2"/>
          <circle
            cx="1199.1"
            cy="431.8"
            r="2"/>
          <circle
            cx="1199.1"
            cy="463.6"
            r="2"/>
          <circle
            cx="1199.1"
            cy="495.4"
            r="2"/>
          <circle
            cx="1199.1"
            cy="527.1"
            r="2"/>
          <circle
            cx="1199.1"
            cy="558.9"
            r="2"/>
          <circle
            cx="1199.1"
            cy="590.7"
            r="2"/>
          <circle
            cx="1199.1"
            cy="622.5"
            r="2"/>
          <circle
            cx="1199.1"
            cy="654.3"
            r="2"/>
          <circle
            cx="1199.1"
            cy="686.1"
            r="2"/>
        </g>
        <g>
          <circle
            cx="1230.8"
            cy="18.6"
            r="2"/>
          <circle
            cx="1230.8"
            cy="50.4"
            r="2"/>
          <circle
            cx="1230.8"
            cy="82.2"
            r="2"/>
          <circle
            cx="1230.8"
            cy="114"
            r="2"/>
          <circle
            cx="1230.8"
            cy="145.8"
            r="2"/>
          <circle
            cx="1230.8"
            cy="177.6"
            r="2"/>
          <circle
            cx="1230.8"
            cy="209.3"
            r="2"/>
          <circle
            cx="1230.8"
            cy="241.1"
            r="2"/>
          <circle
            cx="1230.8"
            cy="272.9"
            r="2"/>
          <circle
            cx="1230.8"
            cy="304.7"
            r="2"/>
          <circle
            cx="1230.8"
            cy="336.5"
            r="2"/>
          <circle
            cx="1230.8"
            cy="368.2"
            r="2"/>
          <circle
            cx="1230.8"
            cy="400"
            r="2"/>
          <circle
            cx="1230.8"
            cy="431.8"
            r="2"/>
          <circle
            cx="1230.8"
            cy="463.6"
            r="2"/>
          <circle
            cx="1230.8"
            cy="495.4"
            r="2"/>
          <circle
            cx="1230.8"
            cy="527.1"
            r="2"/>
          <circle
            cx="1230.8"
            cy="558.9"
            r="2"/>
          <circle
            cx="1230.8"
            cy="590.7"
            r="2"/>
          <circle
            cx="1230.8"
            cy="622.5"
            r="2"/>
          <circle
            cx="1230.8"
            cy="654.3"
            r="2"/>
          <circle
            cx="1230.8"
            cy="686.1"
            r="2"/>
        </g>
        <g>
          <circle
            cx="1262.6"
            cy="18.6"
            r="2"/>
          <circle
            cx="1262.6"
            cy="50.4"
            r="2"/>
          <circle
            cx="1262.6"
            cy="82.2"
            r="2"/>
          <circle
            cx="1262.6"
            cy="114"
            r="2"/>
          <circle
            cx="1262.6"
            cy="145.8"
            r="2"/>
          <circle
            cx="1262.6"
            cy="177.6"
            r="2"/>
          <circle
            cx="1262.6"
            cy="209.3"
            r="2"/>
          <circle
            cx="1262.6"
            cy="241.1"
            r="2"/>
          <circle
            cx="1262.6"
            cy="272.9"
            r="2"/>
          <circle
            cx="1262.6"
            cy="304.7"
            r="2"/>
          <circle
            cx="1262.6"
            cy="336.5"
            r="2"/>
          <circle
            cx="1262.6"
            cy="368.2"
            r="2"/>
          <circle
            cx="1262.6"
            cy="400"
            r="2"/>
          <circle
            cx="1262.6"
            cy="431.8"
            r="2"/>
          <circle
            cx="1262.6"
            cy="463.6"
            r="2"/>
          <circle
            cx="1262.6"
            cy="495.4"
            r="2"/>
          <circle
            cx="1262.6"
            cy="527.1"
            r="2"/>
          <circle
            cx="1262.6"
            cy="558.9"
            r="2"/>
          <circle
            cx="1262.6"
            cy="590.7"
            r="2"/>
          <circle
            cx="1262.6"
            cy="622.5"
            r="2"/>
          <circle
            cx="1262.6"
            cy="654.3"
            r="2"/>
          <circle
            cx="1262.6"
            cy="686.1"
            r="2"/>
        </g>
        <g>
          <circle
            cx="1294.4"
            cy="18.6"
            r="2"/>
          <circle
            cx="1294.4"
            cy="50.4"
            r="2"/>
          <circle
            cx="1294.4"
            cy="82.2"
            r="2"/>
          <circle
            cx="1294.4"
            cy="114"
            r="2"/>
          <circle
            cx="1294.4"
            cy="145.8"
            r="2"/>
          <circle
            cx="1294.4"
            cy="177.6"
            r="2"/>
          <circle
            cx="1294.4"
            cy="209.3"
            r="2"/>
          <circle
            cx="1294.4"
            cy="241.1"
            r="2"/>
          <circle
            cx="1294.4"
            cy="272.9"
            r="2"/>
          <circle
            cx="1294.4"
            cy="304.7"
            r="2"/>
          <circle
            cx="1294.4"
            cy="336.5"
            r="2"/>
          <circle
            cx="1294.4"
            cy="368.2"
            r="2"/>
          <circle
            cx="1294.4"
            cy="400"
            r="2"/>
          <circle
            cx="1294.4"
            cy="431.8"
            r="2"/>
          <circle
            cx="1294.4"
            cy="463.6"
            r="2"/>
          <circle
            cx="1294.4"
            cy="495.4"
            r="2"/>
          <circle
            cx="1294.4"
            cy="527.1"
            r="2"/>
          <circle
            cx="1294.4"
            cy="558.9"
            r="2"/>
          <circle
            cx="1294.4"
            cy="590.7"
            r="2"/>
          <circle
            cx="1294.4"
            cy="622.5"
            r="2"/>
          <circle
            cx="1294.4"
            cy="654.3"
            r="2"/>
          <circle
            cx="1294.4"
            cy="686.1"
            r="2"/>
        </g>
        <g>
          <circle
            cx="1326.2"
            cy="18.6"
            r="2"/>
          <circle
            cx="1326.2"
            cy="50.4"
            r="2"/>
          <circle
            cx="1326.2"
            cy="82.2"
            r="2"/>
          <circle
            cx="1326.2"
            cy="114"
            r="2"/>
          <circle
            cx="1326.2"
            cy="145.8"
            r="2"/>
          <circle
            cx="1326.2"
            cy="177.6"
            r="2"/>
          <circle
            cx="1326.2"
            cy="209.3"
            r="2"/>
          <circle
            cx="1326.2"
            cy="241.1"
            r="2"/>
          <circle
            cx="1326.2"
            cy="272.9"
            r="2"/>
          <circle
            cx="1326.2"
            cy="304.7"
            r="2"/>
          <circle
            cx="1326.2"
            cy="336.5"
            r="2"/>
          <circle
            cx="1326.2"
            cy="368.2"
            r="2"/>
          <circle
            cx="1326.2"
            cy="400"
            r="2"/>
          <circle
            cx="1326.2"
            cy="431.8"
            r="2"/>
          <circle
            cx="1326.2"
            cy="463.6"
            r="2"/>
          <circle
            cx="1326.2"
            cy="495.4"
            r="2"/>
          <circle
            cx="1326.2"
            cy="527.1"
            r="2"/>
          <circle
            cx="1326.2"
            cy="558.9"
            r="2"/>
          <circle
            cx="1326.2"
            cy="590.7"
            r="2"/>
          <circle
            cx="1326.2"
            cy="622.5"
            r="2"/>
          <circle
            cx="1326.2"
            cy="654.3"
            r="2"/>
          <circle
            cx="1326.2"
            cy="686.1"
            r="2"/>
        </g>
        <g>
          <circle
            cx="1357.9"
            cy="18.6"
            r="2"/>
          <circle
            cx="1357.9"
            cy="50.4"
            r="2"/>
          <circle
            cx="1357.9"
            cy="82.2"
            r="2"/>
          <circle
            cx="1357.9"
            cy="114"
            r="2"/>
          <circle
            cx="1357.9"
            cy="145.8"
            r="2"/>
          <circle
            cx="1357.9"
            cy="177.6"
            r="2"/>
          <circle
            cx="1357.9"
            cy="209.3"
            r="2"/>
          <circle
            cx="1357.9"
            cy="241.1"
            r="2"/>
          <circle
            cx="1357.9"
            cy="272.9"
            r="2"/>
          <circle
            cx="1357.9"
            cy="304.7"
            r="2"/>
          <circle
            cx="1357.9"
            cy="336.5"
            r="2"/>
          <circle
            cx="1357.9"
            cy="368.2"
            r="2"/>
          <circle
            cx="1357.9"
            cy="400"
            r="2"/>
          <circle
            cx="1357.9"
            cy="431.8"
            r="2"/>
          <circle
            cx="1357.9"
            cy="463.6"
            r="2"/>
          <circle
            cx="1357.9"
            cy="495.4"
            r="2"/>
          <circle
            cx="1357.9"
            cy="527.1"
            r="2"/>
          <circle
            cx="1357.9"
            cy="558.9"
            r="2"/>
          <circle
            cx="1357.9"
            cy="590.7"
            r="2"/>
          <circle
            cx="1357.9"
            cy="622.5"
            r="2"/>
          <circle
            cx="1357.9"
            cy="654.3"
            r="2"/>
          <circle
            cx="1357.9"
            cy="686.1"
            r="2"/>
        </g>
        <g>
          <circle
            cx="1389.7"
            cy="18.6"
            r="2"/>
          <circle
            cx="1389.7"
            cy="50.4"
            r="2"/>
          <circle
            cx="1389.7"
            cy="82.2"
            r="2"/>
          <circle
            cx="1389.7"
            cy="114"
            r="2"/>
          <circle
            cx="1389.7"
            cy="145.8"
            r="2"/>
          <circle
            cx="1389.7"
            cy="177.6"
            r="2"/>
          <circle
            cx="1389.7"
            cy="209.3"
            r="2"/>
          <circle
            cx="1389.7"
            cy="241.1"
            r="2"/>
          <circle
            cx="1389.7"
            cy="272.9"
            r="2"/>
          <circle
            cx="1389.7"
            cy="304.7"
            r="2"/>
          <circle
            cx="1389.7"
            cy="336.5"
            r="2"/>
          <circle
            cx="1389.7"
            cy="368.2"
            r="2"/>
          <circle
            cx="1389.7"
            cy="400"
            r="2"/>
          <circle
            cx="1389.7"
            cy="431.8"
            r="2"/>
          <circle
            cx="1389.7"
            cy="463.6"
            r="2"/>
          <circle
            cx="1389.7"
            cy="495.4"
            r="2"/>
          <circle
            cx="1389.7"
            cy="527.1"
            r="2"/>
          <circle
            cx="1389.7"
            cy="558.9"
            r="2"/>
          <circle
            cx="1389.7"
            cy="590.7"
            r="2"/>
          <circle
            cx="1389.7"
            cy="622.5"
            r="2"/>
          <circle
            cx="1389.7"
            cy="654.3"
            r="2"/>
          <circle
            cx="1389.7"
            cy="686.1"
            r="2"/>
        </g>
        <g>
          <circle
            cx="1421.5"
            cy="18.6"
            r="2"/>
          <circle
            cx="1421.5"
            cy="50.4"
            r="2"/>
          <circle
            cx="1421.5"
            cy="82.2"
            r="2"/>
          <circle
            cx="1421.5"
            cy="114"
            r="2"/>
          <circle
            cx="1421.5"
            cy="145.8"
            r="2"/>
          <circle
            cx="1421.5"
            cy="177.6"
            r="2"/>
          <circle
            cx="1421.5"
            cy="209.3"
            r="2"/>
          <circle
            cx="1421.5"
            cy="241.1"
            r="2"/>
          <circle
            cx="1421.5"
            cy="272.9"
            r="2"/>
          <circle
            cx="1421.5"
            cy="304.7"
            r="2"/>
          <circle
            cx="1421.5"
            cy="336.5"
            r="2"/>
          <circle
            cx="1421.5"
            cy="368.2"
            r="2"/>
          <circle
            cx="1421.5"
            cy="400"
            r="2"/>
          <circle
            cx="1421.5"
            cy="431.8"
            r="2"/>
          <circle
            cx="1421.5"
            cy="463.6"
            r="2"/>
          <circle
            cx="1421.5"
            cy="495.4"
            r="2"/>
          <circle
            cx="1421.5"
            cy="527.1"
            r="2"/>
          <circle
            cx="1421.5"
            cy="558.9"
            r="2"/>
          <circle
            cx="1421.5"
            cy="590.7"
            r="2"/>
          <circle
            cx="1421.5"
            cy="622.5"
            r="2"/>
          <circle
            cx="1421.5"
            cy="654.3"
            r="2"/>
          <circle
            cx="1421.5"
            cy="686.1"
            r="2"/>
        </g>
        <g>
          <circle
            cx="1453.3"
            cy="18.6"
            r="2"/>
          <circle
            cx="1453.3"
            cy="50.4"
            r="2"/>
          <circle
            cx="1453.3"
            cy="82.2"
            r="2"/>
          <circle
            cx="1453.3"
            cy="114"
            r="2"/>
          <circle
            cx="1453.3"
            cy="145.8"
            r="2"/>
          <circle
            cx="1453.3"
            cy="177.6"
            r="2"/>
          <circle
            cx="1453.3"
            cy="209.3"
            r="2"/>
          <circle
            cx="1453.3"
            cy="241.1"
            r="2"/>
          <circle
            cx="1453.3"
            cy="272.9"
            r="2"/>
          <circle
            cx="1453.3"
            cy="304.7"
            r="2"/>
          <circle
            cx="1453.3"
            cy="336.5"
            r="2"/>
          <circle
            cx="1453.3"
            cy="368.2"
            r="2"/>
          <circle
            cx="1453.3"
            cy="400"
            r="2"/>
          <circle
            cx="1453.3"
            cy="431.8"
            r="2"/>
          <circle
            cx="1453.3"
            cy="463.6"
            r="2"/>
          <circle
            cx="1453.3"
            cy="495.4"
            r="2"/>
          <circle
            cx="1453.3"
            cy="527.1"
            r="2"/>
          <circle
            cx="1453.3"
            cy="558.9"
            r="2"/>
          <circle
            cx="1453.3"
            cy="590.7"
            r="2"/>
          <circle
            cx="1453.3"
            cy="622.5"
            r="2"/>
          <circle
            cx="1453.3"
            cy="654.3"
            r="2"/>
          <circle
            cx="1453.3"
            cy="686.1"
            r="2"/>
        </g>
        <g>
          <circle
            cx="1485"
            cy="18.6"
            r="2"/>
          <circle
            cx="1485"
            cy="50.4"
            r="2"/>
          <circle
            cx="1485"
            cy="82.2"
            r="2"/>
          <circle
            cx="1485"
            cy="114"
            r="2"/>
          <circle
            cx="1485"
            cy="145.8"
            r="2"/>
          <circle
            cx="1485"
            cy="177.6"
            r="2"/>
          <circle
            cx="1485"
            cy="209.3"
            r="2"/>
          <circle
            cx="1485"
            cy="241.1"
            r="2"/>
          <circle
            cx="1485"
            cy="272.9"
            r="2"/>
          <circle
            cx="1485"
            cy="304.7"
            r="2"/>
          <circle
            cx="1485"
            cy="336.5"
            r="2"/>
          <circle
            cx="1485"
            cy="368.2"
            r="2"/>
          <circle
            cx="1485"
            cy="400"
            r="2"/>
          <circle
            cx="1485"
            cy="431.8"
            r="2"/>
          <circle
            cx="1485"
            cy="463.6"
            r="2"/>
          <circle
            cx="1485"
            cy="495.4"
            r="2"/>
          <circle
            cx="1485"
            cy="527.1"
            r="2"/>
          <circle
            cx="1485"
            cy="558.9"
            r="2"/>
          <circle
            cx="1485"
            cy="590.7"
            r="2"/>
          <circle
            cx="1485"
            cy="622.5"
            r="2"/>
          <circle
            cx="1485"
            cy="654.3"
            r="2"/>
          <circle
            cx="1485"
            cy="686.1"
            r="2"/>
        </g>
        <g>
          <circle
            cx="1516.8"
            cy="18.6"
            r="2"/>
          <circle
            cx="1516.8"
            cy="50.4"
            r="2"/>
          <circle
            cx="1516.8"
            cy="82.2"
            r="2"/>
          <circle
            cx="1516.8"
            cy="114"
            r="2"/>
          <circle
            cx="1516.8"
            cy="145.8"
            r="2"/>
          <circle
            cx="1516.8"
            cy="177.6"
            r="2"/>
          <circle
            cx="1516.8"
            cy="209.3"
            r="2"/>
          <circle
            cx="1516.8"
            cy="241.1"
            r="2"/>
          <circle
            cx="1516.8"
            cy="272.9"
            r="2"/>
          <circle
            cx="1516.8"
            cy="304.7"
            r="2"/>
          <circle
            cx="1516.8"
            cy="336.5"
            r="2"/>
          <circle
            cx="1516.8"
            cy="368.2"
            r="2"/>
          <circle
            cx="1516.8"
            cy="400"
            r="2"/>
          <circle
            cx="1516.8"
            cy="431.8"
            r="2"/>
          <circle
            cx="1516.8"
            cy="463.6"
            r="2"/>
          <circle
            cx="1516.8"
            cy="495.4"
            r="2"/>
          <circle
            cx="1516.8"
            cy="527.1"
            r="2"/>
          <circle
            cx="1516.8"
            cy="558.9"
            r="2"/>
          <circle
            cx="1516.8"
            cy="590.7"
            r="2"/>
          <circle
            cx="1516.8"
            cy="622.5"
            r="2"/>
          <circle
            cx="1516.8"
            cy="654.3"
            r="2"/>
          <circle
            cx="1516.8"
            cy="686.1"
            r="2"/>
        </g>
        <g>
          <circle
            cx="1548.6"
            cy="18.6"
            r="2"/>
          <circle
            cx="1548.6"
            cy="50.4"
            r="2"/>
          <circle
            cx="1548.6"
            cy="82.2"
            r="2"/>
          <circle
            cx="1548.6"
            cy="114"
            r="2"/>
          <circle
            cx="1548.6"
            cy="145.8"
            r="2"/>
          <circle
            cx="1548.6"
            cy="177.6"
            r="2"/>
          <circle
            cx="1548.6"
            cy="209.3"
            r="2"/>
          <circle
            cx="1548.6"
            cy="241.1"
            r="2"/>
          <circle
            cx="1548.6"
            cy="272.9"
            r="2"/>
          <circle
            cx="1548.6"
            cy="304.7"
            r="2"/>
          <circle
            cx="1548.6"
            cy="336.5"
            r="2"/>
          <circle
            cx="1548.6"
            cy="368.2"
            r="2"/>
          <circle
            cx="1548.6"
            cy="400"
            r="2"/>
          <circle
            cx="1548.6"
            cy="431.8"
            r="2"/>
          <circle
            cx="1548.6"
            cy="463.6"
            r="2"/>
          <circle
            cx="1548.6"
            cy="495.4"
            r="2"/>
          <circle
            cx="1548.6"
            cy="527.1"
            r="2"/>
          <circle
            cx="1548.6"
            cy="558.9"
            r="2"/>
          <circle
            cx="1548.6"
            cy="590.7"
            r="2"/>
          <circle
            cx="1548.6"
            cy="622.5"
            r="2"/>
          <circle
            cx="1548.6"
            cy="654.3"
            r="2"/>
          <circle
            cx="1548.6"
            cy="686.1"
            r="2"/>
        </g>
        <g>
          <circle
            cx="690.7"
            cy="18.6"
            r="2"/>
          <circle
            cx="690.7"
            cy="50.4"
            r="2"/>
          <circle
            cx="690.7"
            cy="82.2"
            r="2"/>
          <circle
            cx="690.7"
            cy="114"
            r="2"/>
          <circle
            cx="690.7"
            cy="145.8"
            r="2"/>
          <circle
            cx="690.7"
            cy="177.6"
            r="2"/>
          <circle
            cx="690.7"
            cy="209.3"
            r="2"/>
          <circle
            cx="690.7"
            cy="241.1"
            r="2"/>
          <circle
            cx="690.7"
            cy="272.9"
            r="2"/>
          <circle
            cx="690.7"
            cy="304.7"
            r="2"/>
          <circle
            cx="690.7"
            cy="336.5"
            r="2"/>
          <circle
            cx="690.7"
            cy="368.2"
            r="2"/>
          <circle
            cx="690.7"
            cy="400"
            r="2"/>
          <circle
            cx="690.7"
            cy="431.8"
            r="2"/>
          <circle
            cx="690.7"
            cy="463.6"
            r="2"/>
          <circle
            cx="690.7"
            cy="495.4"
            r="2"/>
          <circle
            cx="690.7"
            cy="527.1"
            r="2"/>
          <circle
            cx="690.7"
            cy="558.9"
            r="2"/>
          <circle
            style={{ opacity: 0.75 }}
            cx="690.7"
            cy="590.7"
            r="2"/>
          <circle
            style={{ opacity: 0.5 }}
            cx="690.7"
            cy="622.5"
            r="2"/>
          <circle
            style={{ opacity: 0.25 }}
            cx="690.7"
            cy="654.3"
            r="2"/>
          <circle
            style={{ opacity: 7.000000e-02 }}
            cx="690.7"
            cy="686.1"
            r="2"/>
        </g>
        <g>
          <circle
            cx="658.9"
            cy="18.6"
            r="2"/>
          <circle
            cx="658.9"
            cy="50.4"
            r="2"/>
          <circle
            cx="658.9"
            cy="82.2"
            r="2"/>
          <circle
            cx="658.9"
            cy="114"
            r="2"/>
          <circle
            cx="658.9"
            cy="145.8"
            r="2"/>
          <circle
            cx="658.9"
            cy="177.6"
            r="2"/>
          <circle
            cx="658.9"
            cy="209.3"
            r="2"/>
          <circle
            cx="658.9"
            cy="241.1"
            r="2"/>
          <circle
            cx="658.9"
            cy="272.9"
            r="2"/>
          <circle
            cx="658.9"
            cy="304.7"
            r="2"/>
          <circle
            cx="658.9"
            cy="336.5"
            r="2"/>
          <circle
            cx="658.9"
            cy="368.2"
            r="2"/>
          <circle
            cx="658.9"
            cy="400"
            r="2"/>
          <circle
            cx="658.9"
            cy="431.8"
            r="2"/>
          <circle
            cx="658.9"
            cy="463.6"
            r="2"/>
          <circle
            cx="658.9"
            cy="495.4"
            r="2"/>
          <circle
            cx="658.9"
            cy="527.1"
            r="2"/>
          <circle
            style={{ opacity: 0.8 }}
            cx="658.9"
            cy="558.9"
            r="2"/>
          <circle
            style={{ opacity: 0.5 }}
            cx="658.9"
            cy="590.7"
            r="2"/>
          <circle
            style={{ opacity: 0.25 }}
            cx="658.9"
            cy="622.5"
            r="2"/>
          <circle
            style={{ opacity: 7.000000e-02 }}
            cx="658.9"
            cy="654.3"
            r="2"/>
          <circle
            style={{ opacity: 3.000000e-02 }}
            cx="658.9"
            cy="686.1"
            r="2"/>
        </g>
        <g>
          <circle
            cx="627.1"
            cy="18.6"
            r="2"/>
          <circle
            cx="627.1"
            cy="50.4"
            r="2"/>
          <circle
            cx="627.1"
            cy="82.2"
            r="2"/>
          <circle
            cx="627.1"
            cy="114"
            r="2"/>
          <circle
            cx="627.1"
            cy="145.8"
            r="2"/>
          <circle
            cx="627.1"
            cy="177.6"
            r="2"/>
          <circle
            cx="627.1"
            cy="209.3"
            r="2"/>
          <circle
            cx="627.1"
            cy="241.1"
            r="2"/>
          <circle
            cx="627.1"
            cy="272.9"
            r="2"/>
          <circle
            cx="627.1"
            cy="304.7"
            r="2"/>
          <circle
            cx="627.1"
            cy="336.5"
            r="2"/>
          <circle
            cx="627.1"
            cy="368.2"
            r="2"/>
          <circle
            cx="627.1"
            cy="400"
            r="2"/>
          <circle
            cx="627.1"
            cy="431.8"
            r="2"/>
          <circle
            cx="627.1"
            cy="463.6"
            r="2"/>
          <circle
            cx="627.1"
            cy="495.4"
            r="2"/>
          <circle
            cx="627.1"
            cy="527.1"
            r="2"/>
          <circle
            style={{ opacity: 0.5 }}
            cx="627.1"
            cy="558.9"
            r="2"/>
          <circle
            style={{ opacity: 0.25 }}
            cx="627.1"
            cy="590.7"
            r="2"/>
          <circle
            style={{ opacity: 7.000000e-02 }}
            cx="627.1"
            cy="622.5"
            r="2"/>
          <circle
            style={{ opacity: 3.000000e-02 }}
            cx="627.1"
            cy="654.3"
            r="2"/>
          <circle
            style={{ opacity: 1.000000e-02 }}
            cx="627.1"
            cy="686.1"
            r="2"/>
        </g>
        <g>
          <circle
            cx="595.3"
            cy="18.6"
            r="2"/>
          <circle
            cx="595.3"
            cy="50.4"
            r="2"/>
          <circle
            cx="595.3"
            cy="82.2"
            r="2"/>
          <circle
            cx="595.3"
            cy="114"
            r="2"/>
          <circle
            cx="595.3"
            cy="145.8"
            r="2"/>
          <circle
            cx="595.3"
            cy="177.6"
            r="2"/>
          <circle
            cx="595.3"
            cy="209.3"
            r="2"/>
          <circle
            cx="595.3"
            cy="241.1"
            r="2"/>
          <circle
            cx="595.3"
            cy="272.9"
            r="2"/>
          <circle
            cx="595.3"
            cy="304.7"
            r="2"/>
          <circle
            cx="595.3"
            cy="336.5"
            r="2"/>
          <circle
            cx="595.3"
            cy="368.2"
            r="2"/>
          <circle
            cx="595.3"
            cy="400"
            r="2"/>
          <circle
            cx="595.3"
            cy="431.8"
            r="2"/>
          <circle
            cx="595.3"
            cy="463.6"
            r="2"/>
          <circle
            cx="595.3"
            cy="495.4"
            r="2"/>
          <circle
            style={{ opacity: 0.5 }}
            cx="595.3"
            cy="527.1"
            r="2"/>
          <circle
            style={{ opacity: 0.25 }}
            cx="595.3"
            cy="558.9"
            r="2"/>
          <circle
            style={{ opacity: 7.000000e-02 }}
            cx="595.3"
            cy="590.7"
            r="2"/>
          <circle
            style={{ opacity: 3.000000e-02 }}
            cx="595.3"
            cy="622.5"
            r="2"/>
          <circle
            style={{ opacity: 1.000000e-02 }}
            cx="595.3"
            cy="654.3"
            r="2"/>
        </g>
        <g>
          <circle
            cx="563.6"
            cy="18.6"
            r="2"/>
          <circle
            cx="563.6"
            cy="50.4"
            r="2"/>
          <circle
            cx="563.6"
            cy="82.2"
            r="2"/>
          <circle
            cx="563.6"
            cy="114"
            r="2"/>
          <circle
            cx="563.6"
            cy="145.8"
            r="2"/>
          <circle
            cx="563.6"
            cy="177.6"
            r="2"/>
          <circle
            cx="563.6"
            cy="209.3"
            r="2"/>
          <circle
            cx="563.6"
            cy="241.1"
            r="2"/>
          <circle
            cx="563.6"
            cy="272.9"
            r="2"/>
          <circle
            cx="563.6"
            cy="304.7"
            r="2"/>
          <circle
            cx="563.6"
            cy="336.5"
            r="2"/>
          <circle
            cx="563.6"
            cy="368.2"
            r="2"/>
          <circle
            cx="563.6"
            cy="400"
            r="2"/>
          <circle
            cx="563.6"
            cy="431.8"
            r="2"/>
          <circle
            cx="563.6"
            cy="463.6"
            r="2"/>
          <circle
            style={{ opacity: 0.5 }}
            cx="563.6"
            cy="495.4"
            r="2"/>
          <circle
            style={{ opacity: 0.25 }}
            cx="563.6"
            cy="527.1"
            r="2"/>
          <circle
            style={{ opacity: 7.000000e-02 }}
            cx="563.6"
            cy="558.9"
            r="2"/>
          <circle
            style={{ opacity: 3.000000e-02 }}
            cx="563.6"
            cy="590.7"
            r="2"/>
          <circle
            style={{ opacity: 1.000000e-02 }}
            cx="563.6"
            cy="622.5"
            r="2"/>
        </g>
        <g>
          <circle
            cx="531.8"
            cy="18.6"
            r="2"/>
          <circle
            cx="531.8"
            cy="50.4"
            r="2"/>
          <circle
            cx="531.8"
            cy="82.2"
            r="2"/>
          <circle
            cx="531.8"
            cy="114"
            r="2"/>
          <circle
            cx="531.8"
            cy="145.8"
            r="2"/>
          <circle
            cx="531.8"
            cy="177.6"
            r="2"/>
          <circle
            cx="531.8"
            cy="209.3"
            r="2"/>
          <circle
            cx="531.8"
            cy="241.1"
            r="2"/>
          <circle
            cx="531.8"
            cy="272.9"
            r="2"/>
          <circle
            cx="531.8"
            cy="304.7"
            r="2"/>
          <circle
            cx="531.8"
            cy="336.5"
            r="2"/>
          <circle
            cx="531.8"
            cy="368.2"
            r="2"/>
          <circle
            cx="531.8"
            cy="400"
            r="2"/>
          <circle
            cx="531.8"
            cy="431.8"
            r="2"/>
          <circle
            style={{ opacity: 0.5 }}
            cx="531.8"
            cy="463.6"
            r="2"/>
          <circle
            style={{ opacity: 0.25 }}
            cx="531.8"
            cy="495.4"
            r="2"/>
          <circle
            style={{ opacity: 7.000000e-02 }}
            cx="531.8"
            cy="527.1"
            r="2"/>
          <circle
            style={{ opacity: 3.000000e-02 }}
            cx="531.8"
            cy="558.9"
            r="2"/>
          <circle
            style={{ opacity: 1.000000e-02 }}
            cx="531.8"
            cy="590.7"
            r="2"/>
        </g>
        <g>
          <circle
            cx="500"
            cy="18.6"
            r="2"/>
          <circle
            cx="500"
            cy="50.4"
            r="2"/>
          <circle
            cx="500"
            cy="82.2"
            r="2"/>
          <circle
            cx="500"
            cy="114"
            r="2"/>
          <circle
            cx="500"
            cy="145.8"
            r="2"/>
          <circle
            cx="500"
            cy="177.6"
            r="2"/>
          <circle
            cx="500"
            cy="209.3"
            r="2"/>
          <circle
            cx="500"
            cy="241.1"
            r="2"/>
          <circle
            cx="500"
            cy="272.9"
            r="2"/>
          <circle
            cx="500"
            cy="304.7"
            r="2"/>
          <circle
            cx="500"
            cy="336.5"
            r="2"/>
          <circle
            cx="500"
            cy="368.2"
            r="2"/>
          <circle
            cx="500"
            cy="400"
            r="2"/>
          <circle
            style={{ opacity: 0.5 }}
            cx="500"
            cy="431.8"
            r="2"/>
          <circle
            style={{ opacity: 0.25 }}
            cx="500"
            cy="463.6"
            r="2"/>
          <circle
            style={{ opacity: 7.000000e-02 }}
            cx="500"
            cy="495.4"
            r="2"/>
          <circle
            style={{ opacity: 3.000000e-02 }}
            cx="500"
            cy="527.1"
            r="2"/>
          <circle
            style={{ opacity: 1.000000e-02 }}
            cx="500"
            cy="558.9"
            r="2"/>
        </g>
        <g>
          <circle
            cx="468.2"
            cy="18.6"
            r="2"/>
          <circle
            cx="468.2"
            cy="50.4"
            r="2"/>
          <circle
            cx="468.2"
            cy="82.2"
            r="2"/>
          <circle
            cx="468.2"
            cy="114"
            r="2"/>
          <circle
            cx="468.2"
            cy="145.8"
            r="2"/>
          <circle
            cx="468.2"
            cy="177.6"
            r="2"/>
          <circle
            cx="468.2"
            cy="209.3"
            r="2"/>
          <circle
            cx="468.2"
            cy="241.1"
            r="2"/>
          <circle
            cx="468.2"
            cy="272.9"
            r="2"/>
          <circle
            cx="468.2"
            cy="304.7"
            r="2"/>
          <circle
            cx="468.2"
            cy="336.5"
            r="2"/>
          <circle
            cx="468.2"
            cy="368.2"
            r="2"/>
          <circle
            style={{ opacity: 0.5 }}
            cx="468.2"
            cy="400"
            r="2"/>
          <circle
            style={{ opacity: 0.25 }}
            cx="468.2"
            cy="431.8"
            r="2"/>
          <circle
            style={{ opacity: 7.000000e-02 }}
            cx="468.2"
            cy="463.6"
            r="2"/>
          <circle
            style={{ opacity: 3.000000e-02 }}
            cx="468.2"
            cy="495.4"
            r="2"/>
          <circle
            style={{ opacity: 1.000000e-02 }}
            cx="468.2"
            cy="527.1"
            r="2"/>
        </g>
        <g>
          <circle
            cx="436.5"
            cy="18.6"
            r="2"/>
          <circle
            cx="436.5"
            cy="50.4"
            r="2"/>
          <circle
            cx="436.5"
            cy="82.2"
            r="2"/>
          <circle
            cx="436.5"
            cy="114"
            r="2"/>
          <circle
            cx="436.5"
            cy="145.8"
            r="2"/>
          <circle
            cx="436.5"
            cy="177.6"
            r="2"/>
          <circle
            cx="436.5"
            cy="209.3"
            r="2"/>
          <circle
            cx="436.5"
            cy="241.1"
            r="2"/>
          <circle
            cx="436.5"
            cy="272.9"
            r="2"/>
          <circle
            cx="436.5"
            cy="304.7"
            r="2"/>
          <circle
            cx="436.5"
            cy="336.5"
            r="2"/>
          <circle
            style={{ opacity: 0.5 }}
            cx="436.5"
            cy="368.2"
            r="2"/>
          <circle
            style={{ opacity: 0.25 }}
            cx="436.5"
            cy="400"
            r="2"/>
          <circle
            style={{ opacity: 7.000000e-02 }}
            cx="436.5"
            cy="431.8"
            r="2"/>
          <circle
            style={{ opacity: 3.000000e-02 }}
            cx="436.5"
            cy="463.6"
            r="2"/>
          <circle
            style={{ opacity: 1.000000e-02 }}
            cx="436.5"
            cy="495.4"
            r="2"/>
        </g>
        <g>
          <circle
            cx="404.7"
            cy="18.6"
            r="2"/>
          <circle
            cx="404.7"
            cy="50.4"
            r="2"/>
          <circle
            cx="404.7"
            cy="82.2"
            r="2"/>
          <circle
            cx="404.7"
            cy="114"
            r="2"/>
          <circle
            cx="404.7"
            cy="145.8"
            r="2"/>
          <circle
            cx="404.7"
            cy="177.6"
            r="2"/>
          <circle
            cx="404.7"
            cy="209.3"
            r="2"/>
          <circle
            cx="404.7"
            cy="241.1"
            r="2"/>
          <circle
            cx="404.7"
            cy="272.9"
            r="2"/>
          <circle
            cx="404.7"
            cy="304.7"
            r="2"/>
          <circle
            style={{ opacity: 0.5 }}
            cx="404.7"
            cy="336.5"
            r="2"/>
          <circle
            style={{ opacity: 0.25 }}
            cx="404.7"
            cy="368.2"
            r="2"/>
          <circle
            style={{ opacity: 7.000000e-02 }}
            cx="404.7"
            cy="400"
            r="2"/>
          <circle
            style={{ opacity: 3.000000e-02 }}
            cx="404.7"
            cy="431.8"
            r="2"/>
          <circle
            style={{ opacity: 1.000000e-02 }}
            cx="404.7"
            cy="463.6"
            r="2"/>
        </g>
        <g>
          <circle
            cx="372.9"
            cy="18.6"
            r="2"/>
          <circle
            cx="372.9"
            cy="50.4"
            r="2"/>
          <circle
            cx="372.9"
            cy="82.2"
            r="2"/>
          <circle
            cx="372.9"
            cy="114"
            r="2"/>
          <circle
            cx="372.9"
            cy="145.8"
            r="2"/>
          <circle
            cx="372.9"
            cy="177.6"
            r="2"/>
          <circle
            cx="372.9"
            cy="209.3"
            r="2"/>
          <circle
            cx="372.9"
            cy="241.1"
            r="2"/>
          <circle
            cx="372.9"
            cy="272.9"
            r="2"/>
          <circle
            style={{ opacity: 0.5 }}
            cx="372.9"
            cy="304.7"
            r="2"/>
          <circle
            style={{ opacity: 0.25 }}
            cx="372.9"
            cy="336.5"
            r="2"/>
          <circle
            style={{ opacity: 7.000000e-02 }}
            cx="372.9"
            cy="368.2"
            r="2"/>
          <circle
            style={{ opacity: 3.000000e-02 }}
            cx="372.9"
            cy="400"
            r="2"/>
          <circle
            style={{ opacity: 1.000000e-02 }}
            cx="372.9"
            cy="431.8"
            r="2"/>
        </g>
        <g>
          <circle
            cx="341.1"
            cy="18.6"
            r="2"/>
          <circle
            cx="341.1"
            cy="50.4"
            r="2"/>
          <circle
            cx="341.1"
            cy="82.2"
            r="2"/>
          <circle
            cx="341.1"
            cy="114"
            r="2"/>
          <circle
            cx="341.1"
            cy="145.8"
            r="2"/>
          <circle
            cx="341.1"
            cy="177.6"
            r="2"/>
          <circle
            cx="341.1"
            cy="209.3"
            r="2"/>
          <circle
            cx="341.1"
            cy="241.1"
            r="2"/>
          <circle
            style={{ opacity: 0.5 }}
            cx="341.1"
            cy="272.9"
            r="2"/>
          <circle
            style={{ opacity: 0.25 }}
            cx="341.1"
            cy="304.7"
            r="2"/>
          <circle
            style={{ opacity: 7.000000e-02 }}
            cx="341.1"
            cy="336.5"
            r="2"/>
          <circle
            style={{ opacity: 3.000000e-02 }}
            cx="341.1"
            cy="368.2"
            r="2"/>
          <circle
            style={{ opacity: 1.000000e-02 }}
            cx="341.1"
            cy="400"
            r="2"/>
        </g>
        <g>
          <circle
            cx="309.4"
            cy="18.6"
            r="2"/>
          <circle
            cx="309.4"
            cy="50.4"
            r="2"/>
          <circle
            cx="309.4"
            cy="82.2"
            r="2"/>
          <circle
            cx="309.4"
            cy="114"
            r="2"/>
          <circle
            cx="309.4"
            cy="145.8"
            r="2"/>
          <circle
            cx="309.4"
            cy="177.6"
            r="2"/>
          <circle
            cx="309.4"
            cy="209.3"
            r="2"/>
          <circle
            style={{ opacity: 0.5 }}
            cx="309.4"
            cy="241.1"
            r="2"/>
          <circle
            style={{ opacity: 0.25 }}
            cx="309.4"
            cy="272.9"
            r="2"/>
          <circle
            style={{ opacity: 7.000000e-02 }}
            cx="309.4"
            cy="304.7"
            r="2"/>
          <circle
            style={{ opacity: 3.000000e-02 }}
            cx="309.4"
            cy="336.5"
            r="2"/>
          <circle
            style={{ opacity: 1.000000e-02 }}
            cx="309.4"
            cy="368.2"
            r="2"/>
        </g>
        <g>
          <circle
            cx="277.6"
            cy="18.6"
            r="2"/>
          <circle
            cx="277.6"
            cy="50.4"
            r="2"/>
          <circle
            cx="277.6"
            cy="82.2"
            r="2"/>
          <circle
            cx="277.6"
            cy="114"
            r="2"/>
          <circle
            cx="277.6"
            cy="145.8"
            r="2"/>
          <circle
            cx="277.6"
            cy="177.6"
            r="2"/>
          <circle
            style={{ opacity: 0.5 }}
            cx="277.6"
            cy="209.3"
            r="2"/>
          <circle
            style={{ opacity: 0.25 }}
            cx="277.6"
            cy="241.1"
            r="2"/>
          <circle
            style={{ opacity: 7.000000e-02 }}
            cx="277.6"
            cy="272.9"
            r="2"/>
          <circle
            style={{ opacity: 3.000000e-02 }}
            cx="277.6"
            cy="304.7"
            r="2"/>
          <circle
            style={{ opacity: 1.000000e-02 }}
            cx="277.6"
            cy="336.5"
            r="2"/>
        </g>
        <g>
          <circle
            cx="245.8"
            cy="18.6"
            r="2"/>
          <circle
            cx="245.8"
            cy="50.4"
            r="2"/>
          <circle
            cx="245.8"
            cy="82.2"
            r="2"/>
          <circle
            cx="245.8"
            cy="114"
            r="2"/>
          <circle
            cx="245.8"
            cy="145.8"
            r="2"/>
          <circle
            style={{ opacity: 0.5 }}
            cx="245.8"
            cy="177.6"
            r="2"/>
          <circle
            style={{ opacity: 0.25 }}
            cx="245.8"
            cy="209.3"
            r="2"/>
          <circle
            style={{ opacity: 7.000000e-02 }}
            cx="245.8"
            cy="241.1"
            r="2"/>
          <circle
            style={{ opacity: 3.000000e-02 }}
            cx="245.8"
            cy="272.9"
            r="2"/>
          <circle
            style={{ opacity: 1.000000e-02 }}
            cx="245.8"
            cy="304.7"
            r="2"/>
        </g>
        <g>
          <circle
            cx="214"
            cy="18.6"
            r="2"/>
          <circle
            cx="214"
            cy="50.4"
            r="2"/>
          <circle
            cx="214"
            cy="82.2"
            r="2"/>
          <circle
            cx="214"
            cy="114"
            r="2"/>
          <circle
            style={{ opacity: 0.5 }}
            cx="214"
            cy="145.8"
            r="2"/>
          <circle
            style={{ opacity: 0.25 }}
            cx="214"
            cy="177.6"
            r="2"/>
          <circle
            style={{ opacity: 7.000000e-02 }}
            cx="214"
            cy="209.3"
            r="2"/>
          <circle
            style={{ opacity: 3.000000e-02 }}
            cx="214"
            cy="241.1"
            r="2"/>
          <circle
            style={{ opacity: 1.000000e-02 }}
            cx="214"
            cy="272.9"
            r="2"/>
        </g>
        <g>
          <circle
            cx="182.3"
            cy="18.6"
            r="2"/>
          <circle
            cx="182.3"
            cy="50.4"
            r="2"/>
          <circle
            cx="182.3"
            cy="82.2"
            r="2"/>
          <circle
            style={{ opacity: 0.5 }}
            cx="182.3"
            cy="114"
            r="2"/>
          <circle
            style={{ opacity: 0.25 }}
            cx="182.3"
            cy="145.8"
            r="2"/>
          <circle
            style={{ opacity: 7.000000e-02 }}
            cx="182.3"
            cy="177.6"
            r="2"/>
          <circle
            style={{ opacity: 3.000000e-02 }}
            cx="182.3"
            cy="209.3"
            r="2"/>
          <circle
            style={{ opacity: 1.000000e-02 }}
            cx="182.3"
            cy="241.1"
            r="2"/>
        </g>
        <g>
          <circle
            cx="150.5"
            cy="18.6"
            r="2"/>
          <circle
            cx="150.5"
            cy="50.4"
            r="2"/>
          <circle
            style={{ opacity: 0.5 }}
            cx="150.5"
            cy="82.2"
            r="2"/>
          <circle
            style={{ opacity: 0.25 }}
            cx="150.5"
            cy="114"
            r="2"/>
          <circle
            style={{ opacity: 7.000000e-02 }}
            cx="150.5"
            cy="145.8"
            r="2"/>
          <circle
            style={{ opacity: 3.000000e-02 }}
            cx="150.5"
            cy="177.6"
            r="2"/>
          <circle
            style={{ opacity: 1.000000e-02 }}
            cx="150.5"
            cy="209.3"
            r="2"/>
        </g>
        <g>
          <circle
            cx="118.7"
            cy="18.6"
            r="2"/>
          <circle
            style={{ opacity: 0.5 }}
            cx="118.7"
            cy="50.4"
            r="2"/>
          <circle
            style={{ opacity: 0.25 }}
            cx="118.7"
            cy="82.2"
            r="2"/>
          <circle
            style={{ opacity: 7.000000e-02 }}
            cx="118.7"
            cy="114"
            r="2"/>
          <circle
            style={{ opacity: 3.000000e-02 }}
            cx="118.7"
            cy="145.8"
            r="2"/>
          <circle
            style={{ opacity: 1.000000e-02 }}
            cx="118.7"
            cy="177.6"
            r="2"/>
        </g>
        <g>
          <circle
            style={{ opacity: 0.5 }}
            cx="86.9"
            cy="18.6"
            r="2"/>
          <circle
            style={{ opacity: 0.25 }}
            cx="86.9"
            cy="50.4"
            r="2"/>
          <circle
            style={{ opacity: 7.000000e-02 }}
            cx="86.9"
            cy="82.2"
            r="2"/>
          <circle
            style={{ opacity: 3.000000e-02 }}
            cx="86.9"
            cy="114"
            r="2"/>
          <circle
            style={{ opacity: 1.000000e-02 }}
            cx="86.9"
            cy="145.8"
            r="2"/>
        </g>
        <g>
          <circle
            style={{ opacity: 0.25 }}
            cx="55.2"
            cy="18.6"
            r="2"/>
          <circle
            style={{ opacity: 7.000000e-02 }}
            cx="55.2"
            cy="50.4"
            r="2"/>
          <circle
            style={{ opacity: 3.000000e-02 }}
            cx="55.2"
            cy="82.2"
            r="2"/>
          <circle
            style={{ opacity: 1.000000e-02 }}
            cx="55.2"
            cy="114"
            r="2"/>
        </g>
        <g>
          <circle
            style={{ opacity: 7.000000e-02 }}
            cx="23.4"
            cy="18.6"
            r="2"/>
          <circle
            style={{ opacity: 3.000000e-02 }}
            cx="23.4"
            cy="50.4"
            r="2"/>
          <circle
            style={{ opacity: 1.000000e-02 }}
            cx="23.4"
            cy="82.2"
            r="2"/>
        </g>
        <g>
          <circle
            style={{ opacity: 3.000000e-02 }}
            cx="-8.4"
            cy="18.6"
            r="2"/>
          <circle
            style={{ opacity: 1.000000e-02 }}
            cx="-8.4"
            cy="50.4"
            r="2"/>
        </g>
        <g>
          <circle
            style={{ opacity: 1.000000e-02 }}
            cx="-40.2"
            cy="18.6"
            r="2"/>
        </g>
      </svg>

    );
  }
}

Icon.displayName = 'UpperRightCornerDotPattern';

export default Icon;
