import React from 'react';
import PropTypes from 'prop-types';
// styles
import {
  DivStyledContentContainer,
  DivStyledDonutChart,
} from './styles';

export const calculateDonutChart = (data, rotation) => {
  const radius = 30;
  const dashArray = 2 * Math.PI * radius;
  const startAngle = rotation;
  let thisSum;
  let ratio;
  let itemRatio;
  let angle;
  let offset;
  let filled = 0;
  let transformedData = [];

  const transformData = () => data.map((item) => {
    itemRatio = ratio * item.value;
    angle = (filled * 360) / 100 + startAngle;
    offset = dashArray - (dashArray * itemRatio) / 100;
    filled += itemRatio;

    return {
      ...item,
      angle,
      dashArray,
      filled,
      itemRatio,
      offset,
      radius,
    };
  });

  if (data && data.length > 0) {
    thisSum = data.reduce((sum, item) => sum + item.value, 0);
    ratio = 100 / thisSum;
    transformedData = transformData();
  } else {
    console.error('data is a required prop for the DonutChart Component');
  }

  return transformedData;
};

export const DonutChartComponent = ({
  children,
  className,
  data,
  id,
  rotation,
}) => {
  const cx = 50; // svg positioning
  const cy = 50; // svg positioning
  const strokeWidth = 4; // the thickness of the donut
  const transformedData = calculateDonutChart(data, rotation);

  return (
    <DivStyledDonutChart
      id={id}
      className={className}>
      <svg
        width='100%'
        height='100%'
        viewBox='0 0 100 100'>
        {transformedData.map((item) => (
          <circle
            cx={cx}
            cy={cy}
            fill='transparent'
            key={item.accessibleDescription}
            r={item.radius}
            stroke={item.color}
            strokeDasharray={item.dashArray}
            strokeDashoffset={item.offset}
            strokeWidth={strokeWidth}
            transform={`rotate(${item.angle} ${cx} ${cy})`}>
            <title>
              {item.accessibleDescription}: {item.value}
            </title>
          </circle>
        ))}
      </svg>
      <DivStyledContentContainer>
        {children}
      </DivStyledContentContainer>
    </DivStyledDonutChart>
  );
};

DonutChartComponent.propTypes = {
  /** Inner content of donut chart */
  children: PropTypes.any,
  /** Optional custom class name */
  className: PropTypes.string,
  /** Data for the Donut Chart */
  data: PropTypes.arrayOf(PropTypes.shape({
    /** the color hex value as a string */
    color: PropTypes.string.isRequired,
    /** the accessible description of the donut data segment as a string for accessibility */
    accessibleDescription: PropTypes.string.isRequired,
    /** the value of the data segment as a number */
    value: PropTypes.number.isRequired,
  })).isRequired,
  /** Component id */
  id: PropTypes.string.isRequired,
  /** The rotation of the Donut Chart */
  rotation: PropTypes.number,
};

DonutChartComponent.defaultProps = {
  data: [],
  rotation: 0,
};

export default DonutChartComponent;
