import React from 'react';
import PropTypes from 'prop-types';
// components
import AssistiveText from '../../components/AssistiveText';

// styles
import {
  CredentialCardContainerDivStyled,
  CredentialCardContainerBlockquoteStyled,
  CredentialFooterText,
  CredentialHeadingTextStyled,
  DescriptionTextStyled,
  IbdLogoStyled,
  NerdWalletLogoStyled,
} from './styles';

const getCredentialLogo = (logo, themeType) => {
  if (typeof logo === 'object') {
    return logo;
  }
  if (logo === 'ibd') {
    return (
      <IbdLogoStyled $themeType={themeType} />
    );
  }
  if (logo === 'nerdwallet') {
    return (
      <NerdWalletLogoStyled $themeType={themeType} />
    );
  }
  return null;
};

const CredentialCardContent = ({
  accentOrientation,
  accentColorVariant,
  backgroundVariant,
  branding,
  className,
  content,
  descriptionTextAlign,
  displayFooterText,
  hasAccent,
  headingColorVariant,
  headingTextUpperCase,
  themeType,
}) => {
  // grab the container, depends on blockquote
  const CredentialCardContainerStyled = content.isCitable
    ? CredentialCardContainerBlockquoteStyled
    : CredentialCardContainerDivStyled;

  return (
    <CredentialCardContainerStyled
      backgroundVariant={backgroundVariant}
      branding={branding}
      className={className}
      hasAccent={hasAccent}
      themeType={themeType}>
      <CredentialHeadingTextStyled
        accentColorVariant={accentColorVariant}
        accentOrientation={accentOrientation}
        ariaLabel={content.headingTextAriaLabel}
        branding={branding}
        className='nmx-credential-card-heading'
        component='h3' // TODO: this should be a prop, with default h3
        credential={content.credential}
        disableBottomPadding
        hasAccent={hasAccent}
        headingColorVariant={headingColorVariant}
        headingTextUpperCase={headingTextUpperCase}
        themeType={themeType}
        weight={300}>
        {content.headingText}
        {content.headingTextAssistiveSupplement && (
          <AssistiveText>
            {content.headingTextAssistiveSupplement}
          </AssistiveText>
        )}
      </CredentialHeadingTextStyled>
      <DescriptionTextStyled
        className='nmx-credential-card-description'
        credentialLogo={content.logo}
        align={descriptionTextAlign}
        themeType={themeType}>
        {content.descriptionText}
      </DescriptionTextStyled>
      {content.logo
        && getCredentialLogo(content.logo, themeType)}
      {/* cardFootnote is used in specific locations (Moody's, AMBest, FitchRatings, S&P) for pdf links in most cases - if this changes, will build in links directly into credentialData */}
      {content.additionalDescription && <>{content.additionalDescription}</>}
      {content.footerText && (
        <CredentialFooterText displayFooterText={displayFooterText}>
          {content.footerText}
        </CredentialFooterText>
      )}

    </CredentialCardContainerStyled>
  );
};

CredentialCardContent.propTypes = {
  /** optional accent color, used in CredentialCardSet */
  accentColorVariant: PropTypes.oneOf(['gold', 'sky']),
  /** optional prop to style credential heading accent, used in CredentialCardSet */
  accentOrientation: PropTypes.oneOf(['horizontal', 'vertical']),
  /** backgroundVariant allows section cards to be different background color based on themeType */
  backgroundVariant: PropTypes.oneOf([
    'darkDefault',
    'lightA',
    'lightB',
    'lightC',
  ]),
  /** sets overall branding of component or module, default='nm' */
  branding: PropTypes.oneOf(['nm', 'pcg']),
  /** Optional custom class name */
  className: PropTypes.string,
  /** Selection List of approved credentials */
  content: PropTypes.shape({
    logo: PropTypes.oneOf(['ibd', 'nerdwallet']) || PropTypes.element,
    descriptionText: PropTypes.node.isRequired,
    footerText: PropTypes.node,
    headingText: PropTypes.node.isRequired,
    headingTextAriaLabel: PropTypes.string,
    headingTextAssistiveSupplement: PropTypes.node,
    isCitable: PropTypes.bool,
    additionalDescription: PropTypes.any,
    sup1: PropTypes.any,
    sup2: PropTypes.any,
  }).isRequired,
  /** optional prop to align descriptionText Typography */
  descriptionTextAlign: PropTypes.oneOf(['inherit', 'left', 'center', 'right']),
  /** optional prop to display footer text */
  displayFooterText: PropTypes.bool,
  /** optional prop to display accent bar, used in CredentialCardSet */
  hasAccent: PropTypes.bool,
  /** optional prop for heading color */
  headingColorVariant: PropTypes.oneOf(['default', 'sky']),
  /** optional boolean to set uppercase heading text, used in CredentialCardSet */
  headingTextUpperCase: PropTypes.bool,
  /** optional superscript added at precise location within respective credential data */
  sup1: PropTypes.any,
  sup2: PropTypes.any, // used if a specific credential has 2 required footnotes
  /** section theme, which determines color styles */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']),
};

CredentialCardContent.defaultProps = {
  accentOrientation: 'vertical',
  accentColorVariant: 'gold',
  backgroundVariant: 'lightB',
  branding: 'nm',
  displayFooterText: false,
  hasAccent: false,
  headingColorVariant: 'default',
  headingTextUpperCase: false,
  themeType: 'lightTheme',
};

export default CredentialCardContent;
