import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// styles
import {
  DivStyledLoadingContainer,
  DivStyledLoadingIcon,
} from './styles';

export const LoadingComponent = ({
  branding,
  children,
  className,
  loadingSpinnerWidth,
  themeType,
}) => (
  <DivStyledLoadingContainer
    className={classNames('loading-container', className)}
    loadingSpinnerWidth={loadingSpinnerWidth}
  >
    <DivStyledLoadingIcon
      branding={branding}
      className='loader'
      {...children && { hasChildren: true }}
      themeType={themeType}
    />
    {children}
  </DivStyledLoadingContainer>
);

LoadingComponent.propTypes = {
  /** sets overall branding of component or module, default='nm' */
  branding: PropTypes.oneOf(['nm', 'pcg']),
  /** optional children used to display loading message under loading indicator */
  children: PropTypes.any,
  /** optional additional className, can be used to hook into from the consuming app for screen positioning */
  className: PropTypes.string,
  /** width of the loading spinner, in pixels */
  loadingSpinnerWidth: PropTypes.number,
  /** optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']),
};

LoadingComponent.defaultProps = {
  branding: 'nm',
  loadingSpinnerWidth: 140,
  themeType: 'lightTheme',
};

export default LoadingComponent;
