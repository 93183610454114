import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import scrollTo from '../../utils/scrollTo';

import {
  BaseButton,
  StartAdornmentContainerStyled,
  EndAdornmentContainerStyled,
} from './styles';

// NOTE: this would be great if this was in a separate, exportable file, and when testing, was working fine in the library Components, but the Storybook docs wasn't recognizing the description comments, propType.type and propType.name, so this works for now until storybook8.x and Node20+ can be used, then we can try that again.
export const buttonPropTypes = {
  /** Optional ariaLabel attribute, used for ADA to add more description to the Button (what will happen when clicked) */
  ariaLabel: PropTypes.string,
  /** sets overall branding of component or module, default='nm' */
  branding: PropTypes.oneOf(['nm', 'pcg']),
  /** Optional custom class name */
  className: PropTypes.string,
  /** Inner Text of button */
  children: PropTypes.node.isRequired,
  /** optional disabled prop */
  disabled: PropTypes.bool,
  /** optional icon end adornment displayed after button text - PASS IN SPECIFIC ICON COMPONENT */
  endAdornment: PropTypes.any,
  /** optional boolean to reset default icon component styles  */
  endAdornmentStylesReset: PropTypes.bool,
  /** Button id */
  id: PropTypes.string.isRequired,
  /** Optional onClick function */
  onClick: PropTypes.func,
  /** optional id of target element to scroll to */
  scrollConfig: PropTypes.shape({
    /** optional scroll behavior */
    behavior: PropTypes.oneOf(['auto', 'smooth']),
    /** optional scroll offset */
    offset: PropTypes.number,
    /** optional id of target element to scroll to */
    scrollToTargetId: PropTypes.string,
  }),
  /** optional icon start adornment displayed before button text - PASS IN SPECIFIC ICON COMPONENT */
  startAdornment: PropTypes.any,
  /** optional boolean to reset default icon component styles  */
  startAdornmentStylesReset: PropTypes.bool,
  /** Optional custom styles */
  styles: PropTypes.object,
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']),
  /** type: default button  */
  type: PropTypes.oneOf(['button', 'submit', 'reset']).isRequired,
  /** button variant */
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']).isRequired,
};

export const ButtonComponent = React.forwardRef(
  (
    {
      ariaLabel,
      branding,
      className,
      children,
      disabled,
      endAdornment: RenderEndAdornment,
      endAdornmentStylesReset,
      id,
      onClick,
      scrollConfig,
      styles,
      startAdornment: RenderStartAdornment,
      startAdornmentStylesReset,
      themeType,
      type,
      variant,
      ...rest
    },
    ref,
  ) => {
    const handleClick = (e) => {
      if (onClick) {
        onClick(e); // handle passed-in onClick function first before scrollTo function
      }
      if (scrollConfig && scrollConfig.scrollToTargetId) {
        scrollTo(e, scrollConfig.scrollToTargetId, scrollConfig);
      }
    };

    return (
      <BaseButton
        {...(ariaLabel && { 'aria-label': ariaLabel })} // NOTE: React implements exceptions to JSX, "aria-*" is one of them: https://reactjs.org/docs/accessibility.html
        branding={branding}
        className={classnames('nmx-button', className)}
        disabled={disabled}
        id={id}
        onClick={handleClick}
        ref={ref}
        style={styles || {}}
        themeType={themeType}
        type={type}
        variant={variant}
        {...rest}>
        {RenderStartAdornment && (
          <StartAdornmentContainerStyled
            startAdornmentStylesReset={startAdornmentStylesReset}>
            <RenderStartAdornment />
          </StartAdornmentContainerStyled>
        )}
        {children}
        {RenderEndAdornment && (
          <EndAdornmentContainerStyled
            endAdornmentStylesReset={endAdornmentStylesReset}>
            <RenderEndAdornment />
          </EndAdornmentContainerStyled>
        )}
      </BaseButton>
    );
  },
);

ButtonComponent.propTypes = buttonPropTypes;

ButtonComponent.defaultProps = {
  branding: 'nm',
  children: '',
  className: null,
  disabled: false,
  endAdornment: null,
  startAdornment: null,
  styles: null,
  type: 'button',
  themeType: 'lightTheme',
  variant: 'primary',
};

export default ButtonComponent;
