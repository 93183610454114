/* eslint-disable import/prefer-default-export */
import em from 'polished/lib/helpers/em';
import rem from 'polished/lib/helpers/rem';
import breakpoints from '../theme/breakpoints';
import { fontFamilySansSerif } from './fonts';

const headings = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
const headingData = {
  h1: {
    fontFamily: fontFamilySansSerif,
    fontSize: rem(44),
    fontSizeMedium: rem(32),
    fontSizeSmall: rem(26),
    lineheight: rem(53),
    lineheightMedium: rem(40),
    lineheightSmall: rem(32),
  },
  h2: {
    fontFamily: fontFamilySansSerif,
    fontSize: rem(30),
    fontSizeMedium: rem(28),
    fontSizeSmall: rem(21),
    lineheight: rem(40),
    lineheightMedium: rem(36),
    lineheightSmall: rem(28),
  },
  h3: {
    fontFamily: fontFamilySansSerif,
    fontSize: rem(24),
    fontSizeMedium: rem(24),
    fontSizeSmall: rem(19),
    lineheight: rem(32),
    lineheightMedium: rem(32),
    lineheightSmall: rem(25),
  },
  h4: {
    fontFamily: fontFamilySansSerif,
    fontSize: rem(21),
    fontSizeMedium: rem(20),
    fontSizeSmall: rem(17),
    lineheight: rem(28),
    lineheightMedium: rem(28),
    lineheightSmall: rem(23),
  },
  h5: {
    fontFamily: fontFamilySansSerif,
    fontSize: rem(21),
    fontSizeMedium: rem(18),
    fontSizeSmall: rem(16),
    lineheight: rem(30),
    lineheightMedium: rem(26),
    lineheightSmall: rem(22),
  },
  h6: {
    fontFamily: fontFamilySansSerif,
    fontSize: rem(16),
    fontSizeMedium: rem(16),
    fontSizeSmall: rem(14),
    lineheight: rem(24),
    lineheightMedium: rem(24),
    lineheightSmall: rem(22),
  },
};

export const getFontHeadingCss = () => headings.map((heading) => {
  // heading = h1, h2, h3 etc
  const data = headingData[heading];
  const {
    fontFamily,
    fontSize,
    fontSizeSmall,
    lineheight,
    lineheightSmall,
  } = data;
  return `
    ${heading}, .nmx-${heading} {
      ${fontFamily}
      font-size: ${fontSizeSmall};
      font-weight: 500;
      letter-spacing: 0.5px;
      line-height: ${lineheightSmall};

      @media (min-width: ${em(breakpoints.values.small)}) {
        letter-spacing: 1px;
      }

      @media (min-width: ${em(breakpoints.values.medium)}) {
        font-size: ${fontSize};
        line-height: ${lineheight};
      }

      @media (min-width: ${em(breakpoints.values.large)}) {
        font-size: ${fontSize};
        line-height: ${lineheight};
      }
    }
    `;
}).join(`
  `);
