import React from 'react';
import PropTypes from 'prop-types';

// styles
import { TextFieldStyled } from '../../styles';

export const FirstNameInputComponent = React.forwardRef(({
  hasError,
  helperText,
  onBlur,
  onChange,
  onClick,
  placeholderText,
  themeType,
  value,
}, ref) => (
  <TextFieldStyled
    themeType={themeType}
    hasError={hasError}
    helperText={helperText}
    id='suspect-email-capture-form-first-name-input'
    labelText='First name'
    maxLength={100}
    name='firstName'
    onBlur={onBlur}
    onChange={onChange}
    onClick={onClick}
    placeholderText={placeholderText}
    ref={ref}
    required={true}
    value={value} />
));

FirstNameInputComponent.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default FirstNameInputComponent;
