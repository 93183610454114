// deprecated
import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
// components
import { Col } from '../../../foundations/Grid';
import Picture from '../../../components/Picture';
import Typography from '../../../foundations/Typography';

export const PhotoColStyled = styled(Col)`
  && {
    ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    return css`
      margin-bottom: ${mediumUp ? 0 : rem(24)};
      ${mediumUp && `padding-right: ${rem(32)}`};
      ${!mediumUp && `
          display: flex;
          justify-content: center;
      `}
    `;
  }
}
  }
`;

export const ContentColStyled = styled(Col)`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    return css`
      && {
        ${mediumUp && `padding-left: ${rem(16)}`};
      }
    `;
  }
}`;

export const TypographyHeadingStyled = styled(Typography)`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    return css`
      && {
        text-align: ${mediumUp ? 'left' : 'center'};
      }
    `;
  }
}`;

// clean this up
export const DivButtonContainer = styled.div`
  && {
    ${({ theme }) => css`
      text-align: ${theme.mediumDown() ? 'center' : 'left'};
      ${theme.smallDown()
        && css`
          button {
            width: 100%;
            max-width: 100%;
          }
        `}
      margin-top: ${rem(12)};
    `}
  }
`;

export const PictureFRPhotoStyled = styled(Picture)`
  ${({ theme, variant }) => {
    const mediumUp = theme.mediumUp();
    return css`
      display: block;
      max-width: ${(variant === 'round') ? rem(220) : rem(270)};
      float: ${mediumUp ? 'right' : 'none'};
    `;
  }
}`;
