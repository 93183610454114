import React from 'react';
import PropTypes from 'prop-types';

// import Illustrative Light Theme Icons
import AppleWithStemLightTheme from './icons/illustrative/lightTheme/AppleWithStem';
import AtomLightTheme from './icons/illustrative/lightTheme/Atom';
import AwarenessRibbonLightTheme from './icons/illustrative/lightTheme/AwarenessRibbon';
import BabyBuggyLightTheme from './icons/illustrative/lightTheme/BabyBuggy';
import BarChartWithUpwardArrowLightTheme from './icons/illustrative/lightTheme/BarChartWithUpwardArrow';
import BeachChairWithBorderLightTheme from './icons/illustrative/lightTheme/BeachChairWithBorder';
import BeachChairWithSunLightTheme from './icons/illustrative/lightTheme/BeachChairWithSun';
import BirthdayCakeLightTheme from './icons/illustrative/lightTheme/BirthdayCake';
import BlueprintWithHouseLightTheme from './icons/illustrative/lightTheme/BlueprintWithHouse';
import BrainWithLightningBoltLightTheme from './icons/illustrative/lightTheme/BrainWithLightningBolt';
import BookWithBookmarkLightTheme from './icons/illustrative/lightTheme/BookWithBookmark';
import CalendarLightTheme from './icons/illustrative/lightTheme/Calendar';
import CameraLightTheme from './icons/illustrative/lightTheme/Camera';
import CarMovingLightTheme from './icons/illustrative/lightTheme/CarMoving';
import CertificateWithAwardRibbonLightTheme from './icons/illustrative/lightTheme/CertificateWithAwardRibbon';
import CheckmarkWithBorderLightTheme from './icons/illustrative/lightTheme/CheckmarkWithBorder';
import CityscapeLightTheme from './icons/illustrative/lightTheme/Cityscape';
import ClipboardWithDollarSignPaperLightTheme from './icons/illustrative/lightTheme/ClipboardWithDollarSignPaper';
import ClockLightTheme from './icons/illustrative/lightTheme/Clock';
import CoffeeCupDisposableLightTheme from './icons/illustrative/lightTheme/CoffeeCupDisposable';
import ComputerScreenWithMouseArrowLightTheme from './icons/illustrative/lightTheme/ComputerScreenWithMouseArrow';
import CurvedArrowThroughXxoLightTheme from './icons/illustrative/lightTheme/CurvedArrowThroughXxo';
import DialGaugeLightTheme from './icons/illustrative/lightTheme/DialGauge';
import DogWithCollarLightTheme from './icons/illustrative/lightTheme/DogWithCollar';
import DollarSignRevolvingArrowsLightTheme from './icons/illustrative/lightTheme/DollarSignRevolvingArrows';
import DollarSignWithCircleAndMouseArrowLightTheme from './icons/illustrative/lightTheme/DollarSignWithCircleAndMouseArrow';
import DollarSignWithFourArrowsPointingInwardLightTheme from './icons/illustrative/lightTheme/DollarSignWithFourArrowsPointingInward';
import DressLightTheme from './icons/illustrative/lightTheme/Dress';
import DrinkingGlassWithStrawAndLemonWheelLightTheme from './icons/illustrative/lightTheme/DrinkingGlassWithStrawAndLemonWheel';
import EnvelopeMovingLightTheme from './icons/illustrative/lightTheme/EnvelopeMoving';
import EnvelopeWithAtSymbolPaperLightTheme from './icons/illustrative/lightTheme/EnvelopeWithAtSymbolPaper';
import EnvelopeWithPaperMoneyLightTheme from './icons/illustrative/lightTheme/EnvelopeWithPaperMoney';
import FamilyHeartLightTheme from './icons/illustrative/lightTheme/FamilyHeart';
import FistHoldingPencilLightTheme from './icons/illustrative/lightTheme/FistHoldingPencil';
import FiveConnectedNodesLightTheme from './icons/illustrative/lightTheme/FiveConnectedNodes';
import FolderWithPaperMoneyLightTheme from './icons/illustrative/lightTheme/FolderWithPaperMoney';
import FourRomanColumnsYellowRoofDotLightTheme from './icons/illustrative/lightTheme/FourRomanColumnsYellowRoofDot';
import FourRomanColumnsYellowRoofDotWithClock4OClockLightTheme from './icons/illustrative/lightTheme/FourRomanColumnsYellowRoofDotWithClock4OClock';
import FourRomanColumnsYellowRoofDotWithClock12OClockLightTheme from './icons/illustrative/lightTheme/FourRomanColumnsYellowRoofDotWithClock12OClock';
import GearAndGlobeConnectedWithOneWayArrowsLightTheme from './icons/illustrative/lightTheme/GearAndGlobeConnectedWithOneWayArrows';
import GraduationHatWithTassleLightTheme from './icons/illustrative/lightTheme/GraduationHatWithTassle';
import GroupOfSixPeopleLightTheme from './icons/illustrative/lightTheme/GroupOfSixPeople';
import HandHoldingCreditCardLightTheme from './icons/illustrative/lightTheme/HandHoldingCreditCard';
import HandWithThreeDollarSignsLightTheme from './icons/illustrative/lightTheme/HandWithThreeDollarSigns';
import HandWithThreePlusSignsLightTheme from './icons/illustrative/lightTheme/HandWithThreePlusSigns';
import HandshakeLightTheme from './icons/illustrative/lightTheme/Handshake';
import HeartWithCrossLightTheme from './icons/illustrative/lightTheme/HeartWithCross';
import HeartWithPulseOximeterWaveLightTheme from './icons/illustrative/lightTheme/HeartWithPulseOximeterWave';
import HomeSafeLockedLightTheme from './icons/illustrative/lightTheme/HomeSafeLocked';
import HourglassLightTheme from './icons/illustrative/lightTheme/Hourglass';
import HouseLightTheme from './icons/illustrative/lightTheme/House';
import HouseWithCheckmarkShieldLightTheme from './icons/illustrative/lightTheme/HouseWithCheckmarkShield';
import HouseWithHammerLightTheme from './icons/illustrative/lightTheme/HouseWithHammer';
import HouseWithPaperMoneyLightTheme from './icons/illustrative/lightTheme/HouseWithPaperMoney';
import IdentificationBadgeLightTheme from './icons/illustrative/lightTheme/IdentificationBadge';
import InformationWordBubbleLightTheme from './icons/illustrative/lightTheme/InformationWordBubble';
import InnerOfficeEnvelopeFastenedWithStringLightTheme from './icons/illustrative/lightTheme/InnerOfficeEnvelopeFastenedWithString';
import LeafLightTheme from './icons/illustrative/lightTheme/Leaf';
import LifePreserverVestLightTheme from './icons/illustrative/lightTheme/LifePreserverVest';
import LightbulbLightTheme from './icons/illustrative/lightTheme/Lightbulb';
import LightbulbWithQuestionMarkLightTheme from './icons/illustrative/lightTheme/LightbulbWithQuestionMark';
import LineGraphUpwardsTrendLightTheme from './icons/illustrative/lightTheme/LineGraphUpwardsTrend';
import MagnifyingGlassLightTheme from './icons/illustrative/lightTheme/MagnifyingGlass';
import MagnifyingGlassWithDollarSignLightTheme from './icons/illustrative/lightTheme/MagnifyingGlassWithDollarSign';
import MapPinLightTheme from './icons/illustrative/lightTheme/MapPin';
import MapPinInformationalLightTheme from './icons/illustrative/lightTheme/MapPinInformational';
import MedalWithStarLightTheme from './icons/illustrative/lightTheme/MedalWithStar';
import MicrophoneWithCableLightTheme from './icons/illustrative/lightTheme/MicrophoneWithCable';
import MobileDeviceWithTwoWordBubblesLightTheme from './icons/illustrative/lightTheme/MobileDeviceWithTwoWordBubbles';
import MobileDeviceWithWifiSymbolLightTheme from './icons/illustrative/lightTheme/MobileDeviceWithWifiSymbol';
import MoneyParachuteWithBorderLightTheme from './icons/illustrative/lightTheme/MoneyParachuteWithBorder';
import MountaintopWithFlagLightTheme from './icons/illustrative/lightTheme/MountaintopWithFlag';
import OneArrowPointingUpToHorizontalLineLightTheme from './icons/illustrative/lightTheme/OneArrowPointingUpToHorizontalLine';
import OneMapPinOneTaxPinConnectedWithCurvyDottedLinesLightTheme from './icons/illustrative/lightTheme/OneMapPinOneTaxPinConnectedWithCurvyDottedLine';
import OnePagePaperArticleLightTheme from './icons/illustrative/lightTheme/OnePagePaperArticle';
import OnePersonOnChampionshipPodiumFirstPlaceLightTheme from './icons/illustrative/lightTheme/OnePersonOnChampionshipPodiumFirstPlace';
import PadlockDisengagedLightTheme from './icons/illustrative/lightTheme/PadlockDisengaged';
import OneTaxPinOneMapPinConnectedWithCurvyDottedLinesLightTheme from './icons/illustrative/lightTheme/OneTaxPinOneMapPinConnectedWithCurvyDottedLine';
import PadlockEngagedLightTheme from './icons/illustrative/lightTheme/PadlockEngaged';
import PaperMapLightTheme from './icons/illustrative/lightTheme/PaperMap';
import PaperMoneyStackLightTheme from './icons/illustrative/lightTheme/PaperMoneyStack';
import PaperShoppingBagEmptyLightTheme from './icons/illustrative/lightTheme/PaperShoppingBagEmpty';
import PaperWithDeadFaceLightTheme from './icons/illustrative/lightTheme/PaperWithDeadFace';
import PaperWithDollarSignAndBarChartLightTheme from './icons/illustrative/lightTheme/PaperWithDollarSignAndBarChart';
import PersonHoldingGroundedFlagLightTheme from './icons/illustrative/lightTheme/PersonHoldingGroundedFlag';
import PersonWithLightbulbWordBubbleLightTheme from './icons/illustrative/lightTheme/PersonWithLightbulbWordBubble';
import PersonWithOutwardArrowsLightTheme from './icons/illustrative/lightTheme/PersonWithOutwardArrows';
import PersonWithPastGrowthHeightLinesLightTheme from './icons/illustrative/lightTheme/PersonWithPastGrowthHeightLines';
import PersonWithPlusCircleLightTheme from './icons/illustrative/lightTheme/PersonWithPlusCircle';
import PersonWithXCircleLightTheme from './icons/illustrative/lightTheme/PersonWithXCircle';
import PiggybankWithCoinLightTheme from './icons/illustrative/lightTheme/PiggybankWithCoin';
import PottedPlantWithDollarSignCircleLightTheme from './icons/illustrative/lightTheme/PottedPlantWithDollarSignCircle';
import PriceTagWithDollarSignLightTheme from './icons/illustrative/lightTheme/PriceTagWithDollarSign';
import ProfileOutlineCheckmarkWithBorderLightTheme from './icons/illustrative/lightTheme/ProfileOutlineCheckmarkWithBorder';
import ProjectorScreenWithPieChartLightTheme from './icons/illustrative/lightTheme/ProjectorScreenWithPieChart';
import RibbonWithCheckmarkLightTheme from './icons/illustrative/lightTheme/RibbonWithCheckmark';
import RocketLightTheme from './icons/illustrative/lightTheme/Rocket';
import SailboatOnWaterLightTheme from './icons/illustrative/lightTheme/SailboatOnWater';
import SettingsSliderLightTheme from './icons/illustrative/lightTheme/SettingsSlider';
import ShieldWithClock180ArrowLightTheme from './icons/illustrative/lightTheme/ShieldWithClock180Arrow';
import ShieldWithClock360ArrowLightTheme from './icons/illustrative/lightTheme/ShieldWithClock360Arrow';
import ShieldWithCrossLightTheme from './icons/illustrative/lightTheme/ShieldWithCross';
import ShieldWithDollarSignLightTheme from './icons/illustrative/lightTheme/ShieldWithDollarSign';
import ShieldWithGlobe2ArrowsLightTheme from './icons/illustrative/lightTheme/ShieldWithGlobe2Arrows';
import ShieldWithGlobe3ArrowsLightTheme from './icons/illustrative/lightTheme/ShieldWithGlobe3Arrows';
import ShieldWithHeartLightTheme from './icons/illustrative/lightTheme/ShieldWithHeart';
import ShieldWithHeartBorderLightTheme from './icons/illustrative/lightTheme/ShieldWithHeartBorder';
import ShieldWithKeyholeLightTheme from './icons/illustrative/lightTheme/ShieldWithKeyhole';
import SquareWithPlusMinusMultiplyEqualQuadrantsLightTheme from './icons/illustrative/lightTheme/SquareWithPlusMinusMultiplyEqualQuadrants';
import StopwatchWithDollarSignLightTheme from './icons/illustrative/lightTheme/StopwatchWithDollarSign';
import SubwayTrainOnTracksLightTheme from './icons/illustrative/lightTheme/SubwayTrainOnTracks';
import SunsetOverWaterHorizonLightTheme from './icons/illustrative/lightTheme/SunsetOverWaterHorizon';
import TelephoneWithOutgoingSoundLightTheme from './icons/illustrative/lightTheme/TelephoneWithOutgoingSound';
import ThreeConnectedNodesLightTheme from './icons/illustrative/lightTheme/ThreeConnectedNodes';
import ThreePeopleConnectedByInnerLinesLightTheme from './icons/illustrative/lightTheme/ThreePeopleConnectedByInnerLines';
import ThreePeopleConnectedByOuterLinesLightTheme from './icons/illustrative/lightTheme/ThreePeopleConnectedByOuterLines';
import ThreePeopleWithDollarSignLightTheme from './icons/illustrative/lightTheme/ThreePeopleWithDollarSign';
import ThumbsUpLightTheme from './icons/illustrative/lightTheme/ThumbsUp';
import TwoInformationalWordBubblesLightTheme from './icons/illustrative/lightTheme/TwoInformationalWordBubbles';
import TwoLeavesLightTheme from './icons/illustrative/lightTheme/TwoLeaves';
import TwoMapPinsConnectedWithCurvyDottedLineLightTheme from './icons/illustrative/lightTheme/TwoMapPinsConnectedWithCurvyDottedLine';
import TwoPapersWithDollarSignLightTheme from './icons/illustrative/lightTheme/TwoPapersWithDollarSign';
import TwoPeopleOneWayArrowLightTheme from './icons/illustrative/lightTheme/TwoPeopleOneWayArrow';
import TwoPeopleOneWordBubbleLightTheme from './icons/illustrative/lightTheme/TwoPeopleOneWordBubble';
import TwoPeopleTwoWayArrowsLightTheme from './icons/illustrative/lightTheme/TwoPeopleTwoWayArrows';
import TwoPeopleTwoWordBubblesLightTheme from './icons/illustrative/lightTheme/TwoPeopleTwoWordBubbles';
import TwoPersonTournamentBracketWithTrophyLightTheme from './icons/illustrative/lightTheme/TwoPersonTournamentBracketWithTrophy';
import TwoRevolvingArrowsOvalLightTheme from './icons/illustrative/lightTheme/TwoRevolvingArrowsOval';
import UmbrellaLightTheme from './icons/illustrative/lightTheme/Umbrella';
import UmbrellaWithBorderLightTheme from './icons/illustrative/lightTheme/UmbrellaWithBorder';
import WalletLightTheme from './icons/illustrative/lightTheme/Wallet';
import WebPageWithDollarSignLightTheme from './icons/illustrative/lightTheme/WebPageWithDollarSign';

// import Illustrative Dark Theme Icons
import AppleWithStemDarkTheme from './icons/illustrative/darkTheme/AppleWithStem';
import AtomDarkTheme from './icons/illustrative/darkTheme/Atom';
import BabyBuggyDarkTheme from './icons/illustrative/darkTheme/BabyBuggy';
import BarChartWithUpwardArrowDarkTheme from './icons/illustrative/darkTheme/BarChartWithUpwardArrow';
import BeachChairWithSunDarkTheme from './icons/illustrative/darkTheme/BeachChairWithSun';
import BirthdayCakeDarkTheme from './icons/illustrative/darkTheme/BirthdayCake';
import BlueprintWithHouseDarkTheme from './icons/illustrative/darkTheme/BlueprintWithHouse';
import BookWithBookmarkDarkTheme from './icons/illustrative/darkTheme/BookWithBookmark';
import BrainWithLightningBoltDarkTheme from './icons/illustrative/darkTheme/BrainWithLightningBolt';
import CalendarDarkTheme from './icons/illustrative/darkTheme/Calendar';
import CameraDarkTheme from './icons/illustrative/darkTheme/Camera';
import CarMovingDarkTheme from './icons/illustrative/darkTheme/CarMoving';
import CertificateWithAwardRibbonDarkTheme from './icons/illustrative/darkTheme/CertificateWithAwardRibbon';
import CityscapeDarkTheme from './icons/illustrative/darkTheme/Cityscape';
import ClipboardWithDollarSignPaperDarkTheme from './icons/illustrative/darkTheme/ClipboardWithDollarSignPaper';
import ClockDarkTheme from './icons/illustrative/darkTheme/Clock';
import CoffeeCupDisposableDarkTheme from './icons/illustrative/darkTheme/CoffeeCupDisposable';
import ComputerScreenWithMouseArrowDarkTheme from './icons/illustrative/darkTheme/ComputerScreenWithMouseArrow';
import CurvedArrowThroughXxoDarkTheme from './icons/illustrative/darkTheme/CurvedArrowThroughXxo';
import DialGaugeDarkTheme from './icons/illustrative/darkTheme/DialGauge';
import DogWithCollarDarkTheme from './icons/illustrative/darkTheme/DogWithCollar';
import DollarSignRevolvingArrowsDarkTheme from './icons/illustrative/darkTheme/DollarSignRevolvingArrows';
import DollarSignWithCircleAndMouseArrowDarkTheme from './icons/illustrative/darkTheme/DollarSignWithCircleAndMouseArrow';
import DollarSignWithFourArrowsPointingInwardDarkTheme from './icons/illustrative/darkTheme/DollarSignWithFourArrowsPointingInward';
import DressDarkTheme from './icons/illustrative/darkTheme/Dress';
import DrinkingGlassWithStrawAndLemonWheelDarkTheme from './icons/illustrative/darkTheme/DrinkingGlassWithStrawAndLemonWheel';
import EnvelopeMovingDarkTheme from './icons/illustrative/darkTheme/EnvelopeMoving';
import EnvelopeWithAtSymbolPaperDarkTheme from './icons/illustrative/darkTheme/EnvelopeWithAtSymbolPaper';
import EnvelopeWithPaperMoneyDarkTheme from './icons/illustrative/darkTheme/EnvelopeWithPaperMoney';
import FamilyHeartDarkTheme from './icons/illustrative/darkTheme/FamilyHeart';
import FistHoldingPencilDarkTheme from './icons/illustrative/darkTheme/FistHoldingPencil';
import FiveConnectedNodesDarkTheme from './icons/illustrative/darkTheme/FiveConnectedNodes';
import FolderWithPaperMoneyDarkTheme from './icons/illustrative/darkTheme/FolderWithPaperMoney';
import FourRomanColumnsYellowRoofDotDarkTheme from './icons/illustrative/darkTheme/FourRomanColumnsYellowRoofDot';
import FourRomanColumnsYellowRoofDotWithClock4OClockDarkTheme from './icons/illustrative/darkTheme/FourRomanColumnsYellowRoofDotWithClock4OClock';
import FourRomanColumnsYellowRoofDotWithClock12OClockDarkTheme from './icons/illustrative/darkTheme/FourRomanColumnsYellowRoofDotWithClock12OClock';
import GearAndGlobeConnectedWithOneWayArrowsDarkTheme from './icons/illustrative/darkTheme/GearAndGlobeConnectedWithOneWayArrows';
import GraduationHatWithTassleDarkTheme from './icons/illustrative/darkTheme/GraduationHatWithTassle';
import HandHoldingCreditCardDarkTheme from './icons/illustrative/darkTheme/HandHoldingCreditCard';
import HandWithThreeDollarSignsDarkTheme from './icons/illustrative/darkTheme/HandWithThreeDollarSigns';
import HandWithThreePlusSignsDarkTheme from './icons/illustrative/darkTheme/HandWithThreePlusSigns';
import HandshakeDarkTheme from './icons/illustrative/darkTheme/Handshake';
import HeartWithCrossDarkTheme from './icons/illustrative/darkTheme/HeartWithCross';
import HeartWithPulseOximeterWaveDarkTheme from './icons/illustrative/darkTheme/HeartWithPulseOximeterWave';
import HomeSafeLockedDarkTheme from './icons/illustrative/darkTheme/HomeSafeLocked';
import HourglassDarkTheme from './icons/illustrative/darkTheme/Hourglass';
import HouseDarkTheme from './icons/illustrative/darkTheme/House';
import HouseWithCheckmarkShieldDarkTheme from './icons/illustrative/darkTheme/HouseWithCheckmarkShield';
import HouseWithHammerDarkTheme from './icons/illustrative/darkTheme/HouseWithHammer';
import HouseWithPaperMoneyDarkTheme from './icons/illustrative/darkTheme/HouseWithPaperMoney';
import IdentificationBadgeDarkTheme from './icons/illustrative/darkTheme/IdentificationBadge';
import InnerOfficeEnvelopeFastenedWithStringDarkTheme from './icons/illustrative/darkTheme/InnerOfficeEnvelopeFastenedWithString';
import LeafDarkTheme from './icons/illustrative/darkTheme/Leaf';
import LifePreserverVestDarkTheme from './icons/illustrative/darkTheme/LifePreserverVest';
import LightbulbDarkTheme from './icons/illustrative/darkTheme/Lightbulb';
import LineGraphUpwardsTrendDarkTheme from './icons/illustrative/darkTheme/LineGraphUpwardsTrend';
import MagnifyingGlassDarkTheme from './icons/illustrative/darkTheme/MagnifyingGlass';
import MagnifyingGlassWithDollarSignDarkTheme from './icons/illustrative/darkTheme/MagnifyingGlassWithDollarSign';
import MapPinDarkTheme from './icons/illustrative/darkTheme/MapPin';
import MapPinInformationalDarkTheme from './icons/illustrative/darkTheme/MapPinInformational';
import MedalWithStarDarkTheme from './icons/illustrative/darkTheme/MedalWithStar';
import MicrophoneWithCableDarkTheme from './icons/illustrative/darkTheme/MicrophoneWithCable';
import MobileDeviceWithTwoWordBubblesDarkTheme from './icons/illustrative/darkTheme/MobileDeviceWithTwoWordBubbles';
import MobileDeviceWithWifiSymbolDarkTheme from './icons/illustrative/darkTheme/MobileDeviceWithWifiSymbol';
import MountaintopWithFlagDarkTheme from './icons/illustrative/darkTheme/MountaintopWithFlag';
import OneArrowPointingUpToHorizontalLineDarkTheme from './icons/illustrative/darkTheme/OneArrowPointingUpToHorizontalLine';
import OnePagePaperArticleDarkTheme from './icons/illustrative/darkTheme/OnePagePaperArticle';
import OnePersonOnChampionshipPodiumFirstPlaceDarkTheme from './icons/illustrative/darkTheme/OnePersonOnChampionshipPodiumFirstPlace';
import PadlockDisengagedDarkTheme from './icons/illustrative/darkTheme/PadlockDisengaged';
import PadlockEngagedDarkTheme from './icons/illustrative/darkTheme/PadlockEngaged';
import PaperMapDarkTheme from './icons/illustrative/darkTheme/PaperMap';
import PaperMoneyStackDarkTheme from './icons/illustrative/darkTheme/PaperMoneyStack';
import PaperShoppingBagEmptyDarkTheme from './icons/illustrative/darkTheme/PaperShoppingBagEmpty';
import PaperWithDeadFaceDarkTheme from './icons/illustrative/darkTheme/PaperWithDeadFace';
import PaperWithDollarSignAndBarChartDarkTheme from './icons/illustrative/darkTheme/PaperWithDollarSignAndBarChart';
import PersonHoldingGroundedFlagDarkTheme from './icons/illustrative/darkTheme/PersonHoldingGroundedFlag';
import PersonWithLightbulbWordBubbleDarkTheme from './icons/illustrative/darkTheme/PersonWithLightbulbWordBubble';
import PersonWithOutwardArrowsDarkTheme from './icons/illustrative/darkTheme/PersonWithOutwardArrows';
import PersonWithPastGrowthHeightLinesDarkTheme from './icons/illustrative/darkTheme/PersonWithPastGrowthHeightLines';
import PersonWithPlusCircleDarkTheme from './icons/illustrative/darkTheme/PersonWithPlusCircle';
import PersonWithXCircleDarkTheme from './icons/illustrative/darkTheme/PersonWithXCircle';
import PiggybankWithCoinDarkTheme from './icons/illustrative/darkTheme/PiggybankWithCoin';
import PottedPlantWithDollarSignCircleDarkTheme from './icons/illustrative/darkTheme/PottedPlantWithDollarSignCircle';
import PriceTagWithDollarSignDarkTheme from './icons/illustrative/darkTheme/PriceTagWithDollarSign';
import ProjectorScreenWithPieChartDarkTheme from './icons/illustrative/darkTheme/ProjectorScreenWithPieChart';
import RibbonWithCheckmarkDarkTheme from './icons/illustrative/darkTheme/RibbonWithCheckmark';
import RocketDarkTheme from './icons/illustrative/darkTheme/Rocket';
import SailboatOnWaterDarkTheme from './icons/illustrative/darkTheme/SailboatOnWater';
import SettingsSliderDarkTheme from './icons/illustrative/darkTheme/SettingsSlider';
import ShieldWithClock180ArrowDarkTheme from './icons/illustrative/darkTheme/ShieldWithClock180Arrow';
import ShieldWithClock360ArrowDarkTheme from './icons/illustrative/darkTheme/ShieldWithClock360Arrow';
import ShieldWithCrossDarkTheme from './icons/illustrative/darkTheme/ShieldWithCross';
import ShieldWithDollarSignDarkTheme from './icons/illustrative/darkTheme/ShieldWithDollarSign';
import ShieldWithGlobe2ArrowsDarkTheme from './icons/illustrative/darkTheme/ShieldWithGlobe2Arrows';
import ShieldWithHeartDarkTheme from './icons/illustrative/darkTheme/ShieldWithHeart';
import ShieldWithKeyholeDarkTheme from './icons/illustrative/darkTheme/ShieldWithKeyhole';
import SquareWithPlusMinusMultiplyEqualQuadrantsDarkTheme from './icons/illustrative/darkTheme/SquareWithPlusMinusMultiplyEqualQuadrants';
import StopwatchWithDollarSignDarkTheme from './icons/illustrative/darkTheme/StopwatchWithDollarSign';
import SubwayTrainOnTracksDarkTheme from './icons/illustrative/darkTheme/SubwayTrainOnTracks';
import SunsetOverWaterHorizonDarkTheme from './icons/illustrative/darkTheme/SunsetOverWaterHorizon';
import TelephoneWithOutgoingSoundDarkTheme from './icons/illustrative/darkTheme/TelephoneWithOutgoingSound';
import ThreeConnectedNodesDarkTheme from './icons/illustrative/darkTheme/ThreeConnectedNodes';
import ThreePeopleConnectedByInnerLinesDarkTheme from './icons/illustrative/darkTheme/ThreePeopleConnectedByInnerLines';
import ThreePeopleConnectedByOuterLinesDarkTheme from './icons/illustrative/darkTheme/ThreePeopleConnectedByOuterLines';
import ThreePeopleWithDollarSignDarkTheme from './icons/illustrative/darkTheme/ThreePeopleWithDollarSign';
import ThumbsUpDarkTheme from './icons/illustrative/darkTheme/ThumbsUp';
import TwoInformationalWordBubblesDarkTheme from './icons/illustrative/darkTheme/TwoInformationalWordBubbles';
import TwoMapPinsConnectedWithCurvyDottedLineDarkTheme from './icons/illustrative/darkTheme/TwoMapPinsConnectedWithCurvyDottedLine';
import TwoPapersWithDollarSignDarkTheme from './icons/illustrative/darkTheme/TwoPapersWithDollarSign';
import TwoPeopleOneWayArrowDarkTheme from './icons/illustrative/darkTheme/TwoPeopleOneWayArrow';
import TwoPeopleOneWordBubbleDarkTheme from './icons/illustrative/darkTheme/TwoPeopleOneWordBubble';
import TwoPeopleTwoWayArrowsDarkTheme from './icons/illustrative/darkTheme/TwoPeopleTwoWayArrows';
import TwoPeopleTwoWordBubblesDarkTheme from './icons/illustrative/darkTheme/TwoPeopleTwoWordBubbles';
import TwoPersonTournamentBracketWithTrophyDarkTheme from './icons/illustrative/darkTheme/TwoPersonTournamentBracketWithTrophy';
import TwoRevolvingArrowsOvalDarkTheme from './icons/illustrative/darkTheme/TwoRevolvingArrowsOval';
import UmbrellaDarkTheme from './icons/illustrative/darkTheme/Umbrella';
import WalletDarkTheme from './icons/illustrative/darkTheme/Wallet';
import WebPageWithDollarSignDarkTheme from './icons/illustrative/darkTheme/WebPageWithDollarSign';

// import Utility Icons
import AchievementMedalUtility from './icons/utility/AchievementMedal';
import AirplaneUtility from './icons/utility/Airplane';
import AlarmClockUtility from './icons/utility/AlarmClock';
import ArrowDownIntoBoxUtility from './icons/utility/ArrowDownIntoBox';
import ArrowDownToLineUtility from './icons/utility/ArrowDownToLine';
import ArrowRightUtility from './icons/utility/ArrowRight';
import ArrowRightAltUtility from './icons/utility/ArrowRightAlt';
import ArrowUpUtility from './icons/utility/ArrowUp';
import ArrowUpToLineUtility from './icons/utility/ArrowUpToLine';
import AudioUtility from './icons/utility/Audio';
import AudioMutedUtility from './icons/utility/AudioMuted';
import BabyFaceUtility from './icons/utility/BabyFace';
import BagWithDollarSignUtility from './icons/utility/BagWithDollarSign';
import BellUtility from './icons/utility/Bell';
import BoatUtility from './icons/utility/Boat';
import BoneUtility from './icons/utility/Bone';
import BookmarkUtility from './icons/utility/Bookmark';
import BriefcaseUtility from './icons/utility/Briefcase';
import BriefcaseWithCrossUtility from './icons/utility/BriefcaseWithCross';
import BoxWithArrowPointingOutwardIconUtility from './icons/utility/BoxWithArrowPointingOutward';
import CalculatorUtility from './icons/utility/Calculator';
import CalendarUtility from './icons/utility/Calendar';
import CarUtility from './icons/utility/Car';
import CaratRightUtility from './icons/utility/CaratRight';
import ChampagneFluteHalfFullUtility from './icons/utility/ChampagneFluteHalfFull';
import CheckbookUtility from './icons/utility/Checkbook';
import CheckmarkBoldUtility from './icons/utility/CheckmarkBold';
import ChevronDownUtility from './icons/utility/ChevronDown';
import ChevronLeftUtility from './icons/utility/ChevronLeft';
import ChevronLeftNarrowUtility from './icons/utility/ChevronLeftNarrow';
import ChevronRightUtility from './icons/utility/ChevronRight';
import ChevronRightNarrowUtility from './icons/utility/ChevronRightNarrow';
import ChevronUpUtility from './icons/utility/ChevronUp';
import CircleArrow360DegreesUtility from './icons/utility/CircleArrow360Degrees';
import CircleWithDiagonalLineUtility from './icons/utility/CircleWithDiagonalLine';
import CircleWithDollarSignUtility from './icons/utility/CircleWithDollarSign';
import CircleWithExclamationPointUtility from './icons/utility/CircleWithExclamationPoint';
import CircleWithExclamationPointFillUtility from './icons/utility/CircleWithExclamationPointFill';
import CircleWithPersonUtility from './icons/utility/CircleWithPerson';
import CircleWithQuestionMarkUtility from './icons/utility/CircleWithQuestionMark';
import CircleWithThreeMultiLengthLinesUtility from './icons/utility/CircleWithThreeMultiLengthLines';
import CircleWithUpsideDownExclamationPointUtility from './icons/utility/CircleWithUpsideDownExclamationPoint';
import ClockUtility from './icons/utility/Clock';
import CloseUtility from './icons/utility/Close';
import CompassUtility from './icons/utility/Compass';
import ComputerMonitorUtility from './icons/utility/ComputerMonitor';
import CreditCardWithArrowDownUtility from './icons/utility/CreditCardWithArrowDown';
import DevicesUtility from './icons/utility/Devices';
import DiamondUtility from './icons/utility/Diamond';
import DollarSignWithRevolvingArrowsUtility from './icons/utility/DollarSignWithRevolvingArrows';
import DrawerCaretUtility from './icons/utility/DrawerCaret';
import DrawerCaretFlippedUtility from './icons/utility/DrawerCaretFlipped';
import EnvelopeUtility from './icons/utility/Envelope';
import EnvelopeWithPaperFoldedCornerUtility from './icons/utility/EnvelopeWithPaperFoldedCorner';
import ExclamationPointUtility from './icons/utility/ExclamationPoint';
import EyeUtility from './icons/utility/Eye';
import EyeWithSlashUtility from './icons/utility/EyeWithSlash';
import FaxUtility from './icons/utility/Fax';
import FlashlightUtility from './icons/utility/Flashlight';
import FolderUtility from './icons/utility/Folder';
import FolderWithFileUtility from './icons/utility/FolderWithFile';
import FrowningFaceUtility from './icons/utility/FrowningFace';
import GearUtility from './icons/utility/Gear';
import HeartUtility from './icons/utility/Heart';
import HouseUtility from './icons/utility/House';
import KeyUtility from './icons/utility/Key';
import KnifeAndForkUtility from './icons/utility/KnifeAndFork';
import LaptopComputerUtility from './icons/utility/LaptopComputer';
import LeafUtility from './icons/utility/Leaf';
import LeftRightDirectionalSignsUtility from './icons/utility/LeftRightDirectionalSigns';
import LightbulbUtility from './icons/utility/Lightbulb';
import LifePreserverUtility from './icons/utility/LifePreserver';
import LockUtility from './icons/utility/Lock';
import MagnifyingGlassUtility from './icons/utility/MagnifyingGlass';
import MapUtility from './icons/utility/Map';
import MapPinUtility from './icons/utility/MapPin';
import MinusSignUtility from './icons/utility/MinusSign';
import MinusSignFillUtility from './icons/utility/MinusSignFill';
import MobileDeviceUtility from './icons/utility/MobileDevice';
import PalmTreeIslandUtility from './icons/utility/PalmTreeIsland';
import PaperFoldedCornerUtility from './icons/utility/PaperFoldedCorner';
import PaperFoldedCornerWithDollarSignUtility from './icons/utility/PaperFoldedCornerWithDollarSign';
import PaperFoldedCornerWithNotaryUtility from './icons/utility/PaperFoldedCornerWithNotary';
import PaperFoldedCornerWithXAndPencilUtility from './icons/utility/PaperFoldedCornerWithXAndPencil';
import PaperMoneyUtility from './icons/utility/PaperMoney';
import PdfUtility from './icons/utility/Pdf';
import PencilUtility from './icons/utility/Pencil';
import PhoneUtility from './icons/utility/Phone';
import PieChartUtility from './icons/utility/PieChart';
import PlayMediaUtility from './icons/utility/PlayMedia';
import PlusSignFillUtility from './icons/utility/PlusSignFill';
import QuotationMarkDoubleCurvedUtility from './icons/utility/QuotationMarkDoubleCurved';
import QuotationMarkDoubleSquareUtility from './icons/utility/QuotationMarkDoubleSquare';
import RightArrowSplitUtility from './icons/utility/RightArrowSplit';
import RolledScrollUtility from './icons/utility/RolledScroll';
import SandalsUtility from './icons/utility/Sandals';
import ShieldWithCheckmarkUtility from './icons/utility/ShieldWithCheckmark';
import SmileyFaceUtility from './icons/utility/SmileyFace';
import SquareWithTwoOppositeDiagonalArrowsUtility from './icons/utility/SquareWithTwoOppositeDiagonalArrows';
import StarUtility from './icons/utility/Star';
import TargetWithArrowUtility from './icons/utility/TargetWithArrow';
import ThreeVerticalDotsUtility from './icons/utility/ThreeVerticalDots';
import TriangleWithExclamationPointUtility from './icons/utility/TriangleWithExclamationPoint';
import TrophyUtility from './icons/utility/Trophy';
import TwoCreditCardsUtility from './icons/utility/TwoCreditCards';
import TwoFoldersUtility from './icons/utility/TwoFolders';
import TwoPagesFoldedCornerUtility from './icons/utility/TwoPagesFoldedCorner';
import VaultUtility from './icons/utility/Vault';
import WalletUtility from './icons/utility/Wallet';
import WrenchUtility from './icons/utility/Wrench';
import WristwatchUtility from './icons/utility/Wristwatch';

// import Social Icons
// TODO: will be building a "Social Icon Link Template" that uses one (positive) icon (not one for positive space and one for negative (filled)). When that happens, all of the social media icons will get that treatment and the consumer will end up importing the "Social Icon Link Template" instead of importing each individual social media icon and constructing the Link each time. For now, use the "Styling Social Icons as Links" example on the Icons page.
import Facebook from './icons/social/Facebook';
import FacebookDislike from './icons/social/FacebookDislike';
import FacebookLightTheme from './icons/social/lightTheme/Facebook'; // deprecated, will be moved to /social/ in 4.x.x
import FacebookLike from './icons/social/FacebookLike';
import FacebookLikeFill from './icons/social/FacebookLikeFill';
import Instagram from './icons/social/Instagram';
import Linkedin from './icons/social/Linkedin';
import LinkedinLightTheme from './icons/social/lightTheme/Linkedin'; // deprecated, will be moved to /social/ in 4.x.x
import Pinterest from './icons/social/Pinterest';
import Twitter from './icons/social/Twitter'; // deprecated
import TwitterLightTheme from './icons/social/lightTheme/Twitter'; // deprecated
import Youtube from './icons/social/Youtube';
import X from './icons/social/X';

const Icon = ({ id, className, variant, ...otherProps }) => {
  const icons = {
  // Illustrative Light Theme
    'apple-with-stem-light-theme':
      <AppleWithStemLightTheme
        className={className}
        {...otherProps} />,
    'atom-light-theme':
      <AtomLightTheme
        className={className}
        {...otherProps} />,
    'awareness-ribbon-light-theme':
      <AwarenessRibbonLightTheme
        className={className}
        {...otherProps} />,
    'baby-buggy-light-theme':
      <BabyBuggyLightTheme
        className={className}
        {...otherProps} />,
    'bar-chart-with-upward-arrow-light-theme':
      <BarChartWithUpwardArrowLightTheme
        className={className}
        {...otherProps} />,
    'beach-chair-with-border-light-theme':
      <BeachChairWithBorderLightTheme
        className={className}
        {...otherProps} />,
    'beach-chair-with-sun-light-theme':
      <BeachChairWithSunLightTheme
        className={className}
        {...otherProps} />,
    'birthday-cake-light-theme':
      <BirthdayCakeLightTheme
        className={className}
        {...otherProps} />,
    'blueprint-with-house-light-theme':
      <BlueprintWithHouseLightTheme
        className={className}
        {...otherProps} />,
    'book-with-bookmark-light-theme':
      <BookWithBookmarkLightTheme
        className={className}
        {...otherProps} />,
    'brain-with-lightning-bolt-light-theme':
      <BrainWithLightningBoltLightTheme
        className={className}
        {...otherProps} />,
    'calendar-light-theme':
      <CalendarLightTheme
        className={className}
        {...otherProps} />,
    'camera-light-theme':
      <CameraLightTheme
        className={className}
        {...otherProps} />,
    'car-moving-light-theme':
      <CarMovingLightTheme
        className={className}
        {...otherProps} />,
    'certificate-with-award-ribbon-light-theme':
      <CertificateWithAwardRibbonLightTheme
        className={className}
        {...otherProps} />,
    'checkmark-with-border-light-theme':
      <CheckmarkWithBorderLightTheme
        className={className}
        {...otherProps} />,
    'cityscape-light-theme':
      <CityscapeLightTheme
        className={className}
        {...otherProps} />,
    'clipboard-with-dollar-sign-paper-light-theme':
      <ClipboardWithDollarSignPaperLightTheme
        className={className}
        {...otherProps} />,
    'clock-light-theme':
      <ClockLightTheme
        className={className}
        {...otherProps} />,
    'coffee-cup-disposable-light-theme':
      <CoffeeCupDisposableLightTheme
        className={className}
        {...otherProps} />,
    'computer-screen-with-mouse-arrow-light-theme':
      <ComputerScreenWithMouseArrowLightTheme
        className={className}
        {...otherProps} />,
    'curved-arrow-through-xxo-light-theme':
      <CurvedArrowThroughXxoLightTheme
        className={className}
        {...otherProps} />,
    'dial-gauge-light-theme':
      <DialGaugeLightTheme
        className={className}
        {...otherProps} />,
    'dog-with-collar-light-theme':
      <DogWithCollarLightTheme
        className={className}
        {...otherProps} />,
    'dollar-sign-with-circle-and-mouse-arrow-light-theme':
      <DollarSignWithCircleAndMouseArrowLightTheme
        className={className}
        {...otherProps} />,
    'dollar-sign-with-four-arrows-pointing-inward-light-theme':
      <DollarSignWithFourArrowsPointingInwardLightTheme
        className={className}
        {...otherProps} />,
    'dollar-sign-revolving-arrows-light-theme':
      <DollarSignRevolvingArrowsLightTheme
        className={className}
        {...otherProps} />,
    'dress-light-theme':
      <DressLightTheme
        className={className}
        {...otherProps} />,
    'drinking-glass-with-straw-and-lemon-wheel-light-theme':
      <DrinkingGlassWithStrawAndLemonWheelLightTheme
        className={className}
        {...otherProps} />,
    'envelope-moving-light-theme':
      <EnvelopeMovingLightTheme
        className={className}
        {...otherProps} />,
    'envelope-with-at-symbol-paper-light-theme':
      <EnvelopeWithAtSymbolPaperLightTheme
        className={className}
        {...otherProps} />,
    'envelope-with-paper-money-light-theme':
      <EnvelopeWithPaperMoneyLightTheme
        className={className}
        {...otherProps} />,
    'family-heart-light-theme':
      <FamilyHeartLightTheme
        className={className}
        {...otherProps} />,
    'fist-holding-pencil-light-theme':
      <FistHoldingPencilLightTheme
        className={className}
        {...otherProps} />,
    'five-connected-nodes-light-theme':
      <FiveConnectedNodesLightTheme
        className={className}
        {...otherProps} />,
    'folder-with-paper-money-light-theme':
      <FolderWithPaperMoneyLightTheme
        className={className}
        {...otherProps} />,
    'four-roman-columns-yellow-roof-dot-light-theme':
      <FourRomanColumnsYellowRoofDotLightTheme
        className={className}
        {...otherProps} />,
    'four-roman-columns-yellow-roof-dot-with-clock-4-o-clock-light-theme':
      <FourRomanColumnsYellowRoofDotWithClock4OClockLightTheme
        className={className}
        {...otherProps} />,
    'four-roman-columns-yellow-roof-dot-with-clock-12-o-clock-light-theme':
      <FourRomanColumnsYellowRoofDotWithClock12OClockLightTheme
        className={className}
        {...otherProps} />,
    'gear-and-globe-connected-with-one-way-arrows-light-theme':
      <GearAndGlobeConnectedWithOneWayArrowsLightTheme
        className={className}
        {...otherProps} />,
    'graduation-hat-with-tassle-light-theme':
      <GraduationHatWithTassleLightTheme
        className={className}
        {...otherProps} />,
    'group-of-six-people-light-theme':
      <GroupOfSixPeopleLightTheme
        className={className}
        {...otherProps} />,
    'hand-holding-credit-card-light-theme':
      <HandHoldingCreditCardLightTheme
        className={className}
        {...otherProps} />,
    'hand-with-three-dollar-signs-light-theme':
      <HandWithThreeDollarSignsLightTheme
        className={className}
        {...otherProps} />,
    'hand-with-three-plus-signs-light-theme':
      <HandWithThreePlusSignsLightTheme
        className={className}
        {...otherProps} />,
    'handshake-light-theme':
      <HandshakeLightTheme
        className={className}
        {...otherProps} />,
    'heart-with-cross-light-theme':
      <HeartWithCrossLightTheme
        className={className}
        {...otherProps} />,
    'heart-with-pulse-oximeter-wave-light-theme':
      <HeartWithPulseOximeterWaveLightTheme
        className={className}
        {...otherProps} />,
    'home-safe-locked-light-theme':
      <HomeSafeLockedLightTheme
        className={className}
        {...otherProps} />,
    'hourglass-light-theme':
      <HourglassLightTheme
        className={className}
        {...otherProps} />,
    'house-light-theme':
      <HouseLightTheme
        className={className}
        {...otherProps} />,
    'house-with-checkmark-shield-light-theme':
      <HouseWithCheckmarkShieldLightTheme
        className={className}
        {...otherProps} />,
    'house-with-hammer-light-theme':
      <HouseWithHammerLightTheme
        className={className}
        {...otherProps} />,
    'house-with-paper-money-light-theme':
      <HouseWithPaperMoneyLightTheme
        className={className}
        {...otherProps} />,
    'identification-badge-light-theme':
      <IdentificationBadgeLightTheme
        className={className}
        {...otherProps} />,
    'information-word-bubble-light-theme':
      <InformationWordBubbleLightTheme
        className={className}
        {...otherProps} />,
    'inner-office-envelope-fastened-with-string-light-theme':
      <InnerOfficeEnvelopeFastenedWithStringLightTheme
        className={className}
        {...otherProps} />,
    'leaf-light-theme':
      <LeafLightTheme
        className={className}
        {...otherProps} />,
    'life-preserver-vest-light-theme':
      <LifePreserverVestLightTheme
        className={className}
        {...otherProps} />,
    'lightbulb-light-theme':
      <LightbulbLightTheme
        className={className}
        {...otherProps} />,
    'lightbulb-with-question-mark-light-theme':
      <LightbulbWithQuestionMarkLightTheme
        className={className}
        {...otherProps} />,
    'line-graph-upwards-trend-light-theme':
      <LineGraphUpwardsTrendLightTheme
        className={className}
        {...otherProps} />,
    'magnifying-glass-light-theme':
      <MagnifyingGlassLightTheme
        className={className}
        {...otherProps} />,
    'magnifying-glass-with-dollar-sign-light-theme':
      <MagnifyingGlassWithDollarSignLightTheme
        className={className}
        {...otherProps} />,
    'map-pin-light-theme':
      <MapPinLightTheme
        className={className}
        {...otherProps} />,
    'map-pin-informational-light-theme':
      <MapPinInformationalLightTheme
        className={className}
        {...otherProps} />,
    'medal-with-star-light-theme':
      <MedalWithStarLightTheme
        className={className}
        {...otherProps} />,
    'microphone-with-cable-light-theme':
      <MicrophoneWithCableLightTheme
        className={className}
        {...otherProps} />,
    'mobile-device-with-two-word-bubbles-light-theme':
      <MobileDeviceWithTwoWordBubblesLightTheme
        className={className}
        {...otherProps} />,
    'mobile-device-with-wifi-symbol-light-theme':
      <MobileDeviceWithWifiSymbolLightTheme
        className={className}
        {...otherProps} />,
    'money-parachute-with-border-light-theme':
      <MoneyParachuteWithBorderLightTheme
        className={className}
        {...otherProps} />,
    'mountaintop-with-flag-light-theme':
      <MountaintopWithFlagLightTheme
        className={className}
        {...otherProps} />,
    'one-arrow-pointing-up-to-horizontal-line-light-theme':
      <OneArrowPointingUpToHorizontalLineLightTheme
        className={className}
        {...otherProps} />,
    'one-map-pin-one-tax-pin-connected-with-curvy-dotted-line-light-theme':
      <OneMapPinOneTaxPinConnectedWithCurvyDottedLinesLightTheme
        className={className}
        {...otherProps} />,
    'one-page-paper-article-light-theme':
      <OnePagePaperArticleLightTheme
        className={className}
        {...otherProps} />,
    'one-person-on-championship-podium-first-place-light-theme':
      <OnePersonOnChampionshipPodiumFirstPlaceLightTheme
        className={className}
        {...otherProps} />,
    'one-tax-pin-one-map-pin-connected-with-curvy-dotted-line-light-theme':
      <OneTaxPinOneMapPinConnectedWithCurvyDottedLinesLightTheme
        className={className}
        {...otherProps} />,
    'padlock-disengaged-light-theme':
      <PadlockDisengagedLightTheme
        className={className}
        {...otherProps} />,
    'padlock-engaged-light-theme':
      <PadlockEngagedLightTheme
        className={className}
        {...otherProps} />,
    'paper-map-light-theme':
      <PaperMapLightTheme
        className={className}
        {...otherProps} />,
    'paper-money-stack-light-theme':
      <PaperMoneyStackLightTheme
        className={className}
        {...otherProps} />,
    'paper-shopping-bag-empty-light-theme':
      <PaperShoppingBagEmptyLightTheme
        className={className}
        {...otherProps} />,
    'paper-with-dead-face-light-theme':
      <PaperWithDeadFaceLightTheme
        className={className}
        {...otherProps} />,
    'paper-with-dollar-sign-and-bar-chart-light-theme':
      <PaperWithDollarSignAndBarChartLightTheme
        className={className}
        {...otherProps} />,
    'person-holding-grounded-flag-light-theme':
      <PersonHoldingGroundedFlagLightTheme
        className={className}
        {...otherProps} />,
    'person-with-lightbulb-word-bubble-light-theme':
      <PersonWithLightbulbWordBubbleLightTheme
        className={className}
        {...otherProps} />,
    'person-with-outward-arrows-light-theme':
      <PersonWithOutwardArrowsLightTheme
        className={className}
        {...otherProps} />,
    'person-with-past-growth-height-lines-light-theme':
      <PersonWithPastGrowthHeightLinesLightTheme
        className={className}
        {...otherProps} />,
    'person-with-plus-circle-light-theme':
      <PersonWithPlusCircleLightTheme
        className={className}
        {...otherProps} />,
    'person-with-x-circle-light-theme':
      <PersonWithXCircleLightTheme
        className={className}
        {...otherProps} />,
    'piggybank-with-coin-light-theme':
      <PiggybankWithCoinLightTheme
        className={className}
        {...otherProps} />,
    'potted-plant-with-dollar-sign-circle-light-theme':
      <PottedPlantWithDollarSignCircleLightTheme
        className={className}
        {...otherProps} />,
    'price-tag-with-dollar-sign-light-theme':
      <PriceTagWithDollarSignLightTheme
        className={className}
        {...otherProps} />,
    'profile-outline-checkmark-with-border-light-theme':
      <ProfileOutlineCheckmarkWithBorderLightTheme
        className={className}
        {...otherProps} />,
    'projector-screen-with-pie-chart-light-theme':
      <ProjectorScreenWithPieChartLightTheme
        className={className}
        {...otherProps} />,
    'ribbon-with-checkmark-light-theme':
      <RibbonWithCheckmarkLightTheme
        className={className}
        {...otherProps} />,
    'rocket-light-theme':
      <RocketLightTheme
        className={className}
        {...otherProps} />,
    'sailboat-on-water-light-theme':
      <SailboatOnWaterLightTheme
        className={className}
        {...otherProps} />,
    'settings-slider-light-theme':
      <SettingsSliderLightTheme
        className={className}
        {...otherProps} />,
    'shield-with-clock-180-arrow-light-theme':
      <ShieldWithClock180ArrowLightTheme
        className={className}
        {...otherProps} />,
    'shield-with-clock-360-arrow-light-theme':
      <ShieldWithClock360ArrowLightTheme
        className={className}
        {...otherProps} />,
    'shield-with-cross-light-theme':
      <ShieldWithCrossLightTheme
        className={className}
        {...otherProps} />,
    'shield-with-dollar-sign-light-theme':
      <ShieldWithDollarSignLightTheme
        className={className}
        {...otherProps} />,
    'shield-with-globe-2-arrows-light-theme':
      <ShieldWithGlobe2ArrowsLightTheme
        className={className}
        {...otherProps} />,
    'shield-with-globe-3-arrows-light-theme':
      <ShieldWithGlobe3ArrowsLightTheme
        className={className}
        {...otherProps} />,
    'shield-with-heart-light-theme':
      <ShieldWithHeartLightTheme
        className={className}
        {...otherProps} />,
    'shield-with-heart-border-light-theme':
      <ShieldWithHeartBorderLightTheme
        className={className}
        {...otherProps} />,
    'shield-with-keyhole-light-theme':
      <ShieldWithKeyholeLightTheme
        className={className}
        {...otherProps} />,
    'square-with-plus-minus-multiply-equal-quadrants-light-theme':
      <SquareWithPlusMinusMultiplyEqualQuadrantsLightTheme
        className={className}
        {...otherProps} />,
    'stopwatch-with-dollar-sign-light-theme':
      <StopwatchWithDollarSignLightTheme
        className={className}
        {...otherProps} />,
    'subway-train-on-tracks-light-theme':
      <SubwayTrainOnTracksLightTheme
        className={className}
        {...otherProps} />,
    'sunset-over-water-horizon-light-theme':
      <SunsetOverWaterHorizonLightTheme
        className={className}
        {...otherProps} />,
    'telephone-with-outgoing-sound-light-theme':
      <TelephoneWithOutgoingSoundLightTheme
        className={className}
        {...otherProps} />,
    'three-connected-nodes-light-theme':
      <ThreeConnectedNodesLightTheme
        className={className}
        {...otherProps} />,
    'three-people-connected-by-inner-lines-light-theme':
      <ThreePeopleConnectedByInnerLinesLightTheme
        className={className}
        {...otherProps} />,
    'three-people-connected-by-outer-lines-light-theme':
      <ThreePeopleConnectedByOuterLinesLightTheme
        className={className}
        {...otherProps} />,
    'three-people-with-dollar-sign-light-theme':
      <ThreePeopleWithDollarSignLightTheme
        className={className}
        {...otherProps} />,
    'thumbs-up-light-theme':
      <ThumbsUpLightTheme
        className={className}
        {...otherProps} />,
    'two-informational-word-bubbles-light-theme':
      <TwoInformationalWordBubblesLightTheme
        className={className}
        {...otherProps} />,
    'two-leaves-light-theme':
      <TwoLeavesLightTheme
        className={className}
        {...otherProps} />,
    'two-map-pins-connected-with-curvy-dotted-line-light-theme':
      <TwoMapPinsConnectedWithCurvyDottedLineLightTheme
        className={className}
        {...otherProps} />,
    'two-papers-with-dollar-sign-light-theme':
      <TwoPapersWithDollarSignLightTheme
        className={className}
        {...otherProps} />,
    'two-people-one-way-arrow-light-theme':
      <TwoPeopleOneWayArrowLightTheme
        className={className}
        {...otherProps} />,
    'two-people-one-word-bubble-light-theme':
      <TwoPeopleOneWordBubbleLightTheme
        className={className}
        {...otherProps} />,
    'two-people-two-way-arrows-light-theme':
      <TwoPeopleTwoWayArrowsLightTheme
        className={className}
        {...otherProps} />,
    'two-people-two-word-bubbles-light-theme':
      <TwoPeopleTwoWordBubblesLightTheme
        className={className}
        {...otherProps} />,
    'two-person-tournament-bracket-with-trophy-light-theme':
      <TwoPersonTournamentBracketWithTrophyLightTheme
        className={className}
        {...otherProps} />,
    'two-revolving-arrows-oval-light-theme':
      <TwoRevolvingArrowsOvalLightTheme
        className={className}
        {...otherProps} />,
    'umbrella-light-theme':
      <UmbrellaLightTheme
        className={className}
        {...otherProps} />,
    'umbrella-with-border-light-theme':
      <UmbrellaWithBorderLightTheme
        className={className}
        {...otherProps} />,
    'wallet-light-theme':
      <WalletLightTheme
        className={className}
        {...otherProps} />,
    'web-page-with-dollar-sign-light-theme':
      <WebPageWithDollarSignLightTheme
        className={className}
        {...otherProps} />,

    // Illustrative Dark Theme
    'apple-with-stem-dark-theme':
      <AppleWithStemDarkTheme
        className={className}
        {...otherProps} />,
    'atom-dark-theme':
      <AtomDarkTheme
        className={className}
        {...otherProps} />,
    'baby-buggy-dark-theme':
      <BabyBuggyDarkTheme
        className={className}
        {...otherProps} />,
    'bar-chart-with-upward-arrow-dark-theme':
      <BarChartWithUpwardArrowDarkTheme
        className={className}
        {...otherProps} />,
    'beach-chair-with-sun-dark-theme':
      <BeachChairWithSunDarkTheme
        className={className}
        {...otherProps} />,
    'birthday-cake-dark-theme':
      <BirthdayCakeDarkTheme
        className={className}
        {...otherProps} />,
    'blueprint-with-house-dark-theme':
      <BlueprintWithHouseDarkTheme
        className={className}
        {...otherProps} />,
    'book-with-bookmark-dark-theme':
      <BookWithBookmarkDarkTheme
        className={className}
        {...otherProps} />,
    'brain-with-lightning-bolt-dark-theme':
      <BrainWithLightningBoltDarkTheme
        className={className}
        {...otherProps} />,
    'calendar-dark-theme':
      <CalendarDarkTheme
        className={className}
        {...otherProps} />,
    'camera-dark-theme':
      <CameraDarkTheme
        className={className}
        {...otherProps} />,
    'car-moving-dark-theme':
      <CarMovingDarkTheme
        className={className}
        {...otherProps} />,
    'certificate-with-award-ribbon-dark-theme':
      <CertificateWithAwardRibbonDarkTheme
        className={className}
        {...otherProps} />,
    'cityscape-dark-theme':
      <CityscapeDarkTheme
        className={className}
        {...otherProps} />,
    'clipboard-with-dollar-sign-paper-dark-theme':
      <ClipboardWithDollarSignPaperDarkTheme
        className={className}
        {...otherProps} />,
    'clock-dark-theme':
      <ClockDarkTheme
        className={className}
        {...otherProps} />,
    'coffee-cup-disposable-dark-theme':
      <CoffeeCupDisposableDarkTheme
        className={className}
        {...otherProps} />,
    'computer-screen-with-mouse-arrow-dark-theme':
      <ComputerScreenWithMouseArrowDarkTheme
        className={className}
        {...otherProps} />,
    'curved-arrow-through-xxo-dark-theme':
      <CurvedArrowThroughXxoDarkTheme
        className={className}
        {...otherProps} />,
    'dial-gauge-dark-theme':
      <DialGaugeDarkTheme
        className={className}
        {...otherProps} />,
    'dog-with-collar-dark-theme':
      <DogWithCollarDarkTheme
        className={className}
        {...otherProps} />,
    'dollar-sign-revolving-arrows-dark-theme':
      <DollarSignRevolvingArrowsDarkTheme
        className={className}
        {...otherProps} />,
    'dollar-sign-with-circle-and-mouse-arrow-dark-theme':
      <DollarSignWithCircleAndMouseArrowDarkTheme
        className={className}
        {...otherProps} />,
    'dollar-sign-with-four-arrows-pointing-inward-dark-theme':
      <DollarSignWithFourArrowsPointingInwardDarkTheme
        className={className}
        {...otherProps} />,
    'dress-dark-theme':
      <DressDarkTheme
        className={className}
        {...otherProps} />,
    'drinking-glass-with-straw-and-lemon-wheel-dark-theme':
      <DrinkingGlassWithStrawAndLemonWheelDarkTheme
        className={className}
        {...otherProps} />,
    'envelope-moving-dark-theme':
      <EnvelopeMovingDarkTheme
        className={className}
        {...otherProps} />,
    'envelope-with-at-symbol-paper-dark-theme':
      <EnvelopeWithAtSymbolPaperDarkTheme
        className={className}
        {...otherProps} />,
    'envelope-with-paper-money-dark-theme':
      <EnvelopeWithPaperMoneyDarkTheme
        className={className}
        {...otherProps} />,
    'family-heart-dark-theme':
      <FamilyHeartDarkTheme
        className={className}
        {...otherProps} />,
    'fist-holding-pencil-dark-theme':
      <FistHoldingPencilDarkTheme
        className={className}
        {...otherProps} />,
    'five-connected-nodes-dark-theme':
      <FiveConnectedNodesDarkTheme
        className={className}
        {...otherProps} />,
    'folder-with-paper-money-dark-theme':
      <FolderWithPaperMoneyDarkTheme
        className={className}
        {...otherProps} />,
    'four-roman-columns-yellow-roof-dot-dark-theme':
      <FourRomanColumnsYellowRoofDotDarkTheme
        className={className}
        {...otherProps} />,
    'four-roman-columns-yellow-roof-dot-with-clock-4-o-clock-dark-theme':
      <FourRomanColumnsYellowRoofDotWithClock4OClockDarkTheme
        className={className}
        {...otherProps} />,
    'four-roman-columns-yellow-roof-dot-with-clock-12-o-clock-dark-theme':
      <FourRomanColumnsYellowRoofDotWithClock12OClockDarkTheme
        className={className}
        {...otherProps} />,
    'gear-and-globe-connected-with-one-way-arrows-dark-theme':
      <GearAndGlobeConnectedWithOneWayArrowsDarkTheme
        className={className}
        {...otherProps} />,
    'graduation-hat-with-tassle-dark-theme':
      <GraduationHatWithTassleDarkTheme
        className={className}
        {...otherProps} />,
    'hand-holding-credit-card-dark-theme':
      <HandHoldingCreditCardDarkTheme
        className={className}
        {...otherProps} />,
    'hand-with-three-dollar-signs-dark-theme':
      <HandWithThreeDollarSignsDarkTheme
        className={className}
        {...otherProps} />,
    'hand-with-three-plus-signs-dark-theme':
      <HandWithThreePlusSignsDarkTheme
        className={className}
        {...otherProps} />,
    'handshake-dark-theme':
      <HandshakeDarkTheme
        className={className}
        {...otherProps} />,
    'heart-with-cross-dark-theme':
      <HeartWithCrossDarkTheme
        className={className}
        {...otherProps} />,
    'heart-with-pulse-oximeter-wave-dark-theme':
      <HeartWithPulseOximeterWaveDarkTheme
        className={className}
        {...otherProps} />,
    'home-safe-locked-dark-theme':
      <HomeSafeLockedDarkTheme
        className={className}
        {...otherProps} />,
    'hourglass-dark-theme':
      <HourglassDarkTheme
        className={className}
        {...otherProps} />,
    'house-dark-theme':
      <HouseDarkTheme
        className={className}
        {...otherProps} />,
    'house-with-checkmark-shield-dark-theme':
      <HouseWithCheckmarkShieldDarkTheme
        className={className}
        {...otherProps} />,
    'house-with-hammer-dark-theme':
      <HouseWithHammerDarkTheme
        className={className}
        {...otherProps} />,
    'house-with-paper-money-dark-theme':
      <HouseWithPaperMoneyDarkTheme
        className={className}
        {...otherProps} />,
    'identification-badge-dark-theme':
      <IdentificationBadgeDarkTheme
        className={className}
        {...otherProps} />,
    'inner-office-envelope-fastened-with-string-dark-theme':
      <InnerOfficeEnvelopeFastenedWithStringDarkTheme
        className={className}
        {...otherProps} />,
    'leaf-dark-theme':
      <LeafDarkTheme
        className={className}
        {...otherProps} />,
    'life-preserver-vest-dark-theme':
      <LifePreserverVestDarkTheme
        className={className}
        {...otherProps} />,
    'lightbulb-dark-theme':
      <LightbulbDarkTheme
        className={className}
        {...otherProps} />,
    'line-graph-upwards-trend-dark-theme':
      <LineGraphUpwardsTrendDarkTheme
        className={className}
        {...otherProps} />,
    'magnifying-glass-dark-theme':
      <MagnifyingGlassDarkTheme
        className={className}
        {...otherProps} />,
    'magnifying-glass-with-dollar-sign-dark-theme':
      <MagnifyingGlassWithDollarSignDarkTheme
        className={className}
        {...otherProps} />,
    'map-pin-dark-theme':
      <MapPinDarkTheme
        className={className}
        {...otherProps} />,
    'map-pin-informational-dark-theme':
      <MapPinInformationalDarkTheme
        className={className}
        {...otherProps} />,
    'medal-with-star-dark-theme':
      <MedalWithStarDarkTheme
        className={className}
        {...otherProps} />,
    'microphone-with-cable-dark-theme':
      <MicrophoneWithCableDarkTheme
        className={className}
        {...otherProps} />,
    'mobile-device-with-two-word-bubbles-dark-theme':
      <MobileDeviceWithTwoWordBubblesDarkTheme
        className={className}
        {...otherProps} />,
    'mobile-device-with-wifi-symbol-dark-theme':
      <MobileDeviceWithWifiSymbolDarkTheme
        className={className}
        {...otherProps} />,
    'mountaintop-with-flag-dark-theme':
      <MountaintopWithFlagDarkTheme
        className={className}
        {...otherProps} />,
    'one-arrow-pointing-up-to-horizontal-line-dark-theme':
      <OneArrowPointingUpToHorizontalLineDarkTheme
        className={className}
        {...otherProps} />,
    'one-page-paper-article-dark-theme':
      <OnePagePaperArticleDarkTheme
        className={className}
        {...otherProps} />,
    'one-person-on-championship-podium-first-place-dark-theme':
      <OnePersonOnChampionshipPodiumFirstPlaceDarkTheme
        className={className}
        {...otherProps} />,
    'padlock-disengaged-dark-theme':
      <PadlockDisengagedDarkTheme
        className={className}
        {...otherProps} />,
    'padlock-engaged-dark-theme':
      <PadlockEngagedDarkTheme
        className={className}
        {...otherProps} />,
    'paper-map-dark-theme':
      <PaperMapDarkTheme
        className={className}
        {...otherProps} />,
    'paper-money-stack-dark-theme':
      <PaperMoneyStackDarkTheme
        className={className}
        {...otherProps} />,
    'paper-shopping-bag-empty-dark-theme':
      <PaperShoppingBagEmptyDarkTheme
        className={className}
        {...otherProps} />,
    'paper-with-dead-face-dark-theme':
      <PaperWithDeadFaceDarkTheme
        className={className}
        {...otherProps} />,
    'paper-with-dollar-sign-and-bar-chart-dark-theme':
      <PaperWithDollarSignAndBarChartDarkTheme
        className={className}
        {...otherProps} />,
    'person-holding-grounded-flag-dark-theme':
      <PersonHoldingGroundedFlagDarkTheme
        className={className}
        {...otherProps} />,
    'person-with-lightbulb-word-bubble-dark-theme':
      <PersonWithLightbulbWordBubbleDarkTheme
        className={className}
        {...otherProps} />,
    'person-with-outward-arrows-dark-theme':
      <PersonWithOutwardArrowsDarkTheme
        className={className}
        {...otherProps} />,
    'person-with-past-growth-height-lines-dark-theme':
      <PersonWithPastGrowthHeightLinesDarkTheme
        className={className}
        {...otherProps} />,
    'person-with-plus-circle-dark-theme':
      <PersonWithPlusCircleDarkTheme
        className={className}
        {...otherProps} />,
    'person-with-x-circle-dark-theme':
      <PersonWithXCircleDarkTheme
        className={className}
        {...otherProps} />,
    'piggybank-with-coin-dark-theme':
      <PiggybankWithCoinDarkTheme
        className={className}
        {...otherProps} />,
    'potted-plant-with-dollar-sign-circle-dark-theme':
      <PottedPlantWithDollarSignCircleDarkTheme
        className={className}
        {...otherProps} />,
    'price-tag-with-dollar-sign-dark-theme':
      <PriceTagWithDollarSignDarkTheme
        className={className}
        {...otherProps} />,
    'projector-screen-with-pie-chart-dark-theme':
      <ProjectorScreenWithPieChartDarkTheme
        className={className}
        {...otherProps} />,
    'ribbon-with-checkmark-dark-theme':
      <RibbonWithCheckmarkDarkTheme
        className={className}
        {...otherProps} />,
    'rocket-dark-theme':
      <RocketDarkTheme
        className={className}
        {...otherProps} />,
    'sailboat-on-water-dark-theme':
      <SailboatOnWaterDarkTheme
        className={className}
        {...otherProps} />,
    'settings-slider-dark-theme':
      <SettingsSliderDarkTheme
        className={className}
        {...otherProps} />,
    'shield-with-clock-180-arrow-dark-theme':
      <ShieldWithClock180ArrowDarkTheme
        className={className}
        {...otherProps} />,
    'shield-with-clock-360-arrow-dark-theme':
      <ShieldWithClock360ArrowDarkTheme
        className={className}
        {...otherProps} />,
    'shield-with-cross-dark-theme':
      <ShieldWithCrossDarkTheme
        className={className}
        {...otherProps} />,
    'shield-with-dollar-sign-dark-theme':
      <ShieldWithDollarSignDarkTheme
        className={className}
        {...otherProps} />,
    'shield-with-heart-dark-theme':
      <ShieldWithHeartDarkTheme
        className={className}
        {...otherProps} />,
    'shield-with-globe-2-arrows-dark-theme':
      <ShieldWithGlobe2ArrowsDarkTheme
        className={className}
        {...otherProps} />,
    'shield-with-keyhole-dark-theme':
      <ShieldWithKeyholeDarkTheme
        className={className}
        {...otherProps} />,
    'square-with-plus-minus-multiply-equal-quadrants-dark-theme':
      <SquareWithPlusMinusMultiplyEqualQuadrantsDarkTheme
        className={className}
        {...otherProps} />,
    'stopwatch-with-dollar-sign-dark-theme':
      <StopwatchWithDollarSignDarkTheme
        className={className}
        {...otherProps} />,
    'subway-train-on-tracks-dark-theme':
      <SubwayTrainOnTracksDarkTheme
        className={className}
        {...otherProps} />,
    'sunset-over-water-horizon-dark-theme':
      <SunsetOverWaterHorizonDarkTheme
        className={className}
        {...otherProps} />,
    'telephone-with-outgoing-sound-dark-theme':
      <TelephoneWithOutgoingSoundDarkTheme
        className={className}
        {...otherProps} />,
    'three-connected-nodes-dark-theme':
      <ThreeConnectedNodesDarkTheme
        className={className}
        {...otherProps} />,
    'three-people-connected-by-inner-lines-dark-theme':
      <ThreePeopleConnectedByInnerLinesDarkTheme
        className={className}
        {...otherProps} />,
    'three-people-connected-by-outer-lines-dark-theme':
      <ThreePeopleConnectedByOuterLinesDarkTheme
        className={className}
        {...otherProps} />,
    'three-people-with-dollar-sign-dark-theme':
      <ThreePeopleWithDollarSignDarkTheme
        className={className}
        {...otherProps} />,
    'thumbs-up-dark-theme':
      <ThumbsUpDarkTheme
        className={className}
        {...otherProps} />,
    'two-informational-word-bubbles-dark-theme':
      <TwoInformationalWordBubblesDarkTheme
        className={className}
        {...otherProps} />,
    'two-map-pins-connected-with-curvy-dotted-line-dark-theme':
      <TwoMapPinsConnectedWithCurvyDottedLineDarkTheme
        className={className}
        {...otherProps} />,
    'two-papers-with-dollar-sign-dark-theme':
      <TwoPapersWithDollarSignDarkTheme
        className={className}
        {...otherProps} />,
    'two-people-one-way-arrow-dark-theme':
      <TwoPeopleOneWayArrowDarkTheme
        className={className}
        {...otherProps} />,
    'two-people-one-word-bubble-dark-theme':
      <TwoPeopleOneWordBubbleDarkTheme
        className={className}
        {...otherProps} />,
    'two-people-two-way-arrows-dark-theme':
      <TwoPeopleTwoWayArrowsDarkTheme
        className={className}
        {...otherProps} />,
    'two-people-two-word-bubbles-dark-theme':
      <TwoPeopleTwoWordBubblesDarkTheme
        className={className}
        {...otherProps} />,
    'two-person-tournament-bracket-with-trophy-dark-theme':
      <TwoPersonTournamentBracketWithTrophyDarkTheme
        className={className}
        {...otherProps} />,
    'two-revolving-arrows-oval-dark-theme':
      <TwoRevolvingArrowsOvalDarkTheme
        className={className}
        {...otherProps} />,
    'umbrella-dark-theme':
      <UmbrellaDarkTheme
        className={className}
        {...otherProps} />,
    'wallet-dark-theme':
      <WalletDarkTheme
        className={className}
        {...otherProps} />,
    'web-page-with-dollar-sign-dark-theme':
      <WebPageWithDollarSignDarkTheme
        className={className}
        {...otherProps} />,

    // utility
    'achievement-medal-utility':
      <AchievementMedalUtility
        className={className}
        {...otherProps} />,
    'airplane-utility':
      <AirplaneUtility
        className={className}
        {...otherProps} />,
    'alarm-clock-utility':
      <AlarmClockUtility
        className={className}
        {...otherProps} />,
    'arrow-down-into-box-utility':
      <ArrowDownIntoBoxUtility
        className={className}
        {...otherProps} />,
    'arrow-down-to-line-utility':
      <ArrowDownToLineUtility
        className={className}
        {...otherProps} />,
    'arrow-right-utility':
      <ArrowRightUtility
        className={className}
        {...otherProps} />,
    'arrow-right-alt-utility':
      <ArrowRightAltUtility
        className={className}
        {...otherProps} />,
    'arrow-up-utility':
      <ArrowUpUtility
        className={className}
        {...otherProps} />,
    'arrow-up-to-line-utility':
      <ArrowUpToLineUtility
        className={className}
        {...otherProps} />,
    'audio-utility':
      <AudioUtility
        className={className}
        {...otherProps} />,
    'audio-muted-utility':
      <AudioMutedUtility
        className={className}
        {...otherProps} />,
    'baby-face-utility':
      <BabyFaceUtility
        className={className}
        {...otherProps} />,
    'bag-with-dollar-sign-utility':
      <BagWithDollarSignUtility
        className={className}
        {...otherProps} />,
    'bell-utility':
      <BellUtility
        className={className}
        {...otherProps} />,
    'boat-utility':
      <BoatUtility
        className={className}
        {...otherProps} />,
    'bone-utility':
      <BoneUtility
        className={className}
        {...otherProps} />,
    'bookmark-utility':
      <BookmarkUtility
        className={className}
        {...otherProps} />,
    'box-with-arrow-pointing-outward-utility':
      <BoxWithArrowPointingOutwardIconUtility
        className={className}
        {...otherProps} />,
    'briefcase-utility':
      <BriefcaseUtility
        className={className}
        {...otherProps} />,
    'briefcase-with-cross-utility':
      <BriefcaseWithCrossUtility
        className={className}
        {...otherProps} />,
    'calculator-utility':
      <CalculatorUtility
        className={className}
        {...otherProps} />,
    'calendar-utility':
      <CalendarUtility
        className={className}
        {...otherProps} />,
    'car-utility':
      <CarUtility
        className={className}
        {...otherProps} />,
    'carat-right-utility':
      <CaratRightUtility
        className={className}
        {...otherProps} />,
    'champagne-flute-half-full-utility':
      <ChampagneFluteHalfFullUtility
        className={className}
        {...otherProps} />,
    'checkbook-utility':
      <CheckbookUtility
        className={className}
        {...otherProps} />,
    'checkmark-bold-utility':
      <CheckmarkBoldUtility
        className={className}
        {...otherProps} />,
    'chevron-down-utility':
      <ChevronDownUtility
        className={className}
        {...otherProps} />,
    'chevron-left-utility':
      <ChevronLeftUtility
        className={className}
        {...otherProps} />,
    'chevron-left-narrow-utility':
      <ChevronLeftNarrowUtility
        className={className}
        {...otherProps} />,
    'chevron-right-utility':
      <ChevronRightUtility
        className={className}
        {...otherProps} />,
    'chevron-right-narrow-utility':
      <ChevronRightNarrowUtility
        className={className}
        {...otherProps} />,
    'chevron-up-utility':
      <ChevronUpUtility
        className={className}
        {...otherProps} />,
    'circle-arrow-360-degrees-utility':
      <CircleArrow360DegreesUtility
        className={className}
        {...otherProps} />,
    'circle-with-diagonal-line-utility':
      <CircleWithDiagonalLineUtility
        className={className}
        {...otherProps} />,
    'circle-with-dollar-sign-utility':
      <CircleWithDollarSignUtility
        className={className}
        {...otherProps} />,
    'circle-with-exclamation-point-utility':
      <CircleWithExclamationPointUtility
        className={className}
        {...otherProps} />,
    'circle-with-exclamation-point-fill-utility':
      <CircleWithExclamationPointFillUtility
        className={className}
        {...otherProps} />,
    'circle-with-person-utility':
      <CircleWithPersonUtility
        className={className}
        {...otherProps} />,
    'circle-with-question-mark-utility':
      <CircleWithQuestionMarkUtility
        className={className}
        {...otherProps} />,
    'circle-with-three-multi-length-lines-utility':
      <CircleWithThreeMultiLengthLinesUtility
        className={className}
        {...otherProps} />,
    'circle-with-upside-down-exclamation-point-utility':
      <CircleWithUpsideDownExclamationPointUtility
        className={className}
        {...otherProps} />,
    'clock-utility':
      <ClockUtility
        className={className}
        {...otherProps} />,
    'close-utility':
      <CloseUtility
        className={className}
        {...otherProps} />,
    'compass-utility':
      <CompassUtility
        className={className}
        {...otherProps} />,
    'computer-monitor-utility':
      <ComputerMonitorUtility
        className={className}
        {...otherProps} />,
    'credit-card-with-arrow-down-utility':
      <CreditCardWithArrowDownUtility
        className={className}
        {...otherProps} />,
    'devices-utility':
      <DevicesUtility
        className={className}
        {...otherProps} />,
    'diamond-utility':
      <DiamondUtility
        className={className}
        {...otherProps} />,
    'dollar-sign-with-revolving-arrows-utility':
      <DollarSignWithRevolvingArrowsUtility
        className={className}
        {...otherProps} />,
    'drawer-caret-utility':
      <DrawerCaretUtility
        className={className}
        {...otherProps} />,
    'drawer-caret-flipped-utility':
      <DrawerCaretFlippedUtility
        className={className}
        {...otherProps} />,
    'envelope-utility':
      <EnvelopeUtility
        className={className}
        {...otherProps} />,
    'envelope-with-paper-folded-corner-utility':
      <EnvelopeWithPaperFoldedCornerUtility
        className={className}
        {...otherProps} />,
    'exclamation-point-utility':
      <ExclamationPointUtility
        className={className}
        {...otherProps} />,
    'eye-utility':
      <EyeUtility
        className={className}
        {...otherProps} />,
    'eye-with-slash-utility':
      <EyeWithSlashUtility
        className={className}
        {...otherProps} />,
    'fax-utility':
      <FaxUtility
        className={className}
        {...otherProps} />,
    'flashlight-utility':
      <FlashlightUtility
        className={className}
        {...otherProps} />,
    'folder-utility':
      <FolderUtility
        className={className}
        {...otherProps} />,
    'folder-with-file-utility':
      <FolderWithFileUtility
        className={className}
        {...otherProps} />,
    'frowning-face-utility':
      <FrowningFaceUtility
        className={className}
        {...otherProps} />,
    'gear-utility':
      <GearUtility
        className={className}
        {...otherProps} />,
    'heart-utility':
      <HeartUtility
        className={className}
        {...otherProps} />,
    'house-utility':
      <HouseUtility
        className={className}
        {...otherProps} />,
    'key-utility':
      <KeyUtility
        className={className}
        {...otherProps} />,
    'knife-and-fork-utility':
      <KnifeAndForkUtility
        className={className}
        {...otherProps} />,
    'laptop-computer-utility':
      <LaptopComputerUtility
        className={className}
        {...otherProps} />,
    'leaf-utility':
      <LeafUtility
        className={className}
        {...otherProps} />,
    'left-right-directional-signs-utility':
      <LeftRightDirectionalSignsUtility
        className={className}
        {...otherProps} />,
    'lightbulb-utility':
      <LightbulbUtility
        className={className}
        {...otherProps} />,
    'life-preserver-utility':
      <LifePreserverUtility
        className={className}
        {...otherProps} />,
    'lock-utility':
      <LockUtility
        className={className}
        {...otherProps} />,
    'magnifying-glass-utility':
      <MagnifyingGlassUtility
        className={className}
        {...otherProps} />,
    'map-utility':
      <MapUtility
        className={className}
        {...otherProps} />,
    'map-pin-utility':
      <MapPinUtility
        className={className}
        {...otherProps} />,
    'minus-sign-utility':
      <MinusSignUtility
        className={className}
        {...otherProps} />,
    'minus-sign-fill-utility':
      <MinusSignFillUtility
        className={className}
        {...otherProps} />,
    'mobile-device-utility':
      <MobileDeviceUtility
        className={className}
        {...otherProps} />,
    'palm-tree-island-utility':
      <PalmTreeIslandUtility
        className={className}
        {...otherProps} />,
    'paper-folded-corner-utility':
      <PaperFoldedCornerUtility
        className={className}
        {...otherProps} />,
    'paper-folded-corner-with-dollar-sign-utility':
      <PaperFoldedCornerWithDollarSignUtility
        className={className}
        {...otherProps} />,
    'paper-folded-corner-with-notary-utility':
      <PaperFoldedCornerWithNotaryUtility
        className={className}
        {...otherProps} />,
    'paper-folded-corner-with-x-and-pencil-utility':
      <PaperFoldedCornerWithXAndPencilUtility
        className={className}
        {...otherProps} />,
    'paper-money-utility':
      <PaperMoneyUtility
        className={className}
        {...otherProps} />,
    'pencil-utility':
      <PencilUtility
        className={className}
        {...otherProps} />,
    'pdf-utility':
      <PdfUtility
        className={className}
        {...otherProps} />,
    'phone-utility':
      <PhoneUtility
        className={className}
        {...otherProps} />,
    'pie-chart-utility':
      <PieChartUtility
        className={className}
        {...otherProps} />,
    'play-media-utility':
      <PlayMediaUtility
        className={className}
        {...otherProps} />,
    'plus-sign-fill-utility':
      <PlusSignFillUtility
        className={className}
        {...otherProps} />,
    'quotation-mark-double-curved-utility':
      <QuotationMarkDoubleCurvedUtility
        className={className}
        {...otherProps} />,
    'quotation-mark-double-square-utility':
      <QuotationMarkDoubleSquareUtility
        className={className}
        {...otherProps} />,
    'right-arrow-split-utility':
      <RightArrowSplitUtility
        className={className}
        {...otherProps} />,
    'rolled-scroll-utility':
      <RolledScrollUtility
        className={className}
        {...otherProps} />,
    'sandals-utility':
      <SandalsUtility
        className={className}
        {...otherProps} />,
    'shield-with-checkmark-utility':
      <ShieldWithCheckmarkUtility
        className={className}
        {...otherProps} />,
    'smiley-face-utility':
      <SmileyFaceUtility
        className={className}
        {...otherProps} />,
    'square-with-two-opposite-diagonal-arrows-utility':
      <SquareWithTwoOppositeDiagonalArrowsUtility
        className={className}
        {...otherProps} />,
    'star-utility':
      <StarUtility
        className={className}
        {...otherProps} />,
    'target-with-arrow-utility':
      <TargetWithArrowUtility
        className={className}
        {...otherProps} />,
    'three-vertical-dots-utility':
      <ThreeVerticalDotsUtility
        className={className}
        {...otherProps} />,
    'triangle-with-exclamation-point-utility':
      <TriangleWithExclamationPointUtility
        className={className}
        {...otherProps} />,
    'trophy-utility':
      <TrophyUtility
        className={className}
        {...otherProps} />,
    'two-credit-cards-utility':
      <TwoCreditCardsUtility
        className={className}
        {...otherProps} />,
    'two-folders-utility':
      <TwoFoldersUtility
        className={className}
        {...otherProps} />,
    'two-pages-folded-corner-utility':
      <TwoPagesFoldedCornerUtility
        className={className}
        {...otherProps} />,
    'vault-utility':
      <VaultUtility
        className={className}
        {...otherProps} />,
    'wallet-utility':
      <WalletUtility
        className={className}
        {...otherProps} />,
    'wrench-utility':
      <WrenchUtility
        className={className}
        {...otherProps} />,
    'wristwatch-utility':
      <WristwatchUtility
        className={className}
        {...otherProps} />,

    // social
    facebook:
      <Facebook
        className={className}
        {...otherProps} />,
    'facebook-dislike':
      <FacebookDislike
        className={className}
        {...otherProps} />,
    'facebook-light-theme':
      <FacebookLightTheme
        className={className}
        {...otherProps} />,
    'facebook-like':
      <FacebookLike
        className={className}
        {...otherProps} />,
    'facebook-like-fill':
      <FacebookLikeFill
        className={className}
        {...otherProps} />,
    instagram:
      <Instagram
        className={className}
        {...otherProps} />,
    linkedin:
      <Linkedin
        className={className}
        {...otherProps} />,
    'linkedin-light-theme':
      <LinkedinLightTheme
        className={className}
        {...otherProps} />,
    pinterest:
      <Pinterest
        className={className}
        {...otherProps} />,
    twitter:
      <Twitter
        className={className}
        {...otherProps} />,
    'twitter-light-theme':
      <TwitterLightTheme
        className={className}
        {...otherProps} />,
    youtube:
      <Youtube
        className={className}
        {...otherProps} />,
    x:
      <X
        className={className}
        {...otherProps} />,
  };

  return icons[variant] || <TriangleWithExclamationPointUtility
    className={className}
    {...otherProps} />;
};

Icon.propTypes = {
  /** Custom class name to add to Icon. */
  className: PropTypes.string,
  /** ID attribute for `Icon`. */
  id: PropTypes.string,
  /** Optional themeType */ // Only used in demo, developers should always load direct path to single/specific Icon for their needs
  // themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']),
  /**  Determines which Icon to display */ // Only used in demo, developers should always load direct path to single/specific Icon for their needs
  variant: PropTypes.oneOf([
    // illustrative Light Theme
    'apple-with-stem-light-theme',
    'awareness-ribbon-light-theme',
    'atom-light-theme',
    'baby-buggy-light-theme',
    'bar-chart-with-upward-arrow-light-theme',
    'beach-chair-with-border-light-theme',
    'beach-chair-with-sun-light-theme',
    'birthday-cake-light-theme',
    'blueprint-with-house-light-theme',
    'book-with-bookmark-light-theme',
    'brain-with-lightning-bolt-light-theme',
    'calendar-light-theme',
    'camera-light-theme',
    'car-moving-light-theme',
    'certificate-with-award-ribbon-light-theme',
    'checkmark-with-border-light-theme',
    'cityscape-light-theme',
    'clipboard-with-dollar-sign-paper-light-theme',
    'clock-light-theme',
    'coffee-cup-disposable-light-theme',
    'computer-screen-with-mouse-arrow-light-theme',
    'curved-arrow-through-xxo-light-theme',
    'dial-gauge-light-theme',
    'dog-with-collar-light-theme',
    'dollar-sign-revolving-arrows-light-theme',
    'dollar-sign-with-circle-and-mouse-arrow-light-theme',
    'dollar-sign-with-four-arrows-pointing-inward-light-theme',
    'dress-light-theme',
    'drinking-glass-with-straw-and-lemon-wheel-light-theme',
    'envelope-moving-light-theme',
    'envelope-with-at-symbol-paper-light-theme',
    'envelope-with-paper-money-light-theme',
    'family-heart-light-theme',
    'fist-holding-pencil-light-theme',
    'five-connected-nodes-light-theme',
    'folder-with-paper-money-light-theme',
    'four-roman-columns-yellow-roof-dot-light-theme',
    'four-roman-columns-yellow-roof-dot-with-clock-4-o-clock-light-theme',
    'four-roman-columns-yellow-roof-dot-with-clock-12-o-clock-light-theme',
    'gear-and-globe-connected-with-one-way-arrows-light-theme',
    'graduation-hat-with-tassle-light-theme',
    'group-of-six-people-light-theme',
    'hand-holding-credit-card-light-theme',
    'hand-with-three-dollar-signs-light-theme',
    'hand-with-three-plus-signs-light-theme',
    'handshake-light-theme',
    'heart-with-cross-light-theme',
    'heart-with-pulse-oximeter-wave-light-theme',
    'home-safe-locked-light-theme',
    'hourglass-light-theme',
    'house-light-theme',
    'house-with-checkmark-shield-light-theme',
    'house-with-hammer-light-theme',
    'house-with-paper-money-light-theme',
    'identification-badge-light-theme',
    'information-word-bubble-light-theme',
    'inner-office-envelope-fastened-with-string-light-theme',
    'leaf-light-theme',
    'life-preserver-vest-light-theme',
    'lightbulb-light-theme',
    'line-graph-upwards-trend-light-theme',
    'magnifying-glass-light-theme',
    'magnifying-glass-with-dollar-sign-light-theme',
    'map-pin-light-theme',
    'map-pin-informational-light-theme',
    'medal-with-star-light-theme',
    'microphone-with-cable-light-theme',
    'mobile-device-with-two-word-bubbles-light-theme',
    'mobile-device-with-wifi-symbol-light-theme',
    'money-parachute-with-border-light-theme',
    'mountaintop-with-flag-light-theme',
    'one-arrow-pointing-up-to-horizontal-line-light-theme',
    'one-map-pin-one-tax-pin-connected-with-curvy-dotted-line-light-theme',
    'one-page-paper-article-light-theme',
    'one-person-on-championship-podium-first-place-light-theme',
    'one-tax-pin-one-map-pin-connected-with-curvy-dotted-line-light-theme',
    'padlock-disengaged-light-theme',
    'padlock-engaged-light-theme',
    'paper-map-light-theme',
    'paper-money-stack-light-theme',
    'paper-shopping-bag-empty-light-theme',
    'paper-with-dead-face-light-theme',
    'paper-with-dollar-sign-and-bar-chart-light-theme',
    'person-holding-grounded-flag-light-theme',
    'person-with-lightbulb-word-bubble-light-theme',
    'person-with-outward-arrows-light-theme',
    'person-with-past-growth-height-lines-light-theme',
    'person-with-plus-circle-light-theme',
    'person-with-x-circle-light-theme',
    'piggybank-with-coin-light-theme',
    'potted-plant-with-dollar-sign-circle-light-theme',
    'price-tag-with-dollar-sign-light-theme',
    'profile-outline-checkmark-with-border-light-theme',
    'projector-screen-with-pie-chart-light-theme',
    'ribbon-with-checkmark-light-theme',
    'rocket-light-theme',
    'sailboat-on-water-light-theme',
    'settings-slider-light-theme',
    'shield-with-clock-180-arrow-light-theme',
    'shield-with-clock-360-arrow-light-theme',
    'shield-with-cross-light-theme',
    'shield-with-dollar-sign-light-theme',
    'shield-with-globe-2-arrows-light-theme',
    'shield-with-globe-3-arrows-light-theme',
    'shield-with-heart-light-theme',
    'shield-with-heart-border-light-theme',
    'shield-with-keyhole-light-theme',
    'square-with-plus-minus-multiply-equal-quadrants-light-theme',
    'stopwatch-with-dollar-sign-light-theme',
    'subway-train-on-tracks-light-theme',
    'sunset-over-water-horizon-light-theme',
    'telephone-with-outgoing-sound-light-theme',
    'three-connected-nodes-light-theme',
    'three-people-connected-by-inner-lines-light-theme',
    'three-people-connected-by-outer-lines-light-theme',
    'three-people-with-dollar-sign-light-theme',
    'thumbs-up-light-theme',
    'two-informational-word-bubbles-light-theme',
    'two-leaves-light-theme',
    'two-map-pins-connected-with-curvy-dotted-line-light-theme',
    'two-papers-with-dollar-sign-light-theme',
    'two-people-one-way-arrow-light-theme',
    'two-people-one-word-bubble-light-theme',
    'two-people-two-way-arrows-light-theme',
    'two-people-two-word-bubbles-light-theme',
    'two-person-tournament-bracket-with-trophy-light-theme',
    'two-revolving-arrows-oval-light-theme',
    'umbrella-light-theme',
    'umbrella-with-border-light-theme',
    'wallet-light-theme',
    'web-page-with-dollar-sign-light-theme',
    // Illustrative Dark Theme
    'apple-with-stem-dark-theme',
    'atom-dark-theme',
    'baby-buggy-dark-theme',
    'bar-chart-with-upward-arrow-dark-theme',
    'beach-chair-with-sun-dark-theme',
    'birthday-cake-dark-theme',
    'blueprint-with-house-dark-theme',
    'book-with-bookmark-dark-theme',
    'brain-with-lightning-bolt-dark-theme',
    'calendar-dark-theme',
    'camera-dark-theme',
    'car-moving-dark-theme',
    'certificate-with-award-ribbon-dark-theme',
    'cityscape-dark-theme',
    'clipboard-with-dollar-sign-paper-dark-theme',
    'clock-dark-theme',
    'coffee-cup-disposable-dark-theme',
    'computer-screen-with-mouse-arrow-dark-theme',
    'curved-arrow-through-xxo-dark-theme',
    'dial-gauge-dark-theme',
    'dog-with-collar-dark-theme',
    'dollar-sign-revolving-arrows-dark-theme',
    'dollar-sign-with-circle-and-mouse-arrow-dark-theme',
    'dollar-sign-with-four-arrows-pointing-inward-dark-theme',
    'dress-dark-theme',
    'drinking-glass-with-straw-and-lemon-wheel-dark-theme',
    'envelope-moving-dark-theme',
    'envelope-with-at-symbol-paper-dark-theme',
    'envelope-with-paper-money-dark-theme',
    'family-heart-dark-theme',
    'fist-holding-pencil-dark-theme',
    'five-connected-nodes-dark-theme',
    'folder-with-paper-money-dark-theme',
    'four-roman-columns-yellow-roof-dot-dark-theme',
    'four-roman-columns-yellow-roof-dot-with-clock-4-o-clock-dark-theme',
    'four-roman-columns-yellow-roof-dot-with-clock-12-o-clock-dark-theme',
    'gear-and-globe-connected-with-one-way-arrows-dark-theme',
    'graduation-hat-with-tassle-dark-theme',
    'hand-holding-credit-card-dark-theme',
    'hand-with-three-dollar-signs-dark-theme',
    'hand-with-three-plus-signs-dark-theme',
    'handshake-dark-theme',
    'heart-with-cross-dark-theme',
    'heart-with-pulse-oximeter-wave-dark-theme',
    'home-safe-locked-dark-theme',
    'hourglass-dark-theme',
    'house-dark-theme',
    'house-with-checkmark-shield-dark-theme',
    'house-with-hammer-dark-theme',
    'house-with-paper-money-dark-theme',
    'identification-badge-dark-theme',
    'inner-office-envelope-fastened-with-string-dark-theme',
    'leaf-dark-theme',
    'life-preserver-vest-dark-theme',
    'lightbulb-dark-theme',
    'line-graph-upwards-trend-dark-theme',
    'magnifying-glass-dark-theme',
    'magnifying-glass-with-dollar-sign-dark-theme',
    'map-pin-dark-theme',
    'map-pin-informational-dark-theme',
    'medal-with-star-dark-theme',
    'microphone-with-cable-dark-theme',
    'mobile-device-with-two-word-bubbles-dark-theme',
    'mobile-device-with-wifi-symbol-dark-theme',
    'mountaintop-with-flag-dark-theme',
    'one-arrow-pointing-up-to-horizontal-line-dark-theme',
    'one-page-paper-article-dark-theme',
    'one-person-on-championship-podium-first-place-dark-theme',
    'padlock-disengaged-dark-theme',
    'padlock-engaged-dark-theme',
    'paper-map-dark-theme',
    'paper-money-stack-dark-theme',
    'paper-shopping-bag-empty-dark-theme',
    'paper-with-dead-face-dark-theme',
    'paper-with-dollar-sign-and-bar-chart-dark-theme',
    'person-holding-grounded-flag-dark-theme',
    'person-with-lightbulb-word-bubble-dark-theme',
    'person-with-outward-arrows-dark-theme',
    'person-with-past-growth-height-lines-dark-theme',
    'person-with-plus-circle-dark-theme',
    'person-with-x-circle-dark-theme',
    'piggybank-with-coin-dark-theme',
    'potted-plant-with-dollar-sign-circle-dark-theme',
    'price-tag-with-dollar-sign-dark-theme',
    'projector-screen-with-pie-chart-dark-theme',
    'ribbon-with-checkmark-dark-theme',
    'rocket-dark-theme',
    'sailboat-on-water-dark-theme',
    'settings-slider-dark-theme',
    'shield-with-clock-180-arrow-dark-theme',
    'shield-with-clock-360-arrow-dark-theme',
    'shield-with-cross-dark-theme',
    'shield-with-dollar-sign-dark-theme',
    'shield-with-globe-2-arrows-dark-theme',
    'shield-with-heart-dark-theme',
    'shield-with-keyhole-dark-theme',
    'square-with-plus-minus-multiply-equal-quadrants-dark-theme',
    'stopwatch-with-dollar-sign-dark-theme',
    'subway-train-on-tracks-dark-theme',
    'sunset-over-water-horizon-dark-theme',
    'telephone-with-outgoing-sound-dark-theme',
    'three-connected-nodes-dark-theme',
    'three-people-connected-by-inner-lines-dark-theme',
    'three-people-connected-by-outer-lines-dark-theme',
    'three-people-with-dollar-sign-dark-theme',
    'thumbs-up-dark-theme',
    'two-informational-word-bubbles-dark-theme',
    'two-map-pins-connected-with-curvy-dotted-line-dark-theme',
    'two-papers-with-dollar-sign-dark-theme',
    'two-people-one-way-arrow-dark-theme',
    'two-people-one-word-bubble-dark-theme',
    'two-people-two-way-arrows-dark-theme',
    'two-people-two-word-bubbles-dark-theme',
    'two-person-tournament-bracket-with-trophy-dark-theme',
    'two-revolving-arrows-oval-dark-theme',
    'umbrella-dark-theme',
    'wallet-dark-theme',
    'web-page-with-dollar-sign-dark-theme',
    // utility
    'achievement-medal-utility',
    'airplane-utility',
    'alarm-clock-utility',
    'arrow-down-into-box-utility',
    'arrow-down-to-line-utility',
    'arrow-right-utility',
    'arrow-right-alt-utility',
    'arrow-up-utility',
    'arrow-up-to-line-utility',
    'audio-utility',
    'audio-muted-utility',
    'baby-face-utility',
    'bag-with-dollar-sign-utility',
    'bell-utility',
    'boat-utility',
    'bone-utility',
    'bookmark-utility',
    'box-with-arrow-pointing-outward-utility',
    'briefcase-utility',
    'briefcase-with-cross-utility',
    'calculator-utility',
    'calendar-utility',
    'car-utility',
    'carat-right-utility',
    'champagne-flute-half-full-utility',
    'checkbook-utility',
    'checkmark-bold-utility',
    'chevron-down-utility',
    'chevron-left-utility',
    'chevron-left-narrow-utility',
    'chevron-right-utility',
    'chevron-right-narrow-utility',
    'chevron-up-utility',
    'circle-arrow-360-degrees-utility',
    'circle-with-diagonal-line-utility',
    'circle-with-dollar-sign-utility',
    'circle-with-exclamation-point-utility',
    'circle-with-exclamation-point-fill-utility',
    'circle-with-person-utility',
    'circle-with-question-mark-utility',
    'circle-with-three-multi-length-lines-utility',
    'circle-with-upside-down-exclamation-point-utility',
    'clock-utility',
    'close-utility',
    'compass-utility',
    'computer-monitor-utility',
    'credit-card-with-arrow-down-utility',
    'devices-utility',
    'diamond-utility',
    'dollar-sign-with-revolving-arrows-utility',
    'drawer-caret-utility',
    'drawer-caret-flipped-utility',
    'envelope-utility',
    'envelope-with-paper-folded-corner-utility',
    'exclamation-point-utility',
    'eye-utility',
    'eye-with-slash-utility',
    'fax-utility',
    'flashlight-utility',
    'folder-utility',
    'folder-with-file-utility',
    'frowning-face-utility',
    'gear-utility',
    'heart-utility',
    'house-utility',
    'key-utility',
    'knife-and-fork-utility',
    'laptop-computer-utility',
    'leaf-utility',
    'left-right-directional-signs-utility',
    'lightbulb-utility',
    'life-preserver-utility',
    'lock-utility',
    'magnifying-glass-utility',
    'map-utility',
    'map-pin-utility',
    'minus-sign-utility',
    'minus-sign-fill-utility',
    'mobile-device-utility',
    'palm-tree-island-utility',
    'paper-folded-corner-utility',
    'paper-folded-corner-with-dollar-sign-utility',
    'paper-folded-corner-with-notary-utility',
    'paper-folded-corner-with-x-and-pencil-utility',
    'paper-money-utility',
    'pdf-utility',
    'pencil-utility',
    'phone-utility',
    'pie-chart-utility',
    'play-media-utility',
    'plus-sign-fill-utility',
    'quotation-mark-double-curved-utility',
    'quotation-mark-double-square-utility',
    'right-arrow-split-utility',
    'rolled-scroll-utility',
    'sandals-utility',
    'shield-with-checkmark-utility',
    'smiley-face-utility',
    'square-with-two-opposite-diagonal-arrows-utility',
    'star-utility',
    'target-with-arrow-utility',
    'triangle-with-exclamation-point-utility',
    'trophy-utility',
    'three-vertical-dots-utility',
    'two-credit-cards-utility',
    'two-folders-utility',
    'two-pages-folded-corner-utility',
    'vault-utility',
    'wallet-utility',
    'wrench-utility',
    'wristwatch-utility',
    // social
    'facebook',
    'facebook-dislike',
    'facebook-light-theme',
    'facebook-like',
    'facebook-like-fill',
    'instagram',
    'linkedin',
    'linkedin-light-theme',
    'pinterest',
    'twitter',
    'twitter-light-theme',
    'youtube',
    'x',
  ]),
};

export default Icon;
