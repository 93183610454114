
import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import Typography from '../../foundations/Typography';

// eslint-disable-next-line import/prefer-default-export
export const TypographyHeadingWithLineStyled = styled(Typography)`
  ${({ theme, centeredLine }) => {
    let top = rem(-7);
    if (centeredLine) {
      top = rem(-12);
    }

    return css`
    display: flex;
    align-items: flex-end;
    padding-bottom: 0;
    margin-bottom: 0;
    color: ${theme.colors.neutral.grayDark10};
    text-transform: uppercase;

    /** The border */
    ::after {
      content: '';
      flex: 1;
      width: 100%;
      position: relative;
      top: ${top};
      border-bottom: 1px solid ${theme.colors.neutral.grayDark10};
      margin-left: ${rem(10)}
    }
  `;
  }
}
`;
