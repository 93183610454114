// deprecated
/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
// components
import { Col } from '../../../foundations/Grid';

export const PictureColStyled = styled(Col)`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    return css`
      margin-bottom: ${mediumUp ? 0 : rem(24)};
    `;
  }}
`;
