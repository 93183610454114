// deprecated
import React from 'react';
import PropTypes from 'prop-types';
// components
import Link, { linkPropTypes } from '../../../components/Link';
import Typography from '../../../foundations/Typography';
import { Col, Row } from '../../../foundations/Grid';

import {
  ContentColStyled,
  DivButtonContainer,
  PhotoColStyled,
  PictureFRPhotoStyled,
  TypographyHeadingStyled,
} from './styles';

const DefaultCard = ({
  cta,
  desktopWebpSrc,
  disableLazyLoad,
  headingText,
  imageSrc,
  imageVariant,
  mobileImageSrc,
  mobileWebpSrc,
  subText,
  text,
  themeType,
}) => {
  const contents = <>
    <TypographyHeadingStyled
      component='h2'
      themeType={themeType}>
      {headingText}
    </TypographyHeadingStyled>
    {/* TODO: ensure defaultText can handle html being passed in or maybe a react component? */}
    <Typography themeType={themeType}>
      {text}
    </Typography>
    {subText && (
      <Typography
        themeType={themeType}
        weight={400}>
        {subText}
      </Typography>
    )}
    {cta
      && (
        <DivButtonContainer>
          <Link
            {...cta} // NOTE: list the dynamic props first, to not overwrite the hard-coded props
            id='advisor-card-connect-button'
            themeType={themeType}
            variant='buttonLinkPrimary'
          >
            {cta.linkText} {/** change this to cta.children in 4.x.x */}
          </Link>
        </DivButtonContainer>
      )}
  </>;

  return imageSrc
    ? (
      <Row align='center'>
        <PhotoColStyled medium={4}>
          <PictureFRPhotoStyled
            src={imageSrc}
            mobileSrc={mobileImageSrc}
            mobileWebpSrc={mobileWebpSrc}
            desktopWebpSrc={desktopWebpSrc}
            altText={'Picture of a financial representative'}
            lazyLoad={!disableLazyLoad}
            width='200'
            height='100%'
            variant={imageVariant}
          />
        </PhotoColStyled>
        <ContentColStyled medium={8}>
          {contents}
        </ContentColStyled>
      </Row>
    )
    : (
      <Row styles={{ textAlign: 'center' }}>
        <Col
          small={10}
          medium={8}>
          {contents}
        </Col>
      </Row>
    );
};

DefaultCard.propTypes = {
  desktopWebpSrc: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  headingText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  imageOnTop: PropTypes.bool,
  imageSrc: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  imageVariant: PropTypes.oneOf(['rectangular', 'round']),
  mobileImageSrc: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  mobileWebpSrc: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  cta: PropTypes.shape({
    linkText: PropTypes.oneOfType([ // deprecated
      PropTypes.string,
      PropTypes.object,
    ]).isRequired,
    ...linkPropTypes,
  }),
  disableLazyLoad: PropTypes.bool,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']),
};

DefaultCard.defaultProps = {
  headingText: 'Take the next step.',
  imageSrc: 'https://www.northwesternmutual.com/assets/images/frs/leo-tucker-desktop-v7.webp',
  text: 'Our advisors are here to give you more of the information you want, and the knowledge you never knew you needed. To get to your next goal, and the next.',
  disableLazyLoad: false,
};

export default DefaultCard;
