import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import Typography from '../../foundations/Typography';

export const DlStyled = styled.dl`
  margin: 0 0 0 ${rem(24)};
  padding: 0 0 0 ${rem(8)};
`;

export const SpanStyledDescriptionListItem = styled.span`
  display: block;
  margin-bottom: ${rem(16)};
`;

export const DtStyled = styled.dt`
  ${({ theme, themeType, disableBullet }) => css`
    display: inline;
    margin-right: ${rem(4)};
    float: left;
    list-style-type: disc;
    font-weight: 400;
    ${!disableBullet && `
      &::before {
        content: '';
        display: inline-block;
        height: ${rem(6)};
        width: ${rem(6)};
        margin-right: ${rem(8)};
        margin-bottom: ${rem(3)};
        border-radius: 50%;
        background-color: ${themeType === 'lightTheme' ? theme.colors.text.lightTheme.default : theme.colors.text.darkTheme.default}; /** nmx-pcg and darkTheme will inherit the same styles */
      }
    `}
  `}
`;

export const DdStyled = styled.dd`
  display: inline;
  margin: 0;
`;

export const TypographyStyled = styled(Typography)`
  display: inline;
`;
