import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import classNames from 'classnames';

import './inline-tooltip.scss'; // TODO: convert to Styled Components

// Dynamically place dialogs for the tooltips based on location
export const placeDialog = (mmPhone, modalId, posX, posY) => {
  const thisDialog = document.getElementById(modalId);
  thisDialog.style.top = `${posY}px`;

  // We ONLY want to set left adjustment for devices larger than phones
  if (!mmPhone.matches) {
    thisDialog.style.left = `${posX}px`;
    thisDialog.style['max-width'] = `${posX * 2}px`; // prevent overflow of dialog off screen for mobile
  } else {
    thisDialog.style.left = '50%';
  }
};

export const openDialog = (buttonElem, closeDialog, mmPhone, modalId) => {
  if (typeof window !== 'undefined') {
    window.addEventListener('resize', closeDialog);
    window.addEventListener('scroll', closeDialog);
    window.addEventListener('keydown', closeDialog);
  }

  // Get tooltip button location
  const posX = buttonElem.getBoundingClientRect().left;
  const posY = buttonElem.getBoundingClientRect().top;

  placeDialog(mmPhone, modalId, posX, posY);
};

export const closeDialog = (event, waiting) => {
  if (event && event.key === 'Escape') {
    // eslint-disable-next-line no-use-before-define
    recursiveCloseDialog();
  }

  function recursiveCloseDialog() {
    // throttling this close dialog function
    if (waiting) {
      return;
    }

    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', recursiveCloseDialog);
      window.removeEventListener('scroll', recursiveCloseDialog);
      window.removeEventListener('keydown', recursiveCloseDialog);
    }
  }
  // Call the recursiveCloseDialog function directly
  recursiveCloseDialog();
};

export const handleCloseDialog = (e, waiting, setWaiting, setDialogDisplay) => {
  closeDialog(e, waiting);
  setDialogDisplay(false);
  setWaiting(true);
  setTimeout(() => {
    setWaiting(false);
  }, 300);
};

export const handleOpenDialog = (e, onClickThisTriggerButtonRef, onClickCloseDialog, onClickMmPhone, onClickModalId, setDialogDisplay) => {
  openDialog(onClickThisTriggerButtonRef, onClickCloseDialog, onClickMmPhone, onClickModalId);
  setDialogDisplay(true);
};

export const InlineTooltipComponent = ({
  branding,
  buttonId,
  modalId,
  tooltipText,
}) => {
  const [dialogDisplay, setDialogDisplay] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const mmPhone = typeof window !== 'undefined' ? window.matchMedia('(max-width: 1023px)') : false;

  const thisTriggerButtonRef = useRef(null);

  return (
    <span className='nm-inline-tooltip'>
      <span className='nm-inline-tooltip__tooltip'>
        <button
          className={classNames('nm-inline-tooltip__tooltip-icon-button', { 'is-active': dialogDisplay }, { 'nmx-pcg': branding === 'pcg' })}
          data-testid={buttonId}
          id={buttonId}
          onBlur={(e) => handleCloseDialog(e, waiting, setWaiting, setDialogDisplay)}
          onClick={(e) => handleOpenDialog(e, thisTriggerButtonRef.current, closeDialog, mmPhone, modalId, setDialogDisplay)}
          onFocus={(e) => handleOpenDialog(e, thisTriggerButtonRef.current, closeDialog, mmPhone, modalId, setDialogDisplay)}
          onMouseEnter={(e) => handleOpenDialog(e, thisTriggerButtonRef.current, closeDialog, mmPhone, modalId, setDialogDisplay)}
          onMouseLeave={(e) => handleCloseDialog(e, waiting, setWaiting, setDialogDisplay)}
          ref={thisTriggerButtonRef}
          type='button'
        >
          &#63;
        </button>
      </span>
      <span
        className={classNames('nm-inline-tooltip__dialog-window', { 'is-active': dialogDisplay }, { 'nmx-pcg': branding === 'pcg' })}
        id={modalId}
      >
        <span className={classNames('nm-inline-tooltip__dialog-window-close-btn', { 'is-active': dialogDisplay }, { 'nmx-pcg': branding === 'pcg' })}>
          &#10005;
        </span>
        {tooltipText}
      </span>
    </span>
  );
};

InlineTooltipComponent.propTypes = {
  /** sets overall branding of component or module, default='nm' */
  branding: PropTypes.oneOf(['nm', 'pcg']),
  /** required id placed on button */
  buttonId: PropTypes.string.isRequired,
  /** required id placed on the dialog span */
  modalId: PropTypes.string.isRequired,
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']),
  /** required text populated inside the tooltip */
  tooltipText: PropTypes.any.isRequired,
};

InlineTooltipComponent.defaultProps = {
  branding: 'nm',
  themeType: 'lightTheme',
};

export default withTheme(InlineTooltipComponent);
