// deprecated
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from '../../../foundations/Grid';
import Typography from '../../../foundations/Typography';
import Link from '../../../components/Link';

const CrcCard = ({ city, phone }) => (
  <>
    <Row>
      <Col xsmall={12}>
        <Typography
          weight={200}
          align='center'
          component='h3'
          uppercase>{city}</Typography>
      </Col>
    </Row>
    <Row styles={{ textAlign: 'center' }}>
      <Col xsmall={12}>
        <Link linkUrl={`tel:${phone.replace(/-/g, '')}`}>{phone}</Link>
      </Col>
    </Row>
  </>
);

CrcCard.propTypes = {
  city: PropTypes.string,
  phone: PropTypes.string,
};

export default CrcCard;
