import rem from 'polished/lib/helpers/rem';
import styled, { css } from 'styled-components';

import { standardFontCss } from '../../foundations/GlobalStyles';
import colors from '../../foundations/theme/colors';
import gradients from '../../foundations/theme/effects/gradients';

const BUTTON_TRANSITION = '100ms';

// // Default Styles
export const getDefaultStyles = (noWrap) => css`
  ${standardFontCss};
  padding: 0;
  font-weight: 400;
  cursor: pointer;
  ${noWrap && 'white-space: nowrap;'}
`;

export const getResetStyles = () => css`
  border: none;
  background-color: transparent;
  color: initial;
  font-size: initial;
  font-weight: initial;
  line-height: initial;
  text-decoration: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  outline: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -moz-appearance: none; /** ff fix */
  -webkit-appearance: none; /** webkit fix */
  appearance: none; /** webkit fix */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-fill-color: currentColor;
  -webkit-text-stroke: 0;
  -webkit-text-stroke-width: 0;
  white-space: normal;
  &:link {
    color: initial;
  }
  &:visited {
    color: initial;
  }
  &:hover {
    color: initial;
  }
  &:focus {
    color: initial;
  }
  &:active {
    color: initial;
  }
`;

export const BaseLink = styled.a`
  ${({ branding, resetBaseStyles, theme, variant, themeType, noWrap }) => {
    const mediumUp = theme.mediumUp();

    const getDefaultUnderlineStyle = () => css`
      text-decoration: underline;
      text-underline-offset: ${rem(5)};
    `;

    // Primary Light Theme
    const getPrimaryLightThemeStyles = () => css`
      &:link {
        color: ${colors.action.lightTheme.linkDefault};
        ${getDefaultUnderlineStyle()};
      }
      &:visited {
        color: ${colors.action.lightTheme.linkVisited};
        ${getDefaultUnderlineStyle()};
      }
      &:hover {
        color: ${colors.action.lightTheme.linkHover};
        ${getDefaultUnderlineStyle()};
      }
      &:focus {
        /** browser to handle native focus */
      }
      &:active {
        color: ${colors.action.lightTheme.linkActive};
        ${getDefaultUnderlineStyle()};
      }
    `;

    // Primary Dark Theme
    const getPrimaryDarkThemeStyles = () => css`
      &:link {
        color: ${colors.action.darkTheme.linkDefault};
        ${getDefaultUnderlineStyle()};
      }
      &:visited {
        color: ${colors.action.darkTheme.linkVisited};
        ${getDefaultUnderlineStyle()};
      }
      &:hover {
        color: ${colors.action.darkTheme.linkHover};
        text-decoration: none;
      }
      &:focus {
        /** browser to handle native focus */
        /** offset focus ring for higher visibility */
        outline: auto ${rem(5)} -webkit-focus-ring-color;
      }
      &:active {
        color: ${colors.action.darkTheme.linkActive};
        ${getDefaultUnderlineStyle()};
      }
    `;

    // Secondary Light Theme
    const getSecondaryLightThemeStyles = () => css`
      &:link {
        color: ${colors.action.lightTheme.linkSecondaryDefault};
        ${getDefaultUnderlineStyle()};
      }
      &:visited {
        color: ${colors.action.lightTheme.linkSecondaryVisited};
        ${getDefaultUnderlineStyle()};
      }
      &:hover {
        color: ${colors.action.lightTheme.linkSecondaryHover};
        ${getDefaultUnderlineStyle()};
      }
      &:focus {
        /** browser to handle native focus */
      }
      &:active {
        color: ${colors.action.lightTheme.linkSecondaryActive};
        ${getDefaultUnderlineStyle()};
      }
    `;

    // Secondary Dark Theme
    const getSecondaryDarkThemeStyles = () => css`
      &:link {
        color: ${colors.action.darkTheme.linkSecondaryDefault};
        ${getDefaultUnderlineStyle()};
      }
      &:visited {
        color: ${colors.action.darkTheme.linkSecondaryVisited};
        ${getDefaultUnderlineStyle()};
      }
      &:hover {
        color: ${colors.action.darkTheme.linkSecondaryHover};
        text-decoration: none;
      }
      &:focus {
        /** browser to handle native focus */
        /** offset focus ring for higher visibility */
        outline: auto ${rem(5)} -webkit-focus-ring-color;
      }
      &:active {
        color: ${colors.action.darkTheme.linkSecondaryActive};
        ${getDefaultUnderlineStyle()};
      }
    `;

    // primaryDeemphasize Light Theme
    const getPrimaryDeemphasizeLightThemeStyles = () => css`
      &:link {
        color: ${colors.action.lightTheme.linkDefault};
        text-decoration: none;
        font-weight: 500;
      }
      &:visited {
        color: ${colors.action.lightTheme.linkVisited};
        text-decoration: none;
      }
      &:hover {
        color: ${colors.action.lightTheme.linkHover};
        ${getDefaultUnderlineStyle()};
      }
      &:focus {
        /** browser to handle native focus */
      }
      &:active {
        color: ${colors.action.lightTheme.linkActive};
        text-decoration: none;
      }
    `;

    // primaryDeemphasize Dark Theme
    const getPrimaryDeemphasizeDarkThemeStyles = () => css`
      &:link {
        color: ${colors.action.darkTheme.linkDefault};
        text-decoration: none;
        font-weight: 500;
      }
      &:visited {
        color: ${colors.action.darkTheme.linkVisited};
        text-decoration: none;
      }
      &:hover {
        color: ${colors.action.darkTheme.linkHover};
        ${getDefaultUnderlineStyle()};
      }
      &:focus {
        /** browser to handle native focus */
        /** offset focus ring for higher visibility */
        outline: auto ${rem(5)} -webkit-focus-ring-color;
      }
      &:active {
        color: ${colors.action.darkTheme.linkActive};
        text-decoration: none;
      }
    `;

    // Default Button Link Styles
    /** TODO: import BaseButton from '../Button/styles' */
    const getDefaultButtonLinkStyles = () => css`
      display: inline-flex;
      min-width: ${rem(
    200,
  )}; /** TODO: configure mobile width (full-width with 1rem outer gutter at small (480) and lower) vs what other max/min-width? */
      max-width: max-content;
      height: auto;
      min-height: ${mediumUp ? rem(55) : rem(47)};
      padding: ${rem(11)} ${rem(25)} ${rem(9)};
      background-clip: padding-box; /** ff fix */
      border-radius: ${rem(2)};
      && {
        /** double-specificity to override getDefaultStyles */
        font-size: ${rem(16)};
        line-height: ${rem(20)};
      }
      font-weight: 500;
      letter-spacing: ${rem(1)};
      -webkit-tap-highlight-color: transparent;
      text-align: center;
      align-items: center;
      justify-content: center;
      transition:
        /** TODO: confirm autoprefixer handles this */ background-color
          ${BUTTON_TRANSITION} ease-out,
        color ${BUTTON_TRANSITION} ease-out,
        border-color ${BUTTON_TRANSITION} ease-out;
      /* user-select: none; */
      text-decoration: none;

      /** button states (hover, active, focus) handled by individual variants */
      /** link states */
      &:hover,
      &:active {
        text-decoration: none;
        text-underline-offset: auto;
      }

      /** ff focus fix */
      &::-moz-focus-inner {
        padding: 0;
        border: none;
      }

      @media print {
        display: none !important;
      }
    `;

    // Button Link Primary Light Theme
    const getButtonLinkPrimaryLightThemeStyles = () => css`
      border: ${rem(1.5)} solid ${theme.colors.action.lightTheme.ctaBgDefault};
      background-color: ${theme.colors.action.lightTheme.ctaBgDefault};
      color: ${theme.colors.action.lightTheme.ctaTextDefault};

      /** button states */
      /** .is-active is the JS alternative (legacy) way for us to trigger the hover state on a button dynamically */
      /** TODO: remove .is-active if not being used post-legacy */
      &:hover,
      &.is-active {
        border-color: ${theme.colors.action.lightTheme.ctaBgHover};
        background-color: ${theme.colors.action.lightTheme.ctaBgHover};
      }
      &:focus {
        /** browser to handle native focus */
      }
      &:active {
        border-color: ${theme.colors.action.lightTheme.ctaBgActive};
        background-color: ${theme.colors.action.lightTheme.ctaBgActive};
      }
    `;

    // Button Link Primary Dark Theme
    const getButtonLinkPrimaryDarkThemeStyles = () => css`
      border: ${rem(1.5)} solid ${theme.colors.action.darkTheme.ctaBgDefault};
      background-color: ${theme.colors.action.darkTheme.ctaBgDefault};
      color: ${theme.colors.action.darkTheme.ctaTextDefault};

      /** button states */
      &:hover,
      &.is-active {
        border-color: ${theme.colors.action.darkTheme.ctaBgHover};
        background-color: ${theme.colors.action.darkTheme.ctaBgHover};
      }
      &:focus {
        /** browser to handle native focus */
        /** offset focus ring for higher visibility */
        outline: auto ${rem(5)} -webkit-focus-ring-color;
      }
      &:active {
        border-color: ${theme.colors.action.darkTheme.ctaBgActive};
        background-color: ${theme.colors.action.darkTheme.ctaBgActive};
      }
    `;

    // Button Link Secondary Light Theme
    const getButtonLinkSecondaryLightThemeStyles = () => css`
      border: ${rem(1.5)} solid ${theme.colors.action.lightTheme.ctaBgDefault};
      background-color: ${theme.colors.action.lightTheme.ctaBgSecondary};
      color: ${theme.colors.action.lightTheme.ctaBgDefault};

      /** button states */
      &:hover,
      &.is-active {
        border-color: ${theme.colors.action.lightTheme.ctaBgHover};
        background-color: ${theme.colors.action.lightTheme.ctaBgSecondaryHover};
        color: ${theme.colors.action.lightTheme.ctaBgHover};
      }
      &:focus {
        /** browser to handle native focus */
        background-color: ${theme.colors.action.lightTheme.ctaBgSecondaryHover};
      }
      &:active {
        border-color: ${theme.colors.action.lightTheme.ctaBgActive};
        background-color: ${theme.colors.action.lightTheme.ctaBgSecondaryActive};
        color: ${theme.colors.action.lightTheme.ctaBgActive};
      }
    `;

    // Button Link Secondary Dark Theme
    const getButtonLinkSecondaryDarkThemeStyles = () => css`
      border: ${rem(1.5)} solid ${theme.colors.action.darkTheme.ctaTextSecondary};
      background-color: ${theme.colors.action.darkTheme.ctaBgSecondary};
      color: ${theme.colors.action.darkTheme.ctaTextSecondary};

      /** button states */
      &:hover,
      &.is-active {
        border-color: ${theme.colors.action.darkTheme.ctaTextSecondary};
        background-color: ${theme.colors.action.darkTheme.ctaBgSecondaryHover};
        color: ${theme.colors.action.darkTheme.ctaTextSecondary};
      }
      &:focus {
        /** browser to handle native focus */
        /** offset focus ring for higher visibility */
        background-color: ${theme.colors.action.darkTheme.ctaBgSecondaryHover};
        outline: auto ${rem(5)} -webkit-focus-ring-color;
      }
      &:active {
        border-color: ${theme.colors.action.darkTheme.ctaTextSecondary};
        background-color: ${theme.colors.action.darkTheme.ctaBgSecondaryActive};
        color: ${theme.colors.action.darkTheme.ctaTextSecondary};
      }
    `;

    // Button Link Tertiary Light Theme
    const getButtonLinkTertiaryLightThemeStyles = () => css`
      min-width: unset;
      min-height: ${mediumUp ? rem(38) : rem(32)};
      padding: 0;
      border: none;
      background-color: ${theme.colors.action.lightTheme.ctaBgTertiary};
      font-weight: 500;
      color: ${theme.colors.action.lightTheme.ctaBgDefault};

      /** button states */
      &:hover,
      &.is-active {
        border: none;
        background-color: ${theme.colors.action.lightTheme.ctaBgTertiary};
        color: ${theme.colors.action.lightTheme.ctaBgHover};
        ${getDefaultUnderlineStyle()}; /** underline on tertiary buttonLink only (not button) */
      }
      &:focus {
        /** browser to handle native focus */
      }
      &:active {
        border: none;
        background-color: ${theme.colors.action.lightTheme.ctaBgTertiary};
        color: ${theme.colors.action.lightTheme.ctaBgActive};
        ${getDefaultUnderlineStyle()}; /** underline on tertiary buttonLink only (not button) */
      }
    `;

    // Button Link Tertiary Dark Theme
    const getButtonLinkTertiaryDarkThemeStyles = () => css`
      min-width: unset;
      min-height: ${mediumUp ? rem(38) : rem(32)};
      padding: 0;
      border: none;
      background-color: ${theme.colors.action.darkTheme.ctaBgTertiary};
      font-weight: 500;
      color: ${theme.colors.action.darkTheme.ctaTextTertiary};

      /** button states */
      &:hover,
      &.is-active {
        border: none;
        color: ${theme.colors.action.darkTheme.ctaTextTertiary};
        ${getDefaultUnderlineStyle()}; /** underline on tertiary buttonLink only (not button) */
      }
      &:focus {
        /** browser to handle native focus */
        /** offset focus ring for higher visibility */
        outline: auto ${rem(5)} -webkit-focus-ring-color;
      }
      &:active {
        border: none;
        background-color: ${theme.colors.action.darkTheme.ctaBgSecondaryActive};
        color: ${theme.colors.action.darkTheme.ctaTextTertiary};
        ${getDefaultUnderlineStyle()}; /** underline on tertiary buttonLink only (not button) */
      }
    `;

    const getPrimaryPcgLightThemeStyles = () => css`
      color: ${theme.colors.brand.pcg.darkBlue3};

      /** link states */
      &:hover {
        color: ${colors.action.lightTheme.linkDefault};
      }
      &:focus {
        /** browser to handle native focus */
      }
      &:active {
        color: ${colors.brand.pcg.lightBlue1};
      }
    `;

    const getPrimaryPcgDarkThemeStyles = () => css`
      &:link {
        color: ${colors.action.darkTheme.linkActive};
      }
      &:visited {
        color: ${colors.action.darkTheme.linkActive}; /** TODO: confirm with Creative */
      }
      &:hover {
        color: ${colors.action.darkTheme.linkActive};
      }
      &:focus {
        /** browser to handle native focus */
      }
      &:active {
        color: ${colors.action.darkTheme.linkActive}; /** TODO: confirm with Creative */
      }
    `;

    // Themed Buttons
    // PCG
    const getPcgRuleGradientStyles = () => css`
      ${gradients.pcgGold};
    `;

    const getPrimaryPcgStyles = () => css`
      &:link {
        border: none;
        color: ${theme.colors.neutral.grayDark30};
        letter-spacing: ${rem(3)};
        text-transform: uppercase;
        ${getPcgRuleGradientStyles()}
      }
      /** base button states */
      &:hover {
        border: none;
        ${getPcgRuleGradientStyles()}
      }
      &:focus,
      &:active {
        border: none;
      }
    `;

    const getPcgStyles = () => {
      let pcgStyles;
      if (variant === 'primary' || variant === 'primaryDeemphasize') {
        pcgStyles = themeType === 'lightTheme' ? getPrimaryPcgLightThemeStyles() : getPrimaryPcgDarkThemeStyles();
      } else if (variant === 'buttonLinkPrimary' || variant === 'buttonLinkSecondary' || variant === 'buttonLinkTertiary') {
        pcgStyles = getPrimaryPcgStyles();
      }
      return pcgStyles;
    };

    return css`
      /** Default/Base Link styles get loaded first every time */
      ${getDefaultStyles(noWrap)}
      /* then, conditionally, color, border, background-color styles get added */
      /* get link primary styles */
      ${themeType === 'lightTheme' && variant === 'primary' && getPrimaryLightThemeStyles()} /** Default = Primary Light Theme */
      ${themeType === 'darkTheme' && variant === 'primary' && getPrimaryDarkThemeStyles()}
      /* get link secondary styles */
      ${themeType === 'lightTheme' && variant === 'secondary' && getSecondaryLightThemeStyles()}
      ${themeType === 'darkTheme' && variant === 'secondary' && getSecondaryDarkThemeStyles()}
       /* get link primary deemphasize styles */
      ${themeType === 'lightTheme' && variant === 'primaryDeemphasize' && getPrimaryDeemphasizeLightThemeStyles()} /** Default = Primary Light Theme */
      ${themeType === 'darkTheme' && variant === 'primaryDeemphasize' && getPrimaryDeemphasizeDarkThemeStyles()}
      /* Default/Base buttonLink styles get loaded first if buttonLink variant */
      ${['buttonLinkPrimary', 'buttonLinkSecondary', 'buttonLinkTertiary'].includes(variant) && getDefaultButtonLinkStyles()}
      /* then, conditionally, color, border, background-color styles get added */
      /* get "button" link primary styles */
      ${themeType === 'lightTheme' && variant === 'buttonLinkPrimary' && getButtonLinkPrimaryLightThemeStyles()}
      ${themeType === 'darkTheme' && variant === 'buttonLinkPrimary' && getButtonLinkPrimaryDarkThemeStyles()}
        /* get "button" link secondary styles */
      ${themeType === 'lightTheme' && variant === 'buttonLinkSecondary' && getButtonLinkSecondaryLightThemeStyles()}
      ${themeType === 'darkTheme' && variant === 'buttonLinkSecondary' && getButtonLinkSecondaryDarkThemeStyles()}
      /* get "button" link tertiary styles */
      ${themeType === 'lightTheme' && variant === 'buttonLinkTertiary' && getButtonLinkTertiaryLightThemeStyles()}
      ${themeType === 'darkTheme' && variant === 'buttonLinkTertiary' && getButtonLinkTertiaryDarkThemeStyles()}
      /* get "button" link PCG styles */
      ${branding === 'pcg' && getPcgStyles()};
      ${resetBaseStyles && getResetStyles()}
    `;
  }}
`;

export const StartAdornmentContainerStyled = styled.span`
  ${({ startAdornmentStylesReset }) => `
    width: ${rem(19)};
    height: ${rem(19)};
    margin-right: ${rem(7)};
    ${!startAdornmentStylesReset && `
      .nmx-icon {
        position: relative;
        width: ${rem(19)};
        height: ${rem(19)};
        top: ${rem(5)};
      }
    `}
  `}
`;

export const EndAdornmentContainerStyled = styled.span`
  ${({ endAdornmentStylesReset }) => `
    width: ${rem(19)};
    height: ${rem(19)};
    margin-left: ${rem(7)};
    text-align: left;
    ${!endAdornmentStylesReset && `
      .nmx-icon {
        position: relative;
        width: ${rem(19)};
        height: ${rem(19)};
        top: ${rem(5)};
      }
    `}
  `}
`;
