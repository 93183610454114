import React from 'react';
import PropTypes from 'prop-types';
import TextField from '../../../../components/forms/TextField';

export const PhoneInputComponent = React.forwardRef(({
  hasError,
  helperText,
  onBlur,
  onChange,
  onKeyUp,
  themeType,
  value,
}, ref) => (
  <TextField
    hasError={hasError}
    helperText={helperText}
    id="in-page-lead-form-phone-input"
    labelText='Phone number'
    maxLength={14}
    name='phone'
    onBlur={onBlur}
    onChange={onChange}
    onKeyUp={onKeyUp}
    ref={ref}
    required={true}
    themeType={themeType}
    type='tel'
    value={value} />
));

PhoneInputComponent.propTypes = {
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  value: PropTypes.string,
};

export default PhoneInputComponent;
