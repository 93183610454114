import React from 'react';
import PropTypes from 'prop-types';

export const HeadComponent = ({
  canonical,
  config,
  Container,
  metaRobotsNoIndexNoFollow,
  pageDescription,
  pageTitle,
  siteTitleFirst,
  useAkzidenz,
  useHeap,
  useReCaptcha,
}) => {
  const {
    adobeLaunchScript,
    appName,
    appVersion,
    basePath,
    nmxTemplateVersion,
    public: envPublic,
  } = config;

  return (
    <Container>
      <meta charSet="utf-8" />
      <meta
        httpEquiv="X-UA-Compatible"
        content="IE=edge" />
      {metaRobotsNoIndexNoFollow && (
        <meta
          name="robots"
          content="noindex, nofollow"></meta>
      )}

      {siteTitleFirst ? (
        <title>Northwestern Mutual | {pageTitle}</title>
      ) : (
        <title>{pageTitle} | Northwestern Mutual</title>
      )}

      {canonical && <link
        rel="canonical"
        href={canonical} />}

      <meta
        name="description"
        content={pageDescription} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0" />

      {/* LINKS ///////////// */}
      {<link
        rel="preconnect"
        href="https://assets.adobedtm.com"
        crossOrigin="true" />}
      {<script
        src={adobeLaunchScript}
        async></script>}

      <script>
        {`
          window.adobeDataLayer = window.adobeDataLayer || [];
          window.adobeDataLayer.push({
            'app-data': {
              name: '${appName}',
              version: '${appVersion}'
            },
          });
        `}
      </script>

      {useReCaptcha && (
        <script>
          {`
            var onloadCallback = function() {
              const isSafari = /^((?!chrome|android).)*safari/i.test(typeof navigator !== 'undefined' ? navigator.userAgent : '')
              if (isSafari && typeof window !== 'undefined') {
                const recaptchaEl = document.querySelector('.grecaptcha-badge')
                recaptchaEl.style.visibility = 'hidden'
              } else {
                // remove .safari-inflow-recaptcha-disclaimer if it is present
                const recaptchaPolicy = document.querySelector('.safari-inflow-recaptcha-disclaimer');
                if (recaptchaPolicy) {
                  recaptchaPolicy.style.display = 'none';
                }
              }
            };
          `}
        </script>
      )}
      {useReCaptcha && (
        <script
          src={`https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=${envPublic.recaptchaV3}`}
          async
          defer></script>
      )}

      {/* FAVICON */}
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${basePath}/template/assets/${nmxTemplateVersion}/images/favicon/apple-touch-icon.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`${basePath}/template/assets/${nmxTemplateVersion}/images/favicon/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`${basePath}/template/assets/${nmxTemplateVersion}/images/favicon/favicon-16x16.png`}
      />
      <link
        rel="manifest"
        href={`${basePath}/template/assets/${nmxTemplateVersion}/images/favicon/site.webmanifest`}
      />
      <link
        rel="mask-icon"
        href={`${basePath}/template/assets/${nmxTemplateVersion}/images/favicon/safari-pinned-tab.svg`}
        color="#00487b"
      />
      <link
        rel="shortcut icon"
        href={`${basePath}/template/assets/${nmxTemplateVersion}/images/favicon/favicon.ico`}
      />
      <meta
        name="apple-mobile-web-app-title"
        content="Northwestern Mutual" />
      <meta
        name="application-name"
        content="Northwestern Mutual" />
      <meta
        name="msapplication-TileColor"
        content="#da532c" />
      <meta
        name="msapplication-config"
        content={`${basePath}/template/assets/${nmxTemplateVersion}/images/favicon/browserconfig.xml`}
      />
      <meta
        name="theme-color"
        content="#ffffff"></meta>
      {/* END FAVICON */}

      {useAkzidenz && (
        <link
          rel="stylesheet"
          href="https://use.typekit.net/typ5dev.css" />
      )}

      {/* Fonts */}
      <link
        rel="preload"
        as="font"
        type="font/woff2"
        href={`${basePath}/template/assets/${nmxTemplateVersion}/fonts/guardian/GuardianSans-Medium.woff2`}
        crossOrigin="true"
      />
      <link
        rel="preload"
        as="font"
        type="font/woff2"
        href={`${basePath}/template/assets/${nmxTemplateVersion}/fonts/guardian/GuardianSans-Regular.woff2`}
        crossOrigin="true"
      />
      <link
        rel="preload"
        as="font"
        type="font/woff2"
        href={`${basePath}/template/assets/${nmxTemplateVersion}/fonts/guardian/GuardianSans-Thin.woff2`}
        crossOrigin="true"
      />
      <link
        rel="preload"
        as="font"
        type="font/woff2"
        href={`${basePath}/template/assets/${nmxTemplateVersion}/fonts/guardian/GuardianSans-Light.woff2`}
        crossOrigin="true"
      />

      {/* schema/base.schema.partial - only load on homepage */}
      {typeof window !== 'undefined' && window.location && window.location.pathname === '/' && (
        <script type="application/ld+json">
          {`{
          "@context": "https://schema.org",
          "@graph":
          [
            {
              "@type" : "Corporation",
              "name": "Northwestern Mutual",
              "legalName": "The Northwestern Mutual Life Insurance Company",
              "url": "https://www.northwesternmutual.com",
              "logo": "https://www.northwesternmutual.com/template/assets/1.1.132/images/logos/logo-horizontal-navy.svg",
              "contactPoint": [{
                "@type" : "ContactPoint",
                "telephone" : "+1-866-950-4644",
                "contactType" : "customer service"
              }],
              "diversityPolicy": "https://www.northwesternmutual.com/our-commitment-to-diversity/",
              "sameAs": [
                "https://www.facebook.com/northwesternmutual",
                "https://twitter.com/nm_financial",
                "https://www.linkedin.com/company/northwestern-mutual",
                "https://instagram.com/northwesternmutual"
              ]
            },
            {
              "@type": "WebSite",
              "name": "Northwestern Mutual",
              "url": "https://www.northwesternmutual.com",
              "potentialAction": {
                  "@type": "SearchAction",
                  "target": "https://www.northwesternmutual.com/?q={search_term}",
                  "query-input": "required name=search_term"
              }
            },
            {
              "@type": "SiteNavigationElement",
              "name": "Life Insurance",
              "description": "Life insurance policies including whole life insurance, universal life insurance, and term life insurance allow you to secure your family's future.",
              "url":"https://www.northwesternmutual.com/life-insurance/"
            },
            {
              "@type": "SiteNavigationElement",
              "name": "Financial Planning",
              "description": "Building a personal financial plan takes process, planning and strategy. Northwestern Mutual advisors provide experienced advisory to secure your financial goals",
              "url":"https://www.northwesternmutual.com/financial-planning/"
            },
            {
              "@type": "SiteNavigationElement",
              "name": "Investments",
              "description": "Our investment services provide tools for your investing account: learn how our investment products can help you reach your investing goals.",
              "url":"https://www.northwesternmutual.com/investments/"
            }
          ]
        }`}
        </script>
      )}

      {/* HEAP */}
      {useHeap && (
        <link
          rel='preconnect'
          href={`https://cdn.heapanalytics.com/js/heap-${config.heapAppId}.js`}
          as="script"
        />
      )}
      {useHeap && (
        <script
          type="text/javascript"
          async>
          {`
              window.heap = window.heap || [], heap.load = function (e, t) { window.heap.appid = e, window.heap.config = t = t || {}; const r = document.createElement("script"); r.type = "text/javascript", r.async = !0, r.src = \`https://cdn.heapanalytics.com/js/heap-\${e}.js\`; const a = document.getElementsByTagName("script")[0]; a.parentNode.insertBefore(r, a); for (let n = function (e) { return function () { heap.push([e].concat(Array.prototype.slice.call(arguments, 0))); }; }, p = ["addEventProperties", "addUserProperties", "clearEventProperties", "identify", "resetIdentity", "removeEventProperty", "setEventProperties", "track", "unsetEventProperty"], o = 0; o < p.length; o++)heap[p[o]] = n(p[o]); };heap.load(${config.heapAppId}, { disableTextCapture: true, });
            `}
        </script>
      )}

      <script
        type='text/javascript'
        async
        defer
        src={`${basePath}/assets/js/scripts/new-relic-06-2023.js`} />
    </Container>
  );
};

HeadComponent.propTypes = {
  /** Config vars used in head */
  config: PropTypes.shape({
    basePath: PropTypes.string.isRequired,
    nmxTemplateVersion: PropTypes.string.isRequired,
    newRelicApplicationId: PropTypes.string.isRequired,
    newRelicLicenseKey: PropTypes.string.isRequired,
    heapAppId: PropTypes.string.isRequired,
    adobeLaunchScript: PropTypes.string.isRequired,
    public: PropTypes.shape({ recaptchaV3: PropTypes.string }).isRequired,
  }).isRequired,
  /** container for head component. Replace with react-helmet or react-scripts helment if not wanting default <head /> */
  Container: PropTypes.any,
  metaRobotsNoIndexNoFollow: PropTypes.bool,
  siteTitleFirst: PropTypes.bool,
  pageTitle: PropTypes.string.isRequired,
  pageDescription: PropTypes.string.isRequired,
  canonical: PropTypes.string.isRequired,
  useReCaptcha: PropTypes.bool,
  useHeap: PropTypes.bool,
};

HeadComponent.defaultProps = {
  Container: 'head',
  metaRobotsNoIndexNoFollow: false,
  siteTitleFirst: false,
  useHeap: true,
};

export default HeadComponent;
