import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Label from '../Label';
import ValidationErrorLabel from '../HelperTextLabel';

import { InputStyled, TextAreaStyled } from './styles';

export const TextFieldComponent = React.forwardRef(({
  ariaLabel,
  disabled,
  hasError,
  helperText,
  id,
  inputType,
  labelText,
  labelVariant,
  multiline,
  name,
  onChange,
  placeholderText,
  required,
  textAreaRows,
  themeType,
  type,
  ...rest
}, ref) => {
  const [value, setValue] = useState('');

  const handleChange = (e) => {
    // set local value
    setValue(e.target.value);
    // call additional custom onChange function
    onChange(e);
  };

  return (
    {
      ...labelVariant === 'nested'
      && <Label
        disabled={disabled}
        hasError={hasError}
        helperText={helperText}
        htmlFor={id}
        inputType={inputType}
        labelText={labelText}
        ref={ref}
        required={required}
        themeType={themeType}
      >
        {
          !multiline
            ? <InputStyled
              {...ariaLabel && { 'aria-label': ariaLabel }} // NOTE: React implements exceptions to JSX, "aria-*" is one of them: https://reactjs.org/docs/accessibility.html
              aria-required={required}
              disabled={disabled}
              hasError={hasError}
              id={id}
              name={name}
              onChange={(e) => {
                handleChange(e);
              }}
              placeholder={placeholderText}
              themeType={themeType}
              type={type}
              {...type !== 'date' && { value }}
              {...rest}
            />
            : <TextAreaStyled
              {...ariaLabel && { 'aria-label': ariaLabel }} // NOTE: React implements exceptions to JSX, "aria-*" is one of them: https://reactjs.org/docs/accessibility.html
              aria-required={required}
              // defaultValue={defaultValue} // TODO: add in defaultValue for preliminary field value, needed?
              hasError={hasError}
              id={id}
              name={name}
              onChange={(e) => {
                handleChange(e);
              }}
              rows={textAreaRows}
              themeType={themeType}
              value={value}
              {...rest}></TextAreaStyled>
        }
        <ValidationErrorLabel
          disabled={disabled}
          hasError={hasError}
          helperText={helperText}
          themeType={themeType}
        />
      </Label>,
      ...labelVariant === 'sibling'
      && <>
        <Label
          disabled={disabled}
          hasError={hasError}
          helperText={helperText}
          htmlFor={id}
          inputType={inputType}
          labelText={labelText}
          ref={ref}
          required={required}
          themeType={themeType}
        />
        {
          !multiline
            ? <InputStyled
              {...ariaLabel && { 'aria-label': ariaLabel }} // NOTE: React implements exceptions to JSX, "aria-*" is one of them: https://reactjs.org/docs/accessibility.html
              aria-required={required}
              disabled={disabled}
              hasError={hasError}
              id={id}
              name={name}
              onChange={(e) => {
                handleChange(e);
              }}
              placeholder={placeholderText}
              themeType={themeType}
              type={type}
              {...type !== 'date' && { value }}
              {...rest}
            />
            : <TextAreaStyled
              {...ariaLabel && { 'aria-label': ariaLabel }} // NOTE: React implements exceptions to JSX, "aria-*" is one of them: https://reactjs.org/docs/accessibility.html
              aria-required={required}
              // defaultValue={defaultValue} // TODO: add in defaultValue for preliminary field value, needed?
              hasError={hasError}
              id={id}
              name={name}
              onChange={(e) => {
                handleChange(e);
              }}
              rows={textAreaRows}
              themeType={themeType}
              value={value}
              {...rest}></TextAreaStyled>
        }
        <ValidationErrorLabel
          disabled={disabled}
          hasError={hasError}
          helperText={helperText}
          themeType={themeType}
        />
      </>,
    }
  );
});

TextFieldComponent.propTypes = {
  /** Optional ariaLabel attribute, used for ADA to add more description to the TextField (more descriptive text field label) */
  ariaLabel: PropTypes.string,
  /** Disabled attribute */
  disabled: PropTypes.bool,
  /** Is this input in an error state */
  hasError: PropTypes.bool,
  /** Optional helperText positioned below input field */
  helperText: PropTypes.string,
  /** Required id attribute */
  id: PropTypes.string.isRequired,
  /** InputType with value select: depicts pseudo select dropdown styles */
  inputType: PropTypes.oneOf(['text', 'select']),
  /** The label placed on the text field */
  labelText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  /** default labelVariant is nested, sibling labelVariant separates label and input */
  labelVariant: PropTypes.oneOf(['nested', 'sibling']).isRequired,
  /** Specify a max character length for the text field */
  maxLength: PropTypes.number,
  /** Should this textField be a textarea? */
  multiline: PropTypes.bool,
  /** Name (attribute) applied to input or textarea */
  name: PropTypes.string, // TODO: this is starting to look like this should be required?
  /** Optional function when textField loses focus */
  onBlur: PropTypes.func,
  /** Optional function when textField contents changes */
  onChange: PropTypes.func,
  /** Optional function on textField entry (keyUp) */
  onKeyUp: PropTypes.func,
  /** Placeholder text when no value is provided */
  placeholderText: PropTypes.string,
  /** Textfield is required */
  required: PropTypes.bool,
  /** Number of textArea rows to show when multiline set to true */
  textAreaRows: PropTypes.number,
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme', 'nmx-pcg']),
  /** Required type attribute */
  type: PropTypes.oneOf(['date', 'email', 'number', 'password', 'tel', 'text']).isRequired,
  /** Required textField value */
  value: PropTypes.string, // TODO: security engineer, this should be string only?
};

TextFieldComponent.defaultProps = {
  disabled: false,
  hasError: false,
  helperText: '',
  inputType: 'text',
  labelVariant: 'nested',
  multiline: false,
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  onKeyDown: () => {},
  onKeyUp: () => {},
  required: false,
  textAreaRows: 5,
  themeType: 'lightTheme',
  type: 'text',
};

export default TextFieldComponent;
