/**
 * A very simple little function to log output.
 * ---
 * This is primarily here so clients can configure whether or not errors are thrown vs just logged out as console
 * output. For now, we're avoiding any actual 3rd party logging libraries to keep things simple, and because
 * it would be a bit overkill, since we only want a binary choice of "should throw error or not" (for now)
 *
 * This is in "Services" as it depends on a global config value
 */
import dataLayerConfig from '../dataLayerConfig';
/**
 * Logs the error instance passed in + its stack trace if any, or throws an error if
 * `dataLayerConfig.shouldThrowErrors` is turned on
 *
 * @param {Error} errorClass
 */

var error = function error(errorClass) {
  if (dataLayerConfig.shouldThrowErrors) {
    if (!(errorClass instanceof Error)) {
      throw new TypeError('If set, the argument must be an Error instance. e.g., `logger.error(new MyError("some error msg")`.');
    }

    throw errorClass;
  } else {
    console.error("Analytics library error: '".concat(errorClass.message, "',\n\nstacktrace:\n\n").concat(errorClass.stack));
  }
};

export { error };