import DOMPurify from 'dompurify';

export const sanitizeInput = (input) => DOMPurify.sanitize(input);

export const handleStateChange = (componentInstance) => (event) => {
  if (event.state && Object.prototype.hasOwnProperty.call(event.state, 'query')) {
    const sanitizedQuery = sanitizeInput(event.state.query);
    componentInstance.setState({ query: sanitizedQuery });
    componentInstance.performSearch();
  }
};

export default handleStateChange;
