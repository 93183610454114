import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Row } from '../../../foundations/Grid/grid';
import Typography from '../../../foundations/Typography';

import {
  ColContentContainerStyled,
  ColIconContainerStyled,
  LinkStyled,
  Styles,
} from './styles';

export const ProductResourceCalloutComponent = ({
  ariaLabel,
  icon,
  linkId,
  linkPath,
  linkText,
  text,
}) => {
  const styles = Styles();
  const IconToRender = icon;
  return (
    <Row align='center'>
      <ColIconContainerStyled
        xsmall={2}
        small={1}>
        <IconToRender style={styles.IconStyled} />
      </ColIconContainerStyled>
      <ColContentContainerStyled
        xsmall={10}
        small={11}>
        <Typography component='p'>
          {text}
        </Typography>
        <LinkStyled
          id={linkId}
          linkUrl={linkPath}
          variant='secondary'
          aria-label={ariaLabel}>
          {linkText}
        </LinkStyled>
      </ColContentContainerStyled>
    </Row>
  );
};

ProductResourceCalloutComponent.propTypes = {
  ariaLabel: PropTypes.string,
  icon: PropTypes.any.isRequired,
  linkId: PropTypes.string.isRequired,
  linkPath: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default withTheme(ProductResourceCalloutComponent);
