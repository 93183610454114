import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import { Col } from '../../../../foundations/Grid';
import Link from '../../../../components/Link';
import Typography from '../../../../foundations/Typography';

export const FooterSection = styled.section`
  padding-top: ${rem(32)};
`;

export const ColStyledFafa = styled(Col)`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    const largeUp = theme.largeUp();
    return css`
      margin-top: ${largeUp ? 0 : rem(32)};
      text-align: ${mediumUp ? 'left' : 'center'};
      @media print {
        max-width: 100%;
        flex: 0 100%;
      }
    `;
  }}
`;

export const TypographyStyledFafa = styled(Typography)`
  && {
    ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    const largeUp = theme.largeUp();
    return css`
      display: ${mediumUp ? 'inline-block' : 'block'};
      margin-right: ${mediumUp ? 0 : rem(16)};
      padding-bottom: ${largeUp ? rem(16) : rem(6)};
      text-align: ${mediumUp ? 'left' : 'center'};
    `;
  }}
  }
`;

export const LinkStyledFafa = styled(Link)`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    const largeUp = theme.largeUp();
    let thisMargin = '0 auto';
    if (largeUp) {
      thisMargin = `0 0 0 ${rem(20)}`;
    } else if (mediumUp) {
      thisMargin = 0;
    }
    return css`
      display: ${largeUp ? 'inline-block' : 'block'};
      margin: ${thisMargin};
    `;
  }}
`;

export const ColStyledAppDownload = styled(Col)`
${({ theme }) => {
    const largeUp = theme.largeUp();
    const large1200Up = theme.large1200Up();
    let thisPaddingLeft = 'auto';
    if (large1200Up) {
      thisPaddingLeft = rem(40);
    } else if (largeUp) {
      thisPaddingLeft = rem(10);
    }
    return css`
      padding-left: ${thisPaddingLeft};
    `;
  }}
`;

export const ColStyledSocialLinksLargeUp = styled(Col)`
${({ theme }) => {
    const largeUp = theme.largeUp();
    const large1200Up = theme.large1200Up();
    let thisPaddingLeft = 'auto';
    if (large1200Up) {
      thisPaddingLeft = rem(40);
    } else if (largeUp) {
      thisPaddingLeft = rem(10);
    }
    return css`
      padding-left: ${thisPaddingLeft};
    `;
  }}

`;
