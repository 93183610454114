import rem from 'polished/lib/helpers/rem';

const spacings = {
  xxxxxlarge: 72,
  xxxxlarge: 60,
  xxxlarge: 48,
  xxlarge: 40,
  xlarge: 32, // 2rem
  large: 28,
  medium: 24, // 1.5rem
  small: 20,
  xsmall: 16, // 1rem
  xxsmall: 12,
  xxxsmall: 8,
  xxxxsmall: 4,
};

// function which returns a multiplier on the default spacing of 4px
export default (multiplier = 1) => {
  let spacing;

  const funcs = {
    get() {
      // If a default spacing value is passed in as a string use that value, otherwise assume multiplier
      spacing = spacings[multiplier] || (4 * multiplier);
      return spacing;
    },
    px() {
      return `${spacing}px`;
    },
    rem() {
      return rem(this.px(spacing));
    },
  };

  spacing = funcs.get(multiplier);
  return funcs;
};
