import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import hexToRgba from 'hex-to-rgba';
import gradients from '../../foundations/theme/effects/gradients';

export const getSectionBackgroundColor = (
  backgroundVariant,
  branding,
  theme,
  themeType,
) => {
  // lightTheme backgroundVariants
  if (themeType === 'lightTheme') {
    if (backgroundVariant === 'lightA') {
      return theme.colors.background.lightTheme.lightA;
    }
    if (backgroundVariant === 'lightB') {
      return theme.colors.background.lightTheme.lightB;
    }
    if (backgroundVariant === 'lightC') {
      return theme.colors.background.lightTheme.lightC;
    }
    if (backgroundVariant === 'lightSky') {
      return theme.colors.background.lightTheme.lightSky;
    }
  }
  // darkTheme backgroundVariants
  if (themeType === 'darkTheme') {
    if (branding === 'pcg') {
      // TODO: PCG lightTheme
      return `
        ${gradients.pcgBlue};
      `;
    }
    if (backgroundVariant === 'darkDefault') {
      return theme.colors.background.darkTheme.darkDefault;
    }
  }

  // Default lightTheme lightA
  return theme.colors.background.lightTheme.lightA;
};

export const getSectionColor = (theme, themeType) => {
  if (themeType === 'darkTheme') {
    return theme.colors.text.darkTheme.default;
  }
  // all lightTheme backgroundVariants get this font color
  return theme.colors.text.lightTheme.default;
};

export const getSectionBackgroundTexture = (
  textureGranuleColor,
  textureGranuleShape,
  theme,
) => {
  const thisShapeSize = textureGranuleShape; // TODO: alternative shape size
  const thisShapePosition = 'center'; // TODO: alternative shape position
  const thisTextureGranuleColor = textureGranuleColor === 'blue' ? theme.colors.background.lightTheme.texture.blue : theme.colors.background.darkTheme.texture.sky; // TODO: alternative texture granule color
  return `radial-gradient(${thisShapeSize} at ${thisShapePosition}, ${thisTextureGranuleColor} 10%, transparent 10%), transparent`;
};

export const SectionContainerStyled = styled.section`
  ${({
    backgroundVariant,
    branding,
    disableBottomPadding,
    disableTopPadding,
    hasTexture,
    theme,
    themeType,
  }) => {
    const mediumUp = theme.mediumUp();
    let thisPaddingBottom = rem(48);
    /* If a disableBottomPadding prop was provided, override the default */
    if (disableBottomPadding) {
      thisPaddingBottom = 0;
    } else if (mediumUp) {
      thisPaddingBottom = rem(52);
    }

    let thisPaddingTop = rem(40);
    /* If a disableTopPadding prop was provided, override the default */
    if (disableTopPadding) {
      thisPaddingTop = 0;
    } else if (mediumUp) {
      thisPaddingTop = rem(52);
    }

    const thisBackgroundColor = getSectionBackgroundColor(
      backgroundVariant,
      branding,
      theme,
      themeType,
    ); /** formerly background-color, switched to background in favor of nmx-pcg gradient */

    return css`
      position: relative; /** for hasTexture */
      max-width: unset;
      padding-top: ${thisPaddingTop};
      && {
        padding-bottom: ${thisPaddingBottom};
      }
      background: ${thisBackgroundColor};
      color: ${getSectionColor(theme, themeType)};
      ${hasTexture
        && `& >.nmx-container {
          position: relative;
          z-index: 1;
        }`}
    `;
  }}
`;

export const BgTextureLeftContainerStyled = styled.div`
  ${({
    backgroundVariant,
    branding,
    textureGranuleColor,
    textureGranuleShape,
    theme,
    themeType,
  }) => {
    const thisBackgroundColor = getSectionBackgroundColor(
      backgroundVariant,
      branding,
      theme,
      themeType,
    ); /** formerly background-color, switched to background in favor of nmx-pcg gradient */
    const thisBackgroundTexture = getSectionBackgroundTexture(
      textureGranuleColor,
      textureGranuleShape,
      theme,
    );
    const thisBackgroundTextureSize = '15px 15px';

    return css`
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      margin-left: -60%;
      background: ${thisBackgroundTexture};
      background-size: ${thisBackgroundTextureSize};

      &::after {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        width: ${rem(500)};
        height: 100%;
        background: linear-gradient(
          45deg,
          ${hexToRgba(thisBackgroundColor, 0)} 40%,
          ${thisBackgroundColor} 50%
        );
      }
    `;
  }}
`;

export const BgTextureRightContainerStyled = styled.div`
  ${({
    backgroundVariant,
    branding,
    textureGranuleColor,
    textureGranuleShape,
    theme,
    themeType,
  }) => {
    const thisBackgroundColor = getSectionBackgroundColor(
      backgroundVariant,
      branding,
      theme,
      themeType,
    ); /** formerly background-color, switched to background in favor of nmx-pcg gradient */
    const thisBackgroundTexture = getSectionBackgroundTexture(
      textureGranuleColor,
      textureGranuleShape,
      theme,
    );
    const thisBackgroundTextureSize = '15px 15px';

    return css`
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      margin-left: 60%;
      background: ${thisBackgroundTexture};
      background-size: ${thisBackgroundTextureSize};

      &::after {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: ${rem(500)};
        height: 100%;
        background: linear-gradient(
          45deg,
          ${thisBackgroundColor} 50%,
          ${hexToRgba(thisBackgroundColor, 0)} 60%
        );
      }
    `;
  }}
`;
