import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { CONSTANTS, events } from '@gelato/analytics-datalayer';
import FrontendHttpService from '@nmx/utils-frontend/dist/services/FrontendHttpService';
import Location from '@nmx/utils-frontend/dist/utilities/frontend/location';
import Session from '@nmx/utils-frontend/dist/utilities/frontend/session';
import {
  autoFormatPhone,
  isPhoneNumber,
} from '@nmx/utils-frontend/dist/utilities/validators';
import { newRelicJSError } from '@nmx/utils-frontend/dist/utilities/frontend/Analytics/new_relic_helper';
import LeadService from '@nmx/utils-frontend/dist/services/LeadService/Frontend';
import { processAgentId } from '../../utils/processAgentId';
import { Container, Row } from '../../foundations/Grid';
import { goals } from './utilities/inPageLeadFormData';
import Form from '../../components/forms/Form';
import LoadingModule from './LoadingModule';
import NoMatchModule from './SubmissionScreens/NoMatchModule';
import ProgressBar from '../../components/ProgressBar';
import ThankYouModule from './SubmissionScreens/ThankYouModule';
// Inputs
import AgeInput from './inputs/AgeInput';
import EmailInput from './inputs/EmailInput';
import FirstNameInput from './inputs/FirstNameInput';
import GoalCardRadioInput from './inputs/GoalCardRadioInput';
import GoalSelectInput from './inputs/GoalSelectInput';
import IncomeRangeSelectInput from './inputs/IncomeRangeSelectInput';
import LastNameInput from './inputs/LastNameInput';
import PhoneInput from './inputs/PhoneInput';
import WealthRangeSelectInput from './inputs/WealthRangeSelectInput';
import ZipInput from './inputs/ZipInput';
// custom icons
import ChevronLeftIcon from '../../foundations/Icon/icons/utility/ChevronLeft';
import {
  babyCarriageIcon,
  beachChairIcon,
  familyIcon,
  graduationIcon,
  growthChartIcon,
  homeIcon,
  shieldIcon,
  walletIcon,
  wordBubbleIcon,
} from './goalCardIcons/goalCardIcons'; // TODO: this is probably not good for maintenance - we're probably safe to import each icon to this location based on themeType // TODO: looks like we'll need this sooner than later - Creative officially requested lightTheme InPageLeadForm Hero variant
// styles
import {
  ButtonBackStyled,
  ButtonNextStyled,
  ContainerGoalViewStyled,
  DivDynamicViewStyled,
  DivGoalCardsColStyled,
  DivInputColStyled,
  DivNextButtonContainerStyled,
  DivProgressBarContainerStyled,
  DivViewContainerStyled,
  RowGoalCardsStyled,
  SectionContainerStyled,
} from './styles';
// Tooltips
import {
  AgeTooltip,
  EmailTooltip,
  FirstNameTooltip,
  GoalTooltip,
  IncomeRangeTooltip,
  LastNameTooltip,
  PhoneTooltip,
  WealthRangeTooltip,
  ZipTooltip,
} from './tooltips/tooltips'; // TODO: this is probably not good for maintenance - we're probably safe to import each tooltip to this location based on themeType
// utilities
import {
  buildLeadPayload,
  initialAccessibilityObj,
  initialActiveViewsObj,
  validateCurrentInput,
  validateStep1,
  validateStep2,
  validateStep3,
  validateStep4,
} from './utilities/utilities';
// miscModules
import {
  embeddedHeadingRow,
  errorSubmittingMessage,
  getScreenNumber,
  heroContactInfoHeadingRow,
  heroDemographicHeadingRow,
  heroGoalsHeadingRow,
  heroPersonalInfoHeadingRow,
} from './MiscModules/miscModules';
// adobe variables
const {
  FORM_COMPLETE_FAILURE,
  FORM_COMPLETE,
  FORM_INTERACT,
  FORM_LOAD,
} = CONSTANTS.NAME_PUSH_EVENTS;
const { EMBEDDED_FAFA } = CONSTANTS.NAME_LEAD_FORM_EXPERIENCES;

let thisSubmissionTimeout;

export const cancelImmediateMatchTimeout = () => {
  clearTimeout(thisSubmissionTimeout);
};

export const setViewScreen = (step, allowTransition, activateViewTransition, setAllowTransition, variant, accessibilityTransitionSpeed) => {
  if (allowTransition) {
    switch (step) {
      case 'step1':
        activateViewTransition('isActiveViewStep1', 'isAccessibleStep1');
        break;
      case 'step2':
        if (variant === 'hero') {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        }
        activateViewTransition('isActiveViewStep2', 'isAccessibleStep2');
        break;
      case 'step3':
        activateViewTransition('isActiveViewStep3', 'isAccessibleStep3');
        break;
      case 'step4':
        activateViewTransition('isActiveViewStep4', 'isAccessibleStep4');
        break;
      case 'isSubmitting':
        activateViewTransition(
          'isActiveViewIsSubmitting',
          'isAccessibleIsSubmitting',
        );
        break;
      case 'thankYou':
        activateViewTransition('isActiveViewThankYou', 'isAccessibleThankYou');
        break;
      case 'error':
        activateViewTransition('isActiveViewError', 'isAccessibleError');
        cancelImmediateMatchTimeout();
        break;
      case 'noMatch':
        activateViewTransition('isActiveViewNoMatch', 'isAccessibleNoMatch');
        break;
      default:
      // do nothing
    }
    setAllowTransition(false);
    setTimeout(() => {
      setAllowTransition(true);
    }, accessibilityTransitionSpeed);
  }
};

export const checkForLowNetWorth = (thisIncomeRange, thisWealthRange) => {
  // return true if use selects under $40K for both income and wealth range dropdowns
  let res = false;
  if (thisIncomeRange === '39999' && thisWealthRange === '39999') {
    res = true;
  }
  return res;
};

export const redirectToFamPage = () => {
  // redirects the form to the /financial-advisor-match/ page for the immediate match experience
  window.location.href = '/financial-advisor-match/';
  return false;
};

const leadHttpService = new FrontendHttpService({
  baseURL: '<%=leadApiBaseUrl%>', // bring this back after dev
  // baseURL: 'https://qa.nmx.cxnon.apps.northwesternmutual.com/nmx-api-proxy/nmx/ms-form-proxy', // use this baseUrl for local testing/submitting
  fullResponse: true,
});

export const handleImmediateMatchTimeout = (immediateMatchFeatureFlagEnabled, timeoutInMs, isUnder40kNetWorth) => {
  // for immediate match, if the successful submission request hangs longer than 30 seconds, we redirect and show the user the email matching (error) view
  if (immediateMatchFeatureFlagEnabled) {
    thisSubmissionTimeout = setTimeout(() => {
      localStorage.setItem('belowLeadMinimum', isUnder40kNetWorth); // set localStorage value for use on the /financial-advisor-match/ page (FAM)
      localStorage.setItem('diamond', 'submissionTimeout');
      redirectToFamPage();
    }, timeoutInMs); // 30 seconds
  }
};

export const setCorrectViewsAndStorageValuesForUser = (
  immediateMatchFeatureFlagEnabled,
  isUnder40kNetWorth,
  response,
  setOtherPageSectionDisplays,
  setView,
) => {
  if (isUnder40kNetWorth) {
    if (immediateMatchFeatureFlagEnabled) {
      localStorage.setItem('belowLeadMinimum', true); // set localStorage value for use on the /financial-advisor-match/ page (FAM)
      localStorage.setItem('diamond', response.data); // unset dynamoId for under $40K users just to be safe
      redirectToFamPage();
    } else {
      setView('noMatch');
      Session.set('inPageLeadFormNoMatch', true); // session value we use to determine what other page sections should display on the FAFA page
    }
  } else if (immediateMatchFeatureFlagEnabled) {
    // success for immediate match experience
    localStorage.setItem('belowLeadMinimum', false);
    localStorage.setItem('diamond', response.data);
    redirectToFamPage();
  } else {
    // success for standard leads experience
    setView('thankYou');
    setOtherPageSectionDisplays();
    Session.set('inPageLeadFormNoMatch', false);
  }
};

export const InPageLeadFormComponent = (props) => {
  const {
    applicationId,
    backgroundVariant,
    branding,
    campaign,
    config,
    formLocation,
    immediateMatchFeatureFlagEnabled,
    onFormEngaged,
    sectionId,
    specialization,
    themeType,
    variant,
  } = props;

  // REFS /////////////////////////////
  // These refs are used to target the text within the input labels
  // so that we can capture their computed width and position the tooltips dynamically.
  const goalInputLabelText = useRef();
  const ageInputLabelText = useRef();
  const zipInputLabelText = useRef();
  const incomeRangeInputLabelText = useRef();
  const wealthRangeInputLabelText = useRef();
  const firstNameInputLabelText = useRef();
  const lastNameInputLabelText = useRef();
  const emailInputLabelText = useRef();
  const phoneInputLabelText = useRef();

  // React Hooks ////////////////////////////////
  // ACTIVE VIEW SETTER
  const [view, setActiveView] = useState({ initialActiveViewsObj });

  // ACCESSIBLE VIEW SETTER
  const [accessibility, setViewAccessibility] = useState({ initialAccessibilityObj });

  // Goals cards
  const [goalCardsArray, setGoalCardsArray] = useState();
  const [goalInputsArray, setGoalInputsArray] = useState();
  const [onLoadSectionsArray, setOnLoadSectionsArray] = useState();
  const [onSubmitSectionsArray, setOnSubmitSectionsArray] = useState();

  const [values, setValues] = useState({
    application_id: '',
    goal: '',
    age: '',
    zip: '',
    incomeRange: '',
    wealthRange: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });

  const [errors, setErrors] = useState({
    goal: false,
    age: false,
    zip: false,
    incomeRange: false,
    wealthRange: false,
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
  });

  // Error messages are set by this component's native utilities.js validators.
  const [errorMessages, setErrorMessages] = useState({
    goal: '',
    age: '',
    zip: '',
    incomeRange: '',
    wealthRange: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });

  const [viewValidation, setViewValidation] = useState({
    step1: false,
    step2: false,
    step3: false,
    step4: false,
  });

  const [displayWealthRange, setDisplayWealthRange] = useState(false);
  const [backButtonTarget, setBackButtonTarget] = useState('step1');
  const [progressBarFillPercentage, setProgressBarFillPercentage] = useState(0);
  const [formIsValid, setFormIsValid] = useState(false);
  // Sets the position for the tooltips next to the end of each label
  const [goalInputLabelPos, setGoalInputLabelPos] = useState('');
  const [ageInputLabelPos, setAgeInputLabelPos] = useState('');
  const [zipInputLabelPos, setZipInputLabelPos] = useState('');
  const [incomeRangeInputLabelPos, setIncomeRangeInputLabelPos] = useState('');
  const [wealthRangeInputLabelPos, setWealthRangeInputLabelPos] = useState('');
  const [firstNameInputLabelPos, setFirstNameInputLabelPos] = useState('');
  const [lastNameInputLabelPos, setLastNameInputLabelPos] = useState('');
  const [emailInputLabelPos, setEmailInputLabelPos] = useState('');
  const [phoneInputLabelPos, setPhoneInputLabelPos] = useState('');
  const [allowTransition, setAllowTransition] = useState(true);

  // The fading transition for the views is currently 1000 ms
  // The accessibility transition needs to occur slightly afterwards.
  const accessibilityTransitionSpeed = 1100;

  // Accepts strings of the property accessors for the view and accessibility objects
  // and sets the correct views to be active.
  const activateViewTransition = (viewStep, accessibilityStep) => {
    setActiveView({
      ...initialActiveViewsObj,
      [viewStep]: true,
    });
    // NOW, we want to make isAccessibleStep1: true IMMEDIATELY, and allow the previous view to still be true...
    // DELAY...then make all OTHER accessibility fields FALSE

    // persists other current states and makes the next view and previous view to true
    setViewAccessibility({
      ...accessibility,
      [accessibilityStep]: true,
    });
    setTimeout(() => {
      // Sets all states to false accept for the new current view
      setViewAccessibility({
        ...initialAccessibilityObj,
        [accessibilityStep]: true,
      });
    }, accessibilityTransitionSpeed);
  };

  const analyticsPush = (e) => {
    const { value, name } = e.target;
    events.leadForms.interact({
      experience: EMBEDDED_FAFA,
      actionType: name,
      actionValues: value,
      eventName: FORM_INTERACT,
    });
  };

  // Some sections get hidden and others display on submit.
  const setOtherPageSectionDisplays = () => {
    if (onSubmitSectionsArray) {
      for (let i = 0; i < onSubmitSectionsArray.length; i += 1) {
        onSubmitSectionsArray[i].style.display = 'block';
      }
    }
    if (onLoadSectionsArray) {
      for (let i = 0; i < onLoadSectionsArray.length; i += 1) {
        onLoadSectionsArray[i].style.display = 'none';
      }
    }
  };

  const setView = (step) => {
    setViewScreen(step, allowTransition, activateViewTransition, setAllowTransition, variant, accessibilityTransitionSpeed);
  };

  // EVENT HANDLERS ///////////////////////////////////////
  const handleChange = (e) => {
    // if goals card
    if (e.target.getAttribute('data-type') === 'goals-card') {
      if (goalCardsArray !== undefined && goalCardsArray !== null) {
        for (let i = 0; i < goalCardsArray.length; i += 1) {
          goalCardsArray[i].classList.remove('is-active');
          goalInputsArray[i].setAttribute('aria-checked', false);
        }
      }
      e.target.parentNode.classList.add('is-active');
      e.target.setAttribute('aria-checked', true);

      analyticsPush(e);
    }
    // validate individual fields as they are filled out
    const currentInputIsValid = validateCurrentInput(e, values.incomeRange);
    // Set error states for inputs in real-time.
    // If a field has an error, set the boolean to display the error tooltip.
    setErrors({
      ...errors,
      [e.target.name]: !currentInputIsValid[0],
    });
    // If there are errors, set the appropriate error message returned from the validators.
    setErrorMessages({
      ...errorMessages,
      [e.target.name]: currentInputIsValid[1],
    });
    // Set input values, used for display and in the payload for submission.
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
    // Inform parent component of engagement with form
    onFormEngaged();
  };

  const handlePhoneKeyUp = (e) => {
    // try to format the phone number on keyUp
    const phoneInput = e.target.value;
    // If the formatted num ends up being valid
    if (isPhoneNumber(phoneInput)) {
      const formattedPhoneInput = autoFormatPhone(phoneInput);
      setValues({
        ...values,
        phone: formattedPhoneInput,
      });
    }
  };

  const handleReCaptchaCallback = (recaptchaReponseToken) => {
    let request = null;
    let payload = null;
    const campaignName = Location.getQueryParam('campName') || 'NM.com In-page lead Form';
    const sampleFinancialPlanTourFromLocalStorage = JSON.parse(localStorage.getItem('sampleFinancialPlanTour')); // TODO: probably should check for localStorage !== 'undefined'

    const agentId = processAgentId(config);

    // set sfptValues from localStorage
    let sfptValues = {};
    if (campaign === 'sampleFinancialPlanTour') {
      sfptValues = {
        secondaryCampaignName: 'sample-plan',
        financialGoals: sampleFinancialPlanTourFromLocalStorage[0].financialGoals,
        ageRange: sampleFinancialPlanTourFromLocalStorage[1].ageRange,
        relationshipStatus: sampleFinancialPlanTourFromLocalStorage[2].relationshipStatus,
        childrenStatus: sampleFinancialPlanTourFromLocalStorage[3].childrenStatus,
        investmentExperience: sampleFinancialPlanTourFromLocalStorage[4].investmentExperience,
      };
    }

    payload = buildLeadPayload(
      values,
      agentId,
      campaignName,
      recaptchaReponseToken,
      sfptValues,
    );
    payload.is_recaptcha_v3 = true; // Moving this lead form to v3 recaptcha
    if (specialization) {
      // If algo sublist filter specialization is passed in, feed to API
      payload.specialization = specialization;
    }

    events.leadForms.complete({
      experience: EMBEDDED_FAFA,
      submissionType: 'lead',
      eventName: FORM_COMPLETE,
      submissionData: payload,
    });

    // SUBMIT THE FORM (based on feature flagging, the form can just submit to leads, or submit to the immediate match lead endpoint)
    if (immediateMatchFeatureFlagEnabled) {
      // immediate match lead submissions
      request = leadHttpService.post('/immediate-match', payload);
    } else {
      // default submissions for standard leads
      request = LeadService.submitLead(payload);
    }

    const isUnder40kNetWorth = checkForLowNetWorth(payload.income_range, payload.wealth_range);

    handleImmediateMatchTimeout(immediateMatchFeatureFlagEnabled, 30000, isUnder40kNetWorth);
    try {
      request
        .then((response) => {
          // if the user selected under $40K for household income and net worth, show alternate messaging
          setCorrectViewsAndStorageValuesForUser(
            immediateMatchFeatureFlagEnabled,
            isUnder40kNetWorth,
            response,
            setOtherPageSectionDisplays,
            setView,
          );
        })
        .catch((error) => {
          setView('error');
          console.error(error);
          newRelicJSError(`Error submitting lead on ${formLocation}.`, error);
        });
    } catch (error) {
      setView('error');
      console.error(error);
      newRelicJSError(`Error submitting lead on ${formLocation}.`, error);
      events.leadForms.completeFailure({
        experience: EMBEDDED_FAFA,
        errorMsg: `Unable to submit in-page lead form with error ${error}`,
        eventName: FORM_COMPLETE_FAILURE,
      });
    }
  };

  // call this function when we've validated the form and are ready to submit
  const executeRecaptchaAndSubmit = () => {
    setView('isSubmitting');
    // Wait 1100ms to allow submitting view to fully transition in before going to confirmation view
    setTimeout(() => {
      try {
        // recaptchaV3 method for executing
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(config.public.recaptchaV3, { action: 'submit' })
            .then((token) => {
              document
                .querySelectorAll('.recaptchaResponse')
                .forEach((elem) => {
                  // eslint-disable-next-line no-param-reassign
                  elem.value = token;
                });
              handleReCaptchaCallback(token); // standard lead submit
            });
        });
      } catch (error) {
        // if recaptcha execute fails
        setView('error');
        console.error(error);
        newRelicJSError(
          `Error executing recaptcha at form submit at path: ${window.location.pathname}.`,
          error,
        );
      }
    }, accessibilityTransitionSpeed);
  };

  // call this function when we've validated the form and there's an error
  const resetRecaptcha = () => {
    try {
      window.grecaptcha.ready(() => {
        // recaptchaV3 method for executing
        window.grecaptcha
          .execute(config.public.recaptchaV3, { action: 'reset' })
          .then((token) => {
            document.querySelectorAll('.recaptchaResponse').forEach((elem) => {
              // eslint-disable-next-line no-param-reassign
              elem.value = token;
            });
          });
      });
    } catch (error) {
      // if recaptcha execute fails, show error
      setView('error');
      console.error(error);
      newRelicJSError(
        `Error resetting recaptcha at form submit at path: ${window.location.pathname}.`,
        error,
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // final check for errors
    setFormIsValid(
      viewValidation.step1
        && viewValidation.step2
        && viewValidation.step3
        && viewValidation.step4,
    );

    if (formIsValid) {
      executeRecaptchaAndSubmit();
    } else {
      resetRecaptcha();
    }
  };

  // useEffect HOOKS ////////////////////////////////////
  useEffect(() => {
    // this is to set the initial state
    setValues({
      ...values,
      application_id: applicationId,
    });
    localStorage.setItem('belowLeadMinimum', false);
    Session.set('inPageLeadFormNoMatch', false);
    setView('step1'); // Change the initial view for testing/development here ( step1 is the original setting )
    setGoalCardsArray(document.querySelectorAll('.fafa-hero-goal-card'));
    setGoalInputsArray(
      document.querySelectorAll('.fafa-hero-goal-card-radio-input'),
    );
    setOnLoadSectionsArray(document.querySelectorAll('.js-hide-on-submit'));
    setOnSubmitSectionsArray(document.querySelectorAll('.js-show-on-submit'));

    // get input label width (with refs) so we can dynamically position the tooltips (with state vars)
    if (variant !== 'hero') {
      setGoalInputLabelPos(
        goalInputLabelText.current.getBoundingClientRect().width,
      );
    }
    setAgeInputLabelPos(ageInputLabelText.current.getBoundingClientRect().width);
    setZipInputLabelPos(zipInputLabelText.current.getBoundingClientRect().width);
    setIncomeRangeInputLabelPos(
      incomeRangeInputLabelText.current.getBoundingClientRect().width,
    );
    setFirstNameInputLabelPos(
      firstNameInputLabelText.current.getBoundingClientRect().width,
    );
    setLastNameInputLabelPos(
      lastNameInputLabelText.current.getBoundingClientRect().width,
    );
    setEmailInputLabelPos(
      emailInputLabelText.current.getBoundingClientRect().width,
    );
    setPhoneInputLabelPos(
      phoneInputLabelText.current.getBoundingClientRect().width,
    );
    events.leadForms.load({
      experience: EMBEDDED_FAFA,
      eventName: FORM_LOAD,
    });
  }, []);

  useEffect(() => {
    // Hiding other sections of the FAFA page on load. They will display again on submit.
    if (onSubmitSectionsArray) {
      for (let i = 0; i < onSubmitSectionsArray.length; i += 1) {
        onSubmitSectionsArray[i].style.display = 'none';
      }
    }
  }, [onSubmitSectionsArray]);

  // Updates progressBar everytime the view updates
  useEffect(() => {
    if (view.isActiveViewStep1) {
      setProgressBarFillPercentage(2); // Creative request to show 2% of ProgressBar to avoid display of empty progress
      setBackButtonTarget('');
    } else if (view.isActiveViewStep2) {
      setProgressBarFillPercentage(25);
      setBackButtonTarget('step1');
    } else if (view.isActiveViewStep3) {
      setProgressBarFillPercentage(50);
      setBackButtonTarget('step2');
    } else if (view.isActiveViewStep4) {
      setProgressBarFillPercentage(75);
      setBackButtonTarget('step3');
    } else if (view.isActiveViewIsSubmitting || view.isActiveViewError) {
      setProgressBarFillPercentage(100);
      setBackButtonTarget('step4');
    } else {
      setBackButtonTarget('');
    }
  }, [view]);

  // position tooltip for wealthRange when it displays
  useEffect(() => {
    if (displayWealthRange) {
      setWealthRangeInputLabelPos(
        wealthRangeInputLabelText.current.getBoundingClientRect().width,
      );
    }
  }, [displayWealthRange]);

  // As inputs are filled out, validate each field and check if the view is valid and complete
  useEffect(() => {
    // only validate steps if they are active
    if (view.isActiveViewStep1) {
      // STEP 1: If all fields are valid, set view as valid and activate next button
      setViewValidation({
        ...viewValidation,
        step1: validateStep1(values.goal),
      });
    } else if (view.isActiveViewStep2) {
      // Display wealth range input if low incomeRange
      setDisplayWealthRange(values.incomeRange && values.incomeRange <= 39999);
      // STEP 2: If all fields are valid, set view as valid and activate next button
      setViewValidation({
        ...viewValidation,
        step2: validateStep2(
          values.age,
          values.zip,
          values.incomeRange,
          values.wealthRange,
        ),
      });
    } else if (view.isActiveViewStep3) {
      // STEP 3: If all fields are valid, set view as valid and activate next button
      setViewValidation({
        ...viewValidation,
        step3: validateStep3(values.firstName, values.lastName),
      });
    } else if (view.isActiveViewStep4) {
      // STEP 4: If all fields are valid, set view as valid and activate submit button
      setViewValidation({
        ...viewValidation,
        step4: validateStep4(values.email, values.phone),
      });
    }
  }, [values]);

  useEffect(() => {
    setFormIsValid(
      viewValidation.step1
        && viewValidation.step2
        && viewValidation.step3
        && viewValidation.step4,
    );
  }, [viewValidation.step4]);

  return (
    <SectionContainerStyled
      backgroundVariant={backgroundVariant}
      branding={branding}
      id={sectionId}
      moduleName='in-page-lead-form-module'
      moduleVariation='A'
      themeType={themeType}>
      <DivProgressBarContainerStyled
        isActive={
          view.isActiveViewStep1
          || view.isActiveViewStep2
          || view.isActiveViewStep3
          || view.isActiveViewStep4
          || view.isActiveViewIsSubmitting
          || view.isActiveViewThankYou
          || view.isActiveViewError
          || view.isActiveViewNoMatch
        }>
        <ProgressBar
          branding={branding}
          fillPercentage={progressBarFillPercentage}
          themeType={themeType}
        />
      </DivProgressBarContainerStyled>
      <Form
        applicationId={applicationId}
        id={`${sectionId}-form`}
        legend='Find a financial advisor form'
        onSubmit={handleSubmit}>
        <DivViewContainerStyled
          views={view}
          variant={variant}
          displayWealthRange={displayWealthRange}
          errors={errors}>
          {backButtonTarget && (
            <ButtonBackStyled
              branding={branding}
              id='in-page-lead-form-back-button'
              startAdornment={ChevronLeftIcon}
              startAdornmentStylesReset
              themeType={themeType}
              variant='tertiary'
              onClick={() => {
                setView(backButtonTarget);
              }}>
              Back
            </ButtonBackStyled>
          )}
          {/* STEP 1 //////////////////////////////// */}
          <DivDynamicViewStyled
            isActive={view.isActiveViewStep1}
            aria-hidden={!view.isActiveViewStep1}
            isAccessible={accessibility.isAccessibleStep1}
            themeType={themeType}>
            <ContainerGoalViewStyled
              id='in-page-lead-financial-goal-info-view'
              variant={variant}>
              {getScreenNumber(1, themeType)}
              {variant === 'hero'
                ? heroGoalsHeadingRow
                : embeddedHeadingRow(false, 'h2', themeType, backgroundVariant)}
              {/* Only shows on the 'hero' variant of the form */}
              {variant === 'hero' && (
                <>
                  <RowGoalCardsStyled align="center">
                    <DivGoalCardsColStyled>
                      <GoalCardRadioInput
                        id="fafa-interactive-flow-goal-card-buy-a-home"
                        icon={homeIcon()}
                        value={goals[1].name}
                        onChange={handleChange}
                      />
                    </DivGoalCardsColStyled>
                    <DivGoalCardsColStyled>
                      <GoalCardRadioInput
                        id="fafa-interactive-flow-goal-card-save-for-college"
                        icon={graduationIcon()}
                        value={goals[2].name}
                        onChange={handleChange}
                      />
                    </DivGoalCardsColStyled>
                    <DivGoalCardsColStyled>
                      <GoalCardRadioInput
                        id="fafa-interactive-flow-goal-card-save-for-retirement"
                        icon={beachChairIcon()}
                        value={goals[3].name}
                        onChange={handleChange}
                      />
                    </DivGoalCardsColStyled>
                    <DivGoalCardsColStyled>
                      <GoalCardRadioInput
                        id="fafa-interactive-flow-goal-card-protect-my-income"
                        icon={shieldIcon()}
                        value={goals[4].name}
                        onChange={handleChange}
                      />
                    </DivGoalCardsColStyled>
                    <DivGoalCardsColStyled>
                      <GoalCardRadioInput
                        id="fafa-interactive-flow-goal-card-plan-for-my-growing-family"
                        icon={babyCarriageIcon()}
                        value={goals[5].name}
                        onChange={handleChange}
                      />
                    </DivGoalCardsColStyled>
                    <DivGoalCardsColStyled>
                      <GoalCardRadioInput
                        id="fafa-interactive-flow-goal-card-protect-my-family"
                        icon={familyIcon()}
                        value={goals[6].name}
                        onChange={handleChange}
                      />
                    </DivGoalCardsColStyled>
                    <DivGoalCardsColStyled>
                      <GoalCardRadioInput
                        id="fafa-interactive-flow-goal-card-grow-my-business"
                        icon={growthChartIcon()}
                        value={goals[7].name}
                        onChange={handleChange}
                      />
                    </DivGoalCardsColStyled>
                    <DivGoalCardsColStyled>
                      <GoalCardRadioInput
                        id="fafa-interactive-flow-goal-card-grow-my-investments"
                        icon={walletIcon()}
                        value={goals[8].name}
                        onChange={handleChange}
                      />
                    </DivGoalCardsColStyled>
                    <DivGoalCardsColStyled>
                      <GoalCardRadioInput
                        id="fafa-interactive-flow-goal-card-im-not-sure"
                        icon={wordBubbleIcon()}
                        value={goals[9].name}
                        onChange={handleChange}
                      />
                    </DivGoalCardsColStyled>
                    <DivGoalCardsColStyled />
                  </RowGoalCardsStyled>
                </>
              )}
              <Row align="center">
                {/* FINANCIAL GOAL SELECT DROPDOWN //////////////////////////////////////////////////////////////////// */}
                {variant !== 'hero' && (
                  <DivInputColStyled
                    inputWidth={390}
                    className="nmx-col">
                    <GoalSelectInput
                      hasError={errors.goal}
                      helperText={errorMessages.goal}
                      onBlur={analyticsPush}
                      onChange={handleChange}
                      ref={goalInputLabelText}
                      themeType={themeType}
                      value={values.goal}
                    />
                    <GoalTooltip
                      branding={branding}
                      leftPos={goalInputLabelPos}
                      themeType={themeType}
                    />
                  </DivInputColStyled>
                )}
                <DivNextButtonContainerStyled variant={variant}>
                  <ButtonNextStyled
                    branding={branding}
                    themeType={themeType}
                    id="in-page-lead-step-1-next-button"
                    variant="primary"
                    disabled={!viewValidation.step1}
                    ariaLabel="Proceed to step 2"
                    formvariant={variant}
                    onClick={() => {
                      setView('step2');
                    }}>
                    Next
                  </ButtonNextStyled>
                </DivNextButtonContainerStyled>
              </Row>
            </ContainerGoalViewStyled>
          </DivDynamicViewStyled>
          {/* STEP 2 //////////////////////////////// */}
          <DivDynamicViewStyled
            isActive={view.isActiveViewStep2}
            aria-hidden={!view.isActiveViewStep2}
            isAccessible={accessibility.isAccessibleStep2}
            themeType={themeType}>
            <Container id="in-page-lead-demographic-info-view">
              {getScreenNumber(2, themeType)}
              {variant === 'hero'
                ? heroDemographicHeadingRow
                : embeddedHeadingRow(true, 'p', themeType)}
              <Row align="center">
                {/* AGE INPUT //////////////////////////////////////////////////////////////////// */}
                <DivInputColStyled inputWidth={140}>
                  <AgeInput
                    hasError={errors.age}
                    helperText={errorMessages.age}
                    onBlur={analyticsPush}
                    onChange={handleChange}
                    ref={ageInputLabelText}
                    themeType={themeType}
                    type="number"
                    value={values.age}
                  />
                  <AgeTooltip
                    branding={branding}
                    leftPos={ageInputLabelPos}
                    themeType={themeType}
                  />
                </DivInputColStyled>
                {/* ZIP CODE INPUT //////////////////////////////////////////////////////////////////// */}
                <DivInputColStyled inputWidth={160}>
                  <ZipInput
                    hasError={errors.zip}
                    helperText={errorMessages.zip}
                    onBlur={analyticsPush}
                    onChange={handleChange}
                    ref={zipInputLabelText}
                    themeType={themeType}
                    value={values.zip}
                  />
                  <ZipTooltip
                    branding={branding}
                    leftPos={zipInputLabelPos}
                    themeType={themeType}
                  />
                </DivInputColStyled>
                {/* INCOME RANGE SELECT DROPDOWN //////////////////////////////////////////////////////////////////// */}
                <DivInputColStyled inputWidth={235}>
                  <IncomeRangeSelectInput
                    hasError={errors.incomeRange}
                    helperText={errorMessages.incomeRange}
                    onBlur={analyticsPush}
                    onChange={handleChange}
                    ref={incomeRangeInputLabelText}
                    themeType={themeType}
                    value={values.incomeRange}
                  />
                  <IncomeRangeTooltip
                    branding={branding}
                    leftPos={incomeRangeInputLabelPos}
                    themeType={themeType}
                  />
                </DivInputColStyled>
                {/* WEALTH RANGE SELECT DROPDOWN //////////////////////////////////////////////////////////////////// */}
                {displayWealthRange && (
                  <DivInputColStyled inputWidth={225}>
                    <WealthRangeSelectInput
                      hasError={errors.wealthRange}
                      helperText={errorMessages.wealthRange}
                      onBlur={analyticsPush}
                      onChange={handleChange}
                      ref={wealthRangeInputLabelText}
                      themeType={themeType}
                      value={values.wealthRange}
                    />
                    <WealthRangeTooltip
                      branding={branding}
                      leftPos={wealthRangeInputLabelPos}
                      themeType={themeType}
                    />
                  </DivInputColStyled>
                )}
                <DivNextButtonContainerStyled variant={variant}>
                  <ButtonNextStyled
                    branding={branding}
                    themeType={themeType}
                    id="in-page-lead-step-2-next-button"
                    variant="primary"
                    disabled={!viewValidation.step2}
                    ariaLabel="Proceed to step 3"
                    formvariant={variant}
                    onClick={() => {
                      setView('step3');
                    }}>
                    Next
                  </ButtonNextStyled>
                </DivNextButtonContainerStyled>
              </Row>
            </Container>
          </DivDynamicViewStyled>
          {/* STEP 3 //////////////////////////////// */}
          <DivDynamicViewStyled
            isActive={view.isActiveViewStep3}
            aria-hidden={!view.isActiveViewStep3}
            isAccessible={accessibility.isAccessibleStep3}
            themeType={themeType}>
            <Container id="in-page-lead-personal-info-view">
              {getScreenNumber(3, themeType)}
              {variant === 'hero'
                ? heroPersonalInfoHeadingRow
                : embeddedHeadingRow(true, 'p', themeType)}
              <Row align="center">
                {/* FIRST NAME INPUT //////////////////////////////////////////////////////////////////// */}
                <DivInputColStyled inputWidth={260}>
                  <FirstNameInput
                    hasError={errors.firstName}
                    helperText={errorMessages.firstName}
                    onBlur={analyticsPush}
                    onChange={handleChange}
                    ref={firstNameInputLabelText}
                    themeType={themeType}
                    value={values.firstName}
                  />
                  {variant !== 'hero' && (
                    <FirstNameTooltip
                      branding={branding}
                      leftPos={firstNameInputLabelPos}
                      themeType={themeType}
                    />
                  )}
                </DivInputColStyled>
                {/* LAST NAME INPUT //////////////////////////////////////////////////////////////////// */}
                <DivInputColStyled inputWidth={260}>
                  <LastNameInput
                    hasError={errors.lastName}
                    helperText={errorMessages.lastName}
                    onBlur={analyticsPush}
                    onChange={handleChange}
                    ref={lastNameInputLabelText}
                    themeType={themeType}
                    value={values.lastName}
                  />
                  {variant !== 'hero' && (
                    <LastNameTooltip
                      branding={branding}
                      leftPos={lastNameInputLabelPos}
                      themeType={themeType}
                    />
                  )}
                </DivInputColStyled>
                <DivNextButtonContainerStyled variant={variant}>
                  <ButtonNextStyled
                    branding={branding}
                    themeType={themeType}
                    id="in-page-lead-step-3-next-button"
                    variant="primary"
                    disabled={!viewValidation.step3}
                    ariaLabel="Proceed to step 4"
                    formvariant={variant}
                    onClick={() => {
                      setView('step4');
                    }}>
                    Next
                  </ButtonNextStyled>
                </DivNextButtonContainerStyled>
              </Row>
            </Container>
          </DivDynamicViewStyled>
          {/* STEP 4 //////////////////////////////// */}
          <DivDynamicViewStyled
            isActive={view.isActiveViewStep4}
            aria-hidden={!view.isActiveViewStep4}
            isAccessible={accessibility.isAccessibleStep4}
            themeType={themeType}>
            <Container id="in-page-lead-contact-info-view">
              {getScreenNumber(4, themeType)}
              {variant === 'hero'
                ? heroContactInfoHeadingRow
                : embeddedHeadingRow(true, 'p', themeType)}
              <Row align="center">
                {/* EMAIL INPUT //////////////////////////////////////////////////////////////////// */}
                <DivInputColStyled inputWidth={260}>
                  <EmailInput
                    hasError={errors.email}
                    helperText={errorMessages.email}
                    onBlur={analyticsPush}
                    onChange={handleChange}
                    ref={emailInputLabelText}
                    themeType={themeType}
                    value={values.email}
                  />
                  {variant !== 'hero' && (
                    <EmailTooltip
                      branding={branding}
                      leftPos={emailInputLabelPos}
                      themeType={themeType}
                    />
                  )}
                </DivInputColStyled>
                {/* PHONE INPUT //////////////////////////////////////////////////////////////////// */}
                <DivInputColStyled inputWidth={260}>
                  <PhoneInput
                    hasError={errors.phone}
                    helperText={errorMessages.phone}
                    onBlur={analyticsPush}
                    onChange={handleChange}
                    onKeyUp={handlePhoneKeyUp}
                    ref={phoneInputLabelText}
                    themeType={themeType}
                    value={values.phone}
                  />
                  {variant !== 'hero' && (
                    <PhoneTooltip
                      branding={branding}
                      leftPos={phoneInputLabelPos}
                      themeType={themeType}
                    />
                  )}
                </DivInputColStyled>
                <DivNextButtonContainerStyled variant={variant}>
                  <ButtonNextStyled
                    branding={branding}
                    themeType={themeType}
                    id="in-page-lead-step-submit-button"
                    variant="primary"
                    type="submit"
                    disabled={!formIsValid}
                    formvariant={variant}
                    ariaLabel="Submit form">
                    Submit
                  </ButtonNextStyled>
                </DivNextButtonContainerStyled>
              </Row>
            </Container>
          </DivDynamicViewStyled>
          {/* IS SUBMITTING //////////////////////////////// */}
          <DivDynamicViewStyled
            isActive={view.isActiveViewIsSubmitting}
            aria-hidden={!view.isActiveViewIsSubmitting}
            isAccessible={accessibility.isAccessibleIsSubmitting}
            centered
            themeType={themeType}>
            <LoadingModule
              branding={branding}
              themeType={themeType} />
          </DivDynamicViewStyled>
          {/* THANK YOU //////////////////////////////// */}
          <DivDynamicViewStyled
            isActive={view.isActiveViewThankYou}
            aria-hidden={!view.isActiveViewThankYou}
            isAccessible={accessibility.isAccessibleThankYou}
            centered
            themeType={themeType}>
            <ThankYouModule
              branding={branding}
              themeType={themeType}
              userName={values.firstName}
              variant={variant}
            />
          </DivDynamicViewStyled>
          {/* ERROR //////////////////////////////// */}
          <DivDynamicViewStyled
            isActive={view.isActiveViewError}
            aria-hidden={!view.isActiveViewError}
            isAccessible={accessibility.isAccessibleError}
            centered
            themeType={themeType}>
            {errorSubmittingMessage(themeType)}
          </DivDynamicViewStyled>
          {/* NO MATCH //////////////////////////////// */}
          <DivDynamicViewStyled
            isActive={view.isActiveViewNoMatch}
            aria-hidden={!view.isActiveViewNoMatch}
            isAccessible={accessibility.isAccessibleNoMatch}
            centered
            themeType={themeType}>
            <NoMatchModule
              branding={branding}
              themeType={themeType}
              userName={values.firstName}
              variant={variant}
            />
          </DivDynamicViewStyled>
        </DivViewContainerStyled>
        <input
          type="hidden"
          name="recaptcha_response"
          className="recaptchaResponse"
        />
      </Form>
    </SectionContainerStyled>
  );
};

InPageLeadFormComponent.propTypes = {
  /** application Id for the form */
  applicationId: PropTypes.string,
  /** backgroundVariant allows section to be different background color based on themeType */
  backgroundVariant: PropTypes.oneOf([
    'darkDefault',
    'lightA',
    'lightB',
    'lightC',
  ]),
  /** sets overall branding of component or module, default='nm' */
  branding: PropTypes.oneOf(['nm', 'pcg']),
  /** optional campaign name */
  campaign: PropTypes.string,
  /** currently used for form recaptcha options */
  config: PropTypes.shape({
    public: PropTypes.shape({ recaptchaInvisible: PropTypes.string })
      .isRequired,
  }),
  /** required string used for analytics */
  formLocation: PropTypes.string.isRequired,
  /** optional boolean, controlled by feature flagging, used for showing users the immediate match experience */
  immediateMatchFeatureFlagEnabled: PropTypes.bool,
  /** This callback is used to tell parent component that the form has first been engaged with */
  onFormEngaged: PropTypes.func,
  /** Id for the section */
  sectionId: PropTypes.string.isRequired,
  /** section theme, which determines color styles */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']),
  /** Specialization to be passed into the leads API and used in the algo sublist rep filtering logic */
  specialization: PropTypes.oneOf(['SPECIAL_NEEDS']),
  /** what variant of this form */
  variant: PropTypes.oneOf(['embedded', 'hero']).isRequired,
};

InPageLeadFormComponent.defaultProps = {
  applicationId: 'INPAGE-LEAD', // set it to be INPAGE-LEAD by default, and can be overrided
  backgroundVariant: 'darkDefault',
  branding: 'nm',
  campaign: '',
  // Necessary to allow legacy apps to capture the configs with string replace
  config: { public: { recaptchaInvisible: '<%=recaptchaInvisible%>' } },
  immediateMatchFeatureFlagEnabled: false,
  onFormEngaged: () => {},
  themeType: 'darkTheme',
  variant: 'embedded',
};

export default InPageLeadFormComponent;
