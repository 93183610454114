import React from 'react';
import PropTypes from 'prop-types';
// components
import AssistiveText from '../../../../components/AssistiveText';
import { Container } from '../../../../foundations/Grid';

export const FooterComponent = ({ customFooter }) => {
  const { footerContent, footerHeadingAssistiveText } = customFooter;
  // TODO: remove class "nmx-base" once nmx-template 1.0.x is implemented and "nmx-base" is added to body tag
  return (
    <footer
      id='footer'
      data-nosnippet>
      <Container>
        <AssistiveText component='h3'>
          {footerHeadingAssistiveText}
        </AssistiveText>
        {footerContent}
      </Container>
    </footer>
  );
};

FooterComponent.propTypes = {
  /** JSX customFooter markup, must use footerVariant='custom' for this content to render */
  customFooter: PropTypes.shape({
    /** Required customFooter content */
    footerContent: PropTypes.node.isRequired,
    /** Required field to satisfy HTML5 accessibility and SEO requirements */
    footerHeadingAssistiveText: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]).isRequired,
  }).isRequired,
};

FooterComponent.defaultProps = {
  customFooter: ({
    footerContent: (<></>),
    footerHeadingAssistiveText: (<>Find What You&#39;re Looking for at Northwestern Mutual</>),
  }),
};

export default FooterComponent;
