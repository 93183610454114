// deprecated
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
// components
import Link from '../../../components/Link';
import Placeholder from '../../DynamicAgentCard/Placeholder/placeholder';
import Typography from '../../../foundations/Typography';
import { Col, Row } from '../../../foundations/Grid';
// helpers
import sanitizePhone from '../../../../assets/js/helpers/sanitizePhone';

import {
  ColCtaContainerStyled,
  DivBottomCtaContainerStyled,
  DivFinraContainerStyled,
  DivInfoContainerStyled,
  LinkButtonStyled,
  PictureStyled,
  RowMediumFinraContainerStyled,
  SpanTitleHeadingStyled,
  TypographyWithIconStyled,
} from './styles';

const FinraLink = (themeType) => (
  <Link
    isOutgoingLink
    linkUrl='https://brokercheck.finra.org/'
    themeType={themeType}
    variant='primaryDeemphasize'>
    FINRA BrokerCheck
  </Link>
);

const getCtaRow = (
  linkUrl,
  themeType,
) => (
  <Row>
    <Col>
      <LinkButtonStyled
        linkUrl={linkUrl}
        themeType={themeType}
        variant='buttonLinkSecondary'>
        Let&#39;s Connect
      </LinkButtonStyled>
    </Col>
  </Row>
);

export const getFormattedFrImage = (firstName, imgSrc, lastName, name) => {
  if (imgSrc) {
    return <PictureStyled
      src={imgSrc}
      lazyLoad={false}
      altText={name}
      fullWidth
    />;
  }
  return <Placeholder
    first={firstName}
    last={lastName} />;
};

const EntityCard = ({
  config,
  dataObject,
  themeType,
}) => {
  const theme = useContext(ThemeContext);
  const largeUp = theme.largeUp();
  const mediumDown = theme.mediumDown();
  const {
    address,
    firstName,
    imgSrc,
    lastName,
    linkUrl,
    name,
    phone,
    title,
  } = dataObject;

  const getFormattedAddress = () => {
    const addressPieces = [];

    if (address.street) addressPieces.push(address.street);
    if (address.building) addressPieces.push(address.building);

    addressPieces.push(`\n${address.city}, ${address.state}`);

    if (address.zipLastFour) {
      addressPieces.push(`${address.zip}-${address.zipLastFour}`);
    } else {
      addressPieces.push(address.zip);
    }

    return addressPieces.join(' ');
  };

  return (
    <Row>
      <Col
        medium={3}
        large={3}>
        {getFormattedFrImage(firstName, imgSrc, lastName, name)}
        <RowMediumFinraContainerStyled>
          <Col align='center'>
            <Typography themeType={themeType}>
              {FinraLink(themeType)}
            </Typography>
          </Col>
        </RowMediumFinraContainerStyled>
      </Col>
      <Col
        medium={9}
        large={6}>
        <Row>
          <Col>
            <Typography
              component='h2'
              themeType={themeType}>
              {name} {title && <SpanTitleHeadingStyled>&#124; {title}</SpanTitleHeadingStyled>}
            </Typography>
          </Col>
        </Row>
        <DivInfoContainerStyled>
          <Row>
            <Col
              xsmall={10}
              medium={11}>
              <TypographyWithIconStyled
                config={config}
                icon={'map-pin'}
                component='address'
                variant='p'
                themeType={themeType}
                preWrap>
                {getFormattedAddress()}
              </TypographyWithIconStyled>
            </Col>
          </Row>
          <Row>
            <Col
              xsmall={10}
              medium={11}>
              <TypographyWithIconStyled
                config={config}
                icon={'phone'}
                themeType={themeType}>
                {phone && <Link
                  linkUrl={`tel:${sanitizePhone(phone)}`}
                  themeType={themeType}
                  variant='primaryDeemphasize'>
                  {phone}
                </Link>}
              </TypographyWithIconStyled>
            </Col>
          </Row>
        </DivInfoContainerStyled>
        {/* Only for medium view */}
        {(!largeUp && !mediumDown) && (
          <>
            {getCtaRow(linkUrl, themeType)}
          </>
        )}
      </Col>
      <ColCtaContainerStyled large={3}>
        {getCtaRow(linkUrl, themeType)}
        <Row>
          <Col>
            <DivBottomCtaContainerStyled>
              <LinkButtonStyled
                linkUrl={linkUrl}
                themeType={themeType}
                variant='buttonLinkSecondary'>
                View my profile page
              </LinkButtonStyled>
            </DivBottomCtaContainerStyled>
          </Col>
        </Row>
        <Row>
          <Col align='center'>
            <DivFinraContainerStyled>
              {FinraLink(themeType)}
            </DivFinraContainerStyled>
          </Col>
        </Row>
      </ColCtaContainerStyled>
    </Row>
  );
};

EntityCard.propTypes = {
  config: PropTypes.shape({
    basePath: PropTypes.string,
    nmxTemplateVersion: PropTypes.string,
  }),
  dataObject: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    address: PropTypes.shape({
      street: PropTypes.string,
      building: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.string,
      zipLastFour: PropTypes.string,
    }),
    phone: PropTypes.string,
    linkUrl: PropTypes.string,
    imgSrc: PropTypes.string,
  }),
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme', 'nmx-pcg']),
};

EntityCard.defaultProps = { themeType: 'lightTheme' };

export default EntityCard;
