import PropTypes from 'prop-types';
import em from 'polished/lib/helpers/em';
import rem from 'polished/lib/helpers/rem';
import { createGlobalStyle } from 'styled-components';
import Colors from '../theme/colors';
import breakpoints from '../theme/breakpoints';
import normalizeCss from './normalize';
import { getFonts, fontFamilySansSerif } from './fonts';
import { getFontHeadingCss } from './headings';

export const fontHeadingCss = getFontHeadingCss();
export const standardFontCss = `
  margin: 0;
  ${fontFamilySansSerif}
  font-size: ${rem(15)};
  font-style: normal;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: ${rem(23)};
  text-rendering: optimizeLegibility;

  @media (min-width: ${em(breakpoints.values.small)}) {
    font-size: ${rem(19)};
    line-height: ${rem(29)};
  }
  @media (min-width: ${em(breakpoints.values.xlarge)}) {
    font-size: ${rem(19)};
    line-height: ${rem(29)};
  }
`;

const GlobalStyles = createGlobalStyle`
  ${normalizeCss}
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  // TODO: remove tandelle?
  @font-face {
    font-display: swap;
    font-family: 'tandelle';
    font-weight: 400;
    src: local('Tandelle-Regular'),
      url('${(props) => props.config.basePath}/template/assets/${(props) => props.config.nmxTemplateVersion}/fonts/tandelle/Tandelle-Regular.woff2') format('woff2'),
      url('${(props) => props.config.basePath}/template/assets/${(props) => props.config.nmxTemplateVersion}/fonts/tandelle/Tandelle-Regular.woff') format('woff');
  }

  ${(props) => getFonts(props.config.basePath, props.config.nmxTemplateVersion)}

  body {
    color: ${Colors.text.lightTheme.default};
    ${fontFamilySansSerif}
  }
  body.modal-is-open {
    position: fixed;
    overflow: hidden;
    width: 100%;
    #onetrust-banner-sdk {
      z-index: 0;
    }
  }

  ::-ms-clear {
    display: none;
  }

  embed,
  img,
  object,
  video {
    max-width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: 500;
    letter-spacing: ${rem(0.5)};
    text-rendering: optimizeLegibility;
    @media (min-width: ${em(breakpoints.values.small)}) {
      letter-spacing: ${rem(1)};
    }
  }

  ${getFontHeadingCss()}

  button,
  input,
  optgroup,
  select,
  textarea {
    ${fontFamilySansSerif}
  }

  .nmx-jumbo-type {
    font-size: ${rem(60)};
    line-height: ${rem(50)};

    @media (min-width: ${em(breakpoints.values.medium)}) {
      font-size: ${rem(50)};
      line-height: ${rem(48)};
    }
    @media (min-width: ${em(breakpoints.values.large)}) {
      font-size: ${rem(80)};
      line-height: ${rem(70)};
    }
  }

  p, .nmx-p {
    ${standardFontCss}
  }

  small, .nmx-small {
    ${fontFamilySansSerif}
    font-size: ${rem(12)}; // typically 80% of "base" fonts size at that breakpoint.
    font-style: normal;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: ${rem(19)}; // typically 80% of "base" fonts size at that breakpoint.
    text-rendering: optimizeLegibility;
  }

  ul {
    padding-left: 1rem;
  }

  address {
    font-style: normal;
  }

  .nmx-assistive-text {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
  }

  .nmx-print {
    display: none;
  }

  /* // reduced default */
  ul.reduced {
    padding: 0;
    /* // tag-qualified for higher specificity */
    margin: 0;
    list-style: none;
  }

  @media print {
    body {
      margin: 1cm; // TODO: move to @page?
    }

    * {
      color: #000 !important;
      text-shadow: none !important;
      background-color: #fff !important;
      transition: none !important;
    }

    a[href],
    a[href]:hover {
      font-weight: 400 !important;
      text-decoration: none !important;
    }

    /* // show/hide specific elements */
    .nmx-no-print,
    .nmx-assistive-text,
    #header-logo,
    #nmx-client-login,
    #nmx-search-console,
    #nmx-mobile-nav-container,
    #nmx-nav-link-login-button-container,
    .nmx-login-button,
    .nm-expandable-toast,
    .grecaptcha-badge {
      /* // hide main header */
      /* // TODO: use better header hook (id) when template work is at a good spot */
      display: none !important;
    }

    .nmx-print {
      display: block !important;
    }

    embed,
    nav,
    object,
    video {
      display: none !important;
    }

    blockquote,
    img,
    pre {
      page-break-inside: avoid;
    }
  }
`;

GlobalStyles.propTypes = {
  config: PropTypes.shape({
    basePath: PropTypes.string.isRequired,
    nmxTemplateVersion: PropTypes.string.isRequired,
  }).isRequired,
};

GlobalStyles.defaultProps = {
  config: {
    basePath: '<%=basePath%>',
    nmxTemplateVersion: '<%=nmxTemplateVersion%>',
  },
};

export default GlobalStyles;
