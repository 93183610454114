import rem from 'polished/lib/helpers/rem';
import styled, { css } from 'styled-components';

import gradients from '../../foundations/theme/effects/gradients';

const BUTTON_TRANSITION = '100ms';

// Default Styles
const getDefaultStyles = (mediumUp) => css`
  display: inline-flex;
  min-width: ${rem(200)}; /** TODO: configure mobile width (full-width with 1rem outer gutter at small (480) and lower) vs what other max/min-width? */
  max-width: max-content;
  height: auto;
  min-height: ${mediumUp ? rem(55) : rem(47)};
  padding: ${rem(11)} ${rem(25)} ${rem(9)};
  background-clip: padding-box; /** ff fix */
  border-radius: ${rem(2)};
  font-size: ${rem(16)};
  font-weight: 500;
  letter-spacing: ${rem(1)};
  line-height: ${rem(20)};
  -webkit-tap-highlight-color: transparent;
  text-align: center;
  align-items: center;
  justify-content: center;
  transition:
    /** TODO: confirm autoprefixer handles this */
    background-color ${BUTTON_TRANSITION} ease-out,
    color ${BUTTON_TRANSITION} ease-out,
    border-color ${BUTTON_TRANSITION} ease-out;
  cursor: default; /** button cursor no longer using pointer unless a linkButton */
  user-select: none;

  /** button states (hover, active, focus) handled by individual variants */

  /** ff focus fix */
  &::-moz-focus-inner {
    padding: 0;
    border: none;
  }

  @media print {
    display: none !important;
  }
`;

// ////////////////////////////////////////////////////////////////
// primary button functions

// Primary Light Theme
const getPrimaryLightThemeStyles = (theme) => css`
  border: ${rem(1.5)} solid ${theme.colors.action.lightTheme.ctaBgDefault};
  background-color: ${theme.colors.action.lightTheme.ctaBgDefault};
  color: ${theme.colors.action.lightTheme.ctaTextDefault};

  /** button states */
  /** .is-active is the JS alternative (legacy) way for us to trigger the hover state on a button dynamically */
  /** TODO: remove .is-active if not being used post-legacy */
  &:hover,
  &.is-active {
    border-color: ${theme.colors.action.lightTheme.ctaBgHover};
    background-color: ${theme.colors.action.lightTheme.ctaBgHover};
  }

  &:active {
    border-color: ${theme.colors.action.lightTheme.ctaBgActive};
    background-color: ${theme.colors.action.lightTheme.ctaBgActive};
  }

  &:focus {
    /** browser to handle native focus */
  }
`;

// Primary Dark Theme
const getPrimaryDarkThemeStyles = (theme) => css`
  border: ${rem(1.5)} solid ${theme.colors.action.darkTheme.ctaBgDefault};
  background-color: ${theme.colors.action.darkTheme.ctaBgDefault};
  color: ${theme.colors.action.darkTheme.ctaTextDefault};

  /** button states */
  &:hover,
  &.is-active {
    border-color: ${theme.colors.action.darkTheme.ctaBgHover};
    background-color: ${theme.colors.action.darkTheme.ctaBgHover};
  }

  &:active {
    border-color: ${theme.colors.action.darkTheme.ctaBgActive};
    background-color: ${theme.colors.action.darkTheme.ctaBgActive};
  }

  &:focus {
    /** browser to handle native focus */
    /** offset focus ring for higher visibility */
    outline: auto ${rem(5)} -webkit-focus-ring-color;
  }
`;

/* primary button styles, lightTheme or darkTheme, when button is not disabled */
const getPrimaryButtonStyles = (theme, themeType, variant, disabled) => {
  let primaryButtonStyles;
  if (variant === 'primary' && !disabled) {
    if (themeType === 'lightTheme') {
      primaryButtonStyles = getPrimaryLightThemeStyles(theme);
    } else if (themeType === 'darkTheme') {
      primaryButtonStyles = getPrimaryDarkThemeStyles(theme);
    }
  }
  return primaryButtonStyles;
};

// ////////////////////////////////////////////////////////////////
// secondary button functions

// Secondary Light Theme
const getSecondaryLightThemeStyles = (theme) => css`
  border: ${rem(1.5)} solid ${theme.colors.action.lightTheme.ctaBgDefault};
  background-color: ${theme.colors.action.lightTheme.ctaBgSecondary};
  color: ${theme.colors.action.lightTheme.ctaBgDefault};

  /** button states */
  &:hover,
  &.is-active {
    border-color: ${theme.colors.action.lightTheme.ctaBgHover};
    background-color: ${theme.colors.action.lightTheme.ctaBgSecondaryHover};
    color: ${theme.colors.action.lightTheme.ctaBgHover};
  }

  &:active {
    border-color: ${theme.colors.action.lightTheme.ctaBgActive};
    background-color: ${theme.colors.action.lightTheme.ctaBgSecondaryActive};
    color: ${theme.colors.action.lightTheme.ctaBgActive};
  }

  &:focus {
    /** browser to handle native focus */
    background-color: ${theme.colors.action.lightTheme.ctaBgSecondaryHover};
  }
`;

// Secondary Dark Theme
const getSecondaryDarkThemeStyles = (theme) => css`
  border: ${rem(1.5)} solid ${theme.colors.action.darkTheme.ctaTextSecondary};
  background-color: ${theme.colors.action.darkTheme.ctaBgSecondary};
  color: ${theme.colors.action.darkTheme.ctaTextSecondary};

  /** button states */
  &:hover,
  &.is-active {
    border-color: ${theme.colors.action.darkTheme.ctaTextSecondary};
    background-color: ${theme.colors.action.darkTheme.ctaBgSecondaryHover};
    color: ${theme.colors.action.darkTheme.ctaTextSecondary};
  }

  &:active {
    border-color: ${theme.colors.action.darkTheme.ctaTextSecondary};
    background-color: ${theme.colors.action.darkTheme.ctaBgSecondaryActive};
    color: ${theme.colors.action.darkTheme.ctaTextSecondary};
  }

  &:focus {
    /** browser to handle native focus */
    /** offset focus ring for higher visibility */
    background-color: ${theme.colors.action.darkTheme.ctaBgSecondaryHover};
    outline: auto 5px -webkit-focus-ring-color;
  }
`;

/* secondary button styles, lightTheme or darkTheme, when button is not disabled */
const getSecondaryButtonStyles = (theme, themeType, variant, disabled) => {
  let secondaryButtonStyles;
  if (variant === 'secondary' && !disabled) {
    if (themeType === 'lightTheme') {
      secondaryButtonStyles = getSecondaryLightThemeStyles(theme);
    } else if (themeType === 'darkTheme') {
      secondaryButtonStyles = getSecondaryDarkThemeStyles(theme);
    }
  }
  return secondaryButtonStyles;
};

// ////////////////////////////////////////////////////////////////
// tertiary button functions

// Tertiary Light Theme
const getTertiaryLightThemeStyles = (theme, mediumUp) => css`
   min-width: unset;
   min-height: ${mediumUp ? rem(38) : rem(32)};
   padding: 0;
   border: none;
   background-color: ${theme.colors.action.lightTheme.ctaBgTertiary};
   color: ${theme.colors.action.lightTheme.ctaBgDefault};

   /** button states */
   &:hover,
   &.is-active {
     border: none;
     background-color: ${theme.colors.action.lightTheme.ctaBgTertiary};
     color: ${theme.colors.action.lightTheme.ctaBgHover};
     text-decoration: underline;
     text-underline-offset: ${rem(5)};
   }

   &:active {
     border: none;
     background-color: ${theme.colors.action.lightTheme.ctaBgTertiary};
     color: ${theme.colors.action.lightTheme.ctaBgActive};
   }

   &:focus {
     /** browser to handle native focus */
   }
 `;

// Tertiary Dark Theme
const getTertiaryDarkThemeStyles = (theme, mediumUp) => css`
  min-width: unset;
  min-height: ${mediumUp ? rem(38) : rem(32)};
  padding: 0;
  border: none;
  background-color: ${theme.colors.action.darkTheme.ctaBgTertiary};
  color: ${theme.colors.action.darkTheme.ctaTextTertiary};

  /** button states */
  &:hover,
  &.is-active {
    border: none;
    background-color: ${theme.colors.action.darkTheme.ctaBgTertiary};
    color: ${theme.colors.action.darkTheme.ctaTextTertiary};
    text-decoration: underline;
    text-underline-offset: ${rem(5)};
  }

  &:active {
    border: none;
    background-color: ${theme.colors.action.darkTheme.ctaBgSecondaryActive};
    color: ${theme.colors.action.darkTheme.ctaTextTertiary};
  }

  &:focus {
    /** browser to handle native focus */
    /** offset focus ring for higher visibility */
    outline: auto ${rem(5)} -webkit-focus-ring-color;
  }
`;

/* tertiary button styles, lightTheme or darkTheme, when button is not disabled */
const getTertiaryButtonStyles = (theme, themeType, variant, disabled, mediumUp) => {
  let tertiaryButtonStyles;
  if (variant === 'tertiary' && !disabled) {
    if (themeType === 'lightTheme') {
      tertiaryButtonStyles = getTertiaryLightThemeStyles(theme, mediumUp);
    } else if (themeType === 'darkTheme') {
      tertiaryButtonStyles = getTertiaryDarkThemeStyles(theme, mediumUp);
    }
  }
  return tertiaryButtonStyles;
};

// ////////////////////////////////////////////////////////////////
/* DISABLED BUTTON STYLE FUNCTIONS */

// Disabled Primary Light Theme
const getPrimaryDisabledLightThemeStyles = (theme) => css`
  border: ${rem(1.5)} solid ${theme.colors.action.lightTheme.ctaBgDisabled};
  background-color: ${theme.colors.action.lightTheme.ctaBgDisabled};
  color: ${theme.colors.action.lightTheme.ctaTextDisabled};

  /** button states */
  &:hover,
  &.is-active,
  &:active,
  &:focus {
    border-color: ${theme.colors.action.lightTheme.ctaBgDisabled};
    background-color: ${theme.colors.action.lightTheme.ctaBgDisabled};
  }
`;

// Disabled Primary Dark Theme
const getPrimaryDisabledDarkThemeStyles = (theme) => css`
  border: ${rem(1.5)} solid ${theme.colors.action.darkTheme.ctaBgDisabled};
  background-color: ${theme.colors.action.darkTheme.ctaBgDisabled};
  color: ${theme.colors.action.darkTheme.ctaTextDisabled};

  /** button states */
  &:hover,
  &.is-active,
  &:active,
  &:focus {
    border-color: ${theme.colors.action.darkTheme.ctaBgDisabled};
    background-color: ${theme.colors.action.darkTheme.ctaBgDisabled};
  }
`;

// Disabled Secondary Light Theme
const getSecondaryDisabledLightThemeStyles = (theme) => css`
  border: ${rem(1.5)} solid ${theme.colors.action.lightTheme.ctaBgDisabled};
  background-color: ${theme.colors.action.lightTheme.ctaBgSecondary};
  color: ${theme.colors.action.lightTheme.ctaTextDisabled};

  /** button states */
  &:hover,
  &.is-active,
  &:active,
  &:focus {
    border-color: ${theme.colors.action.lightTheme.ctaBgDisabled};
    background-color: ${theme.colors.action.lightTheme.ctaBgSecondary};
    color: ${theme.colors.action.lightTheme.ctaTextDisabled};
  }
`;

// Disabled Secondary Dark Theme
const getSecondaryDisabledDarkThemeStyles = (theme) => css`
  border: ${rem(1.5)} solid ${theme.colors.action.lightTheme.ctaBgDisabled};
  background-color: ${theme.colors.action.lightTheme.ctaBgSecondary};
  color: ${theme.colors.action.lightTheme.ctaBgDisabled};

  /** button states */
  &:hover,
  &.is-active,
  &:active,
  &:focus {
    border-color: ${theme.colors.action.lightTheme.ctaBgDisabled};
    background-color: ${theme.colors.action.lightTheme.ctaBgSecondary};
    color: ${theme.colors.action.lightTheme.ctaBgDisabled};
  }
`;

// Disabled Tertiary Light Theme
const getTertiaryDisabledLightThemeStyles = (theme) => css`
  border: none;
  background-color: ${theme.colors.action.lightTheme.ctaBgTertiary};
  color: ${theme.colors.action.lightTheme.ctaTextDisabled};

  /** button states */
  &:hover,
  &.is-active,
  &:active,
  &:focus {
    border: none;
    background-color: ${theme.colors.action.lightTheme.ctaBgTertiary};
    color: ${theme.colors.action.lightTheme.ctaTextDisabled};
  }
`;

// Disabled Tertiary Dark Theme
const getTertiaryDisabledDarkThemeStyles = (theme) => css`
  border: none;
  background-color: ${theme.colors.action.darkTheme.ctaBgTertiary};
  color: ${theme.colors.action.darkTheme.ctaTextTertiaryDisabled};

  /** button states */
  &:hover,
  &.is-active,
  &:active,
  &:focus {
    border: none;
    background-color: ${theme.colors.action.darkTheme.ctaBgTertiary};
    color: ${theme.colors.action.darkTheme.ctaTextTertiaryDisabled};
  }
`;

/* primary button styles, lightTheme or darkTheme, when button IS disabled */
const getPrimaryDisabledButtonStyles = (theme, themeType, variant, disabled) => {
  let primaryDisabledButtonStyles;
  if (variant === 'primary' && disabled) {
    if (themeType === 'lightTheme') {
      primaryDisabledButtonStyles = getPrimaryDisabledLightThemeStyles(theme);
    } else if (themeType === 'darkTheme') {
      primaryDisabledButtonStyles = getPrimaryDisabledDarkThemeStyles(theme);
    }
  }
  return primaryDisabledButtonStyles;
};

/* secondary button styles, lightTheme or darkTheme, when button IS disabled */
const getSecondaryDisabledButtonStyles = (theme, themeType, variant, disabled) => {
  let secondaryDisabledButtonStyles;
  if (variant === 'secondary' && disabled) {
    if (themeType === 'lightTheme') {
      secondaryDisabledButtonStyles = getSecondaryDisabledLightThemeStyles(theme);
    } else if (themeType === 'darkTheme') {
      secondaryDisabledButtonStyles = getSecondaryDisabledDarkThemeStyles(theme);
    }
  }
  return secondaryDisabledButtonStyles;
};

/* tertiary button styles, lightTheme or darkTheme, when button IS disabled */
const getTertiaryDisabledButtonStyles = (theme, themeType, variant, disabled) => {
  let tertiaryDisabledButtonStyles;
  if (variant === 'tertiary' && disabled) {
    if (themeType === 'lightTheme') {
      tertiaryDisabledButtonStyles = getTertiaryDisabledLightThemeStyles(theme);
    } else if (themeType === 'darkTheme') {
      tertiaryDisabledButtonStyles = getTertiaryDisabledDarkThemeStyles(theme);
    }
  }
  return tertiaryDisabledButtonStyles;
};

// ////////////////////////////////////////////////////////////////
// PCG functions
const getPcgRuleGradientStylesHover = () => css`
  ${gradients.pcgGoldHover};
`;

const getPcgRuleGradientStyles = () => css`
  ${gradients.pcgGold};
`;

const getPrimaryPcgStyles = (theme) => css`
  border: none;
  color: ${theme.colors.neutral.grayDark20};
  letter-spacing: ${rem(3)};
  text-transform: uppercase;
  ${getPcgRuleGradientStyles()}

  /** base button states */
  &:hover {
    border: none;
    ${getPcgRuleGradientStylesHover()}
  }

  &:focus {
    border: none;
  }

  &:active {
    border: none;
  }
`;

/* PCG button styles */
const getPrimaryPcgDisabledStyles = (theme) => css`
  border: none;
  color: ${theme.colors.neutral.gray};
  letter-spacing: ${rem(3)};
  text-transform: uppercase;
  background-color: ${theme.colors.neutral.grayLight10};
`;

const pcgButtonStyles = (theme, disabled) => (disabled ? getPrimaryPcgDisabledStyles(theme) : getPrimaryPcgStyles(theme));

// ////////////////////////////////////////////////////////////////

export const BaseButton = styled.button`
  ${({ branding, disabled, theme, themeType, variant }) => {
    const mediumUp = theme.mediumUp();

    // Themed Buttons
    return css`
      /** Default/Base Button styles get loaded first every time */
      ${getDefaultStyles(mediumUp)}
      /** then, conditionally, color, border, background-color styles get added */

      /* primary styles */
      ${getPrimaryButtonStyles(theme, themeType, variant, disabled)};

      /* secondary styles */
      ${getSecondaryButtonStyles(theme, themeType, variant, disabled)};

      /* tertiary styles */
      ${getTertiaryButtonStyles(theme, themeType, variant, disabled, mediumUp)};

      /* get disabled button styles */
      /* disabled primary styles */
      ${getPrimaryDisabledButtonStyles(theme, themeType, variant, disabled)};

      /* disabled secondary styles */
      ${getSecondaryDisabledButtonStyles(theme, themeType, variant, disabled)};

      /* disabled secondary styles */
      ${getTertiaryDisabledButtonStyles(theme, themeType, variant, disabled)};

      /* set pcg button styles */
      ${branding === 'pcg' && pcgButtonStyles(theme, disabled)} 
    `;
  }}
`;

// TODO: icons using stroke instead of fill should be using currentColor

export const StartAdornmentContainerStyled = styled.span`
  ${({ startAdornmentStylesReset }) => `
    width: ${rem(19)};
    height: ${rem(19)};
    margin-right: ${rem(7)};
    ${!startAdornmentStylesReset && `
      .nmx-icon {
        position: relative;
        width: ${rem(19)};
        height: ${rem(19)};
        top: ${rem(5)};
      }
    `}
  `}
`;

export const EndAdornmentContainerStyled = styled.span`
  ${({ endAdornmentStylesReset }) => `
    width: ${rem(19)};
    height: ${rem(19)};
    margin-left: ${rem(7)};
    text-align: left;
    ${!endAdornmentStylesReset && `
      .nmx-icon {
        position: relative;
        width: ${rem(19)};
        height: ${rem(19)};
        top: ${rem(5)};
      }
    `}
  `}
`;
