import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
// components
import Container from '../../../foundations/Grid/container';
// icons
import MagnifyingGlass from '../../../foundations/Icon/icons/illustrative/darkTheme/MagnifyingGlass';
import ThumbsUpIcon from '../../../foundations/Icon/icons/illustrative/darkTheme/ThumbsUp';

export const ContainerStyled = styled(Container)`
  ${({ theme, formvariant }) => {
    const mediumUp = theme.mediumUp();
    if (formvariant === 'hero') {
      return css`
        margin-top: ${mediumUp ? rem(50) : rem(32)};
        margin-bottom: ${mediumUp ? rem(60) : rem(48)};
      `;
    }
    return false;
  }}
`;

const iconStyles = ({ theme, formvariant }) => {
  let marginTop = 0;
  let iconWidth = rem(50);
  const xlargeUp = theme.xlargeUp();
  const mediumUp = theme.mediumUp();

  if (mediumUp) {
    marginTop = rem(-25);
  }

  if (formvariant === 'hero' && xlargeUp) {
    iconWidth = rem(60); // hero desktop icon width
  }

  return css`
    width: ${iconWidth};
    margin-top: ${marginTop};
    margin-bottom: ${rem(10)};
  `;
};

export const ThumbsUpIconStyled = styled(ThumbsUpIcon)`
  ${iconStyles};
`;

export const MagnifyingGlassIconStyled = styled(MagnifyingGlass)`
  ${iconStyles};
`;
